import { makeStyles } from '@material-ui/core/styles';
import {
  Modal,
  Backdrop,
  TextField,
  Button,
  Container,
  CardContent,
  Typography,
  FormGroup
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import Api from 'Api/Api';
import { useParams } from 'react-router-dom';
import { useNotify } from 'react-admin';
import { Form, Field } from 'react-final-form';
import { useCallback, useEffect, useState } from 'react';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  create: {
    margin: '1rem 0 0 0',
    overflowY: 'scroll',
    maxHeight: `${window.innerHeight ?? 900}px`
  },
  input: {
    margin: '1rem 0 0 0'
  },
  container: {
    background: '#fff'
  },
  createButton: {
    margin: '1rem 0 0 0'
  },
  cancelButton: {
    margin: '1rem 0 0 10px'
  }
}));

const PromptModal = ({ isOpen = false, setIsOpen = () => {} }) => {
  const notify = useNotify();
  const classes = useStyles();
  const [systemPrompt, setSystemPrompt] = useState(null);
  const [loaded, setLoaded] = useState(isOpen);
  let { flowGeneratorSessionId } = useParams();

  const fetchSystemPrompt = useCallback(async () => {
    try {
      const response = await Api.fetchSystemPrompt(flowGeneratorSessionId);
      if (Object.keys(response).length === 0) {
        notify(
          'Click "Generate Response" button first to initialise the GPT session. \n\n' +
            'Note it may take a few moments for the session to initialise',
          'warning'
        );
        return;
      }
      setSystemPrompt(response);
    } catch (e) {
      console.error(e);
      notify('Error fetching prompt', 'error');
      setSystemPrompt(null);
    }
  }, [flowGeneratorSessionId, notify]);

  useEffect(() => {
    const runEffect = async () => {
      if (!loaded && isOpen) {
        await fetchSystemPrompt();
        setLoaded(true);
      }
      if (!isOpen && loaded) setLoaded(false);
    };
    runEffect();
  }, [fetchSystemPrompt, isOpen, loaded]);

  const updateSystemPrompt = async values => {
    try {
      await Api.updateSystemPrompt(
        flowGeneratorSessionId,
        values.prompt,
        values.input
      );
      setIsOpen(false);
      await fetchSystemPrompt();
    } catch (e) {
      console.error(e);
      notify('Error updating prompt', 'error');
    }
  };

  if (!isOpen || !systemPrompt) return null;

  return (
    <Modal
      open={isOpen}
      className={classes.modal}
      onClose={() => setIsOpen(false)}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
      closeAfterTransition>
      <Container maxWidth="md" className={classes.container}>
        <CardContent>
          <Typography variant="h5" gutterBottom>
            Edit Prompt
          </Typography>
          <Form
            onSubmit={updateSystemPrompt}
            initialValues={{
              prompt: systemPrompt.prompt,
              input: systemPrompt.input
            }}
            validate={values => {
              const errors = {};
              if (!values.prompt) errors.prompt = 'Required';
              return errors;
            }}>
            {({ handleSubmit, submitting, pristine, submitError }) => (
              <form onSubmit={handleSubmit}>
                <FormGroup>
                  <Field name="input">
                    {({ input, meta }) => (
                      <TextField
                        {...input}
                        style={{ width: '100%' }}
                        minRows={1}
                        maxRows={3}
                        multiline
                        type="text"
                        variant="outlined"
                        label="Initial Input (optional)"
                        className={classes.input}
                        error={meta.error && meta.touched}
                        helperText={
                          meta.error && meta.touched ? meta.error : null
                        }
                      />
                    )}
                  </Field>
                </FormGroup>
                <FormGroup>
                  <Field name="prompt">
                    {({ input, meta }) => (
                      <TextField
                        {...input}
                        style={{ width: '100%' }}
                        minRows={10}
                        maxRows={30}
                        multiline
                        type="text"
                        variant="outlined"
                        label="Prompt"
                        className={classes.input}
                        error={meta.error && meta.touched}
                        helperText={
                          meta.error && meta.touched ? meta.error : null
                        }
                      />
                    )}
                  </Field>
                </FormGroup>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  type="submit"
                  className={classes.createButton}
                  startIcon={<SaveIcon />}
                  disabled={submitting || pristine || submitError}>
                  Update
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  className={classes.cancelButton}
                  onClick={() => setIsOpen(false)}
                  startIcon={<CancelIcon />}>
                  Cancel
                </Button>
              </form>
            )}
          </Form>
        </CardContent>
      </Container>
    </Modal>
  );
};

export default PromptModal;
