import styled from 'styled-components';
import {
  Radio,
  FormControlLabel,
  Button,
  Typography,
  CircularProgress
} from '@material-ui/core';
import { useNotify } from 'react-admin';
import Api from 'Api/Api';
import { useCallback, useState } from 'react';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import GPTFeedback from 'Components/GPTFeedback';

const statusMap = {
  failed: 'Failed',
  Failed: 'Failed',
  passedCoreCriteria: 'Passed core criteria',
  PassedCoreCriteria: 'Passed core criteria',
  passedAllCriteria: 'Passed all criteria',
  PassedAllCriteria: 'Passed all criteria'
};

const MisconceptionTagValidationSummary = ({
  questionId = null,
  currentAnswerValue = null,
  values,
  onChange = () => {}
}) => {
  const notify = useNotify();
  const [validationResult, setValidationResult] = useState({});
  const [loadingValidation, setLoadingValidation] = useState(false);

  const validateMisconceptiontag = useCallback(
    async misconceptionTag => {
      setLoadingValidation(true);
      try {
        if (!questionId) throw new Error('No question id provided');
        if (!currentAnswerValue) throw new Error('No answer value provided');
        if (!misconceptionTag) throw new Error('No misconception tag provided');

        const result = await Api.validateMisconceptionTag(
          questionId,
          currentAnswerValue,
          misconceptionTag
        );
        setValidationResult(() => ({
          currentAnswerValue,
          misconceptionTag,
          ...result
        }));
      } catch (e) {
        console.error(e);
        notify(`Error validating misconception tag: ${e}`, 'error');
      }
      setLoadingValidation(false);
    },
    [currentAnswerValue, notify, questionId]
  );

  if (loadingValidation)
    return (
      <div>
        <CircularProgress size={20} />
        <Typography>Validating...</Typography>
      </div>
    );

  const {
    status,
    summary,
    think,
    suggestedTags,
    misconceptionTag,
    tableName,
    partitionKey,
    rowKey
  } = validationResult;

  const statusText = statusMap[status];

  return (
    <div>
      <Button
        color="secondary"
        size="small"
        variant="outlined"
        type="button"
        startIcon={<VerifiedUserIcon />}
        disabled={!values?.embedding?.searchText}
        onClick={() => validateMisconceptiontag(values?.embedding?.searchText)}>
        Validate
      </Button>
      {!!summary ? (
        <ValidationResultSummary
          issuccess={statusText !== 'Failed'}
          key={currentAnswerValue}>
          <div>
            <strong>Your tag: </strong>
            <span style={{ fontStyle: 'italic' }}>"{misconceptionTag}"</span>
            <strong> {statusText}</strong>
          </div>
          <div>
            <strong>TL;DR</strong>
            <br />
            {summary}
          </div>
          <div>
            <strong>Summary:</strong>
            <br />
            {think}
          </div>
          {!!suggestedTags?.length ? (
            <AltTagWrapper>
              <strong>Alternative Tags:</strong>
              {suggestedTags?.map(tag => (
                <div>
                  <FormControlLabel
                    control={<Radio />}
                    label={tag}
                    onChange={() => onChange({ searchText: tag })}
                    name="embedding-radio"
                    value={tag}
                    checked={tag === values?.embedding?.searchText}
                  />
                </div>
              ))}
            </AltTagWrapper>
          ) : null}
          <GPTFeedback
            tableName={tableName}
            partitionKey={partitionKey}
            rowKey={rowKey}
            comments={`Misconception tag: ${misconceptionTag} - ${statusText} - summary: ${summary}`}
          />
        </ValidationResultSummary>
      ) : null}
    </div>
  );
};

const ValidationResultSummary = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ issuccess }) => (issuccess ? '#95f5af' : '#fabed1')};
  padding: 1rem;
  margin: 1rem 0 0 0;
  border-radius: 8px;
  div {
    margin: 0 0 0.25rem 0;
  }
`;

const AltTagWrapper = styled.div`
  padding: 1rem;
  background: #fff;
  border-radius: 6px;
  margin-top: 0.25rem;
  div {
    margin: 0;
  }
  span {
    padding: 2px 5px 0 0.25rem;
  }
`;

export default MisconceptionTagValidationSummary;
