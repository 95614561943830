import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button, FormGroup } from '@material-ui/core';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { React } from 'react';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import { Form, Field } from 'react-final-form';
import CachedIcon from '@material-ui/icons/Cached';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  create: {
    margin: '1rem 0 0 0'
  },
  input: {
    margin: '1rem 0 0 0'
  },
  createButton: {
    margin: '1rem 0 0 0'
  },
  cancelButton: {
    margin: '1rem 0 0 10px'
  },
  select: {
    margin: '1rem 0 0 0',
    width: '100%'
  },
  numberInput: {
    margin: '1rem 0 0 0'
  }
}));

const EditWorksheetQuestionForm = ({
  onSubmit,
  onCancel,
  worksheetQuestion = {},
  resetCache,
  cacheState
}) => {
  const classes = useStyles();

  const isCreate = !worksheetQuestion?.constructId;

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        ...worksheetQuestion
      }}
      validate={values => {
        const errors = {};
        if (isNaN(parseInt(values.constructId, 10))) {
          errors.cosntructId = 'Please enter a valid ConstructId';
        }
        if (!values.answerText && !values.answerImageUrl) {
          if (!values.answerText) {
            errors.answerText =
              'Please enter answer image and / or answer text';
          }
          if (!values.answerImageUrl) {
            errors.answerImageUrl =
              'Please enter answer image and / or answer text';
          }
        }
        if (!values.questionText && !values.questionImageUrl) {
          if (!values.questionText) {
            errors.questionText =
              'Please enter question image and / or question text';
          }
          if (!values.questionImageUrl) {
            errors.questionImageUrl =
              'Please enter question image and / or question text';
          }
        }
        return errors;
      }}>
      {({ handleSubmit, submitting, pristine, submitError }) => (
        <form onSubmit={handleSubmit}>
          <FormGroup className={classes.numberInput}>
            <Field name="constructId">
              {({ input, meta }) => (
                <TextField
                  {...input}
                  id="standard-number"
                  label="ConstructId"
                  type="number"
                  disabled={!isCreate}
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : null}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              )}
            </Field>
          </FormGroup>
          <FormGroup className={classes.numberInput}>
            <Field name="sequence">
              {({ input, meta }) => (
                <TextField
                  {...input}
                  id="standard-number"
                  label="Sequence / Question No."
                  type="number"
                  disabled={!isCreate}
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : null}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              )}
            </Field>
          </FormGroup>
          <FormGroup>
            <Field name="questionImageUrl">
              {({ input, meta }) => (
                <TextField
                  {...input}
                  id="standard-basic"
                  label="QuestionImageUrl"
                  variant="filled"
                  className={classes.input}
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : null}
                />
              )}
            </Field>
          </FormGroup>
          <FormGroup>
            <Field name="questionText">
              {({ input, meta }) => (
                <TextField
                  {...input}
                  id="standard-basic"
                  label="QuestionText"
                  variant="filled"
                  multiline
                  rows={2}
                  className={classes.input}
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : null}
                />
              )}
            </Field>
          </FormGroup>
          <FormGroup>
            <Field name="answerImageUrl">
              {({ input, meta }) => (
                <TextField
                  {...input}
                  id="standard-basic"
                  label="AnswerImageUrl"
                  variant="filled"
                  className={classes.input}
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : null}
                />
              )}
            </Field>
          </FormGroup>
          <FormGroup>
            <Field name="answerText">
              {({ input, meta }) => (
                <TextField
                  {...input}
                  id="standard-basic"
                  label="AnswerText"
                  variant="filled"
                  multiline
                  rows={2}
                  className={classes.input}
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : null}
                />
              )}
            </Field>
          </FormGroup>
          <Button
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            className={classes.createButton}
            startIcon={<SaveIcon />}
            disabled={submitting || pristine || submitError}>
            Update
          </Button>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            onClick={() => resetCache({ showNotify: true })}
            className={classes.cancelButton}
            startIcon={<CachedIcon />}
            disabled={
              submitting || !pristine || submitError || cacheState?.isLoading
            }>
            Reset Cache
          </Button>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            className={classes.cancelButton}
            onClick={() => onCancel()}
            startIcon={<CancelIcon />}
            disabled={submitting}>
            Cancel
          </Button>
        </form>
      )}
    </Form>
  );
};

export default EditWorksheetQuestionForm;
