import FlowItemListGrid from './LessonShowComponents/FlowItemListGrid';
import { Grid, Slide, Box } from '@material-ui/core';
import { makeStyles, createTheme } from '@material-ui/core/styles';
import styled from 'styled-components';
import dataProvider from 'dataProvider';
import { Loading, useNotify } from 'react-admin';
import { useParams } from 'react-router';
import { useEffect, useState, useCallback } from 'react';
import { useSignalROn } from 'Hooks/useSignalROn';
import StudentShowCard from 'Components/Students/StudentShowCard';
import { useJoinFlowSessionSignalRGroup } from 'Hooks/useJoinFlowSessionSignalRGroup';
import ChatBox from './LessonShowComponents/ChatBox';
import useGetAuthUser from 'Hooks/useGetAuthUser';
import { useSelector } from 'react-redux';
import HidePanelButton from 'Components/HidePanelButton';
import NotifySound from 'Assets/notification.mp3';
import Api from 'Api/Api';
import useApiRequest from 'Hooks/useApiRequest';
import UnClaimLessonModal from 'Components/Lessons/UnClaimLessonModal';
import moment from 'moment';
import useQuery from 'Hooks/useQuery';
import { useContext } from 'react';
import { SignalRConnectionContext } from 'Providers/SignalRProvider';
import { useReducer } from 'react';

const theme = createTheme({});

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    height: '100%'
  },
  studentShow: {
    height: 'calc(100vh - 65px)'
  },
  hidePanel: {
    textAlign: 'center',
    padding: '0.25rem'
  }
}));

const initialState = { flowItems: [] };

function reducer(state, action) {
  switch (action.type) {
    case 'addItem':
      const existingFlowItem = state.flowItems.find(
        fi => fi.flowItemId === action.payload.flowItemId
      );
      if (existingFlowItem) return state;
      return {
        flowItems: [...state.flowItems, action.payload]
      };
    case 'setItems':
      return {
        flowItems: action.payload
      };
    default:
      throw new Error();
  }
}

const LessonShow = _ => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const notify = useNotify();
  const classes = useStyles();
  const { studentShowOpen } = useSelector(s => s.uxState);
  let { flowGeneratorSessionId } = useParams();
  flowGeneratorSessionId = parseInt(flowGeneratorSessionId, 10);
  const [data, setData] = useState({});
  useJoinFlowSessionSignalRGroup(flowGeneratorSessionId);
  const user = useGetAuthUser();

  // GPT Bits
  const isGPTEnabled = useQuery().get('gpt')?.toLowerCase() === 'true';
  // eslint-disable-next-line no-unused-vars
  const [isGPTLoading, setIsGPTLoading] = useState(false);
  const [responseInvalidated, setResponseInvalidated] = useState(false);
  const [gptText, setGPTText] = useState('');
  const [isPromptModalOpen, setIsPromptModalOpen] = useState(false);
  // END GPT Bits

  const [isClaimModalOpen, setIsClaimModalOpen] = useState(false);
  const [isClaiming, setIsClaiming] = useState(false);
  const [isRegenerateDispatched, setIsRegenerateDispatched] = useState(false);

  // Track SignalR connection date
  const { connectionInfo, signalRConnection } = useContext(
    SignalRConnectionContext
  );
  const [connectionDate, setConnectionDate] = useState(null);

  const cancelTutorResponse = useCallback(
    async () => await Api.cancelTutorResponse(flowGeneratorSessionId),
    [flowGeneratorSessionId]
  );

  const regenerateTutorResponse = useCallback(async () => {
    setGPTText('');
    setIsRegenerateDispatched(true);
    await Api.regenerateTutorResponse(flowGeneratorSessionId);
    setIsRegenerateDispatched(false);
  }, [flowGeneratorSessionId]);

  const { data: response } = useApiRequest(
    async () => await Api.fetchTutorResponse(flowGeneratorSessionId)
  );

  const getLesson = useCallback(async () => {
    try {
      if (!flowGeneratorSessionId) return;
      const { data } = await dataProvider.getOne('flow-generator-sessions', {
        id: flowGeneratorSessionId
      });
      dispatch({
        type: 'setItems',
        payload: data?.flowGeneratorSession?.flowItems || []
      });
      setData(data);
    } catch (e) {
      console.error(e);
    }
  }, [flowGeneratorSessionId]);

  useEffect(() => {
    async function runEffect() {
      if (
        flowGeneratorSessionId &&
        (flowGeneratorSessionId !== data?.flowGeneratorSessionId ||
          connectionInfo?.connectionDate !== connectionDate)
      ) {
        await getLesson();
        localStorage.setItem(
          `lesson${flowGeneratorSessionId}`,
          new Date().toISOString()
        );
        setGPTText('');
        setConnectionDate(connectionInfo.connectionDate);
      }
    }
    runEffect();
  }, [
    connectionDate,
    connectionInfo.connectionDate,
    data?.flowGeneratorSessionId,
    flowGeneratorSessionId,
    getLesson
  ]);

  const processFlowSession = useCallback(
    flowSession => {
      if (flowSession?.flowGeneratorSessionId !== data?.flowGeneratorSessionId)
        return;

      let flowItemGroupInputs = [
        ...data.flowGeneratorSession.flowItemGroupInputs
      ];

      flowSession.flowItemGroupInputs.forEach(fi => {
        flowItemGroupInputs = flowItemGroupInputs.filter(
          fio => fio.flowItemGroup !== fi.flowItemGroup
        );
        flowItemGroupInputs.push(fi);
      });

      setData({
        ...data,
        isGPTEnabled: flowSession.isGPTEnabled,
        tutorUserId: flowSession.tutorUserId,
        tutorUsername:
          flowSession.tutorUserId === user.id
            ? user.fullname
            : data.tutorUsername,
        flowGeneratorSession: {
          ...data.flowGeneratorSession,
          flowItemGroupInputs: flowItemGroupInputs.sort(
            (a, b) => new Date(a?.dateCreated) - new Date(b?.dateCreated)
          )
        }
      });
    },
    [data, user?.fullname, user?.id]
  );

  const claimLesson = useCallback(async () => {
    try {
      if (!data) return;
      setIsClaiming(true);
      if (user?.id === data?.tutorUserId) {
        await Api.unClaimFlowGeneratorSessionLessonIntervention(
          data?.flowGeneratorSessionId,
          { dateEnded: moment().format() }
        );
        setIsClaimModalOpen(true);
      } else {
        const flowSession = await Api.claimFlowGeneratorSessionLesson(
          data?.flowGeneratorSessionId,
          isGPTEnabled
        );
        processFlowSession(flowSession);
      }
      setIsClaiming(false);
    } catch (e) {
      console.error(e);
      notify('Unable to claim this lesson. Please try again.', 'error');
    }
  }, [data, user?.id, isGPTEnabled, processFlowSession, notify]);

  useSignalROn(
    `DispatchFlowGeneratorSession:${flowGeneratorSessionId}`,
    flowSession => processFlowSession(flowSession)
  );

  const processFlowItem = useCallback(
    flowItem => {
      if (flowItem?.flowGeneratorSessionId !== data?.flowGeneratorSessionId)
        return;

      const existingFlowItem = state.flowItems.find(
        fi => fi.flowItemId === flowItem.flowItemId
      );

      if (existingFlowItem) return;

      if (
        flowItem?.userId > 0 &&
        flowItem?.userId !== user?.id &&
        !flowItem?.flowItemGroup
      ) {
        new Audio(NotifySound)
          .play()
          .then(r => {})
          .catch(e => console.warn(e));
      }

      dispatch({ type: 'addItem', payload: flowItem });
      setData(() => ({
        ...data,
        flowGeneratorSession: {
          ...data.flowGeneratorSession
        }
      }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, user?.id]
  );

  useSignalROn(`DispatchFlowItem:${flowGeneratorSessionId}`, flowItem =>
    processFlowItem(flowItem)
  );

  useSignalROn(`DispatchGPTTextLoading:${flowGeneratorSessionId}`, isLoading =>
    setIsGPTLoading(isLoading.toLowerCase() === 'true')
  );

  useSignalROn(`DispatchGPTText:${flowGeneratorSessionId}`, gptText => {
    setGPTText(gptText);
    setResponseInvalidated(true);
  });

  if (!data || data?.flowGeneratorSessionId !== flowGeneratorSessionId) {
    return (
      <div className={classes.root}>
        <Loading />
      </div>
    );
  }

  const text = !!gptText?.length
    ? gptText
    : !responseInvalidated && typeof response === 'string'
    ? response
    : gptText;

  const pinnedFlowItem = state?.flowItems?.length
    ? state?.flowItems
        ?.filter(
          fi =>
            // fi.flowItemGroup?.toLowerCase().includes('question') &&
            !fi.userId &&
            fi.templateSlug &&
            fi.flowItemType?.toLowerCase()?.includes('pinned')
        )
        ?.sort(
          (a, b) => new Date(b.dateDispatched) - new Date(a.dateDispatched)
        )[0]
    : null;

  console.log('pinnedFlowItem', pinnedFlowItem);

  return (
    <Grid
      container
      direction="row"
      alignItems="flex-start"
      style={{ position: 'relative' }}>
      <Grid
        item
        xs={12}
        sm={studentShowOpen ? 8 : 11}
        style={{
          transition: theme.transitions.create('all', {
            easing: theme.transitions.easing.easeInOut,
            duration: '0.7s'
          }),
          maxWidth: !studentShowOpen ? '97%' : '',
          flexBasis: !studentShowOpen ? '97%' : ''
        }}>
        <FlowItemListGrid
          data={data}
          flowItems={state.flowItems}
          claimLesson={claimLesson}
          isClaiming={isClaiming}
        />
        {data?.tutorUserId === user?.id && (
          <Grid item xs={12} style={{ position: 'sticky', bottom: '0' }}>
            <ChatBox
              isPromptModalOpen={isPromptModalOpen}
              setIsPromptModalOpen={setIsPromptModalOpen}
              flowGeneratorSessionId={data?.flowGeneratorSessionId}
              //loading={isGPTLoading && data?.isGPTEnabled}
              text={text}
              isGPTEnabled={false}
              // isGPTEnabled={data?.isGPTEnabled || !!gptText?.length}
              clearTutorResponse={() => setGPTText('')}
              cancelTutorResponse={cancelTutorResponse}
              regenerateTutorResponse={regenerateTutorResponse}
              isRegenerateDispatched={isRegenerateDispatched}
              studentUserId={data?.userId}
              signalRConnection={signalRConnection}
              flowItemGroup={pinnedFlowItem?.flowItemGroup}
              pinnedImage={pinnedFlowItem?.imageUrl}
              pinnedText={pinnedFlowItem?.text}
            />
          </Grid>
        )}
      </Grid>
      <StyledGrid
        item
        xs={12}
        sm={studentShowOpen ? 4 : 1}
        maxwidth={false}
        style={{
          transition: theme.transitions.create('all', {
            easing: theme.transitions.easing.easeInOut,
            duration: '0.7s'
          }),
          maxWidth: !studentShowOpen ? '3%' : ''
        }}>
        <div
          className={classes.studentShow}
          style={{
            transition: theme.transitions.create('all', {
              easing: theme.transitions.easing.easeInOut,
              duration: '1s'
            }),
            background: studentShowOpen ? 'white' : '#ccc'
          }}>
          <Slide
            direction="down"
            in={!studentShowOpen}
            mountOnEnter
            unmountOnExit
            timeout={200}>
            <Box className={classes.hidePanel}>
              <HidePanelButton property="studentShowOpen" />
            </Box>
          </Slide>
          <Slide
            direction="left"
            in={studentShowOpen}
            mountOnEnter
            unmountOnExit
            timeout={700}>
            <div>
              <StudentShowCard
                data={data}
                claimLesson={claimLesson}
                isClaiming={isClaiming}
              />
            </div>
          </Slide>
        </div>
      </StyledGrid>
      <UnClaimLessonModal
        isModalOpen={isClaimModalOpen}
        setisModalOpen={() => setIsClaimModalOpen(false)}
        flowGeneratorSessionId={data?.flowGeneratorSessionId}
      />
    </Grid>
  );
};

const StyledGrid = styled(Grid)`
  position: sticky;
  top: 0;
`;

export default LessonShow;
