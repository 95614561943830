import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from 'react';
import { getDotMatrixGrid, getLineGrid } from '../helpers/gridHelpers';

const spacingOpts = [
  { dotSpacing: 10, dotRadius: 1 },
  { dotSpacing: 20, dotRadius: 2 },
  { dotSpacing: 30, dotRadius: 3 },
  { dotSpacing: 40, dotRadius: 4 },
  { dotSpacing: 50, dotRadius: 5 },
  { dotSpacing: 60, dotRadius: 6 },
  { dotSpacing: 70, dotRadius: 7 },
  { dotSpacing: 80, dotRadius: 8 },
  { dotSpacing: 90, dotRadius: 9 },
  { dotSpacing: 100, dotRadius: 10 }
];

const useGetGrid = ({ stageWidthOffset = 0, stageHeightOffset = 0 }) => {
  const wrapperRef = useRef(null);
  const [grid, setGrid] = useState([]);
  const [gridSpacing, setGridSpacing] = useState(spacingOpts[1].dotSpacing);
  const [gridType, setGridType] = useState('line');
  const [stageSize, setStageSize] = useState({ width: 0, height: 0 });

  useLayoutEffect(() => {
    if (wrapperRef.current) {
      const { clientWidth, clientHeight } = wrapperRef.current;
      setGrid(
        gridType === 'line'
          ? getLineGrid({
              width: clientWidth,
              height: clientHeight,
              lineSpacing: gridSpacing,
              lineColor: '#ddd',
              lineWidth: 1
            })
          : getDotMatrixGrid({
              width: clientWidth,
              height: clientHeight,
              dotRadius: spacingOpts.find(s => s.dotSpacing === gridSpacing)
                .dotRadius,
              dotSpacing: gridSpacing,
              dotColor: '#ddd'
            })
      );
      setStageSize({ width: clientWidth, height: clientHeight });
    }
  }, [gridSpacing, gridType]);

  const handleResize = useCallback(() => {
    setStageSize({
      width: window.innerWidth + stageWidthOffset,
      height: window.innerHeight + stageHeightOffset
    });
  }, [stageHeightOffset, stageWidthOffset]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return {
    wrapperRef,
    grid,
    gridSpacing,
    setGridSpacing,
    gridType,
    setGridType,
    stageSize
  };
};

export default useGetGrid;
