import {
  Modal,
  Backdrop,
  Container,
  Card,
  CardContent,
  Typography
} from '@material-ui/core';

const ImageModal = ({ record, classes, isModalOpen, setisModalOpen }) => {
  return (
    <Modal
      open={isModalOpen}
      onClose={setisModalOpen}
      className={classes.modal}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
      closeAfterTransition>
      <Container maxWidth="md">
        <Card
          className={classes.create}
          style={{
            overflowY: 'scroll',
            maxHeight: `${window.innerHeight ?? 900}px`
          }}>
          <CardContent>
            <div style={{ textAlign: 'center' }}>
              <img
                src={
                  record.imageURL?.includes('.jp')
                    ? record.imageURL
                    : record.sourceQuestionImageURL
                }
                alt=""
                style={{ width: 'auto', maxHeight: '400px' }}
              />
            </div>
            <Typography variant="h6" gutterBottom>
              <strong>Answer)</strong>{' '}
              {['-', 'A', 'B', 'C', 'D'][record.correctAnswer]}
            </Typography>
            <hr />
            <Typography variant="p" gutterBottom style={{ textAlign: 'left' }}>
              <strong>A)</strong> {record.explanationA}
            </Typography>
            <hr />
            <Typography variant="p" gutterBottom style={{ textAlign: 'left' }}>
              <strong>B)</strong> {record.explanationB}
            </Typography>
            <hr />
            <Typography variant="p" gutterBottom style={{ textAlign: 'left' }}>
              <strong>C)</strong> {record.explanationC}
            </Typography>
            <hr />
            <Typography variant="p" gutterBottom style={{ textAlign: 'left' }}>
              <strong>D)</strong> {record.explanationD}
            </Typography>
          </CardContent>
        </Card>
      </Container>
    </Modal>
  );
};

export default ImageModal;
