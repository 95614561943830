import { defaultTheme } from 'react-admin';
import merge from 'lodash/merge';
import pink from '@material-ui/core/colors/pink';
import red from '@material-ui/core/colors/red';

const theme = merge({}, defaultTheme, {
  palette: {
    primary: {
      light: '#6875ff',
      main: '#4353ff',
      dark: '#2e3ab2',
      contrastText: '#fff'
    },
    secondary: pink,
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2
  },
  typography: {
    fontSize: 14,
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Arial',
      'sans-serif'
    ].join(','),
    body1: {
      fontSize: 14
    }
  },
  overrides: {
    MuiButton: {
      // override the styles of all instances of this component
      root: {
        // Name of the rule
        color: 'white' // Some CSS
      }
    },
    MuiDrawer: {
      root: {
        background: '#eee',
        zIndex: '999'
      }
    }
  }
});

export default theme;
