import { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import useApiRequest from 'Hooks/useApiRequest';
import Api from 'Api/Api';
import FlowsMenu from './Components/Menus/FlowsMenu';
import FlowTemplateCanvas from './Components/FlowTemplateCanvas';
import { useParams } from 'react-router';
import { Loading, useNotify } from 'react-admin';
import { useHistory } from 'react-router';
import FullPageLoader from '../../Components/FullPageLoader';
import CreateFlowTemplateModal from './Components/Modals/CreateFlowTemplateModal';
import EditFlowTemplate from './Components/Forms/EditFlowTemplate';
import FlowItemTemplateCanvas from './Components/FlowItemTemplateCanvas';
import EditFlowItemTemplate from './Components/Forms/EditFlowItemTemplate';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: 0,
    overflow: 'hidden'
  },
  listGridItem: {
    borderRight: '1px solid #ddd',
    borderLeft: '1px solid #ddd'
  },
  menuGridItem: {
    borderLeft: '1px solid #ddd',
    height: 'calc(100vh - 48px)',
    overflowY: 'scroll',
    overflowX: 'hidden'
  },
  showItem: {
    height: 'calc(100vh - 48px)',
    overflow: 'hidden'
  }
}));

const FlowTemplatePage = () => {
  const notify = useNotify();
  const classes = useStyles();
  const history = useHistory();
  let {
    templateSlug = '',
    flowItemTemplateSlug = '',
    level = null
  } = useParams();
  const [isCreateFlowTemplateModalOpen, setIsCreateFlowTemplateModalOpen] =
    useState(false);
  const [newNodePosition, setNewNodePosition] = useState(null);
  const [selectedFlowTemplate, setSelectedFlowTemplate] = useState(null);
  const [selectedFlowItem, setSelectedFlowItem] = useState(null);
  const [loading, setLoading] = useState(false);
  const [flowTemplateGroup, setFlowTemplateGroup] = useState(null);
  const [currentUri, setCurrentUri] = useState(null);

  const {
    data: templateSlugs,
    loading: loadingTemplateSlugs,
    reload: reloadTemplateSlugs
  } = useApiRequest(async () => await Api.getAllFlowTemplateSlugs());

  const { reload, loading: loadingTemplates } = useApiRequest(async () => {
    try {
      if (!templateSlug) return null;

      const response = await Api.getFlowTemplateGroup(templateSlug);
      setFlowTemplateGroup(response);
      return response;
    } catch (e) {
      console.error(e);
      notify(`${e}`, 'error');
      history.push('/flows');
    }

    return null;
  });

  const fetchFlowTemplateGroup = useCallback(
    async templateSlug => {
      setLoading(true);
      try {
        const response = await Api.getFlowTemplateGroup(templateSlug);
        setFlowTemplateGroup(response);
      } catch (e) {
        console.error(e);
        notify(`${e}`, 'error');
      }
      setLoading(false);
    },
    [notify]
  );

  const updateFlowTemplate = useCallback(
    async (flowTemplate, position, isMoveOnly = false) => {
      if (loading) return;

      if (!isMoveOnly) setLoading(true);

      try {
        if (!position) throw new Error('No position provided!');

        flowTemplate.xPosition = Math.floor(flowTemplate.xPosition);
        flowTemplate.yPosition = Math.floor(flowTemplate.yPosition);

        const updatedFlowTemplate = await Api.updateFlowTemplate(
          templateSlug,
          flowTemplate,
          position,
          isMoveOnly
        );

        if (!updatedFlowTemplate) throw new Error('No updatedFlowTemplate!');

        if (!isMoveOnly) {
          notify('Flow Template updated!', 'success');
          await reload();
          setLoading(false);
          return;
        }

        flowTemplateGroup.flowTemplates = flowTemplateGroup.flowTemplates.map(
          ft =>
            ft.position === updatedFlowTemplate.position
              ? updatedFlowTemplate
              : ft
        );
        setFlowTemplateGroup(() => flowTemplateGroup);
        if (selectedFlowTemplate?.position === updatedFlowTemplate.position) {
          setSelectedFlowTemplate(updatedFlowTemplate);
        }
      } catch (e) {
        console.error(e);
        notify(`${e}`, 'error');
      }
      setLoading(false);
    },
    [
      flowTemplateGroup,
      loading,
      notify,
      reload,
      selectedFlowTemplate?.position,
      templateSlug
    ]
  );

  const updateFlowItem = useCallback(
    async (flowItem, sequence, isMoveOnly = false) => {
      if (loading) return;

      if (!isMoveOnly) setLoading(true);

      try {
        if (!sequence) throw new Error('No sequence provided!');
        if (!flowItem.templateSlug)
          throw new Error('No templateSlug provided!');

        flowItem.xPosition = Math.floor(flowItem.xPosition);
        flowItem.yPosition = Math.floor(flowItem.yPosition);

        const updatedFlowItem = await Api.updateFlowItem(
          flowItem.templateSlug,
          flowItem,
          sequence,
          isMoveOnly
        );

        if (!updatedFlowItem) throw new Error('No updated flow item!');

        if (!isMoveOnly) {
          notify('Flow item updated!', 'success');
          await reload();
          setLoading(false);
          return;
        }

        flowTemplateGroup.flowItems = flowTemplateGroup.flowItems
          .map(ft =>
            ft.sequence === updatedFlowItem.sequence ? updatedFlowItem : ft
          )
          .filter(
            (ft, i, arr) =>
              arr.findIndex(f => f.flowItemId === ft.flowItemId) === i
          );
        setFlowTemplateGroup(() => flowTemplateGroup);
        if (selectedFlowItem?.flowItemId === updatedFlowItem.flowItemId) {
          setSelectedFlowItem(updatedFlowItem);
        }
      } catch (e) {
        console.error(e);
        notify(`${e}`, 'error');
      }
      setLoading(false);
    },
    [flowTemplateGroup, loading, notify, reload, selectedFlowItem?.flowItemId]
  );

  const deleteFlowTemplate = useCallback(
    async flowTemplate => {
      if (loading) return;
      setLoading(true);
      try {
        if (!flowTemplate) throw new Error('No flowTemplate provided!');
        if (!flowTemplate.position)
          throw new Error('No flowTemplate.position provided!');
        if (!flowTemplate.templateSlug)
          throw new Error('No flowTemplate.templateSlug provided!');
        await Api.deleteFlowTemplate(
          flowTemplate.templateSlug,
          flowTemplate.position
        );
        window.location.reload();
      } catch (e) {
        console.error(e);
        notify(`${e}`, 'error');
      }
      setLoading(false);
    },
    [loading, notify]
  );

  const deleteFlowItem = useCallback(
    async flowItem => {
      if (loading) return;
      setLoading(true);
      try {
        if (!flowItem) throw new Error('No flowItem provided!');
        if (!flowItem.sequence)
          throw new Error('No flowItem.sequence provided!');
        if (!flowItem.templateSlug)
          throw new Error('No flowItem.templateSlug provided!');
        await Api.deleteFlowItem(flowItem.templateSlug, flowItem.sequence);
        window.location.reload();
      } catch (e) {
        console.error(e);
        notify(`${e}`, 'error');
      }
      setLoading(false);
    },
    [loading, notify]
  );

  const insertFlowTemplate = useCallback(
    async (flowTemplate, position) => {
      if (loading) return;
      setLoading(true);
      try {
        if (!position) throw new Error('No position provided!');
        if (!flowTemplate) throw new Error('No flowTemplate provided!');

        flowTemplate.xPosition = Math.floor(flowTemplate.xPosition);
        flowTemplate.yPosition = Math.floor(flowTemplate.yPosition);

        await Api.insertFlowTemplate(templateSlug, flowTemplate, position);
        await reload();
        setIsCreateFlowTemplateModalOpen(false);
      } catch (e) {
        console.error(e);
        notify(`${e}`, 'error');
      }
      setLoading(false);
    },
    [loading, notify, reload, templateSlug]
  );

  const insertFlowItem = useCallback(
    async (flowItem, sequence) => {
      if (loading) return;
      setLoading(true);
      try {
        if (!sequence) throw new Error('No sequence provided!');
        if (!flowItem) throw new Error('No flowItem provided!');
        if (!flowItem.templateSlug)
          throw new Error('No templateSlug provided!');

        flowItem.xPosition = Math.floor(flowItem.xPosition);
        flowItem.yPosition = Math.floor(flowItem.yPosition);

        await Api.insertFlowItem(flowItem.templateSlug, flowItem, sequence);
        await reload();
      } catch (e) {
        console.error(e);
        notify(`${e}`, 'error');
      }
      setLoading(false);
    },
    [loading, notify, reload]
  );

  const createNewTemplateSlug = useCallback(
    async (templateSlug, flowTemplate) => {
      setLoading(true);
      try {
        if (!templateSlug) throw new Error('No templateSlug provided!');
        if (!flowTemplate) throw new Error('No flowTemplate provided!');

        await Api.createNewTemplateSlug(templateSlug, flowTemplate);
        history.push(`/flows/${templateSlug}`);
        window.location.reload();
      } catch (e) {
        console.error(e);
        notify(`${e}`, 'error');
      }
      setLoading(false);
    },
    [history, notify]
  );

  const cloneFlowTemplate = useCallback(
    async values => {
      if (!values?.templateSlug) return;
      setLoading(true);
      try {
        const flowTemplate = await Api.cloneFlowTemplate(
          values.templateSlug,
          values.cloneTemplateSlug
        );
        await reloadTemplateSlugs();
        window.location.replace(`/flows/${flowTemplate.templateSlug}`);
      } catch (e) {
        console.error(e);
        notify(`${e}`, 'error');
      }
      setLoading(false);
    },
    [notify, reloadTemplateSlugs]
  );

  const selectFlowTemplate = flowTemplate => {
    if (!flowTemplate) return;
    setSelectedFlowTemplate(flowTemplate);
  };

  const selectFlowItemTemplate = flowItemId => {
    if (!flowItemId) return;
    setSelectedFlowItem(flowItemId);
  };

  useEffect(() => {
    const runEffect = async () => {
      if (flowTemplateGroup && selectedFlowTemplate) {
        setSelectedFlowTemplate(
          flowTemplateGroup.flowTemplates.find(
            ft => ft.position === selectedFlowTemplate.position
          )
        );
      }
      if (!templateSlug && selectedFlowTemplate) {
        setSelectedFlowTemplate(null);
      }
      if (currentUri !== window.location.href) {
        setCurrentUri(window.location.href);
        await reload();
      }
    };
    runEffect();
  }, [
    currentUri,
    flowTemplateGroup,
    reload,
    selectedFlowTemplate,
    templateSlug
  ]);

  const filterFlowItemTemplates = useCallback(() => {
    const flowItemTemplates = flowTemplateGroup?.flowItems || [];
    return flowItemTemplates.filter(ft =>
      flowItemTemplateSlug ? ft.templateSlug === flowItemTemplateSlug : true
    );
  }, [flowItemTemplateSlug, flowTemplateGroup?.flowItems]);

  const filterTemplates = useCallback(() => {
    const templates = flowTemplateGroup?.flowTemplates || [];
    return templates.map(ft => {
      const hasParent = !!templates.find(
        fi =>
          fi.flowTemplateSlug !== null &&
          fi.flowTemplateSlug === ft.parentFlowTemplateSlug
      );
      ft.level = hasParent ? 1 : 0;
      return ft;
    });
  }, [flowTemplateGroup?.flowTemplates]);

  if (loadingTemplateSlugs) return <Loading />;

  const flowTemplates = filterTemplates(flowTemplateGroup?.flowTemplates || []);

  const filteredFlowTemplates = flowTemplates.filter(
    ft => level === null || `${ft.level}` === `${level}`
  );

  const flowItemTemplates = filterFlowItemTemplates();

  return (
    <Grid container className={classes.root}>
      <Grid item xs={3} lg={2} className={classes.listGridItem}>
        <FlowsMenu
          templateSlugs={templateSlugs}
          templateSlug={templateSlug}
          filteredFlowTemplates={filteredFlowTemplates}
          flowTemplates={flowTemplates}
          flowItems={flowItemTemplates}
          loadFlowTemplate={async templateSlug =>
            await fetchFlowTemplateGroup(templateSlug)
          }
          cloneFlowTemplate={cloneFlowTemplate}
          updateFlowTemplate={updateFlowTemplate}
          insertFlowTemplate={insertFlowTemplate}
          createNewTemplateSlug={createNewTemplateSlug}
        />
      </Grid>
      <Grid item xs={6} lg={7} className={classes.showItem}>
        {!flowItemTemplateSlug ? (
          <FlowTemplateCanvas
            key={templateSlug}
            flowTemplates={{
              ...flowTemplateGroup,
              flowTemplates: filteredFlowTemplates
            }}
            selectFlowTemplate={selectFlowTemplate}
            updateFlowTemplate={updateFlowTemplate}
            templateSlug={templateSlug}
            openCreateFlowTemplateModal={newNodePosition => {
              setIsCreateFlowTemplateModalOpen(true);
              setNewNodePosition(newNodePosition);
            }}
          />
        ) : (
          <FlowItemTemplateCanvas
            key={flowItemTemplateSlug}
            flowItemTemplates={flowItemTemplates}
            flowItemTemplateSlug={flowItemTemplateSlug}
            templateSlug={templateSlug}
            insertFlowItem={insertFlowItem}
            updateFlowItem={updateFlowItem}
            selectFlowItemTemplate={selectFlowItemTemplate}
            selectedFlowItem={selectedFlowItem}
            flowTemplates={flowTemplates}
            isLoading={loading || (loadingTemplates && templateSlug)}
          />
        )}
      </Grid>
      <Grid item xs={3} lg={3} className={classes.menuGridItem}>
        {!flowItemTemplateSlug ? (
          <EditFlowTemplate
            key={selectedFlowTemplate?.position}
            flowItemTemplates={flowItemTemplates}
            flowTemplate={selectedFlowTemplate}
            isLoading={loading || (loadingTemplates && templateSlug)}
            flowTemplates={filteredFlowTemplates}
            updateFlowTemplate={updateFlowTemplate}
            deleteFlowTemplate={deleteFlowTemplate}
            insertFlowItem={insertFlowItem}
            updateFlowItem={updateFlowItem}
          />
        ) : (
          <EditFlowItemTemplate
            key={selectedFlowItem?.sequence}
            flowItemTemplates={flowItemTemplates}
            flowItem={selectedFlowItem}
            loading={loading || (loadingTemplates && templateSlug)}
            flowTemplates={filteredFlowTemplates}
            updateFlowItem={updateFlowItem}
            deleteFlowItem={deleteFlowItem}
          />
        )}
      </Grid>
      <FullPageLoader loading={loading || (loadingTemplates && templateSlug)} />
      <CreateFlowTemplateModal
        key={templateSlug}
        flowItemTemplates={flowItemTemplates}
        templateSlug={templateSlug}
        insertFlowTemplate={insertFlowTemplate}
        isModalOpen={isCreateFlowTemplateModalOpen}
        setIsModalOpen={setIsCreateFlowTemplateModalOpen}
        insertFlowItem={insertFlowItem}
        updateFlowItem={updateFlowItem}
        newNodePosition={newNodePosition}
        isLoading={loading}
        flowTemplates={flowTemplates}
      />
    </Grid>
  );
};

export default FlowTemplatePage;
