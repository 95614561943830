const flowItemGroupType = {
  default: 'Default',
  diagnosticQuestionAnswer: 'DiagnosticQuestionAnswer',
  worksheetQuestionAnswer: 'WorksheetQuestionAnswer',
  diagnosticQuestionRetryAnswer: 'DiagnosticQuestionRetryAnswer',
  checkoutQuestion: 'CheckoutQuestion',
  checkoutQuestionRetry: 'CheckoutQuestionRetry'
};

export default flowItemGroupType;
