import { makeStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, Container, Card } from '@material-ui/core';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useCallback, React, useState, useEffect } from 'react';
import { Button, useDataProvider, useNotify, useRefresh } from 'react-admin';
import { useLocation } from 'react-router-dom';
import MisconceptionTaggingQuestionForm from './MisconceptionTagForm';
import TabPanel from 'Components/TabPanel';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import useGetAuthUser from 'Hooks/useGetAuthUser';

const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: 700,
    position: 'relative'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  tabs: {
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  create: {
    margin: '1rem 0 0 0',
    overflowY: 'scroll',
    maxHeight: `${window.innerHeight ?? 900}px`
  },
  input: {
    margin: '1rem 0 0 0'
  },
  close: {
    position: 'absolute',
    top: '1.5rem',
    right: '1.5rem',
    borderRadius: '50%',
    color: '#000'
  },
  answerLetter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '1.5rem',
    fontWeight: 600,
    width: '3.5rem',
    borderRadius: '0.5rem',
    backgroundColor: '#F53838',
    color: '#fff'
  },
  answerLetterCorrect: {
    backgroundColor: '#09C18A'
  }
}));

const MisconceptionTaggingQuestionModal = ({ isOpen, setIsOpen }) => {
  const location = useLocation();
  const id = location.pathname.split('/').reverse()[0];
  const notify = useNotify();
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const [question, setQuestion] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const user = useGetAuthUser();

  const fetchQuestion = useCallback(
    async id => {
      try {
        if (!id) return;

        const result = await dataProvider.getOne(
          'topic-pathway-quiz-question-insights',
          {
            id
          }
        );

        if (!result?.data)
          throw new Error(`Unable to fetch flow question ${id}`);

        setQuestion(() => result.data);
      } catch (e) {
        notify('Error fetching flow question!', 'error');
        console.error(e);
      }
    },
    [dataProvider, notify]
  );

  const saveMisconceptionTag = useCallback(
    async values => {
      // If it's not flagged clear the fields associated with being flagged
      if (!values.flagged) {
        values.misconceptionStatus = null;
        values.comments = null;
      }

      values = {
        ...values,
        ...values.embedding
      };

      if (!values.searchText) {
        values.searchText = null;
        values.embeddingOutputId = null;
      }

      if (
        !values.similarDistractorQuestionId ||
        !values.similarDistractorAnswerValue
      ) {
        values.similarDistractorQuestionId = null;
        values.similarDistractorAnswerValue = null;
      }

      if (
        !values.searchText &&
        !values.misconceptionStatus &&
        !values.comments
      ) {
        notify('Please enter a tag or a flag', 'error');
        return;
      }

      try {
        if (values.id) {
          await dataProvider.update('misconception-tags', {
            id: values.id,
            body: values,
            idAlias: 'misconceptionTagId'
          });
        } else {
          await dataProvider.create('misconception-tags', {
            body: values,
            idAlias: 'misconceptionTagId'
          });
        }

        fetchQuestion(id);
        refresh();
        notify('Misconception Tag Saved!', 'success');
      } catch (e) {
        notify(e.message, 'error');
        console.error(e);
      }
    },
    [dataProvider, notify, fetchQuestion, refresh, id]
  );

  const deleteMisconceptionTag = async misconceptionTagId => {
    try {
      await dataProvider.delete(
        'misconception-tags',
        {
          id: misconceptionTagId
        },
        'misconceptionTagId'
      );
      fetchQuestion(id);
      refresh();
      notify('Misconception Tag Deleted!', 'success');
    } catch (e) {
      notify('Error Deleting Misconception Tag!', 'error');
      console.error(e);
    }
  };

  useEffect(() => {
    const runEffect = async () => {
      if (isLoaded || !isOpen) return;
      id && (await fetchQuestion(id));
      setIsLoaded(true);
    };
    runEffect();
  }, [fetchQuestion, isLoaded, isOpen, id]);

  if (!isLoaded) {
    return null;
  }

  const mapAnswer = answer => {
    const mapper = {
      1: 'A',
      2: 'B',
      3: 'C',
      4: 'D'
    };

    return mapper[answer] || false;
  };

  let tabs = [];
  [1, 2, 3, 4].forEach(answerValue => {
    const isCorrect = question.givenAnswer === answerValue;
    const answerLetter = mapAnswer(answerValue);

    // Find existing tags for this answer~
    const misconceptionTag = question.misconceptionTags.find(
      t => t.answerValue === answerValue && t.userId === user.userId
    );

    const label = (
      <span
        className={
          classes.answerLetter +
          ' ' +
          (isCorrect ? classes.answerLetterCorrect : '')
        }>
        {answerLetter}
        {isCorrect || misconceptionTag ? <CheckIcon /> : ''}
      </span>
    );

    tabs.push({
      label,
      children: (
        <MisconceptionTaggingQuestionForm
          answerValue={answerValue}
          answerLetter={answerLetter}
          onSubmit={saveMisconceptionTag}
          onDelete={deleteMisconceptionTag}
          question={question}
          misconceptionTag={misconceptionTag}
          isCorrect={isCorrect}
        />
      )
    });
  });

  return (
    <Modal
      open={isOpen}
      onClose={() => setIsOpen()}
      className={classes.modal}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
      closeAfterTransition>
      <Container className={classes.container}>
        <Card className={classes.create}>
          {question.imageURL && (
            <img
              src={`https://images.diagnosticquestions.com${question.imageURL}`}
              alt={question.sequence}
              style={{ width: '100%' }}
            />
          )}
          <Button className={classes.close} onClick={setIsOpen}>
            <CloseIcon />
          </Button>
          <TabPanel
            className={classes.tabs}
            tabs={tabs}
            centered
            variant="standard"
          />
        </Card>
      </Container>
    </Modal>
  );
};

export default MisconceptionTaggingQuestionModal;
