import LightTooltip from 'Components/LightTooltip';
import Square from 'Components/Shapes/Square';

const fillColors = [
  'rgb(222, 80, 80)',
  'rgb(110, 194, 130)',
  'rgb(	255, 191, 0)'
];

const WorksheetQuestionProgressItem = ({
  isCorrect,
  isAnswered,
  width,
  height,
  style,
  title
}) => {
  return (
    <LightTooltip placement="top-start" title={title ?? 'Worksheet'}>
      <div>
        <Square
          width={width ?? 12}
          height={height ?? 12}
          fill={fillColors[isCorrect ? 1 : isAnswered ? 0 : 2]}
          style={style}
        />
      </div>
    </LightTooltip>
  );
};

export default WorksheetQuestionProgressItem;
