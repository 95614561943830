import ReactFlow, {
  removeElements,
  addEdge,
  MiniMap,
  Controls,
  Background
} from 'react-flow-renderer';
import {
  Button,
  Select,
  FormControl,
  InputLabel,
  MenuItem
} from '@material-ui/core';
import { useState, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';
import FlowTemplateNode from './FlowTemplateNode';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import html2canvas from 'html2canvas';
import { useNotify } from 'react-admin';
import FullPageLoader from 'Components/FullPageLoader';
import dagre from 'dagre';
import SaveFlowTemplateLayoutModal from './Modals/SaveFlowTemplateLayoutModal';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import SaveIcon from '@material-ui/icons/Save';
import { getFlowTemplateFromNode } from './flowTemplateHelpers';
import {
  useHistory,
  useParams
} from 'react-router-dom/cjs/react-router-dom.min';
import { getFlowTemplateNodeHeight } from './flowHelpers';

const FlowTemplateCanvas = ({
  templateSlug,
  flowTemplates,
  selectFlowTemplate,
  updateFlowTemplate,
  rankdir = 'TB',
  openCreateFlowTemplateModal,
  flowItemTemplateSlug
}) => {
  flowTemplates = flowTemplates?.flowTemplates || [];
  const history = useHistory();
  const { level = null } = useParams();
  const [isSaveLayoutModalOpen, setIsSaveLayoutModalOpen] = useState(false);
  const [autoLayout, setAutoLayout] = useState(false);
  const [isLayoutStale, setIsLayoutStale] = useState(false);
  const [loading, setLoading] = useState(false);
  const notify = useNotify();
  const reactFlowWrapper = useRef(null);
  const reactFlowInstanceRef = useRef(null);
  const [elements, setElements] = useState([]);
  const onElementsRemove = elementsToRemove =>
    setElements(els => removeElements(elementsToRemove, els));
  const onConnect = params => setElements(els => addEdge(params, els));
  const [selectedRootNode, setSelectedRootNode] = useState(null);

  const onLoad = reactFlowInstance => {
    setTimeout(() => reactFlowInstance.fitView(), 100);
    reactFlowInstanceRef.current = reactFlowInstance;
  };

  const mapFlowTemplatesToNodes = useCallback(
    (flowTemplates, nodeHeight = 100, rankdir = 'TB', autoLayout) => {
      if (!flowTemplates.length) return [];

      const g = new dagre.graphlib.Graph();
      g.setGraph({ rankdir });
      g.setDefaultEdgeLabel(() => ({}));

      let x = 400;
      let y = 40;

      const nodes =
        flowTemplates.map((ft, i) => {
          y = i === 0 ? y : y + nodeHeight;
          const nodeType =
            i === 0
              ? 'input'
              : i + 1 === flowTemplates.length
              ? 'output'
              : 'default';
          const calcNodeHeight = getFlowTemplateNodeHeight({
            ...ft,
            nodeHeight,
            isEndNode: i + 1 === flowTemplates.length,
            isStartNode: i === 0
          });
          const node = {
            id: `#${ft.position}>${
              ft.flowItemTemplateSlug ||
              ft.flowTemplateSlug ||
              ft.parentFlowTemplateSlug
            }`,
            type: nodeType,
            data: {
              label: (
                <FlowTemplateNode
                  {...ft}
                  nodeType={nodeType}
                  openCreateFlowTemplateModal={() =>
                    openCreateFlowTemplateModal(ft.position + 1)
                  }
                />
              )
            },
            position: { x: ft.xPosition || x, y: ft.yPosition || y },
            positionMapping: ft.positionMapping,
            skipToExpressions: ft.skipToExpressions ?? []
          };
          g.setNode(node.id, {
            width: 400,
            height: calcNodeHeight
          });
          return node;
        }) ?? [];

      const edges = [];

      nodes?.forEach((n, i) => {
        const nextNode = nodes.length > i + 1 ? nodes[i + 1] : null;

        if (!nextNode) return;

        n.skipToExpressions.forEach((s, i) => {
          const destNode =
            nodes.find(ft => ft.position === s.position) ||
            nodes.find(ft => ft.positionMapping === s.positionMapping);

          if (!destNode?.id) return;

          edges.push({
            id: `edge-${n.id}-${destNode.id}`,
            source: n.id,
            target: destNode.id,
            label: `${n.id} > ${destNode.id}${
              destNode.positionMapping ? ` ${destNode.positionMapping}` : ''
            }`,
            style:
              destNode.id === selectedRootNode
                ? { strokeWidth: '1px', stroke: 'blue' }
                : selectedRootNode === n.id
                ? { strokeWidth: '1px', stroke: 'red' }
                : {}
          });
          g.setEdge(n.id, destNode.id);
        });

        edges.push({
          id: `edge-${n.id}`,
          source: n.id,
          target: nextNode.id,
          style:
            nextNode?.id === selectedRootNode
              ? { strokeWidth: '1px', stroke: 'blue' }
              : selectedRootNode === n.id
              ? { strokeWidth: '1px', stroke: 'red' }
              : {}
        });

        g.setEdge(n.id, nextNode.id);
      });

      if (g.nodeCount && autoLayout) {
        dagre.layout(g);
        nodes.forEach(n => {
          const node = g.node(n.id);
          if (node) {
            const x = Math.round(node.x);
            const y = Math.round(node.y);
            if (!isLayoutStale && (n.position.x !== x || n.position.y !== y)) {
              setIsLayoutStale(true);
            }
            n.position = { x, y };
          }
        });
      } else {
        setIsLayoutStale(false);
      }

      return [...nodes, ...edges];
    },
    [isLayoutStale, openCreateFlowTemplateModal, selectedRootNode]
  );

  useEffect(() => {
    const runEffect = async () => {
      setElements(
        mapFlowTemplatesToNodes(flowTemplates, 100, rankdir, autoLayout)
      );
    };
    runEffect();
  }, [
    autoLayout,
    flowTemplates,
    flowTemplates.length,
    mapFlowTemplatesToNodes,
    rankdir,
    selectedRootNode
  ]);

  // https://reactflow.dev/examples/interaction/
  const onElementClick = (event, node) => {
    const flowTemplate = getFlowTemplateFromNode(node, flowTemplates);
    selectFlowTemplate(flowTemplate);
    setSelectedRootNode(node.id);
  };

  const onNodeDragStop = async (event, node) => {
    try {
      const flowTemplate = getFlowTemplateFromNode(node, flowTemplates);

      if (!flowTemplate) return;

      flowTemplate.xPosition = node.position.x;
      flowTemplate.yPosition = node.position.y;

      await updateFlowTemplate(flowTemplate, flowTemplate.position, true);
    } catch (e) {
      console.error(e);
      notify(`${e}`, 'error');
    }
  };

  const handleDownloadToPdf = async (reactFlowWrapperRef, isFrame = true) => {
    const wrapper = reactFlowWrapperRef.current;
    if (wrapper) {
      try {
        setLoading(true);
        const originalWidth = wrapper.style.width;
        const originalHeight = wrapper.style.height;

        if (!isFrame) {
          let minX = Infinity,
            minY = Infinity,
            maxX = -Infinity,
            maxY = -Infinity;

          elements.forEach(element => {
            if ('position' in element) {
              // This is a node
              const { x, y } = element.position;
              minX = Math.min(minX, x);
              minY = Math.min(minY, y);
              maxX = Math.max(maxX, x);
              maxY = Math.max(maxY, y);
            }
          });

          const width = maxX - minX;
          const height = maxY - minY;
          wrapper.style.width = `${width + 200}px`;
          wrapper.style.height = `${height + 200}px`;
        }

        const canvas = await html2canvas(wrapper);
        const imgData = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.href = imgData;
        link.download = `${templateSlug}-flowTemplates-${
          isFrame ? 'frame' : 'canvas'
        }-${new Date().toISOString()}.png`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        wrapper.style.width = originalWidth;
        wrapper.style.height = originalHeight;
      } catch (e) {
        console.error(e);
        notify(`${e}`, 'error');
      }
      setLoading(false);
    }
  };

  return (
    <>
      <Wrapper ref={reactFlowWrapper}>
        <StyledReactFlow
          onLoad={onLoad}
          elements={elements}
          snapToGrid={true}
          snapGrid={[15, 15]}
          onElementsRemove={onElementsRemove}
          onElementClick={onElementClick}
          onNodeDragStop={onNodeDragStop}
          onConnect={onConnect}>
          <Controls />
          <Background color="#aaa" gap={16} />
          <MiniMap
            nodeColor={node => {
              switch (node.type) {
                case 'input':
                  return 'rgb(0,0,255)';
                case 'default':
                  return '#00ff00';
                case 'output':
                  return 'red';
                default:
                  return '#eee';
              }
            }}
            nodeStrokeWidth={3}
          />
          <div
            style={{
              position: 'absolute',
              left: 10,
              top: 10,
              zIndex: 999,
              minWidth: '130px'
            }}>
            <FormControl style={{ width: '130px' }}>
              <InputLabel>Flow Template Level</InputLabel>
              <Select
                value={level === null ? -1 : parseInt(level, 10)}
                disabled={!flowTemplates.length}
                onChange={e =>
                  history.push(
                    `/flows/${templateSlug}${
                      e.target.value > -1 ? `/${e.target.value}` : ''
                    }`
                  )
                }
                variant="filled">
                {[
                  { value: -1, label: 'All' },
                  { value: 0, label: 'Parents' },
                  { value: 1, label: 'Children' }
                ]
                  .filter(
                    l =>
                      l.value <=
                        Math.max(
                          ...[...new Set(flowTemplates.map((f, i) => f.level))]
                        ) || l.value === -1
                  )
                  .map((l, i) => (
                    <MenuItem key={i} value={l.value}>
                      {l.label}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl style={{ width: '200px', margin: '0 0 0 0.5rem' }}>
              <InputLabel>Flow Item Templates</InputLabel>
              <Select
                value={flowItemTemplateSlug}
                disabled={!flowTemplates?.find(ft => !!ft.flowItemTemplateSlug)}
                onChange={e =>
                  history.push(`/flows/${templateSlug}/0/${e.target.value}`)
                }
                variant="filled">
                {flowTemplates
                  .filter(ft => !!ft.flowItemTemplateSlug)
                  .filter(
                    (ft, i, self) =>
                      self.findIndex(
                        t => t.flowItemTemplateSlug === ft.flowItemTemplateSlug
                      ) === i
                  )
                  .map((ft, i) => (
                    <MenuItem key={i} value={ft.flowItemTemplateSlug}>
                      {ft.flowItemTemplateSlug}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
          <div
            style={{ position: 'absolute', right: 220, top: 10, zIndex: 999 }}>
            <Button
              variant="contained"
              color="default"
              disabled={!flowTemplates.length}
              style={{ backgroundColor: autoLayout ? '#90EE90' : '' }}
              onClick={() => {
                setElements(
                  mapFlowTemplatesToNodes(
                    flowTemplates,
                    100,
                    rankdir,
                    !autoLayout
                  )
                );
                setAutoLayout(!autoLayout);
              }}
              startIcon={<DeviceHubIcon />}>
              Auto Layout
            </Button>
          </div>
          <div
            style={{ position: 'absolute', right: 120, top: 10, zIndex: 999 }}>
            <Button
              variant="contained"
              color="default"
              disabled={!isLayoutStale || !flowTemplates.length}
              onClick={() => setIsSaveLayoutModalOpen(true)}
              startIcon={<SaveIcon />}>
              Save
            </Button>
          </div>
          <div
            style={{ position: 'absolute', right: 10, top: 10, zIndex: 999 }}>
            <Button
              variant="contained"
              color="default"
              disabled={!flowTemplates.length}
              onClick={() => {
                setLoading(true);
                handleDownloadToPdf(reactFlowWrapper, true);
                setLoading(false);
              }}
              startIcon={<PhotoLibraryIcon />}>
              Frame
            </Button>
          </div>
        </StyledReactFlow>
      </Wrapper>
      <FullPageLoader loading={loading} />
      {flowTemplates.length && (
        <SaveFlowTemplateLayoutModal
          isModalOpen={isSaveLayoutModalOpen}
          setIsModalOpen={setIsSaveLayoutModalOpen}
          templateSlug={templateSlug}
          flowTemplates={flowTemplates || []}
          nodes={elements.filter(e => 'position' in e)}
          updateFlowTemplate={updateFlowTemplate}
        />
      )}
    </>
  );
};

/**
 * https://reactflow.dev/docs/theming/
 */
const StyledReactFlow = styled(ReactFlow)`
  .react-flow__node {
    border-radius: 8px;
    min-width: 400px;
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.3);
    border: 2px solid #999;
  }
  .react-flow__node:hover {
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.3);
  }
  .selected {
    border: 2px solid #555;
  }
  height: 100%;
  width: 100%;
`;

const Wrapper = styled.div`
  height: 100%;
  z-index: 998;
`;

export default FlowTemplateCanvas;
