import {
  Button,
  FormLabel,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio
} from '@material-ui/core';
import WizardIcon from '@material-ui/icons/Casino';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useState } from 'react';

const useStyles = makeStyles(theme => ({
  input: {
    margin: '1rem 0 0 0'
  },
  radio: {
    display: 'flex',
    flexDirection: 'row'
  }
}));

const Step2 = ({ onComplete }) => {
  const classes = useStyles();
  const [value, setValue] = useState(null);

  const handleChange = event => {
    console.log(event.target.value);
    setValue(event.target.value);
  };

  return (
    <>
      <FormControl component="fieldset">
        <FormLabel component="legend">Confidence</FormLabel>
        <RadioGroup
          aria-label="confidence"
          name="confidence"
          className={classes.radio}
          value={value}
          onChange={handleChange}>
          <FormControlLabel value="0" control={<Radio />} label="0%" />
          <FormControlLabel value="25" control={<Radio />} label="25%" />
          <FormControlLabel value="50" control={<Radio />} label="50%" />
          <FormControlLabel value="75" control={<Radio />} label="75%" />
          <FormControlLabel value="100" control={<Radio />} label="100%" />
        </RadioGroup>
      </FormControl>

      <Button
        variant="contained"
        color="primary"
        size="large"
        type="submit"
        className={classes.createButton}
        startIcon={<WizardIcon />}
        disabled={value === null || undefined}
        onClick={() => onComplete({ confidence: parseInt(value) })}>
        Create
      </Button>
    </>
  );
};

export default Step2;
