import { makeStyles, Paper, Typography, Button } from '@material-ui/core';
import styled from 'styled-components';
import TabPanel from 'Components/TabPanel';
import CheckIcon from '@material-ui/icons/Check';
import { useCallback, useEffect, useState } from 'react';
import useGetAuthUser from 'Hooks/useGetAuthUser';
import LoadingModal from 'Pages/QuestionExtractor/Components/LoadingModal';
import { Empty, useDataProvider, useNotify } from 'react-admin';
import MisconceptionTagEditForm from './Modal/MisconceptionTagEditForm';
import { useParams } from 'react-router';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '1rem'
  },
  tabs: {
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  form: {
    borderTop: '1px solid #ccc',
    marginTop: '1rem',
    paddingTop: '1rem'
  },
  reviewStatus: {
    marginTop: '1rem'
  },
  buttons: {
    marginTop: '1rem'
  },
  createButton: {
    marginRight: '1rem'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0 0 0.5rem 0'
  },
  container: {
    display: 'flex',
    padding: '0 0 1rem 0',
    flexDirection: 'row'
  },
  containerLeft: {
    width: '50%',
    padding: '0 0.75rem 0 0'
  },
  containerRight: {
    width: '50%'
  },
  answerLetter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '1.5rem',
    fontWeight: 600,
    width: '3.5rem',
    borderRadius: '0.5rem',
    backgroundColor: '#F53838',
    color: '#fff'
  },
  answerLetterCorrect: {
    backgroundColor: '#09C18A'
  },
  explanation: {
    marginBottom: '1rem',
    backgroundColor: '#FAF3D3',
    borderRadius: '0.5rem',
    padding: '1rem'
  },
  headerButton: {
    borderRadius: '30px',
    padding: '3px'
  }
}));

const mapAnswer = answer => {
  const mapper = {
    1: 'A',
    2: 'B',
    3: 'C',
    4: 'D'
  };

  return mapper[answer] || false;
};

const fetchTabs = (question, userId = null, classes = {}) =>
  [1, 2, 3, 4].map(answerValue => {
    const isCorrect = question.givenAnswer === answerValue;
    const answerLetter = mapAnswer(answerValue);

    // Find existing tags for this answer~
    const misconceptionTag = question.misconceptionTags?.find(
      t => t.answerValue === answerValue && t.userId === userId
    );

    const label = (
      <span
        className={
          classes.answerLetter +
          ' ' +
          (isCorrect ? classes.answerLetterCorrect : '')
        }>
        {answerLetter}
        {isCorrect || misconceptionTag ? <CheckIcon /> : ''}
      </span>
    );

    return {
      label,
      value: answerValue,
      children: (
        <div className={classes.explanation}>
          <Typography>
            <strong>Explanation {answerLetter}: </strong>
            {question[`explanation${answerLetter}`]}
          </Typography>
        </div>
      )
    };
  });

export const MisconceptionTagPage = () => {
  const { id } = useParams();

  if (!id) return <Empty />;

  return <MisconceptionTag record={{ id }} />;
};

export const MisconceptionTag = ({ record }) => {
  const dataProvider = useDataProvider();
  const user = useGetAuthUser();
  const userId = user?.userId;
  const classes = useStyles();
  const notify = useNotify();
  const [question, setQuestion] = useState(record);
  const [isLoaded, setIsLoaded] = useState(false);
  const defaultCurrentAnswerValue = [1, 2, 3, 4].find(
    d => d !== record?.givenAnswer
  );
  const [currentAnswerValue, setCurrentAnswerValue] = useState(
    defaultCurrentAnswerValue
  );
  const [isSaving, setIsSaving] = useState(false);

  const fetchQuestion = useCallback(
    async id => {
      try {
        if (!id) return;

        const result = await dataProvider.getOne(
          'topic-pathway-quiz-question-insights',
          {
            id
          }
        );

        if (!result?.data)
          throw new Error(`Unable to fetch flow question ${id}`);

        setQuestion(() => result.data);
      } catch (e) {
        notify(`${e}`, 'error');
        console.error(e);
      }
    },
    [dataProvider, notify]
  );

  const deleteMisconceptionTag = useCallback(
    async misconceptionTagId => {
      try {
        await dataProvider.delete(
          'misconception-tags',
          {
            id: misconceptionTagId
          },
          'misconceptionTagId'
        );
        await fetchQuestion(record.id);
        notify('Misconception Tag Deleted!', 'success');
      } catch (e) {
        notify('Error Deleting Misconception Tag!', 'error');
        console.error(e);
      }
    },
    [dataProvider, fetchQuestion, notify, record?.id]
  );

  const saveMisconceptionTag = useCallback(
    async values => {
      if (isSaving) return;
      setIsSaving(true);

      try {
        if (!values.flagged) {
          values.misconceptionStatus = null;
          values.comments = null;
        }

        values = {
          ...values,
          ...values.embedding,
          answerValue: currentAnswerValue
        };

        if (!values.searchText) {
          values.searchText = null;
          values.embeddingOutputId = null;
        }

        if (
          !values.similarDistractorQuestionId ||
          !values.similarDistractorAnswerValue
        ) {
          values.similarDistractorQuestionId = null;
          values.similarDistractorAnswerValue = null;
        }

        if (
          !values.searchText &&
          !values.misconceptionStatus &&
          !values.comments
        )
          throw new Error('Please enter a tag or a flag', 'error');

        if (values.id) {
          await dataProvider.update('misconception-tags', {
            id: values.id,
            body: values,
            idAlias: 'misconceptionTagId'
          });
        } else {
          await dataProvider.create('misconception-tags', {
            body: values,
            idAlias: 'misconceptionTagId'
          });
        }

        await fetchQuestion(record.id);
        notify('Misconception Tag Saved!', 'success');
      } catch (e) {
        notify(`${e}`, 'error');
        console.error(e);
      }
      setIsSaving(false);
    },
    [
      currentAnswerValue,
      dataProvider,
      fetchQuestion,
      isSaving,
      notify,
      record?.id
    ]
  );

  useEffect(() => {
    const runEffect = async () => {
      if (isLoaded || !record?.id) return;

      await fetchQuestion(record.id);
      setIsLoaded(true);
    };
    runEffect();
  }, [fetchQuestion, isLoaded, record?.id]);

  if (!question) return <Empty />;

  if (!isLoaded) return <LoadingModal title="Loading..." />;

  const tabs = fetchTabs(question, userId, classes);

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div>
          <strong>Misconception Tagging - </strong>QuestionId:{' '}
          <a
            href={`/flow-questions/edit/${question.primaryQuestionId}_${question.questionId}?displayedFilters=%7B%7D&filter=%7B"q"%3A"${question.questionId}"%7D&order=ASC&page=1&perPage=20&sort=id`}
            target="_blank">
            {question.questionId}
          </a>
        </div>
        <Button
          className={classes.headerButton}
          variant="outlined"
          color="secondary"
          type="button"
          size="small"
          onClick={() => {
            window.open(
              `https://eedi.notion.site/Misconception-Tagging-Guidance-49ff5dc9478e47c3b24fa034f99eada0`,
              '_blank'
            );
          }}>
          ?
        </Button>
      </div>
      <div className={classes.container}>
        <div className={classes.containerLeft}>
          <ImageWrapper>
            <Image>
              <img
                src={`https://images.diagnosticquestions.com${question.imageURL}`}
                alt={question.constuct}
              />
            </Image>
            <StyledTabPanel
              padding={'1rem 0 0 0'}
              className={classes.tabs}
              tabs={tabs}
              defaultTab={defaultCurrentAnswerValue - 1}
              onChange={value => setCurrentAnswerValue(value + 1)}
              centered
              variant="standard"
            />
            <QuestionInfo>
              <div>
                <div className="info">
                  <strong>ConstructId:</strong>
                  <a
                    href={`/worksheet-questions?filter=%7B%22q%22%3A${question.constructId}%7D`}
                    target="_blank"
                    rel="noreferrer">
                    {question.constructId}
                  </a>
                </div>
                <div style={{ fontStyle: 'italic' }}>{question.construct}</div>
                <hr style={{ width: '100%' }} />
                <div className="info">
                  <strong>Misconception tag count (this question):</strong>
                  {question.misconceptionTagCount}
                </div>
              </div>
            </QuestionInfo>
          </ImageWrapper>
        </div>
        <div className={classes.containerRight}>
          <MisconceptionTagEditForm
            question={question}
            currentAnswerValue={currentAnswerValue}
            onSubmit={saveMisconceptionTag}
            onDelete={deleteMisconceptionTag}
            misconceptionTag={
              question?.misconceptionTags?.length > 0
                ? question?.misconceptionTags?.find(
                    m =>
                      m.answerValue === currentAnswerValue &&
                      m.userId === userId
                  )
                : {}
            }
            data={question}
          />
        </div>
      </div>
    </div>
  );
};

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  img {
    width: 100%;
    height: auto;
    max-width: 640px;
  }
`;

const Image = styled.div`
  display: flex;
  justify-content: center;
`;

const QuestionInfo = styled.div`
  width: 100%;
  background: #ddd;
  border-radius: 6px;
  margin-top: 0;

  > div {
    display: flex;
    flex-direction: column;
    padding: 1rem;
  }

  strong {
    margin: 0 0.5rem 0 0;
  }

  .info {
    display: flex;
    justify-content: space-between;
  }
`;

const StyledTabPanel = styled(TabPanel)``;
