import React from 'react';
import { CircularProgress, Radio, FormControlLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Api from 'Api/Api';
import useApiRequest from 'Hooks/useApiRequest';

const useStyles = makeStyles(theme => ({
  suggestedTags: {
    maxHeight: '100px',
    border: '1px solid #eee',
    padding: '0.5rem 1rem',
    overflow: 'scroll',
    marginBottom: '1rem',
    borderRadius: '4px'
  },
  radio: {
    padding: '2px'
  }
}));

const MisconceptionTagSuggested = ({
  question,
  answerValue,
  value,
  onChange,
  ...props
}) => {
  const classes = useStyles();
  const { loading, data: suggestedTags } = useApiRequest(() =>
    Api.request(
      'GET',
      `v4/admin/questions/${question.questionId}/answer-values/${answerValue}/similar-distractors`
    )
  );

  if (loading) {
    return (
      <div className={classes.suggestedTags}>
        <CircularProgress size={20} />
      </div>
    );
  }

  const deduplicatedSuggestedTags = [
    ...new Map(
      suggestedTags.map(item => [item['embeddingOutputId'], item])
    ).values()
  ];

  return (
    <div className={classes.suggestedTags}>
      {deduplicatedSuggestedTags.length
        ? deduplicatedSuggestedTags.map(tag => (
            <div>
              <FormControlLabel
                control={<Radio className={classes.radio} />}
                label={tag.searchText}
                onChange={() => onChange(tag)}
                name="embedding-radio"
                value={tag.embeddingOutputId}
                checked={tag.embeddingOutputId === value.embeddingOutputId}
              />
            </div>
          ))
        : 'No suggested tags available! Sorry about that.'}
    </div>
  );
};

export default MisconceptionTagSuggested;
