import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Grid
} from '@material-ui/core';
import moment from 'moment';
import RequestEntry from './RequestEntry';
import AddExistingSchoolCard from './AddExistingSchoolCard';
import AddNewSchoolCard from './AddNewSchoolCard';

const RequestAdminForm = ({ studentId, data }) => {
  const timeStamp = moment(data.timestamp).format('YYYY-MM-DD HH:mm');

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start">
      <Card variant="outlined">
        <CardHeader title={timeStamp} />
        <CardContent>
          <Typography className={data.name}></Typography>
          <RequestEntry studentId={studentId} entry={data} />
        </CardContent>
      </Card>
      <AddExistingSchoolCard style={{ width: '35%' }} studentId={studentId} />
      <AddNewSchoolCard style={{ width: '35%' }} studentId={studentId} />
    </Grid>
  );
};

export default RequestAdminForm;
