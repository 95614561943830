import { ListContextProvider, useListController } from 'react-admin';

const ListBase = ({ children, ...props }) => {
  const listController = useListController(props);
  return (
    <ListContextProvider value={listController}>{children}</ListContextProvider>
  );
};

export default ListBase;
