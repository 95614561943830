/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback } from 'react';

const useApiRequest = (apiRequest, isRequestable = true) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  const apiCallback = useCallback(async (updatedApiRequest = null) => {
    if (!loading) return;
    try {
      const fetchedData = !!updatedApiRequest
        ? await updatedApiRequest()
        : await apiRequest();
      setData(fetchedData);
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    const runEffect = async () => {
      loading && isRequestable && (await apiCallback());
    };
    runEffect();
  }, []);

  const reload = async updatedApiRequest => {
    setLoading(true);
    await apiCallback(updatedApiRequest);
  };

  return {
    data,
    loading,
    reload,
    setData
  };
};

export default useApiRequest;
