import { useState } from 'react';
import useGetAuthUser from 'Hooks/useGetAuthUser';
import { useSignalROn } from 'Hooks/useSignalROn';

const useIsTyping = (flowGeneratorSessionId, delay = 5000) => {
  const [typingStates, setIsTypingStates] = useState([]);
  const [typingTimeouts, setIsTypingTimeouts] = useState([]);
  const user = useGetAuthUser();

  const filterTypingState = isTypingMessage =>
    typingStates?.filter(tm => tm.userId !== isTypingMessage.userId) ?? [];

  const processIsTyping = isTypingMessage => {
    if (
      !isTypingMessage?.userId ||
      !user?.userId ||
      !isTypingMessage?.flowGeneratorSessionId !== flowGeneratorSessionId
    )
      return;

    isTypingMessage.isCurrentUser = user.userId === isTypingMessage.userId;

    // Update typing state
    const updatedTypingStates = filterTypingState(isTypingMessage);
    updatedTypingStates.push(isTypingMessage);
    setIsTypingStates(updatedTypingStates);

    // Mange timeouts
    const timeout = typingTimeouts.find(
      tt => tt.userId === isTypingMessage.userId
    );
    const updatedTypingTimeouts = typingTimeouts.filter(
      tt => tt.userId !== isTypingMessage.userId
    );
    if (timeout) {
      clearTimeout(timeout.typingTimeout);
    }
    const typingTimeout = setTimeout(
      () => setIsTypingStates(filterTypingState(isTypingMessage)),
      delay
    );
    updatedTypingTimeouts.push({
      userId: isTypingMessage.userId,
      typingTimeout
    });
    setIsTypingTimeouts(updatedTypingTimeouts);
  };

  useSignalROn(`DispatchIsTyping:${flowGeneratorSessionId}`, isTypingMessage =>
    processIsTyping(isTypingMessage)
  );

  return typingStates;
};

export default useIsTyping;
