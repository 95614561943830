import { Route } from 'react-router-dom';
import Dashboard from 'Pages/Dashboard/Dashboard';
import TutorRotaPage from 'Pages/TutorRota/TutorRotaPage';
import FlowTemplatePage from 'Pages/Flows/FlowTemplatePage';
import MisconceptionTagsList from 'Pages/MisconceptionTags/MisconceptionTagsList';
import PromptList from 'Pages/Conversations/PromptList';
import AwardsShow from 'Pages/Awards/AwardsShow';
import NewFlowPage from 'Pages/Flows/NewFlowPage';
import LessonDashboardPage from 'Pages/LessonDashboard/LessonDashboardPage';
import ApproveTeacherShow from 'Pages/ApproveTeacher/ApproveTeacherShow';
import RecommendationPage from 'Pages/Recommendation/RecommendationPage';
import ExperimentPage from 'Pages/Experiment/ExperimentPage';
import WhiteboardPage from 'Pages/Whiteboard/WhiteboardPage';

const customRoutes = [
  <Route exact path="/" component={LessonDashboardPage} />,
  <Route
    exact
    path="/flow-generator-sessions/:flowGeneratorSessionId?/show"
    component={Dashboard}
  />,
  <Route exact path="/tutor-rota" component={TutorRotaPage} />,
  <Route
    path="/flows/:templateSlug?/:level?/:flowItemTemplateSlug?"
    component={FlowTemplatePage}
  />,
  <Route exact path="/create-flow" component={NewFlowPage} />,
  <Route
    exact
    path="/misconception-tags/list"
    component={MisconceptionTagsList}
  />,
  <Route exact path="/awards" component={AwardsShow} />,
  <Route path="/prompts" component={PromptList} />,
  <Route exact path="/lessons" component={Dashboard} />,
  <Route exact path="/approve-teacher" component={ApproveTeacherShow} />,
  <Route path="/recommendation" component={RecommendationPage} />,
  <Route path="/experiment" component={ExperimentPage} />,
  <Route path="/whiteboard/:sessionId?/:inputId?" component={WhiteboardPage} />
];
export default customRoutes;
