import TextFlowItem from './TextFlowItem';
import ImageFlowItem from './ImageFlowItem';
import VideoFlowItem from './VideoFlowItem';
import FileFlowItem from './FileFlowItem';
import InformationFlowItem from './InformationFlowItem';

export const FlowLessonType = {
  ChallengeWorksheet: 'ChallengeWorksheet',
  TopicPathwayQuiz: 'TopicPathwayQuiz',
  Generic: 'Generic',
  ChatWithTutor: 'ChatWithTutor',
  TopicPathwayQuizQuestion: 'TopicPathwayQuizQuestion',
  QuestionOfTheDay: 'QuestionOfTheDay',
  OnDemand: 'OnDemand',
  RetrievalPracticeTopic: 'RetrievalPracticeTopic'
};

export const FlowItemType = {
  Default: 'Default',
  Validator: 'Validator',
  Text: 'Text',
  Image: 'Image',
  Video: 'Video',
  RadioButtonInput: 'RadioButtonInput',
  TextInput: 'TextInput',
  Divider: 'Divider',
  PinnedImage: 'PinnedImage',
  PinnedVideo: 'PinnedVideo',
  DiagnosticQuestionAnswerInput: 'DiagnosticQuestionAnswerInput',
  DiagnosticQuestionRetryInput: 'DiagnosticQuestionRetryInput',
  HighlightedText: 'HighlightedText',
  File: 'File',
  PinnedText: 'PinnedText',
  Information: 'Information',
  UploadInput: 'UploadInput',
  TextInputExplanation: 'TextInputExplanation'
};

export const FlowItemTypes = Object.values(FlowItemType);

export const ContentType = {
  Default: 'Default',
  CheckInQuestionAnswer: 'CheckInQuestionAnswer',
  CheckInQuestionRetryAnswer: 'CheckInQuestionRetryAnswer',
  CheckOutQuestionAnswer: 'CheckOutQuestionAnswer',
  CheckOutQuestionRetryAnswer: 'CheckOutQuestionRetryAnswer',
  WorksheetQuestionAnswer: 'WorksheetQuestionAnswer',
  FluencyPracticeAnswer: 'FluencyPracticeAnswer',
  IntelligentPracticeAnswer: 'IntelligentPracticeAnswer',
  StretchAndChallengeAnswer: 'StretchAndChallengeAnswer',
  Video: 'Video',
  WorksheetQuestionAnswerCorrect: 'WorksheetQuestionAnswerCorrect',
  FluencyPracticeAnswerCorrect: 'FluencyPracticeAnswerCorrect',
  IntelligentPracticeAnswerCorrect: 'IntelligentPracticeAnswerCorrect',
  StretchAndChallengeAnswerCorrect: 'StretchAndChallengeAnswerCorrect'
};

export const ContentTypes = Object.values(ContentType);

export const InputFlowItemTypes = [
  FlowItemType.RadioButtonInput,
  FlowItemType.TextInput,
  FlowItemType.DiagnosticQuestionAnswerInput,
  FlowItemType.DiagnosticQuestionRetryInput,
  FlowItemType.UploadInput,
  FlowItemType.TextInputExplanation
];

export const FlowItems = {
  TextFlowItem,
  ImageFlowItem,
  VideoFlowItem,
  FileFlowItem
};

export const FlowItem = ({ props }) => {
  if (!props) return;

  props.text = props.text?.replaceAll('\\n', '\n') ?? null;

  switch (props.flowItemType) {
    case FlowItemType.Video:
    case FlowItemType.PinnedVideo:
      return <VideoFlowItem props={props} />;
    case FlowItemType.Image:
    case FlowItemType.PinnedImage:
      return <ImageFlowItem props={props} />;
    case FlowItemType.PinnedText:
    case FlowItemType.Text:
    case FlowItemType.HighlightedText:
    case FlowItemType.RadioButtonInput:
    case FlowItemType.TextInput:
    case FlowItemType.TextInputExplanation:
    case FlowItemType.DiagnosticQuestionAnswerInput:
    case FlowItemType.DiagnosticQuestionRetryInput:
    case FlowItemType.Default:
      return <TextFlowItem props={props} />;
    case FlowItemType.File:
      return <FileFlowItem props={props} />;
    case FlowItemType.Information:
      return <InformationFlowItem props={props} />;
    default:
      return null;
  }
};
