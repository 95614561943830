import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { useState } from 'react';

const TabPanelContainer = props => {
  const { children, value, index, padding = 10, ...other } = props;
  return (
    <div
      key={index}
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}>
      {value === index && <Box style={{ padding }}>{children}</Box>}
    </div>
  );
};

TabPanelContainer.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

const a11yProps = index => {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`
  };
};

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper
  }
}));

const TabPanel = ({
  tabs,
  defaultTab = 0,
  padding = 10,
  onChange = () => {},
  ...props
}) => {
  const classes = useStyles();
  const [value, setValue] = useState(defaultTab);

  const handleChange = (event, newValue) => {
    onChange(newValue);
    setValue(newValue);
  };
  let tabCounter = 0;
  let tabContentCounter = 0;

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default" elevation={0}>
        <StyledTabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          {...props}>
          {tabs.map((t, i) => {
            return (
              <Tab
                label={t.label}
                disabled={t.disabled}
                {...a11yProps(tabCounter++)}
                key={i}
              />
            );
          })}
        </StyledTabs>
      </AppBar>
      {tabs.map((t, i) => {
        return (
          <TabPanelContainer
            padding={padding}
            value={value}
            index={tabContentCounter++}
            key={i}>
            {t.children}
          </TabPanelContainer>
        );
      })}
    </div>
  );
};

const StyledTabs = styled(Tabs)`
  .MuiTab-root {
    min-width: 50px !important;
  }
`;

export default TabPanel;
