import * as React from 'react';
import { useRecordContext } from 'react-admin';
import { FormControlLabel, Checkbox } from '@material-ui/core';

const CheckboxFieldComponent = props => {
  const record = useRecordContext(props);
  const isSelected = props.isSelected(record);
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={isSelected}
          onChange={() => props.onChange(record)}
          name="isSelected"
          color="primary"
        />
      }
    />
  );
};

export default CheckboxFieldComponent;
