import LessonCard from 'Components/Lessons/LessonCard';
import useInterval from 'Hooks/useInterval';
import { useSignalROn } from 'Hooks/useSignalROn';
import { useEffect, useState } from 'react';
import { useListContext } from 'react-admin';
import { useParams } from 'react-router';
import HelpSound from 'Assets/helprequest.mp3';
import { lessonTypeMapping } from '../Helpers/lessonHelpers';

const LessonDataGrid = () => {
  let { flowGeneratorSessionId } = useParams();
  flowGeneratorSessionId = parseInt(flowGeneratorSessionId, 10);
  let { loading, refetch, page, data, ids, filterValues } = useListContext();
  const [listData, setListData] = useState(
    ids?.length ? Object.keys(data).map(k => data[k]) : []
  );

  const sortList = data =>
    data.sort((a, b) => {
      if (new Date(a.dateHelpRequested) < new Date(b.dateHelpRequested))
        return -1;
      if (!!a.dateHelpRequested) return -1;
      if (new Date(a.dateCreated) > new Date(b.dateCreated)) return -1;
      return 0;
    });

  const processFlowSession = flowSession => {
    if (!flowSession?.flowGeneratorSessionId) return;

    const lessonIndex = Object.values(listData)
      .map(l => l.flowGeneratorSessionId)
      .indexOf(flowSession.flowGeneratorSessionId);

    if (lessonIndex === -1 && page === 1) {
      // logic to add new lesson to list

      if (
        !!flowSession.dateCompleted &&
        filterValues['dateCompleted']?.id === 'isNotCompleted'
      )
        return;

      if (
        !flowSession.dateCompleted &&
        filterValues['dateCompleted']?.id === 'isCompleted'
      )
        return;

      if (
        filterValues['lessonType'] &&
        lessonTypeMapping[filterValues['lessonType']?.Value] !==
          flowSession.lessonType
      )
        return;

      if (
        !flowSession.dateHelpRequested &&
        !flowSession.isHelpRequested &&
        filterValues['isHelpRequested']?.Value === 'true'
      )
        return;

      if (
        !flowSession.tutorId &&
        filterValues['isActiveIntervention']?.Value === 'true'
      )
        return;

      if (flowSession.dateHelpRequested) {
        new Audio(HelpSound)
          .play()
          .then(r => {})
          .catch(e => console.warn(e));
      }

      listData.push({
        id: flowGeneratorSessionId,
        ...flowSession,
        flowGeneratorSession: flowSession
      });

      setListData(sortList(listData));

      return;
    }

    let isHelpRequestChanged = false;
    let isTutorChanged = false;
    let isHelpRequested = false;

    const listDataCopy = Object.values(listData).map(l => {
      if (l?.flowGeneratorSessionId === flowSession?.flowGeneratorSessionId) {
        let flowItemGroupInputs = [
          ...l.flowGeneratorSession.flowItemGroupInputs
        ];

        flowSession.flowItemGroupInputs.forEach(fi => {
          flowItemGroupInputs = flowItemGroupInputs.filter(
            fio => fio.flowItemGroup !== fi.flowItemGroup
          );
          flowItemGroupInputs.push(fi);
        });

        if (l.tutorUserId === 0) l.tutorUserId = null;

        isHelpRequestChanged =
          flowSession.dateHelpRequested !== l.dateHelpRequested;
        isTutorChanged = flowSession.tutorUserId !== l.tutorUserId;

        isHelpRequested =
          isHelpRequested ||
          (!!flowSession.dateHelpRequested && !l.dateHelpRequested);

        l.tutorUserId = flowSession.tutorUserId;
        l.dateHelpRequested = flowSession.dateHelpRequested;

        l.flowGeneratorSession = {
          ...l.flowGeneratorSession,
          dateHelpRequested: flowSession.dateHelpRequested,
          tutorId: flowSession.tutorId,
          flowItemGroupInputs: flowItemGroupInputs.sort(
            (a, b) => new Date(a?.dateCreated) - new Date(b?.dateCreated)
          )
        };
      }
      return l;
    });

    if (isHelpRequestChanged || isTutorChanged) {
      isHelpRequested &&
        new Audio(HelpSound)
          .play()
          .then(r => {})
          .catch(e => console.warn(e));
    }

    setListData(sortList(listDataCopy));
  };

  useSignalROn('DispatchFlowGeneratorSession', flowSession =>
    processFlowSession(flowSession)
  );

  useInterval(refetch, 60000);

  useEffect(() => {
    if (!data) return;
    setListData(
      ids?.length ? sortList(Object.keys(data).map(k => data[k])) : []
    );
  }, [data, ids?.length]);

  return (
    <div>
      {listData.map((l, i) => (
        <LessonCard
          key={`${l.id}-${i}`}
          props={{ ...l, flowGeneratorSessionId, loading }}
        />
      ))}
    </div>
  );
};

export default LessonDataGrid;
