import { Typography, CardContent, Paper, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from 'Components/Avatar';
import useGetAuthUser from 'Hooks/useGetAuthUser';
import FeaturedVideoIcon from '@material-ui/icons/FeaturedVideo';
import FormattedTextContent from './FormattedTextContent';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: props =>
      props.isBot || props.isCurrentTutor ? 'row' : 'row-reverse'
  },
  bubble: {
    flex: 1
  },
  paper: {
    borderRadius: '1.5rem',
    backgroundColor: props =>
      props.isBot ? '#4353ff' : props.isCurrentTutor ? '#b3ffec' : '#fff',
    color: props => (props.isBot ? '#fff' : 'currentColor'),
    textAlign: props =>
      props.isBot || props.isCurrentTutor ? 'left' : 'right',
    border: props => (props.isPinned ? '1px solid #595959' : '')
  },
  pinnedHeader: {
    padding: '0.5rem 0.5rem 0.5rem 1rem',
    margin: '0',
    borderBottom: '1px solid black'
  },
  pinnedIcon: {
    height: '1em',
    width: '1em',
    top: '.125em',
    position: 'relative',
    fontSize: '1rem',
    float: 'right'
  },
  avatar: {
    margin: '12px',
    width: '24px',
    height: '24px',
    flex: '0 0 24px'
  },
  header: {
    fontSize: '12px',
    padding: '16px 16px 0 16px'
  },
  time: {
    margin: '5px 0 0 0',
    textAlign: 'right',
    fontSize: '11px',
    opacity: '0.75'
  },
  content: {
    padding: '16px !important'
  },
  nameBlock: {
    padding: '0.5rem 1rem 0 1rem',
    margin: '0 0 -7px',
    fontWeight: 'bold',
    fontSize: '12px',
    textAlign: 'left'
  }
}));

const TextFlowItem = ({ props }) => {
  const user = useGetAuthUser();
  const {
    text,
    userId,
    avatarColor,
    dateDispatched,
    flowItemType,
    isCurrentlyPinned,
    tutorName,
    studentName,
    isShowName,
    isTutor
  } = props;
  const isPinned = flowItemType.toLowerCase().includes('pinned');
  const isBot = userId === 0;
  const isCurrentTutor = user?.userId === userId;
  const name = isTutor || isBot ? tutorName : studentName;
  const styleProps = {
    backgroundColor: avatarColor ?? 'rgb(54, 171, 255)',
    isBot,
    isCurrentTutor,
    isPinned: props.flowItemType.toLowerCase().includes('pinned')
  };
  const classes = useStyles(styleProps);

  if (!text) return null;

  return (
    <Box className={classes.root}>
      {isBot ? (
        <img src="/assets/face.png" alt="Eedi" className={classes.avatar} />
      ) : (
        <Avatar name={`${userId}`} size={24} className={classes.avatar} />
      )}
      <Box>
        <Paper elevation={1} className={classes.paper}>
          {isPinned && (
            <h5 className={classes.pinnedHeader}>
              {name && isShowName ? `${name} - ` : ''}
              {isCurrentlyPinned ? 'Currently Pinned' : 'Pinned'}
              <FeaturedVideoIcon className={classes.pinnedIcon} />
            </h5>
          )}
          {!isPinned && name && isShowName && (
            <Box className={classes.nameBlock}>{name}</Box>
          )}
          <CardContent className={classes.content}>
            <Typography variant="body2" component="span">
              <FormattedTextContent content={text} />
            </Typography>
          </CardContent>
        </Paper>
        {dateDispatched && (
          <Typography variant="body2" className={classes.time}>
            {dateDispatched}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default TextFlowItem;
