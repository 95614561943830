import styled from 'styled-components';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Accordion from '@material-ui/core/Accordion';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { useState } from 'react';

const SessionRow = ({ session }) => {
  const {
    firstName,
    lastName,
    lessonType,
    flowGeneratorSessionId,
    tutorUsername,
    tutorUserId
  } = session;
  return (
    <TutorRow>
      {firstName} {lastName} - {lessonType}
      {tutorUsername ?? tutorUserId
        ? ` - Tutor: ${tutorUsername ?? tutorUserId}`
        : ''}
      <PreviewButton
        onClick={() =>
          window.open(
            `/flow-generator-sessions/${flowGeneratorSessionId}/show`,
            '_blank',
            'noopener,noreferrer'
          )
        }>
        Preview <OpenInNewIcon />
      </PreviewButton>
    </TutorRow>
  );
};

const TutorClaimedRow = ({ tutor }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { sessions, tutorUserId, tutorUsername } = tutor;

  if (!sessions?.length) return null;

  const handleChange = panel => (_, isExpanded) => {
    setIsExpanded(isExpanded ? panel : false);
  };

  const panelName = `panel${tutorUserId}`;
  return (
    <TutorWrapper>
      <StyledAccordion
        expanded={isExpanded === panelName}
        onChange={handleChange(panelName)}>
        <StyledAccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${panelName}bh-content`}
          id={`${panelName}bh-header`}>
          <h4>
            Tutor: {tutorUsername ?? tutorUserId} ({sessions.length})
          </h4>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          {sessions.map((session, i) => (
            <SessionRow key={i} session={session} />
          ))}
        </StyledAccordionDetails>
      </StyledAccordion>
    </TutorWrapper>
  );
};

const TutorWrapper = styled.div`
  width: 100%;
  margin: 0 0 0.25rem 0;
`;

const StyledAccordion = styled(Accordion)`
  background: #555;
  color: white;
  padding: 0.1rem;
  margin: 0;
  .MuiAccordionSummary-content {
    margin: 0;
  }
`;
const StyledAccordionSummary = styled(AccordionSummary)`
  margin: 0;
`;
const StyledAccordionDetails = styled(AccordionDetails)`
  display: flex;
  flex-direction: column;
  background: #666;
`;

const TutorRow = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 20px 10px 20px;
`;

const PreviewButton = styled.button`
  display: flex;
  align-items: center;
  padding: 5px;
  cursor: pointer;
  border-radius: 5px;
  background: #3262ab;
  border: 1px solid #163666;
  color: #fff;
  margin: 0 0.5rem 0 0.5rem;
`;

export default TutorClaimedRow;
