import { useEffect, useState } from 'react';
import authProvider from 'authProvider';

const useGetPermissions = () => {
  const [permissions, setPermissions] = useState({ isLoading: true });

  useEffect(() => {
    const runEffect = async () => {
      if (permissions?.userRole || !permissions?.isLoading) return;

      try {
        const result = await authProvider.getPermissions();
        setPermissions(() => ({ ...result, isLoading: false }));
      } catch (e) {
        console.error(e);
      }
    };
    runEffect();
  }, [permissions?.isLoading, permissions?.userRole]);

  return permissions;
};

export default useGetPermissions;
