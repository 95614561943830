import BAvatar from 'boring-avatars';

const Avatar = ({ name, size, className }) => {
  return (
    <div className={className}>
      <BAvatar
        size={size}
        name={name}
        variant="beam"
        colors={[
          '#36ABFF',
          '#4353FF',
          '#FF35C0',
          '#F53838',
          '#FC5926',
          '#FEAC06',
          '#09C18A',
          '#20c997',
          '#17a2b8',
          '#E8F1F7',
          '#FFD0D9',
          '#D88C45',
          '#945ED8',
          '#302093'
        ]}
      />
    </div>
  );
};

export default Avatar;
