import { makeStyles } from '@material-ui/core/styles';
import {
  Modal,
  Backdrop,
  Container,
  Card,
  CardContent,
  Typography
} from '@material-ui/core';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useCallback, React } from 'react';
import { useDataProvider, useNotify, useRefresh } from 'react-admin';
import TutorForm from './TutorForm';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  create: {
    margin: '1rem 0 0 0'
  }
}));

const CreateTutorModal = ({ isOpen, setIsOpen }) => {
  const notify = useNotify();
  const history = useHistory();
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();

  const createTutor = useCallback(
    async values => {
      try {
        await dataProvider.update('tutors', {
          id: -1,
          body: values
        });
        refresh();
        setIsOpen();
        notify('Tutor Created!', 'success');
      } catch (e) {
        notify(`Error creating Tutor! \n\n ${e}`, 'error');
        console.error(e);
      }
    },
    [dataProvider, notify, refresh, setIsOpen]
  );

  return (
    <Modal
      open={isOpen}
      onClose={() => setIsOpen()}
      className={classes.modal}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
      closeAfterTransition>
      <Container maxWidth="md">
        <Card className={classes.create}>
          <CardContent>
            <Typography variant="h5" gutterBottom>
              Create Tutor
            </Typography>
            <TutorForm
              onSubmit={createTutor}
              onCancel={() => history.push('/tutors')}
            />
          </CardContent>
        </Card>
      </Container>
    </Modal>
  );
};

export default CreateTutorModal;
