import { makeStyles } from '@material-ui/core/styles';
import {
  Modal,
  Backdrop,
  Container,
  Card,
  CardContent,
  Typography
} from '@material-ui/core';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useCallback, React } from 'react';
import { useDataProvider, useNotify, useRefresh } from 'react-admin';
import UpdateEmbeddingForm from './UpdateEmbeddingForm';
import { useState } from 'react';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  create: {
    margin: '1rem 0 0 0',
    overflowY: 'scroll',
    maxHeight: `${window.innerHeight ?? 900}px`
  },
  input: {
    margin: '1rem 0 0 0'
  }
}));

const UpdateEmbeddingModal = ({ isOpen, setIsOpen, embeddingOutput }) => {
  const notify = useNotify();
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const [isDeleting, setIsDeleting] = useState(false);
  const [tagToDelete, setTagToDelete] = useState(null);
  const [embedding, setEmbedding] = useState(embeddingOutput);

  const onClose = useCallback(() => {
    window.open('/embedding-outputs', '_self');
    setIsOpen();
  }, [setIsOpen]);

  const updateEmbedding = useCallback(
    async values => {
      if (!embeddingOutput?.id) return;
      try {
        await dataProvider.update('embedding-outputs', {
          id: embeddingOutput.id,
          body: values
        });
        refresh();
        onClose();
        notify('Embedding updated!', 'success');
      } catch (e) {
        notify('Error updating embedding!', 'error');
        console.error(e);
      }
    },
    [dataProvider, embeddingOutput.id, notify, onClose, refresh]
  );

  const deleteEmbedding = useCallback(async () => {
    if (!embeddingOutput?.id || !isDeleting) return;
    try {
      await dataProvider.delete('embedding-outputs', {
        id: embeddingOutput.id
      });
      onClose();
      notify('Embedding deleted!', 'warning');
    } catch (e) {
      notify('Error deleting embedding!', 'error');
      console.error(e);
    }
  }, [dataProvider, embeddingOutput.id, isDeleting, notify, onClose]);

  const deleteMisconceptionTag = useCallback(
    async misconceptionTagId => {
      if (!misconceptionTagId) return;

      try {
        await dataProvider.delete('misconception-tags', {
          id: misconceptionTagId
        });
        const embeddingCopy = { ...embedding };
        embeddingCopy.misconceptionTags =
          embeddingCopy.misconceptionTags.filter(
            t => t.misconceptionTagId !== misconceptionTagId
          );
        setEmbedding(() => embeddingCopy);
        notify('Misconception tag deleted!', 'warning');
      } catch (e) {
        notify('Error deleting misconception tag!', 'error');
        console.error(e);
      }
    },
    [dataProvider, embedding, notify]
  );

  if (embedding === null) return null;

  return (
    <Modal
      open={isOpen}
      onClose={() => onClose()}
      className={classes.modal}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
      closeAfterTransition>
      <Container maxWidth="md">
        <Card className={classes.create}>
          <CardContent>
            <Typography variant="h5" gutterBottom>
              Update Embedding Output - EmbeddingOutputId: {embedding.id}
            </Typography>
            <UpdateEmbeddingForm
              embeddingOutput={embedding}
              onSubmit={updateEmbedding}
              onDelete={() => {
                deleteEmbedding();
                setIsDeleting(!isDeleting);
              }}
              onCancel={onClose}
              isDeleting={isDeleting}
              onDeleteTag={tagId => {
                deleteMisconceptionTag(tagToDelete);
                setTagToDelete(tagId);
              }}
              tagToDelete={tagToDelete}
            />
          </CardContent>
        </Card>
      </Container>
    </Modal>
  );
};

export default UpdateEmbeddingModal;
