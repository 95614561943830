import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  TextField,
  FormControl,
  Button,
  Select,
  MenuItem,
  InputLabel
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MetadataContext } from 'Providers/MetadataProvider';
import { useNotify, Notification } from 'react-admin';
import { useContext } from 'react';
import { Form, Field } from 'react-final-form';
import Api from 'Api/Api';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  textInput: {
    marginBottom: '0.2rem',
    width: '100%'
  },
  select: {
    marginBottom: '0.2rem',
    width: '100%'
  }
}));

const AddNewSchoolCard = ({ studentId, ...props }) => {
  const { countries } = useContext(MetadataContext);
  const history = useHistory();
  const notify = useNotify();
  const classes = useStyles();

  const onSubmit = async values => {
    try {
      const submitBody = {
        countryId: values.country.countryId,
        name: values.name,
        address: values.address,
        city: values.city,
        zipOrPostCode: values.zipOrPostCode,
        website: values.website
      };

      await Api.request(
        'PUT',
        `v4/admin/students/${studentId}/team-school/requests`,
        submitBody
      );

      history.push('/requests');
    } catch {
      notify(
        'There had been issue when trying to perform this action',
        'error'
      );
    }
  };

  return (
    <Card variant="outlined" {...props}>
      <CardHeader title="Add to new school" />
      <Form
        onSubmit={onSubmit}
        validate={values => {
          const errors = {};
          if (!values.country) {
            errors.country = 'Country is required';
          }
          if (!values.name) {
            errors.name = 'School name is required';
          }
          return errors;
        }}
        render={({ handleSubmit, submitting, pristine }) => (
          <form onSubmit={handleSubmit}>
            <CardContent>
              <FormControl>
                <Field name="country">
                  {({ input, meta }) => (
                    <>
                      <InputLabel required id="country-label">
                        Country
                      </InputLabel>
                      <Select
                        {...input}
                        className={classes.select}
                        name="country"
                        labelId="country-label"
                        error={meta.error && meta.touched}>
                        {countries.map((c, idx) => (
                          <MenuItem key={idx} value={c}>
                            {c.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </>
                  )}
                </Field>
                <Field name="name">
                  {({ input, meta }) => (
                    <TextField
                      required
                      {...input}
                      className={classes.textInput}
                      error={meta.error && meta.touched}
                      label="School Name"
                      helperText={
                        meta.error && meta.touched ? meta.error : null
                      }
                    />
                  )}
                </Field>
                <Field name="address">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      className={classes.textInput}
                      error={meta.error && meta.touched}
                      label="1st line of address"
                      helperText={
                        meta.error && meta.touched ? meta.error : null
                      }
                    />
                  )}
                </Field>
                <Field name="city">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      className={classes.textInput}
                      error={meta.error && meta.touched}
                      label="City"
                      helperText={
                        meta.error && meta.touched ? meta.error : null
                      }
                    />
                  )}
                </Field>

                <Field name="zipOrPostCode">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      className={classes.textInput}
                      error={meta.error && meta.touched}
                      label="Zip Or Postcode"
                      helperText={
                        meta.error && meta.touched ? meta.error : null
                      }
                    />
                  )}
                </Field>

                <Field name="website">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      className={classes.textInput}
                      error={meta.error && meta.touched}
                      label="Website address"
                      helperText={
                        meta.error && meta.touched ? meta.error : null
                      }
                    />
                  )}
                </Field>
              </FormControl>
            </CardContent>
            <CardActions>
              <Button
                type="submit"
                color="primary"
                disabled={submitting || pristine}>
                Submit
              </Button>
            </CardActions>
          </form>
        )}
      />
      <Notification />
    </Card>
  );
};

export default AddNewSchoolCard;
