const initialState = {
  lessonListOpen: false,
  studentShowOpen: false,
  lessonListAutoScrollEnabled: true,
  addHybridSchoolModalOpen: false
};

export default function usersReducer(state = initialState, action) {
  try {
    switch (action.type) {
      case 'uxState/set':
        return { ...state, ...action.payload };
      default:
        return state;
    }
  } catch (error) {
    console.error(error);
  }

  return state;
}
