import { SignalRConnectionContext } from 'Providers/SignalRProvider';
import { useCallback, useContext, useEffect, useRef } from 'react';

export const useSignalROn = (method, callback) => {
  const { signalRConnection } = useContext(SignalRConnectionContext);

  const callbackRef = useRef();
  callbackRef.current = callback;

  const staticCallback = useCallback(
    (...args) => callbackRef.current(...args),
    []
  );

  useEffect(() => {
    if (!signalRConnection) {
      return;
    }

    signalRConnection.on(method, staticCallback);
    return () => signalRConnection.off(method, staticCallback);
  }, [method, signalRConnection, staticCallback]);
};
