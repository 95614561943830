import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import flowItemGroupType from 'Helpers/flowItemGroupType';
import extractProgress from 'Helpers/extractProgress';
import QuizQuestionProgressItem from './QuizQuestionProgressItem';
import QuizQuestionRetryProgressItem from './QuizQuestionRetryProgressItem';
import WorksheetQuestionProgressItem from './WorksheetQuestionProgressItem';
import CheckoutQuestionProgressItem from './CheckoutQuestionProgressItem';
import lessonTypes from 'Helpers/lessonTypes';

const fillColors = [
  'rgb(222, 80, 80)',
  'rgb(110, 194, 130)',
  'rgb(	255, 191, 0)'
];

const useStyles = makeStyles({
  root: {
    display: 'flex',
    borderRadius: '99rem',
    background: '#eee',
    padding: '1px 8px 1px 8px',
    minHeight: '12px',
    margin: '6px 0'
  }
});

const ProgressIndicator = props => {
  const { flowItemGroupInputs, lessonType } = props;
  const classes = useStyles();
  const progress = extractProgress(flowItemGroupInputs, true, true, 12, 12, {
    marginRight: '3px'
  });
  const progressItems = [];
  return (
    <Box
      direction="row"
      justify="flex-start"
      alignItems="center"
      className={classes.root}>
      {progress.map((pi, i) => {
        switch (pi.flowItemGroupType) {
          case flowItemGroupType.diagnosticQuestionAnswer:
            return <QuizQuestionProgressItem {...pi} key={i} />;
          case flowItemGroupType.diagnosticQuestionRetryAnswer:
            return <QuizQuestionRetryProgressItem {...pi} key={i} />;
          case flowItemGroupType.worksheetQuestionAnswer:
            if (
              progressItems.find(
                pig => pig.questionSequence === pi.questionSequence
              ) &&
              lessonType === lessonTypes.topicPathwayQuiz
            )
              return null;
            progressItems.push(pi);
            const worksheetQuestions = progress.filter(
              pig =>
                pig.questionSequence === pi.questionSequence &&
                pig.flowItemGroupType ===
                  flowItemGroupType.worksheetQuestionAnswer
            );
            let title = null;
            let fill = null;
            if (worksheetQuestions.length) {
              const correctAnswerCount = worksheetQuestions
                .map(ws => (ws.isCorrect ? 1 : 0))
                .reduce((sum, x) => sum + x);
              const totalCount = worksheetQuestions.length;
              title = `Worksheet Score - ${correctAnswerCount}/${totalCount}`;
              const score = correctAnswerCount / totalCount;
              fill =
                score > 0.7
                  ? fillColors[1]
                  : score < 0.5
                  ? fillColors[2]
                  : fillColors[0];
            }
            return (
              <WorksheetQuestionProgressItem
                title={title}
                fill={fill}
                {...pi}
                key={i}
              />
            );
          case flowItemGroupType.checkoutQuestionRetry:
          case flowItemGroupType.checkoutQuestion:
            return (
              <CheckoutQuestionProgressItem
                title={`CheckoutQuestion ${
                  pi.flowItemGroupType ===
                  flowItemGroupType.checkoutQuestionRetry
                    ? 'Retry'
                    : ''
                }`}
                {...pi}
                key={i}
              />
            );
          default:
            return null;
        }
      })}
    </Box>
  );
};

export default ProgressIndicator;
