import { List, Pagination, FunctionField } from 'react-admin';
import RequestsDataGrid from './RequestsComponents/RequestsDataGrid';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {
    margin: '12px',
    minHeight: '350px'
  }
}));

const RequestsPagination = props => (
  <Pagination rowsPerPageOptions={[20, 30, 40, 50]} {...props} />
);

const RequestList = props => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <List
        title="New School Requests"
        pagination={<RequestsPagination />}
        bulkActionButtons={false}
        perPage={20}
        sort={{ field: 'Username', order: 'DESC' }}
        {...props}>
        <RequestsDataGrid rowClick="show">
          <FunctionField
            source="studentId"
            sortByOrder="DESC"
            label="Student ID"
            sortBy="StudentId"
            render={r => `#${r.id}`}
          />
          <FunctionField
            label="Username"
            reference="requests"
            sortBy="Username"
            render={r => r.username ?? 'N/A'}
          />
          <FunctionField
            label="Date Requested"
            reference="requests"
            sortBy="DateRequested"
            render={r =>
              r.dateRequested
                ? moment(r.dateRequested).format('YYYY/DD/MM')
                : '-'
            }
          />
          <FunctionField
            label="School name"
            reference="requests"
            sortBy="Name"
            render={r => r.name ?? 'N/A'}
          />
          <FunctionField
            label="Url"
            reference="requests"
            sortBy="Url"
            render={r => r.url ?? 'N/A'}
          />
        </RequestsDataGrid>
      </List>
    </Box>
  );
};

export default RequestList;
