import moment from 'moment';
import 'moment-timezone';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  CardActionArea,
  CardContent,
  Typography,
  Tooltip,
  Box
} from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import FlagIcon from '@material-ui/icons/Flag';
import { useHistory } from 'react-router';
import ProgressIndicator from './ProgressIndicator';
import Avatar from 'Components/Avatar';
import Circle from 'Components/Shapes/Circle';
import lessonTypes from 'Helpers/lessonTypes';

const useStyles = makeStyles({
  card: {
    display: 'flex',
    borderRadius: 0,
    width: '100%',
    padding: 0
  },
  cardLeft: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '100px',
    marginRight: '12px'
  },
  cardRight: {
    width: '130px',
    flex: '1 0 auto',
    minWidth: '130px'
  },
  cardHeader: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '4px'
  },
  cardIcons: {
    display: 'flex',
    marginLeft: 'auto',
    alignItems: 'center'
  },
  icon: {
    height: '20px',
    width: '20px',
    margin: '0 4px 0 0'
  }
});

const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 13
  },
  arrow: {
    color: theme.palette.common.white
  }
}))(Tooltip);

const LessonCard = ({ props }) => {
  const { flowGeneratorSessionId, id } = props;
  const classes = useStyles();
  const history = useHistory();
  const inputs = props.flowGeneratorSession?.flowItemGroupInputs;
  const lastChecked = localStorage.getItem(`lesson${id}`);
  const lastActivity = inputs[inputs.length - 1]?.dateCreated;

  if (!id) return null;

  const fullName = `${props.firstName ?? '(Refresh Required)'} ${
    !!props.firstName ? props.lastName : ''
  }`;

  return (
    <Box className={classes.card}>
      <CardActionArea
        href={
          flowGeneratorSessionId === id
            ? '#'
            : `/flow-generator-sessions/${id}/show`
        }
        onClick={e => {
          e.preventDefault();
          return history.push(
            flowGeneratorSessionId === id
              ? '#'
              : `/flow-generator-sessions/${id}/show`
          );
        }}>
        <CardContent
          style={{
            borderBottom: '1px solid #ddd',
            backgroundColor: id === flowGeneratorSessionId ? '#f6f6f6' : '#fff'
          }}>
          <div className={classes.cardHeader}>
            <Avatar name={fullName} size={24} />
            <Typography
              gutterBottom
              variant="inherit"
              component="h4"
              noWrap={false}
              color={!props.isOnline ? 'textSecondary' : 'textPrimary'}
              style={{ marginBottom: 0, marginLeft: '4px' }}>
              {fullName}
            </Typography>
            <div className={classes.cardIcons}>
              {props.dateHelpRequested && (
                <LightTooltip
                  placement="top-start"
                  title="Help Requested!"
                  arrow>
                  <FlagIcon
                    className={classes.icon}
                    style={{
                      color: 'red'
                    }}
                  />
                </LightTooltip>
              )}
              {props.tutorUsername && (
                <LightTooltip
                  placement="top-start"
                  title={`Claimed by ${props.tutorUsername}`}
                  arrow>
                  <FlagIcon
                    className={classes.icon}
                    style={{
                      color: '#4caf50'
                    }}
                  />
                </LightTooltip>
              )}
              {props.dateCompleted ? (
                <LightTooltip
                  placement="top-start"
                  title={`Lesson completed ${moment
                    .utc(props.dateCompleted)
                    .tz('Europe/London')
                    .fromNow()}`}
                  arrow>
                  <CheckCircleIcon
                    className={classes.icon}
                    style={{
                      color: '#4caf50'
                    }}
                  />
                </LightTooltip>
              ) : (
                <LightTooltip
                  placement="top-start"
                  title={`Lesson started ${moment
                    .utc(props.dateCreated)
                    .tz('Europe/London')
                    .fromNow()}`}
                  arrow>
                  <AccessTimeIcon
                    className={classes.icon}
                    style={{
                      color: '#ffc107'
                    }}
                  />
                </LightTooltip>
              )}
              {new Date(lastChecked) < new Date(lastActivity) && (
                <LightTooltip
                  placement="top-start"
                  title={`Lesson updated ${moment
                    .utc(lastActivity)
                    .tz('Europe/London')
                    .fromNow()}`}
                  arrow>
                  <Circle width={16} height={16} radius={8} fill="#1565c0" />
                </LightTooltip>
              )}
            </div>
          </div>
          {!!props.quizName && (
            <Typography variant="body1" color="textSecondary">
              <b>{props.quizName}</b> ({props.quizId})
            </Typography>
          )}
          {[
            lessonTypes.challengeWorksheet,
            lessonTypes.topicPathwayQuiz
          ].includes(props.lessonType) && (
            <ProgressIndicator
              flowItemGroupInputs={inputs}
              lessonType={props.lessonType}
            />
          )}
        </CardContent>
      </CardActionArea>
    </Box>
  );
};

export default LessonCard;
