import { Modal, Container, CardContent, Typography } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import BuilderStepPanel from './BuilderStepPanel';
import Step0 from './Step0';
import Step1 from './Step1';
import Step2 from './Step2';
import Api from 'Api/Api';
import { Loading } from 'react-admin';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 224
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`
  },
  create: {
    margin: '1rem 0 0 0',
    overflowY: 'scroll',
    maxHeight: `${window.innerHeight ?? 900}px`
  },
  input: {
    margin: '1rem 0 0 0'
  },
  container: {
    background: '#fff'
  },
  createButton: {
    margin: '1rem 10px 0 0'
  },
  cancelButton: {
    margin: '1rem 0 0 0'
  }
}));

const BuilderSteps = [
  {
    step: 0,
    label: 'Step 0',
    title: 'LEVEL BOOSTER!!!',
    panelComponent: <Step0 />
  },
  {
    step: 1,
    label: 'Step 1',
    title: 'Learning goal/how can we help?',
    panelComponent: ({ onNext }) => <Step1 onNext={onNext} />
  },
  {
    step: 2,
    label: 'Step 2',
    title: 'How confident are you in mathematics?',
    panelComponent: ({ onComplete }) => <Step2 onComplete={onComplete} />
  }
];

const BuilderModal = ({ userId, isOpen, onClose }) => {
  const classes = useStyles();
  const [currentStep, setCurrentStep] = useState(0);
  const [payload, setPayload] = useState({
    topicPathwayCollectionId: 4,
    userIds: []
  });

  const [processing, setProcessing] = useState(false);

  const handleProgressChange = (event, newValue) => {
    console.log(newValue);
    setCurrentStep(newValue);
  };

  const onNext = newPayload => {
    console.log(newPayload);

    setPayload({ ...payload, ...newPayload });

    if (currentStep < BuilderSteps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const onComplete = async newPayload => {
    setProcessing(true);

    const finalPayload = { ...payload, ...newPayload };

    // Send payload to the FlowAPI
    const newPlan = await Api.createStudyPlan(finalPayload);

    setProcessing(false);
    onClose(newPlan);
  };

  useEffect(() => {
    setPayload({
      topicPathwayCollectionId: 4,
      userIds: [userId]
    });
  }, [userId]);

  return (
    <Modal open={isOpen} onClose={onClose} className={classes.modal}>
      <Container maxWidth="md" className={classes.container}>
        <CardContent>
          {processing ? (
            <Loading />
          ) : (
            <>
              <Typography variant="h5" gutterBottom>
                Create Your Study Plan
              </Typography>

              <div className={classes.root}>
                <Tabs
                  orientation="vertical"
                  variant="scrollable"
                  scrollButtons="off"
                  value={currentStep}
                  onChange={handleProgressChange}
                  aria-label="Vertical tabs example"
                  className={classes.tabs}>
                  {BuilderSteps.map(step => {
                    console.log(
                      currentStep,
                      step.step,
                      step.step === currentStep + 1
                    );
                    return (
                      <Tab
                        label={step.label}
                        // disabled={
                        //   step.step === currentStep + 1 || step.step !== currentStep
                        // }
                      />
                    );
                  })}
                </Tabs>

                {/* {BuilderSteps.map((step, idx) => (
              <BuilderStepPanel value={currentStep} index={idx}>
                <>
                  <Typography variant="h6" className={classes.title}>
                    {step.title}
                  </Typography>
                  {step.panelComponent({
                    onNext: () => setCurrentStep(currentStep + 1),
                    onComplete: () => {
                      console.log('complete');
                      onClose();
                    }
                  })}
                </>
              </BuilderStepPanel>
            ))} */}
                <BuilderStepPanel value={currentStep} index={0}>
                  <>
                    <Typography variant="h6" className={classes.title}>
                      LEVEL BOOSTER!!!
                    </Typography>
                    <Step0 onNext={onNext} />
                  </>
                </BuilderStepPanel>
                <BuilderStepPanel value={currentStep} index={1}>
                  <>
                    <Typography variant="h6" className={classes.title}>
                      Learning goal/how can we help?
                    </Typography>
                    <Step1 onNext={onNext} />
                  </>
                </BuilderStepPanel>
                <BuilderStepPanel value={currentStep} index={2}>
                  <>
                    <Typography variant="h6" className={classes.title}>
                      'How confident are you in mathematics?'
                    </Typography>
                    <Step2 onComplete={onComplete} />
                  </>
                </BuilderStepPanel>
              </div>
            </>
          )}
        </CardContent>
      </Container>
    </Modal>
  );
};

export default BuilderModal;
