import Api from 'Api/Api';
import useApiRequest from 'Hooks/useApiRequest';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { IconButton } from '@material-ui/core';
import { useCallback } from 'react';
import { getTimeAgo, lessonTypes } from 'Pages/Lessons/Helpers/lessonHelpers';
import { useState } from 'react';
import { useEffect } from 'react';
import styled from 'styled-components';
import { useRef } from 'react';
import FlagIcon from '@material-ui/icons/Flag';
import CachedIcon from '@material-ui/icons/Cached';
import { useSignalROn } from 'Hooks/useSignalROn';
import { useJoinLessonDashboardSignalRGroup } from 'Hooks/useJoinLessonDashboardSignalRGroup';
import StatsCard from './Components/StatsCard';
import HelpSound from 'Assets/helprequest.mp3';
import red_alert from 'Assets/red_alert.mp3';
import HelpRequestRow from './Components/HelpRequestRow';
import TutorClaimedRow from './Components/TutorClaimedRow';
import { useSelector } from 'react-redux';
import { useNotify } from 'react-admin';
import FullPageLoader from 'Components/FullPageLoader';
import useIntervalPlus from 'Hooks/useIntervalPlus';
import AudiotrackIcon from '@material-ui/icons/Audiotrack';
import LightTooltip from 'Components/LightTooltip';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: '2rem',
    background: '#333'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  paper: {
    background: '#444',
    padding: theme.spacing(2),
    color: '#fff',
    display: 'flex',
    textAlign: 'center',
    flexDirection: 'column'
  },
  label: {
    margin: 0
  },
  audio: {
    color: '#f18282',
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center'
  },
  audioButton: {
    background: '#f18282',
    margin: '0 0 0 0.5rem',
    padding: '0.25rem',
    borderRadius: '10px',
    '&:hover': {
      background: '#ef4141'
    }
  },
  sortButton: {
    background: '#d3c8c8',
    padding: '0.25rem',
    borderRadius: '10px',
    '&:hover': {
      background: '#938d8d'
    }
  }
}));

const busyChart = {
  100: '🟧🟨🟨🟨🟨',
  90: '🟨🟨🟨🟨🟩',
  80: '🟨🟨🟨🟩🟩',
  70: '🟨🟨🟩🟩🟩',
  60: '🟨🟩🟩🟩🟩',
  50: '🟩🟩🟩🟩🟩',
  40: '🟩🟩🟩🟩',
  30: '🟩🟩🟩',
  20: '🟩🟩',
  10: '🟩',
  0: '🤷'
};

const LessonDashboardPage = () => {
  const notify = useNotify();
  const userName = useSelector(s => s.user?.userName);
  const classes = useStyles();
  const { data } = useApiRequest(
    async () => await Api.getFlowGeneratorSessionDashboardStats()
  );
  const [audioStatus, setAudioStatus] = useState(null);
  const [sortBy, setSortBy] = useState('date');

  const helpRequests = useRef([]);
  const [loaded, setLoaded] = useState(false);
  const [claiming, setClaiming] = useState(false);
  useJoinLessonDashboardSignalRGroup();
  const [stats, setStats] = useState({
    ...data,
    activeFlowSessionsCount: 0,
    questionLessonCount: 0,
    questionOfTheDayLessonCount: 0,
    challengeLessonCount: 0,
    topicLessonCount: 0,
    chatWithTutor: 0,
    retrievalLessonCount: 0
  });
  const timeAgo = useRef({ interval: null, date: null });
  const [timeAgoLabel, setTimeAgoLabel] = useState('N/A');

  const enableAudio = useCallback(() => {
    new Audio(HelpSound)
      .play()
      .then(() => {
        setAudioStatus(null);
      })
      .catch(() => {
        if (!audioStatus) {
          setAudioStatus('Click button to enable audio alerts 👉');
        }
      });
  }, [audioStatus]);

  const filterData = useCallback(
    data => {
      if (!data) return;

      if (!data?.activeFlowSessions) data.activeFlowSessions = [];

      const stats = { ...data };

      stats.questionLessonCount = data.activeFlowSessions.filter(
        session =>
          session.lessonType === lessonTypes.TopicPathwayQuizQuestion ||
          session.lessonType === lessonTypes.Question
      ).length;

      stats.questionOfTheDayLessonCount = data.activeFlowSessions.filter(
        session => session.lessonType === lessonTypes.QuestionOfTheDay
      ).length;

      stats.challengeLessonCount = data.activeFlowSessions.filter(
        session => session.lessonType === lessonTypes.ChallengeWorksheet
      ).length;

      stats.topicLessonCount = data.activeFlowSessions.filter(
        session => session.lessonType === lessonTypes.TopicPathwayQuiz
      ).length;

      stats.chatWithTutor = data.activeFlowSessions.filter(
        session =>
          session.lessonType === lessonTypes.OnDemand ||
          session.lessonType === lessonTypes.ChatWithTutor
      ).length;

      stats.retrievalLessonCount = data.activeFlowSessions.filter(
        session => session.lessonType === lessonTypes.RetrievalPractice
      ).length;

      stats.activeFlowSessionsCount = data.activeFlowSessions.filter(
        session => !session.dateCompleted
      ).length;

      stats.onlineUsersCount = new Set(
        data.activeFlowSessions.map(a => a.userId)
      ).size;

      stats.helpRequestedFlowSessionIds = data.activeFlowSessions
        .filter(session => !!session.dateHelpRequested)
        .map(f => f.flowGeneratorSessionId);

      const recentHelpRequests = stats.helpRequestedFlowSessionIds.filter(
        h => helpRequests.current.indexOf(h) === -1
      );

      stats.helpRequestCount = stats.helpRequestedFlowSessionIds.length;

      stats.claimedCount = data.activeFlowSessions.filter(
        session => !!session.tutorUserId
      ).length;

      stats.activeFlowSessions = stats.activeFlowSessions.map(a => {
        a.helpRequestInterval = getTimeAgo(a.dateHelpRequested);
        return a;
      });

      stats.onlineTutorsCount = new Set(
        stats?.tutorsOnline?.map(a => a) ?? []
      ).size;

      timeAgo.current.date = new Date().toISOString();
      timeAgo.current.activeFlowSessionsCount = stats.activeFlowSessionsCount;
      setTimeAgoLabel(getTimeAgo(timeAgo.current.date));
      setStats(stats);

      const newHelpRequests = recentHelpRequests.filter(
        a => helpRequests.current.indexOf(a) === -1
      );

      if (!!newHelpRequests.length) {
        try {
          helpRequests.current = stats.helpRequestedFlowSessionIds;
          enableAudio();
        } catch (e) {
          console.warn(e);
        }
      }

      // find any help requests that have been completed
      const completedHelpRequests = helpRequests.current.filter(
        a => stats.helpRequestedFlowSessionIds.indexOf(a) === -1
      );

      if (!!completedHelpRequests.length) {
        helpRequests.current = stats.helpRequestedFlowSessionIds;
      }
    },
    [enableAudio, helpRequests]
  );

  const refetch = useCallback(async () => {
    try {
      filterData(await Api.getFlowGeneratorSessionDashboardStats());
    } catch (e) {
      notify('Error refreshing dashboard', 'warning');
      console.error(e);
    }
  }, [filterData, notify]);

  useIntervalPlus(refetch, 30000);

  useEffect(() => {
    if (!data || loaded) return;
    filterData(data);
    setLoaded(true);
    enableAudio();
  }, [data, enableAudio, filterData, loaded]);

  const processSession = useCallback(
    flowSession => {
      const session = stats.activeFlowSessions.find(
        f => f.flowGeneratorSessionId === flowSession.flowGeneratorSessionId
      );
      timeAgo.current.date = new Date().toISOString();
      console.log('UPDATED SESSION!', session, flowSession);
      if (!session) {
        filterData({
          ...stats,
          activeFlowSessions: [...stats.activeFlowSessions, flowSession]
        });
      } else {
        filterData({
          ...stats,
          activeFlowSessions: stats.activeFlowSessions.map(f =>
            f.flowGeneratorSessionId === flowSession.flowGeneratorSessionId
              ? {
                  ...f,
                  ...flowSession,
                  firstName:
                    session.firstName || flowSession.firstName || 'Student',
                  lastName: session.lastName || flowSession.lastName || '',
                  lessonType: session.lessonType || flowSession.lessonType,
                  quizName: session.quizName || flowSession.quizName,
                  yearGroup: session.yearGroup || flowSession.yearGroup,
                  quizQuestionCorrectness:
                    session.quizQuestionCorrectness ||
                    flowSession.quizQuestionCorrectness,
                  assignmentId:
                    session.assignmentId || flowSession.assignmentId,
                  avgScore: session.avgScore || flowSession.avgScore,
                  percentages:
                    session.percentages?.length >
                    flowSession?.percentages?.length
                      ? session.percentages
                      : flowSession.percentages || session.percentages,
                  percentageComplete:
                    session.percentageComplete || flowSession.percentageComplete
                }
              : f
          )
        });
      }
    },
    [filterData, stats]
  );

  useSignalROn('DispatchFlowGeneratorSession', flowSession =>
    processSession(flowSession)
  );

  useSignalROn(
    'dispatchflowgeneratorsession',
    flowSession => {} //processSession(flowSession)
  );

  const getBusyChart = count => {
    let key = Object.keys(busyChart)
      .sort((a, b) => a - b)
      .find(k => k >= count);
    return busyChart[key] ?? '🤷';
  };

  const redAlertReceived = useCallback(
    async payload => {
      if (!payload) return;

      try {
        payload = JSON.parse(payload);
        notify(
          `Tutor ${payload.userName} has requested assistance!`,
          'warning'
        );
        new Audio(red_alert)
          .play()
          .then(r => {})
          .catch(e => enableAudio());
      } catch (e) {
        console.error(e);
      }
    },
    [enableAudio, notify]
  );

  useSignalROn('RedAlert', payload => redAlertReceived(payload));

  const redAlert = useCallback(async () => {
    try {
      await Api.sendSlackMessage(
        `:rotating_light: Tutor *${userName}* has requested assistance on the bot :rotating_light:`
      );
      await Api.dispatchSignalRMessage({
        groups: ['LessonDashboardGroup'],
        methods: ['RedAlert'],
        payload: JSON.stringify({
          userName,
          dateCreated: new Date().toISOString()
        })
      });
    } catch (e) {
      console.warn(e);
    }
  }, [userName]);

  const getTutorClaimed = claimedFlowSessions => {
    const tutorClaimed = [];

    claimedFlowSessions.forEach(session => {
      const tutor = tutorClaimed.find(
        t => t.tutorUserId === session.tutorUserId
      );
      if (tutor) {
        tutor.sessions.push(session);
      } else {
        tutorClaimed.push({
          tutorUsername: session.tutorUsername,
          tutorUserId: session.tutorUserId,
          sessions: [session]
        });
      }
    });

    return tutorClaimed;
  };

  const sortByDate = (a, b) => {
    return new Date(a?.dateHelpRequested) - new Date(b?.dateHelpRequested);
  };

  const sortByScoreThenDate = (a, b) => {
    if (!a || !b) return 0;
    // Both have valid avgScore, compare them.
    if (a.avgScore >= 0 && b.avgScore >= 0) {
      if (a.avgScore < b.avgScore) return -1;
      if (a.avgScore > b.avgScore) return 1;
    }

    // At this point, either both don't have valid avgScore,
    // or it's irrelevant since they are equal; so, sort by date, most recent first.
    return new Date(a?.dateHelpRequested) - new Date(b?.dateHelpRequested);
  };

  const helpRequestFlowSessions = stats?.activeFlowSessions?.length
    ? stats?.activeFlowSessions
        ?.filter(
          a => (!!a.dateHelpRequested || !!a.isHelpRequested) && !a.tutorUserId
        )
        ?.sort(sortBy === 'date' ? sortByDate : sortByScoreThenDate)
    : [];

  const claimedFlowSessions = stats?.activeFlowSessions?.length
    ? stats?.activeFlowSessions
        ?.filter(a => !!a.tutorUserId)
        ?.sort(
          (a, b) =>
            new Date(a.dateHelpRequested) - new Date(b.dateHelpRequested)
        )
    : [];

  return (
    <div className={classes.root} key={timeAgo.current.interval}>
      <FullPageLoader loading={claiming} />
      <Grid container spacing={1}>
        <Grid item xs={12} md={6} style={{ marginBottom: '32px' }}>
          <StatsCard
            loading={!loaded}
            style={{ textAlign: 'left', height: '100%' }}>
            <Filter>
              <h3 style={{ margin: '0 0 0.5rem 0', padding: 0 }}>
                Help Requests ({helpRequestFlowSessions.length}){' '}
                <FlagIcon
                  className={classes.icon}
                  style={{
                    color: 'red',
                    fontSize: '20px'
                  }}
                />
              </h3>
              <FilterButtons>
                <LightTooltip
                  placement="bottom"
                  title="Sort by predicted score %"
                  arrow>
                  <IconButton
                    style={{
                      margin: '0 0.5rem 0 0',
                      background: sortBy !== 'date' ? 'lightgreen' : ''
                    }}
                    className={classes.sortButton}
                    onClick={() => setSortBy(null)}>
                    <FormatListNumberedIcon />
                  </IconButton>
                </LightTooltip>
                <LightTooltip
                  placement="bottom"
                  title="Sort by oldest requests"
                  arrow>
                  <IconButton
                    className={classes.sortButton}
                    style={{
                      background: sortBy === 'date' ? 'lightgreen' : ''
                    }}
                    onClick={() => setSortBy('date')}>
                    <CalendarTodayIcon />
                  </IconButton>
                </LightTooltip>
              </FilterButtons>
            </Filter>
            <HelpRequestBox>
              {helpRequestFlowSessions.map((h, i) => (
                <HelpRequestRow
                  className="help-request-row"
                  key={i}
                  {...h}
                  setClaiming={claiming => setClaiming(claiming)}
                />
              ))}
              {!helpRequestFlowSessions.length && (
                <p style={{ textAlign: 'center' }}>No help requests</p>
              )}
            </HelpRequestBox>
          </StatsCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <StatsCard>
                <div className={classes.header}>
                  <h3 className={classes.label}>Live Lesson Dashboard</h3>
                  <div className={classes.audio}>
                    {audioStatus ? (
                      <h3>
                        {audioStatus}
                        <IconButton
                          className={classes.audioButton}
                          onClick={enableAudio}>
                          <AudiotrackIcon />
                        </IconButton>
                      </h3>
                    ) : null}
                  </div>
                  <p className={classes.label}>
                    Last updated: {timeAgoLabel}{' '}
                    <IconButton aria-label="reconnect" onClick={refetch}>
                      <CachedIcon style={{ color: '#fff' }} />
                    </IconButton>
                  </p>
                </div>
              </StatsCard>
            </Grid>
            <Grid item xs={12} md={6}>
              <StatsCard
                loading={!loaded}
                style={{ minHeight: '198px', height: 'calc(100% - 22px)' }}>
                <LightTooltip
                  placement="bottom"
                  title={stats.tutorsOnline?.map((t, i) => (
                    <p key={i}>{t}</p>
                  ))}
                  arrow>
                  <div
                    style={{
                      justifyContent: 'center',
                      padding: '5px'
                    }}>
                    <h1 className={classes.label}>{stats.onlineUsersCount}</h1>
                    {getBusyChart(stats.onlineUsersCount)}
                    <h4 className={classes.label}>Users Online</h4>
                    <hr style={{ width: '100%' }} />
                    <h1 className={classes.label}>{stats.onlineTutorsCount}</h1>
                    {getBusyChart(stats.onlineTutorsCount)}
                    <h4 className={classes.label}>Tutors Online</h4>
                  </div>
                </LightTooltip>
              </StatsCard>
            </Grid>
            <Grid item xs={12} md={6}>
              <StatsCard
                loading={!loaded}
                style={{
                  minHeight: '198px',
                  height: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '5px'
                }}>
                <h4 style={{ margin: '0 0 0.25rem 0' }}>
                  Press in case of emergency 👇
                </h4>
                <BigRedButton onClick={redAlert}>
                  <div>Help!</div>
                </BigRedButton>
              </StatsCard>
            </Grid>

            <Grid item xs={12} md={12} style={{ marginTop: '0.6rem' }}>
              <StatsCard loading={!loaded} style={{ textAlign: 'left' }}>
                <h3 style={{ margin: '0 0 0.5rem 0', padding: 0 }}>
                  Claimed ({claimedFlowSessions.length}){' '}
                  <FlagIcon
                    className={classes.icon}
                    style={{
                      color: 'green',
                      fontSize: '20px'
                    }}
                  />
                </h3>
                <LessonListBox>
                  {!!claimedFlowSessions?.length ? (
                    getTutorClaimed(claimedFlowSessions).map((tutor, i) => (
                      <TutorClaimedRow key={i} tutor={tutor} />
                    ))
                  ) : (
                    <p style={{ textAlign: 'center' }}>None claimed</p>
                  )}
                </LessonListBox>
              </StatsCard>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const HelpRequestBox = styled.div`
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  background: #333;
  border-radius: 10px;

  .help-request-row {
    border-bottom: 1px solid #555;
  }

  &::-webkit-scrollbar {
    border-radius: 10px;
    width: 10px;
    // make scroll button gray
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  svg {
    height: 20px;
  }
`;

const Filter = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FilterButtons = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 0 0.35rem 0;
`;

const LessonListBox = styled.div`
  height: calc(100vh - 570px);
  overflow-y: scroll;
  overflow-x: hidden;
  background: #333;
  border-radius: 10px;

  &::-webkit-scrollbar {
    border-radius: 10px;
    width: 10px;
    // make scroll button gray
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  div:hover {
    background: #555;
  }
  svg {
    height: 20px;
  }
`;

const BigRedButton = styled.div`
  div {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    border: none;
    color: white;
    font-family: Avenir, Arial, sans-serif;
    font-weight: 900;
    font-size: 1.5rem;
    background: red;
    text-shadow: 0 3px 1px rgba(122, 17, 8, 0.8);
    box-shadow: 0 8px 0 rgb(183, 9, 0), 0 15px 20px rgba(0, 0, 0, 0.35);
    text-transform: uppercase;
    transition: 0.1s all ease-in;
    outline: none;
    cursor: pointer;
    text-align: center;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  div:hover {
    font-size: 1.8rem;
  }
`;

export default LessonDashboardPage;
