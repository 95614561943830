import { Filter, SearchInput } from 'react-admin';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  Select,
  MenuItem,
  FormControl,
  Button,
  InputLabel
} from '@material-ui/core';
import { useCallback, useState } from 'react';
import useApiRequest from 'Hooks/useApiRequest';
import Api from 'Api/Api';
import CancelIcon from '@material-ui/icons/Cancel';
import MultipleSelect from 'Components/MultipleSelect';

const useStyles = makeStyles(theme => ({
  collectionSelectControl: {
    margin: '0 0 0 1rem',
    display: 'flex',
    alignSelf: 'center',
    width: '200px'
  },
  toggleControl: {
    display: 'flex',
    alignSelf: 'center',
    margin: '0 0 0 1rem'
  },
  clearButton: {
    lineHeight: '1rem',
    color: '#4353ff',
    fontSize: '0.8125rem',
    padding: '4px',
    border: 'none',
    background: 'none',
    boxShadow: 'none',
    float: 'right'
  },
  clearControl: {
    display: 'flex',
    alignSelf: 'center',
    margin: '0 0 0 1rem'
  },
  formGroup: { display: 'flex', flexDirection: 'row', width: '100%' },
  buttonWrapper: { display: 'flex' },
  filterWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  }
}));

const collectionDefault = [
  { key: 'Select Collection...', disabled: true, value: '' }
];

const levelsDefault = [{ key: 'Select Level...', disabled: true, value: '' }];

const MisconceptionTagsFilter = props => {
  const classes = useStyles();
  const { filterValues, setFilters } = props;
  const [selectedFilters, setSelectedFilters] = useState(
    !!filterValues
      ? Object.keys(filterValues)?.filter(k =>
          [
            'checkOut',
            'checkIn',
            'isDeclarativeKnowledge',
            'isMetaData'
          ]?.includes(k)
        )
      : []
  );
  const savefilters = useCallback(
    updatedFilterValues => {
      setFilters(updatedFilterValues);
    },
    [setFilters]
  );
  const { data } = useApiRequest(
    async () => await Api.getAllTopicPathwayCollections(['levels'])
  );
  const currentCollection = data?.find(
    c =>
      filterValues['topicPathwayCollectionId']?.Value &&
      parseInt(c.topicPathwayCollectionId, 10) ===
        parseInt(filterValues['topicPathwayCollectionId']?.Value, 10)
  );
  const collections = data?.length
    ? [
        ...collectionDefault,
        ...data.map(d => ({ key: d.name, value: d.topicPathwayCollectionId }))
      ]
    : [...collectionDefault];

  const levels = currentCollection?.levels?.length
    ? [
        ...levelsDefault,
        ...currentCollection.levels.map(l => ({
          key: `Level - ${l}`,
          value: `${l}`,
          disabled: false
        }))
      ]
    : [];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const filterOpts = [
    {
      label: 'Check-out',
      value: 'checkOut',
      disabled: selectedFilters.indexOf('checkIn') !== -1
    },
    {
      label: 'Check-in',
      value: 'checkIn',
      disabled: selectedFilters.indexOf('checkOut') !== -1
    },
    {
      label: 'Declarative Knowledge',
      value: 'isDeclarativeKnowledge'
    },
    {
      label: 'Metadata',
      value: 'isMetaData'
    }
  ];

  const selectFilters = useCallback(
    filters => {
      setSelectedFilters(filters);
      const filtersToSave = {};
      filterOpts.forEach(f => {
        if (filters.indexOf(f.value) !== -1) {
          filtersToSave[f.value] = {
            id: f.value,
            Value: true,
            Operator: 'Eq',
            Type: 'bool'
          };
        } else if (filterValues[f.value]) {
          delete filterValues[f.value];
          savefilters({ ...filterValues });
        }
      });
      if (Object.keys(filtersToSave).length)
        savefilters({ ...filterValues, ...filtersToSave });
    },
    [filterOpts, filterValues, savefilters]
  );

  return (
    <div className={classes.filterWrapper}>
      <Filter {...props}>
        <SearchInput source="q" placeholder="Search..." alwaysOn />
      </Filter>
      <FormControl className={classes.collectionSelectControl}>
        <InputLabel id="subject-label">Subject</InputLabel>
        <Select
          name="Subject"
          labelId="subject-label"
          value={filterValues['subjectId']?.Value ?? ''}
          onClick={e =>
            savefilters({
              ...filterValues,
              subjectId: {
                id: 'subjectId',
                Value: e.target.value ?? '',
                Operator: 'Eq',
                Type: 'int'
              }
            })
          }>
          {[
            { key: 'Number', value: '32' },
            { key: 'Data & Statistics', value: '101' },
            { key: 'Geometry', value: '71' },
            { key: 'Algebra', value: '49' }
          ].map((c, idx) => (
            <MenuItem key={idx} value={c.value}>
              {c.key}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl className={classes.collectionSelectControl}>
        <InputLabel id="collections-label">Collection</InputLabel>
        <Select
          name="collection"
          value={filterValues['topicPathwayCollectionId']?.Value ?? ''}
          onClick={e =>
            savefilters({
              ...filterValues,
              topicPathwayCollectionId: {
                id: 'topicPathwayCollectionId',
                Value: e.target.value ?? '',
                Operator: 'Eq',
                Type: 'int'
              }
            })
          }>
          {collections.map((s, idx) => (
            <MenuItem
              key={idx}
              value={s.value}
              disabled={s.disabled ? true : false}>
              {s.key}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {levels.length > 0 && (
        <FormControl className={classes.collectionSelectControl}>
          <InputLabel id="level-label">Level</InputLabel>
          <Select
            name="level"
            inputProps={{ 'aria-label': 'Without label' }}
            onClick={e => {
              if (
                !filterValues['topicPathwayCollectionId']?.Value ||
                !e.target.value
              )
                return null;

              savefilters({
                ...filterValues,
                level: {
                  id: 'level',
                  Value: `${e.target.value}`,
                  Operator: 'Eq',
                  Type: 'int'
                }
              });
            }}>
            {levels.map((s, idx) => (
              <MenuItem key={idx} value={s.value} disabled={s.disabled}>
                {s.key}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      <MultipleSelect
        className={classes.collectionSelectControl}
        label="Filter options"
        name="filterOptions"
        preSelectedOptions={selectedFilters}
        onChange={opts => selectFilters(opts)}
        opts={filterOpts}
      />

      <FormControl className={classes.clearControl}>
        <Button
          variant="contained"
          color="default"
          className={classes.clearButton}
          startIcon={<CancelIcon />}
          onClick={() => {
            savefilters({});
            setSelectedFilters([]);
          }}>
          Clear Filters
        </Button>
      </FormControl>
    </div>
  );
};
export default MisconceptionTagsFilter;
