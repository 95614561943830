import { makeStyles } from '@material-ui/core/styles';
import {
  Modal,
  Backdrop,
  Container,
  Card,
  CardContent,
  Typography,
  Button,
  InputLabel,
  MenuItem,
  FormGroup,
  Select,
  TextField
} from '@material-ui/core';
import ColorPicker from 'material-ui-color-picker';
import { Form, Field } from 'react-final-form';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { React, useState } from 'react';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import styled from 'styled-components';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '500px'
  },
  create: {
    margin: '1rem 0 0 0',
    overflowY: 'scroll',
    maxHeight: `${window.innerHeight ?? 900}px`
  },
  input: {
    margin: '1rem 0 0 0'
  }
}));

const EditTagModal = ({
  isOpen,
  setIsOpen,
  onSubmit = e => console.log(e),
  metaDataTags = []
}) => {
  const classes = useStyles();
  const [editorState, setEditorState] = useState({
    metaDataTagId: null
  });

  const onClose = () => {
    setIsOpen();
    setEditorState(() => ({ metaDataTagId: null }));
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      className={classes.modal}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
      closeAfterTransition>
      <Container maxWidth="md">
        <Card className={classes.create} style={{ minHeight: '500px' }}>
          <CardContent style={{ position: 'relative' }}>
            <Typography variant="h5" gutterBottom>
              Edit Tags
            </Typography>
            <FormGroup>
              <InputLabel
                required
                id="metaDataTagId-label"
                className={classes.select}>
                Metadata Tags
              </InputLabel>
              <StyledSelect
                name="metaDataTagId"
                labelId="metaDataTagId-label"
                variant="filled"
                value={editorState.metaDataTagId}
                onChange={e =>
                  setEditorState(() => ({
                    ...editorState,
                    metaDataTagId: e.target.value,
                    hexColor: null
                  }))
                }>
                {metaDataTags.map((t, i) => (
                  <StyledMenuItem key={i} value={t.metaDataTagId}>
                    <ColorBlock blockcolor={t.hexColor} />
                    <div>{t.label}</div>
                  </StyledMenuItem>
                ))}
              </StyledSelect>
            </FormGroup>
            <Form
              onSubmit={e => {
                onSubmit(e);
                setEditorState(() => ({ metaDataTagId: null }));
              }}
              initialValues={{
                metaDataTagId: editorState.metaDataTagId,
                label: metaDataTags.find(
                  t => t.metaDataTagId === editorState.metaDataTagId
                )?.label,
                hexColor: metaDataTags.find(
                  t => t.metaDataTagId === editorState.metaDataTagId
                )?.hexColor
              }}
              validate={values => {
                const errors = {};
                console.log(errors);
                return errors;
              }}>
              {({ handleSubmit, submitting, pristine, submitError }) => (
                <form onSubmit={handleSubmit}>
                  <FormGroup>
                    <Field name="label">
                      {({ input, meta }) => (
                        <TextField
                          {...input}
                          id="standard-basic"
                          label="Label"
                          variant="filled"
                          className={classes.input}
                          error={meta.error && meta.touched}
                          helperText={
                            meta.error && meta.touched ? meta.error : null
                          }
                        />
                      )}
                    </Field>
                  </FormGroup>
                  <FormGroup>
                    <Field name="hexColor">
                      {({ input, meta }) => (
                        <StyledColorPicker
                          {...input}
                          name="hexColor"
                          defaultValue="COLOR!"
                          error={meta.error && meta.touched}
                          helperText={
                            meta.error && meta.touched ? meta.error : null
                          }
                        />
                      )}
                    </Field>
                  </FormGroup>
                  <ButtonWrapper>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      type="submit"
                      className={classes.createButton}
                      startIcon={<SaveIcon />}
                      disabled={submitting || pristine || submitError}>
                      {editorState.metaDataTagId ? 'Update' : 'Create'}
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      className={classes.cancelButton}
                      onClick={onClose}
                      startIcon={<CancelIcon />}
                      disabled={submitting}>
                      Cancel
                    </Button>
                  </ButtonWrapper>
                </form>
              )}
            </Form>
          </CardContent>
        </Card>
      </Container>
    </Modal>
  );
};

const ColorBlock = styled.div`
  width: 10px;
  height: 20px;
  background: ${props => props.blockcolor ?? '#DC143C'};
  margin: 0 0.5rem 0 0;
`;

const StyledMenuItem = styled(MenuItem)`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
`;

const StyledSelect = styled(Select)`
  .MuiSelect-root {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
`;

const StyledColorPicker = styled(ColorPicker)`
  margin: 0.5rem 0 0.5rem 0;
  input {
    font-weight: bold;
  }
`;

const ButtonWrapper = styled.div`
  margin: 2rem 0 0 0;
  * {
    margin: 0 0.5rem 0 0;
  }
`;

export default EditTagModal;
