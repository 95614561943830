import Api from 'Api/Api';

const authProvider = {
  login: async ({ username, password, secret, googleIdToken }) => {
    let token = null;
    let user = null;

    if (!googleIdToken) {
      token = await Api.signIn(username, password, secret);
    } else {
      token = await Api.signInWithGoogle(googleIdToken);
    }

    if (token) {
      localStorage.setItem('authToken', token);
      user = await Api.getUserContext();
    }
    if (
      user?.isAdmin ||
      user?.isSuperAdmin ||
      user?.userRoles?.includes('Moderator') ||
      user?.userRoles?.includes('ContractorAdmin')
    ) {
      localStorage.setItem('user', JSON.stringify(user));
      return Promise.resolve();
    }
    localStorage.removeItem('authToken');
    localStorage.removeItem('user');
    throw new Error(
      'Your username or password has not been recognised. Please try again.'
    );
  },
  logout: () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('user');
    return Promise.resolve();
  },
  // called when the API returns an error
  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      localStorage.removeItem('authToken');
      return Promise.reject({ message: 'Unauthorized request!' });
    }
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: async () => {
    const token = localStorage.getItem('authToken');
    return token
      ? Promise.resolve()
      : Promise.reject({ message: 'Unauthorized request!' });
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => {
    try {
      const user = localStorage.getItem('user');
      if (user) {
        const { userRole, isAdmin, isSuperAdmin, userRoles } = JSON.parse(user);
        return Promise.resolve({
          userRole,
          isAdmin,
          isSuperAdmin,
          userRoles: userRoles ?? [],
          userId: user.userId
        });
      }
    } catch (error) {
      console.log(error);
    }
    return Promise.reject({ message: 'Unauthorized request!' });
  },
  getIdentity: () => {
    try {
      const user = localStorage.getItem('user');
      if (user) {
        const { userId, firstName, lastName } = JSON.parse(user);
        return Promise.resolve({
          id: userId,
          userId,
          fullname: `${firstName} ${lastName}`,
          firstName,
          lastName
        });
      }
    } catch (error) {
      console.log(error);
    }
    return Promise.reject({ message: 'Unauthorized request!' });
  }
};

export default authProvider;
