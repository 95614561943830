import { makeStyles } from '@material-ui/core/styles';
import {
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Button,
  Box
} from '@material-ui/core';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CloneFlowTemplateModal from '../Modals/CloneFlowTemplateModal';
import NewFlowTemplateModal from '../Modals/NewFlowTemplateModal';
import { useNotify } from 'react-admin';
import CachedIcon from '@material-ui/icons/Cached';
import Api from 'Api/Api';
import LoadingModal from 'Pages/QuestionExtractor/Components/LoadingModal';
import SaveConfirmButton from '../SaveConfirmButton';
import { FlowLessonType } from 'Components/FlowItems';
import TestFlowTemplateModal from '../Modals/TestFlowTemplateModal';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '1rem'
  },
  container: {
    minWidth: '400px'
  },
  input: {
    width: '100%',
    margin: '0.5rem 0 1rem'
  },
  select: {
    width: '100%',
    margin: '1rem 0 1rem'
  },
  formControl: {
    width: '100%'
  },
  button: {
    margin: '0.5rem 0'
  }
}));

const FlowsMenu = ({
  templateSlugs,
  templateSlug,
  cloneFlowTemplate,
  createNewTemplateSlug,
  flowTemplates = [],
  flowItems = []
}) => {
  const lessonType = flowTemplates?.find(t => !!t.lessonType)?.lessonType;
  const [loading, setLoading] = useState(false);
  const notify = useNotify();
  const history = useHistory();
  const classes = useStyles();
  const [isCloneFlowTemplateModalOpen, setIsCloneFlowTemplateModalOpen] =
    useState(false);
  const [isNewFlowTemplateModalOpen, setIsNewFlowTemplateModalOpen] =
    useState(false);
  const [isTestModalOpen, setIsTestModalOpen] = useState(false);
  const [isClearingCache, setIsClearingCache] = useState(false);

  const clearCache = useCallback(async () => {
    try {
      if (isClearingCache) return;
      setIsClearingCache(true);
      await Api.clearFlowTemplateCache({
        templateSlugs: [templateSlug]
      });
      notify('Whoop! Cache cleared!', 'success');
    } catch (error) {
      notify('Error resetting cache', 'error');
      console.error(error);
    }
    setIsClearingCache(false);
  }, [isClearingCache, notify, templateSlug]);

  const updateFlowTemplateLessonType = useCallback(
    async (templateSlug, lessonType) => {
      if (loading) return;
      setLoading(true);
      try {
        await Api.updateFlowTemplateLessonType(templateSlug, lessonType);
        notify('Whoop! Lesson type updated!', 'success');
        window.location.reload();
      } catch (error) {
        notify('Error updating lesson type', 'error');
        console.error(error);
      }
      setLoading(false);
    },
    [loading, notify]
  );

  return (
    <Box className={classes.root}>
      <FormControl className={classes.formControl}>
        <InputLabel>Flow Templates</InputLabel>
        <Select
          value={templateSlug}
          onChange={e => window.location.replace(`/flows/${e.target.value}`)}
          variant="filled">
          {templateSlugs?.map((t, i) => (
            <MenuItem key={i} value={t.templateSlug}>
              {t.templateSlug}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl
        className={classes.formControl}
        style={{ margin: '0.5rem 0 0 0' }}>
        <InputLabel>Lesson Type</InputLabel>
        <Select
          key={lessonType}
          disabled={!templateSlug}
          value={lessonType}
          onChange={e =>
            updateFlowTemplateLessonType(templateSlug, e.target.value)
          }
          variant="filled">
          {Object.keys(FlowLessonType).map((t, i) => (
            <MenuItem key={i} value={t}>
              {t}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl className={classes.formControl}>
        <Button
          variant="contained"
          color="secondary"
          endIcon={<FileCopyIcon />}
          className={classes.button}
          disabled={!templateSlug || !lessonType}
          onClick={() => setIsTestModalOpen(true)}>
          Test Template
        </Button>
      </FormControl>
      <FormControl className={classes.formControl}>
        <Button
          variant="contained"
          color="secondary"
          endIcon={<FileCopyIcon />}
          className={classes.button}
          disabled={!templateSlug}
          onClick={() => setIsCloneFlowTemplateModalOpen(true)}>
          Clone Template
        </Button>
      </FormControl>
      <FormControl className={classes.formControl}>
        <SaveConfirmButton
          icon={<CachedIcon />}
          buttonCopy="Reset Cache"
          confirmCopy="Are you sure?"
          loadingCopy="Resetting..."
          submitting={isClearingCache}
          className={classes.button}
          pristine={!templateSlug}
          onClick={async () => await clearCache()}
        />
      </FormControl>
      <hr />
      <FormControl className={classes.formControl}>
        <Button
          variant="contained"
          color="secondary"
          endIcon={<CloudUploadIcon />}
          className={classes.button}
          onClick={() => history.push('/create-flow')}>
          Upload Template
        </Button>
      </FormControl>
      <FormControl className={classes.formControl}>
        <Button
          variant="contained"
          color="secondary"
          endIcon={<NoteAddIcon />}
          className={classes.button}
          onClick={() => setIsNewFlowTemplateModalOpen(true)}>
          New Template
        </Button>
      </FormControl>
      <CloneFlowTemplateModal
        setIsModalOpen={setIsCloneFlowTemplateModalOpen}
        cloneFlowTemplate={cloneFlowTemplate}
        isModalOpen={isCloneFlowTemplateModalOpen}
        templateSlug={templateSlug}
      />
      <NewFlowTemplateModal
        setIsModalOpen={setIsNewFlowTemplateModalOpen}
        createNewTemplateSlug={createNewTemplateSlug}
        isModalOpen={isNewFlowTemplateModalOpen}
      />
      <LoadingModal
        open={isClearingCache || loading}
        title={isClearingCache ? 'Clearing cache...' : 'Updating...'}
        subTitle={
          isClearingCache
            ? 'This may take a few minutes...go make a cup of tea!'
            : 'Please wait a moment...'
        }
      />
      <TestFlowTemplateModal
        setIsModalOpen={setIsTestModalOpen}
        isModalOpen={isTestModalOpen}
        templateSlug={templateSlug}
        lessonType={lessonType}
        flowItems={flowItems}
        flowTemplates={flowTemplates}
      />
    </Box>
  );
};

export default FlowsMenu;
