import moment from 'moment';
import 'moment-timezone';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@material-ui/core';

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow);

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    margin: '0 0 12px 0'
  },
  table: {}
}));

const Topics = ({ props }) => {
  const classes = useStyles();
  let totalCorrectAnswerCount = 0;
  let totalCorrectionIncorrectAnswerCount = 0;
  let totalCorrectionCorrectAnswerCount = 0;
  let totalIncorrectAnswerCount = 0;
  let averageScore = 0;
  let quizCount = 0;
  let completedQuizCount = 0;
  let startedQuizCount = 0;
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>#QuizSessionId</StyledTableCell>
            <StyledTableCell align="right">DateCreated</StyledTableCell>
            <StyledTableCell align="right">DateCompleted</StyledTableCell>
            <StyledTableCell align="right">Level</StyledTableCell>
            <StyledTableCell align="right">QuizId</StyledTableCell>
            <StyledTableCell align="right">QuestionCount</StyledTableCell>
            <StyledTableCell align="right">CorrectAnswers</StyledTableCell>
            <StyledTableCell align="right">IncorrectAnswers</StyledTableCell>
            <StyledTableCell align="right">
              CorrectCorrectionAnswers
            </StyledTableCell>
            <StyledTableCell align="right">
              IncorrectCorrectionAnswers
            </StyledTableCell>
            <StyledTableCell align="right">Score</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.map(row => {
            if (!row.questionCount)
              return (
                <StyledTableRow key={row.name}>
                  <StyledTableCell align="left" colspan={9}>
                    No QuizSessions!
                  </StyledTableCell>
                </StyledTableRow>
              );

            totalCorrectAnswerCount += row.correctAnswerCount ?? 0;
            totalIncorrectAnswerCount += row.incorrectAnswerCount ?? 0;
            totalCorrectionCorrectAnswerCount +=
              row.correctionCorrectAnswerCount ?? 0;
            totalCorrectionIncorrectAnswerCount +=
              row.correctionIncorrectAnswerCount ?? 0;
            let score =
              ((row.correctAnswerCount + row.correctionCorrectAnswerCount) /
                row.questionCount) *
              100;
            averageScore += score;
            completedQuizCount += row.dateCompleted ? 1 : 0;
            startedQuizCount++;
            quizCount++;

            return (
              <StyledTableRow key={row.name}>
                <StyledTableCell component="th" scope="row">
                  {row.quizSessionId}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {moment.utc(row.dateCreated).tz('Europe/London').format()}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {row.dateCompleted
                    ? moment.utc(row.dateCompleted).tz('Europe/London').format()
                    : '-'}
                </StyledTableCell>
                <StyledTableCell align="right">{row.level}</StyledTableCell>
                <StyledTableCell align="right">{row.quizId}</StyledTableCell>
                <StyledTableCell align="right">
                  {row.questionCount}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {row.correctAnswerCount}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {row.incorrectAnswerCount}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {row.correctionCorrectAnswerCount}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {row.correctionIncorrectAnswerCount}
                </StyledTableCell>
                <StyledTableCell align="right">{score}%</StyledTableCell>
              </StyledTableRow>
            );
          })}
          <StyledTableRow>
            <StyledTableCell component="th" scope="row">
              Totals
            </StyledTableCell>
            <StyledTableCell align="right">
              Started Quizzes: {startedQuizCount}
            </StyledTableCell>
            <StyledTableCell align="right">
              Completed Quizzes: {completedQuizCount}
            </StyledTableCell>
            <StyledTableCell align="right"></StyledTableCell>
            <StyledTableCell align="right"></StyledTableCell>
            <StyledTableCell align="right"></StyledTableCell>
            <StyledTableCell align="right">
              {totalCorrectAnswerCount}
            </StyledTableCell>
            <StyledTableCell align="right">
              {totalIncorrectAnswerCount}
            </StyledTableCell>
            <StyledTableCell align="right">
              {totalCorrectionCorrectAnswerCount}
            </StyledTableCell>
            <StyledTableCell align="right">
              {totalCorrectionIncorrectAnswerCount}
            </StyledTableCell>
            <StyledTableCell align="right">
              {averageScore ? averageScore / quizCount : 0}%
            </StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Topics;
