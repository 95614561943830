const Triangle = props => {
  const { fill, height, width } = props;
  return (
    <svg id="triangle" height={height} width={width} {...props}>
      <polygon
        points={`${height / 2} 0, ${width} ${width}, 0 ${height}`}
        fill={fill}
      />
    </svg>
  );
};

export default Triangle;
