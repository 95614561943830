import { Filter, SearchInput } from 'react-admin';
import { useEffect, useState, useCallback } from 'react';
import {
  Select,
  MenuItem,
  FormControl,
  Button,
  Modal,
  Backdrop,
  Container,
  Card,
  CardContent,
  Typography
} from '@material-ui/core';
import CachedIcon from '@material-ui/icons/Cached';
import CancelIcon from '@material-ui/icons/Cancel';
import useQuery from 'Hooks/useQuery';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Api from 'Api/Api';
import useApiRequest from 'Hooks/useApiRequest';
import { useHistory } from 'react-router-dom';
import ToggleSwitch from 'Components/ToggleSwitch';
import { useNotify } from 'ra-core/esm/sideEffect';

const useStyles = makeStyles(theme => ({
  collectionSelect: {
    minHeight: '36px'
  },
  createButton: {
    color: '#4353ff',
    fontSize: '0.8125rem',
    padding: '4px',
    border: 'none',
    background: 'none',
    boxShadow: 'none',
    float: 'right',
    margin: '0 10px 0 0'
  },
  collectionSelectControl: {
    margin: '0 0 0 1rem',
    display: 'flex',
    alignSelf: 'center',
    width: '200px'
  },
  clearButton: {
    lineHeight: '1rem',
    color: '#4353ff',
    fontSize: '0.8125rem',
    padding: '4px',
    border: 'none',
    background: 'none',
    boxShadow: 'none',
    float: 'right'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '600px'
  },
  formGroup: { display: 'flex', flexDirection: 'row', width: '100%' },
  buttonWrapper: { display: 'flex' }
}));

const collectionDefault = [
  { key: 'Select Collection...', disabled: true, value: '' }
];

const levelsDefault = [{ key: 'Select Level...', disabled: true, value: '' }];

const TopicPathwayQuizFilter = props => {
  const notify = useNotify();
  const history = useHistory();
  const { filterValues, setFilters } = props;
  const classes = useStyles();
  const topicPathwayCollectionId = useQuery().get('topicPathwayCollectionId');
  const { loading, data, reload } = useApiRequest(() =>
    Api.getAllTopicPathwayCollections(['levels'])
  );
  const [collections, setCollections] = useState(collectionDefault);
  const [cacheState, setCacheState] = useState({
    isModalOpen: false,
    isLoading: false
  });
  const [isLoaded, setIsLoaded] = useState(false);

  const savefilters = useCallback(
    updatedFilterValues => {
      setFilters(updatedFilterValues);
    },
    [setFilters]
  );

  const resetCache = useCallback(async () => {
    if (!filterValues?.topicPathwayCollectionId?.Value) return;
    try {
      setCacheState(() => ({ ...cacheState, isLoading: true }));
      await Api.reCacheFlowTemplates({
        topicPathwayCollectionId: filterValues?.topicPathwayCollectionId?.Value
      });
      setCacheState(() => ({
        ...cacheState,
        isModalOpen: false,
        isLoading: false
      }));
      notify('Whoop! Cache cleared!', 'success');
    } catch (error) {
      setCacheState(() => ({ ...cacheState, isLoading: false }));
      notify('Error resetting cache', 'error');
      console.log(error);
    }
  }, [cacheState, filterValues?.topicPathwayCollectionId?.Value, notify]);

  const setPublished = useCallback(
    async isPublished => {
      const currentCollection = data?.find(
        c =>
          filterValues['topicPathwayCollectionId']?.Value &&
          parseInt(c.topicPathwayCollectionId, 10) ===
            parseInt(filterValues['topicPathwayCollectionId']?.Value, 10)
      );

      if (!currentCollection || currentCollection?.isPublished === isPublished)
        return;

      const updatedCollection = await Api.updateTopicPathwayCollection(
        currentCollection.topicPathwayCollectionId,
        { isPublished }
      );

      setCollections(
        collections.map(c => {
          if (
            c.topicPathwayCollectionId ===
            updatedCollection.topicPathwayCollectionId
          ) {
            c.isPublished = updatedCollection.isPublished;
          }

          return c;
        })
      );

      reload();
    },
    [collections, data, filterValues, reload]
  );

  useEffect(() => {
    const runEffect = async () => {
      if (
        !!topicPathwayCollectionId &&
        !filterValues['topicPathwayCollectionId']?.Value
      )
        savefilters({
          ...filterValues,
          topicPathwayCollectionId: {
            id: 'topicPathwayCollectionId',
            Value: topicPathwayCollectionId,
            Operator: 'Eq',
            Type: 'int'
          }
        });

      if (!loading && data?.length && !isLoaded) {
        setCollections([
          ...collectionDefault,
          ...data.map(d => ({ key: d.name, value: d.topicPathwayCollectionId }))
        ]);
        setIsLoaded(true);
      }
    };
    runEffect();
  }, [
    data,
    filterValues,
    history,
    isLoaded,
    loading,
    props,
    savefilters,
    setFilters,
    topicPathwayCollectionId,
    cacheState
  ]);

  const currentCollection = data?.find(
    c =>
      filterValues['topicPathwayCollectionId']?.Value &&
      parseInt(c.topicPathwayCollectionId, 10) ===
        parseInt(filterValues['topicPathwayCollectionId']?.Value, 10)
  );

  const levels = currentCollection?.levels?.length
    ? [
        ...levelsDefault,
        ...currentCollection.levels.map(l => ({
          key: `Level - ${l}`,
          value: `${l}`,
          disabled: false
        }))
      ]
    : [];

  return (
    <>
      <Filter {...props}>
        <SearchInput source="q" alwaysOn />
      </Filter>
      <FormControl className={classes.collectionSelectControl}>
        <Select
          name="collection"
          className={classes.collectionSelect}
          value={filterValues['topicPathwayCollectionId']?.Value ?? ''}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          onClick={e =>
            savefilters({
              ...filterValues,
              topicPathwayCollectionId: {
                id: 'topicPathwayCollectionId',
                Value: e.target.value ?? '',
                Operator: 'Eq',
                Type: 'int'
              }
            })
          }>
          {collections.map((s, idx) => (
            <MenuItem
              key={idx}
              value={s.value}
              disabled={s.disabled ? true : false}>
              {s.key}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {levels.length > 0 && (
        <FormControl className={classes.collectionSelectControl}>
          <Select
            name="level"
            className={classes.collectionSelect}
            value={filterValues['level']?.Value ?? ''}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            onClick={e => {
              if (
                !filterValues['topicPathwayCollectionId']?.Value ||
                !e.target.value
              )
                return null;

              savefilters({
                ...filterValues,
                level: {
                  id: 'level',
                  Value: `${e.target.value}`,
                  Operator: 'Eq',
                  Type: 'int'
                }
              });
            }}>
            {levels.map((s, idx) => (
              <MenuItem key={idx} value={s.value} disabled={s.disabled}>
                {s.key}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {!!currentCollection?.topicPathwayCollectionId && (
        <>
          <FormControl className={classes.collectionSelectControl}>
            <Button
              variant="contained"
              color="default"
              className={classes.clearButton}
              startIcon={<CachedIcon />}
              onClick={() =>
                setCacheState(() => ({ ...cacheState, isModalOpen: true }))
              }>
              Reset Cache
            </Button>
          </FormControl>
          <FormControl className={classes.collectionSelectControl}>
            <ToggleSwitch
              input={{
                onChange: async value => await setPublished(value),
                value: currentCollection?.isPublished
              }}
              label="Published"
              invert={false}
              meta={{}}
            />
          </FormControl>
        </>
      )}
      <FormControl className={classes.collectionSelectControl}>
        <Button
          variant="contained"
          color="default"
          className={classes.clearButton}
          startIcon={<CancelIcon />}
          onClick={() => savefilters({})}>
          Clear Filters
        </Button>
      </FormControl>
      <Modal
        open={cacheState.isModalOpen}
        onClose={() =>
          setCacheState(() => ({ ...cacheState, isModalOpen: false }))
        }
        className={classes.modal}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
        closeAfterTransition>
        <Container maxWidth="sm">
          <Card className={classes.create}>
            <CardContent>
              <Typography variant="h5" gutterBottom>
                Reset Cache
              </Typography>
              {!cacheState.isLoading && (
                <>
                  <p>
                    Resetting cache may cause slower performance for users and
                    possibly cause issues during during peak load e.g. 9am-5pm.
                  </p>
                  <p>Are you sure you wish to reset cache?</p>
                </>
              )}
              {cacheState.isLoading && (
                <>
                  <p style={{ textAlign: 'center' }}>
                    Resetting cache... this may take a while (a few hours for a
                    large collection!){' '}
                    <span role="img" aria-labelledby="beverage">
                      ☕
                    </span>
                  </p>
                  <img
                    style={{
                      textAlign: 'center',
                      margin: '0 auto',
                      height: '150px',
                      width: 'auto',
                      display: 'flex'
                    }}
                    src="/assets/loady.gif"
                    alt="loading!"></img>
                </>
              )}
              <div className={classes.formGroup}>
                <div className={classes.buttonWrapper}>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={cacheState.isLoading}
                    startIcon={<CachedIcon />}
                    onClick={() => resetCache()}>
                    Yes, I Know What I'm Doing!
                  </Button>
                </div>
                <div
                  className={classes.buttonWrapper}
                  style={{ marginLeft: 'auto' }}>
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={cacheState.isLoading}
                    startIcon={<CancelIcon />}
                    onClick={() =>
                      setCacheState(() => ({
                        ...cacheState,
                        isModalOpen: false
                      }))
                    }>
                    I'll leave it for now!
                  </Button>
                </div>
              </div>
            </CardContent>
          </Card>
        </Container>
      </Modal>
    </>
  );
};
export default TopicPathwayQuizFilter;
