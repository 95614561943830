import {
  Button,
  FormControl,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  makeStyles
} from '@material-ui/core';
import { useCallback, useState } from 'react';
import { Field, Form } from 'react-final-form';
import styled from 'styled-components';
import SaveConfirmButton from '../SaveConfirmButton';
import Check from '@material-ui/icons/Check';
import { useNotify } from 'react-admin';
import Api from 'Api/Api';
import { Delete } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  formGroup: {
    margin: '0 0 1rem 0'
  },
  validateButton: {
    margin: '0 0 1rem 0'
  },
  valid: {
    padding: '0.5rem',
    margin: '0 0 1rem 0',
    background: '#c8e6c9',
    borderRadius: '4px',
    border: '1px solid #7cb342',
    color: '#7cb342'
  },
  invalid: {
    padding: '0.5rem',
    margin: '0 0 1rem 0',
    background: '#ffcdd2',
    borderRadius: '4px',
    border: '1px solid #f44336',
    color: '#f44336'
  }
}));

const EditSkipToExpressionForm = ({
  flowTemplate,
  flowTemplates = [],
  currentExpression = null,
  updateFlowTemplate,
  deleteExpression
}) => {
  const [validatedExpression, setValidatedExpression] = useState('');
  const [invalidExpression, setInvalidExpression] = useState('');
  const classes = useStyles();
  const notify = useNotify();
  const expression = flowTemplate?.skipToExpressions?.find(
    (_, i) => i === currentExpression
  ) ?? { expression: null, position: 0, positionMapping: null };

  const positionMappings = flowTemplates
    .filter(t => t.positionMapping && t.position > flowTemplate.position + 1)
    .map(t => ({
      label: `#${t.position} - ${t.positionMapping}`,
      value: t.positionMapping
    }));

  const validateExpression = useCallback(
    async expression => {
      try {
        const result = await Api.validateExpression(expression);

        if (!result) {
          throw new Error('No results returned!');
        }

        if (
          result?.exceptionMessage?.length &&
          result?.exceptionMessage !== 'RuleFailed'
        ) {
          setInvalidExpression(expression);
          throw new Error(result.exceptionMessage);
        }

        setValidatedExpression(expression);
        notify('Rule passed validation', 'success');
      } catch (e) {
        notify(e.message, 'error');
        console.error(e);
      }
    },
    [notify]
  );

  return (
    <Form
      onSubmit={async values => {
        const skipToExpressions = flowTemplate?.skipToExpressions ?? [];

        if (currentExpression === null) {
          skipToExpressions.push(values);
        } else {
          skipToExpressions[currentExpression] = values;
        }

        flowTemplate.skipToExpressions = skipToExpressions;
        await updateFlowTemplate(flowTemplate, flowTemplate.position);
      }}
      initialValues={expression}
      validate={values => {
        const errors = {};
        if (!values.expression) {
          errors.expression = 'An expression is required';
        }
        if (!values.positionMapping) {
          errors.positionMapping = 'A position mapping is required';
        }
        return errors;
      }}>
      {({ handleSubmit, values, submitting, pristine, submitError }) => (
        <form onSubmit={handleSubmit}>
          <FormGroup className={classes.formGroup}>
            <Field name="expression">
              {({ input, meta }) => (
                <TextField
                  {...input}
                  spellCheck={false}
                  label="Expression"
                  variant="filled"
                  multiline
                  minRows={4}
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : null}
                />
              )}
            </Field>
          </FormGroup>
          {values.expression === validatedExpression ? (
            <div className={classes.valid}>Expression is valid</div>
          ) : values.expression === invalidExpression ? (
            <div className={classes.invalid}>Expression is invalid</div>
          ) : null}
          <Button
            className={classes.validateButton}
            variant="contained"
            color="secondary"
            disabled={
              !values.expression ||
              invalidExpression === values.expression ||
              validatedExpression === values.expression
            }
            onClick={() => validateExpression(values.expression)}
            endIcon={<Check />}>
            Validate Expression
          </Button>
          <FormGroup className={classes.formGroup}>
            <Field name="positionMapping">
              {({ input, meta }) => (
                <>
                  <InputLabel id="positionMapping-label">
                    Position Mapping
                  </InputLabel>
                  <Select
                    id="positionMapping"
                    {...input}
                    disabled={positionMappings.length === 0}
                    style={{ minHeight: '40px' }}
                    variant="filled"
                    placeholder="Position Mapping"
                    error={meta.error && meta.touched}
                    helperText={meta.error && meta.touched ? meta.error : null}>
                    {positionMappings?.map((t, i) => (
                      <MenuItem key={i} value={t.value}>
                        {t.label}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              )}
            </Field>
          </FormGroup>
          <SaveConfirmButton
            isDoubleConfirm={true}
            submitting={submitting}
            pristine={pristine}
            disabled={
              !values.expression ||
              !values.positionMapping ||
              validatedExpression !== values.expression
            }
            submitError={submitError}
            style={{ width: '100%', margin: 0, padding: 0 }}
          />
          {currentExpression !== null && (
            <SaveConfirmButton
              isDoubleConfirm={true}
              confirmCopy="Delete Expression?"
              loadingCopy="Deleting..."
              buttonCopy="Delete"
              icon={<Delete />}
              pristine={false}
              style={{ width: '100%', margin: '0', padding: '0' }}
              onClick={deleteExpression}
            />
          )}
        </form>
      )}
    </Form>
  );
};

const EditFlowTemplateExpressionsForm = ({
  flowTemplate,
  flowTemplates,
  updateFlowTemplate
}) => {
  const [isEditingExpression, setIsEditingExpression] = useState(false);
  const [currentExpression, setCurrentExpression] = useState(null);
  const skipToExpressions = flowTemplate?.skipToExpressions || [];
  const skipToExpressionsSelect = skipToExpressions.map((s, i) => ({
    label: `#${i + 1} Expression - ${s.positionMapping}`,
    value: i
  }));

  return (
    <Root>
      {skipToExpressionsSelect.length > 0 && !isEditingExpression && (
        <FormGroup style={{ margin: '0 0 1rem 0' }}>
          <FormControl>
            <InputLabel id="parentFlowTemplateSlug-label">
              Expressions
            </InputLabel>
            <Select
              placeholder="Expressions"
              onClick={e => {
                setCurrentExpression(e.target.value);
                setIsEditingExpression(true);
              }}>
              {skipToExpressionsSelect?.map((t, i) => (
                <MenuItem key={i} value={t.value}>
                  {t.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </FormGroup>
      )}
      {!isEditingExpression ? (
        <Button
          variant="contained"
          color="primary"
          type="button"
          onClick={() => {
            setIsEditingExpression(true);
            setCurrentExpression(null);
          }}>
          Add Expression
        </Button>
      ) : (
        <div>
          <EditSkipToExpressionForm
            flowTemplate={flowTemplate}
            flowTemplates={flowTemplates}
            currentExpression={currentExpression}
            updateFlowTemplate={(flowTemplate, position) => {
              updateFlowTemplate(flowTemplate, position);
              setCurrentExpression(null);
              setIsEditingExpression(false);
            }}
            deleteExpression={currentExpression => {
              const skipToExpressions = flowTemplate?.skipToExpressions ?? [];
              skipToExpressions.splice(currentExpression, 1);
              flowTemplate.skipToExpressions = skipToExpressions;
              updateFlowTemplate(flowTemplate, flowTemplate.position);
              setCurrentExpression(null);
              setIsEditingExpression(false);
            }}
          />
          <Button
            variant="contained"
            color="secondary"
            type="button"
            onClick={() => {
              setIsEditingExpression(false);
              setCurrentExpression(null);
            }}>
            Cancel
          </Button>
        </div>
      )}
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;
  padding: 1rem;
`;

export default EditFlowTemplateExpressionsForm;
