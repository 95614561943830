import styled from 'styled-components';
import extractProgress from 'Helpers/extractProgress';
import QuizQuestionProgressItem from 'Components/Lessons/QuizQuestionProgressItem';
import QuizQuestionRetryProgressItem from 'Components/Lessons/QuizQuestionRetryProgressItem';
import WorksheetQuestionProgressItem from 'Components/Lessons/WorksheetQuestionProgressItem';
import CheckoutQuestionProgressItem from 'Components/Lessons/CheckoutQuestionProgressItem';
import flowItemGroupType from 'Helpers/flowItemGroupType';
import { isObject } from 'lodash';
import CircleText from 'Components/Shapes/CircleText';
import { Tooltip } from '@material-ui/core';

const Header = styled.div`
  text-align: center;
  background: #ccc;
  border: none;
  border-radius: 50px 50px 0 0;
  max-width: fit-content;
  padding: 5px 12px 5px 12px;
  margin: 0 0 10px 0;
  align-self: center;
`;

const ProgressItem = styled.div`
  padding: 3px;
  display: flex;
  align-self: center;
`;

const ProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  background: #eee;
  border-radius: 99rem;
  padding: 0px 0 5px;
  min-width: 30px;
  min-height: 30px;
  cursor: pointer;
`;

const ProgressBarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 0 10px 0;
  border: 1px solid #ccc;
  margin: 0 0 1rem 0;
  border-radius: 10px;
  background: #eee;
  overflow-x: auto;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 1rem 0 1rem;
`;

const RowItem = styled.div`
  cursor: pointer;
  margin: 0 0.25rem 0 0;
`;

const QuestionSequenceProgress = ({ progress, sequence }) => {
  const sequenceProgress = progress.filter(
    pi => pi.questionSequence === sequence
  );

  return (
    <ProgressContainer>
      <Header>{sequence}</Header>
      {sequenceProgress.map((pi, i) => {
        switch (pi.flowItemGroupType) {
          case flowItemGroupType.diagnosticQuestionAnswer:
            return (
              <ProgressItem key={i}>
                <QuizQuestionProgressItem {...pi} />
              </ProgressItem>
            );
          case flowItemGroupType.diagnosticQuestionRetryAnswer:
            return (
              <ProgressItem key={i}>
                <QuizQuestionRetryProgressItem {...pi} />{' '}
              </ProgressItem>
            );
          case flowItemGroupType.worksheetQuestionAnswer:
            return (
              <ProgressItem key={i}>
                <WorksheetQuestionProgressItem
                  title="Worksheet Question"
                  {...pi}
                />
              </ProgressItem>
            );
          case flowItemGroupType.checkoutQuestionRetry:
          case flowItemGroupType.checkoutQuestion:
            return (
              <ProgressItem key={i}>
                <CheckoutQuestionProgressItem
                  title={`CheckoutQuestion ${
                    pi.flowItemGroupType ===
                    flowItemGroupType.checkoutQuestionRetry
                      ? 'Retry'
                      : ''
                  }`}
                  {...pi}
                />
              </ProgressItem>
            );
          default:
            return null;
        }
      })}
    </ProgressContainer>
  );
};

const LessonProgress = props => {
  const progress = extractProgress(
    props?.flowGeneratorSession?.flowItemGroupInputs,
    true,
    true,
    16,
    16
  );

  const calculatePercentage = quizQuestionCorrectness => {
    if (!isObject(quizQuestionCorrectness)) return [];

    return Object.keys(quizQuestionCorrectness).map((key, i) => ({
      sequence: i + 1,
      value: 100 / Object.keys(quizQuestionCorrectness).length,
      score: quizQuestionCorrectness[key],
      color:
        quizQuestionCorrectness[key] < 0.5
          ? 'red'
          : quizQuestionCorrectness[key] > 0.7
          ? 'green'
          : 'orange'
    }));
  };

  const percentages = calculatePercentage(props?.quizQuestionCorrectness);
  const percentage = percentages.length
    ? (percentages.reduce((acc, curr) => acc + curr.score, 0) /
        percentages.length) *
      100
    : 0;

  let questions = [1, 2, 3, 4, 5];

  if (percentages.length > 0 && percentages.length !== questions.length) {
    questions = percentages.map((p, i) => i + 1);
  }

  return (
    <>
      {percentages.length > 0 && percentage >= 0 ? (
        <ProgressBarWrapper>
          <span style={{ margin: '0 0 10px 10px' }}>
            Predicted correctness for each question:
          </span>
          <Row>
            {percentages.map((segment, i) => {
              const score =
                segment.score >= 0
                  ? `${Math.round(segment.score * 100)}%`
                  : 'N/A';
              const color = segment.score >= 0 ? segment.color : 'gray';
              return (
                <RowItem>
                  <Tooltip
                    key={i}
                    placement="top"
                    title={`Question ${segment.sequence} predicted correctness = ${score}`}
                    arrow>
                    <span>
                      <CircleText
                        width="50px"
                        fill={color}
                        style={{ color: '#fff' }}>
                        {score}
                      </CircleText>
                    </span>
                  </Tooltip>
                </RowItem>
              );
            })}
          </Row>
        </ProgressBarWrapper>
      ) : null}
      <Row>
        {questions.map(q => (
          <RowItem key={q}>
            <Tooltip title={`Question ${q} progress`} placement="top" arrow>
              <span>
                <QuestionSequenceProgress progress={progress} sequence={q} />
              </span>
            </Tooltip>
          </RowItem>
        ))}
      </Row>
    </>
  );
};

export default LessonProgress;
