import useApiRequest from 'Hooks/useApiRequest';
import Api from 'Api/Api';
import CircularProgressBar from 'Components/CircularProgressBar';
import { makeStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 0 0.5rem 0'
  },
  progress: {
    marginLeft: '0.5rem',
    fontSize: '0.9rem',
    color: '#777',
    textAlign: 'right',
    display: 'flex',
    flexDirection: 'column'
  },
  total: {
    fontSize: '0.8rem',
    color: '#999',
    marginLeft: '0.25rem'
  },
  info: {
    textAlign: 'left'
  }
});

const MisconceptionTaggingFilter = ({ fromDate = null, ...props }) => {
  const classes = useStyles();
  const { data, loading } = useApiRequest(
    async () => await Api.getMisconceptionTagStats(fromDate)
  );

  if (loading) {
    return null;
  }

  return (
    <div className={classes.root} {...props}>
      <Tooltip
        title={
          <p>
            {data.misconceptionTaggedPercentage}% misconceptions tagged. <br />
            {data.distinctTotalMisconceptionTaggedCount.toLocaleString()}{' '}
            answers tagged out of {(data.questionCount * 3).toLocaleString()}
            {fromDate?.length ? (
              <>
                <br />
                {`Since ${fromDate}`}
              </>
            ) : (
              <>
                <br />
                All time
              </>
            )}
          </p>
        }>
        <strong>
          <CircularProgressBar
            percentage={data.misconceptionTaggedPercentage}
          />
        </strong>
      </Tooltip>
      <div className={classes.progress}>
        <Tooltip
          title={`${data.distinctTotalMisconceptionTaggedCount.toLocaleString()} answers tagged out of ${(
            data.questionCount * 3
          ).toLocaleString()}`}>
          <div className={classes.info}>
            <strong>Total tagged: </strong>
            {data.distinctTotalMisconceptionTaggedCount.toLocaleString()}
          </div>
        </Tooltip>
        <Tooltip
          title={`You've added ${data.distinctUserMisconceptionTaggedCount.toLocaleString()} tags`}>
          <div className={classes.info}>
            <strong>You've tagged: </strong>
            {data.distinctUserMisconceptionTaggedCount.toLocaleString()}
          </div>
        </Tooltip>
        <Tooltip
          title={`${data.distinctEmbeddingMisconceptionsCount.toLocaleString()} unique misconceptions tagged`}>
          <div className={classes.info}>
            <strong>Unique tagged: </strong>
            {data.distinctEmbeddingMisconceptionsCount.toLocaleString()}
          </div>
        </Tooltip>
      </div>
    </div>
  );
};
export default MisconceptionTaggingFilter;
