const Loading = ({ text = 'Saving changes...', loading = true, ...props }) => {
  if (!loading) return null;

  return (
    <div {...props}>
      <p style={{ textAlign: 'center' }}>{text}</p>
      <img
        style={{
          textAlign: 'center',
          margin: '0 auto',
          height: '150px',
          width: 'auto',
          display: 'flex'
        }}
        src="/assets/loady.gif"
        alt="loading!"></img>
    </div>
  );
};

export default Loading;
