import { makeStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  customWidth: {
    maxWidth: 500,
    margin: '8px 25px 5px -25px',
    fontSize: 14
  }
}));

const ToolTip = props => {
  const classes = useStyles();

  if (!props.text) return <>{props.children}</>;

  return (
    <Tooltip
      title={props.text}
      placement={props.placement ? props.placement : 'top'}
      classes={{ tooltip: classes.customWidth }}
      arrow>
      <span>{props.children}</span>
    </Tooltip>
  );
};

export default ToolTip;
