import { makeStyles } from '@material-ui/core/styles';
import { ListItem, Divider } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  listItem: {
    padding: '4px 0',
    display: 'flex'
  },
  header: {
    fontWeight: '600'
  },
  data: {
    marginLeft: 'auto'
  }
}));

const ListItemRow = props => {
  const classes = useStyles();
  return (
    <>
      <ListItem className={classes.listItem} alignItems="flex-start">
        {props.header && (
          <span className={classes.header}>{props.header}:</span>
        )}
        {props.header && <span className={classes.data}>{props.data}</span>}
        {props.children && props.children}
      </ListItem>
      <Divider component="li" />
    </>
  );
};

export default ListItemRow;
