import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Button } from '@material-ui/core';
import TabPanel from 'Components/TabPanel';
import LessonSummary from './LessonSummary';
import Student from './Student';
import Parents from './Parents';
import Progress from './Progress';
import LessonProgress from './LessonProgress';
import DynamicQuiz from './DynamicQuiz';
import Topics from './Topics';
import Avatar from 'Components/Avatar';
import useGetAuthUser from 'Hooks/useGetAuthUser';
import HidePanelButton from 'Components/HidePanelButton';
import ToolTip from 'Components/ToolTip';

const useStyles = makeStyles(theme => ({
  root: {
    height: 'calc(100vh - 65px)',
    backgroundColor: '#fff',
    borderLeft: '1px solid #ddd'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  headerOptions: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    padding: '16px',
    width: '100%'
  },
  avatar: {
    margin: '12px',
    flex: '0 0 48px'
  },
  hidePanel: {
    alignSelf: 'flex-start',
    padding: '0.5rem'
  }
}));

const StudentShowCard = props => {
  const { data, isStudentShow, isClaiming, claimLesson } = props;
  const tabs = [];
  const student = data.student ? data.student : data;
  const user = useGetAuthUser();

  if (data) {
    if (!isStudentShow && !!data?.quizId) {
      tabs.push({
        label: 'Summary',
        children: <LessonSummary {...data} />
      });
      tabs.push({
        label: 'Progress',
        children: <LessonProgress {...data} />
      });
    }
    if (student) {
      tabs.push({
        label: 'Student',
        children: <Student {...{ ...data, ...student }} />
      });
    }
    if (student?.progress) {
      tabs.push({
        label: 'Level Progress',
        children: <Progress progress={student.progress} />
      });
    }
    if (student?.parents && student?.parents.length) {
      tabs.push({
        label: 'Parents',
        children: (
          <Parents
            parents={student.parents}
            studentUserId={student?.userId}
            uniqueCode={student?.uniqueCode}
          />
        )
      });
    }
    if (
      isStudentShow &&
      student?.dynamicQuizSummary &&
      student?.dynamicQuizSummary?.quizSessionId
    ) {
      tabs.push({
        label: 'Dynamic Quiz',
        children: <DynamicQuiz props={student.dynamicQuizSummary} />
      });
    }
    if (isStudentShow && student?.topicInsights) {
      tabs.push({
        label: 'Topic Insights',
        children: <Topics props={student.topicInsights} />
      });
    }
  }

  const isPlusUser = !!student?.parents?.find(
    p =>
      p.subscriptionStatus === 'active' || p.subscriptionStatus === 'trialing'
  );

  const classes = useStyles({});

  return (
    <Box className={classes.root}>
      <div>
        <div className={classes.header}>
          {!isStudentShow && (
            <Box className={classes.hidePanel}>
              <HidePanelButton property="studentShowOpen" />
            </Box>
          )}
          <Avatar
            name={`${data.firstName} ${data.lastName}`}
            size={72}
            className={classes.avatar}
          />
          <Box className={classes.headerOptions}>
            <Typography variant="h6" style={{ lineHeight: 1.2 }}>
              {data.firstName} {data.lastName}
              {data.country ? ` (${data.country})` : ''}
            </Typography>
            <Typography
              variant="body1"
              color="textSecondary"
              style={{ marginBottom: '4px' }}>
              User ID: {data.userId}
            </Typography>
            {!isStudentShow && (
              <ToolTip
                text={
                  !isPlusUser ? 'Cannot claim non plus user sessions' : null
                }>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => claimLesson()}
                  disabled={isClaiming || !isPlusUser}>
                  {user?.id === data.tutorUserId
                    ? 'unclaim this lesson'
                    : 'claim this lesson'}
                </Button>
              </ToolTip>
            )}
          </Box>
        </div>
        <TabPanel tabs={tabs} />
      </div>
    </Box>
  );
};

export default StudentShowCard;
