import { FlowItemType } from 'Components/FlowItems';
import styled from 'styled-components';
import { Button } from '@material-ui/core';

const SimpleTextFlowItem = flowItem => {
  return <FlowItem>{flowItem.text}</FlowItem>;
};

const SimpleImageFlowItem = flowItem => {
  return (
    <FlowItem>
      <Image src={flowItem.imageUrl} alt={flowItem.imageUrl} />
    </FlowItem>
  );
};

const SimpleDividerFlowItem = flowItem => {
  return (
    <FlowItem>
      <Divider>
        <Line />
        <Middle>{flowItem.text}</Middle>
        <Line />
      </Divider>
    </FlowItem>
  );
};

const SimpleInformationMessageFlowItem = flowItem => {
  return <InfoMessage>{flowItem.text}</InfoMessage>;
};

const SimpleRadioButtonInputFlowItem = flowItem => {
  if (!flowItem.radioButtons?.length) return null;
  return (
    <FlowItem>
      <p>{flowItem.text}</p>
      {flowItem.radioButtons.map((r, i) => (
        <Button variant="contained" color="secondary" type="button" key={i}>
          {r.label}
        </Button>
      ))}
    </FlowItem>
  );
};

const SimpleFlowItem = flowItem => {
  switch (flowItem.flowItemType) {
    case FlowItemType.PinnedText:
    case FlowItemType.Text:
      if (!flowItem.textOptions?.length)
        return <SimpleTextFlowItem {...flowItem} />;
      return flowItem.textOptions.map((t, i) => (
        <SimpleTextFlowItem key={i} {...flowItem} text={t} />
      ));
    case FlowItemType.Divider:
      if (!flowItem.textOptions?.length)
        return <SimpleDividerFlowItem {...flowItem} />;
      return flowItem.textOptions.map((t, i) => (
        <SimpleDividerFlowItem key={i} {...flowItem} text={t} />
      ));
    case FlowItemType.Image:
    case FlowItemType.PinnedImage:
      if (!flowItem.imageUrlOptions?.length)
        return <SimpleImageFlowItem {...flowItem} />;
      return flowItem.imageUrlOptions.map((i, j) => (
        <SimpleImageFlowItem key={j} {...flowItem} imageUrl={i} />
      ));
    case FlowItemType.RadioButtonInput:
      if (!flowItem.textOptions?.length)
        return <SimpleRadioButtonInputFlowItem {...flowItem} />;
      return flowItem.textOptions.map((t, i) => (
        <SimpleRadioButtonInputFlowItem key={i} {...flowItem} text={t} />
      ));
    case FlowItemType.Information:
      if (!flowItem.textOptions?.length)
        return <SimpleInformationMessageFlowItem {...flowItem} />;
      return flowItem.textOptions.map((t, i) => (
        <SimpleInformationMessageFlowItem key={i} {...flowItem} text={t} />
      ));
    default:
      return null;
  }
};

const FlowItem = styled.div`
  background: #eee;
  border-radius: 0rem 1rem 1rem 1rem;
  border: 1px solid #ccc;
  margin: 0.5rem 0 0 0;
  font-size: 1rem;
  padding: 0.5rem;
  word-break: break-word !important;
  p {
    margin: 0;
    padding: 0.1rem 1rem 0.1rem 1rem;
    text-align: left;
    word-break: break-word;
  }
  button {
    text-transform: none;
    margin: 0.2rem;
  }
`;

const Image = styled.img`
  width: auto;
  max-height: 150px;
`;

const Divider = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const Middle = styled.div`
  width: 100%;
  font-size: 0.7rem;
`;

const Line = styled.div`
  border-bottom: 2px solid #aaa;
  width: 100%;
  height: 2px;
`;

const InfoMessage = styled.div`
  margin: 0.5rem 0 0 0;
  background: #e6ffed;
  border-radius: 0.5rem;
  border: 1px solid #b7eb8f;
  width: 100%;
`;

export default SimpleFlowItem;
