import Latex from 'react-latex';
import styled from 'styled-components';

function latexToHtmlTable(latexTable) {
  const regex = /\\begin{tabular}{([^}]+)}([^]*?)\\end{tabular}/s;
  const match = latexTable.match(regex);

  if (!match) {
    return [];
  }

  const rows = match[2]
    .split(/\r?\n/)
    .map(s => s.replace('\\hline', ''))
    .filter(s => s?.length)
    .map(s => s.replace('\\\\', '').split(' & '));

  // max array length
  const max = rows.reduce((acc, row) => Math.max(acc, row.length), 0);

  // pad all rows to max length
  return rows.map(row => {
    while (row.length < max) {
      row.push('');
    }
    return row;
  });
}

function LatexTable(latex) {
  if (!latex?.includes('\\begin{tabular}')) return null;

  const rows = latexToHtmlTable(latex);

  if (!rows.length) return null;

  const max = rows.reduce((acc, row) => Math.max(acc, row.length), 0);
  const width = `${100 / max}%`;

  return (
    <Table border="1" cellSpacing="0" cellPadding="5" width={width}>
      <tbody>
        {rows.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {row.map((cell, cellIndex) => (
              <td key={cellIndex}>
                <Latex>{cell}</Latex>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

const Table = styled.table`
  width: 100%;
  max-width: 500px;
  table-layout: fixed;
  border-collapse: collapse;
  margin: 0 auto;
  th,
  td {
    width: ${props => (props.width ? props.width : '33.33%')};
    border: 1px solid black;
    padding: 5px;
    text-align: center;
    vertical-align: middle;
  }
`;

export { latexToHtmlTable, LatexTable };
