import styled from 'styled-components';
import ToolTip from './ToolTip';

const ProgressBar = ({
  percentage = 0,
  percentages = [],
  height = '8px',
  background = '#ccc',
  foreground = '#008dcf',
  tooltip = '',
  ...props
}) => {
  if (!percentages?.length && percentage !== null && percentage !== undefined) {
    percentages = [{ sequence: 1, value: percentage, color: foreground }];
  }

  return (
    <div>
      <ToolTip text={`${percentage || 0}% ${tooltip}`} placement="bottom">
        <Wrapper className="progress-bar-wrapper" {...props}>
          <Background
            className="progress-bar-background"
            height={height}
            background={background}>
            {percentages
              .sort((a, b) => a.sequence - b.sequence)
              .map((segment, i) => (
                <Bar
                  key={i}
                  percentage={segment.value}
                  foreground={segment.color}
                />
              ))}
          </Background>
        </Wrapper>
      </ToolTip>
    </div>
  );
};

const Wrapper = styled.div`
  margin: 0.25rem 0 0.25rem 0;
`;

const Background = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 15px;
  ${({ background = '#ccc', height = '8px' }) => `
  background: ${background};
  height: ${height};
  `}
  width: 100%;
  overflow: hidden;
  border-radius: 15px;
`;

const Bar = styled.div`
  ${({ percentage = 0, foreground = '#008dcf' }) => `
  background: ${foreground};
  width: ${percentage}%;
  `}
`;

export default ProgressBar;
