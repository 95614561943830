const Logo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      style={{ width: '1.5rem', height: '1.5rem' }}>
      <g fill="none" fillRule="evenodd">
        <path fill="#fff" d="M0 100h20V0H0z"></path>
        <path fill="#fff" d="M40 40h60V20H40z"></path>
        <path fill="#fff" d="M40 80h60V60H40z"></path>
      </g>
    </svg>
  );
};

export default Logo;
