const fetchLessonFilters = filterValues => {
  const lessonFilterValuesString = localStorage.getItem('lessonFilterValues');
  if (lessonFilterValuesString) {
    try {
      console.warn(
        'Found stored filters!',
        JSON.parse(lessonFilterValuesString)
      );
      return JSON.parse(lessonFilterValuesString);
    } catch (e) {
      console.error(e);
    }
  } else {
    localStorage.setItem('lessonFilterValues', JSON.stringify(filterValues));
  }
  return filterValues;
};

export default fetchLessonFilters;
