import { Paper, Container, Switch, Tooltip, Button } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Loading, useNotify } from 'react-admin';
import Api from 'Api/Api';
import { useCallback, useEffect, useState } from 'react';
import useGetAuthUser from 'Hooks/useGetAuthUser';
import { truncateString } from 'Helpers/stringHelpers';
import ChatIcon from '@material-ui/icons/Chat';
import ConversationPromptModal from './Components/ConversationPromptModal';
import DeleteIcon from '@material-ui/icons/Delete';

const PromptList = () => {
  const user = useGetAuthUser();
  const notify = useNotify();
  const [state, setState] = useState({
    loading: false,
    prompts: [],
    columns: [
      {
        field: 'prompt',
        headerName: 'Prompt'
      }
    ],
    loaded: false,
    isModalOpen: false,
    currentPrompt: null
  });

  const updatePrompt = useCallback(
    async prompt => {
      try {
        const response = await Api.upsertPrompt(prompt);
        if (response) {
          setState(() => ({
            ...state,
            prompts: state.prompts
              .map(p => {
                if (p.prompt === prompt.prompt && p.userId === prompt.userId) {
                  p = prompt;
                }
                return p;
              })
              .filter(p => p.dateDeleted === null)
          }));
        }
      } catch (e) {
        console.error(e);
        notify('Error updating prompt', 'error');
      }
    },
    [notify, state]
  );

  const getPrompts = useCallback(async () => {
    if (state.loading) return;
    try {
      setState(() => ({ ...state, loading: true }));
      const prompts = await Api.getPrompts(1000);

      if (!!prompts?.length) {
        prompts.forEach((p, i) => {
          p.id = i;
        });
      }

      setState(() => ({
        ...state,
        loading: false,
        prompts,
        loaded: true
      }));
      return;
    } catch (error) {
      console.error(error);
      notify('Error: Unable to fetch prompts', 'error');
    }
    setState(() => ({ ...state, loading: true, loaded: true }));
  }, [notify, state]);

  useEffect(() => {
    const runEffect = async () => {
      if (state.prompts.length > 0 || state.loading || state.loaded) return;
      await getPrompts();
    };
    runEffect();
  }, [getPrompts, state.loaded, state.loading, state.prompts.length]);

  if (state.loading) return <Loading />;

  return (
    <Container maxWidth="lg">
      <TableContainer component={Paper} style={{ margin: '1rem 0 0 0' }}>
        <Table aria-label="PromptList">
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell align="left">Prompt</TableCell>
              <TableCell align="left">Author</TableCell>
              <TableCell align="left">Public</TableCell>
              <TableCell align="left"></TableCell>
              <TableCell align="left"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {state.prompts.map(row => (
              <TableRow key={row.title}>
                <TableCell component="th" scope="row">
                  {row.title}
                </TableCell>
                <TableCell align="left">
                  <Tooltip
                    title={
                      row.prompt.length > 144
                        ? truncateString(row.prompt, 2000)
                        : ''
                    }>
                    <div>{truncateString(row.prompt, 144)}</div>
                  </Tooltip>
                </TableCell>
                <TableCell align="left">{row.author}</TableCell>
                <TableCell align="left">
                  <Switch
                    checked={row.isPublic}
                    disabled={user.userId !== row.userId}
                    onChange={async () =>
                      await updatePrompt({ ...row, isPublic: !row.isPublic })
                    }
                    name="isPublic"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />
                </TableCell>
                <TableCell align="left">
                  <Button
                    variant="contained"
                    color="default"
                    startIcon={<ChatIcon />}
                    onClick={() =>
                      setState(() => ({
                        ...state,
                        isModalOpen: true,
                        currentPrompt: row.prompt
                      }))
                    }>
                    Start
                  </Button>
                </TableCell>
                <TableCell align="left">
                  <Button
                    variant="contained"
                    color="default"
                    startIcon={<DeleteIcon />}
                    disabled={user.userId !== row.userId}
                    onClick={async () =>
                      await updatePrompt({
                        ...row,
                        dateDeleted: new Date().toISOString()
                      })
                    }>
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
            {state.prompts.length === 0 && (
              <TableRow>
                <TableCell colSpan={2}>No prompts found</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <ConversationPromptModal
        isOpen={state.isModalOpen}
        setIsOpen={isOpen =>
          setState(() => ({ ...state, isModalOpen: isOpen }))
        }
        isCreateMode={true}
        prompt={state.currentPrompt}
      />
    </Container>
  );
};

export default PromptList;
