const locales = [
  { name: 'English (UK)', code: 'en-GB', countryCode: 'GB' },
  { name: 'English (US)', code: 'en-US', countryCode: 'US' },
  { name: 'Portuguese (Brazil)', code: 'pt-BR' },
  { name: 'German', code: 'de-DE' },
  { name: 'French', code: 'fr-FR' },
  { name: 'Spanish', code: 'es-ES' },
  { name: 'Italian', code: 'it-IT' },
  { name: 'Portuguese', code: 'pt-PT' }
];

const languageHelpers = {
  locales
};

export default languageHelpers;
