import { makeStyles, Modal, Paper } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    display: 'flex',
    flexDirection: 'column'
  }
}));

const LoadingModal = ({
  open,
  onClose = () => {},
  title = 'Saving all...',
  subTitle = 'Please wait.'
}) => {
  const classes = useStyles();

  return (
    <Modal className={classes.modal} open={open} onClose={onClose}>
      <Paper className={classes.paper}>
        <h2 style={{ margin: '0 auto' }}>{title}</h2>
        <div style={{ width: '100%' }}>
          <img
            style={{
              textAlign: 'center',
              margin: '0 auto',
              height: '150px',
              width: 'auto',
              display: 'flex'
            }}
            src="/assets/loady.gif"
            alt="loading!"
          />
        </div>
        <h3 style={{ margin: '0 auto' }}>{subTitle}</h3>
      </Paper>
    </Modal>
  );
};

export default LoadingModal;
