import Api from 'Api/Api';
import { useEffect } from 'react';
import { useState } from 'react';
import { useCallback } from 'react';
import { createContext } from 'react';

export const MetadataContext = createContext();

export const MetadataProvider = ({ children }) => {
  const [meta, setMeta] = useState();

  const getMetaData = useCallback(async () => {
    const supportCountries = await Api.getSupportedCountries();

    setMeta({ countries: supportCountries });
  }, []);

  useEffect(() => {
    async function runEffect() {
      await getMetaData();
    }
    runEffect();
  }, [getMetaData]);

  return (
    <MetadataContext.Provider value={meta}>{children}</MetadataContext.Provider>
  );
};
