import { makeStyles } from '@material-ui/core/styles';
import {
  Modal,
  Backdrop,
  TextField,
  Button,
  Select,
  InputLabel,
  MenuItem,
  Container,
  Card,
  CardContent,
  Typography,
  FormGroup
} from '@material-ui/core';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useCallback, React, useState, useEffect } from 'react';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import { Form, Field } from 'react-final-form';
import { useNotify, useRefresh, useListContext } from 'react-admin';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import moment from 'moment';
import filterHelper from 'Helpers/filterHelper';
import Api from 'Api/Api';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  create: {
    margin: '1rem 0 0 0'
  },
  input: {
    margin: '1rem 0 0 0'
  },
  createButton: {
    margin: '1rem 0 0 0'
  },
  cancelButton: {
    margin: '1rem 0 0 10px'
  },
  select: {
    margin: '1rem 0 0 0',
    width: '100%'
  },
  dateInput: {
    width: '50%',
    margin: '1rem 0 0 0'
  }
}));

const subscriptionStatuses = {
  active: 'active',
  trialing: 'trialing',
  cancelled: 'canceled'
};

const subscriptionStatusOptions = [
  subscriptionStatuses.active,
  subscriptionStatuses.trialing,
  subscriptionStatuses.cancelled
];

const EditUserSubscriptionsModal = ({
  isOpen,
  setIsOpen,
  userSubscription = null,
  familyStudentIds = [],
  isAllSelected
}) => {
  const notify = useNotify();
  const classes = useStyles();
  const { total, filterValues } = useListContext();
  const refresh = useRefresh();
  const [isLoaded, setIsLoaded] = useState(false);
  const isMultipleEdit = isAllSelected || !!familyStudentIds?.length;
  const [isSubmitting, setIsSubmitting] = useState(false);

  const updateSubscriptions = useCallback(
    async values => {
      try {
        setIsSubmitting(true);
        const studentIds = isAllSelected
          ? []
          : !!familyStudentIds?.length
          ? familyStudentIds
          : [userSubscription?.familyStudentId];
        const update = {
          subscriptionStatus: values.subscriptionStatus,
          subscriptionPeriodEnd: values.subscriptionPeriodEnd,
          familyStudentIds: !isAllSelected ? studentIds : [],
          filter: isAllSelected
            ? filterHelper.getQueryFilter(filterValues)
            : null
        };
        await Api.bulkUpdateUserSubscriptions(update);
        refresh();
        setTimeout(() => {
          setIsOpen();
          notify('Subscriptions updated!', 'success');
          setIsSubmitting(false);
        }, 2000);
      } catch (e) {
        notify('Error updating subscriptions, check logs!', 'error');
        console.error(e);
        setIsSubmitting(false);
      }
    },
    [
      familyStudentIds,
      filterValues,
      isAllSelected,
      notify,
      refresh,
      setIsOpen,
      userSubscription?.familyStudentId
    ]
  );

  useEffect(() => {
    const runEffect = async () => {
      if (isLoaded || !isOpen) return;
      setIsLoaded(true);
    };
    runEffect();
  }, [isLoaded, isOpen]);

  return (
    <Modal
      open={isOpen}
      onClose={() => setIsOpen()}
      className={classes.modal}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
      closeAfterTransition>
      <Container maxWidth="md">
        <Card className={classes.create}>
          <CardContent>
            <Typography variant="h5" gutterBottom>
              {familyStudentIds.length > 0 || isAllSelected
                ? 'Edit Multiple Subscriptions'
                : 'Edit Subscription'}
            </Typography>
            <Form
              onSubmit={async values => await updateSubscriptions(values)}
              initialValues={{
                subscriptionStatus: isMultipleEdit
                  ? 'active'
                  : userSubscription?.subscriptionStatus,
                subscriptionPeriodEnd: moment(
                  userSubscription?.subscriptionPeriodEnd
                ).format('YYYY-MM-DDTHH:mm')
              }}
              validate={values => {
                const errors = {};
                if (!values.subscriptionPeriodEnd) {
                  errors.subscriptionPeriodEnd =
                    'Please set a subscription period end date';
                }
                return errors;
              }}>
              {({ handleSubmit, submitting, pristine, submitError }) => (
                <form onSubmit={handleSubmit}>
                  <FormGroup>
                    {!isMultipleEdit && userSubscription && (
                      <>
                        <Typography variant="subtitle1">
                          <strong>Family StudentId:</strong>{' '}
                          {userSubscription.familyStudentId ?? '-'}
                        </Typography>
                        <Typography variant="subtitle1">
                          <strong>Username:</strong>{' '}
                          {userSubscription.studentUserName ?? '-'}
                        </Typography>
                        <Typography variant="subtitle1">
                          <strong>Student Name:</strong>{' '}
                          {userSubscription.studentName ?? '-'}
                        </Typography>
                        <Typography variant="subtitle1">
                          <strong>Parent UserId:</strong>{' '}
                          {userSubscription.parentId ?? '-'}
                        </Typography>
                        <Typography variant="subtitle1">
                          <strong>Parent username:</strong>{' '}
                          {userSubscription.parentUserName ?? '-'}
                        </Typography>
                        <Typography variant="subtitle1">
                          <strong>Parent Name:</strong>{' '}
                          {userSubscription.parentName ?? '-'}
                        </Typography>
                        <Typography variant="subtitle1">
                          <strong>Customer Id:</strong>{' '}
                          {userSubscription.stripeCustomerId ?? '-'}
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          style={{ display: 'flex' }}>
                          <strong>Is Stripe Subscription?</strong>{' '}
                          {userSubscription.isStripeSubscription ? (
                            <CheckIcon />
                          ) : (
                            <ClearIcon />
                          )}
                        </Typography>
                        {!!userSubscription.stripeSubscriptionId && (
                          <Typography variant="subtitle1">
                            <strong>Stripe Subscription Id:</strong>{' '}
                            {userSubscription.stripeSubscriptionId}
                          </Typography>
                        )}
                        <Typography
                          variant="subtitle1"
                          style={{ display: 'flex' }}>
                          <strong>Is Hybrid User?</strong>{' '}
                          {userSubscription.studentSchools?.length > 0 ? (
                            <CheckIcon />
                          ) : (
                            <ClearIcon />
                          )}
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          style={{ display: 'flex' }}>
                          <strong>School Subscription?</strong>{' '}
                          {!!userSubscription.schoolId ? (
                            <CheckIcon />
                          ) : (
                            <ClearIcon />
                          )}
                        </Typography>
                        {userSubscription.schoolName && (
                          <Typography variant="subtitle1">
                            <strong>School Name: </strong>{' '}
                            {userSubscription.schoolName}
                          </Typography>
                        )}
                        {userSubscription.parentUserName?.includes(
                          'unclaimed'
                        ) &&
                          !!userSubscription.invitationLink && (
                            <Typography variant="subtitle1">
                              <strong>Parent Invite Link:</strong>{' '}
                              <a
                                href={`https://family.eedi.com${userSubscription.invitationLink}`}>{`https://family.eedi.com${userSubscription.invitationLink}`}</a>
                            </Typography>
                          )}
                        {userSubscription.parentUserName?.includes(
                          'unclaimed'
                        ) &&
                          !userSubscription.invitationLink && (
                            <Typography variant="subtitle1">
                              <strong>Parent Invite Link:</strong> Invitation
                              code expired!
                            </Typography>
                          )}
                      </>
                    )}
                    {isMultipleEdit && (
                      <>
                        <Typography variant="subtitle1">
                          <strong>Selected Student Count:</strong>{' '}
                          {isAllSelected ? total : familyStudentIds.length}
                        </Typography>
                      </>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Field name="subscriptionStatus">
                      {({ input, meta }) => (
                        <>
                          <InputLabel
                            required
                            id="subscriptionStatus-label"
                            className={classes.select}>
                            Subscription Status
                          </InputLabel>
                          <Select
                            {...input}
                            name="subscriptionStatus"
                            labelId="subscriptionStatus-label"
                            error={meta.error && meta.touched}>
                            {subscriptionStatusOptions.map((c, idx) => (
                              <MenuItem key={idx} value={c}>
                                {c}
                              </MenuItem>
                            ))}
                          </Select>
                        </>
                      )}
                    </Field>
                  </FormGroup>
                  <FormGroup>
                    <Field name="subscriptionPeriodEnd">
                      {({ input, meta }) => (
                        <TextField
                          {...input}
                          id="datetime-local"
                          label="Subscription Period End"
                          type="datetime-local"
                          className={classes.dateInput}
                          //value={moment(input.value).format('YYYY-MM-DD')}
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                      )}
                    </Field>
                  </FormGroup>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    type="submit"
                    className={classes.createButton}
                    startIcon={<SaveIcon />}
                    disabled={
                      submitting ||
                      (pristine && !isMultipleEdit) ||
                      submitError ||
                      isSubmitting
                    }>
                    Update
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    disabled={isSubmitting}
                    className={classes.cancelButton}
                    onClick={() => setIsOpen()}
                    startIcon={<CancelIcon />}>
                    Cancel
                  </Button>
                </form>
              )}
            </Form>
          </CardContent>
        </Card>
      </Container>
    </Modal>
  );
};

export default EditUserSubscriptionsModal;
