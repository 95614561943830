import { Filter, SearchInput } from 'react-admin';

const ConversationListFilter = props => {
  return (
    <>
      <Filter {...props}>
        <SearchInput source="q" alwaysOn />
      </Filter>
    </>
  );
};
export default ConversationListFilter;
