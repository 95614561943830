import {
  useListContext,
  TopToolbar,
  ExportButton,
  sanitizeListRestProps
} from 'react-admin';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles(theme => ({
  root: {
    justifyContent: 'flex-end!important',
    WebkitJustifyContent: 'flex-end!important',
    WebKitBoxPack: 'end!important',
    width: '100%'
  },
  createButton: {
    color: '#4353ff',
    fontSize: '0.8125rem',
    padding: '4px',
    border: 'none',
    background: 'none',
    boxShadow: 'none',
    float: 'right',
    margin: '0 10px 0 0'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '600px'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  formGroup: { display: 'flex', flexDirection: 'row', width: '100%' },
  buttonWrapper: { display: 'flex' }
}));

const TutorListActions = props => {
  const classes = useStyles();
  const history = useHistory();
  const { className, maxResults, ...rest } = props;
  const { total } = useListContext();

  return (
    <TopToolbar className={classes.root} {...sanitizeListRestProps(rest)}>
      <Button
        variant="contained"
        color="default"
        className={classes.createButton}
        startIcon={<AddIcon />}
        onClick={r => history.push(`/tutors/create`)}>
        Add Tutor
      </Button>
      <ExportButton disabled={total === 0} maxResults={maxResults} />
    </TopToolbar>
  );
};

export default TutorListActions;
