import { List, Pagination, FunctionField } from 'react-admin';
import StudentFilter from './StudentsComponents/StudentFilter';
import StudentDataGrid from './StudentsComponents/StudentDataGrid';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import StudentListActions from './StudentsComponents/StudentListActions';

const useStyles = makeStyles(theme => ({
  root: {
    margin: '12px',
    minHeight: '350px'
  }
}));

const StudentPagination = props => (
  <Pagination rowsPerPageOptions={[20, 30, 40, 50]} {...props} />
);

const StudentsList = props => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <List
        title="Students"
        filters={<StudentFilter />}
        pagination={<StudentPagination />}
        bulkActionButtons={false}
        perPage={20}
        actions={<StudentListActions />}
        sort={{ field: 'TopicPathwayCollectionStudentId', order: 'DESC' }}
        {...props}>
        <StudentDataGrid rowClick="show">
          <FunctionField
            source="topicPathwayCollectionStudentId"
            sortByOrder="DESC"
            label="Student ID"
            sortBy="TopicPathwayCollectionStudentId"
            render={r => `#${r.id}`}
          />
          <FunctionField
            label="Current Level"
            reference="students"
            sortBy="CurrentLevel"
            render={r => r.currentLevel ?? 'N/A'}
          />
          <FunctionField
            label="Year Group"
            reference="students"
            sortable={false}
            render={r => r.yearGroupName ?? 'N/A'}
          />
          <FunctionField
            label="Name"
            reference="students"
            sortBy="Student.User.FirstName, Student.User.LastName"
            render={r => `${r.firstName} ${r.lastName}`}
          />
          <FunctionField
            label="Email"
            reference="students"
            sortBy="Student.User.Email"
            render={r => r.email ?? 'N/A'}
          />
          <FunctionField
            label="Username"
            reference="students"
            sortBy="Student.User.UserName"
            render={r => r.userName ?? 'N/A'}
          />
          <FunctionField
            label="Last Login"
            reference="students"
            sortBy="Student.User.UserSuccessfulLogInAttempt.DateUpdated"
            render={r =>
              r.dateLastLoggedIn
                ? moment(r.dateLastLoggedIn).format('YYYY/DD/MM')
                : '-'
            }
          />
          <FunctionField
            label="LevelBooster"
            reference="students"
            sortable={false}
            render={r =>
              r.dynamicQuizSummary?.dynamicQuizAnswerPattern ?? 'N/A'
            }
          />
        </StudentDataGrid>
      </List>
    </Box>
  );
};

export default StudentsList;
