const Square = props => {
  const { width, height, fill } = props;
  return (
    <svg id="square" height={height} width={width} {...props}>
      <rect width={width} height={height} fill={fill} />
    </svg>
  );
};

export default Square;
