import { makeStyles } from '@material-ui/core/styles';
import {
  Modal,
  Backdrop,
  Button,
  TextField,
  FormGroup
} from '@material-ui/core';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CancelIcon from '@material-ui/icons/Cancel';
import { Field, Form } from 'react-final-form';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '400px'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  container: {
    minWidth: '400px'
  },
  input: {
    width: '100%',
    margin: '0.5rem 0 1rem'
  },
  formControl: {
    width: '100%'
  },
  button: {
    float: 'right',
    margin: '0.5rem 0 0 1rem'
  }
}));

const CloneFlowTemplateModal = ({
  isModalOpen,
  setIsModalOpen,
  cloneFlowTemplate,
  templateSlug
}) => {
  const classes = useStyles();
  return (
    <Modal
      open={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      className={classes.modal}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
      closeAfterTransition>
      <div className={classes.paper}>
        <h3>Clone Flow Template</h3>
        <h4>Are you sure you want to clone {templateSlug}?</h4>
        <Form
          onSubmit={async values => {
            await cloneFlowTemplate(values);
            setIsModalOpen(false);
          }}
          initialValues={{ cloneTemplateSlug: '', templateSlug }}
          validate={values => {
            const errors = {};
            if (!values.cloneTemplateSlug) {
              errors.cloneTemplateSlug = 'New Template Slug Required';
            }
            if (values.cloneTemplateSlug === values.templateSlug) {
              errors.cloneTemplateSlug = 'New Template Slug must be different';
            }
            return errors;
          }}>
          {({ handleSubmit, submitting, pristine, submitError }) => (
            <form
              className={classes.container}
              noValidate
              onSubmit={handleSubmit}>
              <FormGroup>
                <Field name="cloneTemplateSlug">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label="New Template Slug"
                      className={classes.input}
                      InputLabelProps={{
                        shrink: true
                      }}
                      error={meta.error && meta.touched}
                      helperText={
                        meta.error && meta.touched ? meta.error : null
                      }
                    />
                  )}
                </Field>
              </FormGroup>
              <Button
                variant="contained"
                color="primary"
                size="large"
                className={classes.button}
                startIcon={<FileCopyIcon />}
                disabled={submitting || pristine || submitError}
                type="submit">
                Clone
              </Button>
              <Button
                variant="contained"
                disabled={submitting}
                className={classes.button}
                startIcon={<CancelIcon />}
                onClick={() => setIsModalOpen(false)}>
                Cancel
              </Button>
            </form>
          )}
        </Form>
      </div>
    </Modal>
  );
};

export default CloneFlowTemplateModal;
