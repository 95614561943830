import { makeStyles } from '@material-ui/core/styles';
import {
  Modal,
  Backdrop,
  Button,
  TextField,
  FormGroup
} from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import { useNotify } from 'react-admin';
import { useCallback, useEffect, useState } from 'react';
import { FlowLessonType } from 'Components/FlowItems';
import settings from '../../../../settings';
import useClipboard from 'Hooks/useClipboard';

const topicBasedLessonTypes = [
  FlowLessonType.ChallengeWorksheet,
  FlowLessonType.TopicPathwayQuiz,
  FlowLessonType.RetrievalPracticeTopic,
  FlowLessonType.QuestionOfTheDay
];

const calculateVisibleFields = values => [
  {
    name: 'templateSlug',
    isVisible: true,
    values
  },
  {
    name: 'lessonType',
    isVisible: true
  },
  {
    name: 'topicPathwayQuizId',
    isVisible: topicBasedLessonTypes.includes(values.lessonType)
  },
  {
    name: 'questionSequence',
    isVisible: [
      FlowLessonType.QuestionOfTheDay,
      FlowLessonType.TopicPathwayQuizQuestion
    ].includes(values.lessonType)
  }
];

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '400px'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  container: {
    minWidth: '400px'
  },
  input: {
    width: '100%',
    margin: '0.5rem 0 1rem'
  },
  formControl: {
    width: '100%'
  },
  button: {
    margin: '0.5rem 0 0'
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

const TestFlowTemplateModal = ({
  templateSlug,
  lessonType,
  isModalOpen,
  setIsModalOpen = () => {},
  flowItems = [],
  flowTemplates = [],
  ...props
}) => {
  const classes = useStyles();
  const notify = useNotify();

  const { handleClipboardClick } = useClipboard({
    onSuccess: () => notify('URL Copied to clipboard!', 'success')
  });

  const getUrl = useCallback(
    values => {
      const token = localStorage.getItem('authToken');
      if (!token) throw new Error('Unauthorized request!');
      const url = `${settings.TEACHER_APP_URL}/flow-template/${
        values.templateSlug
      }/${lessonType}${
        values.topicPathwayQuizId ? `/${values.topicPathwayQuizId}` : ''
      }${values.questionSequence ? `/${values.questionSequence}` : ''}`;
      return url + `?token=${token}`;
    },
    [lessonType]
  );

  const openTestTemplate = useCallback(
    async values => {
      try {
        window.open(getUrl(values), '_blank');
        setIsModalOpen(false);
      } catch (e) {
        notify(e.message);
        console.error(e);
      }
    },
    [getUrl, notify, setIsModalOpen]
  );

  const [fields, setFields] = useState(() =>
    calculateVisibleFields({ templateSlug, lessonType })
  );

  const onChange = useCallback((onChange, name, value, values) => {
    onChange(value);
    setFields(() => calculateVisibleFields(values));
  }, []);

  const isVisible = name => {
    const field = fields.find(f => f.name === name);
    if (!field) return true;
    return field?.isVisible;
  };

  useEffect(() => {
    if (!isModalOpen) return;

    if (!flowItems?.length) {
      notify('No flow items found', 'warning');
      setIsModalOpen(false);
      return;
    }

    if (flowItems.length && flowTemplates.length) {
      for (const flowTemplate of flowTemplates
        .filter(t => !!t.flowItemTemplateSlug)
        .map(t => t.flowItemTemplateSlug)) {
        if (!flowItems.find(i => i.templateSlug === flowTemplate)) {
          notify('Flow template not found', 'warning');
          setIsModalOpen(false);
          return;
        }
      }
    }

    setFields(() => calculateVisibleFields({ templateSlug, lessonType }));
  }, [
    templateSlug,
    lessonType,
    flowItems.length,
    isModalOpen,
    notify,
    setIsModalOpen,
    flowItems,
    flowTemplates
  ]);

  return (
    <Modal
      {...props}
      open={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      className={classes.modal}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
      closeAfterTransition>
      <div className={classes.paper}>
        <h3>Test Flow Template</h3>
        <Form
          onSubmit={async values => {
            openTestTemplate(values);
          }}
          initialValues={{ templateSlug, lessonType }}
          validate={values => {
            const errors = {};
            if (!values.templateSlug) {
              errors.templateSlug = 'New Template Slug Required';
            }
            if (values.questionSequence < 0) {
              errors.questionSequence = 'Question Sequence must be positive';
            }
            if (values.questionSequence > 30) {
              errors.questionSequence =
                'Question Sequence must be less than 30';
            }
            if (values.topicPathwayQuizId < 0) {
              errors.topicPathwayQuizId = 'TopicPathwayQuizId must be positive';
            }
            if (
              topicBasedLessonTypes.includes(values.lessonType) &&
              !values.topicPathwayQuizId
            ) {
              errors.topicPathwayQuizId = 'TopicPathwayQuizId is required';
            }
            return errors;
          }}>
          {({ handleSubmit, submitting, submitError, values }) => (
            <form
              className={classes.container}
              noValidate
              onSubmit={handleSubmit}>
              <FormGroup className={classes.formControl}>
                <Field name="templateSlug">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label="Template Slug"
                      className={classes.input}
                      InputLabelProps={{
                        shrink: true
                      }}
                      error={meta.error && meta.touched}
                      helperText={
                        meta.error && meta.touched ? meta.error : null
                      }
                    />
                  )}
                </Field>
              </FormGroup>
              <FormGroup className={classes.formControl}>
                <p style={{ pading: 0, margin: '0 0 0.5rem 0' }}>
                  Lesson Type: {lessonType}
                </p>
              </FormGroup>
              {isVisible('topicPathwayQuizId') ? (
                <FormGroup className={classes.formControl}>
                  <Field name="topicPathwayQuizId">
                    {({ input, meta }) => (
                      <TextField
                        {...input}
                        label="TopicPathwayQuizId"
                        className={classes.input}
                        InputLabelProps={{
                          shrink: true
                        }}
                        onChange={e =>
                          onChange(
                            input.onChange,
                            'topicPathwayQuizId',
                            e.target.value,
                            values
                          )
                        }
                        error={meta.error && meta.touched}
                        helperText={
                          meta.error && meta.touched ? meta.error : null
                        }
                      />
                    )}
                  </Field>
                </FormGroup>
              ) : null}
              {isVisible('questionSequence') ? (
                <FormGroup className={classes.formControl}>
                  <Field name="questionSequence">
                    {({ input, meta }) => (
                      <TextField
                        {...input}
                        label="Question Sequence (optional)"
                        tyle="number"
                        className={classes.input}
                        InputLabelProps={{
                          shrink: true
                        }}
                        onChange={e =>
                          onChange(
                            input.onChange,
                            'questionSequence',
                            e.target.value,
                            values
                          )
                        }
                        error={meta.error && meta.touched}
                        helperText={
                          meta.error && meta.touched ? meta.error : null
                        }
                      />
                    )}
                  </Field>
                </FormGroup>
              ) : null}
              <div className={classes.buttonGroup}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  className={classes.button}
                  disabled={submitError}
                  type="submit">
                  Test
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  className={classes.button}
                  disabled={submitError}
                  onClick={() => handleClipboardClick(getUrl(values))}>
                  Copy URL
                </Button>
                <Button
                  variant="contained"
                  disabled={submitting}
                  className={classes.button}
                  onClick={() => setIsModalOpen(false)}>
                  Cancel
                </Button>
              </div>
            </form>
          )}
        </Form>
      </div>
    </Modal>
  );
};

export default TestFlowTemplateModal;
