import { Box } from '@material-ui/core';
import TabPanel from 'Components/TabPanel';
import EditFlowTemplateExpressionsForm from './EditFlowTemplateExpressionsForm';
import styled from 'styled-components';
import { Delete } from '@material-ui/icons';
import SaveConfirmButton from '../SaveConfirmButton';
import EditFlowTemplateForm from './EditFlowTemplateForm';

const EditFlowTemplate = ({
  flowTemplate,
  isLoading,
  flowTemplates = [],
  flowItemTemplates = [],
  updateFlowTemplate,
  insertFlowItem = () => {},
  updateFlowItem = () => {},
  deleteFlowTemplate,
  ...props
}) => {
  const tabs = [
    {
      label: 'Edit Flow Template Group',
      children: (
        <div {...props}>
          <EditFlowTemplateForm
            key={flowTemplate?.position}
            title={''}
            flowItems={flowItemTemplates}
            isLoading={isLoading}
            flowTemplate={flowTemplate}
            flowTemplates={flowTemplates}
            updateFlowTemplate={updateFlowTemplate}
            updateFlowItem={updateFlowItem}
            insertFlowItem={insertFlowItem}
            style={{ margin: '0 1.5rem 0 0' }}
          />
          {!!flowTemplate && (
            <SaveConfirmButton
              key={flowTemplate.position}
              isDoubleConfirm={true}
              submitting={isLoading}
              confirmCopy="Confirm Delete?"
              loadingCopy="Deleting..."
              buttonCopy="Delete"
              icon={<Delete />}
              pristine={false}
              style={{ width: '94%' }}
              onClick={() => deleteFlowTemplate(flowTemplate)}
            />
          )}
        </div>
      )
    },
    {
      label: `Edit Expressions (${
        flowTemplate?.skipToExpressions?.length ?? 0
      })`,
      children: (
        <EditFlowTemplateExpressionsForm
          flowTemplate={flowTemplate}
          flowTemplates={flowTemplates}
          updateFlowTemplate={updateFlowTemplate}
        />
      ),
      disabled: ![
        ...(flowTemplates?.filter(ft => ft.position > flowTemplate?.position) ??
          [])
      ].length
    }
  ];

  if (!flowTemplate)
    return (
      <Box>
        <NoTemplate>No template selected</NoTemplate>
      </Box>
    );

  return (
    <Box>
      <TabPanel tabs={tabs} />
    </Box>
  );
};

const NoTemplate = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem;
  color: #aaa;
`;

export default EditFlowTemplate;
