import { useSelector, useDispatch } from 'react-redux';
import { createContext, useEffect, useCallback } from 'react';
import Api from 'Api/Api';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  let user = useSelector(s => s.user);
  let isUserFetchedFromStore = false;
  if (!user) {
    const userItem = localStorage.getItem('user');
    if (userItem) {
      user = JSON.parse(userItem);
      isUserFetchedFromStore = true;
    }
  }
  const token = localStorage.getItem('authToken');
  const dispatch = useDispatch();

  const getSetUser = useCallback(async () => {
    if (user) return;
    const fetchedUser = await Api.getUserContext();
    if (fetchedUser?.isAdmin) {
      dispatch({ type: 'user/set', payload: fetchedUser });
    } else {
      localStorage.removeItem('authToken');
    }
  }, [dispatch, user]);

  useEffect(() => {
    async function runEffect() {
      if (!user && token) {
        await getSetUser();
      }
      if (isUserFetchedFromStore) {
        dispatch({ type: 'user/set', payload: user });
      }
    }
    runEffect();
  }, [user, token, getSetUser, isUserFetchedFromStore, dispatch]);

  if (!token && !window.location.href.includes('login')) {
    window.location.href = '/login';
  }

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
};
