import {
  Select,
  InputLabel,
  MenuItem,
  TextField,
  FormGroup,
  Button,
  Box,
  Chip,
  Typography
} from '@material-ui/core';
import styled from 'styled-components';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { useState } from 'react';
import CancelIcon from '@material-ui/icons/Cancel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Field, Form } from 'react-final-form';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import SaveConfirmButton from 'Pages/Flows/Components/SaveConfirmButton';
import languageHelpers from 'Helpers/languageHelpers';
import Loading from 'Pages/Flows/Components/Loading';
import cloneDeep from 'lodash/cloneDeep';
import ToolTip from 'Components/ToolTip';
import { truncateString } from 'Helpers/stringHelpers';

const EditTopicTagForm = ({
  topic = {},
  topicTagIds = [],
  topicTags = [],
  allTopicTags = [],
  fetchTopicTags = () => {},
  deleteTopicTag = () => {},
  updateTopicTag = () => {},
  updateTopicPathwayQuiz = () => {},
  locale = 'en-GB',
  isUpdatingTags = false,
  ...props
}) => {
  const formatTags = tags =>
    tags?.length
      ? cloneDeep([
          ...tags
            .sort((a, b) => b.topicTagId - a.topicTagId)
            .sort((a, b) => a.parentId - b.parentId)
        ]).map(t => {
          t.tag =
            !!t.parentId && !t.tag.includes('>')
              ? `${
                  allTopicTags.find(t2 => t2.topicTagId === t.parentId)?.tag
                } > ${t.tag}`
              : t.tag;
          return t;
        })
      : [];

  const [optionIdx, setOptionIdx] = useState(null);
  const [filteredTopicTags, setFilteredTopicTags] = useState(
    topicTags.map(t => t.tag)
  );
  const formattedTopicTags = formatTags(topicTags);
  const currentTags = formatTags(
    allTopicTags?.filter(t => topicTagIds?.indexOf(t.topicTagId) > -1)
  );
  const [searchTopicTags, setSearchTopicTags] = useState(
    formatTags(topicTags).filter(t => topicTagIds?.indexOf(t.topicTagId) === -1)
  );

  if (isUpdatingTags) return <Loading />;

  return (
    <Root>
      <StyledFormGroup>
        <InputLabel id="locales-label">Locale</InputLabel>
        <Select
          name="locales"
          labelId="locales-label"
          onChange={e => {
            fetchTopicTags(e.target.value);
            setOptionIdx(null);
          }}
          value={locale}>
          {languageHelpers.locales.map((locale, idx) => (
            <MenuItem key={idx} value={locale.code}>
              {locale.name}
            </MenuItem>
          ))}
        </Select>
      </StyledFormGroup>
      {!!formattedTopicTags?.length ? (
        <StyledFormGroup>
          <InputLabel id="topicTags-label">Topic Tags</InputLabel>
          <Select
            name="topicTags"
            labelId="topicTags-label"
            onChange={e => setOptionIdx(e.target.value)}
            value={optionIdx}>
            {formattedTopicTags.map((topicTag, idx) => (
              <MenuItem key={idx} value={idx}>
                <StyledTag>{topicTag.tag}</StyledTag>
              </MenuItem>
            ))}
          </Select>
        </StyledFormGroup>
      ) : (
        <NotFound>No topic tags found</NotFound>
      )}
      {optionIdx !== null && (
        <FormRoot>
          {topicTags[optionIdx] ? (
            <Typography variant="h5">Edit Tag</Typography>
          ) : (
            <Typography variant="h5">Add New Tag</Typography>
          )}
          <StyledForm
            onSubmit={async e => {
              e.preventDefault();
              console.log('form submitted!', e);
            }}
            initialValues={
              topicTags[optionIdx] || {
                tag: '',
                locale,
                parentId: null
              }
            }
            validate={values => {
              const errors = {};
              if (!values.tag?.length) {
                errors.sequence = 'Please enter a tag';
              }
              if (!values.locale?.length) {
                errors.labelOptions = 'Please enter a locale';
              }
              if (
                !values.topicTagId &&
                !!topicTags.find(
                  t => t.tag.toLowerCase() === values?.tag?.toLowerCase()
                )
              ) {
                errors.tag = 'Tag already exists';
              }
              if (
                !!values.parentId &&
                !topicTags.find(t => t.topicTagId === values.parentId)
              ) {
                errors.parentId = 'Invalid parent tag';
              }
              return errors;
            }}>
            {({ handleSubmit, submitting, pristine, values }) => (
              <form onSubmit={handleSubmit} {...props}>
                <FormGroup>
                  <Field name="tag">
                    {({ input, meta }) => (
                      <>
                        <Autocomplete
                          {...input}
                          disableClearable
                          error={meta.error && meta.touched}
                          onChange={(e, value) => {
                            input.onChange(value);
                            setFilteredTopicTags(
                              topicTags
                                .filter(t =>
                                  t.tag
                                    .toLowerCase()
                                    .includes(value?.toLowerCase())
                                )
                                .map(t => t.tag)
                            );
                          }}
                          onInput={e => {
                            input.onChange(e.target.value);
                            setFilteredTopicTags(
                              topicTags
                                .filter(t =>
                                  t.tag
                                    .toLowerCase()
                                    .includes(e.target.value?.toLowerCase())
                                )
                                .map(t => t.tag)
                            );
                          }}
                          filterOptions={(options, params) => {
                            if (params.inputValue !== '') {
                              const exactMatch = options.includes(
                                params.inputValue
                              );
                              if (!exactMatch) {
                                options.push(`Add "${params.inputValue}"`);
                              }
                            }

                            return options;
                          }}
                          selectOnFocus
                          clearOnBlur
                          handleHomeEndKeys
                          options={filteredTopicTags || []}
                          getOptionLabel={option => {
                            if (typeof option === 'string') {
                              return option;
                            }
                            return '';
                          }}
                          renderOption={option => {
                            return option;
                          }}
                          freeSolo
                          renderInput={params => (
                            <TextField {...params} label="Tag" />
                          )}
                        />
                      </>
                    )}
                  </Field>
                </FormGroup>
                <StyledFormGroup>
                  <Field name="locale">
                    {({ input, meta }) => (
                      <>
                        <InputLabel id="locales-label">Locale</InputLabel>
                        <Select
                          {...input}
                          error={meta.error && meta.touched}
                          labelId="locales-label"
                          value={values.locale}>
                          {languageHelpers.locales.map((locale, idx) => (
                            <MenuItem key={idx} value={locale.code}>
                              {locale.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </>
                    )}
                  </Field>
                </StyledFormGroup>
                {!!topicTags?.filter(
                  t => !t.parentId && t.topicTagId !== values.topicTagId
                )?.length ? (
                  <StyledFormGroup>
                    <Field name="parentId">
                      {({ input, meta }) => (
                        <>
                          <InputLabel id="parentId-label">
                            Parent Tag
                          </InputLabel>
                          <Select
                            {...input}
                            error={meta.error && meta.touched}
                            labelId="parentId-label">
                            {topicTags
                              ?.filter(
                                t =>
                                  !t.parentId &&
                                  t.topicTagId !== values.topicTagId
                              )
                              ?.map((topicTag, idx) => (
                                <MenuItem key={idx} value={topicTag.topicTagId}>
                                  {topicTag.tag}
                                </MenuItem>
                              ))}
                          </Select>
                        </>
                      )}
                    </Field>
                  </StyledFormGroup>
                ) : null}
                <ButtonWrapper>
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ margin: '0.5rem 0 0 0' }}
                      endIcon={<AddCircleOutlineIcon />}
                      onClick={() => {
                        updateTopicTag(values);
                        setOptionIdx(null);
                      }}
                      disabled={submitting || pristine}>
                      {!topicTags[optionIdx] ? 'Add' : 'Update'}
                    </Button>
                  </div>
                  {!!topicTags[optionIdx] && (
                    <div>
                      <SaveConfirmButton
                        key={optionIdx}
                        isDoubleConfirm={true}
                        submitting={submitting}
                        confirmCopy="Confirm Delete?"
                        loadingCopy="Deleting..."
                        buttonCopy="Delete"
                        icon={<DeleteForeverIcon />}
                        pristine={false}
                        onClick={() => {
                          setOptionIdx(null);
                          deleteTopicTag(topicTags[optionIdx].topicTagId);
                        }}
                      />
                    </div>
                  )}
                  <div>
                    <Button
                      variant="contained"
                      color="secondary"
                      endIcon={<CancelIcon />}
                      style={{ margin: '0.5rem 0 0 0' }}
                      onClick={() => setOptionIdx(null)}>
                      Cancel
                    </Button>
                  </div>
                </ButtonWrapper>
              </form>
            )}
          </StyledForm>
        </FormRoot>
      )}
      {optionIdx === null ? (
        <Button
          variant="contained"
          color="secondary"
          onClick={() =>
            setOptionIdx(!optionIdx ? topicTags?.length + 1 : null)
          }
          endIcon={<AddCircleOutlineIcon />}>
          Add Tag
        </Button>
      ) : null}
      <FormRoot>
        <Typography variant="h5">Topic Tags</Typography>
        <Autocomplete
          disableClearable
          onChange={(e, value) => {
            const foundTags = formatTags(topicTags)
              .filter(t => t.tag.toLowerCase().includes(value?.toLowerCase()))
              .map(t => t)
              .filter(t => topicTagIds?.indexOf(t.topicTagId) === -1);
            setSearchTopicTags(() => foundTags);
            if (foundTags.length === 1) {
              updateTopicPathwayQuiz(
                {
                  ...topic,
                  topicPathwayQuizTagIds: [
                    ...topic.topicTagIds,
                    foundTags[0].topicTagId
                  ]
                },
                true
              );
              setSearchTopicTags(() =>
                formatTags(topicTags).filter(
                  t =>
                    t.topicTagId !== foundTags[0].topicTagId &&
                    topicTagIds?.indexOf(t.topicTagId) === -1
                )
              );
            }
          }}
          value={''}
          onInput={e => {
            setSearchTopicTags(() =>
              topicTags
                .filter(t =>
                  t.tag.toLowerCase().includes(e.target.value?.toLowerCase())
                )
                .map(t => t)
                .filter(t => topicTagIds?.indexOf(t.topicTagId) === -1)
            );
          }}
          filterOptions={(options, params) => {
            if (params.inputValue !== '') {
              const exactMatch = options.includes(params.inputValue);
              if (!exactMatch) {
                options.push(`"${params.inputValue}" Not found`);
              }
            }

            return options;
          }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          options={searchTopicTags?.map(t => t.tag) || []}
          getOptionLabel={option => {
            if (typeof option === 'string') {
              return option;
            }
            return '';
          }}
          renderOption={option => {
            return option;
          }}
          freeSolo
          renderInput={params => (
            <TextField {...params} label="Search for tag" />
          )}
        />
        <TagBox style={{ margin: '0.25rem 0 0.25rem 0' }}>
          {currentTags.map((topicTag, idx) => (
            <div>
              <ToolTip text={topicTag.tag.length > 55 ? topicTag.tag : ''}>
                <StyledChip
                  key={idx}
                  label={truncateString(topicTag.tag, 55, '...')}
                  onDelete={() => {
                    updateTopicPathwayQuiz(
                      {
                        ...topic,
                        topicPathwayQuizTagIds: topicTagIds.filter(
                          t => t !== topicTag.topicTagId
                        )
                      },
                      true
                    );
                    setSearchTopicTags(() =>
                      formatTags(topicTags).filter(
                        t => topicTagIds?.indexOf(t.topicTagId) === -1
                      )
                    );
                  }}
                  deleteIcon={<CancelIcon />}
                />
              </ToolTip>
            </div>
          ))}
        </TagBox>
      </FormRoot>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  div {
    word-break: break-all;
  }
  margin: 0.25rem 0 0.25rem 0;
`;

const StyledForm = styled(Form)`
  button {
    margin: 0.5rem;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledFormGroup = styled(FormGroup)`
  margin: 0.25rem 0 0.25rem 0;
`;

const StyledTag = styled.div``;

const NotFound = styled.div`
  padding: 1rem;
  background: #eee;
  text-align: center;
  margin: 0.5rem 0 0.5rem 0;
  border-radius: 0.25rem;
`;

const FormRoot = styled.div`
  padding: 1rem;
  background: #eee;
  margin: 0.5rem 0 0.5rem 0;
  border-radius: 0.25rem;
`;

const StyledChip = styled(Chip)`
  margin: 0.25rem 0.25rem 0.25rem 0;
  cursor: pointer;
`;

const TagBox = styled(Box)`
  display: flex;
  overflow: hidden;
  width: 100%;
  flex-wrap: wrap;
`;

export default EditTopicTagForm;
