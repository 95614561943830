import React, { useEffect, useState } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import styled from 'styled-components';

const MultipleSelect = ({
  label = 'Options',
  opts = [],
  onChange = () => {},
  name = 'multi-select',
  preSelectedOptions = [],
  ...props
}) => {
  const [selectedOptions, setSelectedOptions] = useState(preSelectedOptions);

  const handleChange = event => {
    setSelectedOptions(event.target.value);
    onChange(event.target.value);
  };

  useEffect(() => {
    setSelectedOptions(preSelectedOptions);
  }, [preSelectedOptions]);

  if (!opts.length) return null;

  return (
    <FormControl {...props}>
      {label && <InputLabel>{label}</InputLabel>}
      <Select
        multiple
        value={selectedOptions}
        onChange={handleChange}
        name={name}
        renderValue={selected => (
          <SelectedContainer>
            {selected.map(s => (
              <SelectedItem>{s}</SelectedItem>
            ))}
          </SelectedContainer>
        )}>
        {opts.map(opt => (
          <MenuItem key={opt.label} disabled={opt.disabled} value={opt.value}>
            {opt.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const SelectedContainer = styled.div`
  display: flex;
`;

const SelectedItem = styled.div`
  background-color: #ccc;
  border-radius: 4px;
  color: #000;
  padding: 0.25rem;
  margin: 0 0.25rem 0 0.25rem;
`;

export default MultipleSelect;
