import React, { useState, useEffect, useCallback } from 'react';
import {
  TextField,
  CircularProgress,
  Button,
  FormGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Api from 'Api/Api';

const MisconceptionTagSelect = ({ value, onChange, ...props }) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [dialogValue, setDialogValue] = useState('');
  const [open, toggleOpen] = useState(false);

  const getOptions = useCallback(async () => {
    if (inputValue.length < 3) {
      return setOptions([]);
    }

    try {
      setLoading(true);
      const response = await Api.getEmbeddingsSearch(inputValue);
      setOptions(
        response.data.map(r => ({
          embeddingOutputId: r.embeddingOutputId,
          searchText: r.searchText
        }))
      );
    } catch (e) {
    } finally {
      setLoading(false);
    }
  }, [inputValue]);

  const addOption = event => {
    event.preventDefault();
    onChange({
      searchText: dialogValue
    });

    toggleOpen(false);
  };

  useEffect(() => {
    const delayGetOptions = setTimeout(getOptions, 500);
    return () => clearTimeout(delayGetOptions);
  }, [inputValue, getOptions]);

  return (
    <>
      <Autocomplete
        {...props}
        options={options}
        loading={loading}
        value={value}
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              toggleOpen(true);
              setDialogValue(newValue);
            });
          } else if (newValue && newValue.inputValue) {
            toggleOpen(true);
            setDialogValue(newValue.inputValue);
          } else {
            onChange(newValue);
          }
        }}
        onInputChange={(event, newValue) => {
          setInputValue(newValue);
        }}
        getOptionLabel={option => {
          if (typeof option === 'string') {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.searchText;
        }}
        freeSolo
        getOptionSelected={(option, value) =>
          option.embeddingOutputId === value.embeddingOutputId
        }
        filterOptions={(options, params) => {
          if (params.inputValue !== '') {
            const exactMatch = options.includes(params.inputValue);

            if (!exactMatch) {
              options.push({
                inputValue: params.inputValue,
                searchText: `Add "${params.inputValue}"`
              });
            }
          }

          return options;
        }}
        id="misconception-tag"
        selectOnFocus={false}
        renderOption={option => option.searchText}
        renderInput={params => (
          <TextField
            {...params}
            label="Misconception tag"
            variant="outlined"
            multiline
            maxRows={4}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              )
            }}
          />
        )}
      />
      <Dialog
        open={open}
        onClose={() => toggleOpen(false)}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          Add a new misconception tag
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please make sure you've searched the list before adding something
            new!
          </DialogContentText>
          <FormGroup>
            <TextField
              autoFocus
              variant="outlined"
              id="name"
              value={dialogValue}
              onChange={event => setDialogValue(event.target.value)}
              label="Add misconception tag"
              type="text"
            />
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => toggleOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={addOption} type="submit" color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MisconceptionTagSelect;
