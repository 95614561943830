import { Filter, SearchInput } from 'react-admin';
import { FormControl, Select, MenuItem } from '@material-ui/core';
import { useCallback, useEffect } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  searchInput: {
    minWidth: '450px'
  },
  collectionSelect: {
    minHeight: '36px'
  },
  createButton: {
    color: '#4353ff',
    fontSize: '0.8125rem',
    padding: '4px',
    border: 'none',
    background: 'none',
    boxShadow: 'none',
    float: 'right',
    margin: '0 10px 0 0'
  },
  selectControl: {
    margin: '0 0 0 1rem',
    display: 'flex',
    alignSelf: 'center',
    minWidth: '250px'
  },
  clearButton: {
    lineHeight: '1rem',
    color: '#4353ff',
    fontSize: '0.8125rem',
    padding: '4px',
    border: 'none',
    background: 'none',
    boxShadow: 'none',
    float: 'right'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '600px'
  },
  formGroup: { display: 'flex', flexDirection: 'row', width: '100%' },
  buttonWrapper: { display: 'flex' }
}));

const EmbeddingOutputFilters = props => {
  const classes = useStyles();
  const { filterValues, setFilters } = props;

  const savefilters = useCallback(
    updatedFilterValues => {
      if (updatedFilterValues['embeddingType']?.Value === '')
        delete updatedFilterValues['embeddingType'];
      setFilters(updatedFilterValues);
    },
    [setFilters]
  );

  useEffect(() => {
    if (!filterValues['embeddingType']) {
      savefilters({
        ...filterValues,
        embeddingType: {
          id: 'embeddingType',
          Value: 1,
          Operator: 'Eq',
          Type: 'int'
        }
      });
    }
  }, [filterValues, savefilters]);

  return (
    <>
      <Filter {...props}>
        <SearchInput source="q" alwaysOn className={classes.searchInput} />
      </Filter>
      <FormControl className={classes.selectControl}>
        <Select
          name="collection"
          className={classes.collectionSelect}
          value={filterValues['embeddingType']?.Value ?? 1}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          onClick={e =>
            savefilters({
              ...filterValues,
              embeddingType: {
                id: 'embeddingType',
                Value: e.target.value ?? '',
                Operator: 'Eq',
                Type: 'int'
              }
            })
          }>
          {[
            { key: 'MisconceptionTag', value: 1 },
            { key: 'Question', value: 2 },
            { key: 'Quiz', value: 3 },
            { key: 'TopicPathwayQuiz', value: 4 },
            { key: 'QuestionAnswerTeacherExplanation', value: 5 }
          ].map((s, idx) => (
            <MenuItem
              key={idx}
              value={s.value}
              disabled={s.disabled ? true : false}>
              {s.key}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};
export default EmbeddingOutputFilters;
