import { makeStyles } from '@material-ui/core/styles';
import {
  Modal,
  Backdrop,
  Container,
  Card,
  CardContent,
  Typography
} from '@material-ui/core';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useCallback, React, useState, useEffect } from 'react';
import { useDataProvider, useNotify, useRefresh } from 'react-admin';
import { useLocation, useHistory } from 'react-router-dom';
import TutorForm from './TutorForm';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  create: {
    margin: '1rem 0 0 0'
  },
  input: {
    margin: '1rem 0 0 0'
  }
}));

const EditTutorModal = ({ isOpen, setIsOpen }) => {
  const location = useLocation();
  const history = useHistory();
  const tutorId = location.pathname.split('/').reverse()[0];
  const notify = useNotify();
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const [tutor, setTutor] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);

  const fetchTutor = useCallback(
    async tutorId => {
      try {
        if (!tutorId) return;

        const tutor = await dataProvider.getOne('tutors', {
          id: tutorId
        });

        if (!tutor?.data) throw new Error(`Unable to fetch topic ${tutorId}`);

        setTutor(() => tutor.data);
      } catch (e) {
        notify('Error fetching topic!', 'error');
        console.error(e);
      }
    },
    [dataProvider, notify]
  );

  const updateTutor = useCallback(
    async values => {
      try {
        values.description = values.description?.length
          ? values.description
          : '';
        await dataProvider.update('tutors', {
          id: values.tutorId,
          body: values
        });
        refresh();
        setIsOpen();
        notify('Tutor Updated!', 'success');
      } catch (e) {
        notify('Error updating tutor!', 'error');
        console.error(e);
      }
    },
    [dataProvider, notify, refresh, setIsOpen]
  );

  useEffect(() => {
    const runEffect = async () => {
      if (isLoaded || !isOpen) return;
      tutorId && (await fetchTutor(tutorId));
      setIsLoaded(true);
    };
    runEffect();
  }, [fetchTutor, isLoaded, isOpen, tutorId]);

  return (
    <Modal
      open={isOpen}
      onClose={() => setIsOpen()}
      className={classes.modal}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
      closeAfterTransition>
      <Container maxWidth="md">
        <Card className={classes.create}>
          <CardContent>
            <Typography variant="h5" gutterBottom>
              Edit Tutor
            </Typography>
            <TutorForm
              onSubmit={updateTutor}
              onCancel={() => history.push('/tutors')}
              tutor={tutor}
            />
          </CardContent>
        </Card>
      </Container>
    </Modal>
  );
};

export default EditTutorModal;
