import {
  Card,
  CardContent,
  Container,
  Typography,
  Button,
  FormGroup,
  Select,
  FormLabel,
  Backdrop,
  Modal,
  MenuItem
} from '@material-ui/core';
import Api from 'Api/Api';
import { useCallback } from 'react';
import { useNotify, Notification } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Form, Field } from 'react-final-form';
import StarsIcon from '@material-ui/icons/Stars';

const useStyles = makeStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  input: {
    margin: '1rem 0 0 0'
  }
});

const awardActions = [
  {
    value: 'Awarded50',
    key: '50 Coins'
  },
  {
    value: 'Awarded100',
    key: '100 Coins'
  },
  {
    value: 'Awarded150',
    key: '150 Coins'
  },
  {
    value: 'Awarded200',
    key: '200 Coins'
  },
  {
    value: 'Awarded250',
    key: '250 Coins'
  },
  {
    value: 'Awarded300',
    key: '300 Coins'
  },
  {
    value: 'Awarded350',
    key: '350 Coins'
  },
  {
    value: 'Awarded400',
    key: '400 Coins'
  },
  {
    value: 'Awarded450',
    key: '450 Coins'
  },
  {
    value: 'Awarded500',
    key: '500 Coins'
  }
];

const AwardsModal = ({
  isOpen,
  setIsOpen = () => {},
  studentUserId,
  onAwarded = () => {}
}) => {
  const classes = useStyles();
  const notify = useNotify();
  const award = useCallback(
    async values => {
      try {
        await Api.bulkUpdateUserEvents({
          action: values.awardAction,
          userIds: [studentUserId]
        });

        const awardObj = awardActions.find(a => a.value === values.awardAction);
        onAwarded(awardObj.key);

        setIsOpen(false);
        notify('Coins awarded!', 'success');
      } catch (e) {
        console.error(e);
        notify('Error: Awarding coins failed.', 'warning');
      }
    },
    [notify, onAwarded, setIsOpen, studentUserId]
  );

  if (!studentUserId) return;

  return (
    <Modal
      open={isOpen}
      onClose={() => setIsOpen(false)}
      className={classes.modal}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
      closeAfterTransition>
      <Container maxWidth="md">
        <Card className={classes.create}>
          <CardContent>
            <Form
              onSubmit={award}
              initialValues={{
                awardAction: null
              }}
              validate={values => {
                const errors = {};
                if (values.awardAction === null) {
                  errors.awardAction = 'Please select an award action.';
                }
                return errors;
              }}>
              {({ handleSubmit, submitting, pristine, submitError }) => (
                <form onSubmit={handleSubmit}>
                  <Typography variant="h5" gutterBottom>
                    Award Student Coins
                  </Typography>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ textAlign: 'left' }}>
                    UserId: {studentUserId}
                  </Typography>
                  <FormGroup style={{ margin: '1rem 0 1rem 0' }}>
                    <FormLabel>Award Actions</FormLabel>
                    <Field name="awardAction">
                      {({ input, meta }) => (
                        <Select
                          {...input}
                          name="awardAction"
                          error={meta.error && meta.touched}>
                          {awardActions.map((s, idx) => (
                            <MenuItem key={idx} value={s.value}>
                              {s.key}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    </Field>
                  </FormGroup>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    type="submit"
                    className={classes.createButton}
                    startIcon={<StarsIcon />}
                    disabled={submitting || pristine}>
                    Send Awards
                  </Button>
                </form>
              )}
            </Form>
            <Notification />
          </CardContent>
        </Card>
      </Container>
    </Modal>
  );
};

export default AwardsModal;
