import { makeStyles } from '@material-ui/core/styles';
import { Container, Button } from '@material-ui/core';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import Api from 'Api/Api';
import { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useNotify } from 'ra-core/esm/sideEffect';
import React from 'react';
import * as dates from 'date-arithmetic';
import { isEmpty } from 'lodash';
import Week from './Week';
import useGetAllTutors from 'Hooks/useGetAllTutors';
import EditTutorRotaModal from './EditTutorRotaModal';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '1rem'
  },
  container: {
    minWidth: '400px'
  },
  button: {
    float: 'right',
    margin: '0.5rem 0 0 1rem'
  },
  copyButton: {
    marginLeft: 'auto'
  }
}));

const StyledCalendar = styled(Calendar)``;

const Tutors = () => {
  const notify = useNotify();
  const startOfWeek = moment().startOf('week');
  const localizer = momentLocalizer(moment);
  const classes = useStyles();
  const [events, setEvents] = useState([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [dateActiveFrom, setDateActiveFrom] = useState(startOfWeek.toDate());
  const tutors = useGetAllTutors();

  const getTutorRotas = useCallback(
    async (dateActiveFrom = null, dateActiveTo = null, tutorIds = []) => {
      try {
        const tutorRotas = await Api.getTutorRotas(
          !dateActiveFrom ? null : moment(dateActiveFrom).format(),
          !dateActiveTo ? null : moment(dateActiveTo).format(),
          tutorIds
        );

        if (isEmpty(tutorRotas)) {
          notify('No tutor rotas found for this week!', 'warning');
          setEvents([]);
          return;
        }

        setEvents(
          tutorRotas?.map(t => ({
            title: `${t.tutorFullname}`,
            start: new Date(t.dateActiveFrom),
            end: new Date(t.dateActiveTo),
            resource: t
          }))
        );
      } catch (e) {
        notify(e.toString(), 'warning');
      }
    },
    [notify]
  );

  const updateTutorRota = useCallback(
    async (
      tutorId,
      tutorRotaId = null,
      dateActiveFrom = null,
      dateActiveTo = null
    ) => {
      try {
        setIsEditModalOpen(false);
        const tutorRota = await Api.upsertTutorRota(
          tutorId,
          tutorRotaId,
          !dateActiveFrom ? null : moment(dateActiveFrom).format(),
          !dateActiveTo ? null : moment(dateActiveTo).format()
        );

        if (isEmpty(tutorRota)) {
          notify('Tutor rota not updated! Check error logs.', 'warning');
          return;
        }

        const filteredEvents = events.filter(
          e => e.resource?.tutorRotaId !== tutorRota.tutorRotaId
        );

        if (tutorRota.dateDeleted) {
          notify('Entry deleted!', 'warning');
          setEvents(filteredEvents);
          return;
        }

        filteredEvents.push({
          title: `${tutorRota.tutorFullname}`,
          start: new Date(tutorRota.dateActiveFrom),
          end: new Date(tutorRota.dateActiveTo),
          resource: tutorRota
        });

        setEvents(filteredEvents);
      } catch (e) {
        console.error(e);
        notify(e.toString(), 'warning');
      }
    },
    [events, notify]
  );

  const onNavigate = async dateActiveFrom => {
    setDateActiveFrom(dateActiveFrom);
    await getTutorRotas(dateActiveFrom, dates.add(dateActiveFrom, 8, 'day'));
  };

  const onSelectEvent = event => {
    if (selectedEvent?.resource?.tutorRotaId === event.resource.tutorRotaId) {
      setIsEditModalOpen(false);
      return;
    }
    setSelectedEvent(event);
    setIsEditModalOpen(true);
  };

  const onSelectSlot = slot => {
    setSelectedEvent({
      start: slot.start,
      end: slot.end
    });
    setIsEditModalOpen(true);
  };

  const copyPreviousWeek = useCallback(async () => {
    try {
      const previousActiveFrom = dates.add(dateActiveFrom, -7, 'day');
      const previousWeeksRota = await Api.getTutorRotas(
        moment(previousActiveFrom).format(),
        moment(dateActiveFrom).format()
      );
      const tutorRotasToUpsert = [];
      previousWeeksRota.forEach(async r => {
        tutorRotasToUpsert.push({
          tutorId: r.tutorId,
          dateActiveFrom: moment(
            dates.add(new Date(r.dateActiveFrom), 7, 'day')
          ).format(),
          dateActiveTo: moment(
            dates.add(new Date(r.dateActiveTo), 7, 'day')
          ).format()
        });
      });
      if (previousWeeksRota.length > 0) {
        let tutorRotas = await Api.bulkUpsertTutorRotas(tutorRotasToUpsert);
        if (!isEmpty(tutorRotas)) {
          setEvents(
            tutorRotas?.map(t => ({
              title: `${t.tutorFullname}`,
              start: new Date(t.dateActiveFrom),
              end: new Date(t.dateActiveTo),
              resource: t
            }))
          );
        }
      }
    } catch (e) {
      notify(e.toString, 'warning');
    }
  }, [dateActiveFrom, notify]);

  useEffect(() => {
    const runEffect = async () => {
      !events.length && getTutorRotas(startOfWeek);
    };
    runEffect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container className={classes.root}>
      <h3>
        Tutor Rota{' '}
        <Button
          variant="contained"
          color="primary"
          size="small"
          className={classes.copyButton}
          disabled={events.length ? true : false}
          onClick={() => copyPreviousWeek()}>
          Copy previous week
        </Button>
      </h3>
      <StyledCalendar
        selectable
        className={classes.calendar}
        localizer={localizer}
        events={events}
        defaultView={Views.WEEK}
        scrollToTime={new Date(1970, 1, 1, 7)}
        startAccessor="start"
        endAccessor="end"
        defaultDate={startOfWeek.toDate()}
        style={{ height: 700 }}
        getNow={() => moment().toDate()}
        onNavigate={e => onNavigate(e)}
        onSelectEvent={e => onSelectEvent(e)}
        onSelectSlot={e => onSelectSlot(e)}
        views={{ week: Week }}
      />
      <EditTutorRotaModal
        isEditModalOpen={isEditModalOpen}
        selectedEvent={selectedEvent}
        setIsEditModalOpen={setIsEditModalOpen}
        tutors={tutors}
        setSelectedEvent={setSelectedEvent}
        updateTutorRota={updateTutorRota}
      />
    </Container>
  );
};

export default Tutors;
