import {
  Typography,
  Card,
  TextField,
  CardContent,
  Button,
  FormGroup,
  Grow,
  InputAdornment,
  ListItem,
  ListItemText,
  List,
  ListItemIcon
} from '@material-ui/core';
import { useCallback, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import { useNotify, Notification } from 'react-admin';
import Api from 'Api/Api';
import SearchIcon from '@material-ui/icons/Search';
import debounce from 'lodash/debounce';
import UpdateHybridSchoolForm from './UpdateHybridSchoolForm';

const useStyles = makeStyles({
  create: {
    margin: '1rem 0 0 0'
  },
  input: {
    margin: '1rem 0 0 0'
  },
  createButton: {
    margin: '1rem 0 0 0'
  },
  cancelSearchButton: {
    margin: '1rem 0 0 0'
  },
  cancelUpdateButton: {
    margin: '1rem 0 0 10px'
  },
  searchList: {}
});

const SchoolSearchRow = ({ style, name, isHybridSchool, onClick }) => {
  return (
    <ListItem button style={style} onClick={onClick}>
      <ListItemIcon>
        {isHybridSchool && <img src="/assets/pluspill.png" alt="Eedi Plus" />}
      </ListItemIcon>
      <ListItemText primary={name} />
    </ListItem>
  );
};

const UpdateHybridSchoolCard = ({ onCancel }) => {
  const notify = useNotify();
  const classes = useStyles();
  const [state, setState] = useState({
    selectedSchool: null,
    searchResults: [],
    searchText: null,
    hybridSchools: null
  });

  const searchUpdate = event => {
    setState(() => ({
      ...state,
      searchText: !!event?.target?.value ? event.target.value : null
    }));
  };

  const selectSchool = useCallback(
    async selectedSchool => {
      selectedSchool.isHybridSchool &&
        notify(`${selectedSchool.name} is already a hybrid school!`, 'success');
      setState(() => ({ ...state, selectedSchool }));
    },
    [notify, state]
  );

  const onSearchTextChange = debounce(searchUpdate, 500);

  useEffect(() => {
    const runEffect = async () => {
      let hybridSchools = state.hybridSchools;
      if (state.hybridSchools === null) {
        hybridSchools = await Api.getAllHybridSchools();
        setState(() => ({ ...state, hybridSchools }));
      }
      if (state.searchText === null && !!state.searchedText) {
        setState(() => ({
          ...state,
          searchResults: [],
          searchedText: null
        }));
      } else if (
        !!state.searchText &&
        state.searchedText !== state.searchText
      ) {
        const results = await Api.searchSchools(state.searchText);
        if (!!results?.data?.length && hybridSchools.length) {
          results.data = results.data.map(r => {
            const hybridSchool = hybridSchools.find(
              h => h.schoolId === r.schoolId
            );
            r.isHybridSchool = !!hybridSchool;
            if (r.isHybridSchool) {
              r.defaultTrialLength = hybridSchool.defaultTrialLength;
              r.teacherId = hybridSchool.teacherId;
            }
            return r;
          });
        }
        setState(() => ({
          ...state,
          searchResults: results.data,
          selectedSchool: null,
          searchedText: state.searchText
        }));
      }
    };
    runEffect();
  }, [state, state.searchText]);

  return (
    <Card className={classes.create}>
      <CardContent>
        <Grow
          in={!state.selectedSchool}
          timeout={350}
          mountOnEnter
          unmountOnExit>
          <div>
            <Typography variant="h5" gutterBottom>
              Search for existing school
            </Typography>
            <FormGroup>
              <TextField
                className={classes.margin}
                id="input-with-icon-textfield"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  )
                }}
                autoComplete="off"
                onChange={e => onSearchTextChange(e)}
              />
            </FormGroup>
            <List className={classes.searchList}>
              {state.searchResults.map((r, i) => (
                <SchoolSearchRow
                  name={r.name}
                  isHybridSchool={r.isHybridSchool}
                  key={i}
                  style={{}}
                  onClick={async () => await selectSchool(r)}
                />
              ))}
            </List>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              className={classes.cancelSearchButton}
              onClick={() => onCancel()}
              startIcon={<CancelIcon />}>
              Cancel
            </Button>
          </div>
        </Grow>

        <Grow
          in={!!state.selectedSchool}
          timeout={350}
          mountOnEnter
          unmountOnExit>
          <div>
            <UpdateHybridSchoolForm
              school={state.selectedSchool}
              onCancel={() => {
                setState(() => ({ ...state, selectedSchool: null }));
                onCancel();
              }}
            />
          </div>
        </Grow>
      </CardContent>
      <Notification />
    </Card>
  );
};

export default UpdateHybridSchoolCard;
