import {
  useListContext,
  TopToolbar,
  ExportButton,
  sanitizeListRestProps
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CreateNotificationModal from '../CreateNotificationModal';
import { useState } from 'react';
import CreateStudentNotificationModal from '../CreateStudentNotificationModal';

const useStyles = makeStyles(theme => ({
  root: {
    justifyContent: 'flex-end!important',
    WebkitJustifyContent: 'flex-end!important',
    WebKitBoxPack: 'end!important',
    width: '100%'
  },
  createButton: {
    color: '#4353ff',
    fontSize: '0.8125rem',
    padding: '4px',
    border: 'none',
    background: 'none',
    boxShadow: 'none',
    float: 'right',
    margin: '0 10px 0 0'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '600px'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  }
}));

const NotificationListActions = props => {
  const classes = useStyles();
  const { className, maxResults, ...rest } = props;
  const { total } = useListContext();
  const [isSchoolNotifyModalOpen, setIsSchoolNotifyCreateModalOpen] =
    useState(false);
  const [isStudentNotifyModalOpen, setIsStudentNotifyCreateModalOpen] =
    useState(false);

  return (
    <>
      <TopToolbar className={classes.root} {...sanitizeListRestProps(rest)}>
        <Button
          variant="contained"
          color="default"
          className={classes.createButton}
          startIcon={<AddIcon />}
          onClick={() => setIsSchoolNotifyCreateModalOpen(true)}>
          New School Notification
        </Button>
        <Button
          variant="contained"
          color="default"
          className={classes.createButton}
          startIcon={<AddIcon />}
          onClick={r => setIsStudentNotifyCreateModalOpen(true)}>
          New Student Notification
        </Button>
        <ExportButton disabled={total === 0} maxResults={maxResults} />
      </TopToolbar>
      <CreateNotificationModal
        isOpen={isSchoolNotifyModalOpen}
        setIsOpen={setIsSchoolNotifyCreateModalOpen}
      />
      <CreateStudentNotificationModal
        isOpen={isStudentNotifyModalOpen}
        setIsOpen={setIsStudentNotifyCreateModalOpen}
      />
    </>
  );
};

export default NotificationListActions;
