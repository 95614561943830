import {
  Typography,
  Card,
  TextField,
  CardContent,
  Button,
  FormGroup,
  Select,
  FormLabel,
  MenuItem,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Form, Field } from 'react-final-form';
import SaveIcon from '@material-ui/icons/Save';
import Countries from 'Components/Countries';
import CancelIcon from '@material-ui/icons/Cancel';
import { useNotify, Notification } from 'react-admin';
import Api from 'Api/Api';
import { useSelector } from 'react-redux';

const useStyles = makeStyles({
  create: {
    margin: '1rem 0 0 0'
  },
  input: {
    margin: '1rem 0 0 0'
  },
  createButton: {
    margin: '1rem 0 0 0'
  },
  cancelButton: {
    margin: '1rem 0 0 10px'
  }
});

const CreateHybridSchoolCard = ({ onCancel }) => {
  const notify = useNotify();
  const user = useSelector(u => u.user);
  const classes = useStyles();

  const createSchool = useCallback(
    async values => {
      try {
        const school = await Api.insertSchool({
          name: values.name,
          addressLine1: values.addressLine1,
          city: values.city,
          country: values.country,
          zipOrPostCode: values.zipOrPostCode
        });

        if (!school?.schoolId) {
          throw new Error('There was an error creating the school.');
        }

        const hybridSchool = await Api.upsertHybridSchool(school.schoolId, {
          defaultTrialLength: values.defaultTrialLength
        });

        if (!hybridSchool?.schoolId) {
          throw new Error(
            `There was an error setting up new schoolId: ${hybridSchool.schoolId} as a hybrid school.`
          );
        }

        if (values.addCurrentUserToSchool && !!user?.userId) {
          await Api.assignUserToSchool(user?.userId, hybridSchool.schoolId);
        }

        notify('School created!', 'success');
        onCancel();
      } catch (e) {
        notify(`Oops - unable to create school: \n ${e}`, 'error');
        console.error(e);
      }
    },
    [notify, onCancel, user?.userId]
  );

  return (
    <Card className={classes.create}>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          Create New School
        </Typography>
        <Form
          onSubmit={createSchool}
          initialValues={{
            name: null,
            addressLine1: null,
            city: null,
            country: 'United Kingdom',
            zipOrPostCode: null,
            defaultTrialLength: 14,
            addCurrentUserToSchool: false
          }}
          validate={values => {
            const errors = {};
            if (!values.name) {
              errors.name = 'Please enter a school name';
            }
            if (!values.addressLine1) {
              errors.addressLine1 = 'Please enter a street address';
            }
            if (!values.city) {
              errors.city = 'Please enter a town or city';
            }
            if (!values.zipOrPostCode) {
              errors.zipOrPostCode = 'Please enter a valid zip or postal code';
            }
            if (
              isNaN(parseInt(values.defaultTrialLength, 10)) ||
              parseInt(values.defaultTrialLength, 10) < -1
            ) {
              errors.defaultTrialLength =
                'Please enter a valid trial length from -1 (unlimited) or greater';
            }
            return errors;
          }}>
          {({ handleSubmit, submitting, pristine, submitError }) => (
            <form onSubmit={handleSubmit}>
              <FormGroup>
                <Field name="name">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      id="standard-basic"
                      label="School Name"
                      variant="filled"
                      className={classes.input}
                      error={meta.error && meta.touched}
                      helperText={
                        meta.error && meta.touched ? meta.error : null
                      }
                    />
                  )}
                </Field>
              </FormGroup>
              <FormGroup>
                <Field name="addressLine1">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      id="standard-basic"
                      label="Street Address"
                      variant="filled"
                      className={classes.input}
                      error={meta.error && meta.touched}
                      helperText={
                        meta.error && meta.touched ? meta.error : null
                      }
                    />
                  )}
                </Field>
              </FormGroup>
              <FormGroup>
                <Field name="city">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      id="standard-basic"
                      label="Town / City"
                      variant="filled"
                      className={classes.input}
                      error={meta.error && meta.touched}
                      helperText={
                        meta.error && meta.touched ? meta.error : null
                      }
                    />
                  )}
                </Field>
              </FormGroup>
              <FormGroup>
                <Field name="zipOrPostCode">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      id="standard-basic"
                      label="Zip or Postcode"
                      variant="filled"
                      className={classes.input}
                      error={meta.error && meta.touched}
                      helperText={
                        meta.error && meta.touched ? meta.error : null
                      }
                    />
                  )}
                </Field>
              </FormGroup>
              <FormGroup style={{ margin: '1rem 0 1rem 0' }}>
                <FormLabel>Country</FormLabel>
                <Field name="country">
                  {({ input, meta }) => (
                    <Select
                      {...input}
                      name="tutorSatisfactionRating"
                      error={meta.error && meta.touched}>
                      {Countries.countriesList
                        .map(s => ({ key: s.name, value: s.name }))
                        .map((s, idx) => (
                          <MenuItem key={idx} value={s.value}>
                            {s.key}
                          </MenuItem>
                        ))}
                    </Select>
                  )}
                </Field>
              </FormGroup>
              <FormGroup>
                <Field name="defaultTrialLength">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      id="standard-number"
                      label="Default Trial Length"
                      type="number"
                      error={meta.error && meta.touched}
                      helperText={
                        meta.error && meta.touched ? meta.error : null
                      }
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  )}
                </Field>
              </FormGroup>
              <FormGroup>
                <Field name="addCurrentUserToSchool" type="checkbox">
                  {({ input, meta }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...input}
                          error={meta.error && meta.touched}
                        />
                      }
                      label="Add yourself as school admin?"
                    />
                  )}
                </Field>
              </FormGroup>
              <Button
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                className={classes.createButton}
                startIcon={<SaveIcon />}
                disabled={submitting || pristine || submitError}>
                Create
              </Button>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                className={classes.cancelButton}
                onClick={() => onCancel()}
                startIcon={<CancelIcon />}>
                Cancel
              </Button>
            </form>
          )}
        </Form>
      </CardContent>
      <Notification />
    </Card>
  );
};

export default CreateHybridSchoolCard;
