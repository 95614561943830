import { Admin, Resource, Notification } from 'react-admin';
import customRoutes from 'Routes/customRoutes';
import CustomLayout from 'Components/CustomLayout';
import CustomLoginPage from 'Pages/Login/CustomLoginPage';
import Lesson from 'Pages/Lessons/Lesson';
import StudentList from 'Pages/Students/StudentsList';
import StudentShow from 'Pages/Students/StudentShow';
import RequestsList from 'Pages/Requests/RequestsList';
import RequestsShow from 'Pages/Requests/RequestsShow';
import { createBrowserHistory as createHistory } from 'history';
import authProvider from 'authProvider';
import { Provider } from 'react-redux';
import createStore from 'createStore';
import { UserProvider } from 'Providers/UserProvider';
import { SignalRProvider } from 'Providers/SignalRProvider';
import dataProvider from 'dataProvider';
import SchoolIcon from '@material-ui/icons/School';
import theme from 'theme';
import { MetadataProvider } from 'Providers/MetadataProvider';
import HybridSchoolsList from 'Pages/HybridSchools/HybridSchoolsList';
import HybridSchoolEdit from 'Pages/HybridSchools/HybridSchoolEdit';
import HybridSchoolCreate from 'Pages/HybridSchools/HybridSchoolCreate';
import TopicPathwayQuizList from 'Pages/TopicPathwayQuizs/TopicPathwayQuizList';
import UserSubscriptionsList from 'Pages/UserSubscriptions/UserSubscriptionsList';
import FlowQuestionsList from 'Pages/FlowQuestions/FlowQuestionsList';
import WorksheetQuestionsList from 'Pages/WorksheetQuestions/WorksheetQuestionsList';
import MisconceptionTagQuestionsList from 'Pages/MisconceptionTags/MisconceptionTagQuestionsList';
import TutorList from 'Pages/Tutors/TutorList';
import QuestionExtractorList from 'Pages/QuestionExtractor/QuestionExtratorList';
import QuestionExtractorShow from 'Pages/QuestionExtractor/QuestionExtractorShow';
import EmbeddingOutputList from 'Pages/EmbeddingOuputs/EmbeddingOutputsList';
import ConversationShow from 'Pages/Conversations/ConversationShow';
import ConversationList from 'Pages/Conversations/ConversationList';
import NotificationsList from 'Pages/Notifications/NotificationsList';
import { MisconceptionTagPage } from 'Pages/MisconceptionTags/MisconceptionTag';
import ConstructsList from 'Pages/Constructs/ConstructsList';

const history = createHistory();
function App() {
  return (
    <Provider
      store={createStore({
        authProvider,
        dataProvider,
        history
      })}>
      <UserProvider>
        <SignalRProvider>
          <MetadataProvider>
            <Admin
              theme={theme}
              loginPage={CustomLoginPage}
              layout={CustomLayout}
              history={history}
              authProvider={authProvider}
              dataProvider={dataProvider}
              customRoutes={customRoutes}>
              <Resource name="students" list={StudentList} show={StudentShow} />
              <Resource
                options={{ label: 'Lessons' }}
                name="flow-generator-sessions"
                list={Lesson}
                icon={SchoolIcon}
              />
              <Resource
                name="requests"
                list={RequestsList}
                show={RequestsShow}
              />
              <Resource
                name="hybrid-schools"
                list={HybridSchoolsList}
                edit={HybridSchoolEdit}
                create={HybridSchoolCreate}
                show={HybridSchoolEdit}
              />
              <Resource
                name="topic-pathway-quizs"
                list={TopicPathwayQuizList}
                show={TopicPathwayQuizList}
              />
              <Resource
                name="flow-questions"
                list={FlowQuestionsList}
                show={FlowQuestionsList}
              />
              <Resource
                name="worksheet-questions"
                list={WorksheetQuestionsList}
                show={WorksheetQuestionsList}
              />
              <Resource
                name="construct-transcripts"
                list={ConstructsList}
                // show={ConstructsList}
              />
              <Resource
                name="misconception-tags"
                list={MisconceptionTagQuestionsList}
                show={MisconceptionTagPage}
              />
              <Resource
                name="question-metadata"
                list={QuestionExtractorList}
                show={QuestionExtractorShow}
              />
              <Resource
                name="user-subscriptions"
                list={UserSubscriptionsList}
                show={UserSubscriptionsList}
              />
              <Resource name="embedding-outputs" list={EmbeddingOutputList} />
              <Resource name="tutors" list={TutorList} show={TutorList} />
              <Resource
                name="conversations"
                list={ConversationList}
                show={ConversationShow}
              />
              <Resource
                name="school-notifications"
                list={NotificationsList}
                show={NotificationsList}
              />
              <Notification />
            </Admin>
          </MetadataProvider>
        </SignalRProvider>
      </UserProvider>
    </Provider>
  );
}

export default App;
