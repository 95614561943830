import { makeStyles, createTheme } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { Container, CardContent } from '@material-ui/core';
import { useCallback, useEffect, useState } from 'react';
import RecommendationPrompt from './RecommendationPrompt';
import { useSignalROn } from 'Hooks/useSignalROn';
import Api from 'Api/Api';
import BuilderModal from './Components/BuilderModal';
import useQuery from 'Hooks/useQuery';
import PlanShow from './Components/PlanShow';

const theme = createTheme({});

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    height: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  studentShow: {
    height: 'calc(100vh - 65px)'
  },
  hidePanel: {
    textAlign: 'center',
    padding: '0.25rem'
  },
  create: {
    margin: '1rem 0 0 0',
    overflowY: 'scroll',
    maxHeight: `${window.innerHeight ?? 900}px`
  },
  input: {
    margin: '1rem 0 0 0'
  },
  container: {
    background: '#fff'
  },
  createButton: {
    margin: '1rem 10px 0 0'
  },
  cancelButton: {
    margin: '1rem 0 0 0'
  }
}));

const dumbData = {
  rowKey: '0008876598738084',
  prompt: 'I wanna learn how to draw bar chats',
  filteredPrompt: 'bar charts',
  summary: null,
  isFailed: false,
  error: null,
  topicPathwayCollectionId: 4,
  userIds: [3369624],
  planGroups: [
    {
      isMutable: true,
      sequence: 1,
      title: 'Level Boosters \uD83D\uDE80',
      description:
        'Excellent, all 1 have completed level boosters! \uD83C\uDF89\nTaking the level booster will enhance our ability to generate a plan for your students.',
      items: [
        {
          isMutable: true,
          sequence: 1,
          title: 'Complete level boosters',
          description: 'All students have completed the level-booster!',
          link: 'https://family.eedi.com/level-booster',
          progress: 100,
          isCompleted: true,
          topicId: null,
          prediction: null
        }
      ],
      progress: 100,
      isCompleted: true
    },
    {
      isMutable: true,
      sequence: 2,
      title: 'Understanding Bar Charts',
      description:
        "This plan focuses on enhancing the student's understanding and interpretation of bar charts, a fundamental aspect of data representation.",
      items: [
        {
          isMutable: true,
          sequence: 1,
          title: 'Introduction to Bar Charts',
          description:
            'An introductory lesson on what bar charts are and how to interpret them.',
          link: 'https://family.eedi.com/topic/1432',
          progress: 0,
          isCompleted: false,
          topicId: 1432,
          prediction: 0.62288797436
        },
        {
          isMutable: true,
          sequence: 2,
          title: 'Understanding Frequency Tables',
          description:
            'A lesson on understanding frequency tables as a precursor to generating bar charts.',
          link: 'https://family.eedi.com/topic/1174',
          progress: 0,
          isCompleted: false,
          topicId: 1174,
          prediction: 0.51210750224
        },
        {
          isMutable: true,
          sequence: 3,
          title: 'Line Graphs Interpretation',
          description:
            'Understanding line graphs to differentiate and appreciate various data representation methods.',
          link: 'https://family.eedi.com/topic/1175',
          progress: 0,
          isCompleted: false,
          topicId: 1175,
          prediction: 0.6948078644200001
        },
        {
          isMutable: true,
          sequence: 4,
          title: 'Pie Charts Introduction',
          description:
            "An introduction to pie charts to complement the student's knowledge on data representation.",
          link: 'https://family.eedi.com/topic/1281',
          progress: 0,
          isCompleted: false,
          topicId: 1281,
          prediction: 0.6067626094999999
        },
        {
          isMutable: true,
          sequence: 5,
          title: 'Reading Timetables',
          description:
            'Enhancing skills on reading and interpreting data from timetables, a practical application of understanding data.',
          link: 'https://family.eedi.com/topic/1218',
          progress: 0,
          isCompleted: false,
          topicId: 1218,
          prediction: 0.6028046929800001
        }
      ],
      progress: 0,
      isCompleted: false
    },
    {
      isMutable: true,
      sequence: 3,
      title: 'Understanding Data Visualization',
      description:
        'This plan focuses on building foundational knowledge in data visualization, leading up to understanding bar charts.',
      items: [
        {
          isMutable: true,
          sequence: 1,
          title: 'Pictograms 1',
          description: 'Introduction to data representation using pictograms.',
          link: 'https://family.eedi.com/topic/1419',
          progress: 0,
          isCompleted: false,
          topicId: 1419,
          prediction: 0.62074385404
        },
        {
          isMutable: true,
          sequence: 2,
          title: 'Pie charts 2',
          description: 'Understanding data distribution with pie charts.',
          link: 'https://family.eedi.com/topic/1418',
          progress: 0,
          isCompleted: false,
          topicId: 1418,
          prediction: 0.50008743586
        },
        {
          isMutable: true,
          sequence: 3,
          title: 'Time series graphs 1',
          description: 'Analyzing data over time with time series graphs.',
          link: 'https://family.eedi.com/topic/1297',
          progress: 0,
          isCompleted: false,
          topicId: 1297,
          prediction: 0.60366992594
        },
        {
          isMutable: true,
          sequence: 4,
          title: 'Time series graphs 2',
          description: 'Advanced concepts in time series data analysis.',
          link: 'https://family.eedi.com/topic/1296',
          progress: 0,
          isCompleted: false,
          topicId: 1296,
          prediction: 0.49089853108000003
        },
        {
          isMutable: true,
          sequence: 5,
          title: 'Time series graphs 3',
          description: 'Mastering time series graphs interpretation.',
          link: 'https://family.eedi.com/topic/1433',
          progress: 0,
          isCompleted: false,
          topicId: 1433,
          prediction: 0.65345350026
        }
      ],
      progress: 0,
      isCompleted: false
    },
    {
      isMutable: true,
      sequence: 4,
      title: 'Advanced Bar Charts Analysis',
      description:
        'This lesson plan focuses on deepening the understanding of data representation and analysis through advanced topics related to bar charts.',
      items: [
        {
          isMutable: true,
          sequence: 1,
          title: 'Mean from a list of data 1',
          description:
            'Understanding how to calculate the mean from a list of data.',
          link: 'https://family.eedi.com/topic/1295',
          progress: 0,
          isCompleted: false,
          topicId: 1295,
          prediction: 0.43967308998
        },
        {
          isMutable: true,
          sequence: 2,
          title: 'Mean from a list of data 2',
          description:
            'Further exploration into calculating mean from complex datasets.',
          link: 'https://family.eedi.com/topic/1411',
          progress: 0,
          isCompleted: false,
          topicId: 1411,
          prediction: 0.43627265038
        },
        {
          isMutable: true,
          sequence: 3,
          title: 'Mode from a list of data',
          description: 'Identifying the mode in various datasets.',
          link: 'https://family.eedi.com/topic/1410',
          progress: 0,
          isCompleted: false,
          topicId: 1410,
          prediction: 0.71173047482
        },
        {
          isMutable: true,
          sequence: 4,
          title: 'Median from a list of data',
          description:
            'Learning to find the median and its significance in data analysis.',
          link: 'https://family.eedi.com/topic/1409',
          progress: 0,
          isCompleted: false,
          topicId: 1409,
          prediction: 0.49441971868
        },
        {
          isMutable: true,
          sequence: 5,
          title: 'Pie charts 3',
          description:
            'Advanced concepts in representing data with pie charts.',
          link: 'https://family.eedi.com/topic/1417',
          progress: 0,
          isCompleted: false,
          topicId: 1417,
          prediction: 0.67513231158
        }
      ],
      progress: 0,
      isCompleted: false
    }
  ]
};

const RecommendationPage = ({ sessionId }) => {
  const classes = useStyles();
  const [builderIsOpened, setBuilderIsOpened] = useState(false);
  const [data, setData] = useState(null);

  const userId = parseInt(useQuery().get('userId'));
  const showDemo = useQuery().get('showdemo') === 'true';

  return (
    <Container maxWidth="md" className={classes.container}>
      <CardContent>
        {/*
        <Typography variant="h5" gutterBottom>
          What would you like to learn today?
        </Typography>
        <RecommendationPrompt
          loading={isGPTLoading}
          cancelResponse={cancelResponse}
        /> */}

        <Button color="secondary" onClick={() => setBuilderIsOpened(true)}>
          Launch builder
        </Button>

        {showDemo && <PlanShow planGroups={dumbData.planGroups} />}
        {data?.planGroups && <PlanShow planGroups={data.planGroups} />}

        {builderIsOpened && (
          <BuilderModal
            userId={userId}
            isOpen={builderIsOpened}
            onClose={newPlan => {
              setBuilderIsOpened(false);

              if (newPlan) {
                setData(newPlan);
              }
            }}
          />
        )}
      </CardContent>
    </Container>
  );
};

export default RecommendationPage;
