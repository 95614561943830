import { makeStyles } from '@material-ui/core/styles';
import { Field } from 'react-final-form';
import { TextField, FormGroup, Box, Chip } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import CancelIcon from '@material-ui/icons/Cancel';
import ToolTip from 'Components/ToolTip';

const useStyles = makeStyles(theme => ({
  invalid: {
    padding: '0.5rem !important',
    margin: '0 0 1rem 0',
    background: '#ffcdd2',
    borderRadius: '4px',
    border: '1px solid #f44336',
    color: '#f44336'
  },
  chip: {
    maxWidth: '300px',
    margin: '0 0.25rem 0.25rem 0',
    cursor: 'pointer'
  }
}));

const ArrayAutoComplete = ({ name, values, label, onChange = null }) => {
  const classes = useStyles();
  return (
    <FormGroup>
      <Field name={name}>
        {({ input, meta }) => (
          <>
            <Autocomplete
              {...input}
              disableClearable
              onChange={(e, newValue) => {
                const opts = values[name]?.length ? [...values[name]] : [];
                const updateOpts = [
                  ...opts,
                  newValue.replace('Add ', '').replace(/"/g, '')
                ];
                if (onChange) {
                  onChange(updateOpts, input.onChange);
                  return;
                }
                input.onChange(updateOpts);
              }}
              options={values[name] || []}
              filterOptions={(options, params) => {
                if (params.inputValue !== '') {
                  const exactMatch = options.includes(params.inputValue);
                  if (!exactMatch) {
                    options.push(`Add "${params.inputValue}"`);
                  }
                }

                return options;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              getOptionLabel={option => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return '';
              }}
              renderOption={option => option}
              freeSolo
              renderInput={params => <TextField {...params} label={label} />}
            />
            <Box style={{ margin: '0.25rem 0 0.25rem 0' }}>
              {meta.error && meta.touched && (
                <Box className={classes.invalid}>{meta.error}</Box>
              )}
              {values[name]?.map((opt, idx) => (
                <ToolTip key={idx} text={opt} placement="top">
                  <Chip
                    label={opt}
                    className={classes.chip}
                    onDelete={() => {
                      const opts = [...values[name]];
                      opts.splice(idx, 1);
                      input.onChange(opts);
                    }}
                    deleteIcon={<CancelIcon />}
                  />
                </ToolTip>
              ))}
            </Box>
          </>
        )}
      </Field>
    </FormGroup>
  );
};

export default ArrayAutoComplete;
