import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Text, Transformer } from 'react-konva';

const templateText = '[Type to replace me...]';

const EditableText = ({
  text,
  x = 0,
  y = 0,
  handleSelect = () => {},
  onUpdateText = () => {},
  onStartTransform = () => {},
  selectedId = null,
  index = null,
  color = 'black',
  id = null,
  width = 0,
  height = 0,
  rotation = 0,
  fontSize = 25,
  ...props
}) => {
  const [isSelected, setIsSelected] = useState(false);
  const [currentText, setCurrentText] = useState(text ?? templateText);
  const textRef = useRef(null);
  const trRef = useRef(null);

  const handleTextClick = useCallback(() => {
    setIsSelected(true);
    onStartTransform(true);
    handleSelect(id);
  }, [handleSelect, id, onStartTransform]);

  const handleKeyDown = useCallback(
    e => {
      if (selectedId !== id) return;

      const isPrintableKey = key => {
        return (
          key.length === 1 ||
          key === 'Enter' ||
          key === 'Backspace' ||
          key === 'Delete' ||
          key === ' '
        );
      };

      if (!isPrintableKey(e.key)) return;

      if (e.key === 'Delete') {
        onUpdateText(null, index);
        setCurrentText(null);
        handleSelect(null);
      } else if (e.key === 'Backspace') {
        const updatedText = text?.length
          ? text.replace(templateText, '').slice(0, -1)
          : null;
        setCurrentText(updatedText);
        onUpdateText(
          !!updatedText ? { text: updatedText, color, fontSize } : null,
          index
        );
      } else if (e.key === 'Enter') {
        const text = `${currentText.replace(templateText, '')}\n`;
        setCurrentText(text);
        onUpdateText({ text, color, fontSize }, index);
      } else if (e.key.length === 1) {
        let key = e.key;
        if (e.shiftKey) {
          if (key.match(/[a-z]/i)) {
            key = key.toUpperCase();
          } else {
            const shiftedKeys = {
              1: '!',
              2: '@',
              3: '#',
              4: '$',
              5: '%',
              6: '^',
              7: '&',
              8: '*',
              9: '(',
              0: ')',
              '-': '_',
              '=': '+',
              '[': '{',
              ']': '}',
              '\\': '|',
              ';': ':',
              "'": '"',
              ',': '<',
              '.': '>',
              '/': '?'
            };
            key = shiftedKeys[key] || key;
          }
        }
        const text = `${currentText.replace(templateText, '')}${key}`;
        setCurrentText(text);
        onUpdateText({ text, color, fontSize }, index);
      }
    },
    [
      selectedId,
      id,
      onUpdateText,
      index,
      handleSelect,
      text,
      color,
      fontSize,
      currentText
    ]
  );

  const onMoveEnd = useCallback(
    e => {
      const node = textRef.current;

      const scaleX = node.scaleX();
      const scaleY = node.scaleY();

      // Update the dimensions based on scaling
      node.scaleX(1);
      node.scaleY(1);
      node.width(node.width() * scaleX);
      node.height(node.height() * scaleY);

      // scale font size
      //let fontSize = node.fontSize() * Math.max(scaleX, scaleY);
      //node.fontSize(fontSize > 10 ? fontSize : 10);

      console.log(node);

      const newDimensions = {
        width: node.width(),
        height: node.height(),
        x: node.x(),
        y: node.y(),
        rotation: node.rotation(),
        fontSize: node.fontSize()
      };
      onUpdateText({ text, color, fontSize, ...newDimensions }, index);
    },
    [onUpdateText, text, color, fontSize, index]
  );

  useEffect(() => {
    if (isSelected && trRef.current) {
      trRef.current.nodes([textRef.current]);
      trRef.current.getLayer().batchDraw();
    }
    if (selectedId?.length) {
      setIsSelected(selectedId === id);
    } else {
      setIsSelected(false);
    }
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown, id, isSelected, selectedId]);

  return (
    <>
      <Text
        text={currentText}
        x={x}
        y={y}
        width={width || 250}
        height={height || 30}
        fontSize={fontSize}
        rotation={rotation}
        draggable
        onMouseDown={() => onStartTransform(true)}
        onClick={handleTextClick}
        onTransformEnd={onMoveEnd}
        onDragEnd={onMoveEnd}
        fill={color}
        ref={textRef}
        {...props}
      />
      <Transformer
        visible={isSelected}
        ref={trRef}
        boundBoxFunc={(oldBox, newBox) => {
          // Limit the resizing to keep the text in a reasonable size
          if (newBox.width < 20 || newBox.height < 20) {
            return oldBox;
          }
          return newBox;
        }}
      />
    </>
  );
};

export default EditableText;
