import { useSignalROn } from 'Hooks/useSignalROn';
import { useCallback, useState } from 'react';
import styled from 'styled-components';

const pastelColours = [
  '#FFB3BA', // Light Pink
  '#FFDFBA', // Light Orange
  '#FFFFBA', // Light Yellow
  '#BAFFC9', // Light Green
  '#BAE1FF' // Light Blue
];

const getDeterministicColour = (value, colours = pastelColours) => {
  const index =
    Math.abs(
      [...value.toString()].reduce((acc, char) => acc + char.charCodeAt(0), 0)
    ) % colours.length;
  return colours[index];
};

const useDrawPointers = ({
  sessionId = null,
  currentUserId = null,
  stageRef
}) => {
  const [pointers, setPointers] = useState([]);

  const removePointer = useCallback(pointerId => {
    setPointers(prevPointers =>
      prevPointers.filter(pointer => pointer.userId !== pointerId)
    );
  }, []);

  const updatePointer = useCallback(
    pointer => {
      if (!pointer) return;

      try {
        if (stageRef && stageRef.current) {
          const rect = stageRef.current.getBoundingClientRect();
          pointer.x = rect.left + pointer.x;
          pointer.y = rect.top + pointer.y;

          // check if the pointer is outside the bounds of the stage
          if (
            pointer.x < rect.left ||
            pointer.x > rect.right ||
            pointer.y < rect.top ||
            pointer.y > rect.bottom
          ) {
            return;
          }
        }

        if (pointers.find(p => p.userId === pointer.userId)) {
          setPointers(prevPointers =>
            prevPointers.map(prevPointer =>
              prevPointer.userId === pointer.userId ? pointer : prevPointer
            )
          );
          return;
        }

        setPointers(prevPointers => [...prevPointers, pointer]);
      } catch (e) {
        console.error(e);
      }
    },
    [pointers, stageRef]
  );

  useSignalROn(`PointerEvent`, pointer => {
    try {
      console.log('PointerEvent', pointer);

      if (
        currentUserId === pointer.userId ||
        parseInt(sessionId) !== pointer.sessionId
      )
        return;

      updatePointer(pointer);
    } catch (e) {
      console.error(e);
    }
  });

  return (
    <>
      {pointers.map(pointer => (
        <Pointer
          key={pointer.userId}
          userid={pointer.userId}
          x={pointer.x}
          y={pointer.y}>
          <box-icon name="cross" size="md" />
          <span>{pointer.label}</span>
        </Pointer>
      ))}
    </>
  );
};

const Pointer = styled.div`
  position: absolute;
  left: ${props => props.x}px;
  top: ${props => props.y}px;
  color: ${props => getDeterministicColour(props.userid)};
  fill: ${props => getDeterministicColour(props.userid)};
`;

export default useDrawPointers;
