import { Grid, Paper, Grow, Button } from '@material-ui/core';
import { FlowItem } from 'Components/FlowItems/index';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, createRef } from 'react';
import moment from 'moment';
import 'moment-timezone';
import useGetAuthUser from 'Hooks/useGetAuthUser';
import useIsTyping from 'Hooks/useIsTyping';
import styled from 'styled-components';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { useCallback } from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%!important',
    padding: '2rem 1rem',
    margin: '0!important',
    height: 'calc(100vh - 235px)',
    backgroundImage: 'url(/assets/email-pattern.png)',
    overflowY: 'scroll',
    overflowX: 'hidden'
  },
  flowItem: {
    alignSelf: props => (props.bot ? 'flex-start' : 'flex-end')
  },
  paper: {
    background: 'none',
    border: 'none',
    padding: 0,
    boxShadow: 'none',
    margin: 0
  }
}));

const ConverstationListGrid = props => {
  const { data } = props;
  const classes = useStyles();
  const [flowItemLength, setFlowItemLength] = useState(0);
  const { lessonListAutoScrollEnabled } = useSelector(s => s.uxState);
  const ref = createRef();
  const user = useGetAuthUser();
  const typingStates = useIsTyping(data?.flowGeneratorSessionId);
  const scrollToBottom = useCallback(
    () => ref.current?.scrollIntoView({ behavior: 'smooth' }),
    [ref]
  );

  useEffect(() => {
    const newFlowItemLength = data?.flowGeneratorSession?.flowItems?.length;
    if (newFlowItemLength !== flowItemLength) {
      scrollToBottom();
      setFlowItemLength(newFlowItemLength);
    }
    setFlowItemLength();
    if (lessonListAutoScrollEnabled) {
      setTimeout(
        () => ref.current?.scrollIntoView({ behavior: 'smooth' }),
        700
      );
    }
  }, [
    data,
    ref,
    typingStates,
    lessonListAutoScrollEnabled,
    flowItemLength,
    scrollToBottom
  ]);

  const mapFlowItems = () => {
    if (!data?.flowGeneratorSession?.flowItems) return [];
    const flowItems = [];
    data.flowGeneratorSession.flowItems
      .sort((a, b) => new Date(a.dateDispatched) - new Date(b.dateDispatched))
      .forEach((fi, i) => {
        if (i > 0) {
          const previousFlowItem = flowItems[i - 1];
          fi.isShowName = previousFlowItem.userId !== fi.userId;
        }
        flowItems.push(fi);
      });
    return flowItems;
  };

  const flowItems = mapFlowItems();

  return (
    <Grid
      container
      item
      direction="column"
      spacing={2}
      className={classes.root}>
      <StickyHeader>
        <div style={{ flex: 'auto' }} />
        <ScrollToBottomButton
          variant="contained"
          size="large"
          className={classes.button}
          startIcon={<KeyboardArrowDownIcon />}
          onClick={() => scrollToBottom()}
        />
      </StickyHeader>
      <StyledGrid>
        <Grid
          container
          item
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={1}>
          {flowItems.map((fi, i) => {
            return (
              <Grid
                item
                xs={12}
                lg={8}
                key={i}
                style={{
                  alignSelf:
                    fi.userId > 0 && fi.userId !== user?.id
                      ? 'flex-end'
                      : 'flex-start',
                  justifyContent: 'center'
                }}>
                <Grow in={true}>
                  <Paper elevation={1} className={classes.paper}>
                    <FlowItem
                      props={{
                        ...fi,
                        dateDispatched: moment
                          .utc(fi.dateDispatched)
                          .tz('Europe/London')
                          .format('MMMM Do YYYY, h:mm:ss a')
                      }}
                      key={i}
                    />
                  </Paper>
                </Grow>
              </Grid>
            );
          })}
          <div ref={ref} style={{ margin: '-50px 0 0 0' }} />
        </Grid>
      </StyledGrid>
    </Grid>
  );
};

const StickyHeader = styled.div`
  position: absolute;
  top: 10px;
  display: flex;
  justify-content: flex-end;
`;

const ScrollToBottomButton = styled(Button)`
  padding: 10px !important;
  border-radius: 50% !important;
  width: 32px !important;
  height: 32px;
  min-width: 32px !important;
  min-width: 32px;
  span {
    display: contents;
  }
`;

const StyledGrid = styled.div`
  .MuiGrid-container {
    justify-content: center;
  }
`;

export default ConverstationListGrid;
