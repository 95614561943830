import { makeStyles } from '@material-ui/core/styles';
import {
  Modal,
  Backdrop,
  Container,
  Card,
  CardContent
} from '@material-ui/core';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useCallback, React, useState, useEffect } from 'react';
import { useDataProvider, useNotify, useRefresh } from 'react-admin';
import { useLocation } from 'react-router-dom';
import Api from 'Api/Api';
import EditTopicPathwayQuizForm from './EditTopicPathwayQuizForm';
import TabPanel from 'Components/TabPanel';
import EditTopicTagForm from './EditTopicTagForm';
import FullPageLoader from 'Components/FullPageLoader';
import languageHelpers from 'Helpers/languageHelpers';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
    margin: 0
  },
  create: {
    margin: 0,
    padding: 0,
    overflowY: 'scroll',
    minHeight: 'calc(100vh - 80px)',
    maxHeight: `${window.innerHeight ?? 900}px`
  },
  content: {
    padding: 0,
    margin: 0
  }
}));

const EditTopicPathwayQuizModal = ({ isOpen, setIsOpen }) => {
  const location = useLocation();
  const topicPathwayQuizId = location.pathname.split('/').reverse()[0];
  const notify = useNotify();
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const [topic, setTopic] = useState(null);
  const [countryYearGroups, setCountryYearGroups] = useState([]);
  const [topicTags, setTopicTags] = useState([]);
  const [allTopicTags, setAllTopicTags] = useState([]);
  const [locale, setLocale] = useState('en-GB');
  const [isLoaded, setIsLoaded] = useState(false);
  const [cacheState, setCacheState] = useState({
    isModalOpen: false,
    isLoading: false
  });
  const [isUpdatingTags, setIsUpdatingTags] = useState(false);

  const fetchTopicPathwayQuiz = useCallback(
    async topicPathwayQuizId => {
      try {
        if (!topicPathwayQuizId) return;

        const topic = await dataProvider.getOne('topic-pathway-quizs', {
          id: topicPathwayQuizId
        });

        if (!topic?.data)
          throw new Error(`Unable to fetch topic ${topicPathwayQuizId}`);

        setTopic(() => topic.data);
        return topic.data;
      } catch (e) {
        notify('Error fetching topic!', 'error');
        console.error(e);
      }
      return {};
    },
    [dataProvider, notify]
  );

  const fetchCountryYearGroups = useCallback(
    async countryCode => {
      try {
        const countryYearGroups = await Api.getCountryYearGroups(countryCode);
        if (!countryYearGroups)
          throw new Error(
            `Unable to fetch country year groups for ${countryCode}`
          );
        setCountryYearGroups(countryYearGroups);
      } catch (e) {
        notify('Error fetching topic!', 'error');
        console.error(e);
      }
    },
    [notify]
  );

  const fetchTopicTags = useCallback(
    async (locale = 'en-GB', parentId = null) => {
      setIsUpdatingTags(true);
      try {
        const topicTags = (await Api.getTopicTags(locale, parentId)) ?? [];

        if (locale) {
          setTopicTags(() => topicTags);
          setLocale(locale);
        } else {
          setAllTopicTags(() => topicTags);
        }
      } catch (e) {
        notify('Error fetching topic tags!', 'error');
        console.error(e);
      }
      setIsUpdatingTags(false);
    },
    [notify]
  );

  const updateTopicTag = useCallback(
    async ({
      topicTagId,
      tag,
      locale = 'en-GB',
      parentId = null,
      ...props
    }) => {
      if (!tag) {
        notify('No tag value!', 'error');
        return;
      }
      setIsUpdatingTags(true);
      try {
        if (topicTagId) {
          const updatedTag = await Api.updateTopicTag(topicTagId, {
            tag,
            locale,
            parentId
          });
          setTopicTags(
            topicTags.map(t => (t.topicTagId === topicTagId ? updatedTag : t))
          );
        } else {
          const newTag = await Api.insertTopicTag({ tag, locale, parentId });
          setTopicTags([...topicTags, newTag]);
        }
        notify('Tag updated!', 'success');
      } catch (e) {
        notify('Error updating tag!', 'error');
        console.error(e);
      }
      setIsUpdatingTags(false);
    },
    [notify, topicTags]
  );

  const deleteTopicTag = useCallback(
    async topicTagId => {
      if (!topicTagId) {
        notify('No tag value!', 'error');
        return;
      }
      setIsUpdatingTags(true);
      try {
        await Api.deleteTopicTag(topicTagId);
        await fetchTopicTags(locale);
        notify('Tag deleted!', 'success');
      } catch (e) {
        notify('Error deleting tag!', 'error');
        console.error(e);
      }
      setIsUpdatingTags(false);
    },
    [fetchTopicTags, locale, notify]
  );

  const resetCache = useCallback(
    async ({ showNotify = true }) => {
      if (!topic?.quizId) return;
      try {
        setCacheState(() => ({ ...cacheState, isLoading: true }));
        await Api.reCacheFlowTemplates({
          quizIds: [topic.quizId]
        });
        setCacheState(() => ({
          ...cacheState,
          isModalOpen: false,
          isLoading: false
        }));
        showNotify && notify('Whoop! Cache cleared!', 'success');
      } catch (error) {
        setCacheState(() => ({ ...cacheState, isLoading: false }));
        notify('Error resetting cache', 'error');
        console.log(error);
      }
    },
    [cacheState, notify, topic?.quizId]
  );

  const updateTopicPathwayQuiz = useCallback(
    async (values, isTags = false) => {
      if (isTags) setIsUpdatingTags(true);
      try {
        const { data: update } = await dataProvider.update(
          'topic-pathway-quizs',
          {
            id: values.topicPathwayQuizId,
            body: values
          }
        );

        setTopic(() => update);

        if (!isTags) {
          await resetCache({ showNotify: false });
          refresh();
          setIsOpen();
        } else {
          await fetchTopicTags(locale);
        }
        notify('Topic Updated!', 'success');
      } catch (e) {
        notify('Error updating topic!', 'error');
        console.error(e);
      }
      setIsUpdatingTags(false);
    },
    [
      dataProvider,
      fetchTopicTags,
      locale,
      notify,
      refresh,
      resetCache,
      setIsOpen
    ]
  );

  useEffect(() => {
    const runEffect = async () => {
      if (isLoaded || !isOpen) return;
      let countryCode = 'GB';
      if (topicPathwayQuizId) {
        const topic = await fetchTopicPathwayQuiz(topicPathwayQuizId);
        if (topic) {
          const collections =
            (await Api.getAllTopicPathwayCollections([])) ?? [];
          const currentCollection = collections?.find(
            c => c.topicPathwayCollectionId === topic.topicPathwayCollectionId
          );
          if (currentCollection) {
            countryCode = !currentCollection.locale?.length
              ? 'GB'
              : currentCollection.locale.match(/.{2}$/)[0];
          }
        }
      }
      const currentLocale =
        languageHelpers.locales.find(c => c.countryCode === countryCode)
          ?.code ?? 'en-GB';
      console.log(
        currentLocale,
        countryCode,
        languageHelpers.locales,
        languageHelpers.locales.find(c => c.countryCode === countryCode)
      );
      setLocale(currentLocale);
      await fetchCountryYearGroups(countryCode);
      await fetchTopicTags(locale);
      await fetchTopicTags(null, null);
      setIsLoaded(true);
    };
    runEffect();
  }, [
    fetchCountryYearGroups,
    fetchTopicPathwayQuiz,
    fetchTopicTags,
    topicTags,
    isLoaded,
    isOpen,
    topicPathwayQuizId,
    locale
  ]);

  if (!isLoaded) return <FullPageLoader loading={true} />;

  const tabs = [
    {
      label: 'Edit Topic',
      children: (
        <EditTopicPathwayQuizForm
          topic={topic}
          updateTopicPathwayQuiz={updateTopicPathwayQuiz}
          countryYearGroups={countryYearGroups}
          cacheState={cacheState}
          setIsOpen={setIsOpen}
          resetCache={resetCache}
        />
      )
    },
    {
      label: 'Edit Tags',
      children: (
        <EditTopicTagForm
          topic={topic}
          updateTopicPathwayQuiz={updateTopicPathwayQuiz}
          locale={locale}
          setLocale={setLocale}
          topicTags={topicTags}
          allTopicTags={allTopicTags}
          fetchTopicTags={fetchTopicTags}
          updateTopicTag={updateTopicTag}
          deleteTopicTag={deleteTopicTag}
          isUpdatingTags={isUpdatingTags}
          topicTagIds={topic?.topicTagIds || []}
        />
      )
    }
  ];

  return (
    <Modal
      open={isOpen}
      onClose={() => setIsOpen()}
      className={classes.modal}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
      closeAfterTransition>
      <Container maxWidth="md">
        <Card className={classes.create}>
          <CardContent className={classes.content}>
            <TabPanel tabs={tabs} />
          </CardContent>
        </Card>
      </Container>
    </Modal>
  );
};

export default EditTopicPathwayQuizModal;
