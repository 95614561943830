import { makeStyles } from '@material-ui/core/styles';
import { Field, Form } from 'react-final-form';
import {
  Select,
  InputLabel,
  MenuItem,
  TextField,
  FormGroup,
  Button,
  Box
} from '@material-ui/core';
import {
  ContentTypes,
  FlowItemType,
  FlowItemTypes,
  InputFlowItemTypes
} from 'Components/FlowItems';
import { useCallback, useState } from 'react';
import Api from 'Api/Api';
import { useNotify } from 'react-admin';
import Check from '@material-ui/icons/Check';
import EditRadioButtonForm from './EditRadioButtonForm';
import styled from 'styled-components';
import ArrayAutoComplete from './ArrayAutoComplete';
import SaveConfirmButton from '../SaveConfirmButton';
import { parseTemplateText } from '../flowTemplateHelpers';

const useStyles = makeStyles(theme => ({
  root: {},
  select: {
    width: '100%',
    margin: '1rem 0 1rem'
  },
  formGroup: {
    width: '100%',
    margin: '0 0 0.25rem 0'
  },
  inputLabel: {
    position: 'relative'
  },
  h3: {
    padding: '0 0 0 1rem'
  },
  label: {
    padding: '0 0 0 1rem',
    fontSize: '1rem',
    textAlign: 'left'
  },
  multiline: {
    width: '100%',
    margin: '0.25rem 0 0.5rem'
  },
  button: {
    margin: '0.25rem 0 1rem'
  },
  valid: {
    padding: '0.5rem !important',
    margin: '0 0 1rem 0',
    background: '#c8e6c9',
    borderRadius: '4px',
    border: '1px solid #7cb342',
    color: '#7cb342'
  },
  invalid: {
    padding: '0.5rem !important',
    margin: '0 0 1rem 0',
    background: '#ffcdd2',
    borderRadius: '4px',
    border: '1px solid #f44336',
    color: '#f44336'
  },
  autoComplete: {
    margin: '0.25rem 0 0.25rem'
  },
  box: {
    margin: '0.25rem 0 0.25rem',
    border: '1px solid #ccc',
    padding: '0.5rem !important',
    borderRadius: '4px'
  }
}));

const calculateVisibleFields = values => {
  const fields = [
    {
      name: 'sequence',
      isVisible: true
    },
    {
      name: 'flowItemGroup',
      isVisible: true
    },
    {
      name: 'flowItemType',
      isVisible: true
    },
    {
      name: 'radioButtons',
      isVisible: values.flowItemType === FlowItemType.RadioButtonInput
    },
    {
      name: 'contentType',
      isVisible: [...InputFlowItemTypes, FlowItemType.Validator].includes(
        values.flowItemType
      )
    },
    {
      name: 'isNextExpression',
      isVisible: true
    },
    {
      name: 'isSuccessExpression',
      isVisible: [...InputFlowItemTypes, FlowItemType.Validator].includes(
        values.flowItemType
      )
    },
    {
      name: 'textOptions',
      isVisible:
        values.flowItemType !== FlowItemType.Validator && !!values.flowItemType
    },
    {
      name: 'imageUrlOptions',
      isVisible:
        values.flowItemType === FlowItemType.PinnedImage ||
        values.flowItemType === FlowItemType.Image,
      presets: [{ name: 'flowItemType', value: FlowItemType.Image }]
    },
    {
      name: 'videoUrlOptions',
      isVisible:
        values.flowItemType === FlowItemType.Video ||
        values.flowItemType === FlowItemType.PinnedVideo,
      presets: [{ name: 'flowItemType', value: FlowItemType.Video }]
    }
  ];

  return fields;
};

const EditFlowItemForm = ({
  flowItem = {},
  flowItems = [],
  templateSlug,
  flowTemplates = [],
  title = 'Flow Item Template',
  loading = false,
  updateFlowItem = () => {},
  ...props
}) => {
  const classes = useStyles();
  const notify = useNotify();
  const [validatedIsNextExpression, setValidatedIsNextExpression] =
    useState('');
  const [invalidIsNextExpression, setInvalidIsNextExpression] = useState('');
  const [validatedIsSuccessExpression, setValidatedIsSuccessExpression] =
    useState('');
  const [invalidIsSuccessExpression, setInvalidIsSuccessExpression] =
    useState('');
  const [fields, setFields] = useState(calculateVisibleFields(flowItem));

  const onChange = useCallback((onChange, name, value, values) => {
    onChange(value);
    const valuesCopy = { ...values };
    valuesCopy[name] = value;
    setFields(() => calculateVisibleFields(valuesCopy));
  }, []);

  const isVisible = name => {
    const field = fields.find(f => f.name === name);
    if (!field) return true;
    return field?.isVisible;
  };

  const validateExpression = useCallback(
    async (expression, setValid, setInvalid) => {
      try {
        const result = await Api.validateExpression(expression);

        if (!result) {
          throw new Error('No results returned!');
        }

        if (
          result?.exceptionMessage?.length &&
          result?.exceptionMessage !== 'RuleFailed'
        ) {
          setInvalid(expression);
          throw new Error(result.exceptionMessage);
        }

        setValid(expression);
        notify('Rule passed validation', 'success');
      } catch (e) {
        notify(e.message, 'error');
        console.error(e);
      }
    },
    [notify]
  );

  return (
    <Form
      key={`${flowItem?.sequence}`}
      className={classes.root}
      onSubmit={async values => {
        await updateFlowItem(
          values,
          !!flowItem?.sequence ? flowItem.sequence : values.sequence || 1
        );
      }}
      initialValues={{
        sequence: 1,
        templateSlug,
        xPosition: 0,
        yPosition: 0,
        ...flowItem
      }}
      validate={values => {
        const errors = {};
        if (!values.flowItemGroup && isVisible('flowItemGroup')) {
          errors.flowItemGroup = 'Please enter a FlowItemGroup';
        }
        if (
          values.flowItemGroup &&
          values.flowItemGroup.length < 5 &&
          isVisible('flowItemGroup')
        ) {
          errors.flowItemGroup = 'FlowItemGroup must be at least 5 characters';
        }
        if (!values.flowItemType && isVisible('flowItemType')) {
          errors.flowItemType = 'Please enter a FlowItemType';
        }
        if (
          !values.contentType &&
          !InputFlowItemTypes.includes(values.flowItemType) &&
          isVisible('contentType')
        ) {
          errors.contentType =
            'Please enter a ContentType to go with the input';
        }
        if (
          InputFlowItemTypes.includes(values.flowItemType) &&
          !values.isSuccessExpression &&
          isVisible('isSuccessExpression')
        ) {
          errors.isSuccessExpression =
            'Please enter an IsSuccessExpression or use the default';
        }
        if (
          (values.flowItemType === FlowItemType.Text ||
            values.flowItemType === FlowItemType.Information ||
            values.flowItemType === FlowItemType.HighlightedText ||
            values.flowItemType === FlowItemType.Divider) &&
          !values.textOptions?.length
        ) {
          errors.textOptions = 'Please enter at least one Text Option';
        }
        if (
          values.flowItemType === FlowItemType.Video &&
          !values.videoUrlOptions?.length
        ) {
          errors.videoUrlOptions = 'Please enter at least one Video URL Option';
        }
        if (
          values.flowItemType === FlowItemType.Image &&
          !values.imageUrlOptions?.length
        ) {
          errors.imageUrlOptions = 'Please enter at least one Image URL Option';
        }
        return errors;
      }}>
      {({ handleSubmit, submitting, pristine, submitError, values }) => (
        <form onSubmit={handleSubmit} {...props}>
          {!!title ? <h3 className={classes.h3}>{title}</h3> : null}
          <FormGroup className={classes.formGroup}>
            {isVisible('sequence') ? (
              <Field name="sequence">
                {({ input, meta }) => (
                  <TextField
                    {...input}
                    label="Sequence *"
                    variant="filled"
                    type="number"
                    InputLabelProps={{
                      shrink: true
                    }}
                    onChange={e =>
                      onChange(
                        input.onChange,
                        'sequence',
                        e.target.value,
                        values
                      )
                    }
                    className={classes.input}
                    error={meta.error && meta.touched}
                    helperText={meta.error && meta.touched ? meta.error : null}
                  />
                )}
              </Field>
            ) : null}
          </FormGroup>
          {isVisible('flowItemGroup') ? (
            <FormGroup className={classes.formGroup}>
              <Field name="flowItemGroup">
                {({ input, meta }) => (
                  <TextField
                    {...input}
                    label="FlowItemGroup *"
                    variant="filled"
                    onChange={e =>
                      onChange(
                        input.onChange,
                        'flowItemGroup',
                        parseTemplateText(e.target.value),
                        values
                      )
                    }
                    InputLabelProps={{
                      shrink: true
                    }}
                    className={classes.input}
                    error={meta.error && meta.touched}
                    helperText={meta.error && meta.touched ? meta.error : null}
                  />
                )}
              </Field>
            </FormGroup>
          ) : null}
          {isVisible('flowItemType') ? (
            <FormGroup className={classes.formGroup}>
              <Field name="flowItemType">
                {({ input, meta }) => (
                  <>
                    <InputLabel
                      required
                      id="flowItemType-label"
                      className={classes.select}>
                      Flow Item Type
                    </InputLabel>
                    <Select
                      {...input}
                      onChange={e =>
                        onChange(
                          input.onChange,
                          'flowItemType',
                          e.target.value,
                          values
                        )
                      }
                      name="flowItemType"
                      labelId="flowItemType-label"
                      error={meta.error && meta.touched}>
                      {FlowItemTypes.map((c, idx) => (
                        <MenuItem key={idx} value={c}>
                          {c}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
              </Field>
            </FormGroup>
          ) : null}
          {isVisible('radioButtons') ? (
            <FormGroup>
              <Field name="radioButtons">
                {({ input }) => (
                  <EditRadioButtonForm
                    className={classes.box}
                    radioButtons={values.radioButtons}
                    onChange={opts =>
                      onChange(input.onChange, 'radioButtons', opts, values)
                    }
                  />
                )}
              </Field>
            </FormGroup>
          ) : null}
          {isVisible('contentType') ? (
            <FormGroup className={classes.formGroup}>
              <Field name="contentType">
                {({ input, meta }) => (
                  <>
                    <InputLabel
                      required
                      id="contentType-label"
                      className={classes.select}>
                      Content Type
                    </InputLabel>
                    <Select
                      {...input}
                      onChange={e =>
                        onChange(
                          input.onChange,
                          'contentType',
                          e.target.value,
                          values
                        )
                      }
                      name="contentType"
                      labelId="contentType-label"
                      error={meta.error && meta.touched}>
                      {ContentTypes.map((c, idx) => (
                        <MenuItem key={idx} value={c}>
                          {c}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
              </Field>
            </FormGroup>
          ) : null}
          {isVisible('isNextExpression') ? (
            <Box className={classes.box}>
              <FormGroup>
                <Field name="isNextExpression">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      className={classes.multiline}
                      spellCheck={false}
                      label="IsNextExpression (optional)"
                      onChange={e =>
                        onChange(
                          input.onChange,
                          'isNextExpression',
                          e.target.value,
                          values
                        )
                      }
                      multiline
                      minRows={2}
                      error={meta.error && meta.touched}
                      helperText={
                        meta.error && meta.touched ? meta.error : null
                      }
                    />
                  )}
                </Field>
              </FormGroup>
              {!!values.isNextExpression &&
              values.isNextExpression === validatedIsNextExpression ? (
                <div className={classes.valid}>Expression is valid</div>
              ) : !!values.isNextExpression &&
                values.isNextExpression === invalidIsNextExpression ? (
                <div className={classes.invalid}>Expression is invalid</div>
              ) : null}
              <Button
                className={classes.validateButton}
                variant="contained"
                color="secondary"
                disabled={
                  !values.isNextExpression ||
                  invalidIsNextExpression === values.isNextExpression ||
                  validatedIsNextExpression === values.isNextExpression
                }
                onClick={() =>
                  validateExpression(
                    values.isNextExpression,
                    setValidatedIsNextExpression,
                    setInvalidIsNextExpression
                  )
                }
                endIcon={<Check />}>
                Validate
              </Button>
            </Box>
          ) : null}
          {isVisible('textOptions') ? (
            <Box className={classes.box}>
              <ArrayAutoComplete
                name="textOptions"
                label="Text Options"
                values={values}
                onChange={(opts, set) => {
                  onChange(set, 'textOptions', opts, values);
                }}
              />
            </Box>
          ) : null}
          {isVisible('isSuccessExpression') ? (
            <Box className={classes.box}>
              <FormGroup>
                <Field name="isSuccessExpression">
                  {({ input, meta }) => (
                    <>
                      <TextField
                        {...input}
                        className={classes.multiline}
                        spellCheck={false}
                        onChange={e =>
                          onChange(
                            input.onChange,
                            'isSuccessExpression',
                            e.target.value,
                            values
                          )
                        }
                        label="IsSuccessExpression"
                        multiline
                        minRows={2}
                        error={meta.error && meta.touched}
                        helperText={
                          meta.error && meta.touched ? meta.error : null
                        }
                      />
                      {values.isSuccessExpression ===
                      validatedIsSuccessExpression ? (
                        <div className={classes.valid}>Expression is valid</div>
                      ) : values.isSuccessExpression ===
                        invalidIsSuccessExpression ? (
                        <div className={classes.invalid}>
                          Expression is invalid
                        </div>
                      ) : null}
                      <ButtonWrapper>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => {
                            input.onChange('INPUT != NULL');
                          }}>
                          Default
                        </Button>
                        <Button
                          className={classes.validateButton}
                          variant="contained"
                          color="secondary"
                          disabled={
                            !values.isSuccessExpression ||
                            invalidIsSuccessExpression ===
                              values.isSuccessExpression ||
                            validatedIsSuccessExpression ===
                              values.isSuccessExpression
                          }
                          onClick={() =>
                            validateExpression(
                              values.isSuccessExpression,
                              setValidatedIsSuccessExpression,
                              setInvalidIsSuccessExpression
                            )
                          }
                          endIcon={<Check />}>
                          Validate
                        </Button>
                      </ButtonWrapper>
                    </>
                  )}
                </Field>
              </FormGroup>
            </Box>
          ) : null}
          {isVisible('imageUrlOptions') ? (
            <Box className={classes.box}>
              <ArrayAutoComplete
                name="imageUrlOptions"
                label="Image URL Options"
                values={values}
                onChange={(opts, set) =>
                  onChange(
                    set,
                    'imageUrlOptions',
                    opts.map(opt => parseTemplateText(opt)),
                    values
                  )
                }
              />
            </Box>
          ) : null}
          {isVisible('videoUrlOptions') ? (
            <Box className={classes.box}>
              <ArrayAutoComplete
                name="videoUrlOptions"
                label="Video URL Options"
                values={values}
                onChange={(opts, set) =>
                  onChange(
                    set,
                    'videoUrlOptions',
                    opts.map(opt => parseTemplateText(opt)),
                    values
                  )
                }
              />
            </Box>
          ) : null}
          <SaveConfirmButton
            isDoubleConfirm={true}
            submitting={submitting || loading}
            pristine={pristine}
            submitError={submitError}
            confirmCopy="Confirm Save?"
            style={{ width: '100%', padding: '0', margin: '0' }}
          />
        </form>
      )}
    </Form>
  );
};

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export default EditFlowItemForm;
