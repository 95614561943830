import {
  Card,
  CardContent,
  Container,
  Typography,
  TextField,
  Button,
  FormGroup
} from '@material-ui/core';
import Api from 'Api/Api';
import { useCallback, useState } from 'react';
import { useNotify, Notification } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Form, Field } from 'react-final-form';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';

const useStyles = makeStyles({
  root: {
    margin: '1rem 0 0 0'
  },
  input: {
    margin: '1rem 0 0 0'
  },
  createButton: {
    margin: '1rem 0 0 0'
  },
  valid: {
    padding: '0.5rem !important',
    margin: '1rem 0 1rem 0',
    background: '#c8e6c9',
    borderRadius: '4px',
    border: '1px solid #7cb342',
    color: '#7cb342'
  },
  invalid: {
    padding: '0.5rem !important',
    margin: '1rem 0 1rem 0',
    background: '#ffcdd2',
    borderRadius: '4px',
    border: '1px solid #f44336',
    color: '#f44336'
  }
});

const ApproveTeacherShow = props => {
  const classes = useStyles();
  const notify = useNotify();
  const [response, setResponse] = useState(null);
  const [state, setState] = useState({
    dispatching: false
  });

  const validateEmail = email => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
  };

  const award = useCallback(
    async values => {
      try {
        setState(() => ({ ...state, dispatching: true }));
        const result = await Api.approveTeacher(values);
        setResponse(() => ({
          isSuccess: true,
          info: `${result.firstName} successfully added to ${result.schoolName}!`
        }));
        notify('Teacher approved!', 'success');
      } catch (e) {
        const error = `Error! unable to approve teacher: ${e}`;
        console.error(error);
        setResponse(() => ({ info: error, isSuccess: false }));
        notify(error, 'warning');
      }
      setState(() => ({ ...state, dispatching: false }));
    },
    [notify, state]
  );

  return (
    <Container maxWidth="md">
      <Card className={classes.root}>
        <CardContent>
          <Form
            onSubmit={award}
            initialValues={{
              schoolId: null,
              email: null
            }}
            validate={values => {
              const errors = {};
              if (values.email === null) {
                errors.email = 'Please enter an email.';
              }
              if (!validateEmail(values.email)) {
                errors.email = 'Please enter a valid email.';
              }
              return errors;
            }}>
            {({ handleSubmit, submitting, pristine, submitError }) => (
              <form onSubmit={handleSubmit}>
                <Typography variant="h5" gutterBottom>
                  Approve Teacher
                </Typography>
                <Typography
                  variant="subtitle1"
                  className={classes.schoolName}
                  gutterBottom>
                  Enter a teachers email and optionally a schoolId to approve
                  them.
                </Typography>
                <FormGroup>
                  <Field name="email">
                    {({ input, meta }) => (
                      <TextField
                        {...input}
                        id="standard-basic"
                        label="Teachers Email"
                        variant="filled"
                        type="text"
                        className={classes.input}
                        error={meta.error && meta.touched}
                        helperText={
                          meta.error && meta.touched ? meta.error : null
                        }
                      />
                    )}
                  </Field>
                </FormGroup>
                <FormGroup>
                  <Field name="schoolId">
                    {({ input, meta }) => (
                      <TextField
                        {...input}
                        id="standard-basic"
                        label="SchoolId (optional)"
                        variant="filled"
                        type="number"
                        className={classes.input}
                        error={meta.error && meta.touched}
                        helperText={
                          meta.error && meta.touched ? meta.error : null
                        }
                      />
                    )}
                  </Field>
                </FormGroup>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  type="submit"
                  className={classes.createButton}
                  startIcon={<ThumbUpIcon />}
                  disabled={
                    submitting || pristine || submitError || state.dispatching
                  }>
                  Submit
                </Button>
                {response?.isSuccess === true ? (
                  <div className={classes.valid}>{response.info}</div>
                ) : response?.isSuccess === false ? (
                  <div className={classes.invalid}>{response.info}</div>
                ) : null}
              </form>
            )}
          </Form>
        </CardContent>

        <Notification />
      </Card>
    </Container>
  );
};

export default ApproveTeacherShow;
