import { Card, CardContent, Container } from '@material-ui/core';
import Api from 'Api/Api';
import { useCallback, useEffect, useState } from 'react';
import { useNotify, Notification, Loading } from 'react-admin';
import UpdateHybridSchoolForm from './Components/UpdateHybridSchoolForm';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    margin: '1rem 0 0 0'
  }
});

const HybridSchoolEdit = props => {
  const classes = useStyles();
  const { history, id: schoolId } = props;
  const notify = useNotify();
  const [school, setSchool] = useState(null);

  const fetchSchool = useCallback(
    async schoolId => {
      try {
        const school = await Api.getSchool(schoolId);
        const hybridSchool = await Api.getHybridSchool(schoolId);
        school.isHybridSchool = !!hybridSchool;
        school.defaultTrialLength = hybridSchool?.defaultTrialLength;
        school.teacherId = hybridSchool?.teacherId;
        setSchool(school);
      } catch (e) {
        notify(`Unable to fetch school! ${e}`, 'error');
      }
    },
    [notify]
  );

  useEffect(() => {
    const runEffect = async () => {
      if (!school && schoolId) {
        await fetchSchool(schoolId);
      }
    };
    runEffect();
  }, [fetchSchool, school, schoolId]);

  if (!school) return <Loading />;

  return (
    <Container maxWidth="md">
      <Card className={classes.root}>
        <CardContent>
          <UpdateHybridSchoolForm
            school={school}
            onCancel={() => history.push('/hybrid-schools')}
          />
        </CardContent>

        <Notification />
      </Card>
    </Container>
  );
};

export default HybridSchoolEdit;
