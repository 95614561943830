import { List, Pagination, FunctionField } from 'react-admin';
import { Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import EmbeddingOutputDataGrid from './Components/EmbeddingOutputDataGrid';
import { useState } from 'react';
import EmbeddingOutputFilters from './Components/EmbeddingOutputFilters';
import EditIcon from '@material-ui/icons/Edit';
import EmbeddingOutputListActions from './Components/EmbeddingOutputListActions';
import UpdateEmbeddingModal from './Components/UpdateEmbeddingModal';

const useStyles = makeStyles(theme => ({
  root: {
    margin: '12px',
    minHeight: '350px'
  },
  editButton: {
    color: '#4353ff',
    fontSize: '0.8125rem',
    padding: '4px',
    border: 'none',
    background: 'none',
    boxShadow: 'none'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '600px'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  formGroup: { display: 'flex', flexDirection: 'row', width: '100%' },
  buttonWrapper: { display: 'flex' }
}));

const StyledList = styled(List)`
  .MuiToolbar-root {
    justify-content: flex-start;
  }
`;

const EmbeddingOutputListPagination = props => (
  <Pagination rowsPerPageOptions={[20, 30, 40, 50]} {...props} />
);

const EmbeddingOutputList = props => {
  document.body.style.overflow = '';
  const classes = useStyles();

  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [currentEmbeddingOutput, setCurrentEmbeddingOutput] = useState(null);

  return (
    <Box className={classes.root}>
      {isUpdateModalOpen && (
        <UpdateEmbeddingModal
          embeddingOutput={currentEmbeddingOutput}
          isOpen={true}
          setIsOpen={() => setIsUpdateModalOpen(false)}
        />
      )}
      <StyledList
        title="Embedding Outputs"
        filters={<EmbeddingOutputFilters />}
        pagination={<EmbeddingOutputListPagination />}
        actions={<EmbeddingOutputListActions />}
        bulkActionButtons={false}
        perPage={20}
        {...props}>
        <EmbeddingOutputDataGrid rowClick="">
          <FunctionField
            label="EmbeddingOutputId"
            sortBy="id"
            render={r => r.id}
          />
          <FunctionField
            label="SearchText"
            sortBy="searchText"
            render={r => r.searchText}
          />
          <FunctionField label="Type" sortBy="type" render={r => r.type} />
          <FunctionField
            label="LinkedItems"
            render={r =>
              r.misconceptionTags?.length > 0
                ? r.misconceptionTags?.length
                : r.topicPathwayQuizEmbeddings?.length > 0
                ? r.topicPathwayQuizEmbeddings?.length
                : '-'
            }
          />
          <FunctionField
            label="Edit"
            sortBy="Edit"
            render={r => (
              <EditButton
                classes={classes}
                setIsUpdateModalOpen={() => {
                  setCurrentEmbeddingOutput(r);
                  setIsUpdateModalOpen(true);
                }}
              />
            )}
          />
        </EmbeddingOutputDataGrid>
      </StyledList>
    </Box>
  );
};

const EditButton = ({ classes, setIsUpdateModalOpen }) => (
  <Button
    variant="contained"
    color="default"
    className={classes.editButton}
    startIcon={<EditIcon />}
    onClick={setIsUpdateModalOpen}>
    Edit Embedding
  </Button>
);

export default EmbeddingOutputList;
