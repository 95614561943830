import { Form } from 'react-final-form';
import {
  Box,
  Chip,
  InputAdornment,
  InputLabel,
  FormControl,
  FilledInput,
  IconButton
} from '@material-ui/core';
import { useListContext } from 'react-admin';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import debounce from 'Helpers/debounce';
import { useCallback, useEffect, useState } from 'react';
import fetchLessonFilters from '../Helpers/fetchLessonFilters';

const useStyles = makeStyles({
  root: {
    padding: '1rem',
    borderBottom: '1px solid #ddd'
  },
  box: {
    width: '100%'
  },
  searchControl: {
    width: '100%',
    borderRadius: 0
  },
  searchInput: {
    maxHeight: '40px',
    margin: '0 0 1rem 0'
  },
  chips: {
    marginBottom: '0.25rem'
  },
  chip: {
    margin: '0 0 2px 2px'
  }
});

const LessonFilterForm = props => {
  const classes = useStyles();
  const { displayedFilters, filterValues, setFilters } = useListContext();
  const [lessonFilterValues, setLessonFilterValues] = useState({});
  const [searchText, setSearchText] = useState('');

  const savefilters = useCallback(
    updatedFilterValues => {
      setFilters(updatedFilterValues);
      setLessonFilterValues(updatedFilterValues);
      localStorage.setItem(
        'lessonFilterValues',
        JSON.stringify(updatedFilterValues)
      );
    },
    [setFilters]
  );

  const toggleFilter = useCallback(
    values => {
      let isSet = false;
      for (const [key] of Object.entries(filterValues)) {
        if (values[key] && filterValues[key].id === values[key].id) {
          delete filterValues[key];
          savefilters({ ...filterValues });
          isSet = true;
          break;
        }
      }
      if (!isSet) {
        savefilters({ ...filterValues, ...values });
      }
    },
    [filterValues, savefilters]
  );

  useEffect(() => {
    const fetchedFilterValues = fetchLessonFilters(filterValues);
    setLessonFilterValues(fetchedFilterValues);
    if (fetchedFilterValues?.q) setSearchText(fetchedFilterValues.q);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!displayedFilters?.main) return null;

  const onSubmit = values => {
    if (Object.keys(values).length > 0) {
      savefilters({ ...filterValues, ...values });
    }
  };

  const onSearchTextChange = event => {
    if (!event?.target?.value?.length) {
      delete filterValues['q'];
      savefilters({ ...filterValues });
    } else {
      debounce(
        savefilters({ ...filterValues, ...{ q: event.target.value } }),
        500
      );
    }
  };

  return (
    <Box className={classes.root}>
      <Form onSubmit={onSubmit} initialValues={lessonFilterValues}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Box display="flex" className={classes.box}>
              <Box component="span" className={classes.box}>
                <FormControl variant="filled" className={classes.searchControl}>
                  <InputLabel>Search</InputLabel>
                  <FilledInput
                    type="text"
                    source="q"
                    label="Search"
                    name="Search"
                    value={searchText}
                    className={classes.searchInput}
                    onChange={e => {
                      onSearchTextChange(e);
                      setSearchText(e.target.value);
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            savefilters({});
                            setSearchText('');
                          }}
                          onMouseDown={() => {}}
                          edge="end">
                          {!null ? <ClearIcon /> : <SearchIcon />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Box>
            </Box>
            <Box>
              <Box className={classes.chips}>
                <Chip
                  size="small"
                  label="Online"
                  color={filterValues['isOnline'] ? 'primary' : 'default'}
                  className={classes.chip}
                  onClick={() =>
                    toggleFilter({
                      isOnline: {
                        id: 'isOnline',
                        Value: 'true',
                        Operator: 'Eq',
                        Type: 'boolean'
                      }
                    })
                  }
                />
                <Chip
                  size="small"
                  label="LessonInProgress"
                  color={
                    filterValues['isLessonInProgress'] ? 'primary' : 'default'
                  }
                  className={classes.chip}
                  onClick={() =>
                    toggleFilter({
                      isLessonInProgress: {
                        id: 'isLessonInProgress',
                        Value: 'true',
                        Operator: 'Eq',
                        Type: 'boolean'
                      }
                    })
                  }
                />
                <Chip
                  size="small"
                  label="Completed"
                  color={
                    filterValues['dateCompleted']?.Value ? 'primary' : 'default'
                  }
                  className={classes.chip}
                  onClick={() =>
                    toggleFilter({
                      dateCompleted: {
                        id: 'isCompleted',
                        Value: moment()
                          .subtract(9999, 'days')
                          .startOf('day')
                          .format(),
                        Operator: 'GT'
                      }
                    })
                  }
                />
                <Chip
                  size="small"
                  label="Not Completed"
                  color={
                    filterValues['dateCompleted']?.Operator === 'Eq'
                      ? 'primary'
                      : 'default'
                  }
                  className={classes.chip}
                  onClick={() =>
                    toggleFilter({
                      dateCompleted: {
                        id: 'isNotCompleted',
                        Value: null,
                        Operator: 'Eq'
                      }
                    })
                  }
                />
                <Chip
                  size="small"
                  label="LessonType: Quiz"
                  color={
                    filterValues['lessonType']?.Value === '3'
                      ? 'primary'
                      : 'default'
                  }
                  className={classes.chip}
                  onClick={() =>
                    toggleFilter({
                      lessonType: {
                        id: 'lessonTypeQuiz',
                        Value: '3',
                        Operator: 'Eq'
                      }
                    })
                  }
                />
                <Chip
                  size="small"
                  label="LessonType: Quiz Question"
                  color={
                    filterValues['lessonType']?.Value === '8'
                      ? 'primary'
                      : 'default'
                  }
                  className={classes.chip}
                  onClick={() =>
                    toggleFilter({
                      lessonType: {
                        id: 'lessonTypeQuiz',
                        Value: '8',
                        Operator: 'Eq'
                      }
                    })
                  }
                />
                <Chip
                  size="small"
                  label="LessonType: Stretch"
                  className={classes.chip}
                  color={
                    filterValues['lessonType']?.Value === '2'
                      ? 'primary'
                      : 'default'
                  }
                  onClick={() =>
                    toggleFilter({
                      lessonType: {
                        id: 'lessonTypeStretch',
                        Value: '2',
                        Operator: 'Eq'
                      }
                    })
                  }
                />
                <Chip
                  size="small"
                  label="ChatWithTutor"
                  className={classes.chip}
                  color={
                    filterValues['lessonType']?.Value === '7'
                      ? 'primary'
                      : 'default'
                  }
                  onClick={() =>
                    toggleFilter({
                      lessonType: {
                        id: 'lessonTypeChat',
                        Value: '7',
                        Operator: 'Eq'
                      }
                    })
                  }
                />
                <Chip
                  size="small"
                  label="OnDemand"
                  className={classes.chip}
                  color={
                    filterValues['lessonType']?.Value === '10'
                      ? 'primary'
                      : 'default'
                  }
                  onClick={() =>
                    toggleFilter({
                      lessonType: {
                        id: 'lessonTypeOnDemand',
                        Value: '10',
                        Operator: 'Eq'
                      }
                    })
                  }
                />
                <Chip
                  size="small"
                  label="General"
                  className={classes.chip}
                  color={
                    filterValues['lessonType']?.Value === '4'
                      ? 'primary'
                      : 'default'
                  }
                  onClick={() =>
                    toggleFilter({
                      lessonType: {
                        id: 'lessonTypeGeneral',
                        Value: '4',
                        Operator: 'Eq'
                      }
                    })
                  }
                />
                <Chip
                  size="small"
                  label="Help Requested"
                  color={
                    filterValues['isHelpRequested']?.Value === 'true'
                      ? 'primary'
                      : 'default'
                  }
                  onClick={() =>
                    toggleFilter({
                      isHelpRequested: {
                        id: 'isHelpRequested',
                        Value: 'true',
                        Operator: 'Eq'
                      }
                    })
                  }
                />
                <Chip
                  size="small"
                  label="All Claimed"
                  color={
                    filterValues['isActiveIntervention']?.Value === 'true'
                      ? 'primary'
                      : 'default'
                  }
                  onClick={() =>
                    toggleFilter({
                      isActiveIntervention: {
                        id: 'isActiveIntervention',
                        Value: 'true',
                        Operator: 'Eq'
                      }
                    })
                  }
                />
                <Chip
                  size="small"
                  label="My Claimed"
                  color={
                    filterValues['isMyActiveIntervention']?.Value === 'true'
                      ? 'primary'
                      : 'default'
                  }
                  onClick={() =>
                    toggleFilter({
                      isMyActiveIntervention: {
                        id: 'isMyActiveIntervention',
                        Value: 'true',
                        Operator: 'Eq'
                      }
                    })
                  }
                />
              </Box>
            </Box>
          </form>
        )}
      </Form>
    </Box>
  );
};

export default LessonFilterForm;
