import { makeStyles } from '@material-ui/core/styles';
import Api from 'Api/Api';
import { useCallback, useEffect, useState } from 'react';
import { useNotify } from 'react-admin';
import { Field, Form } from 'react-final-form';
import {
  TextField,
  Button,
  Select,
  InputLabel,
  MenuItem,
  FormGroup,
  IconButton
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import useUploadFile from 'Hooks/useUploadFile';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles(theme => ({
  input: {
    margin: '1rem 0 0 0'
  },
  updateButton: {
    margin: '1rem 0 0 0'
  },
  cancelButton: {
    margin: '1rem 0 0 10px'
  },
  select: {
    margin: '1rem 0 0 0',
    width: '100%'
  },
  uploadButton: {
    maxWidth: '50px',
    maxHeight: '50px'
  }
}));

const EditQuizQuestionForm = ({ questionId, onCancel = () => {} }) => {
  const classes = useStyles();
  const notify = useNotify();
  const [question, setQuestion] = useState(null);
  const [imgUrl, setImgUrl] = useState(null);
  const [loading, setLoading] = useState(false);

  const {
    file,
    onFileChange,
    FileDetails,
    fileRef,
    clearFile,
    convertToBase64
  } = useUploadFile(false, ['.jpg', '.jpeg', '.png']);

  const fetchQuestion = useCallback(async () => {
    if (loading) return;
    setLoading(true);
    try {
      const result = await Api.getQuizQuestion(questionId);
      if (!result) throw new Error(`Unable to fetch question ${questionId}`);
      setQuestion(() => result);
      setImgUrl(
        () => `https://images.diagnosticquestions.com${result?.imageURL}`
      );
    } catch (e) {
      notify('Error fetching question!', 'error');
      console.error(e);
    }
    setLoading(false);
  }, [loading, notify, questionId]);

  const updateQuestion = useCallback(
    async values => {
      if (loading) return;
      setLoading(true);
      try {
        const response = await Api.updateQuizQuestion(questionId, values);
        if (!response)
          throw new Error(`Unable to update question ${questionId}`);
        setQuestion(() => response);
        setImgUrl(
          () => `https://images.diagnosticquestions.com${response?.imageURL}`
        );
        notify('Question updated!', 'info');
        clearFile();
      } catch (e) {
        notify('Error updating question!', 'error');
        console.error(e);
      }
      setLoading(false);
    },
    [clearFile, loading, notify, questionId]
  );

  useEffect(() => {
    if (questionId && !question) fetchQuestion(questionId);
  }, [questionId, question, fetchQuestion]);

  const updateImageUrl = useCallback(
    file => {
      try {
        if (!file && question?.imageURL) {
          setImgUrl(
            () => `https://images.diagnosticquestions.com${question.imageURL}`
          );
          return;
        }

        if (!file) return;

        const reader = new FileReader();
        reader.onload = e => {
          setImgUrl(e.target.result);
        };
        reader.readAsDataURL(file);
      } catch (e) {
        console.error(e);
      }
    },
    [question?.imageURL]
  );

  useEffect(() => {
    updateImageUrl(file);
  }, [file, updateImageUrl]);

  console.log(question);

  return (
    <Form
      onSubmit={values => updateQuestion(values)}
      initialValues={question}
      validate={values => {
        const errors = {};
        if (!values.correctAnswer) {
          errors.correctAnswer = 'Please enter a correct answer';
        }
        if (!values.explanationA) {
          errors.explanationA = 'Please enter an explanation A';
        }
        if (!values.explanationB) {
          errors.explanationB = 'Please enter an explanation B';
        }
        if (!values.explanationC) {
          errors.explanationC = 'Please enter an explanation C';
        }
        if (!values.explanationD) {
          errors.explanationD = 'Please enter an explanation D';
        }
        console.log(errors);
        return errors;
      }}>
      {({ handleSubmit, submitting, pristine, submitError, form }) => (
        <form
          onSubmit={event => {
            event.preventDefault();
            handleSubmit(event);
          }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center'
            }}>
            <Field name="dataURL">
              {({ input }) => (
                <div>
                  <FormGroup>
                    <span>Upload new image</span>
                    <FileDetails />
                    <IconButton
                      disabled={loading}
                      type="file"
                      className={classes.uploadButton}
                      variant="contained"
                      color="secondary"
                      component="label">
                      <CloudUploadIcon />
                      <input
                        type="file"
                        ref={fileRef}
                        onChange={async e => {
                          const file = onFileChange(e);
                          if (file) {
                            const base64 = await convertToBase64(file);
                            input.onChange(base64);
                          }
                          updateImageUrl(file);
                        }}
                        hidden
                      />
                    </IconButton>
                  </FormGroup>
                </div>
              )}
            </Field>
            <div>
              <FormGroup>
                <div style={{ textAlign: 'center' }}>
                  <img
                    src={imgUrl}
                    alt=""
                    style={{ width: 'auto', maxHeight: '400px' }}
                  />
                </div>
              </FormGroup>
            </div>
          </div>
          <FormGroup>
            <Field name="explanationA">
              {({ input, meta }) => (
                <TextField
                  {...input}
                  name="explanationA"
                  label="Explanation A"
                  variant="filled"
                  disabled={loading}
                  className={classes.input}
                  fullWidth
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : null}
                />
              )}
            </Field>
          </FormGroup>
          <FormGroup>
            <Field name="explanationB">
              {({ input, meta }) => (
                <TextField
                  {...input}
                  name="explanationB"
                  label="Explanation B"
                  variant="filled"
                  disabled={loading}
                  className={classes.input}
                  fullWidth
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : null}
                />
              )}
            </Field>
          </FormGroup>
          <FormGroup>
            <Field name="explanationC">
              {({ input, meta }) => (
                <TextField
                  {...input}
                  name="explanationC"
                  label="Explanation C"
                  variant="filled"
                  disabled={loading}
                  className={classes.input}
                  fullWidth
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : null}
                />
              )}
            </Field>
          </FormGroup>
          <FormGroup>
            <Field name="explanationD">
              {({ input, meta }) => (
                <TextField
                  {...input}
                  name="explanationD"
                  label="Explanation D"
                  variant="filled"
                  disabled={loading}
                  className={classes.input}
                  fullWidth
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : null}
                />
              )}
            </Field>
          </FormGroup>

          <FormGroup>
            <Field name="correctAnswer">
              {({ input, meta }) => (
                <>
                  <InputLabel
                    required
                    id="subject-label"
                    className={classes.select}>
                    Correct Answer
                  </InputLabel>
                  <Select
                    {...input}
                    name="CorrectAnswer"
                    labelId="subject-label"
                    error={meta.error && meta.touched}>
                    {[
                      { key: 'A', value: 1 },
                      { key: 'B', value: 2 },
                      { key: 'C', value: 3 },
                      { key: 'D', value: 4 }
                    ].map((c, idx) => (
                      <MenuItem key={idx} value={c.value}>
                        {c.key}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              )}
            </Field>
          </FormGroup>
          <Button
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            className={classes.updateButton}
            startIcon={<SaveIcon />}
            disabled={submitting || pristine || submitError}>
            Update
          </Button>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            className={classes.cancelButton}
            onClick={() => onCancel()}
            startIcon={<CancelIcon />}
            disabled={submitting}>
            Cancel
          </Button>
        </form>
      )}
    </Form>
  );
};

export default EditQuizQuestionForm;
