import {
  TextField,
  Button,
  Popover,
  Grid,
  IconButton
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Api from 'Api/Api';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Picker, { SKIN_TONE_NEUTRAL } from 'emoji-picker-react';
import Typography from '@material-ui/core/Typography';
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import useUploadFile from 'Hooks/useUploadFile';
import { useNotify } from 'ra-core/esm/sideEffect';
import useFocus from 'Helpers/useFocus';
import useGetAuthUser from 'Hooks/useGetAuthUser';
import useDispatchIsTyping from 'Hooks/useDispatchIsTyping';
import CachedIcon from '@material-ui/icons/Cached';
import CancelIcon from '@material-ui/icons/Cancel';
import SendIcon from '@material-ui/icons/Send';
import ChatIcon from '@material-ui/icons/Chat';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import PromptModal from './PromptModal';
import useGetPermissions from 'Hooks/useGetPermissions';
import AssistantModal from './AssistantModal';
import AwardsModal from 'Pages/Awards/AwardsModal';
import StarsIcon from '@material-ui/icons/Stars';
import { useSignalROn } from 'Hooks/useSignalROn';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    minHeight: '180px',
    background: '#eee',
    padding: '1rem',
    borderTop: '1px solid #ccc'
  },
  textField: {
    background: '#fff',
    width: '100%'
  },
  button: {
    margin: '1rem 0 0 0'
  },
  gptLoading: {
    backgroundImage: 'url(/assets/gptloading.gif)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '40px',
    width: '40px',
    float: 'right'
  },
  emojiButton: {},
  uploadButton: {},
  buttonGrid: {
    display: 'flex'
  },
  optionsGrid: {
    textAlign: 'left'
  }
}));

const ChatBox = ({
  isPromptModalOpen = false,
  flowGeneratorSessionId,
  loading = false,
  text = '',
  isGPTEnabled = false,
  cancelTutorResponse = () => {},
  regenerateTutorResponse = () => {},
  clearTutorResponse = () => {},
  setIsPromptModalOpen = () => {},
  isRegenerateDispatched = false,
  studentUserId,
  signalRConnection = null,
  flowItemGroup = null,
  pinnedImage = null,
  pinnedText = null
}) => {
  const permissions = useGetPermissions();
  const notify = useNotify();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(loading);
  const [message, setMessage] = useState(text);
  const [anchorEl, setAnchorEl] = useState(null);
  const [textInput, setFocus] = useFocus();
  const [isAwardsModalOpen, setIsAwardsModalOpen] = useState(false);
  const user = useGetAuthUser();
  const { dispatchIsTyping } = useDispatchIsTyping(
    flowGeneratorSessionId,
    user?.userId
  );
  const [isAssistantModalOpen, setIsAssistantModalOpen] = useState(false);
  const [isCanvas, setIsCanvas] = useState(false);
  const [inputId, setInputId] = useState(flowItemGroup);

  // create url params for image and text to add to a link
  const urlParams = useMemo(() => {
    const urlParams = new URLSearchParams();
    urlParams.append('image', pinnedImage);
    urlParams.append('text', pinnedText);
    return urlParams;
  }, [pinnedImage, pinnedText]);

  const openWhiteboard = useCallback(
    inputId => {
      window.open(
        `/whiteboard/${flowGeneratorSessionId}${
          inputId ? `/${inputId}?${urlParams.toString()}` : ''
        }`,
        '_blank'
      );
    },
    [flowGeneratorSessionId, urlParams]
  );

  useSignalROn('WhiteboardOpenEvent', data => {
    console.log('WhiteboardOpenEvent', data);
    if (!data || isCanvas === data?.isOpen) return;
    notify(`Whiteboard was ${data?.isOpen ? 'opened' : 'closed'}`, 'info');
    setIsCanvas(data?.isOpen);
    setInputId(data?.inputId);

    if (data?.isOpen) {
      openWhiteboard(data?.inputId);
    }
  });

  useEffect(() => {
    if (!inputId && flowItemGroup) {
      setInputId(flowItemGroup);
    }
  }, [flowItemGroup, inputId]);

  const onToggleCanvas = () => {
    const isOpen = !isCanvas;
    setIsCanvas(isOpen);

    if (signalRConnection) {
      try {
        signalRConnection.invoke('DispatchOpenWhiteboardEvent', {
          sessionId: flowGeneratorSessionId,
          userId: user.userId,
          inputId,
          isOpen
        });
      } catch (error) {
        console.error(error);
      }
    }

    if (isOpen) {
      openWhiteboard(inputId);
    }
  };

  useEffect(() => {
    setMessage(text);
    setIsLoading(loading);
  }, [text, loading]);

  const {
    file,
    onFileChange,
    FileDetails,
    fileRef,
    getSetFormData,
    clearFile
  } = useUploadFile();

  const sendAwardMessage = useCallback(
    async award => {
      if (!award) return;

      try {
        setIsLoading(true);
        await Api.sendTutorMessage(
          flowGeneratorSessionId,
          `Well done! Here's ${award} for your efforts!`
        );
        setIsLoading(false);
        setFocus();
      } catch (e) {
        notify(e.toString(), 'warning');
        console.error(e);
        setIsLoading(false);
      }
    },
    [flowGeneratorSessionId, notify, setFocus]
  );

  const sendMessage = useCallback(async () => {
    if (!message && !file) return;
    try {
      setIsLoading(true);
      if (!file) {
        await Api.sendTutorMessage(flowGeneratorSessionId, message);
      } else {
        await Api.uploadFilesToFlowGeneratorSession(
          flowGeneratorSessionId,
          getSetFormData(message)
        );
      }
      clearTutorResponse();
      setMessage('');
      clearFile();
      setIsLoading(false);
      setFocus();
    } catch (e) {
      notify(e.toString(), 'warning');
      console.error(e);
      setIsLoading(false);
    }
  }, [
    message,
    file,
    clearTutorResponse,
    clearFile,
    setFocus,
    flowGeneratorSessionId,
    getSetFormData,
    notify
  ]);

  const onEmojiClick = (event, emojiObject) => {
    setMessage(`${message || ''}${emojiObject.emoji}`);
  };

  const onKeyPress = async e => {
    // If enter key is pressed, without shift
    if (!e.shiftKey && (e.key === 'Enter' || e.keyCode === 13)) {
      sendMessage();
    } else if (e.key !== 'Backspace' && e.key !== 'Delete') {
      await dispatchIsTyping();
    }
  };

  const isEmojiPickerOpen = Boolean(anchorEl);
  const popoverId = isEmojiPickerOpen ? 'emoji-popover' : undefined;

  return (
    <Grid className={classes.root} container>
      <Grid item xs={11}>
        <form id="chatbox">
          <TextField
            autoFocus
            inputRef={textInput}
            className={classes.textField}
            id="chatinput"
            multiline
            maxRows={8}
            minRows={4}
            disabled={isRegenerateDispatched}
            variant="outlined"
            onChange={e => setMessage(e.target.value)}
            value={message}
            onKeyPress={async e => await onKeyPress(e)}
          />
        </form>
      </Grid>
      <Grid item xs={1} className={classes.optionsGrid}>
        <IconButton
          disabled={isLoading || isRegenerateDispatched}
          aria-describedby={popoverId}
          className={classes.emojiButton}
          variant="contained"
          color="secondary"
          onClick={e => setAnchorEl(e.currentTarget)}>
          <EmojiEmotionsIcon />
        </IconButton>
        <IconButton
          disabled={isLoading || isRegenerateDispatched}
          type="file"
          className={classes.uploadButton}
          variant="contained"
          color="secondary"
          component="label">
          <AttachFileIcon />
          <input type="file" ref={fileRef} onChange={onFileChange} hidden />
        </IconButton>
        <IconButton
          disabled={isLoading || isRegenerateDispatched}
          type="file"
          className={classes.uploadButton}
          variant="contained"
          color="secondary"
          onClick={() => setIsAwardsModalOpen(true)}
          component="label">
          <StarsIcon />
        </IconButton>
        {/* <IconButton
          disabled={isLoading || isRegenerateDispatched}
          color="secondary"
          onClick={onToggleCanvas}>
          <box-icon name="edit" color="#2196f3" />
        </IconButton> */}
        <div></div>
      </Grid>
      <Grid item container xs={12} className={classes.buttonGrid}>
        <Grid item xs={12}>
          <Button
            className={classes.button}
            style={{ margin: '0.5rem 1rem 0 0' }}
            variant="contained"
            color="primary"
            disabled={
              (!message && !file) || isLoading || isRegenerateDispatched
            }
            startIcon={<SendIcon />}
            onClick={() => sendMessage()}>
            Send
          </Button>
          {isGPTEnabled && (
            <>
              <Button
                className={classes.button}
                style={{ margin: '0.5rem 1rem 0 0' }}
                variant="contained"
                color="secondary"
                disabled={isLoading || isRegenerateDispatched}
                startIcon={<CachedIcon />}
                onClick={() => regenerateTutorResponse()}>
                Generate Response
              </Button>
              <Button
                className={classes.button}
                style={{ margin: '0.5rem 1rem 0 0' }}
                variant="contained"
                color="secondary"
                disabled={!isLoading || isRegenerateDispatched}
                startIcon={<CancelIcon />}
                onClick={() => cancelTutorResponse()}>
                Stop Generating
              </Button>
              {!!permissions?.isSuperAdmin && (
                <Button
                  className={classes.button}
                  style={{ margin: '0.5rem 1rem 0 0' }}
                  variant="contained"
                  color="secondary"
                  disabled={isRegenerateDispatched}
                  startIcon={<ChatIcon />}
                  onClick={() => setIsPromptModalOpen(true)}>
                  Edit Prompt
                </Button>
              )}
              <Button
                className={classes.button}
                style={{ margin: '0.5rem 1rem 0 0' }}
                variant="contained"
                color="secondary"
                disabled={isLoading || isRegenerateDispatched}
                startIcon={<ContactSupportIcon />}
                onClick={() => setIsAssistantModalOpen(!isAssistantModalOpen)}>
                Assistant
              </Button>
              {isLoading && <div className={classes.gptLoading} />}
            </>
          )}
        </Grid>
        <Grid item xs={12}>
          <FileDetails />
        </Grid>
      </Grid>
      <Popover
        disabled={isLoading}
        id={popoverId}
        open={isEmojiPickerOpen}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}>
        <Typography className={classes.typography}>
          <Picker
            onEmojiClick={onEmojiClick}
            disableAutoFocus={true}
            skinTone={SKIN_TONE_NEUTRAL}
            groupNames={{ smileys_people: 'PEOPLE' }}
            native
          />
        </Typography>
      </Popover>
      <PromptModal
        isOpen={isPromptModalOpen}
        setIsOpen={setIsPromptModalOpen}
      />
      <AssistantModal
        isOpen={isAssistantModalOpen}
        setIsOpen={setIsAssistantModalOpen}
      />
      <AwardsModal
        isOpen={isAwardsModalOpen}
        setIsOpen={setIsAwardsModalOpen}
        studentUserId={studentUserId}
        onAwarded={sendAwardMessage}
      />
    </Grid>
  );
};

export default ChatBox;
