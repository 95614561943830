import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import Api from 'Api/Api';
import { createContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNotify } from 'react-admin';
import { useCallback } from 'react';

export const SignalRConnectionContext = createContext();

export const SignalRProvider = ({ children }) => {
  const notify = useNotify();
  const user = useSelector(u => u.user);
  const [connection, setConnection] = useState(null);
  const [connectionInfo, setConnectionInfo] = useState({
    userId: null,
    isLoading: false,
    isReconnecting: false,
    isConnected: false,
    connectionDate: null
  });

  const startConnection = async connection => {
    await connection
      .start()
      .then(() => {
        console.warn('connectionInfo - SignalR connection started.');
      })
      .catch(error => {
        console.error('connectionInfo - SignalR connection failed:', error);
        throw error;
      });
  };

  const onDisconnect = useCallback(() => {
    console.error('connectionInfo - SignalR connection closed.');
    setConnectionInfo({
      ...connectionInfo,
      isConnected: false,
      isReconnecting: false
    });
  }, [connectionInfo]);

  const onReconnecting = useCallback(() => {
    console.warn('connectionInfo - SignalR connection reconnecting...');
    setConnectionInfo({
      ...connectionInfo,
      isConnected: false,
      isReconnecting: true
    });
  }, [connectionInfo]);

  const onReconnected = useCallback(() => {
    console.warn('connectionInfo - SignalR connection reconnected.');
    setConnectionInfo({
      ...connectionInfo,
      isConnected: true,
      isReconnecting: false,
      connectionDate: new Date()
    });
  }, [connectionInfo]);

  const startSignalRConnection = useCallback(
    async forceReconnect => {
      try {
        const isConnection =
          connectionInfo.isConnected ||
          connectionInfo.isReconnecting ||
          connectionInfo.isLoading;

        if ((isConnection && !forceReconnect) || !user?.userId) return;

        setConnectionInfo({
          ...connectionInfo,
          isConnected: false,
          isLoading: true
        });

        const info = await Api.getSignalRConnectionInfo(
          user.userId,
          'LessonHub',
          'Admin'
        );

        info.accessToken = info.accessToken || info.accessKey;
        info.url = info.url || info.endpoint;
        const options = {
          accessTokenFactory: () => info.accessToken
        };

        const newConnection = new HubConnectionBuilder()
          .withUrl(info.url, options)
          .withAutomaticReconnect()
          .configureLogging(LogLevel.Debug)
          .build();

        await startConnection(newConnection);
        newConnection.onreconnecting(onReconnecting);
        newConnection.onreconnected(onReconnected);
        newConnection.onclose(onDisconnect);

        setConnectionInfo({
          ...connectionInfo,
          ...info,
          userId: user.userId,
          isConnected: true,
          isLoading: false,
          connectionDate: new Date()
        });
        setConnection(newConnection);
      } catch (e) {
        console.error('connectionInfo - SignalR connection failed:', e);
        throw e;
      }
    },
    [connectionInfo, onDisconnect, onReconnected, onReconnecting, user?.userId]
  );

  useEffect(() => {
    async function createSignalRConnection() {
      try {
        if (
          user?.userId &&
          connectionInfo?.userId !== user.userId &&
          !connectionInfo?.isLoading &&
          !user?.userRoles?.includes('ContractorAdmin')
        ) {
          await startSignalRConnection();
        }
      } catch (e) {
        console.error('connectionInfo - Provider Error!', e);
        setConnectionInfo({
          ...connectionInfo,
          isLoading: false
        });
      }
    }

    createSignalRConnection();
  }, [
    connection,
    connectionInfo,
    notify,
    onDisconnect,
    onReconnected,
    onReconnecting,
    startSignalRConnection,
    user
  ]);

  return (
    <SignalRConnectionContext.Provider
      value={{
        signalRConnection: connection,
        connectionInfo,
        startSignalRConnection
      }}>
      {children}
    </SignalRConnectionContext.Provider>
  );
};
