import { useEffect, useRef, useState } from 'react';
import { Loading } from 'react-admin';
import {
  Image,
  Layer,
  Rect,
  Stage,
  Text,
  Group,
  Transformer
} from 'react-konva';
import useImage from 'use-image';
import { scaleCalc } from './Components/imageHelpers';

const ImageMetaDataCanvas = ({
  rectangles = [],
  handleKeyDown = () => {},
  handleMouseDown = () => {},
  handleMouseMove = () => {},
  handleMouseUp = () => {},
  handleRectClick = () => {},
  selectedId = null,
  editState = {},
  imagewidth = 700,
  bitmap = null,
  imageURL = null
}) => {
  const [image] = useImage(imageURL);

  if (!bitmap || !imageURL) return <Loading />;

  return (
    <div onKeyDown={e => handleKeyDown(e)}>
      <Stage
        width={imagewidth}
        height={(imagewidth / 4) * 3}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={e => handleMouseUp({ id: editState?.nextId, e })}>
        <Layer>
          <Image
            width={imagewidth}
            height={(imagewidth / 4) * 3}
            x={0}
            image={image}
          />
        </Layer>
        <Layer>
          {rectangles.map((rect, i) => (
            <RectangleGroup
              rect={rect}
              key={i}
              bitmap={bitmap}
              imagewidth={imagewidth}
              handleRectClick={handleRectClick}
              selectedId={selectedId}
            />
          ))}
        </Layer>
      </Stage>
    </div>
  );
};

const RectangleGroup = ({
  rect,
  bitmap,
  imagewidth,
  selectedId,
  handleRectClick
}) => {
  const shapeRef = useRef();
  const trRef = useRef();
  const [textPosition, setTextPosition] = useState({ x: 6, y: 6 });

  useEffect(() => {
    if (rect.rectangleId === selectedId) {
      // we need to attach transformer manually
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [rect.rectangleId, selectedId]);

  useEffect(() => {
    if (shapeRef.current) {
      const groupPosition = shapeRef.current.position();
      setTextPosition({
        x: groupPosition.x + 6,
        y: groupPosition.y + 6
      });
    }
  }, [rect]);

  return (
    <>
      <Group
        key={rect.rectangleId}
        x={scaleCalc(bitmap.width, imagewidth, rect.x, rect.scale)}
        y={scaleCalc(bitmap.width, imagewidth, rect.y, rect.scale)}
        width={scaleCalc(bitmap.width, imagewidth, rect.width, rect.scale)}
        height={scaleCalc(bitmap.width, imagewidth, rect.height, rect.scale)}>
        <Rect
          ref={shapeRef}
          width={scaleCalc(bitmap.width, imagewidth, rect.width, rect.scale)}
          height={scaleCalc(bitmap.width, imagewidth, rect.height, rect.scale)}
          opacity={0.5}
          fill={rect.hexColor}
          onClick={e => handleRectClick(e, rect.rectangleId)}
        />
        <Text
          text={rect?.metaDataTag?.label ?? `id-${rect.rectangleId ?? 0}`}
          x={textPosition.x}
          y={textPosition.y}
        />
      </Group>
      {rect.rectangleId === selectedId && (
        <Transformer ref={trRef} rotateEnabled={false} />
      )}
    </>
  );
};

export default ImageMetaDataCanvas;
