import { Filter, SearchInput } from 'react-admin';
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Button
} from '@material-ui/core';
import ToggleSwitch from 'Components/ToggleSwitch';
import { useCallback } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useApiRequest from 'Hooks/useApiRequest';
import Api from 'Api/Api';
import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  collectionSelectControl: {
    margin: '0 0 0 1rem',
    display: 'flex',
    alignSelf: 'center',
    width: '200px'
  },
  clearButton: {
    lineHeight: '1rem',
    color: '#4353ff',
    fontSize: '0.8125rem',
    padding: '4px',
    border: 'none',
    background: 'none',
    boxShadow: 'none',
    float: 'right'
  },
  clearControl: {
    display: 'flex',
    alignSelf: 'center',
    margin: '0 0 0 1rem'
  },
  toggleControl: {
    display: 'flex',
    alignSelf: 'center',
    minWidth: '130px'
  }
}));

const QuestionMetadataFilters = props => {
  const classes = useStyles();
  const { filterValues, setFilters } = props;

  const { data } = useApiRequest(() =>
    Api.getAllTopicPathwayCollections(['levels'])
  );

  const savefilters = useCallback(
    updatedFilterValues => {
      setFilters(updatedFilterValues);
    },
    [setFilters]
  );

  const currentCollection = data?.find(
    c =>
      filterValues['topicPathwayCollectionId']?.Value &&
      parseInt(c.topicPathwayCollectionId, 10) ===
        parseInt(filterValues['topicPathwayCollectionId']?.Value, 10)
  );

  const levelsDefault = [{ key: 'Select Level...', disabled: true, value: '' }];

  const levels = currentCollection?.levels?.length
    ? [
        ...levelsDefault,
        ...currentCollection.levels.map(l => ({
          key: `Level - ${l}`,
          value: `${l}`,
          disabled: false
        }))
      ]
    : [];

  return (
    <div className={classes.root}>
      <Filter {...props}>
        <SearchInput source="q" alwaysOn />
      </Filter>
      <FormControl className={classes.collectionSelectControl}>
        <InputLabel id="subject-label">Subject</InputLabel>
        <Select
          name="Subject"
          labelId="subject-label"
          value={filterValues['subjectId']?.Value ?? ''}
          onClick={e =>
            savefilters({
              ...filterValues,
              subjectId: {
                id: 'subjectId',
                Value: e.target.value ?? '',
                Operator: 'Eq',
                Type: 'int'
              }
            })
          }>
          {[
            { key: 'Number', value: '32' },
            { key: 'Data & Statistics', value: '101' },
            { key: 'Geometry', value: '71' },
            { key: 'Algebra', value: '49' }
          ].map((c, idx) => (
            <MenuItem key={idx} value={c.value}>
              {c.key}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl className={classes.collectionSelectControl}>
        <InputLabel id="collections-label">Collection</InputLabel>
        <Select
          name="collection"
          value={filterValues['topicPathwayCollectionId']?.Value ?? ''}
          onClick={e =>
            savefilters({
              ...filterValues,
              topicPathwayCollectionId: {
                id: 'topicPathwayCollectionId',
                Value: e.target.value ?? '',
                Operator: 'Eq',
                Type: 'int'
              }
            })
          }>
          {data
            ?.map(d => ({ key: d.name, value: d.topicPathwayCollectionId }))
            .map((s, idx) => (
              <MenuItem
                key={idx}
                value={s.value}
                disabled={s.disabled ? true : false}>
                {s.key}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      {levels.length > 0 && (
        <FormControl className={classes.collectionSelectControl}>
          <InputLabel id="levels-label">Levels</InputLabel>
          <Select
            name="level"
            value={filterValues['level']?.Value ?? ''}
            onClick={e => {
              if (
                !filterValues['topicPathwayCollectionId']?.Value ||
                !e.target.value
              )
                return null;

              savefilters({
                ...filterValues,
                level: {
                  id: 'level',
                  Value: `${e.target.value}`,
                  Operator: 'Eq',
                  Type: 'int'
                }
              });
            }}>
            {levels.map((s, idx) => (
              <MenuItem key={idx} value={s.value} disabled={s.disabled}>
                {s.key}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      <FormControl className={classes.collectionSelectControl}>
        <ToggleSwitch
          input={{
            onChange: async value =>
              savefilters({
                ...filterValues,
                isAnyMetaData: {
                  id: 'isAnyMetaData',
                  Value: `${value}` ?? '',
                  Operator: 'Eq',
                  Type: 'string'
                }
              }),
            value: filterValues['isAnyMetaData']?.Value === 'true'
          }}
          label="Any Metadata"
          invert={false}
          meta={{}}
        />
      </FormControl>
      <FormControl className={classes.collectionSelectControl}>
        <ToggleSwitch
          input={{
            onChange: async value =>
              savefilters({
                ...filterValues,
                isFlagged: {
                  id: 'isFlagged',
                  Value: `${value}` ?? '',
                  Operator: 'Eq',
                  Type: 'string'
                }
              }),
            value: filterValues['isFlagged']?.Value === 'true'
          }}
          label="Flagged"
          invert={false}
          meta={{}}
        />
      </FormControl>
      <FormControl className={classes.toggleControl}>
        <ToggleSwitch
          className={classes.toggle}
          input={{
            onChange: value =>
              savefilters({
                ...filterValues,
                checkIn: {
                  id: 'checkIn',
                  Value: value,
                  Operator: 'Eq',
                  Type: 'bool'
                }
              }),
            value: `${filterValues['checkIn']?.Value}`.toLowerCase() === 'true'
          }}
          disabled={
            `${filterValues['checkOut']?.Value}`.toLowerCase() === 'true'
          }
          label="Check-in"
          invert={false}
          meta={{}}
        />
      </FormControl>
      <FormControl className={classes.toggleControl}>
        <ToggleSwitch
          className={classes.toggle}
          input={{
            onChange: value =>
              savefilters({
                ...filterValues,
                checkOut: {
                  id: 'checkOut',
                  Value: value,
                  Operator: 'Eq',
                  Type: 'bool'
                }
              }),
            value: `${filterValues['checkOut']?.Value}`.toLowerCase() === 'true'
          }}
          disabled={
            `${filterValues['checkIn']?.Value}`.toLowerCase() === 'true'
          }
          label="Check-out"
          invert={false}
          meta={{}}
        />
      </FormControl>
      <FormControl className={classes.clearControl}>
        <Button
          variant="contained"
          color="default"
          className={classes.clearButton}
          startIcon={<CancelIcon />}
          onClick={() => savefilters({})}>
          Clear Filters
        </Button>
      </FormControl>
    </div>
  );
};
export default QuestionMetadataFilters;
