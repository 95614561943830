import { TopToolbar } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import FilterButton from 'Components/FilterButton';
import SimplePagination from 'Components/SimplePagination';
import HidePanelButton from 'Components/HidePanelButton';

const useStyles = makeStyles({
  root: {
    padding: '5px 16px 0px 16px',
    borderBottom: '1px solid #ddd'
  },
  hidePanelButton: {
    margin: '0.3rem 0 0 0'
  }
});

const ListActions = () => {
  const classes = useStyles();
  return (
    <TopToolbar className={classes.root}>
      <FilterButton />
      <SimplePagination />
      <div className={classes.hidePanelButton}>
        <HidePanelButton property="lessonListOpen" flipIcons={true} />
      </div>
    </TopToolbar>
  );
};

export default ListActions;
