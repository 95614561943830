import { makeStyles, Modal, Paper } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import {
  RenderAnswerImage,
  RenderAnswerText,
  RenderQuestionImage,
  RenderQuestionText
} from './RenderMetaDatas';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '800px',
    overflowY: 'scroll'
  }
}));

const FunctionalQuestionModal = ({
  open,
  onClose = () => {},
  metaData = [],
  question = {},
  metaDataTags = [],
  imageURL = ''
}) => {
  const classes = useStyles();

  return (
    <Modal className={classes.modal} open={open} onClose={onClose}>
      <Paper className={classes.paper}>
        <div
          style={{
            margin: '0 0 0.5rem 0',
            borderBottom: '1px solid #ccc',
            width: '100%',
            textAlign: 'center'
          }}>
          <h3>Question Preview - {question.questionId}</h3>
        </div>
        <PreviewWrapper>
          <div
            style={{
              padding: '0.75rem 0.75rem 0.75rem 0',
              alignItems: 'flex-start'
            }}>
            {!!imageURL && <img src={imageURL} alt="Question" />}
          </div>
          <div style={{ padding: '0.75rem' }}>
            {metaData?.filter(m => !!m.metaDataTagId && !!m.text).length ===
              0 && (
              <>
                <h2>No metadata has been tagged and annotated!</h2>
                <h4>
                  Draw bounding boxes and applies labels to complete tagging.
                </h4>
              </>
            )}
            {metaData
              .sort((a, b) => a.sequence - b.sequence)
              .map(m => {
                const tag = metaDataTags?.find(
                  mt => mt.metaDataTagId === m.metaDataTagId
                );
                return (
                  <React.Fragment key={m.rectangleId}>
                    <RenderQuestionImage metaData={m} metaDataTag={tag} />
                    <RenderQuestionText metaData={m} metaDataTag={tag} />
                    <RenderAnswerText
                      metaData={m}
                      metaDataTag={tag}
                      question={question}
                    />
                    <RenderAnswerImage
                      metaData={m}
                      metaDataTag={tag}
                      question={question}
                    />
                  </React.Fragment>
                );
              })}
          </div>
        </PreviewWrapper>
      </Paper>
    </Modal>
  );
};

const PreviewWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  img {
    width: 480px;
    height: 360px;
    border: 1px solid #ccc;
  }
`;

export default FunctionalQuestionModal;
