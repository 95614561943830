import { FormControlLabel, Switch } from '@material-ui/core';

const ToggleSwitch = ({
  input: { onChange, value },
  label,
  invert = true,
  meta: { error = null },
  ...rest
}) => {
  return (
    <>
      <FormControlLabel
        control={
          <Switch
            {...rest}
            checked={invert ? !value : value}
            onChange={() => onChange(!value)}
          />
        }
        label={label}></FormControlLabel>
      <p
        className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error"
        id="standard-basic-helper-text">
        {error}
      </p>
    </>
  );
};

export default ToggleSwitch;
