import { makeStyles } from '@material-ui/core/styles';
import {
  Modal,
  Backdrop,
  TextField,
  Button,
  Select,
  FormControl,
  InputLabel,
  MenuItem
} from '@material-ui/core';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import React from 'react';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '400px'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  container: {
    minWidth: '400px'
  },
  input: {
    width: '100%',
    margin: '0.5rem 0 1rem'
  },
  select: {
    width: '100%',
    margin: '1rem 0 1rem'
  },
  formControl: {
    width: '100%'
  },
  button: {
    float: 'right',
    margin: '0.5rem 0 0 1rem'
  }
}));

const EditTutorRotaModal = ({
  isEditModalOpen,
  selectedEvent,
  setIsEditModalOpen,
  tutors,
  setSelectedEvent,
  updateTutorRota
}) => {
  const classes = useStyles();
  return (
    <Modal
      open={isEditModalOpen}
      onClose={() => setIsEditModalOpen(false)}
      className={classes.modal}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
      closeAfterTransition>
      <div className={classes.paper}>
        <h3>Edit</h3>
        <FormControl className={classes.formControl}>
          <InputLabel>Tutor</InputLabel>
          <Select
            value={selectedEvent?.resource?.tutorId}
            onChange={e =>
              setSelectedEvent({
                ...selectedEvent,
                resource: {
                  ...selectedEvent?.resource,
                  tutorId: e.target.value
                }
              })
            }>
            {tutors?.map((t, i) => (
              <MenuItem
                key={i}
                value={t.tutorId}>{`${t.firstName} ${t.lastName}`}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <form className={classes.container} noValidate>
          <TextField
            id="datetime-local"
            label="Date Active From"
            type="datetime-local"
            className={classes.input}
            value={moment(selectedEvent?.start).format('YYYY-MM-DDTHH:mm')}
            onChange={e =>
              setSelectedEvent({
                ...selectedEvent,
                start: new Date(e.target.value)
              })
            }
            InputLabelProps={{
              shrink: true
            }}
          />
          <TextField
            id="datetime-local"
            label="Date Active To"
            type="datetime-local"
            className={classes.input}
            value={moment(selectedEvent?.end).format('YYYY-MM-DDTHH:mm')}
            onChange={e =>
              setSelectedEvent({
                ...selectedEvent,
                end: new Date(e.target.value)
              })
            }
            InputLabelProps={{
              shrink: true
            }}
          />
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.button}
            startIcon={<SaveIcon />}
            disabled={!selectedEvent?.resource?.tutorId}
            onClick={() =>
              updateTutorRota(
                selectedEvent.resource.tutorId,
                selectedEvent.resource.tutorRotaId,
                selectedEvent.start,
                selectedEvent.end
              )
            }>
            Save
          </Button>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            startIcon={<DeleteIcon />}
            disabled={!selectedEvent?.resource?.tutorId}
            onClick={() =>
              updateTutorRota(
                selectedEvent.resource.tutorId,
                selectedEvent.resource.tutorRotaId,
                null,
                null
              )
            }>
            Delete
          </Button>
          <Button
            variant="contained"
            className={classes.button}
            startIcon={<CancelIcon />}
            onClick={() => setIsEditModalOpen(false)}>
            Cancel
          </Button>
        </form>
      </div>
    </Modal>
  );
};

export default EditTutorRotaModal;
