import { makeStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, Container, Card } from '@material-ui/core';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { React } from 'react';
import { useLocation } from 'react-router-dom';
import EditTranscriptPanel from './EditTranscriptPanel';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  create: {
    margin: '1rem 0 0 0',
    overflowY: 'scroll',
    maxHeight: `${window.innerHeight ?? 900}px`
  },
  input: {
    margin: '1rem 0 0 0'
  },
  createButton: {
    margin: '1rem 0 0 0'
  },
  cancelButton: {
    margin: '1rem 0 0 10px'
  },
  select: {
    margin: '1rem 0 0 0',
    width: '100%'
  },
  numberInput: {
    margin: '1rem 0 0 0'
  }
}));

const EditConstructTranscriptModal = ({ isOpen, setIsOpen }) => {
  const location = useLocation();
  const id = location.pathname.split('/').reverse()[0];
  const classes = useStyles();

  if (isNaN(id)) return;

  return (
    <Modal
      open={isOpen}
      onClose={() => setIsOpen()}
      className={classes.modal}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
      closeAfterTransition>
      <Container maxWidth="md">
        <Card className={classes.create}>
          <EditTranscriptPanel constructId={id} />
        </Card>
      </Container>
    </Modal>
  );
};

export default EditConstructTranscriptModal;
