const settings = {
  ENVIRONMENT: 'prod',
  TEACHER_APP_URL:
    'prod' === 'qa'
      ? 'https://teacher-qa.eedi.com'
      : 'prod' === 'prod'
      ? 'https://teacher.eedi.com'
      : process.env.REACT_APP_TEACHER_APP_URL
      ? process.env.REACT_APP_TEACHER_APP_URL
      : 'https://teacher-qa.eedi.com',
  API_URL:
    'prod' === 'qa'
      ? 'https://api-qa.eedi.com'
      : 'prod' === 'prod'
      ? 'https://api.eedi.com'
      : process.env.REACT_APP_API_URL
      ? process.env.REACT_APP_API_URL
      : 'https://api-qa.eedi.com',
  FLOW_API_URL:
    'prod' === 'qa'
      ? 'https://flow-api-qa.eedi.com'
      : 'prod' === 'prod'
      ? 'https://flow-api.eedi.com'
      : process.env.REACT_APP_FLOW_API_URL
      ? process.env.REACT_APP_FLOW_API_URL
      : 'https://flow-api-qa.eedi.com',
  FUNCTIONS_API_URL:
    'prod' === 'qa'
      ? 'https://eedi-functions-api-qa.azurewebsites.net'
      : 'prod' === 'prod'
      ? 'https://eedi-functions-api-prod.azurewebsites.net'
      : process.env.REACT_APP_FUNCTIONS_API_URL
      ? process.env.REACT_APP_FUNCTIONS_API_URL
      : 'https://eedi-functions-api-qa.azurewebsites.net',
  API_NINJA_KEY: 'K7Xz/C4vcSPgz+xK8imYgw==E4sApC3eRTa8KTfg',
  GOOGLE_AUTH_CLIENT_ID:
    '250963904963-e2uegfjodagts71r9lmv58j3ablqv6c6.apps.googleusercontent.com',
  MATHPIX_API_KEY: process.env.REACT_APP_MATHPIX_API_KEY,
  MATHPIX_APP_ID: process.env.REACT_APP_MATHPIX_APP_ID,
  TRANSCRIPT_API_URL:
    'prod' === 'qa'
      ? 'https://eeditranscriptapi-qa.azurewebsites.net'
      : 'prod' === 'prod'
      ? 'https://eeditranscriptapi-prod.azurewebsites.net'
      : process.env.REACT_APP_TRANSCRIPT_API_URL
      ? process.env.REACT_APP_TRANSCRIPT_API_URL
      : 'https://eeditranscriptapi-qa.azurewebsites.net'
};

export default settings;
