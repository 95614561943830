import {
  Select,
  InputLabel,
  MenuItem,
  TextField,
  FormGroup,
  Button,
  Box,
  Chip
} from '@material-ui/core';
import styled from 'styled-components';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { useState } from 'react';
import CancelIcon from '@material-ui/icons/Cancel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Field, Form } from 'react-final-form';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import SaveConfirmButton from '../SaveConfirmButton';

const EditRadioButtonForm = ({
  onChange = () => {},
  radioButtons = [],
  ...props
}) => {
  const [radioButtonIdx, setRadioButtonIdx] = useState(null);
  return (
    <Root>
      {!!radioButtons?.length && (
        <StyledFormGroup>
          <InputLabel id="radioButtons-label">Radio Buttons</InputLabel>
          <Select
            name="radioButtons"
            labelId="radioButtons-label"
            onChange={e => setRadioButtonIdx(e.target.value)}
            value={radioButtonIdx}>
            {radioButtons.map((c, idx) => (
              <MenuItem key={idx} value={idx}>
                #{idx} - {c.labelOptions.join(', ')}
              </MenuItem>
            ))}
          </Select>
        </StyledFormGroup>
      )}
      {radioButtonIdx !== null && (
        <StyledForm
          onSubmit={async e => {
            e.preventDefault();
          }}
          initialValues={
            radioButtons[radioButtonIdx] || {
              sequence: radioButtons?.length ? radioButtons.length + 1 : 1,
              value: null,
              labelOptions: []
            }
          }
          validate={values => {
            const errors = {};
            if (!values.sequence) {
              errors.sequence = 'Please enter a sequence';
            }
            if (!values.value) {
              errors.value = 'Please enter a value';
            }
            if (!values.labelOptions?.length) {
              errors.labelOptions = 'Please enter a label';
            }
            return errors;
          }}>
          {({ handleSubmit, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit} {...props}>
              <FormGroup>
                <Field name="sequence">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label="Sequence"
                      type="number"
                      error={meta.error && meta.touched}
                      helperText={
                        meta.error && meta.touched ? meta.error : null
                      }
                    />
                  )}
                </Field>
              </FormGroup>
              <FormGroup>
                <Field name="labelOptions">
                  {({ input, meta }) => (
                    <>
                      <Autocomplete
                        {...input}
                        disableClearable
                        error={meta.error && meta.touched}
                        onChange={(event, newValue) => {
                          const opts = values?.labelOptions?.length
                            ? [...values.labelOptions]
                            : [];
                          input.onChange([
                            ...opts,
                            newValue.replace('Add ', '').replace(/"/g, '')
                          ]);
                        }}
                        filterOptions={(options, params) => {
                          if (params.inputValue !== '') {
                            const exactMatch = options.includes(
                              params.inputValue
                            );
                            if (!exactMatch) {
                              options.push(`Add "${params.inputValue}"`);
                            }
                          }

                          return options;
                        }}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        options={values?.labelOptions || []}
                        getOptionLabel={option => {
                          // Value selected with enter, right from the input
                          if (typeof option === 'string') {
                            return option;
                          }
                          // Add "xxx" option created dynamically
                          if (option.inputValue) {
                            return option.inputValue;
                          }
                          // Regular option
                          return '';
                        }}
                        renderOption={option => {
                          return option;
                        }}
                        freeSolo
                        renderInput={params => (
                          <TextField {...params} label="Add button label" />
                        )}
                      />
                      <Box style={{ margin: '0.25rem 0 0.25rem 0' }}>
                        {values?.labelOptions?.map((opt, idx) => (
                          <Chip
                            key={idx}
                            label={opt}
                            onDelete={() => {
                              const opts = [...values.labelOptions];
                              opts.splice(idx, 1);
                              input.onChange(opts);
                            }}
                            deleteIcon={<CancelIcon />}
                          />
                        ))}
                      </Box>
                    </>
                  )}
                </Field>
              </FormGroup>
              <FormGroup>
                <Field name="value">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label="Value"
                      type="text"
                      error={meta.error && meta.touched}
                      helperText={
                        meta.error && meta.touched ? meta.error : null
                      }
                    />
                  )}
                </Field>
              </FormGroup>
              <ButtonWrapper>
                <Button
                  style={{ margin: '0.5rem 0 0 0' }}
                  variant="contained"
                  color="primary"
                  endIcon={<AddCircleOutlineIcon />}
                  onClick={() => {
                    try {
                      if (!radioButtons[radioButtonIdx]) {
                        onChange([...radioButtons, values]);
                      } else {
                        const newRadioButtons = [...radioButtons];
                        newRadioButtons[radioButtonIdx] = values;
                        onChange(newRadioButtons);
                      }
                      setRadioButtonIdx(null);
                    } catch (e) {}
                  }}
                  disabled={
                    submitting ||
                    pristine ||
                    !values.value ||
                    !values.labelOptions?.length
                  }>
                  {!radioButtons[radioButtonIdx] ? 'Add' : 'Update'}
                </Button>
                {!!radioButtons[radioButtonIdx] && (
                  <SaveConfirmButton
                    key={radioButtonIdx}
                    isDoubleConfirm={true}
                    submitting={submitting}
                    confirmCopy="Confirm Delete?"
                    loadingCopy="Deleting..."
                    buttonCopy="Delete"
                    icon={<DeleteForeverIcon />}
                    pristine={false}
                    style={{ margin: '0.5rem 0 0 0' }}
                    onClick={() => {
                      // remove the radio button at radioButtonIdx
                      const opts = [...radioButtons];
                      opts.splice(radioButtonIdx, 1);
                      onChange([...opts]);
                      setRadioButtonIdx(null);
                    }}
                  />
                )}
                <Button
                  style={{ margin: '0.5rem 0 0 0' }}
                  variant="contained"
                  color="secondary"
                  endIcon={<CancelIcon />}
                  onClick={() => setRadioButtonIdx(null)}>
                  Cancel
                </Button>
              </ButtonWrapper>
            </form>
          )}
        </StyledForm>
      )}
      {radioButtonIdx === null ? (
        <Button
          variant="contained"
          color="secondary"
          onClick={() =>
            setRadioButtonIdx(!radioButtonIdx ? radioButtons?.length + 1 : null)
          }
          endIcon={<AddCircleOutlineIcon />}>
          Add Radio Buttons
        </Button>
      ) : null}
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  div {
    word-break: break-all;
  }
  margin: 0.25rem 0 0.25rem 0;
`;

const StyledForm = styled(Form)`
  button {
    margin: 0.5rem;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledFormGroup = styled(FormGroup)`
  margin: 0.25rem 0 0.25rem 0;
`;

export default EditRadioButtonForm;
