import Client from './Client';
import Settings from '../settings';
import queryString from 'query-string';

const getUri = (endpoint = null, uri = null) => {
  const formattedUri = `/${uri}`.replace(/\/+/g, '/');
  return `${endpoint}${formattedUri}`;
};

const getFlowApiUri = uri => getUri(Settings.FLOW_API_URL, `${uri}`);

const getApiUri = uri => getUri(Settings.API_URL, uri);

const getFunctionsApiUri = uri =>
  getUri(Settings.FUNCTIONS_API_URL, `api/${uri}`);

class Api {
  /* Users */
  static async signIn(username, password, secret) {
    const params = {
      username,
      password
      //secret
    };
    return Client.request('POST', getApiUri('v3/tokens/admin-refresh'), params);
  }

  static async signInWithGoogle(googleToken) {
    const params = {
      googleToken
    };
    return Client.request('GET', getApiUri(`v3/GoogleTokenSignIn`), params);
  }

  static async getUserContext() {
    return Client.request('GET', getApiUri('v3/users/me'), {
      calculateFeatureFlagsForUser: 'true'
    });
  }

  static async getQuizSession(quizSessionId) {
    return Client.request(
      'GET',
      getApiUri(`v3/quiz-sessions/${quizSessionId}`)
    );
  }

  static async approveTeacher({ email, schoolId }) {
    return Client.request('POST', getApiUri(`/v4/admin/approve-teacher`), {
      email,
      schoolId
    });
  }

  /* FLOW TEMPLATE API */
  static async getFlowTemplateGroup(
    templateSlug,
    isExactPosition = false,
    position = null
  ) {
    return Client.request(
      'GET',
      getFlowApiUri(
        `/flow-templates/${templateSlug}/flow-items?` +
          `isExactPosition=${isExactPosition}` +
          `position=${position}`
      ),
      {}
    );
  }

  static async getAllFlowTemplateSlugs() {
    return Client.request(
      'GET',
      getFlowApiUri(`flow-templates/flow-template-slugs`)
    );
  }

  static async updateFlowTemplate(
    templateSlug,
    flowTemplate,
    position,
    isOnlyXY = false
  ) {
    return Client.request(
      'PUT',
      getFlowApiUri(
        `flow-templates/${templateSlug}/position/${position}?isOnlyXY=${isOnlyXY}`
      ),
      flowTemplate
    );
  }

  static async updateFlowItem(
    templateSlug,
    flowItem,
    sequence,
    isOnlyXY = false
  ) {
    return Client.request(
      'PUT',
      getFlowApiUri(
        `flow-items/${templateSlug}/sequence/${sequence}?isOnlyXY=${isOnlyXY}`
      ),
      flowItem
    );
  }

  static async insertFlowTemplate(templateSlug, flowTemplate, position) {
    return Client.request(
      'POST',
      getFlowApiUri(`flow-templates/${templateSlug}/position/${position}`),
      flowTemplate
    );
  }

  static async insertFlowItem(templateSlug, flowItem, sequence) {
    return Client.request(
      'POST',
      getFlowApiUri(`flow-items/${templateSlug}/sequence/${sequence}`),
      flowItem
    );
  }

  static async deleteFlowTemplate(templateSlug, position) {
    return Client.request(
      'DELETE',
      getFlowApiUri(`flow-templates/${templateSlug}/position/${position}`)
    );
  }

  static async deleteFlowItem(templateSlug, sequence) {
    return Client.request(
      'DELETE',
      getFlowApiUri(`flow-items/${templateSlug}/sequence/${sequence}`)
    );
  }

  static async validateExpression(expression) {
    // parse expression to url encoding
    expression = encodeURIComponent(expression);
    return Client.request(
      'GET',
      getFlowApiUri(`v1/rules-engine/check-rule/${expression}`)
    );
  }

  static async createNewTemplateSlug(templateSlug, flowTemplate) {
    return Client.request(
      'POST',
      getFlowApiUri(`flow-templates/${templateSlug}`),
      flowTemplate
    );
  }

  static async cloneFlowTemplate(templateSlug, cloneFlowTemplateSlug) {
    return Client.request(
      'POST',
      getFlowApiUri(
        `flow-templates/${templateSlug}/clone/${cloneFlowTemplateSlug}`
      )
    );
  }

  static async updateFlowTemplateLessonType(templateSlug, lessonType) {
    return Client.request(
      'PUT',
      getFlowApiUri(`flow-templates/${templateSlug}/lesson-type/${lessonType}`)
    );
  }

  /* FLOW API */
  /**
   * The LessonHub is the primary SignalR hub for the flows API.
   * @param {*} hubName
   * @returns
   */
  static getLessonHubUrl(hubName) {
    return getFlowApiUri(`/${hubName}`);
  }

  /* Metadata */
  static async getSupportedCountries() {
    return Client.request('GET', getApiUri('v3/countries'));
  }

  static async getCountryYearGroups(countryCode) {
    return Client.request(
      'GET',
      getApiUri(`v3/countries/${countryCode}/country-year-groups`)
    );
  }

  /**
   * Fetches the SignalR connection info.
   * @param {*} userId
   * @param {*} hubName
   * @param {*} userRole
   * @returns
   */
  static async getSignalRConnectionInfo(userId, hubName, userRole) {
    return Client.request(
      'GET',
      getFlowApiUri(`/${hubName}/negotiate`),
      {},
      {
        headers: {
          'x-ms-signalr-userid': userId,
          userRole: userRole || 'Student'
        }
      }
    );
  }

  static async claimFlowGeneratorSessionLesson(
    flowGeneratorSessionId,
    isGPT = false
  ) {
    return Client.request(
      'POST',
      getFlowApiUri(
        `/v1/flow-generator-sessions/${flowGeneratorSessionId}/interventions/claim?isGPT=${isGPT}`
      )
    );
  }

  static async updateFlowGeneratorSessionLessonIntervention(
    flowGeneratorSessionId,
    request = null
  ) {
    return Client.request(
      'PUT',
      getFlowApiUri(
        `/v1/flow-generator-sessions/${flowGeneratorSessionId}/interventions`
      ),
      request
    );
  }

  static async unClaimFlowGeneratorSessionLessonIntervention(
    flowGeneratorSessionId,
    request = null
  ) {
    return Client.request(
      'POST',
      getFlowApiUri(
        `/v1/flow-generator-sessions/${flowGeneratorSessionId}/interventions/unclaim`
      ),
      request
    );
  }

  static async getFlowGeneratorSessionDashboardStats() {
    return Client.request('GET', getApiUri(`/v4/admin/lesson-dashboard-stats`));
  }

  /**
   * Fetches a flowGeneratorSession by its id.
   * @param {*} flowGeneratorSessionId
   * @returns
   */
  static async getFlowGeneratorSession(flowGeneratorSessionId) {
    return Client.request(
      'GET',
      getFlowApiUri(`/v1/flow-generator-sessions/${flowGeneratorSessionId}`),
      {}
    );
  }

  /**
   * Updates a flowGeneratorSession by passing in a flowItemGroup and an input.
   * @param {*} flowGeneratorSessionId
   * @param {*} flowItemGroup - Each flowItem is dispatched with a flowItemGroup.
   * If a flowItem is linked to an expected input, we need to pass this back up
   * so we can record the user input and move the user forward.
   * @param {*} input - If a flowItem is expecting an input this value should not be
   * null or empty string.
   * @returns
   */
  static async updateFlowGeneratorSession(
    flowGeneratorSessionId,
    flowItemGroup,
    input
  ) {
    return Client.request(
      'POST',
      getFlowApiUri(`/v1/flow-generator-sessions/${flowGeneratorSessionId}`),
      {
        flowItemGroup,
        input
      }
    );
  }

  /**
   * Send a direct message to a flow session without having to attempt to process the input.
   * @param {*} flowGeneratorSessionId
   * @param {*} input
   * @returns
   */
  static async sendMessage(flowGeneratorSessionId, input) {
    return Client.request(
      'POST',
      getFlowApiUri(
        `/v1/flow-generator-sessions/${flowGeneratorSessionId}/message`
      ),
      {
        input
      }
    );
  }

  static async sendTutorMessage(flowGeneratorSessionId, input) {
    return Client.request(
      'POST',
      getFlowApiUri(
        `/v1/flow-generator-sessions/${flowGeneratorSessionId}/tutor-message`
      ),
      {
        input
      }
    );
  }

  static async toggleTopicPathwayQuizLessonHelp(flowGeneratorSessionId) {
    return Client.request(
      'POST',
      getFlowApiUri(
        `/v1/flow-generator-sessions/${flowGeneratorSessionId}/help-requested`
      ),
      {}
    );
  }

  static async dispatchIsTyping(flowGeneratorSessionId, userId) {
    return Client.request(
      'POST',
      getFlowApiUri(
        `/v1/users/${userId}/flow-generator-sessions/${flowGeneratorSessionId}/typing`
      ),
      {}
    );
  }

  /**
   * This creates a flowGeneratorSession for a particular templateSlug.
   * These templates are agnostic of the rest of the domain e.g. Quizzes, and live within themselves
   * for purposes such as onboarding or surveys.
   * @param {*} userId
   * @param {*} templateSlug
   * @param {*} isFetchExistingSession
   * @returns
   */
  static async createFlowGeneratorSessionForTemplate(
    userId,
    templateSlug,
    isFetchExistingSession = true
  ) {
    return Client.request(
      'POST',
      getFlowApiUri(
        `/v1/users/${userId}/flow-templates/${templateSlug}/` +
          `flow-generator-sessions?=isFetchExistingSession=${isFetchExistingSession}`
      ),
      {}
    );
  }

  /**
   * Creates a flowGeneratorSession and the related entities for a topicPathwayQuiz.
   * @param {*} userId
   * @param {*} topicPathwayQuizId
   * @param {*} templateSlug
   * @param {*} isFetchExistingSession
   * @returns
   */
  static async createFlowGeneratorSessionForTopicPathwayQuiz(
    userId,
    topicPathwayQuizId,
    templateSlug = null,
    isFetchExistingSession = true
  ) {
    return Client.request(
      'POST',
      getFlowApiUri(
        `/v1/users/${userId}/topic-pathway-quizs/${topicPathwayQuizId}/flow-generator-sessions`
      ),
      {
        templateSlug,
        isFetchExistingSession
      }
    );
  }

  static async uploadFilesToFlowGeneratorSession(
    flowGeneratorSessionId,
    formData
  ) {
    return Client.request(
      'PUT',
      getFlowApiUri(
        `/v1/flow-generator-sessions/${flowGeneratorSessionId}/file-upload`
      ),
      formData,
      {
        headers: {
          isFormData: true
        }
      }
    );
  }

  static async uploadFlowTemplate(templateSlug, formData) {
    return Client.request(
      'PUT',
      getFlowApiUri(`/flow-templates/${templateSlug}/upload`),
      formData,
      {
        headers: {
          isFormData: true
        }
      }
    );
  }

  static async uploadFlowItemTemplate(templateSlug, formData) {
    return Client.request(
      'PUT',
      getFlowApiUri(`/flow-templates/${templateSlug}/flow-items/upload`),
      formData,
      {
        headers: {
          isFormData: true
        }
      }
    );
  }

  static async getFlowItemsByFlowItemTemplateSlug(flowItemTemplateSlug) {
    return Client.request(
      'GET',
      getFlowApiUri(`flow-templates/flow-items/${flowItemTemplateSlug}`)
    );
  }

  /* GPT */

  static async regenerateTutorResponse(flowGeneratorSessionId) {
    return Client.request(
      'POST',
      getFlowApiUri(
        `/v1/chat/flow-generator-sessions/${flowGeneratorSessionId}/regenerate-response`
      ),
      {}
    );
  }

  static async fetchTutorResponse(flowGeneratorSessionId) {
    return Client.request(
      'GET',
      getFlowApiUri(
        `/v1/chat/flow-generator-sessions/${flowGeneratorSessionId}/response`
      ),
      {}
    );
  }

  static async cancelTutorResponse(flowGeneratorSessionId) {
    return Client.request(
      'POST',
      getFlowApiUri(
        `/v1/chat/flow-generator-sessions/${flowGeneratorSessionId}/cancel-response`
      ),
      {}
    );
  }

  static async fetchSystemPrompt(flowGeneratorSessionId) {
    return Client.request(
      'GET',
      getFlowApiUri(
        `/v1/chat/flow-generator-sessions/${flowGeneratorSessionId}/system-prompt`
      ),
      {}
    );
  }

  static async updateSystemPrompt(flowGeneratorSessionId, prompt, input) {
    return Client.request(
      'POST',
      getFlowApiUri(
        `/v1/chat/flow-generator-sessions/${flowGeneratorSessionId}/system-prompt`
      ),
      { prompt, input }
    );
  }

  static async bulkUpdateUserEvents({
    action,
    userIds,
    schoolId,
    source = 'EediFamily',
    userRole = 'Student'
  }) {
    return Client.request('POST', getApiUri(`/v3/admin/events/bulk-queue`), {
      action,
      userIds: userIds || [],
      source,
      schoolId,
      userRole
    });
  }

  /* GPT Conversations */

  static async startTutorsAssistant({ flowGeneratorSessionId, input }) {
    const query = input ? `?input=${input}` : '';
    return Client.request(
      'POST',
      getFlowApiUri(
        `/v1/conversations/flow-generator-sessions/${flowGeneratorSessionId}/assistant${query}`
      ),
      {}
    );
  }

  static async startConversation({ prompt, input }) {
    return Client.request('POST', getFlowApiUri(`/v1/conversations`), {
      prompt,
      input
    });
  }

  static async messageConversation({ conversationId, input }) {
    return Client.request(
      'PUT',
      getFlowApiUri(`/v1/conversations/${conversationId}`),
      {
        input
      }
    );
  }

  static async getConversation(conversationId) {
    return Client.request(
      'GET',
      getFlowApiUri(`/v1/conversations/${conversationId}`)
    );
  }

  static async getConversationPrompt(conversationId) {
    return Client.request(
      'GET',
      getFlowApiUri(`/v1/conversations/${conversationId}/prompt`)
    );
  }

  static async getPrompts(take = 25) {
    return Client.request('GET', getFlowApiUri(`/v1/prompts?take=${take}`));
  }

  static async upsertPrompt(prompt) {
    return Client.request('POST', getFlowApiUri(`/v1/prompts`), prompt);
  }

  /* Tutors */
  static async getAllTutors() {
    return Client.request('GET', getApiUri(`v3/tutors`));
  }

  static async getTutorRotas(
    dateActiveFrom = null,
    dateActiveTo = null,
    tutorIds = []
  ) {
    return Client.request('GET', getApiUri(`v3/tutors/tutor-rotas`), {
      dateActiveFrom,
      dateActiveTo,
      tutorIds
    });
  }

  static async upsertTutorRota(
    tutorId,
    tutorRotaId = null,
    dateActiveFrom = null,
    dateActiveTo = null
  ) {
    return Client.request('POST', getApiUri(`v3/tutors/tutor-rotas`), {
      tutorId,
      tutorRotaId,
      dateActiveFrom,
      dateActiveTo
    });
  }

  static async bulkUpsertTutorRotas(tutorRotas = []) {
    return Client.request(
      'POST',
      getApiUri(`v3/tutors/tutor-rotas/bulk-upsert`),
      {
        tutorRotas
      }
    );
  }

  /* Utility */
  static async request(method, url, params = null) {
    return Client.request(method, getApiUri(url), params);
  }

  static getApiUri(endpoint = null, uri = null) {
    return getApiUri(endpoint, uri);
  }

  /* Hybrid Schools */
  static async getHybridSchool(schoolId) {
    return Client.request('GET', getApiUri(`v3/hybrid-schools/${schoolId}`));
  }

  static async getAllHybridSchools() {
    return Client.request('GET', getApiUri(`v3/hybrid-schools`));
  }

  static async upsertHybridSchool(schoolId, body) {
    return Client.request(
      'POST',
      getApiUri(`v3/hybrid-schools/${schoolId}`),
      body
    );
  }

  static async searchSchools(searchText) {
    return Client.request('GET', getApiUri(`v3/schools`), {
      'filter.searchText': searchText
    });
  }

  static async getSchool(schoolId) {
    return Client.request('GET', getApiUri(`v3/schools/${schoolId}`));
  }

  static async insertSchool(body) {
    return Client.request('POST', getApiUri(`v3/schools`), body);
  }

  static async updateSchool(schoolId, body) {
    return Client.request('PUT', getApiUri(`v3/schools/${schoolId}`), body);
  }

  static async deleteTeacher(teacherId) {
    return Client.request('DELETE', getApiUri(`v3/teachers/${teacherId}`));
  }

  static async assignUserToSchool(userId, schoolId) {
    return Client.request(
      'POST',
      getApiUri(`v3/schools/${schoolId}/teachers`),
      {
        userId,
        isMakeDefault: false
      }
    );
  }

  /* Groups */

  static async getAllSchoolGroups(schoolId) {
    return Client.request('GET', getApiUri(`v3/schools/${schoolId}/groups`));
  }

  /* Topic Pathways */
  static async updateTopicPathwayCollection(
    topicPathwayCollectionId,
    update = {}
  ) {
    return Client.request(
      'PUT',
      getApiUri(`v3/topic-pathway-collections/${topicPathwayCollectionId}`),
      update
    );
  }

  static async getEediHomeCollections() {
    return Client.request(
      'GET',
      getApiUri(`/v3/topic-pathway-collections/eedi-home`)
    );
  }

  static async getAllTopicPathwayCollections(includes = []) {
    const queryParams = queryString.stringify({ includes });
    return Client.request(
      'GET',
      getApiUri(`/v4/topic-pathway-collections?${queryParams}`)
    );
  }

  static async insertTopicPathwayCollection(userId, insert) {
    return Client.request(
      'POST',
      getApiUri(`/v4/users/${userId}/topic-pathway-collections`),
      insert
    );
  }

  static async getTopicPathwayQuiz(topicPathwayQuizId) {
    return Client.request(
      'GET',
      getApiUri(`/v4/admin/topic-pathway-quizs/${topicPathwayQuizId}`)
    );
  }

  static async insertTopicPathwayQuiz(insert) {
    return Client.request(
      'POST',
      getApiUri(`/v4/admin/topic-pathway-quizs`),
      insert
    );
  }

  static async updateTopicPathwayQuiz(topicPathwayQuizId, update) {
    return Client.request(
      'PUT',
      getApiUri(`/v4/admin/topic-pathway-quizs/${topicPathwayQuizId}`),
      update
    );
  }

  /* User Subscriptions */
  static async bulkUpdateUserSubscriptions(update) {
    return Client.request(
      'PUT',
      getApiUri(`/v4/admin/user-subscriptions/bulk-update`),
      update
    );
  }

  static async bulkEnrollClassroomStudents(schoolId, update) {
    return Client.request(
      'PUT',
      getApiUri(`/v4/admin/schools/${schoolId}/students/bulk-enroll`),
      update
    );
  }

  static async mergeClassroomStudents(fromUserName, toUserName, toSchoolId) {
    return Client.request('PUT', getApiUri(`/v3/students/merge-students`), {
      fromUserName,
      toUserName,
      toSchoolId
    });
  }

  /* Cache */
  static async clearTopicPathwayQuizTemplateCache() {
    return Client.request(
      'DELETE',
      getFlowApiUri(`/flow-templates/topic-pathway-quizs/clear-cache`)
    );
  }

  static async clearChallengeTemplateCacheForQuiz(
    quizId,
    templateSlug = 'Stretch_v1'
  ) {
    return Client.request(
      'DELETE',
      getFlowApiUri(
        `/v1/flow-templates/${templateSlug}/challenge/quizs/${quizId}/clear-cache`
      )
    );
  }

  /* Flow Questions */
  static async uploadFlowQuestions(formData) {
    return Client.request(
      'POST',
      getApiUri(`/v4/admin/flow-questions/import`),
      formData,
      {
        headers: {
          isFormData: true
        }
      }
    );
  }

  /* Worksheet Questions */
  static async uploadWorksheetQuestions(formData) {
    return Client.request(
      'POST',
      getApiUri(`/v4/admin/worksheet-questions/import`),
      formData,
      {
        headers: {
          isFormData: true
        }
      }
    );
  }

  /* Cache */
  static async reCacheFlowTemplates({
    quizIds = [],
    topicPathwayCollectionId = null,
    templateSlugWhitelist = [],
    flowQuestionIds = [],
    constructIds = []
  }) {
    return Client.request(
      'POST',
      getFlowApiUri(`/flow-templates/re-cache`),
      {
        quizIds,
        topicPathwayCollectionId,
        templateSlugWhitelist,
        flowQuestionIds,
        constructIds
      },
      {},
      -1
    );
  }

  static async clearFlowTemplateCache({
    quizIds = [],
    templateSlugs = [],
    flowQuestionIds = []
  }) {
    return Client.request(
      'POST',
      getFlowApiUri(`/flow-templates/clear-cache`),
      {
        quizIds,
        templateSlugs,
        flowQuestionIds
      }
    );
  }

  static async getEmbeddingsSearch(search) {
    return Client.request(
      'GET',
      getApiUri('v4/admin/embedding-outputs/search'),
      {
        'Filter.SearchText': search,
        'Filter.EmbeddingType': 'MisconceptionTag'
      }
    );
  }

  static async getMisconceptionTags() {
    return Client.request('GET', getApiUri('v4/admin/misconception-tags'));
  }

  static async getMisconceptionTagStats(fromDate = null) {
    let queryParams = {};
    if (fromDate) {
      queryParams = { fromDate };
    }
    return Client.request(
      'GET',
      getApiUri('v4/admin/misconception-tags/statistics'),
      queryParams
    );
  }

  static async sendSlackMessage(message, channelId = 'C0260NMLV8X') {
    return Client.request(
      'POST',
      getFlowApiUri(`/slack/channels/${channelId}?message=${message}`)
    );
  }

  static async dispatchSignalRMessage({ groups = [], methods = [], payload }) {
    return Client.request(
      'POST',
      getFlowApiUri(`v1/signalr-hubs/lesson-hub/dispatch`),
      {
        groups,
        methods,
        payload
      }
    );
  }

  /* Misconception tag validation */
  static async validateMisconceptionTag(questionId, answerValue, input) {
    return Client.request(
      'GET',
      getFlowApiUri(
        `v1/misconception-tags/questions/${questionId}/answerValue/${answerValue}/validate`
      ),
      {
        input
      }
    );
  }

  static async getRecommendedMisconceptionTag(questionId, answerValue) {
    return Client.request(
      'GET',
      getFlowApiUri(
        `v1/misconception-tags/questions/${questionId}/answerValue/${answerValue}/recommended-tag`
      ),
      {}
    );
  }

  static async sendGPTFeedback({
    comments,
    isPositiveFeedback,
    feedbackTableName,
    feedbackPartitionKey,
    feedbackRowKey
  }) {
    return Client.request(
      'POST',
      getFlowApiUri(`v1/teacher-assistant/feedback`),
      {
        comments: comments || '',
        isPositiveFeedback,
        feedbackTableName,
        feedbackPartitionKey,
        feedbackRowKey
      }
    );
  }

  /* Topic Tags */
  static async getTopicTags(locale = 'en-GB', parentId = null) {
    return Client.request('GET', getApiUri('v3/topic-tags'), {
      locale,
      parentId
    });
  }

  static async insertTopicTag(topicTag) {
    return Client.request('POST', getApiUri('v3/topic-tags'), topicTag);
  }

  static async updateTopicTag(topicTagId, topicTag) {
    return Client.request(
      'PUT',
      getApiUri(`v3/topic-tags/${topicTagId}`),
      topicTag
    );
  }

  static async deleteTopicTag(topicTagId) {
    return Client.request('DELETE', getApiUri(`v3/topic-tags/${topicTagId}`));
  }

  /* Notifications */
  static async getSchoolNotifications() {
    return Client.request('GET', getApiUri('v4/admin/school-notifications'));
  }

  static async insertSchoolNotification(notification) {
    return Client.request(
      'POST',
      getApiUri('v4/admin/school-notifications'),
      notification
    );
  }

  static async insertAndDispatchSchoolNotification(notification) {
    return Client.request(
      'POST',
      getFunctionsApiUri('v3/notification-feed'),
      notification
    );
  }

  static async updateAndDispatchSchoolNotification(
    notificationId,
    notification
  ) {
    return Client.request(
      'PUT',
      getFunctionsApiUri(`v3/notification-feed/${notificationId}`),
      notification
    );
  }

  static async insertAndDispatchStudentNotification(notification) {
    return Client.request(
      'POST',
      getFunctionsApiUri('v3/student-notification'),
      notification
    );
  }

  static async updateSchoolNotification(notificationId, notification) {
    return Client.request(
      'PUT',
      getApiUri(`v4/admin/school-notifications/${notificationId}`),
      notification
    );
  }

  static async deleteSchoolNotification(notificationId) {
    return Client.request(
      'DELETE',
      getApiUri(`v4/admin/school-notifications/${notificationId}`)
    );
  }

  static async createStudyPlan(payload) {
    return Client.request(
      'POST',
      getFlowApiUri('v1/planner/generate'),
      payload
    );
  }

  static async getQuizQuestion(questionId) {
    return Client.request('GET', getApiUri(`v3/questions/${questionId}`));
  }

  static async updateQuizQuestion(questionId, question) {
    return Client.request(
      'PUT',
      getApiUri(`v3/questions/${questionId}`),
      question
    );
  }

  // Experimental
  static async speechToText(formData) {
    return Client.request(
      'POST',
      getFlowApiUri('v1/chat/speech-to-text'),
      formData,
      {
        headers: {
          isFormData: true
        }
      }
    );
  }

  // Whiteboard
  static async getWhiteboardSession(sessionId) {
    const response = await Client.request(
      'GET',
      getFlowApiUri(`v1/whiteboards/${sessionId}`)
    );
    if (response.lines) {
      response.lines = JSON.parse(response.lines);
    }
    if (response.shapes) {
      response.shapes = JSON.parse(response.shapes);
    }
    if (response.images) {
      response.images = JSON.parse(response.images);
    }
    if (response.text) {
      response.text = JSON.parse(response.text);
    }
    return response;
  }

  static async getWhiteboardSessionForInput(sessionId, inputId) {
    const response = await Client.request(
      'GET',
      getFlowApiUri(`v1/whiteboards/${sessionId}/inputs/${inputId}`)
    );
    if (response.lines) {
      response.lines = JSON.parse(response.lines);
    }
    if (response.shapes) {
      response.shapes = JSON.parse(response.shapes);
    }
    if (response.images) {
      response.images = JSON.parse(response.images);
    }
    if (response.text) {
      response.text = JSON.parse(response.text);
    }
    return response;
  }

  static async upsertWhiteboardSession(sessionId, request) {
    const payload = {
      lines: JSON.stringify(request.lines),
      shapes: JSON.stringify(request.shapes),
      images: JSON.stringify(request.images),
      text: JSON.stringify(request.text),
      imageSrcs: request.imageSrcs,
      imageSasTokens: request.imageSasTokens,
      inputId: request.inputId
    };
    return Client.request(
      'PUT',
      getFlowApiUri(`v1/whiteboards/${sessionId}`),
      payload
    );
  }

  static async uploadImage(sessionId, formData) {
    return Client.request(
      'POST',
      getFlowApiUri(`v1/whiteboards/${sessionId}/images`),
      formData
    );
  }

  /* Construct Video Transcripts*/
  static async getProofedConstructTranscripts(constructId) {
    return Client.request(
      'GET',
      getApiUri(`/v4/admin/constructs/${constructId}/transcripts`)
    );
  }

  static async getProofedVideoTranscript(constructId, blobUrl) {
    return Client.request(
      'GET',
      getApiUri(
        `/v4/admin/constructs/${constructId}/transcript?relativeVideoUrl=${blobUrl}`
      )
    );
  }

  static async upsertProofedTranscript({
    constructId,
    relativeVideoUrl,
    transcript
  }) {
    return Client.request(
      'POST',
      getApiUri(`/v4/admin/construct-transcripts`),
      {
        constructId,
        relativeVideoUrl,
        transcript
      }
    );
  }

  static async getConstructVideoTranscriptStatuses(constructId) {
    return Client.request(
      'GET',
      `${Settings.TRANSCRIPT_API_URL}/transcription_statuses?construct_id=${constructId}`,
      null,
      { disableToken: true, headers: { token: '345ldfgpoertasdasd=' } }
    );
  }

  static async processConstructVideoTranscripts(
    constructId,
    constructVideoBlobUrls = []
  ) {
    return Client.request(
      'POST',
      `${Settings.TRANSCRIPT_API_URL}/transcribe_construct_videos`,
      {
        construct_id: constructId,
        blob_names: constructVideoBlobUrls
      },
      { disableToken: true, headers: { token: '345ldfgpoertasdasd=' } }
    );
  }

  static async processConstructVideoTranscript(
    constructId,
    constructVideoBlobUrl,
    force = false
  ) {
    return Client.request(
      'POST',
      `${Settings.TRANSCRIPT_API_URL}/transcribe_video`,
      {
        construct_id: constructId,
        blob_names: [constructVideoBlobUrl],
        force
      },
      { disableToken: true, headers: { token: '345ldfgpoertasdasd=' } }
    );
  }

  static async detachParents(studentUserId) {
    return Client.request(
      'DELETE',
      getApiUri(`v4/admin/students/${studentUserId}/parents`)
    );
  }
}

export default Api;
