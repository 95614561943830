import { List, Pagination, FunctionField, ChipField } from 'react-admin';
import { Box, Button, FormControlLabel, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useLocation } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import { useCallback, useEffect, useState } from 'react';
import EditUserSubscriptionsModal from './Components/EditUserSubscriptionsModal';
import UserSubscriptionsDataGrid from './Components/UserSubscriptionsDataGrid';
import UserSubscriptionsListActions from './Components/UserSubscriptionsListActions';
import CheckboxFieldComponent from './Components/CheckboxFieldComponent';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import moment from 'moment-timezone';

const useStyles = makeStyles(theme => ({
  root: {
    margin: '12px',
    minHeight: '350px'
  },
  editButton: {
    color: '#4353ff',
    fontSize: '0.8125rem',
    padding: '4px',
    border: 'none',
    background: 'none',
    boxShadow: 'none'
  }
}));

const UserSusbcriptionsPagination = props => (
  <Pagination rowsPerPageOptions={[20, 30, 40, 50]} {...props} />
);

const UserSubscriptionsList = props => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [isEditModalOpen, setIsEditModalOpen] = useState(
    location.pathname.includes('edit')
  );
  const isSchoolFiltered = window.location.search.includes('schoolId');
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [selectedFields, setSelectedFields] = useState([]);
  const [selectedUserSubscription, setSelectedUserSubscription] =
    useState(null);

  const updateSelection = useCallback(
    r => {
      const isSelected = !selectedFields.find(s => s === r.familyStudentId);
      if (!isSelected) {
        setSelectedFields(() => [
          ...selectedFields.filter(s => s !== r.familyStudentId)
        ]);
      } else {
        setSelectedFields(() => [...selectedFields, r.familyStudentId]);
      }
    },
    [selectedFields]
  );

  useEffect(() => {
    const runEffect = async () => {
      if (location.pathname.includes('edit') !== isEditModalOpen)
        setIsEditModalOpen(!isEditModalOpen);
    };
    runEffect();
  }, [isEditModalOpen, location.pathname, selectedFields]);

  return (
    <Box className={classes.root}>
      <List
        title="User Subscriptions"
        pagination={<UserSusbcriptionsPagination />}
        bulkActionButtons={false}
        perPage={20}
        filterDefaultValues={{
          queryParams: {}
        }}
        filters={
          isEditModalOpen && (
            <EditUserSubscriptionsModal
              isOpen={true}
              setIsOpen={() =>
                history.push(`/user-subscriptions${location.search}`)
              }
              userSubscription={selectedUserSubscription}
              familyStudentIds={selectedFields}
              isAllSelected={isAllSelected}
            />
          )
        }
        sort={{ field: 'StudentId', order: 'DESC' }}
        actions={<UserSubscriptionsListActions />}
        {...props}>
        <UserSubscriptionsDataGrid isRowSelectable={record => true}>
          <CheckboxFieldComponent
            label={
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!isSchoolFiltered}
                    checked={isAllSelected}
                    onChange={() => {
                      setSelectedFields([]);
                      setIsAllSelected(!isAllSelected);
                    }}
                    name="isAllSelected"
                    color="primary"
                  />
                }
              />
            }
            isSelected={r =>
              !!selectedFields.find(s => s === r.familyStudentId) ||
              isAllSelected
            }
            onChange={updateSelection}
          />
          <FunctionField
            sortByOrder="DESC"
            label="StudentId"
            sortBy="StudentId"
            render={r => `${r.id}`}
          />
          <FunctionField
            label="UserName"
            render={r => `${r.studentUserName}`}
          />
          <FunctionField
            label="Name"
            render={r =>
              `${r.studentName ? r.studentName?.substring(0, 15) : 'N/A'}`
            }
          />
          <FunctionField
            label="Parent Connected"
            render={r => (
              <>
                {!!r?.parentUserName &&
                !r.parentUserName?.includes('unclaimed') ? (
                  <CheckIcon />
                ) : (
                  <ClearIcon />
                )}
              </>
            )}
          />
          <FunctionField
            label="School Connected"
            render={r => (
              <>
                {r.studentSchools?.length > 0 ? <CheckIcon /> : <ClearIcon />}
              </>
            )}
          />
          <FunctionField
            label="SchoolSub"
            render={r => <>{!!r.schoolId ? <CheckIcon /> : <ClearIcon />}</>}
          />
          <FunctionField
            label="TrialLength"
            render={r => `${r.trialDaysCount ?? '-'}`}
          />
          <ChipField
            sortable={false}
            label="Status"
            source="adminSubscriptionStatus"
            color="default"
          />
          <FunctionField
            label="PeriodEndDate"
            render={r =>
              r.subscriptionPeriodEnd
                ? moment(r.subscriptionPeriodEnd).format('YYYY-MM-DD')
                : '-'
            }
          />
          <EditStudentButton
            label="Edit"
            classes={classes}
            history={history}
            location={location}
            setSelectedUserSubscription={setSelectedUserSubscription}
          />
        </UserSubscriptionsDataGrid>
      </List>
    </Box>
  );
};

const EditStudentButton = ({
  record,
  classes,
  history,
  setSelectedUserSubscription,
  disabled,
  location
}) => {
  return (
    <Button
      disabled={disabled}
      variant="contained"
      color="default"
      className={classes.editButton}
      startIcon={<EditIcon />}
      onClick={r => {
        history.push(
          `/user-subscriptions/edit/${record.familyStudentId}${location.search}`
        );
        setSelectedUserSubscription(record);
      }}>
      Edit
    </Button>
  );
};

export default UserSubscriptionsList;
