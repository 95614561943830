import { DatagridBody, Datagrid } from 'react-admin';

const UserSubscriptionsDataGrid = props => {
  return (
    <Datagrid
      {...props}
      isRowSelectable={record => true}
      body={<DatagridBody />}
    />
  );
};

export default UserSubscriptionsDataGrid;
