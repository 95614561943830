import { Grid } from '@material-ui/core';
import { makeStyles, createTheme } from '@material-ui/core/styles';
import { Loading } from 'react-admin';
import { useEffect, useState, useCallback } from 'react';
import { useSignalROn } from 'Hooks/useSignalROn';
import { useJoinFlowSessionSignalRGroup } from 'Hooks/useJoinFlowSessionSignalRGroup';
import useGetAuthUser from 'Hooks/useGetAuthUser';
import NotifySound from 'Assets/notification.mp3';
import Api from 'Api/Api';
import ConversationChatBox from './Components/ConversationChatBox';
import ConverstationListGrid from './Components/ConversationListGrid';

const theme = createTheme({});

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    height: '100%'
  },
  studentShow: {
    height: 'calc(100vh - 65px)'
  },
  hidePanel: {
    textAlign: 'center',
    padding: '0.25rem'
  }
}));

const ConversationShow = props => {
  const classes = useStyles();
  let flowGeneratorSessionId = props.id;
  flowGeneratorSessionId = parseInt(flowGeneratorSessionId, 10);
  const [data, setData] = useState({});
  useJoinFlowSessionSignalRGroup(flowGeneratorSessionId);
  const user = useGetAuthUser();
  const [isGPTLoading, setIsGPTLoading] = useState(false);
  const [isPromptModalOpen, setIsPromptModalOpen] = useState(false);

  const cancelResponse = useCallback(
    async () => await Api.cancelTutorResponse(flowGeneratorSessionId),
    [flowGeneratorSessionId]
  );

  const getConversation = useCallback(async () => {
    try {
      if (!flowGeneratorSessionId) return;
      const response = await Api.getConversation(flowGeneratorSessionId);
      setData({ flowGeneratorSession: response, ...response });
    } catch (e) {
      console.error(e);
    }
  }, [flowGeneratorSessionId]);

  useEffect(() => {
    async function runEffect() {
      if (flowGeneratorSessionId && !data?.flowGeneratorSessionId) {
        await getConversation();
      }
    }
    runEffect();
  }, [data?.flowGeneratorSessionId, flowGeneratorSessionId, getConversation]);

  const processFlowSession = flowSession => {
    if (flowSession?.flowGeneratorSessionId !== data?.flowGeneratorSessionId)
      return;

    setData({
      ...data,
      isGPTEnabled: flowSession.isGPTEnabled,
      tutorUserId: flowSession.tutorUserId,
      tutorUsername:
        flowSession.tutorUserId === user.id
          ? user.fullname
          : data.tutorUsername,
      flowGeneratorSession: {
        ...data.flowGeneratorSession
      }
    });
  };

  useSignalROn(
    `DispatchFlowGeneratorSession:${flowGeneratorSessionId}`,
    flowSession => processFlowSession(flowSession)
  );

  const processFlowItem = useCallback(
    flowItem => {
      if (flowItem?.flowGeneratorSessionId !== data?.flowGeneratorSessionId)
        return;

      const existingFlowItem = data.flowGeneratorSession.flowItems.find(
        fi => fi.flowItemId === flowItem.flowItemId
      );

      if (existingFlowItem) return;

      if (
        flowItem?.userId > 0 &&
        flowItem?.userId !== user?.id &&
        !flowItem?.flowItemGroup
      ) {
        new Audio(NotifySound)
          .play()
          .then(r => {})
          .catch(e => console.warn(e));
      }

      setData({
        ...data,
        flowGeneratorSession: {
          ...data.flowGeneratorSession,
          flowItems: [...data.flowGeneratorSession.flowItems, flowItem]
        }
      });
    },
    [data, user?.id]
  );

  useSignalROn(`DispatchFlowItem:${flowGeneratorSessionId}`, flowItem =>
    processFlowItem(flowItem)
  );

  useSignalROn(
    `DispatchGPTTextLoading:${flowGeneratorSessionId}`,
    isLoading => {
      isLoading = isLoading.toLowerCase() === 'true';

      setIsGPTLoading(isLoading);

      if (!isLoading) {
        const flowItems = data.flowGeneratorSession.flowItems.filter(
          d => d.flowItemId !== -1
        );
        setData({
          ...data,
          flowGeneratorSession: { ...data.flowGeneratorSession, flowItems }
        });
      }
    }
  );

  useSignalROn(`DispatchGPTText:${flowGeneratorSessionId}`, gptText => {
    try {
      const gptFlowItem = {
        flowItemId: -1,
        flowItemType: 'Text',
        text: gptText,
        userId: -1,
        dateDispatched: new Date().toISOString()
      };
      const flowItems = data.flowGeneratorSession.flowItems.filter(
        d => d.flowItemId !== -1
      );
      flowItems.push(gptFlowItem);
      setData({
        ...data,
        flowGeneratorSession: { ...data.flowGeneratorSession, flowItems }
      });
    } catch (e) {
      console.error(e);
    }
  });

  if (!data || data?.flowGeneratorSessionId !== flowGeneratorSessionId) {
    return (
      <div className={classes.root}>
        <Loading />
      </div>
    );
  }

  return (
    <Grid
      container
      direction="row"
      alignItems="flex-start"
      style={{ position: 'relative', height: '100%' }}>
      <Grid
        item
        style={{
          transition: theme.transitions.create('all', {
            easing: theme.transitions.easing.easeInOut,
            duration: '0.7s'
          }),
          maxWidth: '100%',
          flexBasis: '100%'
        }}>
        <ConverstationListGrid data={data} />
        <Grid
          item
          xs={12}
          style={{
            position: 'sticky',
            bottom: '0'
          }}>
          <ConversationChatBox
            isPromptModalOpen={isPromptModalOpen}
            setIsPromptModalOpen={setIsPromptModalOpen}
            flowGeneratorSessionId={flowGeneratorSessionId}
            loading={isGPTLoading}
            cancelResponse={cancelResponse}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ConversationShow;
