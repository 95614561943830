import EditNotificationForm from './EditNotificationForm';
import {
  Modal,
  Backdrop,
  Container,
  Card,
  CardContent
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Api from 'Api/Api';
import { useCallback, useState } from 'react';
import { useNotify, useRefresh } from 'react-admin';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
    margin: 0
  },
  content: {
    margin: 0,
    padding: 0,
    overflowY: 'scroll',
    minHeight: 'calc(100vh - 80px)',
    maxHeight: `${window.innerHeight ?? 900}px`
  },
  loading: {
    margin: '0 auto',
    textAlign: 'center',
    padding: '20px'
  }
}));

const EditNotificationModal = ({
  isOpen,
  setIsOpen = () => {},
  notification = {}
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDispatching, setIsDispatching] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();

  const onSave = useCallback(
    async values => {
      setIsLoading(true);
      try {
        await Api.updateSchoolNotification(values.id, {
          schoolId: values.schoolId,
          title: values.title,
          body: values.body,
          activeFromDate: values.activeFromDate,
          activeToDate: values.activeToDate,
          notificationType: values.notificationType,
          isShowInModal: values.isShowInModal
        });
        notify('Notification updated', 'success');
        setIsOpen(false);
        refresh();
      } catch (e) {
        console.log(e);
        notify('Error updating notification', 'error');
      }
      setIsLoading(false);
    },
    [notify, refresh, setIsOpen]
  );

  const onSaveAndDispatch = useCallback(
    async values => {
      setIsDispatching(true);
      console.log(values);
      try {
        await Api.updateAndDispatchSchoolNotification(values.id, {
          schoolId: values.schoolId,
          title: values.title,
          body: values.body,
          activeFromDate: values.activeFromDate,
          activeToDate: values.activeToDate,
          notificationType: values.notificationType,
          isShowInModal: values.isShowInModal
        });
        notify('Notification updated & dispatched', 'success');
        setIsOpen(false);
        refresh();
      } catch (e) {
        console.log(e);
        notify('Error updating & dispatching notification', 'error');
      }
      setIsDispatching(false);
    },
    [notify, refresh, setIsOpen]
  );

  const classes = useStyles();
  return (
    <Modal
      className={classes.modal}
      open={isOpen}
      onClose={() => setIsOpen(false)}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
      closeAfterTransition>
      <Container maxWidth="md">
        <Card className={classes.create}>
          <CardContent className={classes.content}>
            {isLoading || isDispatching ? (
              <div className={classes.loading}>
                <h2 style={{ margin: '0 auto' }}>
                  {isLoading
                    ? 'Saving...'
                    : 'Saving & Dispatching... this may take a moment...'}
                </h2>
                <div style={{ width: '100%' }}>
                  <img
                    style={{
                      textAlign: 'center',
                      margin: '0 auto',
                      height: '150px',
                      width: 'auto',
                      display: 'flex'
                    }}
                    src="/assets/loady.gif"
                    alt="loading!"
                  />
                </div>
              </div>
            ) : (
              <EditNotificationForm
                notification={notification}
                onSave={onSave}
                onSaveAndDispatch={onSaveAndDispatch}
                onCancel={() => setIsOpen(false)}
              />
            )}
          </CardContent>
        </Card>
      </Container>
    </Modal>
  );
};

export default EditNotificationModal;
