import LessonShow from 'Pages/Lessons/LessonShow';
import LessonList from 'Pages/Lessons/LessonList';
import { useParams } from 'react-router';
import { makeStyles, createTheme } from '@material-ui/core/styles';
import React, { useCallback, useEffect, useRef } from 'react';
import useWindowDimensions from 'Hooks/useWindowDimensions';
import { useJoinLessonDashboardSignalRGroup } from 'Hooks/useJoinLessonDashboardSignalRGroup';
import { useSelector } from 'react-redux';
import { Slide } from '@material-ui/core';
import HidePanelButton from 'Components/HidePanelButton';

const theme = createTheme({});

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    padding: 0,
    flexDirection: 'row',
    alignContent: 'flex-start'
  },
  lessonShow: {
    height: 'calc(100vh - 65px)',
    width: '75%',
    background: '#eee',
    backgroundImage: `url("/assets/email-pattern.png")`
  },
  lessonList: {
    height: 'calc(100vh - 48px)',
    width: '25%'
  },
  gridRoot: {
    height: '100%'
  },
  listGridItem: {
    borderRight: '1px solid #ddd',
    borderLeft: '1px solid #ddd'
  },
  showItem: {
    width: '100%',
    background: '#eee',
    backgroundImage: `url("/assets/email-pattern.png")`,
    height: 'calc(100vh - 48px)',
    overflow: 'scroll'
  },
  hideButton: {
    margin: '0.5rem 0 0 0',
    textAlign: 'center'
  }
}));

const Dashboard = () => {
  const { width, height } = useWindowDimensions();
  const { lessonListOpen, lessonListAutoScrollEnabled } = useSelector(
    s => s.uxState
  );
  const classes = useStyles({ height, width });
  let { flowGeneratorSessionId } = useParams();
  flowGeneratorSessionId = parseInt(flowGeneratorSessionId, 10);
  const scroll = useRef(null);
  const list = useRef(null);

  useJoinLessonDashboardSignalRGroup();

  const handleScroll = useCallback(() => {
    if (
      !scroll?.current ||
      !list?.current ||
      scroll?.current?.lessonListAutoScrollEnabled
    ) {
      return;
    }

    let isOverThreshold = false;

    if (
      scroll.current.previous_height &&
      Math.abs(scroll.current.scrollTop - scroll.current.previous_scrollTop) >
        100
    ) {
      scroll.current.scrollTop = scroll.current.previous_scrollTop;
      isOverThreshold = true;
    }

    scroll.current.previous_height = list.current.clientHeight;

    if (!isOverThreshold) {
      scroll.current.previous_scrollTop = scroll.current.scrollTop;
    }
  }, []);

  useEffect(() => {
    if (!scroll?.current) return;

    scroll.current.lessonListAutoScrollEnabled = lessonListAutoScrollEnabled;
    scroll.current.lessonListAutoScrollEnabledConst =
      lessonListAutoScrollEnabled;
    window.addEventListener('mousedown', () => {
      scroll.current.lessonListAutoScrollEnabled = true;
    });
    window.addEventListener('mouseup', () => {
      scroll.current.lessonListAutoScrollEnabled =
        scroll.current.lessonListAutoScrollEnabledConst;
      scroll.current.previous_scrollTop = scroll.current.scrollTop;
    });
    scroll.current.addEventListener('scroll', handleScroll);
  }, [handleScroll, lessonListAutoScrollEnabled]);

  return (
    <div className={classes.root}>
      <div
        className={classes.lessonList}
        style={{
          width: lessonListOpen ? '25%' : '3%',
          transition: theme.transitions.create('all', {
            easing: theme.transitions.easing.easeInOut,
            duration: '1s'
          }),
          background: lessonListOpen ? 'white' : '#ccc'
        }}>
        <Slide
          direction="right"
          in={lessonListOpen}
          mountOnEnter
          unmountOnExit
          timeout={700}>
          <div>
            <LessonList />
          </div>
        </Slide>
        <Slide
          direction="up"
          in={!lessonListOpen}
          mountOnEnter
          unmountOnExit
          timeout={500}>
          <div className={classes.hideButton}>
            <HidePanelButton property="lessonListOpen" flipIcons={true} />
          </div>
        </Slide>
      </div>

      <div
        className={classes.lessonShow}
        style={{
          width: lessonListOpen ? '75%' : '97%',
          transition: theme.transitions.create('all', {
            easing: theme.transitions.easing.easeInOut,
            duration: '0.7s'
          })
        }}>
        {!!flowGeneratorSessionId && (
          <div className={classes.showItem} ref={scroll}>
            <div ref={list}>
              <LessonShow />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
