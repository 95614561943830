import { makeStyles } from '@material-ui/core/styles';
import {
  Modal,
  Backdrop,
  TextField,
  Button,
  FormGroup
} from '@material-ui/core';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import React, { useCallback } from 'react';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import { Field, Form } from 'react-final-form';
import { useNotify } from 'react-admin';
import NoteAddIcon from '@material-ui/icons/NoteAdd';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '500px'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  container: {
    minWidth: '400px'
  },
  input: {
    width: '100%',
    margin: '0.5rem 0 1rem'
  },
  select: {
    width: '100%',
    margin: '1rem 0 1rem'
  },
  formControl: {
    width: '100%'
  },
  button: {
    float: 'right',
    margin: '0.5rem 0 0 1rem'
  },
  uploadButton: {
    width: '40px',
    height: '40px'
  },
  fileDetails: {
    margin: '0',
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'flex-end',
    borderBottom: '1px solid #ddd'
  },
  uploadFormGroup: {
    flexDirection: 'row',
    display: 'flex',
    borderBottom: '1px solid #ddd',
    alignItems: 'center'
  }
}));

const NewFlowTemplateModal = ({
  isModalOpen,
  setIsModalOpen,
  createNewTemplateSlug
}) => {
  const classes = useStyles();
  const notify = useNotify();

  const onSubmit = useCallback(
    async values => {
      try {
        const response = await createNewTemplateSlug(values.templateSlug, {
          templateSlug: values.templateSlug,
          position: 1,
          flowTemplateSlug: `${values.templateSlug} - Node 1`,
          flowTemplateContentType: 'General',
          xPosition: 0,
          yPosition: 0,
          skipToExpressions: []
        });
        !!response && setIsModalOpen(false);
      } catch (e) {
        console.error(e);
        notify(
          `${e || 'Oops! Something went wrong. Please try again later.'}`,
          'error'
        );
      }
    },
    [createNewTemplateSlug, setIsModalOpen, notify]
  );

  return (
    <Modal
      open={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      className={classes.modal}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
      closeAfterTransition>
      <div className={classes.paper}>
        <h3 style={{ display: 'flex' }}>
          Create New Template <NoteAddIcon style={{ margin: '0 0 0 0.5rem' }} />
        </h3>
        <Form
          onSubmit={onSubmit}
          initialValues={{ templateSlug: '' }}
          validate={values => {
            const errors = {};
            if (!values.templateSlug) {
              errors.templateSlug = 'Template Slug Required';
            }
            return errors;
          }}>
          {({ handleSubmit, submitting, pristine, submitError }) => (
            <form className={classes.container} onSubmit={handleSubmit}>
              <FormGroup>
                <Field name="templateSlug">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label="Template Slug"
                      className={classes.input}
                      InputLabelProps={{
                        shrink: true
                      }}
                      error={meta.error && meta.touched}
                      helperText={
                        meta.error && meta.touched ? meta.error : null
                      }
                    />
                  )}
                </Field>
              </FormGroup>
              <Button
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                className={classes.button}
                startIcon={<SaveIcon />}
                disabled={pristine || submitting || submitError}>
                Create
              </Button>
              <Button
                variant="contained"
                className={classes.button}
                startIcon={<CancelIcon />}
                disabled={submitting}
                onClick={() => setIsModalOpen(false)}>
                Cancel
              </Button>
            </form>
          )}
        </Form>
      </div>
    </Modal>
  );
};

export default NewFlowTemplateModal;
