import {
  useListContext,
  TopToolbar,
  ExportButton,
  sanitizeListRestProps
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    justifyContent: 'flex-end!important',
    WebkitJustifyContent: 'flex-end!important',
    WebKitBoxPack: 'end!important',
    width: '100%'
  },
  createButton: {
    color: '#4353ff',
    fontSize: '0.8125rem',
    padding: '4px',
    border: 'none',
    background: 'none',
    boxShadow: 'none',
    float: 'right',
    margin: '0 10px 0 0'
  },
  uploadButton: {
    margin: '0 10px 0 0'
  },
  cancelButton: {},
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  fileDetails: {
    display: 'flex'
  },
  successBox: {
    margin: '1rem 0 1rem 0',
    background: '#d1f5c9',
    padding: '1rem'
  },
  errorBox: {
    margin: '1rem 0 1rem 0',
    background: '#fab8aa',
    padding: '1rem'
  },
  loading: {
    maxHeight: '250px',
    margin: 0
  }
}));

const EmbeddingOutputListActions = props => {
  const classes = useStyles();
  //const history = useHistory();
  const { className, maxResults, ...rest } = props;
  const { total } = useListContext();

  return (
    <>
      <TopToolbar className={classes.root} {...sanitizeListRestProps(rest)}>
        <ExportButton disabled={total === 0} maxResults={maxResults} />
      </TopToolbar>
    </>
  );
};

export default EmbeddingOutputListActions;
