import { makeStyles } from '@material-ui/core/styles';
import {
  TextField,
  Button,
  Select,
  InputLabel,
  MenuItem,
  FormGroup
} from '@material-ui/core';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { React } from 'react';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import { Form, Field } from 'react-final-form';
import styled from 'styled-components';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import FindInPageIcon from '@material-ui/icons/FindInPage';

const useStyles = makeStyles(theme => ({
  input: {
    margin: '1rem 0 0 0'
  },
  createButton: {
    padding: '4px',
    margin: '1rem 0 1rem 0',
    width: '150px'
  },
  cancelButton: {
    margin: '1rem 0 0 10px'
  },
  select: {
    margin: '1rem 0 0 0',
    width: '100%'
  },
  tagButton: {
    margin: '0 0 0 0.5rem'
  }
}));

const embeddingTypes = [
  { key: 'All', value: '' },
  { key: 'MisconceptionTag', value: 1 },
  { key: 'Question', value: 2 },
  { key: 'Quiz', value: 3 },
  { key: 'TopicPathwayQuiz', value: 4 },
  { key: 'QuestionAnswerTeacherExplanation', value: 5 }
];

const UpdateEmbeddingForm = ({
  onSubmit = () => {},
  onCancel = () => {},
  embeddingOutput = {},
  onDeleteTag = () => {},
  isDeleting = false,
  onDelete = () => {},
  tagToDelete = null
}) => {
  const classes = useStyles();

  const initialValue = {
    ...embeddingOutput,
    type: embeddingTypes.find(e => e.key === embeddingOutput.type)?.value || ''
  };

  return (
    <Form
      onSubmit={values => {
        onSubmit(values);
      }}
      initialValues={initialValue}
      validate={values => {
        const errors = {};
        if (!values.searchText) {
          errors.searchText = 'Please enter search text!';
        }
        if (values.searchText?.length < 10) {
          errors.searchText = 'Search text must be at least 10 characters!';
        }
        return errors;
      }}>
      {({ handleSubmit, submitting, pristine, submitError }) => (
        <form onSubmit={handleSubmit}>
          <FormGroup>
            <Field name="type">
              {({ input, meta }) => (
                <>
                  <InputLabel
                    required
                    id="type-label"
                    className={classes.select}>
                    Embedding Type
                  </InputLabel>
                  <Select
                    {...input}
                    name="type"
                    labelId="type-label"
                    error={meta.error && meta.touched}>
                    {embeddingTypes.map((c, idx) => (
                      <MenuItem key={idx} value={c.value}>
                        {c.key}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              )}
            </Field>
          </FormGroup>
          <FormGroup>
            <Field name="searchText">
              {({ input, meta }) => (
                <TextField
                  {...input}
                  id="standard-basic"
                  label="Search Text"
                  variant="filled"
                  multiline
                  className={classes.input}
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : null}
                />
              )}
            </Field>
          </FormGroup>
          <MisconceptionTagWrapper>
            <div>
              <h3>Linked - Misconception Tags</h3>
            </div>
            <MisconceptionTagList>
              {embeddingOutput.misconceptionTags?.map((m, i) => (
                <MisconceptionTag key={i} className="mt">
                  <div>
                    QuestionId: {m.questionId}, AnswerValue: {m.answerValue}
                  </div>
                  <div>
                    <Button
                      variant="contained"
                      color="secondary"
                      size="small"
                      className={classes.tagButton}
                      startIcon={<FindInPageIcon />}
                      disabled={submitting}
                      onClick={() =>
                        window.open(
                          `/misconception-tags?displayedFilters=%7B%7D&filter=%7B"q"%3A"${m.questionId}"%7D&order=ASC&page=1&perPage=100&sort=id`,
                          '_blank'
                        )
                      }>
                      Find
                    </Button>
                  </div>
                  <div>
                    <Button
                      variant="contained"
                      color="secondary"
                      size="small"
                      className={classes.tagButton}
                      startIcon={<DeleteForeverIcon />}
                      disabled={submitting}
                      onClick={() => onDeleteTag(m.misconceptionTagId)}>
                      {tagToDelete === m.misconceptionTagId
                        ? 'Are you sure?'
                        : 'Delete Tag'}
                    </Button>
                  </div>
                </MisconceptionTag>
              ))}
            </MisconceptionTagList>
          </MisconceptionTagWrapper>
          <ButtonWrapper>
            <Button
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              className={classes.createButton}
              startIcon={<SaveIcon />}
              disabled={submitting || pristine || submitError}>
              Update
            </Button>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={() => onDelete()}
              className={classes.cancelButton}
              startIcon={<DeleteForeverIcon />}
              disabled={submitting}>
              {!isDeleting ? 'Delete' : 'Are you sure?'}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              className={classes.cancelButton}
              onClick={() => onCancel()}
              startIcon={<CancelIcon />}
              disabled={submitting}>
              Close
            </Button>
          </ButtonWrapper>
        </form>
      )}
    </Form>
  );
};

const ButtonWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  align-content: center;
  justify-content: flex-start;
`;

const MisconceptionTagWrapper = styled.div``;

const MisconceptionTagList = styled.div`
  display: flex;
  flex-direction: column;
  .mt:nth-of-type(even) {
    background-color: #eee;
  }
`;

const MisconceptionTag = styled.div`
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin: 0.25rem 0 0.25rem 0;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export default UpdateEmbeddingForm;
