import { useSelector } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import { MenuItemLink } from 'react-admin';
import SchoolIcon from '@material-ui/icons/School';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import BallotIcon from '@material-ui/icons/Ballot';
import GridOnIcon from '@material-ui/icons/GridOn';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import QueueIcon from '@material-ui/icons/Queue';
import LabelIcon from '@material-ui/icons/Label';
import useGetPermissions from 'Hooks/useGetPermissions';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import StarsIcon from '@material-ui/icons/Stars';
import FaceIcon from '@material-ui/icons/Face';
import { useHistory } from 'react-router-dom';
import AssignmentIcon from '@material-ui/icons/Assignment';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import SubtitlesIcon from '@material-ui/icons/Subtitles';
import styled from 'styled-components';
import MoreIcon from '@material-ui/icons/More';
import AssessmentIcon from '@material-ui/icons/Assessment';
// import TimelineIcon from '@material-ui/icons/Timeline';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';

const superAdminResources = [
  '/students',
  '/tutors',
  '/hybrid-schools',
  '/user-subscriptions',
  '/topic-pathway-quizs',
  '/flow-questions',
  '/worksheet-questions',
  '/construct-transcripts',
  '/flows',
  '/approve-teacher'
];

const Menu = ({ onMenuClick, logout, ...props }) => {
  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const history = useHistory();
  const { open } = useSelector(state => state.admin.ui.sidebarOpen);
  const permissions = useGetPermissions();

  if (permissions?.isLoading) return null;

  if (
    !permissions?.isAdmin &&
    permissions?.userRoles?.includes('Moderator') &&
    !window.location.pathname.includes('question-metadata')
  ) {
    history.push('/question-metadata');
  }

  !permissions?.isSuperAdmin &&
    !!superAdminResources.find(s => window.location.host.includes(s)) &&
    history.push('/');

  const isSuperAdmin = !!permissions?.isSuperAdmin;
  const isModerator = !!permissions?.userRoles?.includes('Moderator');
  const isTutor = !!permissions?.userRoles?.includes('Tutor');
  const isContractor = !!permissions?.userRoles?.includes('ContractorAdmin');

  if (isContractor) {
    // if current path does not contain construct-transcripts, redirect to construct-transcripts
    if (!window.location.pathname.includes('construct-transcripts')) {
      history.push('/construct-transcripts');
      return null;
    }
    return (
      <>
        <MenuItemLink
          to="/construct-transcripts"
          primaryText="Construct Transcripts"
          leftIcon={<SubtitlesIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
      </>
    );
  }

  return (
    <div style={{ zIndex: '999' }}>
      {(isTutor || isSuperAdmin) && (
        <>
          <MenuItemLink
            to="/"
            primaryText="Dashboard"
            leftIcon={<AssessmentIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
          <MenuItemLink
            to="/lessons"
            primaryText="Lesson Dashboard"
            leftIcon={<DashboardIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
          <MenuItemLink
            to="/flow-generator-sessions"
            primaryText="Lessons"
            leftIcon={<SchoolIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
          <MenuItemLink
            to="/tutor-rota"
            primaryText="Tutor Rota"
            leftIcon={<PermContactCalendarIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
          <MenuItemLink
            to="/requests"
            primaryText="Requests"
            leftIcon={<QueueIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
          <MenuItemLink
            to="/misconception-tags"
            primaryText="Misconception Tagging"
            leftIcon={<LabelIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
          <MenuItemLink
            to="/question-metadata"
            primaryText="Question Metadata"
            leftIcon={<MoreIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
          <MenuItemLink
            to="/construct-transcripts"
            primaryText="Construct Transcripts"
            leftIcon={<SubtitlesIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
        </>
      )}
      {isSuperAdmin && (
        <>
          <p
            style={{
              fontSize: '10px',
              textAlign: 'center',
              background: '#ccc',
              padding: '8px'
            }}>
            Admin
          </p>
          <MenuItemLink
            to="/students"
            primaryText="Students"
            leftIcon={<PeopleIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
          <MenuItemLink
            to="/awards"
            primaryText="Awards"
            leftIcon={<StarsIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
          <MenuItemLink
            to="/tutors"
            primaryText="Tutors"
            leftIcon={<FaceIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
          <MenuItemLink
            to="/hybrid-schools"
            primaryText="Hybrid Schools"
            leftIcon={<AccountBalanceIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
          <MenuItemLink
            to="/user-subscriptions"
            primaryText="User Subscriptions"
            leftIcon={<MonetizationOnIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
          {/* <MenuItemLink
            to="/flows"
            primaryText="Flow Editor"
            leftIcon={<TimelineIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
          /> */}
          <MenuItemLink
            to="/school-notifications"
            primaryText="Notifications"
            leftIcon={<NotificationsActiveIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
          <MenuItemLink
            to="/approve-teacher"
            primaryText="Approve Teacher"
            leftIcon={<ThumbUpIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
        </>
      )}
      {(isModerator || isSuperAdmin) && (
        <>
          <p
            style={{
              fontSize: '10px',
              textAlign: 'center',
              background: '#ccc',
              padding: '8px'
            }}>
            Content
          </p>
          <MenuItemLink
            to={`/topic-pathway-quizs`}
            primaryText="Topic Pathway Quizzes"
            leftIcon={<AccountTreeIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
          <MenuItemLink
            to="/flow-questions"
            primaryText="Flow Questions"
            leftIcon={<BallotIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
          <StyledMenuItemLink
            to="/worksheet-questions"
            primaryText="Worksheet Questions"
            leftIcon={<GridOnIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
          <StyledMenuItemLink
            to="/embedding-outputs"
            primaryText="Embedding Outputs"
            leftIcon={<AssignmentIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
          <MenuItemLink
            to="/conversations"
            primaryText="Conversations"
            leftIcon={<QuestionAnswerIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
        </>
      )}
      {isXSmall && logout}
    </div>
  );
};

const StyledMenuItemLink = styled(MenuItemLink)`
  div {
    color: ${props => (props.isActive ? '#2196f' : 'grey')};
  }
`;

export default Menu;
