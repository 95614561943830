import { makeStyles } from '@material-ui/core/styles';
import { Modal, Backdrop } from '@material-ui/core';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import EditFlowTemplateForm from '../Forms/EditFlowTemplateForm';
import styled from 'styled-components';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '700px'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  }
}));

const CreateFlowTemplateModal = ({
  flowTemplates = [],
  flowItemTemplates = [],
  templateSlug,
  isModalOpen,
  setIsModalOpen,
  insertFlowTemplate,
  newNodePosition = null,
  updateFlowItem = () => {},
  insertFlowItem = () => {}
}) => {
  const classes = useStyles();

  if (!templateSlug || !newNodePosition) return null;

  return (
    <Modal
      open={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      className={classes.modal}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
      closeAfterTransition>
      <div className={classes.paper}>
        <h3 style={{ display: 'flex' }}>
          Add Flow Template <NoteAddIcon style={{ margin: '0 0 0 0.5rem' }} />
        </h3>
        <StyledEditFlowTemplateForm
          flowTemplate={{ templateSlug, position: newNodePosition }}
          flowTemplates={flowTemplates}
          flowItems={flowItemTemplates}
          updateFlowTemplate={insertFlowTemplate}
          updateFlowItem={updateFlowItem}
          insertFlowItem={insertFlowItem}
          title={''}
          isDoubleConfirm={false}
          confirmCopy="Create Flow Template Group"
        />
      </div>
    </Modal>
  );
};

const StyledEditFlowTemplateForm = styled(EditFlowTemplateForm)`
  width: 500px;
  div {
    padding: 0;
  }
`;

export default CreateFlowTemplateModal;
