import { useEffect, useRef } from 'react';

const useInterval = (callback, interval) => {
  const callbackRef = useRef();
  useEffect(() => {
    async function runEffect() {
      callbackRef.current = setInterval(callback, interval);
    }
    runEffect();
    return () => clearInterval(callbackRef.current);
  }, [callback, interval]);
};

export default useInterval;
