import styled from 'styled-components';
import {
  Radio,
  FormControlLabel,
  Button,
  Typography,
  CircularProgress
} from '@material-ui/core';
import { useNotify } from 'react-admin';
import Api from 'Api/Api';
import { useCallback, useState } from 'react';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import { Tooltip } from '@material-ui/core';
import GPTFeedback from 'Components/GPTFeedback';

const MisconceptionTagGenerator = ({
  questionId = null,
  values,
  onChange = () => {},
  disabled = false,
  currentAnswerValue = null
}) => {
  const notify = useNotify();
  const [generatorResult, setGeneratorResult] = useState({});
  const [loadingValidation, setLoadingValidation] = useState(false);

  const generateSuggestions = useCallback(async () => {
    setLoadingValidation(true);
    try {
      if (!currentAnswerValue || !values?.questionId)
        throw new Error('No questionId or value found!');

      const result = await Api.getRecommendedMisconceptionTag(
        values.questionId,
        currentAnswerValue
      );
      setGeneratorResult(() => ({
        currentAnswerValue,
        ...result
      }));
    } catch (e) {
      console.error(e);
      notify(`Error validating misconception tag: ${e}`, 'error');
    }
    setLoadingValidation(false);
  }, [currentAnswerValue, notify, values.questionId]);

  const {
    think,
    suggestedTags,
    existingTags,
    rowKey,
    partitionKey,
    tableName
  } = generatorResult;

  if (loadingValidation)
    return (
      <div>
        <CircularProgress size={20} />
        <Typography>Generating tag suggestions...</Typography>
      </div>
    );

  return (
    <div>
      <Tooltip
        placement={'bottom'}
        title={
          disabled
            ? 'Cannot generate suggestions if no question meta-data is present'
            : 'Generate tag suggestions. This may take a few seconds...'
        }>
        <div>
          <Button
            color="secondary"
            size="small"
            variant="outlined"
            type="button"
            disabled={disabled}
            startIcon={<EmojiObjectsIcon />}
            onClick={() => generateSuggestions(values?.embedding?.searchText)}>
            Generate Suggestions
          </Button>
        </div>
      </Tooltip>
      {!!think ? (
        <ValidationResultSummary issuccess={true} key={currentAnswerValue}>
          <div>
            <strong>Summary:</strong>
            <br />
            {think}
          </div>
          {!!suggestedTags?.length && (
            <AltTagWrapper>
              <strong>Suggestions</strong>
              <SmallSummary>
                These are the newly generated suggestions.
              </SmallSummary>
              {suggestedTags?.map(tag => (
                <div>
                  <FormControlLabel
                    control={<Radio />}
                    label={tag}
                    onChange={() => onChange({ searchText: tag })}
                    name="embedding-radio"
                    value={tag}
                    checked={tag === values?.embedding?.searchText}
                  />
                </div>
              ))}
            </AltTagWrapper>
          )}
          {!!existingTags?.length && (
            <AltTagWrapper>
              <strong>Existing tags</strong>
              <SmallSummary>
                These are existing tags that are most similar to the generated
                ones above.
              </SmallSummary>
              {existingTags?.map(tag => (
                <div>
                  <FormControlLabel
                    control={<Radio />}
                    label={tag}
                    onChange={() => onChange({ searchText: tag })}
                    name="embedding-radio"
                    value={tag}
                    checked={tag === values?.embedding?.searchText}
                  />
                </div>
              ))}
            </AltTagWrapper>
          )}
          <GPTFeedback
            tableName={tableName}
            partitionKey={partitionKey}
            rowKey={rowKey}
            comments={`Summary: ${think} - Suggestions: ${suggestedTags?.join(
              ', '
            )}`}
          />
        </ValidationResultSummary>
      ) : Object.keys(generatorResult).length > 0 ? (
        <ValidationResultSummary issuccess={false} key={currentAnswerValue}>
          <strong>Wasn't able to recommend any tags :-(</strong>
        </ValidationResultSummary>
      ) : null}
    </div>
  );
};

const ValidationResultSummary = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ issuccess }) => (issuccess ? '#cef5ee' : '#fabed1')};
  padding: 1rem;
  margin: 1rem 0 0 0;
  border-radius: 8px;
  div {
    margin: 0 0 0.25rem 0;
  }
`;

const AltTagWrapper = styled.div`
  padding: 1rem;
  background: #fff;
  border-radius: 6px;
  margin-top: 0.25rem;
  div {
    margin: 0;
  }
  span {
    padding: 2px 5px 0 0.25rem;
  }
`;

const SmallSummary = styled.div`
  font-size: 13px;
  color: #777;
`;

export default MisconceptionTagGenerator;
