import { withStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';

const LightToolTip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 13,
    zIndex: 999
  },
  arrow: {
    color: theme.palette.common.white
  }
}))(Tooltip);

const LightTooltip = ({ title, placement, children }) => (
  <LightToolTip placement={placement} title={title} arrow>
    {children}
  </LightToolTip>
);

export default LightTooltip;
