export const constructVideos = [
  {
    label: '1b-talking-teacher',
    blobUrl: constructId =>
      `constructs/${constructId}/teach/1b-talking-teacher.mp4`
  },
  {
    label: '2-narration',
    blobUrl: constructId => `constructs/${constructId}/teach/2-narration.mp4`
  },
  {
    label: '4b-your-turn',
    blobUrl: constructId => `constructs/${constructId}/teach/4b-your-turn.mp4`
  },
  {
    label: '5b-explain-the-mistake',
    blobUrl: constructId =>
      `constructs/${constructId}/teach/5b-explain-the-mistake.mp4`
  },
  {
    label: 'q1',
    blobUrl: constructId => `constructs/${constructId}/worksheet/q1.mp4`
  },
  {
    label: 'q2',
    blobUrl: constructId => `constructs/${constructId}/worksheet/q2.mp4`
  },
  {
    label: 'q3',
    blobUrl: constructId => `constructs/${constructId}/worksheet/q3.mp4`
  },
  {
    label: 'q4',
    blobUrl: constructId => `constructs/${constructId}/worksheet/q4.mp4`
  },
  {
    label: 'q5',
    blobUrl: constructId => `constructs/${constructId}/worksheet/q5.mp4`
  },
  {
    label: 'q6',
    blobUrl: constructId => `constructs/${constructId}/worksheet/q6.mp4`
  },
  {
    label: 'q7',
    blobUrl: constructId => `constructs/${constructId}/worksheet/q7.mp4`
  },
  {
    label: 'q8',
    blobUrl: constructId => `constructs/${constructId}/worksheet/q8.mp4`
  },
  {
    label: 'q9',
    blobUrl: constructId => `constructs/${constructId}/worksheet/q9.mp4`
  },
  {
    label: 'q10',
    blobUrl: constructId => `constructs/${constructId}/worksheet/q10.mp4`
  },
  {
    label: 'q11',
    blobUrl: constructId => `constructs/${constructId}/worksheet/q11.mp4`
  },
  {
    label: 'q12',
    blobUrl: constructId => `constructs/${constructId}/worksheet/q12.mp4`
  }
  // {
  //   label: '1-silent-teacher',
  //   blobUrl: constructId =>
  //     `constructs/${constructId}/teach/1-silent-teacher.mp4`
  // }
];
