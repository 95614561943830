import RequestShowCard from 'Components/Requests/RequestShowCard';
import { Loading, useShowController } from 'react-admin';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '350px'
  }
}));

const RequestsShow = props => {
  const { loaded, loading, record } = useShowController(props);
  const classes = useStyles();
  if (loading || !loaded) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  return (
    <Box className={classes.root}>
      <RequestShowCard data={record} />
    </Box>
  );
};

export default RequestsShow;
