import { makeStyles } from '@material-ui/core/styles';
import {
  Modal,
  Backdrop,
  TextField,
  Button,
  Container,
  CardContent,
  Typography,
  FormGroup
} from '@material-ui/core';
import AssistantIcon from '@material-ui/icons/Assistant';
import CancelIcon from '@material-ui/icons/Cancel';
import Api from 'Api/Api';
import { useHistory } from 'react-router-dom';
import { useNotify } from 'react-admin';
import { Form, Field } from 'react-final-form';
import { useCallback, useEffect, useState } from 'react';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  create: {
    margin: '1rem 0 0 0',
    overflowY: 'scroll',
    maxHeight: `${window.innerHeight ?? 900}px`
  },
  input: {
    margin: '1rem 0 0 0'
  },
  container: {
    background: '#fff'
  },
  createButton: {
    margin: '1rem 10px 0 0'
  },
  cancelButton: {
    margin: '1rem 0 0 0'
  }
}));

const ConversationPromptModal = ({
  isOpen = false,
  setIsOpen = () => {},
  isCreateMode = false,
  flowGeneratorSessionId = null,
  prompt = '',
  input = ''
}) => {
  const history = useHistory();
  const notify = useNotify();
  const classes = useStyles();
  const [systemPrompt, setSystemPrompt] = useState({ prompt, input });
  const [loaded, setLoaded] = useState(isOpen);
  const [startingConversation, setStartingConversation] = useState(false);

  const fetchSystemPrompt = useCallback(
    async flowGeneratorSessionId => {
      try {
        const response = await Api.getConversationPrompt(
          flowGeneratorSessionId
        );
        setSystemPrompt(Object.keys(response).length === 0 ? null : response);
      } catch (e) {
        console.error(e);
        notify('Error fetching prompt', 'error');
        setSystemPrompt(null);
      }
    },
    [notify]
  );

  useEffect(() => {
    const runEffect = async () => {
      if (!loaded && isOpen && flowGeneratorSessionId) {
        await fetchSystemPrompt(flowGeneratorSessionId);
        setLoaded(true);
      }
      if (isOpen && isCreateMode) setLoaded(true);
      if (!isOpen && loaded) setLoaded(false);
      if (
        !flowGeneratorSessionId &&
        (systemPrompt.prompt !== prompt || systemPrompt.input !== input)
      )
        setSystemPrompt({ prompt, input });
    };
    runEffect();
  }, [
    fetchSystemPrompt,
    flowGeneratorSessionId,
    input,
    isCreateMode,
    isOpen,
    loaded,
    prompt,
    systemPrompt.input,
    systemPrompt.prompt
  ]);

  const startConversation = async values => {
    try {
      setStartingConversation(true);
      const conversation = await Api.startConversation(values);

      if (!conversation?.flowGeneratorSessionId)
        throw new Error('Error starting conversation');

      history.push(
        `/conversations/${conversation.flowGeneratorSessionId}/show`
      );
    } catch (e) {
      console.error(e);
      notify('Error starting conversation', 'error');
    }
    setStartingConversation(false);
  };

  if (!isOpen || (!systemPrompt && !isCreateMode) || !loaded) return null;

  return (
    <Modal
      open={isOpen}
      className={classes.modal}
      onClose={() => setIsOpen(false)}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
      closeAfterTransition>
      <Container maxWidth="md" className={classes.container}>
        <CardContent>
          <Typography variant="h5" gutterBottom>
            {isCreateMode
              ? 'Start New Conversation'
              : 'Current Conversation Prompt'}
          </Typography>
          {!startingConversation ? (
            <Form
              onSubmit={startConversation}
              initialValues={{
                prompt: systemPrompt?.prompt || '',
                input: systemPrompt?.input || ''
              }}
              validate={values => {
                const errors = {};
                if (!values.prompt) errors.prompt = 'Required';
                return errors;
              }}>
              {({ handleSubmit, submitting, pristine, submitError }) => (
                <form onSubmit={handleSubmit}>
                  <FormGroup>
                    <Field name="input">
                      {({ input, meta }) => (
                        <TextField
                          {...input}
                          style={{ width: '100%' }}
                          minRows={1}
                          maxRows={3}
                          multiline
                          disabled={!isCreateMode}
                          type="text"
                          variant="outlined"
                          label="Initial Input (optional)"
                          className={classes.input}
                          error={meta.error && meta.touched}
                          helperText={
                            meta.error && meta.touched ? meta.error : null
                          }
                        />
                      )}
                    </Field>
                  </FormGroup>
                  <FormGroup>
                    <Field name="prompt">
                      {({ input, meta }) => (
                        <TextField
                          {...input}
                          style={{ width: '100%' }}
                          minRows={10}
                          maxRows={30}
                          multiline
                          disabled={!isCreateMode}
                          type="text"
                          variant="outlined"
                          label="Prompt"
                          className={classes.input}
                          error={meta.error && meta.touched}
                          helperText={
                            meta.error && meta.touched ? meta.error : null
                          }
                        />
                      )}
                    </Field>
                  </FormGroup>
                  {isCreateMode && (
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      type="submit"
                      className={classes.createButton}
                      startIcon={<AssistantIcon />}
                      disabled={
                        (submitting || pristine || submitError) &&
                        !systemPrompt.prompt
                      }>
                      Start
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    className={classes.cancelButton}
                    onClick={() => setIsOpen(false)}
                    startIcon={<CancelIcon />}>
                    {isCreateMode ? 'Cancel' : 'Close'}
                  </Button>
                </form>
              )}
            </Form>
          ) : (
            <img
              style={{
                textAlign: 'center',
                margin: '0 auto',
                height: '150px',
                width: 'auto',
                display: 'flex'
              }}
              src="/assets/loady.gif"
              alt="loading!"></img>
          )}
        </CardContent>
      </Container>
    </Modal>
  );
};

export default ConversationPromptModal;
