import {
  Select,
  InputLabel,
  MenuItem,
  TextField,
  FormGroup,
  FormControl,
  Button
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Field, Form } from 'react-final-form';
import SaveConfirmButton from '../SaveConfirmButton';
import styled from 'styled-components';
import {
  useHistory,
  useParams
} from 'react-router-dom/cjs/react-router-dom.min';
import CreateFlowItemTemplateModal from '../Modals/CreateFlowItemTemplateModal';
import { useState } from 'react';
import { parseTemplateText } from '../flowTemplateHelpers';

const flowTemplateContentTypes = [
  'General',
  'QuestionConstructContent',
  'WorkSheetQuestion',
  'FlowQuestion',
  'QuizQuestion',
  'QuizQuestionRetry'
];

const useStyles = makeStyles(theme => ({
  root: {
    overflowY: 'scroll'
  },
  select: {
    width: '100%',
    margin: '1rem 0 1rem'
  },
  formControl: {
    width: '90%',
    padding: '0.25rem 2rem 0.25rem 1rem',
    margin: '0 0 0.25rem'
  },
  formGroup: {
    width: '90%',
    padding: '0.25rem 2rem 0.25rem 1rem',
    margin: '0 0 0.25rem'
  },
  inputLabel: {
    position: 'relative'
  },
  h3: {
    padding: '0 0 0 1rem'
  },
  h5: {
    padding: '0 0 0 1rem'
  },
  label: {
    padding: '0 0 0 1rem',
    fontSize: '1rem',
    textAlign: 'left'
  },
  multiline: {
    width: '100%',
    margin: '1rem 0 0.5rem'
  },
  hr: {
    margin: '1rem',
    border: '1px solid #ccc'
  },
  hr100: {
    border: '1px solid #ccc',
    margin: '0.25rem 0 0.25rem'
  },
  p: {
    padding: 0,
    margin: 0
  },
  button: {
    margin: '0.25rem 0 1rem'
  }
}));

const EditFlowTemplateForm = ({
  flowTemplates = [],
  flowTemplate,
  flowItems = [],
  updateFlowTemplate,
  updateFlowItem,
  insertFlowItem,
  title = 'Flow Template Group',
  isDoubleConfirm = true,
  confirmCopy = 'Confirm Save',
  isLoading = false,
  ...props
}) => {
  const [isCreateFlowItemModalOpen, setIsCreateFlowItemModalOpen] =
    useState(false);
  const { templateSlug = '' } = useParams();
  const history = useHistory();
  const classes = useStyles();

  if (!flowTemplate) return null;

  const parentFlowTemplates = [
    ...flowTemplates
      .filter(ft => ft.flowTemplateSlug !== flowTemplate.flowTemplateSlug)
      .map(ft => ({ label: ft.flowTemplateSlug, value: ft.flowTemplateSlug })),
    ...[{ label: 'None', value: null }]
  ];

  return (
    <>
      <Form
        className={classes.root}
        onSubmit={async values => {
          const previousFlowTemplate = flowTemplates
            .sort((a, b) => a.position - b.position)
            .find(ft => ft.position < values.position ?? flowTemplate.position);

          if (!!previousFlowTemplate) {
            values.xPosition = previousFlowTemplate.xPosition + 100;
            values.yPosition = previousFlowTemplate.yPosition + 150;
          }

          await updateFlowTemplate(
            {
              ...{
                flowItemTemplateSlug: null,
                positionMapping: null,
                parentFlowTemplateSlug: null
              },
              ...values,
              skipToExpressions: null
            },
            flowTemplate.position
          );
        }}
        initialValues={flowTemplate}
        validate={values => {
          const errors = {};
          if (!values.flowTemplateSlug || values.flowTemplateSlug?.length < 5) {
            errors.flowTemplateSlug =
              'Enter a flow template slug at least 5 chars long';
          }
          if (!values.position || values.position <= 0) {
            errors.position = 'Position must be greater than 0';
          }
          if (
            values.flowItemTemplateSlug &&
            values.flowItemTemplateSlug?.length < 5
          ) {
            errors.flowItemTemplateSlug =
              'Enter a flow item template slug at least 5 chars long';
          }
          if (
            values.positionMapping?.length &&
            !values.positionMapping?.includes('{{') &&
            !values.positionMapping?.includes('}}')
          ) {
            errors.positionMapping =
              'Position Mapping must contain prefix {{ and suffix }}';
          }
          return errors;
        }}>
        {({ handleSubmit, submitting, pristine, submitError, values }) => (
          <StyledForm onSubmit={handleSubmit} {...props}>
            {!!title ? <h3 className={classes.h3}>{title}</h3> : null}
            <FormGroup className={classes.formGroup}>
              <Field name="flowTemplateSlug">
                {({ input, meta }) => (
                  <TextField
                    {...input}
                    onChange={e => {
                      input.onChange(parseTemplateText(e.target.value));
                    }}
                    label="FlowTemplateSlug"
                    variant="filled"
                    className={classes.input}
                    error={meta.error && meta.touched}
                    helperText={meta.error && meta.touched ? meta.error : null}
                  />
                )}
              </Field>
            </FormGroup>
            <FormGroup className={classes.formGroup}>
              <Field name="parentFlowTemplateSlug">
                {({ input, meta }) => (
                  <FormControl>
                    <InputLabel id="parentFlowTemplateSlug-label">
                      Parent Flow Template Slug
                    </InputLabel>
                    <Select
                      id="parentFlowTemplateSlug"
                      {...input}
                      style={{ minHeight: '40px' }}
                      placeholder="Content Type"
                      error={meta.error && meta.touched}
                      helperText={
                        meta.error && meta.touched ? meta.error : null
                      }>
                      {parentFlowTemplates?.map((t, i) => (
                        <MenuItem key={i} value={t.value}>
                          {t.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Field>
            </FormGroup>
            <FormGroup
              className={classes.formGroup}
              style={{ display: 'flex' }}>
              <Field name="flowItemTemplateSlug">
                {({ input, meta }) => (
                  <TextField
                    {...input}
                    onChange={e => {
                      input.onChange(parseTemplateText(e.target.value));
                    }}
                    label="FlowItemTemplateSlug"
                    variant="filled"
                    className={classes.input}
                    error={meta.error && meta.touched}
                    helperText={meta.error && meta.touched ? meta.error : null}
                  />
                )}
              </Field>
              {!!flowTemplate.flowItemTemplateSlug &&
              !!flowItems.find(
                f => f.templateSlug === flowTemplate.flowItemTemplateSlug
              ) ? (
                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  className={classes.button}
                  onClick={() =>
                    history.push(
                      `/flows/${templateSlug}/0/${flowTemplate.flowItemTemplateSlug}`
                    )
                  }>
                  Edit Flow Item Template
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  disabled={!flowTemplate.flowItemTemplateSlug}
                  className={classes.button}
                  onClick={() => setIsCreateFlowItemModalOpen(true)}>
                  Add Flow Item Template
                </Button>
              )}
            </FormGroup>
            <FormGroup className={classes.formGroup}>
              <Field name="position">
                {({ input, meta }) => (
                  <TextField
                    {...input}
                    label="Position"
                    variant="filled"
                    type="number"
                    className={classes.input}
                    error={meta.error && meta.touched}
                    helperText={meta.error && meta.touched ? meta.error : null}
                  />
                )}
              </Field>
            </FormGroup>
            <FormGroup className={classes.formGroup}>
              <Field name="positionMapping">
                {({ input, meta }) => (
                  <TextField
                    {...input}
                    onChange={e => {
                      input.onChange(parseTemplateText(e.target.value));
                    }}
                    label="Position Mapping"
                    variant="filled"
                    className={classes.input}
                    error={meta.error && meta.touched}
                    helperText={meta.error && meta.touched ? meta.error : null}
                  />
                )}
              </Field>
            </FormGroup>
            <FormGroup className={classes.formGroup}>
              <Field name="flowTemplateContentType">
                {({ input, meta }) => (
                  <>
                    <InputLabel id="flowTemplateContentType-label">
                      Content Type
                    </InputLabel>
                    <Select
                      id="flowTemplateContentType"
                      {...input}
                      style={{ minHeight: '40px' }}
                      variant="filled"
                      placeholder="Content Type"
                      error={meta.error && meta.touched}
                      helperText={
                        meta.error && meta.touched ? meta.error : null
                      }>
                      {flowTemplateContentTypes?.map((t, i) => (
                        <MenuItem key={i} value={t}>
                          {t}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
              </Field>
            </FormGroup>
            <SaveConfirmButton
              isDoubleConfirm={isDoubleConfirm}
              submitting={submitting || isLoading}
              pristine={pristine}
              submitError={submitError}
              confirmCopy={confirmCopy}
            />
          </StyledForm>
        )}
      </Form>
      <CreateFlowItemTemplateModal
        isModalOpen={isCreateFlowItemModalOpen}
        setIsModalOpen={() => setIsCreateFlowItemModalOpen(false)}
        updateFlowItem={updateFlowItem}
        insertFlowItem={insertFlowItem}
        loading={isLoading}
        flowTemplate={flowTemplate}
        flowItems={flowItems}
        templateSlug={flowTemplate?.flowItemTemplateSlug}
        title="Create Flow Item Template"
      />
    </>
  );
};

const StyledForm = styled.form`
  div {
    width: 100%;
  }
`;

export default EditFlowTemplateForm;
