const getQueryFilter = filters => {
  let index = 0;
  const request = {
    filters: []
  };
  for (const [key, value] of Object.entries(filters)) {
    if (key === 'queryParams') {
      continue;
    }

    if (typeof value === 'object') {
      request.filters.push({});
      request['filters'][index].Value = value.Value ? `${value.Value}` : null;
      request['filters'][index].FieldName = `${key}`;
      request['filters'][index].Operator = `${value.Operator}`;
      request['filters'][index].Type = value.Type ?? typeof value.Value;
    } else {
      request['filters'][index].Value = value ? `${value}` : null;
      request['filters'][index].FieldName = `${key}`;
      request['filters'][index].Operator = `Eq`;
      request['filters'][index].Type = typeof value;
    }
    index++;
  }
  return request;
};

const filterHelper = {
  getQueryFilter
};

export default filterHelper;
