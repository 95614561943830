import { makeStyles } from '@material-ui/core/styles';
import {
  Modal,
  Backdrop,
  Container,
  Card,
  CardContent
} from '@material-ui/core';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useCallback, React, useState, useEffect } from 'react';
import { useDataProvider, useNotify, useRefresh } from 'react-admin';
import { useLocation } from 'react-router-dom';
import EditWorksheetQuestionForm from './EditWorksheetQuestionForm';
import Api from 'Api/Api';
import TabPanel from 'Components/TabPanel';
import EditWorksheetVideoPanel from './EditWorksheetVideoPanel';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  create: {
    margin: '1rem 0 0 0',
    overflowY: 'scroll',
    maxHeight: `${window.innerHeight ?? 900}px`
  },
  input: {
    margin: '1rem 0 0 0'
  },
  createButton: {
    margin: '1rem 0 0 0'
  },
  cancelButton: {
    margin: '1rem 0 0 10px'
  },
  select: {
    margin: '1rem 0 0 0',
    width: '100%'
  },
  numberInput: {
    margin: '1rem 0 0 0'
  }
}));

const EditWorksheetQuestionModal = ({ isOpen, setIsOpen }) => {
  const location = useLocation();
  const id = location.pathname.split('/').reverse()[0];
  const notify = useNotify();
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const [worksheetQuestion, setWorksheetQuestion] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [cacheState, setCacheState] = useState({
    isModalOpen: false,
    isLoading: false
  });

  const fetchWorksheetQuestion = useCallback(
    async id => {
      try {
        if (!id) return;

        const result = await dataProvider.getOne('worksheet-questions', {
          id
        });

        if (!result?.data)
          throw new Error(`Unable to fetch worksheet question ${id}`);

        setWorksheetQuestion(() => result.data);
      } catch (e) {
        notify('Error fetching worksheet question!', 'error');
        console.error(e);
      }
    },
    [dataProvider, notify]
  );

  const resetCache = useCallback(
    async ({ showNotify = true }) => {
      if (!worksheetQuestion?.constructId) return;
      try {
        setCacheState(() => ({ ...cacheState, isLoading: true }));
        await Api.reCacheFlowTemplates({
          constructIds: [worksheetQuestion?.constructId]
        });
        setCacheState(() => ({
          ...cacheState,
          isModalOpen: false,
          isLoading: false
        }));
        showNotify && notify('Whoop! Cache cleared!', 'success');
      } catch (error) {
        setCacheState(() => ({ ...cacheState, isLoading: false }));
        notify('Error resetting cache', 'error');
        console.error(error);
      }
    },
    [cacheState, notify, worksheetQuestion?.constructId]
  );

  const updateWorksheetQuestion = useCallback(
    async values => {
      try {
        await dataProvider.update('worksheet-questions', {
          id: values.id,
          body: values
        });
        await resetCache({ showNotify: false });
        refresh();
        setIsOpen();
        notify('Worksheet Question Updated!', 'success');
      } catch (e) {
        notify('Error Updating Worksheet Question!', 'error');
        console.error(e);
      }
    },
    [dataProvider, notify, refresh, resetCache, setIsOpen]
  );

  useEffect(() => {
    const runEffect = async () => {
      if (isLoaded || !isOpen) return;
      id && (await fetchWorksheetQuestion(id));
      setIsLoaded(true);
    };
    runEffect();
  }, [fetchWorksheetQuestion, isLoaded, isOpen, id]);

  const tabs = [
    {
      label: 'Edit Worksheet Question',
      children: (
        <CardContent>
          {!cacheState.isLoading && (
            <EditWorksheetQuestionForm
              worksheetQuestion={worksheetQuestion}
              onSubmit={updateWorksheetQuestion}
              onCancel={setIsOpen}
              resetCache={resetCache}
              cacheState={cacheState}
            />
          )}
          {cacheState.isLoading && (
            <>
              <p style={{ textAlign: 'center' }}>
                Resetting cache for this construct. This includes all linked
                quizs and topics... this may take a few moments...
                <span role="img" aria-labelledby="beverage">
                  ☕
                </span>
              </p>
              <img
                style={{
                  textAlign: 'center',
                  margin: '0 auto',
                  height: '150px',
                  width: 'auto',
                  display: 'flex'
                }}
                src="/assets/loady.gif"
                alt="loading!"></img>
            </>
          )}
        </CardContent>
      )
    },
    {
      label: 'Videos',
      children: (
        <EditWorksheetVideoPanel worksheetQuestion={worksheetQuestion} />
      )
    }
  ];

  return (
    <Modal
      open={isOpen}
      onClose={() => setIsOpen()}
      className={classes.modal}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
      closeAfterTransition>
      <Container maxWidth="md">
        <Card className={classes.create}>
          <TabPanel tabs={tabs} />
        </Card>
      </Container>
    </Modal>
  );
};

export default EditWorksheetQuestionModal;
