import {
  Typography,
  TextField,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Select,
  FormLabel,
  MenuItem
} from '@material-ui/core';
import { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Form, Field } from 'react-final-form';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import { useNotify, Loading } from 'react-admin';
import Countries from 'Components/Countries';
import Api from 'Api/Api';
import { useSelector } from 'react-redux';

const useStyles = makeStyles({
  input: {
    margin: '1rem 0 0 0'
  },
  createButton: {
    margin: '1rem 0 0 0'
  },
  cancelUpdateButton: {
    margin: '1rem 0 0 10px'
  },
  schoolName: {
    display: 'flex'
  },
  plusLogo: {
    maxWidth: '36px',
    maxHeight: '20px',
    display: 'flex',
    alignSelf: 'center',
    margin: '0 0 0 10px'
  }
});

const UpdateHybridSchoolForm = ({ school, onCancel }) => {
  const user = useSelector(u => u.user);
  const notify = useNotify();
  const classes = useStyles();

  const updateSchool = useCallback(
    async values => {
      if (!school?.schoolId) return;

      try {
        const updatedSchool = await Api.updateSchool(school.schoolId, {
          name: values.name,
          addressLine1: values.addressLine1,
          city: values.city,
          country: values.country,
          zipOrPostCode: values.zipOrPostCode
        });

        await Api.upsertHybridSchool(updatedSchool.schoolId, {
          defaultTrialLength: values.defaultTrialLength
        });

        if (
          values.addCurrentUserToSchool &&
          !!user?.userId &&
          !school.teacherId
        ) {
          try {
            await Api.assignUserToSchool(user?.userId, school.schoolId);
          } catch (e) {
            if (!e.includes('already a confirmed teacher for this user'))
              throw new Error(e);
          }
        }

        if (
          !values.addCurrentUserToSchool &&
          !!user?.userId &&
          !!school.teacherId
        ) {
          try {
            await Api.deleteTeacher(school.teacherId);
          } catch (e) {
            throw new Error(e);
          }
        }

        notify('School updated!', 'success');
        onCancel();
      } catch (e) {
        notify(`Oops - unable to update school: \n ${e}`, 'error');
        console.error(e);
      }
    },
    [notify, onCancel, school.schoolId, school.teacherId, user?.userId]
  );

  if (!school) return <Loading />;

  return (
    <Form
      onSubmit={updateSchool}
      initialValues={{
        name: school?.name,
        addressLine1: school?.addressLine1,
        city: school?.city,
        country: school?.country,
        zipOrPostCode: school?.zipOrPostCode,
        defaultTrialLength: school?.defaultTrialLength ?? 14,
        addCurrentUserToSchool: !!school?.teacherId
      }}
      validate={values => {
        const errors = {};
        if (!values.name) {
          errors.name = 'Please enter a school name';
        }
        if (!values.addressLine1) {
          errors.addressLine1 = 'Please enter a street address';
        }
        if (!values.city) {
          errors.city = 'Please enter a town or city';
        }
        if (
          isNaN(parseInt(values.defaultTrialLength, 10)) ||
          parseInt(values.defaultTrialLength, 10) < -1
        ) {
          errors.defaultTrialLength =
            'Please enter a valid trial length from -1 (unlimited) or greater';
        }
        return errors;
      }}>
      {({ handleSubmit, submitting, pristine, submitError }) => (
        <form onSubmit={handleSubmit}>
          <Typography variant="h5" gutterBottom>
            Update School
          </Typography>
          <Typography
            variant="subtitle1"
            className={classes.schoolName}
            gutterBottom>
            {school?.name}{' '}
            {school?.isHybridSchool && (
              <img
                src="/assets/pluspill.png"
                className={classes.plusLogo}
                alt="Eedi Plus"
              />
            )}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            SchoolId: {school?.schoolId}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Invitation Code: {school?.joinCode}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Student Count: {school?.studentCount}
          </Typography>
          <FormGroup>
            <Field name="name">
              {({ input, meta }) => (
                <TextField
                  {...input}
                  id="standard-basic"
                  label="School Name"
                  variant="filled"
                  className={classes.input}
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : null}
                />
              )}
            </Field>
          </FormGroup>
          <FormGroup>
            <Field name="addressLine1">
              {({ input, meta }) => (
                <TextField
                  {...input}
                  id="standard-basic"
                  label="Street Address"
                  variant="filled"
                  className={classes.input}
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : null}
                />
              )}
            </Field>
          </FormGroup>
          <FormGroup>
            <Field name="city">
              {({ input, meta }) => (
                <TextField
                  {...input}
                  id="standard-basic"
                  label="Town / City"
                  variant="filled"
                  className={classes.input}
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : null}
                />
              )}
            </Field>
          </FormGroup>
          <FormGroup>
            <Field name="zipOrPostCode">
              {({ input, meta }) => (
                <TextField
                  {...input}
                  id="standard-basic"
                  label="Zip or Postcode"
                  variant="filled"
                  className={classes.input}
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : null}
                />
              )}
            </Field>
          </FormGroup>
          <FormGroup style={{ margin: '1rem 0 1rem 0' }}>
            <FormLabel>Country</FormLabel>
            <Field name="country">
              {({ input, meta }) => (
                <Select
                  {...input}
                  name="countrySelect"
                  error={meta.error && meta.touched}>
                  {Countries.countriesList
                    .map(s => ({ key: s.name, value: s.name }))
                    .map((s, idx) => (
                      <MenuItem key={idx} value={s.value}>
                        {s.key}
                      </MenuItem>
                    ))}
                </Select>
              )}
            </Field>
          </FormGroup>
          <FormGroup>
            <Field name="defaultTrialLength">
              {({ input, meta }) => (
                <TextField
                  {...input}
                  id="standard-number"
                  label="Default Trial Length"
                  type="number"
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : null}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              )}
            </Field>
          </FormGroup>
          <FormGroup>
            <Field name="addCurrentUserToSchool" type="checkbox">
              {({ input, meta }) => (
                <FormControlLabel
                  control={
                    <Checkbox {...input} error={meta.error && meta.touched} />
                  }
                  label="Current user is school admin?"
                />
              )}
            </Field>
          </FormGroup>
          <Button
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            className={classes.createButton}
            startIcon={<SaveIcon />}
            disabled={submitting || pristine || submitError}>
            Update
          </Button>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            className={classes.cancelUpdateButton}
            onClick={onCancel}
            startIcon={<CancelIcon />}>
            Cancel
          </Button>
        </form>
      )}
    </Form>
  );
};

export default UpdateHybridSchoolForm;
