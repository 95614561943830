export const notificationTypes = {
  PlainTextNotification: 'PlainTextNotification',
  PlainTextNotificationDismissible: 'PlainTextNotificationDismissible',
  MarkDownNotification: 'MarkDownNotification',
  MarkDownNotificationDismissible: 'MarkDownNotificationDismissible',
  HtmlNotification: 'HtmlNotification',
  HtmlNotificationDismissible: 'HtmlNotificationDismissible',
  HtmlBannerNotification: 'HtmlBannerNotification',
  HtmlBannerNotificationDismissible: 'HtmlBannerNotificationDismissible'
};

export const notificationTypeList = [
  notificationTypes.PlainTextNotification,
  notificationTypes.PlainTextNotificationDismissible,
  notificationTypes.MarkDownNotification,
  notificationTypes.MarkDownNotificationDismissible,
  notificationTypes.HtmlNotification,
  notificationTypes.HtmlNotificationDismissible,
  notificationTypes.HtmlBannerNotification,
  notificationTypes.HtmlBannerNotificationDismissible
];

export const notificationTypeGroups = [
  {
    label: 'Plain Text',
    value: notificationTypes.PlainTextNotification,
    dismissible: notificationTypes.PlainTextNotificationDismissible
  },
  {
    label: 'MarkDown',
    value: notificationTypes.MarkDownNotification,
    dismissible: notificationTypes.MarkDownNotificationDismissible
  },
  {
    label: 'Html',
    value: notificationTypes.HtmlNotification,
    dismissible: notificationTypes.HtmlNotificationDismissible
  },
  {
    label: 'Banner',
    value: notificationTypes.HtmlBannerNotification,
    dismissible: notificationTypes.HtmlBannerNotificationDismissible
  }
];
