import Api from 'Api/Api';
import { useState } from 'react';

const useDispatchIsTyping = (flowGeneratorSessionId, userId, delay = 5000) => {
  const [isTyping, setIsTyping] = useState(false);

  const dispatchIsTyping = async () => {
    if (isTyping || !flowGeneratorSessionId || !userId) return;

    setIsTyping(true);
    await Api.dispatchIsTyping(flowGeneratorSessionId, userId);
    setTimeout(() => setIsTyping(false), delay);
  };

  return { isTyping, dispatchIsTyping };
};

export default useDispatchIsTyping;
