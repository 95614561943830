import { makeStyles } from '@material-ui/core/styles';
import {
  TextField,
  Button,
  Select,
  InputLabel,
  MenuItem,
  FormGroup,
  Typography,
  CardContent,
  Collapse,
  FormControlLabel,
  Switch
} from '@material-ui/core';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { React, useState } from 'react';
import SaveIcon from '@material-ui/icons/Save';
import { Form, Field } from 'react-final-form';
import MisconceptionTagSelect from './MisconceptionTagSelect';
import MisconceptionTagSuggested from './MisconceptionTagSuggested';

const useStyles = makeStyles(theme => ({
  cardContent: {
    minHeight: '16rem'
  },
  input: {
    margin: '1rem 0 0 0'
  },
  createButton: {
    margin: '1rem 0 0 0'
  },
  select: {
    margin: '1rem 0 0 0',
    width: '100%'
  },
  explanation: {
    marginBottom: '1rem',
    backgroundColor: '#FAF3D3',
    borderRadius: '0.5rem',
    padding: '1rem'
  },
  correctAnswer: {
    backgroundColor: '#eee',
    borderRadius: '0.5rem',
    padding: '3.5rem 1rem',
    textAlign: 'center',
    color: '#666'
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

const statuses = [
  'Question or answer needs rewriting',
  'Answer has 2 misconceptions',
  'Too specific misconception',
  'Cannot identify misconception',
  'Mathematical slip',
  'Misread question'
];

const MisconceptionTagForm = ({
  onSubmit,
  onDelete,
  answerValue,
  answerLetter,
  question = {},
  misconceptionTag = {},
  isCorrect
}) => {
  const classes = useStyles();
  const [displaySuggested, setDisplaySuggested] = useState(false);

  const initialValues = {
    id: misconceptionTag.misconceptionTagId,
    comments: misconceptionTag?.comments,
    misconceptionStatus: misconceptionTag?.misconceptionStatus,
    questionId: question.questionId,
    answerValue: answerValue,
    flagged:
      misconceptionTag?.comments || misconceptionTag?.misconceptionStatus,
    embedding: {
      embeddingOutputId: misconceptionTag?.embeddingOutputId,
      searchText: misconceptionTag?.searchText ?? '',
      similarDistractorQuestionId:
        misconceptionTag?.similarDistractorQuestionId,
      similarDistractorAnswerValue:
        misconceptionTag?.similarDistractorAnswerValue
    },
    isDeclarativeKnowledge: misconceptionTag?.isDeclarativeKnowledge ?? false
  };

  return (
    <CardContent className={classes.cardContent}>
      <div className={classes.explanation}>
        <Typography>
          <strong>Explanation {answerLetter}: </strong>
          {question[`explanation${answerLetter}`]}
        </Typography>
        <Button
          size="small"
          variant="text"
          color="primary"
          onClick={() => setDisplaySuggested(!displaySuggested)}>
          {displaySuggested ? 'Hide' : 'Show'} suggested tags
        </Button>
      </div>

      {isCorrect ? (
        <div class={classes.correctAnswer}>This is the correct answer!</div>
      ) : (
        <Form onSubmit={onSubmit} initialValues={initialValues}>
          {({ handleSubmit, submitting, pristine, submitError }) => (
            <form onSubmit={handleSubmit}>
              {displaySuggested && (
                <FormGroup>
                  <Field name="embedding">
                    {({ input }) => (
                      <MisconceptionTagSuggested
                        question={question}
                        answerValue={answerValue}
                        {...input}
                      />
                    )}
                  </Field>
                </FormGroup>
              )}

              <FormGroup>
                <Field name="embedding">
                  {({ input }) => (
                    <MisconceptionTagSelect {...input} name="embedding" />
                  )}
                </Field>
              </FormGroup>

              <Field name="isDeclarativeKnowledge" type="checkbox">
                {({ input }) => (
                  <>
                    <FormControlLabel
                      control={<Switch {...input} />}
                      label="Is declarative knowledge"
                    />
                  </>
                )}
              </Field>

              <Field name="flagged" type="checkbox">
                {({ input }) => (
                  <>
                    <FormControlLabel
                      control={<Switch {...input} />}
                      label="Flag"
                    />
                    <Collapse in={input.checked}>
                      <FormGroup>
                        <Field name="misconceptionStatus">
                          {({ input, meta }) => (
                            <>
                              <InputLabel
                                id="misconception-status"
                                className={classes.select}>
                                Flag Reason
                              </InputLabel>
                              <Select
                                {...input}
                                name="misconception-status"
                                labelId="misconception-status"
                                error={meta.error && meta.touched}>
                                {statuses.map((c, idx) => (
                                  <MenuItem key={idx} value={c}>
                                    {c}
                                  </MenuItem>
                                ))}
                              </Select>
                            </>
                          )}
                        </Field>
                      </FormGroup>
                      <FormGroup>
                        <Field name="comments">
                          {({ input, meta }) => (
                            <TextField
                              {...input}
                              id="comments"
                              label="Comments"
                              variant="filled"
                              multiline
                              rows={2}
                              className={classes.input}
                              error={meta.error && meta.touched}
                              helperText={
                                meta.error && meta.touched ? meta.error : null
                              }
                            />
                          )}
                        </Field>
                      </FormGroup>
                    </Collapse>
                  </>
                )}
              </Field>

              <div className={classes.footer}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  type="submit"
                  className={classes.createButton}
                  startIcon={<SaveIcon />}
                  disabled={submitting || pristine || submitError}>
                  Save
                </Button>
                {misconceptionTag?.misconceptionTagId && (
                  <Button
                    color="secondary"
                    size="large"
                    onClick={() =>
                      onDelete(misconceptionTag.misconceptionTagId)
                    }
                    className={classes.createButton}>
                    Delete
                  </Button>
                )}
              </div>
            </form>
          )}
        </Form>
      )}
    </CardContent>
  );
};

export default MisconceptionTagForm;
