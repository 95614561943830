import { useEffect, useState, useContext } from 'react';
import { SignalRConnectionContext } from 'Providers/SignalRProvider';

export const useJoinFlowSessionSignalRGroup = flowGeneratorSessionId => {
  const { signalRConnection, connectionInfo } = useContext(
    SignalRConnectionContext
  );

  const [groupName, setGroupName] = useState(null);
  const [connectionDate, setConnectionDate] = useState(null);

  useEffect(() => {
    const runEffect = async () => {
      if (!flowGeneratorSessionId) return;

      if (
        (flowGeneratorSessionId !== groupName && connectionInfo.isConnected) ||
        connectionDate !== connectionInfo.connectionDate
      ) {
        try {
          console.warn(
            'connectionInfo - Joining flow session group',
            flowGeneratorSessionId
          );
          await signalRConnection.invoke(
            'JoinFlowSessionGroup',
            `${flowGeneratorSessionId}`
          );
          setGroupName(flowGeneratorSessionId);
          setConnectionDate(connectionInfo.connectionDate);
        } catch (e) {
          console.error('connectionInfo - error joining flow session group', e);
        }
      }
    };
    runEffect();
  }, [
    connectionDate,
    connectionInfo.connectionDate,
    connectionInfo.isConnected,
    connectionInfo.isReconnecting,
    flowGeneratorSessionId,
    groupName,
    signalRConnection
  ]);

  return { groupName, connectionDate, signalRConnection };
};
