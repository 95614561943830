import { makeStyles } from '@material-ui/core/styles';
import { List, FunctionField } from 'react-admin';
import EditIcon from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import styled from 'styled-components';
import { Box, Button } from '@material-ui/core';
import NotificationListDataGrid from './ListComponents/NotificationListDataGrid';
import NotificationListActions from './ListComponents/NotificationListActions';
import NotificationListFilter from './ListComponents/NotificationListFilter';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import SaveConfirmButton from 'Pages/Flows/Components/SaveConfirmButton';
import { useState } from 'react';
import { useNotify, useRefresh } from 'react-admin';
import EditNotificationModal from './EditNotificationModal';
import Api from 'Api/Api';

const useStyles = makeStyles(theme => ({
  root: {
    margin: '12px',
    minHeight: '350px'
  },
  editButton: {
    color: '#4353ff',
    fontSize: '0.8125rem',
    padding: '4px',
    border: 'none',
    background: 'none',
    boxShadow: 'none'
  },
  deleteButton: {
    maxWidth: '150px'
  }
}));

const StyledList = styled(List)`
  .MuiToolbar-root {
    justify-content: flex-start;
  }
`;

const NotificationsList = props => {
  const refresh = useRefresh();
  const notify = useNotify();
  const classes = useStyles();
  const history = useHistory();

  return (
    <Box className={classes.root}>
      <StyledList
        title="Student Notifications"
        filters={<NotificationListFilter />}
        bulkActionButtons={false}
        perPage={999}
        sort={{ field: 'id', order: 'DESC' }}
        actions={<NotificationListActions />}
        {...props}>
        <NotificationListDataGrid rowClick="">
          <FunctionField label="UID" render={r => `${r.id}`} />
          <FunctionField label="SchoolId" render={r => `${r.schoolId}`} />
          <FunctionField label="Title" render={r => r.title} />
          <FunctionField
            label="ActiveFromDate"
            render={r => r.activeFromDate ?? '-'}
          />
          <FunctionField
            label="ActiveToDate"
            render={r => r.activeToDate ?? '-'}
          />
          <EditNotificationButton classes={classes} history={history} />
          <DeleteNotificationButton
            classes={classes}
            history={history}
            refresh={refresh}
            notify={notify}
          />
        </NotificationListDataGrid>
      </StyledList>
    </Box>
  );
};

const EditNotificationButton = ({ record, classes }) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  return (
    <>
      <Button
        variant="contained"
        color="default"
        className={classes.editButton}
        startIcon={<EditIcon />}
        onClick={r => setIsEditModalOpen(true)}>
        Edit
      </Button>
      <EditNotificationModal
        notification={record}
        isOpen={isEditModalOpen}
        setIsOpen={setIsEditModalOpen}
      />
    </>
  );
};

const DeleteNotificationButton = ({
  record,
  classes,
  refresh = () => {},
  notify = () => {}
}) => (
  <SaveConfirmButton
    buttonCopy="Delete"
    confirmCopy="Confirm?"
    className={classes.deleteButton}
    pristine={false}
    icon={<Delete />}
    onClick={async r => {
      try {
        await Api.deleteSchoolNotification(record.id);
        notify('Notification deleted!', 'warning');
        refresh();
      } catch (e) {
        console.log(e);
        notify('Error deleting notification', 'error');
      }
    }}>
    Delete
  </SaveConfirmButton>
);

export default NotificationsList;
