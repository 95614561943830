import { makeStyles } from '@material-ui/core/styles';
import {
  Modal,
  Backdrop,
  TextField,
  Button,
  Select,
  InputLabel,
  MenuItem,
  FormControlLabel,
  Switch,
  Container,
  Card,
  CardContent,
  Typography,
  FormGroup
} from '@material-ui/core';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useCallback, React, useState, useEffect } from 'react';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import { Form, Field } from 'react-final-form';
import { useDataProvider, useNotify, useRefresh } from 'react-admin';
import Api from 'Api/Api';
import ToggleSwitch from 'Components/ToggleSwitch';
import FullPageLoader from 'Components/FullPageLoader';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  create: {
    margin: '1rem 0 0 0',
    overflowY: 'scroll',
    maxHeight: `${window.innerHeight ?? 900}px`
  },
  input: {
    margin: '1rem 0 0 0'
  },
  createButton: {
    margin: '1rem 0 0 0'
  },
  cancelButton: {
    margin: '1rem 0 0 10px'
  },
  select: {
    margin: '1rem 0 0 0',
    width: '100%'
  }
}));

const CreateTopicPathwayQuizModal = ({ isOpen, setIsOpen }) => {
  const notify = useNotify();
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const [countryYearGroups, setCountryYearGroups] = useState([]);
  const [countryCode, setCountryCode] = useState('GB');
  const [topicPathwayCollections, setTopicPathwayCollections] = useState([]);
  const [topicPathwayCollectionId, setTopicPathwayCollectionId] =
    useState(null);
  const [loading, setLoading] = useState(true);

  const createTopicPathwayQuiz = useCallback(
    async values => {
      try {
        await dataProvider.create('topic-pathway-quizs', {
          query: { topicPathwayCollectionId: values.topicPathwayCollectionId },
          body: values
        });
        refresh();
        setIsOpen();
        notify('Topic Created!', 'success');
      } catch (e) {
        notify('Error creating topic!', 'error');
        console.error(e);
      }
    },
    [dataProvider, notify, refresh, setIsOpen]
  );

  const onChangeCollection = useCallback(
    async collectionId => {
      try {
        const collection = topicPathwayCollections?.find(
          t => t.topicPathwayCollectionId === collectionId
        );

        if (!collection) return;

        setTopicPathwayCollectionId(collectionId);

        // regex to find the last two characters of the locale e.g. en-GB -> GB
        const newCountryCode = !collection.locale?.length
          ? 'GB'
          : collection.locale.match(/.{2}$/)[0];

        if (countryCode !== newCountryCode) {
          setCountryCode(newCountryCode);
          const countryYearGroups = await Api.getCountryYearGroups(
            newCountryCode
          );
          setCountryYearGroups(countryYearGroups);
        }
      } catch (e) {
        console.error(e);
      }
    },
    [countryCode, topicPathwayCollections]
  );

  useEffect(() => {
    if (!loading) return;
    const runEffect = async () => {
      const topicPathwayCollections = await Api.getAllTopicPathwayCollections(
        []
      );
      setCountryYearGroups(await Api.getCountryYearGroups(countryCode));
      setTopicPathwayCollections(topicPathwayCollections);
      setLoading(false);
    };
    runEffect();
  }, [countryCode, loading]);

  if (loading) return <FullPageLoader loading={true} />;

  return (
    <Modal
      open={isOpen}
      onClose={() => setIsOpen()}
      className={classes.modal}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
      closeAfterTransition>
      <Container maxWidth="md">
        <Card className={classes.create}>
          <CardContent>
            <Typography variant="h5" gutterBottom>
              Create Topic
            </Typography>
            <Form
              onSubmit={createTopicPathwayQuiz}
              initialValues={{
                level: null,
                sequence: null,
                fact: null,
                subjectId: null,
                isDisabled: false,
                countryYearGroupIds: [],
                topicPathwayCollectionId
              }}
              validate={values => {
                const errors = {};
                if (isNaN(parseInt(values.quizId, 10))) {
                  errors.level = 'Please enter a valid quizId';
                }
                if (
                  isNaN(parseInt(values.level, 10)) ||
                  parseInt(values.level, 10) <= -1
                ) {
                  errors.level = 'Please enter a level from 0+';
                }
                if (
                  isNaN(parseInt(values.sequence, 10)) ||
                  parseInt(values.sequence, 10) <= 0
                ) {
                  errors.sequence = 'Please enter a sequence from 1+';
                }
                if (!values.name) {
                  errors.name = 'Please enter a name for the topic';
                }
                if (!values.subjectId) {
                  errors.subjectId = 'Please enter a valid subjectId';
                }
                if (!values.topicPathwayCollectionId) {
                  errors.topicPathwayCollectionId =
                    'Please enter a valid topic pathway collection Id';
                }
                if (
                  !values.countryYearGroupIds ||
                  values.countryYearGroupIds.length === 0
                ) {
                  errors.countryYearGroupIds =
                    'Please enter a valid country year group';
                }
                return errors;
              }}>
              {({
                handleSubmit,
                submitting,
                pristine,
                submitError,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  <FormGroup>
                    <Field name="topicPathwayCollectionId">
                      {({ input, meta }) => (
                        <>
                          <InputLabel
                            required
                            id="topic-label"
                            className={classes.select}>
                            TopicPathwayCollection
                          </InputLabel>
                          <Select
                            {...input}
                            name="topicPathwayCollectionId"
                            labelId="topic-label"
                            error={meta.error && meta.touched}
                            onChange={async e => {
                              await onChangeCollection(e.target.value);
                            }}>
                            {topicPathwayCollections?.map((c, idx) => (
                              <MenuItem
                                key={idx}
                                value={c.topicPathwayCollectionId}>
                                {c.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </>
                      )}
                    </Field>
                  </FormGroup>
                  <FormGroup>
                    <Field name="name">
                      {({ input, meta }) => (
                        <TextField
                          {...input}
                          id="standard-basic"
                          label="Topic Name"
                          variant="filled"
                          className={classes.input}
                          error={meta.error && meta.touched}
                          helperText={
                            meta.error && meta.touched ? meta.error : null
                          }
                        />
                      )}
                    </Field>
                  </FormGroup>
                  <FormGroup>
                    <Field name="quizId">
                      {({ input, meta }) => (
                        <TextField
                          {...input}
                          id="standard-number"
                          label="QuizId"
                          type="number"
                          className={classes.input}
                          error={meta.error && meta.touched}
                          helperText={
                            meta.error && meta.touched ? meta.error : null
                          }
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                      )}
                    </Field>
                  </FormGroup>
                  <FormGroup>
                    <Field name="subjectId">
                      {({ input, meta }) => (
                        <>
                          <InputLabel
                            required
                            id="subject-label"
                            className={classes.select}>
                            Subject
                          </InputLabel>
                          <Select
                            {...input}
                            name="Subject"
                            labelId="subject-label"
                            error={meta.error && meta.touched}>
                            {[
                              { key: 'Number', value: 32 },
                              { key: 'Data & Statistics', value: 101 },
                              { key: 'Geometry', value: 71 },
                              { key: 'Algebra', value: 49 }
                            ].map((c, idx) => (
                              <MenuItem key={idx} value={c.value}>
                                {c.key}
                              </MenuItem>
                            ))}
                          </Select>
                        </>
                      )}
                    </Field>
                  </FormGroup>
                  <FormGroup>
                    <Field name="countryYearGroupIds">
                      {({ input, meta }) => (
                        <>
                          <InputLabel
                            required
                            id="country-year-group-label"
                            className={classes.select}>
                            Year Group
                          </InputLabel>
                          <Select
                            {...input}
                            multiple
                            name="CountryYearGroupIds"
                            labelId="country-year-group-label"
                            error={meta.error && meta.touched}>
                            {countryYearGroups
                              ?.sort((a, b) => {
                                const numA = parseInt(
                                  a.name.match(/\d+/)[0],
                                  10
                                );
                                const numB = parseInt(
                                  b.name.match(/\d+/)[0],
                                  10
                                );
                                return numA - numB;
                              })
                              ?.map(c => (
                                <MenuItem
                                  key={c.countryYearGroupId}
                                  value={c.countryYearGroupId}>
                                  {c.name}
                                </MenuItem>
                              ))}
                          </Select>
                        </>
                      )}
                    </Field>
                  </FormGroup>
                  <FormGroup>
                    <Field name="level">
                      {({ input, meta }) => (
                        <TextField
                          {...input}
                          id="standard-number"
                          label="Level"
                          type="number"
                          className={classes.input}
                          error={meta.error && meta.touched}
                          helperText={
                            meta.error && meta.touched ? meta.error : null
                          }
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                      )}
                    </Field>
                  </FormGroup>
                  <FormGroup>
                    <Field name="sequence">
                      {({ input, meta }) => (
                        <TextField
                          {...input}
                          id="standard-number"
                          label="Sequence"
                          type="number"
                          className={classes.input}
                          error={meta.error && meta.touched}
                          helperText={
                            meta.error && meta.touched ? meta.error : null
                          }
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                      )}
                    </Field>
                  </FormGroup>
                  <FormGroup>
                    <Field name="fact">
                      {({ input, meta }) => (
                        <TextField
                          {...input}
                          id="standard-basic"
                          label="Interesting Fact (optional)"
                          variant="filled"
                          className={classes.input}
                          error={meta.error && meta.touched}
                          helperText={
                            meta.error && meta.touched ? meta.error : null
                          }
                        />
                      )}
                    </Field>
                  </FormGroup>
                  <FormGroup>
                    <Field
                      name="isDisabled"
                      label="Enabled"
                      component={EnabledToggle}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Field
                      name="isInterventionsEnabled"
                      label="Interventions Enabled"
                      component={props => (
                        <ToggleSwitch invert={false} {...props} />
                      )}
                    />
                  </FormGroup>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    type="submit"
                    className={classes.createButton}
                    startIcon={<SaveIcon />}
                    disabled={submitting || pristine || submitError}>
                    Create
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    className={classes.cancelButton}
                    onClick={() => setIsOpen()}
                    startIcon={<CancelIcon />}>
                    Cancel
                  </Button>
                </form>
              )}
            </Form>
          </CardContent>
        </Card>
      </Container>
    </Modal>
  );
};

const EnabledToggle = ({ input: { onChange, value }, label, ...rest }) => {
  return (
    <FormControlLabel
      control={
        <Switch
          {...rest}
          checked={!value}
          onChange={() => onChange(value === false)}
        />
      }
      label={label}
    />
  );
};

export default CreateTopicPathwayQuizModal;
