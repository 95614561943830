import { set } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const ToolButton = ({
  preIcon,
  text,
  postIcon,
  onClick,
  children,
  tooltip,
  isHold = false,
  disabled = false,
  selected = false,
  ...props
}) => {
  const intervalRef = useRef(null);
  const [holding, setHolding] = useState(false);

  const handleMouseDown = useCallback(() => {
    onClick();
    if (isHold) {
      setHolding(true);
    }
  }, [isHold, onClick]);

  const handleMouseUp = useCallback(() => {
    setHolding(false);
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  }, []);

  useEffect(() => {
    if (disabled) {
      setHolding(false);
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    }
    if (isHold && holding) {
      intervalRef.current = setInterval(() => {
        onClick();
      }, 100);
    }
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [isHold, onClick, holding, disabled]);

  return (
    <StyledButton
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onTouchStart={handleMouseDown}
      onTouchEnd={handleMouseUp}
      title={tooltip}
      disabled={disabled}
      selected={selected}
      {...props}>
      {preIcon ? (
        <IconBox>
          <box-icon name={preIcon} {...props} />
        </IconBox>
      ) : null}
      {text}
      {children}
      {postIcon ? (
        <IconBox>
          <box-icon name={postIcon} {...props} />
        </IconBox>
      ) : null}
    </StyledButton>
  );
};

const IconBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
`;

const StyledButton = styled.button`
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid #ccc;
  padding: 3px 5px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.2s ease;
  margin: 0 0.25rem 0.1rem 0.25rem;
  width: 40px;
  height: 40px;
  font-size: 0.8rem;

  &:hover {
    border-color: darkgrey;
    cursor: pointer;
  }

  &:active {
    transform: translateY(2px);
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  }

  // Disabled style
  ${({ disabled }) =>
    disabled &&
    `
    background-color: #f0f0f0;
    color: #ccc;
    cursor: not-allowed;
    border-color: #ccc;
  `}

  // Selected style
  ${({ selected }) =>
    selected &&
    `
    background-color: #f0f0f0;
    color: #333;
    border: 2px solid #ccc;
  `}
`;

export default ToolButton;
