import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  InputLabel
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Api from 'Api/Api';
import { MetadataContext } from 'Providers/MetadataProvider';
import { useNotify, Notification } from 'react-admin';
import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  textInput: {
    marginBottom: '0.2rem',
    width: '100%'
  },
  select: {
    marginBottom: '0.2rem',
    width: '100%'
  }
}));

const AddExistingSchoolCard = ({ studentId, ...props }) => {
  const notify = useNotify();
  const classes = useStyles();
  const history = useHistory();
  const { countries } = useContext(MetadataContext);

  const [country, setCountry] = useState('');
  const [schoolSearch, setSchoolSearch] = useState();

  const [searching, setSearching] = useState(false);
  const [schoolOptions, setSchoolOptions] = useState();
  const [schoolSelection, setSchoolSelection] = useState();

  const handleSearch = async () => {
    try {
      setSearching(true);

      let query = {
        'request.filter.searchText': schoolSearch,
        'request.skip': 0,
        'request.take': 20
      };

      const results = await Api.request(
        'GET',
        `v3/countries/${country.countryId}/schools`,
        query
      );

      setSchoolOptions(results);
      setSearching(false);
    } catch (e) {
      console.error(e);
      notify(
        'There had been issue when trying to search for exisiting schools',
        'error'
      );
    }
  };

  const handleJoin = async () => {
    try {
      const { teamSchoolId } = schoolSelection;
      await Api.request(
        'PUT',
        `v3/students/${studentId}/team-school?teamSchoolId=${teamSchoolId}`
      );
      history.push('/requests');
    } catch (e) {
      console.error(e);
      notify(
        'There had been issue when trying to join student to school',
        'error'
      );
    }
  };

  const handleChangeCountry = e => {
    setCountry(e.target.value);
  };

  const handleChangeSchool = e => {
    setSchoolSelection(e.target.value);
  };

  return (
    <Card variant="outlined" {...props}>
      <CardHeader title="Add to exisitng school" />
      <CardContent>
        <Typography className={classes.title}>
          1. Search for existing
        </Typography>
        <div>
          <InputLabel required id="country-label">
            Country
          </InputLabel>
          <Select
            className={classes.select}
            name="country"
            labelId="country-label"
            value={country}
            onChange={handleChangeCountry}>
            {countries.map((c, idx) => (
              <MenuItem key={idx} value={c}>
                {c.name}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div>
          <InputLabel required id="search-school-label">
            School name
          </InputLabel>
          <TextField
            labelId="search-school-label"
            className={classes.textInput}
            onBlur={e => setSchoolSearch(e.target.value)}></TextField>
        </div>
        <div>
          <Button
            color="primary"
            disabled={!schoolSearch || !country}
            onClick={handleSearch}>
            Search
          </Button>
        </div>

        {searching ? (
          <CircularProgress />
        ) : (
          <>
            {schoolOptions?.length === 0 && (
              <Typography>No Results. Try again!</Typography>
            )}

            {schoolOptions?.length > 0 && (
              <div>
                <Typography className={classes.title}>2. Pick one!</Typography>
                <InputLabel required id="school-label">
                  Existing schools
                </InputLabel>
                <Select
                  className={classes.select}
                  name="school"
                  labelId="school-label"
                  value={schoolSelection}
                  onChange={handleChangeSchool}>
                  {schoolOptions.map((s, idx) => (
                    <MenuItem key={idx} value={s}>
                      <Grid container direction="column">
                        <>
                          <div>
                            <Typography
                              component="span"
                              variant="body2"
                              color="textPrimary">
                              Name -{' '}
                            </Typography>
                            {s.name ?? '[ Not Entered ]'}
                          </div>
                          <div>
                            <Typography
                              component="span"
                              variant="body2"
                              color="textPrimary">
                              City -{' '}
                            </Typography>
                            {s.city ?? '/'}
                          </div>
                          <div>
                            <Typography
                              component="span"
                              variant="body2"
                              color="textPrimary">
                              URL -{' '}
                            </Typography>
                            {s.url ?? '/'}
                          </div>
                          <div>
                            <Typography
                              component="span"
                              variant="body2"
                              color="textPrimary">
                              Zip/PostCode -{' '}
                            </Typography>
                            {s.url ?? '/'}
                          </div>
                        </>
                      </Grid>
                    </MenuItem>
                  ))}
                </Select>
                <Button
                  color="primary"
                  disabled={!schoolSelection}
                  onClick={handleJoin}>
                  Join this school
                </Button>
              </div>
            )}
          </>
        )}
      </CardContent>
      <Notification />
    </Card>
  );
};

export default AddExistingSchoolCard;
