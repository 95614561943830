import {
  Modal,
  Backdrop,
  Container,
  Card,
  CardContent,
  TextField,
  Button,
  FormGroup
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Api from 'Api/Api';
import { useCallback, useState } from 'react';
import { useNotify, useRefresh } from 'react-admin';
import { Field, Form } from 'react-final-form';
import MergeTypeIcon from '@material-ui/icons/MergeType';
import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
    margin: 0
  },
  content: {
    margin: 0,
    padding: 0,
    //overflowY: 'scroll',
    //minHeight: 'calc(100vh - 80px)',
    maxHeight: `${window.innerHeight ?? 900}px`
  },
  loading: {
    margin: '0 auto',
    textAlign: 'center',
    padding: '20px'
  },
  textField: {
    margin: '0.25rem 0 0.25rem 0'
  },
  cancelButton: {
    margin: '0.5rem 0 0 0.5rem'
  },
  mergeButton: {
    margin: '0.5rem 0 0'
  },
  form: {
    padding: '0'
  },
  root: {
    padding: '1rem'
  }
}));

const StudentMergeModal = ({ students, setIsOpen, isOpen }) => {
  const [isLoading, setIsLoading] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();
  const classes = useStyles();

  const onMerge = useCallback(
    async values => {
      if (isLoading) return;

      setIsLoading(true);
      try {
        await Api.mergeClassroomStudents(
          values.fromUserName,
          values.toUserName,
          values.toSchoolId
        );
        notify('Students merged', 'success');
        refresh();
      } catch (e) {
        notify(`Failed to merge students: ${e}`, 'error');
      }
      setIsLoading(false);
    },
    [isLoading, notify, refresh]
  );

  return (
    <Modal
      className={classes.modal}
      open={isOpen}
      onClose={() => setIsOpen(false)}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
      closeAfterTransition>
      <Container maxWidth="sm">
        <Card className={classes.create}>
          <CardContent className={classes.content}>
            <div className={classes.root}>
              <h3>Merge Students</h3>
              {isLoading ? (
                <div className={classes.loading}>
                  <h2 style={{ margin: '0 auto' }}>
                    Merging... Please wait, this may take a moment...
                  </h2>
                  <div style={{ width: '100%' }}>
                    <img
                      style={{
                        textAlign: 'center',
                        margin: '0 auto',
                        height: '150px',
                        width: 'auto',
                        display: 'flex'
                      }}
                      src="/assets/loady.gif"
                      alt="loading!"
                    />
                  </div>
                </div>
              ) : (
                <Form
                  onSubmit={onMerge}
                  initialValues={{}}
                  validate={values => {
                    const errors = {};
                    if (!values.fromUserName) {
                      errors.fromUserName = 'Please enter a username';
                    }
                    if (!values.toUserName) {
                      errors.toUserName = 'Please enter a username';
                    }
                    if (
                      !!values.fromUserName?.length &&
                      values.fromUserName === values.toUserName
                    ) {
                      errors.toUserName = 'Please enter a different username';
                    }
                    if (!values.toSchoolId) {
                      errors.toSchoolId = 'Please enter a schoolId';
                    }
                    return errors;
                  }}>
                  {({
                    handleSubmit,
                    submitting,
                    pristine,
                    submitError,
                    values
                  }) => (
                    <form onSubmit={handleSubmit} className={classes.form}>
                      <FormGroup>
                        <Field name="fromUserName">
                          {({ input, meta }) => (
                            <TextField
                              {...input}
                              id="standard-basic"
                              label="FROM Username"
                              variant="filled"
                              className={classes.textField}
                              error={meta.error && meta.touched}
                              helperText={
                                meta.error && meta.touched ? meta.error : null
                              }
                            />
                          )}
                        </Field>
                      </FormGroup>
                      <FormGroup>
                        <Field name="toUserName">
                          {({ input, meta }) => (
                            <TextField
                              {...input}
                              id="standard-basic"
                              label="TO Username"
                              variant="filled"
                              className={classes.textField}
                              error={meta.error && meta.touched}
                              helperText={
                                meta.error && meta.touched ? meta.error : null
                              }
                            />
                          )}
                        </Field>
                      </FormGroup>
                      <FormGroup>
                        <Field name="toSchoolId">
                          {({ input, meta }) => (
                            <TextField
                              {...input}
                              id="standard-basic"
                              label="TO SchoolId"
                              variant="filled"
                              type="number"
                              className={classes.textField}
                              error={meta.error && meta.touched}
                              helperText={
                                meta.error && meta.touched ? meta.error : null
                              }
                            />
                          )}
                        </Field>
                      </FormGroup>
                      <Button
                        variant="contained"
                        color="primary"
                        size="medium"
                        type="submit"
                        className={classes.mergeButton}
                        startIcon={<MergeTypeIcon />}
                        disabled={submitting || pristine || submitError}>
                        Merge
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        size="medium"
                        className={classes.cancelButton}
                        onClick={() => setIsOpen(false)}
                        startIcon={<CancelIcon />}
                        disabled={submitting}>
                        Cancel
                      </Button>
                    </form>
                  )}
                </Form>
              )}
            </div>
          </CardContent>
        </Card>
      </Container>
    </Modal>
  );
};

export default StudentMergeModal;
