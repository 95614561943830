import { Layout, setSidebarVisibility } from 'react-admin';
import SidebarMenu from './SidebarMenu';
import AppBar from './AppBar';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

const StyledLayout = styled(Layout)`
  div[class*='MuiDrawer-paper'] > div {
    position: relative;
  }
`;

const CustomLayout = props => {
  const dispatch = useDispatch();
  dispatch(setSidebarVisibility(false));
  return <StyledLayout {...props} menu={SidebarMenu} appBar={AppBar} />;
};

export default CustomLayout;
