import {
  useListContext,
  TopToolbar,
  ExportButton,
  sanitizeListRestProps,
  useNotify,
  useRefresh
} from 'react-admin';
import { Form, Field } from 'react-final-form';
import { useCallback, useEffect, useState } from 'react';
import {
  FormGroup,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  InputAdornment,
  FilledInput,
  IconButton,
  Button
} from '@material-ui/core';
import Api from 'Api/Api';
import debounce from 'Helpers/debounce';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import filterHelper from 'Helpers/filterHelper';

const useStyles = makeStyles(theme => ({
  root: {
    justifyContent: 'space-between',
    width: '100%'
  },
  exportButton: {
    display: 'flex'
  },
  form: {
    display: 'flex',
    width: '100%'
  },
  select: {
    minHeight: '40px',
    margin: '0 0 0 10px',
    display: 'flex',
    alignSelf: 'flex-start',
    minWidth: '200px'
  },
  selectLabel: {
    margin: '0 0 0 10px'
  },
  enrollButton: {
    margin: '0 0 0 1rem'
  }
}));

const UserSubscriptionsListActions = props => {
  const classes = useStyles();
  const { className, maxResults, ...rest } = props;
  const { total, filterValues, setFilters } = useListContext();
  const refresh = useRefresh();
  const [schools, setSchools] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState(
    filterValues?.schoolId?.Value ?? null
  );
  const [searchValue, setSearchValue] = useState('');
  const [schoolGroups, setSchoolGroups] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isEnrolling, setIsEnrolling] = useState(false);
  const notify = useNotify();

  const savefilters = useCallback(
    updatedFilterValues => {
      setFilters(updatedFilterValues);
    },
    [setFilters]
  );

  const enrollAllStudents = useCallback(async () => {
    try {
      if (!selectedSchool || isEnrolling) {
        notify('Enrollment in progress...please wait...', 'warning');
      }
      notify('Enrollment in progress, this may take a few minutes!', 'warning');
      setIsEnrolling(true);
      let response = await Api.bulkEnrollClassroomStudents(selectedSchool, {
        filter: filterHelper.getQueryFilter(filterValues)
      });

      const completedEnrollmentsCount = response?.enrollmentStatuses?.reduce(
        (prev, curr) => prev + (curr?.isEnrolled ? 1 : 0),
        0
      );

      const erroredEnrollmentsCount = response?.enrollmentStatuses?.reduce(
        (prev, curr) => prev + (!curr?.isEnrolled ? 1 : 0),
        0
      );

      let status =
        `Enrollment completed!\n\n` +
        `Enrolled ${completedEnrollmentsCount} out of ${response?.enrollmentStatuses?.length} students in selection.\n\n`;

      if (erroredEnrollmentsCount) {
        status +=
          `Some enrollments had errors e.g:\n\n` +
          `${
            response.enrollmentStatuses.find(e => !e.isEnrolled && e.errors)
              ?.errors
          }`;
      }

      notify(status, 'success');
    } catch (e) {
      console.error(e);
      notify('Enrollment failed!', 'error');
    }
    setIsEnrolling(false);
    refresh();
  }, [filterValues, isEnrolling, notify, refresh, selectedSchool]);

  const fetchHybridSchools = useCallback(async () => {
    try {
      const schools = await Api.getAllHybridSchools();
      if (!!schools?.length) {
        setSchools(schools);
      }
    } catch (e) {
      console.error(e);
    }
  }, []);

  const fetchGroups = useCallback(async schoolId => {
    try {
      if (!schoolId) return;

      const groups = await Api.getAllSchoolGroups(schoolId);

      if (groups?.length) {
        setSchoolGroups(() => ({ schoolId, groups }));
      }
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    const runEffect = async () => {
      if (!isLoaded) {
        await fetchHybridSchools();
        setIsLoaded(true);
      }
      if (
        isLoaded &&
        !!selectedSchool &&
        selectedSchool !== schoolGroups?.schoolId
      ) {
        await fetchGroups(selectedSchool);
      }
      if (!!searchValue && filterValues?.q !== searchValue) {
        debounce(savefilters({ ...filterValues, ...{ q: searchValue } }), 1000);
      }
    };
    runEffect();
  }, [
    fetchGroups,
    fetchHybridSchools,
    isLoaded,
    schoolGroups?.schoolId,
    selectedSchool,
    filterValues,
    searchValue,
    savefilters
  ]);

  const onSubmit = values => {
    if (Object.keys(values).length > 0) {
      savefilters({ ...filterValues, ...values });
    }
  };

  const selectFilter = useCallback(
    value => {
      if (!value) return;
      const filter = {};
      filter[value.id] = value;

      if (value.id === 'schoolId') {
        setSelectedSchool(value.Value);
      }

      savefilters({ ...filterValues, ...filter });
    },
    [filterValues, savefilters]
  );

  const getFormValues = () => {
    const formValues = {
      q: searchValue,
      schoolId: '',
      groupId: ''
    };
    for (const [key, value] of Object.entries(filterValues)) {
      if (key !== 'q') {
        formValues[key] = value.Value;
      }
    }
    return formValues;
  };

  return (
    <TopToolbar className={classes.root} {...sanitizeListRestProps(rest)}>
      <Form onSubmit={onSubmit} initialValues={getFormValues()}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className={classes.form}>
            <FormControl variant="filled">
              <InputLabel>Search</InputLabel>
              <Field name="q">
                {({ input, meta }) => {
                  return (
                    <FilledInput
                      disabled={isEnrolling}
                      type="text"
                      {...input}
                      className={classes.searchInput}
                      onChange={event => setSearchValue(event?.target?.value)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => {
                              savefilters({});
                              setSearchValue('');
                              setSelectedSchool(null);
                              setSchoolGroups(null);
                            }}
                            onMouseDown={() => {}}
                            edge="end">
                            {!null ? <ClearIcon /> : <SearchIcon />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  );
                }}
              </Field>
            </FormControl>
            <FormGroup>
              <InputLabel className={classes.selectLabel}>
                Hybrid School
              </InputLabel>
              <Field name="schoolId">
                {({ input, meta }) => (
                  <Select
                    disabled={isEnrolling}
                    className={classes.select}
                    {...input}
                    onChange={r => {
                      selectFilter({
                        id: 'schoolId',
                        Value: r?.target?.value,
                        Operator: 'Eq',
                        Type: 'int'
                      });
                    }}>
                    {schools
                      .map(s => ({
                        value: s.schoolName,
                        key: s.schoolId
                      }))
                      .map((s, idx) => (
                        <MenuItem key={idx} value={s.key}>
                          {s.value}
                        </MenuItem>
                      ))}
                  </Select>
                )}
              </Field>
            </FormGroup>
            {schoolGroups?.groups?.length > 0 && (
              <>
                <FormGroup>
                  <InputLabel className={classes.selectLabel}>
                    Hybrid Group
                  </InputLabel>
                  <Field name="groupId">
                    {({ input, meta }) => (
                      <Select
                        disabled={isEnrolling}
                        className={classes.select}
                        {...input}
                        onChange={r => {
                          selectFilter({
                            id: 'groupId',
                            Value: r?.target?.value,
                            Operator: 'Eq',
                            Type: 'int'
                          });
                        }}>
                        {schoolGroups?.groups
                          ?.map(s => ({
                            value: s.code,
                            key: s.groupId
                          }))
                          .map((s, idx) => (
                            <MenuItem key={idx} value={s.key}>
                              {s.value}
                            </MenuItem>
                          ))}
                      </Select>
                    )}
                  </Field>
                </FormGroup>
                <FormGroup>
                  <Button
                    disabled={isEnrolling}
                    variant="contained"
                    color="primary"
                    className={classes.enrollButton}
                    onClick={async () => await enrollAllStudents()}>
                    {!!getFormValues()?.groupId
                      ? 'Enroll Group Students'
                      : 'Enroll School Students'}
                  </Button>
                </FormGroup>
              </>
            )}
          </form>
        )}
      </Form>
      <ExportButton
        className={classes.exportButton}
        disabled={total === 0}
        maxResults={maxResults}
      />
    </TopToolbar>
  );
};

export default UserSubscriptionsListActions;
