import { scaleCalcAll } from './imageHelpers';

const gridColumns = ['A', 'B', 'C', 'D', 'E', 'F'];

const metaDataTags = [
  { label: 'Answer A Image', type: 'Answer A' },
  { label: 'Answer B Image', type: 'Answer B' },
  { label: 'Answer C Image', type: 'Answer C' },
  { label: 'Answer C Image', type: 'Answer D' },
  { label: 'Answer A Text', type: 'Answer A' },
  { label: 'Answer B Text', type: 'Answer B' },
  { label: 'Answer C Text', type: 'Answer C' },
  { label: 'Answer D Text', type: 'Answer D' },
  { label: 'Question Image', type: 'Question' },
  { label: 'Question Text', type: 'Question' }
];

export const getQuestionMetaDataProgress = questionMetaDataTags => [
  ...new Set(
    metaDataTags
      .map(metaDataTag => {
        const metaData = questionMetaDataTags.find(
          m => m.label === metaDataTag.label
        );
        return !!metaData ? metaDataTag.type : null;
      })
      .filter(m => !!m)
  )
];

const getRange = (range, boundingBoxes) => {
  const [start, end] = range.split(':');
  const [startCol, startRow] = start.split('-');
  const [endCol, endRow] = end.split('-');
  const startColumn = gridColumns.indexOf(startCol);
  const endColumn = gridColumns.indexOf(endCol);
  return boundingBoxes.filter(b => {
    const [col, row] = b.rectangleId.split('-');
    const boundingColumn = gridColumns.indexOf(col);
    return (
      boundingColumn >= startColumn &&
      boundingColumn <= endColumn &&
      row >= startRow &&
      row <= endRow
    );
  });
};

const getRules = defaultWidth => [
  {
    range: 'A-1:F-3',
    label: 'Question Image',
    minWidth: (40 / 100) * defaultWidth
  },
  {
    range: 'A-1:F-3',
    label: 'Question Text',
    minWidth: (10 / 100) * defaultWidth
  },
  {
    range: 'B-4:B-4',
    label: 'Answer A Text',
    minWidth: (10 / 100) * defaultWidth,
    maxCount: 1
  },
  {
    range: 'A-5:A-5',
    label: 'Answer A Text',
    minWidth: (5 / 100) * defaultWidth,
    maxCount: 1
  },
  {
    range: 'B-5:B-5',
    label: 'Answer B Text',
    minWidth: (5 / 100) * defaultWidth,
    maxCount: 1
  },
  {
    range: 'E-4:E-4',
    label: 'Answer B Text',
    minWidth: (5 / 100) * defaultWidth,
    maxCount: 1
  },
  {
    range: 'C-5:C-5',
    label: 'Answer B Text',
    minWidth: (5 / 100) * defaultWidth,
    maxCount: 1
  },
  {
    range: 'B-5:B-5',
    label: 'Answer C Text',
    minWidth: (10 / 100) * defaultWidth,
    maxCount: 1
  },
  {
    range: 'D-5:D-5',
    label: 'Answer C Text',
    minWidth: (5 / 100) * defaultWidth,
    maxCount: 1
  },
  {
    range: 'E-4:E-4',
    label: 'Answer C Text',
    minWidth: (5 / 100) * defaultWidth,
    maxCount: 1
  },
  {
    range: 'F-5:F-5',
    label: 'Answer D Text',
    minWidth: (5 / 100) * defaultWidth,
    maxCount: 1
  }
];

function intersectionArea(rect1, rect2) {
  const xOverlap = Math.max(
    0,
    Math.min(rect1.x + rect1.width, rect2.x + rect2.width) -
      Math.max(rect1.x, rect2.x)
  );
  const yOverlap = Math.max(
    0,
    Math.min(rect1.y + rect1.height, rect2.y + rect2.height) -
      Math.max(rect1.y, rect2.y)
  );
  return xOverlap * yOverlap;
}

// intersectionArea as a percentage
function intersectionAreaPercentage(rect1, rect2) {
  const intersection = intersectionArea(rect1, rect2);
  const area1 = rect1.width * rect1.height;
  const area2 = rect2.width * rect2.height;
  return (intersection / (area1 + area2 - intersection)) * 100;
}

function getBoundingBoxes(defaultWidth, sourceWidth) {
  const boundingBoxes = [];
  const scale = defaultWidth / sourceWidth;
  gridColumns.forEach((column, i) => {
    const parentBoundingBox = {
      rectangleId: `${column}-1`,
      x: i === 0 ? 0 : i * (defaultWidth / gridColumns.length),
      y: 0,
      width: defaultWidth / gridColumns.length,
      height: ((defaultWidth / 4) * 3) / gridColumns.length,
      hexColor: '#000000',
      scale
    };
    boundingBoxes.push(parentBoundingBox);
    for (let index = 0; index < gridColumns.length; index++) {
      const childBoundingBox = {
        rectangleId: `${column}-${index + 2}`,
        x: parentBoundingBox.x,
        y: parentBoundingBox.y + (index + 1) * parentBoundingBox.height,
        width: parentBoundingBox.width,
        height: parentBoundingBox.height,
        hexColor: '#000000',
        scale
      };
      boundingBoxes.push(childBoundingBox);
    }
  });
  return boundingBoxes;
}

export const applyQuestionMetadataTags = (
  metaData,
  metaDataTags,
  defaultWidth = 900,
  sourceWidth = 1024
) => {
  const boundingBoxes = getBoundingBoxes(defaultWidth, sourceWidth);

  metaData.forEach(rect => {
    let maxOverlap = 0;
    let maxOverlapLabel = '';

    for (let row = 0; row < gridColumns.length; row++) {
      for (let col = 1; col <= gridColumns.length; col++) {
        // Define the current grid cell
        const cell = boundingBoxes.find(
          b => b.rectangleId === `${gridColumns[row]}-${col}`
        );

        if (!cell) continue;

        // Calculate the intersection area between the rectangle and the grid cell
        const overlapPercentage = intersectionAreaPercentage(
          scaleCalcAll(
            sourceWidth,
            defaultWidth,
            rect.x,
            rect.y,
            rect.width,
            rect.height,
            rect.scale
          ),
          scaleCalcAll(
            sourceWidth,
            defaultWidth,
            cell.x,
            cell.y,
            cell.width,
            cell.height,
            defaultWidth / sourceWidth
          )
        );

        // Update the maximum overlap and the corresponding label
        if (overlapPercentage > maxOverlap) {
          maxOverlap = overlapPercentage;
          maxOverlapLabel = cell.rectangleId;
        }
      }
    }

    // Assign the label with the highest overlap to the rectangle
    rect.position = {
      label: maxOverlapLabel,
      overlapPercentage: maxOverlap
    };
  });

  getRules(defaultWidth).forEach(rule => {
    const metaDataTag = metaDataTags?.find(m => m.label === rule.label);

    if (!metaDataTag) return;

    const range = getRange(rule.range, boundingBoxes);
    const metaDataInRule = metaData
      .filter(
        m =>
          range.find(r => r.rectangleId === m.position.label) &&
          m.width >= rule.minWidth &&
          !m.dateCreated
      )
      .sort((a, b) => a.x - b.x)
      .sort((a, b) => a.y - b.y);

    if (metaDataInRule.length === 0) return;

    let taggedCount = 0;

    metaDataInRule.forEach(m => {
      if (taggedCount >= rule.maxCount) return;
      m.metaDataTagId = metaDataTag.metaDataTagId;
      m.hexColor = metaDataTag.hexColor;
      m.metaDataTag = metaDataTag;
      taggedCount++;
    });
  });

  return metaData;
};
