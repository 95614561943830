import { DatagridBody, Datagrid, useListContext } from 'react-admin';
import LoadingModal from './LoadingModal';

const EmbeddingOutputDataGrid = props => {
  const { loading } = useListContext();

  return (
    <>
      <LoadingModal open={loading} title="Loading..." subTitle="Please wait" />
      <Datagrid {...props} body={<DatagridBody />} />
    </>
  );
};

export default EmbeddingOutputDataGrid;
