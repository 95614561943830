import { makeStyles } from '@material-ui/core/styles';
import {
  Modal,
  Backdrop,
  TextField,
  Button,
  Container,
  CardContent,
  Typography,
  FormGroup
} from '@material-ui/core';
import ChatIcon from '@material-ui/icons/Chat';
import CancelIcon from '@material-ui/icons/Cancel';
import Api from 'Api/Api';
import { useParams } from 'react-router-dom';
import { useNotify } from 'react-admin';
import { Form, Field } from 'react-final-form';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  create: {
    margin: '1rem 0 0 0',
    overflowY: 'scroll',
    maxHeight: `${window.innerHeight ?? 900}px`
  },
  gptLoading: {
    backgroundImage: 'url(/assets/gptloading.gif)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '40px',
    width: '40px',
    float: 'right'
  },
  input: {
    margin: '1rem 0 0 0'
  },
  container: {
    background: '#fff'
  },
  createButton: {
    margin: '1rem 0 0 0'
  },
  cancelButton: {
    margin: '1rem 0 0 10px'
  }
}));

const AssistantModal = ({ isOpen = false, setIsOpen = () => {} }) => {
  const notify = useNotify();
  const classes = useStyles();
  let { flowGeneratorSessionId } = useParams();

  const startAssistant = async values => {
    try {
      const response = await Api.startTutorsAssistant({
        flowGeneratorSessionId,
        ...values
      });

      if (!response) throw new Error('No session id found');

      setIsOpen(false);
      window.open(`/conversations/${response.flowGeneratorSessionId}/show`);
    } catch (e) {
      console.error(e);
      notify('Error starting assistant!', 'error');
    }
  };

  return (
    <Modal
      open={isOpen}
      className={classes.modal}
      onClose={() => setIsOpen(false)}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
      closeAfterTransition>
      <Container maxWidth="md" className={classes.container}>
        <CardContent>
          <Typography variant="h5" gutterBottom>
            Edit Prompt
          </Typography>
          <Form
            onSubmit={startAssistant}
            initialValues={{
              input: ''
            }}
            validate={values => {
              const errors = {};
              return errors;
            }}>
            {({ handleSubmit, submitting, pristine, submitError }) => (
              <form onSubmit={handleSubmit}>
                <FormGroup>
                  <Field name="input">
                    {({ input, meta }) => (
                      <TextField
                        {...input}
                        style={{ width: '100%' }}
                        minRows={1}
                        maxRows={3}
                        multiline
                        disabled={submitting}
                        type="text"
                        variant="outlined"
                        label="What do you need help with? (optional)"
                        className={classes.input}
                        error={meta.error && meta.touched}
                        helperText={
                          meta.error && meta.touched ? meta.error : null
                        }
                      />
                    )}
                  </Field>
                </FormGroup>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  type="submit"
                  className={classes.createButton}
                  startIcon={<ChatIcon />}
                  disabled={submitting || submitError}>
                  Open Assistant
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  className={classes.cancelButton}
                  onClick={() => setIsOpen(false)}
                  startIcon={<CancelIcon />}>
                  Cancel
                </Button>
                {submitting && <div className={classes.gptLoading} />}
              </form>
            )}
          </Form>
        </CardContent>
      </Container>
    </Modal>
  );
};

export default AssistantModal;
