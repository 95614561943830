import { diff } from 'react-big-calendar/lib/utils/dates';

const calculateAngle = (p1, p2, p3) => {
  const dx1 = p2.x - p1.x;
  const dy1 = p2.y - p1.y;
  const dx2 = p3.x - p2.x;
  const dy2 = p3.y - p2.y;
  const dotProduct = dx1 * dx2 + dy1 * dy2;
  const magnitude1 = Math.sqrt(dx1 * dx1 + dy1 * dy1);
  const magnitude2 = Math.sqrt(dx2 * dx2 + dy2 * dy2);
  const angle = Math.acos(dotProduct / (magnitude1 * magnitude2));
  return angle;
};

const adaptiveSmoothCoords = (
  coords,
  angleThreshold = Math.PI / 4,
  smoothingFactor = 0.5
) => {
  const smoothedCoords = [];

  smoothedCoords.push(coords[0]); // Add the first point unchanged

  for (let i = 1; i < coords.length - 1; i++) {
    const angle = calculateAngle(coords[i - 1], coords[i], coords[i + 1]);

    if (angle > angleThreshold) {
      // If the angle is sharp, keep the point unchanged
      smoothedCoords.push(coords[i]);
    } else {
      // Otherwise, smooth the point with a reduced smoothing effect
      const avgX = (coords[i - 1].x + coords[i].x + coords[i + 1].x) / 3;
      const avgY = (coords[i - 1].y + coords[i].y + coords[i + 1].y) / 3;
      const newX = coords[i].x * (1 - smoothingFactor) + avgX * smoothingFactor;
      const newY = coords[i].y * (1 - smoothingFactor) + avgY * smoothingFactor;
      smoothedCoords.push({ x: newX, y: newY });
    }
  }

  smoothedCoords.push(coords[coords.length - 1]); // Add the last point unchanged

  return smoothedCoords;
};

const detectShape = (
  inputCoords = [],
  minDistance = 20,
  boundary = 10,
  threshold = 80
) => {
  const coords = inputCoords.map(coord => ({
    ...coord,
    sum: coord.x + coord.y,
    diff: coord.x - coord.y
  }));

  const xCoords = coords.map(coord => coord.x);
  const yCoords = coords.map(coord => coord.y);

  const minX = Math.min(...xCoords);
  const maxX = Math.max(...xCoords);
  const minY = Math.min(...yCoords);
  const maxY = Math.max(...yCoords);

  const width = maxX - minX;
  const height = maxY - minY;

  // top right is the coord with the biggest x and the smallest y
  const topRight = coords.reduce(
    (acc, coord) => {
      if (coord.x > acc.x || (coord.x === acc.x && coord.y < acc.y)) {
        return coord;
      }
      return acc;
    },
    { x: -Infinity, y: Infinity }
  );

  const topLeft = coords.reduce((acc, coord) => {
    if (coord.sum < acc.sum) {
      return coord;
    }
    return acc;
  });

  // Get the bottom left as the smallest x value with the largest y value
  const bottomLeft = coords.reduce(
    (acc, coord) => {
      if (coord.x < acc.x || (coord.x === acc.x && coord.y > acc.y)) {
        return coord;
      }
      return acc;
    },
    { x: Infinity, y: -Infinity }
  );

  // Get the bottom right by summing x + y and find the largest value
  const bottomRight = coords.reduce(
    (acc, coord) => {
      const sum = coord.x + coord.y;
      if (sum > acc.sum) {
        acc = { ...coord, sum };
      }
      return acc;
    },
    { x: -Infinity, y: -Infinity, sum: -Infinity }
  );

  const topMiddle = topLeft.x + (topRight.x - topLeft.x) / 2;
  const bottomMiddle = bottomLeft.x + (bottomRight.x - bottomLeft.x) / 2;
  const leftMiddle = topLeft.y + (bottomLeft.y - topLeft.y) / 2;
  const rightMiddle = topRight.y + (bottomRight.y - topRight.y) / 2;

  // Check if the shape is a rectangle
  // Create bounding square
  const boundingSquare = [
    { x: minX, y: minY },
    { x: maxX, y: minY },
    { x: maxX, y: maxY },
    { x: minX, y: maxY }
  ];

  const innerSquare = scalePolygon(boundingSquare, boundary);
  const percentageSquare = calculatePercentageInOuterOutsideInner(
    coords,
    boundingSquare,
    innerSquare
  );

  // Check if the shape is a circle
  const boundingCircle = generateCirclePoints(minX, minY, width, height);
  const innerCircle = scalePolygon(boundingCircle, boundary);
  const percentageCircle = calculatePercentageInOuterOutsideInner(
    coords,
    boundingCircle,
    innerCircle
  );
};

export const getSetFormData = (file, fileName) => {
  console.log(file);
  if (!file) return;

  const formData = new FormData();
  formData.append('FormFile', file);
  formData.append('FileName', fileName || file.name);

  for (let pair of formData.entries()) {
    console.log(pair[0] + ': ' + pair[1]);
  }

  return formData;
};

const doLinesIntersect = (xCoords, yCoords) => {
  const checkLinesIntersect = (x1, y1, x2, y2, x3, y3, x4, y4) => {
    const denominator = (x1 - x2) * (y3 - y4) - (y1 - y2) * (x3 - x4);
    if (denominator === 0) return false; // parallel lines

    const t = ((x1 - x3) * (y3 - y4) - (y1 - y3) * (x3 - x4)) / denominator;
    const u = -((x1 - x2) * (y1 - y3) - (y1 - y2) * (x1 - x3)) / denominator;

    return t > 0 && t < 1 && u > 0 && u < 1;
  };

  // Check if any line segments intersect
  let intersects = false;
  for (let i = 0; i < xCoords.length - 2; i++) {
    for (let j = i + 2; j < xCoords.length - 1; j++) {
      if (
        checkLinesIntersect(
          xCoords[i],
          yCoords[i],
          xCoords[i + 1],
          yCoords[i + 1],
          xCoords[j],
          yCoords[j],
          xCoords[j + 1],
          yCoords[j + 1]
        )
      ) {
        intersects = true;
        break;
      }
    }
    if (intersects) break;
  }

  return intersects;
};

const generateCirclePoints = (tlX, tlY, width, height, numPoints = 30) => {
  const radiusX = width / 2;
  const radiusY = height / 2;
  const centerX = tlX + radiusX;
  const centerY = tlY + radiusY;

  const points = [];
  const angleIncrement = (2 * Math.PI) / numPoints;

  for (let i = 0; i < numPoints; i++) {
    const angle = i * angleIncrement;
    const x = centerX + radiusX * Math.cos(angle);
    const y = centerY + radiusY * Math.sin(angle);
    points.push({ x, y });
  }

  // Add the first point again at the end to close the circle
  points.push(points[0]);

  return points;
};

const pointInPolygon = (point, polygon) => {
  let isInside = false;
  const { x, y } = point;
  const len = polygon.length;

  for (let i = 0, j = len - 1; i < len; j = i++) {
    const xi = polygon[i].x,
      yi = polygon[i].y;
    const xj = polygon[j].x,
      yj = polygon[j].y;

    const intersect =
      yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    if (intersect) isInside = !isInside;
  }

  return isInside;
};

const getPointsInPolygon = (points, polygon) => {
  const filtered = points
    .filter(point => pointInPolygon(point, polygon))
    // distinct
    .filter(
      (point, index, self) =>
        self.findIndex(p => p.x === point.x && p.y === point.y) === index
    );
  if (filtered.length <= 1) return [];
  return filtered;
};

const findCentroid = polygon => {
  let xSum = 0,
    ySum = 0;
  const len = polygon.length;

  polygon.forEach(point => {
    xSum += point.x;
    ySum += point.y;
  });

  return { x: xSum / len, y: ySum / len };
};

const scalePolygon = (polygon, percentage) => {
  const centroid = findCentroid(polygon);
  const scale = percentage / 100;

  return polygon.map(point => {
    return {
      x: centroid.x + (point.x - centroid.x) * scale,
      y: centroid.y + (point.y - centroid.y) * scale
    };
  });
};

const calculatePercentageInOuterOutsideInner = (
  coords,
  outerShape,
  innerShape
) => {
  let count = 0;

  coords.forEach(point => {
    const isInOuter = pointInPolygon(point, outerShape);
    const isInInner = pointInPolygon(point, innerShape);

    if (isInOuter && !isInInner) {
      count++;
    }
  });

  const percentage = (count / coords.length) * 100;
  return percentage;
};

const toKonvaPoints = coords => coords.flatMap(coord => [coord.x, coord.y]);

const calculateBoundingBox = coords => {
  if (coords.length === 0) return [];

  let minX = coords[0].x;
  let minY = coords[0].y;
  let maxX = coords[0].x;
  let maxY = coords[0].y;

  coords.forEach(point => {
    if (point.x < minX) minX = point.x;
    if (point.y < minY) minY = point.y;
    if (point.x > maxX) maxX = point.x;
    if (point.y > maxY) maxY = point.y;
  });

  return [
    { x: minX, y: minY },
    { x: maxX, y: minY },
    { x: maxX, y: maxY },
    { x: minX, y: maxY },
    { x: minX, y: minY }
  ];
};

const calculateQuadrantPercentages = (coords, boundingBox, innerBox) => {
  const minX = Math.min(...boundingBox.map(coord => coord.x));
  const maxX = Math.max(...boundingBox.map(coord => coord.x));
  const minY = Math.min(...boundingBox.map(coord => coord.y));
  const maxY = Math.max(...boundingBox.map(coord => coord.y));
  const midX = minX + (maxX - minX) / 2;
  const midY = minY + (maxY - minY) / 2;

  const topLeftQuadrant = [
    { x: minX, y: minY },
    { x: midX, y: minY },
    { x: midX, y: midY },
    { x: minX, y: midY }
  ];
  const topRightQuadrant = [
    { x: midX, y: minY },
    { x: maxX, y: minY },
    { x: maxX, y: midY },
    { x: midX, y: midY }
  ];
  const bottomLeftQuadrant = [
    { x: minX, y: midY },
    { x: midX, y: midY },
    { x: midX, y: maxY },
    { x: minX, y: maxY }
  ];
  const bottomRightQuadrant = [
    { x: midX, y: midY },
    { x: maxX, y: midY },
    { x: maxX, y: maxY },
    { x: midX, y: maxY }
  ];

  const percentages = [
    calculatePercentageInOuterOutsideInner(
      getPointsInPolygon(coords, topLeftQuadrant),
      topLeftQuadrant,
      innerBox
    ),
    calculatePercentageInOuterOutsideInner(
      getPointsInPolygon(coords, topRightQuadrant),
      topRightQuadrant,
      innerBox
    ),
    calculatePercentageInOuterOutsideInner(
      getPointsInPolygon(coords, bottomLeftQuadrant),
      bottomLeftQuadrant,
      innerBox
    ),
    calculatePercentageInOuterOutsideInner(
      getPointsInPolygon(coords, bottomRightQuadrant),
      bottomRightQuadrant,
      innerBox
    )
  ];

  return percentages;
};

export const convertToRectangle = line => {
  const { points } = line;
  const xCoords = points.filter((_, index) => index % 2 === 0);
  const yCoords = points.filter((_, index) => index % 2 !== 0);
  const coords = xCoords.map((x, index) => ({ x, y: yCoords[index] }));

  // create rectangle based on the bounding box
  const square = calculateBoundingBox(coords);
  const boundingSquare = scalePolygon(square, 120);
  const innerSquare = scalePolygon(boundingSquare, 70);

  const percentageSquare = calculatePercentageInOuterOutsideInner(
    coords,
    boundingSquare,
    innerSquare
  );

  const allQuadrantOccupancyGreaterThan40 = calculateQuadrantPercentages(
    coords,
    boundingSquare,
    innerSquare
  ).every(value => value > 40);

  const gapLength = Math.sqrt(
    Math.pow(xCoords[0] - xCoords[xCoords.length - 1], 2) +
      Math.pow(yCoords[0] - yCoords[yCoords.length - 1], 2)
  );

  if (
    percentageSquare > 80 &&
    allQuadrantOccupancyGreaterThan40 &&
    gapLength < 50
  ) {
    line.points = toKonvaPoints(square);
    line.lineCap = 'square';
    line.lineJoin = 'miter';
    line.stroke = 'red';
    line.tension = 0;
  }

  return line;
};

export const convertToCircle = line => {
  const { points } = line;
  const xCoords = points.filter((_, index) => index % 2 === 0);
  const yCoords = points.filter((_, index) => index % 2 !== 0);
  const coords = xCoords.map((x, index) => ({ x, y: yCoords[index] }));

  const minX = Math.min(...xCoords);
  const maxX = Math.max(...xCoords);
  const minY = Math.min(...yCoords);
  const maxY = Math.max(...yCoords);

  const width = maxX - minX;
  const height = maxY - minY;

  const boundingCircle = scalePolygon(
    generateCirclePoints(minX, minY, width, height),
    120
  );
  const innerCircle = scalePolygon(boundingCircle, 70);
  const circlePoints = toKonvaPoints(
    generateCirclePoints(minX, minY, width, height)
  );
  const quadrantOccupancy = calculateQuadrantPercentages(
    coords,
    boundingCircle,
    innerCircle
  );
  const percentage = calculatePercentageInOuterOutsideInner(
    coords,
    boundingCircle,
    innerCircle
  );
  // calculate the gap length between the start x,y and end x,y
  const gapLength = Math.sqrt(
    Math.pow(xCoords[0] - xCoords[xCoords.length - 1], 2) +
      Math.pow(yCoords[0] - yCoords[yCoords.length - 1], 2)
  );

  const allQuadrantOccupancyGreaterThan40 = quadrantOccupancy.every(
    value => value > 40
  );

  line.points =
    percentage > 80 && allQuadrantOccupancyGreaterThan40 && gapLength < 50
      ? circlePoints
      : line.points;

  return line;
};

const getPerpendicularDistance = (point, lineStart, lineEnd) => {
  const dx = lineEnd.x - lineStart.x;
  const dy = lineEnd.y - lineStart.y;

  if (dx === 0 && dy === 0) {
    return Math.sqrt(
      Math.pow(point.x - lineStart.x, 2) + Math.pow(point.y - lineStart.y, 2)
    );
  }

  const t =
    ((point.x - lineStart.x) * dx + (point.y - lineStart.y) * dy) /
    (dx * dx + dy * dy);
  const closestPoint = {
    x: lineStart.x + t * dx,
    y: lineStart.y + t * dy
  };

  return Math.sqrt(
    Math.pow(point.x - closestPoint.x, 2) +
      Math.pow(point.y - closestPoint.y, 2)
  );
};

const rdp = (points, epsilon) => {
  if (points.length < 3) return points;

  let maxDistance = 0;
  let index = 0;
  for (let i = 1; i < points.length - 1; i++) {
    const distance = getPerpendicularDistance(
      points[i],
      points[0],
      points[points.length - 1]
    );
    if (distance > maxDistance) {
      index = i;
      maxDistance = distance;
    }
  }

  if (maxDistance > epsilon) {
    const left = rdp(points.slice(0, index + 1), epsilon);
    const right = rdp(points.slice(index), epsilon);

    return left.slice(0, left.length - 1).concat(right);
  } else {
    return [points[0], points[points.length - 1]];
  }
};

const calculateDistanceBetweenPoints = (p1, p2) =>
  Math.sqrt(Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2));

const getDistanceBetweenStartAndEnd = points => {
  if (points.length < 2) return 0;

  const startPoint = points[0];
  const endPoint = points[points.length - 1];

  return calculateDistanceBetweenPoints(startPoint, endPoint);
};

const closeShape = (points, distanceThreshold = 20) => {
  if (points.length < 3) return points;

  const startPoint = points[0];
  const endPoint = points[points.length - 1];

  const isCloseEnough =
    Math.round(calculateDistanceBetweenPoints(startPoint, endPoint)) <
    distanceThreshold;

  if (
    isCloseEnough &&
    (startPoint.x !== endPoint.x || startPoint.y !== endPoint.y)
  ) {
    points.push(startPoint);
  }

  return points;
};

const getDistinctPoints = points => {
  const uniquePoints = [];
  const seen = new Set();

  points.forEach(point => {
    const key = `${point.x},${point.y}`;
    if (!seen.has(key)) {
      seen.add(key);
      uniquePoints.push(point);
    }
  });

  return uniquePoints;
};

const isTriangle = points => {
  if (!points?.length) return false;

  const distinctPoints = getDistinctPoints(points);

  if (distinctPoints.length !== 3) return false;

  const [p1, p2, p3] = distinctPoints;

  // Calculate the area of the triangle using the Shoelace formula
  const area =
    Math.abs(
      p1.x * (p2.y - p3.y) + p2.x * (p3.y - p1.y) + p3.x * (p1.y - p2.y)
    ) / 2;

  // If the area is zero, the points are collinear
  return area > 0;
};

export const convertToTriangle = line => {
  const { points } = line;
  const xCoords = points.filter((_, index) => index % 2 === 0);
  const yCoords = points.filter((_, index) => index % 2 !== 0);
  const coords = xCoords.map((x, index) => ({ x, y: yCoords[index] }));

  const distance = getDistanceBetweenStartAndEnd(coords);

  if (distance > 40) return line;

  const closedPoints = closeShape(coords, 40);
  const rdpPoints = rdp(closedPoints, 20);

  if (isTriangle(rdpPoints)) {
    line.points = toKonvaPoints(rdpPoints);
  }

  return line;
};

export const simplifyLine = line => {
  const { points } = line;
  const xCoords = points.filter((_, index) => index % 2 === 0);
  const yCoords = points.filter((_, index) => index % 2 !== 0);
  const coords = xCoords.map((x, index) => ({ x, y: yCoords[index] }));

  const simplifiedPoints = toKonvaPoints(rdp(coords, 25));
  line.points = simplifiedPoints;

  return line;
};

export const smoothLines = line => {
  const { points } = line;
  const xCoords = points.filter((_, index) => index % 2 === 0);
  const yCoords = points.filter((_, index) => index % 2 !== 0);
  const coords = xCoords.map((x, index) => ({ x, y: yCoords[index] }));

  const smoothedCoords = adaptiveSmoothCoords(coords, Math.PI / 4, 0.1);
  const smoothedPoints = toKonvaPoints(smoothedCoords);
  line.points = smoothedPoints;

  return line;
};

export const convertToShape = (shapeType, points) => {};
