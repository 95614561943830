import { useListContext } from 'react-admin';
import { Button, Box } from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flex: 1
  },
  left: {},
  right: {
    marginLeft: 'auto'
  }
});

const SimplePagination = () => {
  const { page, perPage, total, setPage } = useListContext();
  const nbPages = Math.ceil(total / perPage) || 1;
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Button
        disabled={page <= 1}
        color="primary"
        key="prev"
        className={classes.left}
        onClick={() => setPage(page - 1)}>
        <ChevronLeft />
        Prev
      </Button>
      <Button
        disabled={page === nbPages}
        color="primary"
        key="next"
        className={classes.right}
        onClick={() => setPage(page + 1)}>
        Next
        <ChevronRight />
      </Button>
    </Box>
  );
};

export default SimplePagination;
