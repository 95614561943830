import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import {
  TextField,
  Button,
  Select,
  InputLabel,
  MenuItem,
  FormGroup,
  Collapse,
  FormControlLabel,
  Switch
} from '@material-ui/core';
import { Form, Field } from 'react-final-form';
import SaveIcon from '@material-ui/icons/Save';
import MisconceptionTagSelect from './MisconceptionTagSelect';
import MisconceptionTagSuggested from './MisconceptionTagSuggested';
import MisconceptionTagValidationSummary from './MisconceptionTagValidationSummary';
import MisconceptionTagGenerator from './MisconceptionTagGenerator';
import useGetPermissions from 'Hooks/useGetPermissions';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  cardContent: {
    minHeight: '16rem'
  },
  input: {
    margin: '0 0 0 0'
  },
  createButton: {
    margin: '1rem 0 0 0'
  },
  select: {
    margin: '1rem 0 0 0',
    width: '100%'
  },
  explanation: {
    marginBottom: '1rem',
    backgroundColor: '#FAF3D3',
    borderRadius: '0.5rem',
    padding: '1rem'
  },
  correctAnswer: {
    backgroundColor: '#eee',
    borderRadius: '0.5rem',
    padding: '3.5rem 1rem',
    textAlign: 'center',
    color: '#666'
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  flagged: {
    margin: '0 0 1rem 0'
  }
}));

const statuses = [
  'Question or answer needs rewriting',
  'Answer has 2 misconceptions',
  'Too specific misconception',
  'Cannot identify misconception',
  'Mathematical slip',
  'Misread question',
  'Got part way through a multi step question but did not finish.'
];

const MisconceptionTagEditForm = ({
  question,
  currentAnswerValue,
  misconceptionTag = {},
  data = {},
  onSubmit = () => {},
  onDelete = () => {}
}) => {
  const classes = useStyles();
  const history = useHistory();
  const permissions = useGetPermissions();
  const isSuperAdmin = !!permissions?.isSuperAdmin;

  if (!question) return null;

  if (currentAnswerValue === question.givenAnswer)
    return (
      <FormWrapper>
        <CorrectAnswer>
          <div>This is the correct answer!</div>
        </CorrectAnswer>
      </FormWrapper>
    );

  const initialValues = {
    id: misconceptionTag.misconceptionTagId,
    comments: misconceptionTag?.comments,
    misconceptionStatus: misconceptionTag?.misconceptionStatus,
    questionId: question.questionId,
    answerValue: currentAnswerValue,
    flagged:
      misconceptionTag?.comments || misconceptionTag?.misconceptionStatus,
    embedding: {
      embeddingOutputId: misconceptionTag?.embeddingOutputId,
      searchText: misconceptionTag?.searchText,
      similarDistractorQuestionId:
        misconceptionTag?.similarDistractorQuestionId,
      similarDistractorAnswerValue:
        misconceptionTag?.similarDistractorAnswerValue
    },
    isDeclarativeKnowledge: misconceptionTag?.isDeclarativeKnowledge ?? false
  };

  const otherTags =
    data?.misconceptionTags?.filter(
      t =>
        t.userId !== misconceptionTag?.userId &&
        t.answerValue === currentAnswerValue
    ) ?? [];

  console.log(
    'otherTags',
    currentAnswerValue,
    otherTags,
    data.misconceptionTags
  );

  return (
    <FormWrapper
      key={`${misconceptionTag?.searchText}_${misconceptionTag?.embeddingOutputId}`}>
      <Form onSubmit={onSubmit} initialValues={initialValues}>
        {({ handleSubmit, submitting, pristine, submitError, values }) => (
          <form onSubmit={handleSubmit}>
            <FormGroup>
              <Field name="embedding">
                {({ input }) => (
                  <MisconceptionTagSelect {...input} name="embedding" />
                )}
              </Field>
            </FormGroup>
            {otherTags.length > 0 && (
              <div>
                <h4>Other users tags</h4>
                <OtherTagsWraper>
                  {otherTags.map((tag, idx) => (
                    <p key={idx}>
                      {tag.userName} - <i>"{tag.searchText}"</i>
                    </p>
                  ))}
                  {isSuperAdmin && (
                    <Button
                      variant="contained"
                      color="secondary"
                      size="small"
                      onClick={() =>
                        window.open(
                          `/embedding-outputs?displayedFilters=%7B%7D&filter=%7B"embeddingType"%3A%7B"id"%3A"embeddingType"%2C"Value"%3A1%2C"Operator"%3A"Eq"%2C"Type"%3A"int"%7D%2C"q"%3A"${question.questionId}"%7D&order=ASC&page=1&perPage=20&sort=id`,
                          '_blank'
                        )
                      }>
                      Edit Question Tags
                    </Button>
                  )}
                </OtherTagsWraper>
              </div>
            )}
            <Field name="isDeclarativeKnowledge" type="checkbox">
              {({ input }) => (
                <>
                  <FormControlLabel
                    control={<Switch {...input} />}
                    label="Is declarative knowledge"
                  />
                </>
              )}
            </Field>
            <Field name="flagged" type="checkbox">
              {({ input }) => (
                <>
                  <FormControlLabel
                    control={<Switch {...input} />}
                    label="Flag"
                  />
                  <Collapse in={input.checked}>
                    <FormGroup className={classes.flagged}>
                      <Field name="misconceptionStatus">
                        {({ input, meta }) => (
                          <>
                            <InputLabel
                              id="misconception-status"
                              className={classes.select}>
                              Flag Reason
                            </InputLabel>
                            <Select
                              {...input}
                              name="misconception-status"
                              labelId="misconception-status"
                              error={meta.error && meta.touched}>
                              {statuses.map((c, idx) => (
                                <MenuItem key={idx} value={c}>
                                  {c}
                                </MenuItem>
                              ))}
                            </Select>
                          </>
                        )}
                      </Field>
                    </FormGroup>
                    <FormGroup className={classes.flagged}>
                      <Field name="comments">
                        {({ input, meta }) => (
                          <TextField
                            {...input}
                            id="comments"
                            label="Comments"
                            variant="filled"
                            multiline
                            rows={2}
                            className={classes.input}
                            error={meta.error && meta.touched}
                            helperText={
                              meta.error && meta.touched ? meta.error : null
                            }
                          />
                        )}
                      </Field>
                    </FormGroup>
                  </Collapse>
                </>
              )}
            </Field>
            <FormGroup>
              <Field name="embedding">
                {({ input }) => (
                  <MisconceptionTagValidationSummary
                    key={currentAnswerValue}
                    values={values}
                    currentAnswerValue={currentAnswerValue}
                    questionId={question?.questionId}
                    {...input}
                  />
                )}
              </Field>
            </FormGroup>
            <FormGroup style={{ margin: '0.25rem 0 0 0' }}>
              <Field name="embedding">
                {({ input }) => (
                  <MisconceptionTagGenerator
                    key={currentAnswerValue}
                    values={values}
                    currentAnswerValue={currentAnswerValue}
                    questionId={question?.questionId}
                    disabled={!question?.metaDataCount}
                    {...input}
                  />
                )}
              </Field>
            </FormGroup>
            <FormGroup>
              <h4>Similar Distractors</h4>
              <Field name="embedding">
                {({ input }) => (
                  <MisconceptionTagSuggested
                    key={currentAnswerValue}
                    question={question}
                    answerValue={currentAnswerValue}
                    {...input}
                  />
                )}
              </Field>
            </FormGroup>

            <div className={classes.footer}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                className={classes.createButton}
                startIcon={<SaveIcon />}
                disabled={
                  submitting ||
                  submitError ||
                  values?.embedding?.searchText?.length <= 5
                }>
                Save
              </Button>
              {misconceptionTag?.misconceptionTagId && (
                <Button
                  color="secondary"
                  size="large"
                  onClick={() => onDelete(misconceptionTag.misconceptionTagId)}
                  className={classes.createButton}>
                  Delete
                </Button>
              )}
            </div>
          </form>
        )}
      </Form>
    </FormWrapper>
  );
};

const FormWrapper = styled.div``;

const CorrectAnswer = styled.div`
  width: 100%;
  border-radius: 8px;
  text-align: center;
  background: #c5fcd6;
  div {
    padding: 1rem;
  }
`;

const OtherTagsWraper = styled.div`
  width: 100%;
  background: rgb(221, 221, 221);
  border-radius: 6px;
  padding: 0.5rem;
  margin: 0 0 0.5rem 0;
`;

export default MisconceptionTagEditForm;
