import { Filter } from 'react-admin';

const NotificationListFilter = props => {
  return (
    <>
      <Filter {...props}></Filter>
    </>
  );
};
export default NotificationListFilter;
