import {
  List,
  Pagination,
  FunctionField,
  useDataProvider,
  useNotify,
  useRefresh
} from 'react-admin';
import TutorFilter from './Components/TutorFilter';
import TutorListDataGrid from './Components/TutorListDataGrid';
import {
  Box,
  Button,
  Modal,
  Backdrop,
  Container,
  Card,
  CardContent,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TutorListActions from './Components/TutorListActions';
import { useHistory, useLocation } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import CreateTutorModal from './Components/CreateTutorModal';
import CancelIcon from '@material-ui/icons/Cancel';
import EditTutorModal from './Components/EditTutorModal';
import PanoramaIcon from '@material-ui/icons/Panorama';
import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import TutorPreviewModal from './Components/TutorPreviewModal';
import Circle from 'Components/Shapes/Circle';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

const useStyles = makeStyles(theme => ({
  root: {
    margin: '12px',
    minHeight: '350px'
  },
  editButton: {
    color: '#4353ff',
    fontSize: '0.8125rem',
    padding: '4px',
    border: 'none',
    background: 'none',
    boxShadow: 'none'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '600px'
  },
  cancelButton: {
    margin: '0 0 0 10px'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  formGroup: { display: 'flex', flexDirection: 'row', width: '100%' },
  buttonWrapper: { display: 'flex' }
}));

const StyledList = styled(List)`
  .MuiToolbar-root {
    justify-content: flex-start;
  }
`;

const TutorPagination = props => (
  <Pagination rowsPerPageOptions={[20, 30, 40, 50]} {...props} />
);

const TutorList = props => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(
    location.pathname.includes('create')
  );
  const [isEditModalOpen, setIsEditModalOpen] = useState(
    location.pathname.includes('edit')
  );

  useEffect(() => {
    const runEffect = async () => {
      const isCreate = location.pathname.includes('create');
      const isEdit = location.pathname.includes('edit');
      if (isCreate !== isCreateModalOpen) setIsCreateModalOpen(isCreate);
      if (isEdit !== isEditModalOpen) setIsEditModalOpen(isEdit);
    };
    runEffect();
  }, [isCreateModalOpen, isEditModalOpen, location.pathname]);

  return (
    <Box className={classes.root}>
      {isCreateModalOpen && (
        <CreateTutorModal
          isOpen={true}
          setIsOpen={() => history.push('/tutors' + location.search)}
        />
      )}
      {isEditModalOpen && (
        <EditTutorModal
          isOpen={true}
          setIsOpen={() => history.push('/tutors' + location.search)}
        />
      )}
      <StyledList
        title="Tutors"
        filters={<TutorFilter />}
        pagination={<TutorPagination />}
        bulkActionButtons={false}
        perPage={15}
        sort={{}}
        actions={<TutorListActions />}
        {...props}>
        <TutorListDataGrid rowClick="">
          <FunctionField
            sortByOrder="DESC"
            label="TutorId"
            sortBy="TutorId"
            render={r => `${r.tutorId}`}
          />
          <FunctionField
            sortByOrder="DESC"
            label="UserId"
            sortBy="UserId"
            render={r => `${r.userId}`}
          />
          <FunctionField
            label="FirstName"
            sortBy="User.FirstName"
            render={r => r.firstName}
          />
          <FunctionField
            label="LastName"
            sortBy="User.LastName"
            render={r => r.lastName}
          />
          <FunctionField
            label="DateLastLoggedIn"
            sortable={false}
            render={r => r.dateLastLoggedIn}
          />
          <FunctionField
            label="Online"
            sortable={false}
            render={r =>
              r.isOnline ? (
                <Circle width={16} height={16} radius={8} fill="#11DC00" />
              ) : (
                <Circle width={16} height={16} radius={8} fill="#E5E5E5" />
              )
            }
          />
          <FunctionField
            label="View"
            render={r => <ViewButton classes={classes} record={r} />}
          />
          <EditTutorButton classes={classes} history={history} />
          <DeleteTutorButton classes={classes} />
        </TutorListDataGrid>
      </StyledList>
    </Box>
  );
};

const ViewButton = ({ record, classes }) => {
  const [isModalOpen, setisModalOpen] = useState(false);
  return (
    <>
      <Button
        variant="contained"
        color="default"
        className={classes?.editButton}
        startIcon={<PanoramaIcon />}
        onClick={() => setisModalOpen(!isModalOpen)}></Button>
      <TutorPreviewModal
        record={record}
        classes={classes}
        isModalOpen={isModalOpen}
        setisModalOpen={() => setisModalOpen(false)}
      />
    </>
  );
};

const EditTutorButton = ({ record, classes, history }) => (
  <Button
    variant="contained"
    color="default"
    className={classes.editButton}
    startIcon={<EditIcon />}
    onClick={r => history.push(`/tutors/edit/${record.tutorId}`)}>
    Edit
  </Button>
);

const DeleteTutorButton = ({ record, classes, history }) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const refresh = useRefresh();

  const handleDelete = useCallback(async () => {
    try {
      setIsDeleting(true);
      await dataProvider.delete('tutors', { id: record.tutorId });
      notify('Tutor deleted successfully', 'success');
      setIsModalOpen(false);
      refresh();
    } catch (e) {
      notify('Error deleting tutor', 'error');
      console.error(e);
    }
    setIsDeleting(false);
  }, [dataProvider, notify, record?.tutorId, refresh]);

  return (
    <>
      <Button
        variant="contained"
        color="default"
        className={classes.editButton}
        startIcon={<DeleteForeverIcon />}
        onClick={() => setIsModalOpen(true)}>
        Delete
      </Button>
      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        className={classes.modal}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
        closeAfterTransition>
        <Container maxWidth="sm">
          <Card className={classes.create}>
            <CardContent style={{ textAlign: 'center' }}>
              <Typography variant="h5" gutterBottom>
                Are you sure you want to delete this tutor?
              </Typography>
              <img
                src={record.imageURL}
                alt=""
                style={{ width: 'auto', maxHeight: '200px' }}
              />
              <Typography variant="h6" gutterBottom>
                {record.firstName} {record.lastName}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                disabled={isDeleting}
                className={classes.createButton}
                startIcon={<DeleteForeverIcon />}
                onClick={() => handleDelete()}>
                Delete!
              </Button>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                className={classes.cancelButton}
                disabled={isDeleting}
                onClick={() => setIsModalOpen(false)}
                startIcon={<CancelIcon />}>
                Cancel
              </Button>
            </CardContent>
          </Card>
        </Container>
      </Modal>
    </>
  );
};

export default TutorList;
