import { useCallback, useState } from 'react';
import AudioRecorder from './AudioRecorder';
import Api from 'Api/Api';
import { useNotify } from 'react-admin';
import { Container, Box, Paper } from '@material-ui/core';
import Loading from 'Pages/Flows/Components/Loading';

const ExperimentPage = () => {
  const [annotatedAudio, setAnnotatedAudio] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const notify = useNotify();

  const annotate = useCallback(
    async audioBlob => {
      if (!audioBlob || isLoading) return;
      setIsLoading(true);

      try {
        const formData = new FormData();
        formData.append(
          'FormFile',
          new File([audioBlob], 'audio.wav', { type: 'audio/wav' })
        );

        const response = await Api.speechToText(formData);

        if (response.isSuccess) {
          notify('Audio has been annotated successfully', 'success');
          setAnnotatedAudio(response.text);
          setIsLoading(false);
          return;
        }

        notify(
          'An error occurred while trying to annotate the audio:' +
            response.error,
          'error'
        );
      } catch (e) {
        notify('An error occurred while trying to annotate the audio', 'error');
        console.error(
          'An error occurred while trying to annotate the audio:',
          e
        );
      }

      setIsLoading(false);
    },
    [isLoading, notify]
  );
  return (
    <Container>
      <AudioRecorder onStop={annotate} />
      <Loading
        text="Converting speech to text... please wait..."
        loading={isLoading}
      />
      {annotatedAudio ? (
        <Box style={{ margin: '10px 0 0 0' }}>
          <Paper style={{ padding: '10px' }}>{annotatedAudio}</Paper>
        </Box>
      ) : (
        <span>{!isLoading ? 'Start recording to annotate audio' : ''}</span>
      )}
    </Container>
  );
};

export default ExperimentPage;
