import {
  Typography,
  CardContent,
  Box,
  CardMedia,
  Paper
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from 'Components/Avatar';
import useGetAuthUser from 'Hooks/useGetAuthUser';
import FeaturedVideoIcon from '@material-ui/icons/FeaturedVideo';

const useStyles = makeStyles(_ => ({
  media: {
    objectFit: 'contain'
  },
  root: {
    display: 'flex',
    flexDirection: props =>
      props.isBot || props.isCurrentTutor ? 'row' : 'row-reverse'
  },
  bubble: {
    flex: 1
  },
  paper: {
    borderRadius: '1.5rem',
    backgroundColor: props =>
      props.isBot ? '#4353ff' : props.isCurrentTutor ? '#b3ffec' : '#fff',
    color: props => (props.isBot ? '#fff' : 'currentColor'),
    textAlign: props =>
      props.isBot || props.isCurrentTutor ? 'left' : 'right',
    overflow: 'hidden',
    border: props => (props.isPinned ? '1px solid #595959' : '')
  },
  pinnedHeader: {
    padding: '0.5rem 0.5rem 0.5rem 1rem',
    margin: '0',
    borderBottom: '1px solid black'
  },
  pinnedIcon: {
    height: '1em',
    width: '1em',
    top: '.125em',
    position: 'relative',
    fontSize: '1rem',
    float: 'right'
  },
  avatar: {
    margin: '12px',
    width: '24px',
    height: '24px',
    flex: '0 0 24px'
  },
  header: {
    fontSize: '12px',
    padding: '16px 16px 0 16px'
  },
  time: {
    margin: '5px 0 0 0',
    textAlign: 'right',
    fontSize: '11px',
    opacity: '0.75'
  },
  content: {
    padding: '16px !important'
  },
  nameBlock: {
    padding: '0.5rem 1rem 0 1rem',
    margin: '0',
    fontWeight: 'bold',
    fontSize: '12px',
    textAlign: 'left'
  }
}));

const ImageFlowItem = ({ props }) => {
  const {
    text,
    avatarColor,
    dateDispatched,
    videoUrl,
    userId,
    isCurrentlyPinned,
    flowItemType,
    tutorName,
    studentName,
    isShowName,
    isTutor
  } = props;
  const isPinned = flowItemType.toLowerCase().includes('pinned');
  const user = useGetAuthUser();
  const isBot = userId === 0;
  const isCurrentTutor = userId === user?.id;
  const name = isTutor || isBot ? tutorName : studentName;
  const styleProps = {
    backgroundColor: avatarColor ?? 'rgb(54, 171, 255)',
    isBot,
    isCurrentTutor
  };
  const classes = useStyles(styleProps);

  if (!videoUrl) return null;

  return (
    <Box className={classes.root}>
      {!isBot && !isCurrentTutor && (
        <Avatar name={`${userId}`} size={24} className={classes.avatar} />
      )}
      {!isBot && isCurrentTutor && (
        <Avatar name={`${userId}`} size={24} className={classes.avatar} />
      )}
      {isBot && (
        <img src="/assets/face.png" alt="Eedi" className={classes.avatar} />
      )}
      <Box>
        <Paper elevation={1} className={classes.paper}>
          {isPinned && (
            <h5 className={classes.pinnedHeader}>
              {name && isShowName ? `${name} - ` : ''}
              {isCurrentlyPinned ? 'Currently Pinned' : 'Pinned'}
              <FeaturedVideoIcon className={classes.pinnedIcon} />
            </h5>
          )}
          {!isPinned && name && isShowName && (
            <Box className={classes.nameBlock}>{name}</Box>
          )}
          <CardMedia
            component="video"
            height="auto"
            className={classes.media}
            image={videoUrl}
            controls
          />
          {text && (
            <CardContent className={classes.content}>
              <Typography variant="body2" component="p">
                {text?.split('\n').map(t => (
                  <>
                    {t}
                    <br />
                  </>
                ))}
              </Typography>
            </CardContent>
          )}
        </Paper>
        <Typography variant="body2" className={classes.time}>
          {dateDispatched}
        </Typography>
      </Box>
    </Box>
  );
};

export default ImageFlowItem;
