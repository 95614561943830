import styled from 'styled-components';
import AddIcon from '@material-ui/icons/Add';
import { Button } from '@material-ui/core';
import { useState } from 'react';

const FlowTemplateNode = ({
  flowItemTemplateSlug,
  flowTemplateSlug,
  positionMapping,
  parentFlowTemplateSlug,
  flowTemplateContentType,
  nodeType,
  openCreateFlowTemplateModal
}) => {
  const [isAddButtonVisible, setIsAddButtonVisible] = useState(false);
  return (
    <Root
      onMouseEnter={() => setIsAddButtonVisible(true)}
      onMouseLeave={() => setIsAddButtonVisible(false)}>
      {positionMapping ? (
        <PositionMapping>{positionMapping}</PositionMapping>
      ) : null}
      {flowTemplateSlug !== flowItemTemplateSlug && flowTemplateSlug ? (
        <FlowTemplateSlug>
          FlowTemplateSlug: {flowTemplateSlug}
        </FlowTemplateSlug>
      ) : null}
      {flowItemTemplateSlug ? (
        <FlowItemTemplateSlug>
          FlowItemTemplateSlug: {flowItemTemplateSlug}
        </FlowItemTemplateSlug>
      ) : null}
      {parentFlowTemplateSlug ? (
        <ParentFlowTemplateSlug>
          ParentFlowTemplateSlug: {parentFlowTemplateSlug}
        </ParentFlowTemplateSlug>
      ) : null}
      <ContentType>Content Type: {flowTemplateContentType}</ContentType>
      {nodeType !== 'default' ? (
        <IONode input={nodeType === 'input'}>
          {nodeType === 'input' ? 'START' : 'END'}
        </IONode>
      ) : null}
      {isAddButtonVisible && (
        <NodeButtonWrapper>
          <AddNodeButton
            variant="contained"
            color="default"
            onClick={openCreateFlowTemplateModal}
            startIcon={<AddIcon />}></AddNodeButton>
        </NodeButtonWrapper>
      )}
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;

const AddNodeButton = styled(Button)`
  position: relative;
  display: flex;
  border-radius: 50%;
  padding: 0;
  height: 30px;
  width: 3%;
  min-width: 30px;
  .MuiButton-startIcon {
    margin: 0;
  }
`;

const NodeButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: -30px;
`;

const FlowItemTemplateSlug = styled.div`
  border: 1px solid #78b4d6;
  background: #96d9ff;
  border-radius: 5px;
  padding: 0.25rem;
  margin: 0 0 0.25rem;
`;

const FlowTemplateSlug = styled.div`
  border: 1px solid #4b9c60;
  background: #a0ebb4;
  border-radius: 5px;
  padding: 0.25rem;
  margin: 0 0 0.25rem;
`;

const ParentFlowTemplateSlug = styled.div`
  border: 1px solid #4b9c60;
  background: #fdee73;
  border-radius: 5px;
  padding: 0.25rem;
  margin: 0 0 0.25rem;
`;

const PositionMapping = styled.div`
  border: 1px solid #000;
  background: #ddd;
  border-radius: 5px;
  padding: 0.25rem;
  margin: 0 0 0.25rem;
`;

const ContentType = styled.div`
  border: 1px solid #4b9c60;
  background: #cbc3e3;
  border-radius: 5px;
  padding: 0.25rem;
  margin: 0 0 0.25rem;
`;

const IONode = styled.div`
  font-weight: bold;
  ${props =>
    props.input
      ? `
      border: 1px solid #3d43db;
      background: #7175f0;
    `
      : `
      border: 1px solid #c92466;
      background: #ed669c;
  `}

  border-radius: 5px;
  padding: 0.25rem;
`;

export default FlowTemplateNode;
