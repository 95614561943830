import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button, FormGroup } from '@material-ui/core';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { React } from 'react';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import { Form, Field } from 'react-final-form';
import ToggleSwitch from 'Components/ToggleSwitch';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  create: {
    margin: '1rem 0 0 0'
  },
  input: {
    margin: '1rem 0 0 0'
  },
  createButton: {
    margin: '1rem 0 0 0'
  },
  cancelButton: {
    margin: '1rem 0 0 10px'
  },
  select: {
    margin: '1rem 0 0 0',
    width: '100%'
  }
}));

const TutorForm = ({ onSubmit, onCancel, tutor = null }) => {
  const classes = useStyles();
  console.log('tutor', tutor);
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={tutor}
      validate={values => {
        const errors = {};
        const isAddingExistingUser = !tutor && values.userId;
        if (!values.firstName && !isAddingExistingUser) {
          errors.firstName = 'Please enter a first name';
        }
        if (!values.lastName && !isAddingExistingUser) {
          errors.lastName = 'Please enter a last name';
        }
        if (!values.imageURL && !values.isContractor) {
          errors.imageURL = 'Please enter an image URL';
        }
        if (!values.password && !tutor && !isAddingExistingUser) {
          errors.password = 'Please enter a password';
        }
        return errors;
      }}>
      {({ handleSubmit, submitting, pristine, submitError, values }) => (
        <form onSubmit={handleSubmit}>
          <FormGroup>
            <Field name="userId">
              {({ input, meta }) => (
                <TextField
                  {...input}
                  id="standard-basic"
                  label={
                    tutor
                      ? 'UserId (readonly)'
                      : 'Enter a userId if you want to make an existing user a tutor'
                  }
                  variant="filled"
                  disabled={!!tutor}
                  className={classes.input}
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : null}
                />
              )}
            </Field>
          </FormGroup>
          {tutor && (
            <FormGroup>
              <Field name="userName">
                {({ input, meta }) => (
                  <TextField
                    {...input}
                    id="standard-basic"
                    label="Username (readonly)"
                    variant="filled"
                    disabled={true}
                    className={classes.input}
                    error={meta.error && meta.touched}
                    helperText={meta.error && meta.touched ? meta.error : null}
                    autoComplete={false}
                  />
                )}
              </Field>
            </FormGroup>
          )}
          <FormGroup>
            <Field name="email">
              {({ input, meta }) => (
                <TextField
                  {...input}
                  id="standard-basic"
                  label={tutor ? 'Email (readonly)' : 'Email'}
                  variant="filled"
                  disabled={!!tutor}
                  className={classes.input}
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : null}
                  autoComplete={false}
                />
              )}
            </Field>
          </FormGroup>
          <FormGroup>
            <Field name="firstName">
              {({ input, meta }) => (
                <TextField
                  {...input}
                  id="standard-basic"
                  label="First Name"
                  variant="filled"
                  className={classes.input}
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : null}
                  autoComplete={false}
                />
              )}
            </Field>
          </FormGroup>
          <FormGroup>
            <Field name="lastName">
              {({ input, meta }) => (
                <TextField
                  {...input}
                  id="standard-number"
                  label="Last Name"
                  variant="filled"
                  className={classes.input}
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : null}
                  autoComplete={false}
                />
              )}
            </Field>
          </FormGroup>
          <FormGroup>
            <Field name="imageURL">
              {({ input, meta }) => (
                <TextField
                  {...input}
                  id="standard-basic"
                  label="Profile Image URL"
                  variant="filled"
                  className={classes.input}
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : null}
                  autoComplete={false}
                />
              )}
            </Field>
          </FormGroup>
          <FormGroup>
            <Field name="description">
              {({ input, meta }) => (
                <TextField
                  {...input}
                  id="standard-basic"
                  label="Short Bio (optional)"
                  variant="filled"
                  className={classes.input}
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : null}
                  autoComplete={false}
                />
              )}
            </Field>
          </FormGroup>
          <FormGroup>
            <Field name="defaultClaimMessage">
              {({ input, meta }) => (
                <TextField
                  {...input}
                  id="standard-basic"
                  label="Default Claim Message (optional)"
                  variant="filled"
                  className={classes.input}
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : null}
                  autoComplete={false}
                />
              )}
            </Field>
          </FormGroup>
          <FormGroup>
            <Field name="password">
              {({ input, meta }) => (
                <TextField
                  {...input}
                  id="standard-basic"
                  label={tutor ? 'Reset Password (optional)' : 'Password'}
                  variant="filled"
                  type="password"
                  className={classes.input}
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : null}
                  autoComplete={false}
                />
              )}
            </Field>
          </FormGroup>
          <FormGroup>
            <Field
              name="isSuperAdmin"
              label="Super Admin"
              component={props => (
                <ToggleSwitch
                  disabled={!!values.isContractor}
                  invert={false}
                  {...props}
                />
              )}
            />
          </FormGroup>
          <FormGroup>
            <Field
              name="isContractor"
              label="Contractor"
              component={props => (
                <ToggleSwitch
                  disabled={!!values.isSuperAdmin}
                  invert={false}
                  {...props}
                />
              )}
            />
          </FormGroup>
          <Button
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            className={classes.createButton}
            startIcon={<SaveIcon />}
            disabled={submitting || pristine || submitError}>
            {!!tutor ? 'Update' : 'Create'}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            className={classes.cancelButton}
            onClick={() => onCancel()}
            startIcon={<CancelIcon />}>
            Cancel
          </Button>
        </form>
      )}
    </Form>
  );
};

export default TutorForm;
