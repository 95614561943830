import ReactFlow, {
  removeElements,
  addEdge,
  MiniMap,
  Controls,
  Background
} from 'react-flow-renderer';
import {
  Button,
  Select,
  FormControl,
  InputLabel,
  MenuItem
} from '@material-ui/core';
import { useState, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import html2canvas from 'html2canvas';
import { useNotify } from 'react-admin';
import FullPageLoader from 'Components/FullPageLoader';
import dagre from 'dagre';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import SaveIcon from '@material-ui/icons/Save';
import {
  useHistory,
  useParams
} from 'react-router-dom/cjs/react-router-dom.min';
import { getFlowItemTemplateNodeHeight } from './flowHelpers';
import FlowItemTemplateNode from './FlowItemTemplateNode';
import SaveFlowItemTemplateLayoutModal from './Modals/SaveFlowItemTemplateLayoutModal';
import CreateFlowItemTemplateModal from './Modals/CreateFlowItemTemplateModal';

const FlowItemTemplateCanvas = ({
  templateSlug,
  flowItemTemplateSlug,
  flowItemTemplates = [],
  flowTemplates = [],
  selectFlowItemTemplate = () => {},
  updateFlowItem = () => {},
  insertFlowItem = () => {},
  rankdir = 'TB',
  isLoading = false
}) => {
  const history = useHistory();
  const { level = null } = useParams();
  const [createFlowItemSequence, setCreateFlowItemSequence] = useState(null);
  const [isSaveLayoutModalOpen, setIsSaveLayoutModalOpen] = useState(false);
  const [autoLayout, setAutoLayout] = useState(false);
  const [isLayoutStale, setIsLayoutStale] = useState(false);
  const [loading, setLoading] = useState(false);
  const notify = useNotify();
  const reactFlowWrapper = useRef(null);
  const reactFlowInstanceRef = useRef(null);
  const [elements, setElements] = useState([]);
  const onElementsRemove = elementsToRemove =>
    setElements(els => removeElements(elementsToRemove, els));
  const onConnect = params => setElements(els => addEdge(params, els));
  const [selectedRootNode, setSelectedRootNode] = useState(null);
  const [toggleFlowItem, setToggleFlowItem] = useState('Both');

  const onLoad = reactFlowInstance => {
    setTimeout(() => reactFlowInstance.fitView(), 100);
    reactFlowInstanceRef.current = reactFlowInstance;
  };

  const mapFlowItemTemplatesToNodes = useCallback(
    (
      flowItemTemplates,
      nodeHeight = 100,
      rankdir = 'TB',
      autoLayout,
      toggleFlowItem = null
    ) => {
      if (!flowItemTemplates.length) return [];

      const g = new dagre.graphlib.Graph();
      g.setGraph({ rankdir });
      g.setDefaultEdgeLabel(() => ({}));

      let x = 400;
      let y = 0;

      const nodes =
        flowItemTemplates
          .sort((a, b) => a.sequence - b.sequence)
          .map((ft, i) => {
            y = i === 0 ? y : y + nodeHeight;
            const nodeType =
              i === 0
                ? 'input'
                : i + 1 === flowItemTemplates.length
                ? 'output'
                : 'default';
            const calcNodeHeight = getFlowItemTemplateNodeHeight({
              ...ft,
              nodeHeight,
              isEndNode: i + 1 === flowItemTemplates.length,
              isStartNode: i === 0
            });
            const node = {
              index: i,
              id: ft.flowItemId,
              type: nodeType,
              isNextExpression: ft.isNextExpression,
              sequence: ft.sequence,
              data: {
                label: (
                  <FlowItemTemplateNode
                    {...ft}
                    toggleFlowItem={toggleFlowItem}
                    nodeType={nodeType}
                    openCreateFlowTemplateModal={() =>
                      setCreateFlowItemSequence(ft.sequence + 1)
                    }
                  />
                )
              },
              position: { x: ft.xPosition || x, y: ft.yPosition || y }
            };
            g.setNode(node.id, {
              width: 500,
              height: calcNodeHeight
            });
            return node;
          }) ?? [];

      const edges = [];
      const isNextExpressions = [];

      nodes?.forEach((n, i) => {
        const nextNode = nodes.length > i + 1 ? nodes[i + 1] : null;

        if (!nextNode) return;

        if (
          !!nextNode.isNextExpression?.length &&
          isNextExpressions.indexOf(
            e => e.isNextExpression === nextNode.isNextExpression
          ) === -1
        ) {
          isNextExpressions.push(nextNode);
          const nodesInExpressionRange = [];
          let stop = false;

          nodes
            .filter(n => n.index > i)
            .forEach(n => {
              if (n.isNextExpression === nextNode.isNextExpression && !stop) {
                nodesInExpressionRange.push(n);
                return;
              }
              stop = true;
            });
          const maxNodeIndex = Math.max(
            ...nodesInExpressionRange.map(n => n.index)
          );
          if (nodes.length > maxNodeIndex + 1) {
            const nextNodeInExpression = nodes[maxNodeIndex + 1];
            edges.push({
              id: `edge-${n.id}-${nextNodeInExpression.id}`,
              source: n.id,
              target: nextNodeInExpression.id,
              style:
                nextNodeInExpression.id === selectedRootNode
                  ? { strokeWidth: '1px', stroke: 'blue' }
                  : selectedRootNode === n.id
                  ? { strokeWidth: '1px', stroke: 'red' }
                  : {}
            });
            g.setEdge(n.id, nextNodeInExpression.id);
          }
        }

        edges.push({
          id: `edge-${n.id}`,
          source: n.id,
          target: nextNode.id,
          style:
            nextNode?.id === selectedRootNode
              ? { strokeWidth: '1px', stroke: 'blue' }
              : selectedRootNode === n.id
              ? { strokeWidth: '1px', stroke: 'red' }
              : {}
        });

        g.setEdge(n.id, nextNode.id);
      });

      if (g.nodeCount && autoLayout) {
        dagre.layout(g);
        nodes.forEach(n => {
          const node = g.node(n.id);
          if (node) {
            const x = Math.round(node.x);
            const y = Math.round(node.y);
            if (!isLayoutStale && (n.position.x !== x || n.position.y !== y)) {
              setIsLayoutStale(true);
            }
            n.position = { x, y };
          }
        });
      } else {
        setIsLayoutStale(false);
      }

      return [...nodes, ...edges];
    },
    [isLayoutStale, selectedRootNode]
  );

  useEffect(() => {
    const runEffect = async () => {
      setElements(
        mapFlowItemTemplatesToNodes(
          flowItemTemplates,
          100,
          rankdir,
          autoLayout,
          toggleFlowItem
        )
      );
    };
    runEffect();
  }, [
    autoLayout,
    elements?.length,
    flowItemTemplates,
    flowItemTemplates.length,
    mapFlowItemTemplatesToNodes,
    rankdir,
    selectedRootNode,
    toggleFlowItem
  ]);

  // https://reactflow.dev/examples/interaction/
  const onElementClick = (event, node) => {
    const flowItemTemplate = flowItemTemplates.find(
      fi => fi.flowItemId === node.id
    );

    if (!flowItemTemplate) return;

    selectFlowItemTemplate(flowItemTemplate);
    setSelectedRootNode(node.id);
  };

  const onNodeDragStop = async (event, node) => {
    try {
      const flowItemTemplate = flowItemTemplates.find(
        fi => fi.flowItemId === node.id
      );

      if (!flowItemTemplate) return;

      flowItemTemplate.xPosition = node.position.x;
      flowItemTemplate.yPosition = node.position.y;

      await updateFlowItem(flowItemTemplate, flowItemTemplate.sequence, true);
    } catch (e) {
      console.error(e);
      notify(`${e}`, 'error');
    }
  };

  const handleDownloadToPdf = async (reactFlowWrapperRef, isFrame = true) => {
    const wrapper = reactFlowWrapperRef.current;
    if (wrapper) {
      try {
        setLoading(true);
        const originalWidth = wrapper.style.width;
        const originalHeight = wrapper.style.height;

        if (!isFrame) {
          let minX = Infinity,
            minY = Infinity,
            maxX = -Infinity,
            maxY = -Infinity;

          elements.forEach(element => {
            if ('position' in element) {
              // This is a node
              const { x, y } = element.position;
              minX = Math.min(minX, x);
              minY = Math.min(minY, y);
              maxX = Math.max(maxX, x);
              maxY = Math.max(maxY, y);
            }
          });

          const width = maxX - minX;
          const height = maxY - minY;
          wrapper.style.width = `${width + 200}px`;
          wrapper.style.height = `${height + 200}px`;
        }

        const canvas = await html2canvas(wrapper);
        const imgData = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.href = imgData;
        link.download = `${flowItemTemplateSlug}-flowItemTemplates-${
          isFrame ? 'frame' : 'canvas'
        }-${new Date().toISOString()}.png`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        wrapper.style.width = originalWidth;
        wrapper.style.height = originalHeight;
      } catch (e) {
        console.error(e);
        notify(`${e}`, 'error');
      }
      setLoading(false);
    }
  };

  return (
    <>
      <Wrapper ref={reactFlowWrapper}>
        <StyledReactFlow
          key={flowItemTemplateSlug}
          onLoad={onLoad}
          elements={elements}
          snapToGrid={true}
          snapGrid={[15, 15]}
          onElementsRemove={onElementsRemove}
          onElementClick={onElementClick}
          onNodeDragStop={onNodeDragStop}
          onConnect={onConnect}>
          <Controls />
          <Background color="#aaa" gap={16} />
          <MiniMap
            nodeColor={node => {
              switch (node.type) {
                case 'input':
                  return 'rgb(0,0,255)';
                case 'default':
                  return '#00ff00';
                case 'output':
                  return 'red';
                default:
                  return '#eee';
              }
            }}
            nodeStrokeWidth={3}
          />
          <div
            style={{
              position: 'absolute',
              left: 10,
              top: 10,
              zIndex: 999,
              minWidth: '130px'
            }}>
            <FormControl style={{ width: '130px' }}>
              <InputLabel>Flow Template Level</InputLabel>
              <Select
                value={level === null ? -1 : parseInt(level, 10)}
                disabled={!flowTemplates.length}
                onChange={e =>
                  history.push(
                    `/flows/${templateSlug}${
                      e.target.value > -1 ? `/${e.target.value}` : ''
                    }`
                  )
                }
                variant="filled">
                {[
                  { value: -1, label: 'All' },
                  { value: 0, label: 'Parents' },
                  { value: 1, label: 'Children' }
                ]
                  .filter(
                    l =>
                      l.value <=
                        Math.max(
                          ...[...new Set(flowTemplates.map((f, i) => f.level))]
                        ) || l.value === -1
                  )
                  .map((l, i) => (
                    <MenuItem key={i} value={l.value}>
                      {l.label}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl style={{ width: '200px', margin: '0 0 0 0.5rem' }}>
              <InputLabel>Flow Item Templates</InputLabel>
              <Select
                value={flowItemTemplateSlug}
                disabled={!flowTemplates?.find(ft => !!ft.flowItemTemplateSlug)}
                onChange={e =>
                  history.push(`/flows/${templateSlug}/0/${e.target.value}`)
                }
                variant="filled">
                {flowTemplates
                  .filter(ft => !!ft.flowItemTemplateSlug)
                  .filter(
                    (ft, i, self) =>
                      self.findIndex(
                        t => t.flowItemTemplateSlug === ft.flowItemTemplateSlug
                      ) === i
                  )
                  .map((ft, i) => (
                    <MenuItem key={i} value={ft.flowItemTemplateSlug}>
                      {ft.flowItemTemplateSlug}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl style={{ width: '200px', margin: '0 0 0 0.5rem' }}>
              <InputLabel>View</InputLabel>
              <Select
                value={toggleFlowItem}
                disabled={!flowItemTemplates?.length}
                onChange={e => setToggleFlowItem(e.target.value)}
                variant="filled">
                {[
                  { value: 'Both', label: 'Both' },
                  { value: 'Flow Item Preview', label: 'Flow Item Preview' },
                  { value: 'Flow Item Info', label: 'Flow Item Info' }
                ].map((ft, i) => (
                  <MenuItem key={i} value={ft.value}>
                    {ft.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div
            style={{ position: 'absolute', right: 220, top: 10, zIndex: 999 }}>
            <Button
              variant="contained"
              color="default"
              disabled={!flowItemTemplates.length}
              style={{ backgroundColor: autoLayout ? '#90EE90' : '' }}
              onClick={() => {
                setElements(
                  mapFlowItemTemplatesToNodes(
                    flowItemTemplates,
                    100,
                    rankdir,
                    !autoLayout
                  )
                );
                setAutoLayout(!autoLayout);
              }}
              startIcon={<DeviceHubIcon />}>
              Auto Layout
            </Button>
          </div>
          <div
            style={{ position: 'absolute', right: 120, top: 10, zIndex: 999 }}>
            <Button
              variant="contained"
              color="default"
              disabled={!isLayoutStale || !flowItemTemplates.length}
              onClick={() => setIsSaveLayoutModalOpen(true)}
              startIcon={<SaveIcon />}>
              Save
            </Button>
          </div>
          <div
            style={{ position: 'absolute', right: 10, top: 10, zIndex: 999 }}>
            <Button
              variant="contained"
              color="default"
              disabled={!flowItemTemplates.length}
              onClick={() => {
                setLoading(true);
                handleDownloadToPdf(reactFlowWrapper, true);
                setLoading(false);
              }}
              startIcon={<PhotoLibraryIcon />}>
              Frame
            </Button>
          </div>
        </StyledReactFlow>
      </Wrapper>
      <FullPageLoader loading={loading} />
      {!!flowItemTemplates.length ? (
        <SaveFlowItemTemplateLayoutModal
          isModalOpen={isSaveLayoutModalOpen}
          setIsModalOpen={setIsSaveLayoutModalOpen}
          templateSlug={flowItemTemplateSlug}
          flowItems={flowItemTemplates || []}
          nodes={elements.filter(e => 'position' in e)}
        />
      ) : (
        <CreateFlowItemTemplateModal
          isModalOpen={!loading && !isLoading}
          setIsModalOpen={() => {}}
          templateSlug={flowItemTemplateSlug}
          flowItems={flowItemTemplates || []}
          insertFlowItem={insertFlowItem}
          updateFlowItem={updateFlowItem}
        />
      )}
      <CreateFlowItemTemplateModal
        isModalOpen={createFlowItemSequence !== null}
        setIsModalOpen={() => setCreateFlowItemSequence(null)}
        templateSlug={flowItemTemplateSlug}
        flowItems={flowItemTemplates || []}
        insertFlowItem={insertFlowItem}
        updateFlowItem={updateFlowItem}
        flowItem={{ sequence: createFlowItemSequence }}
      />
    </>
  );
};

/**
 * https://reactflow.dev/docs/theming/
 */
const StyledReactFlow = styled(ReactFlow)`
  .react-flow__node {
    border-radius: 8px;
    min-width: 400px;
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.3);
    border: 2px solid #999;
  }
  .react-flow__node:hover {
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.3);
  }
  .selected {
    border: 2px solid #555;
  }
  height: 100%;
  width: 100%;
`;

const Wrapper = styled.div`
  height: 100%;
  z-index: 998;
`;

export default FlowItemTemplateCanvas;
