import { sanitizeListRestProps, TopToolbar } from 'react-admin';
import StudentMergeModal from './StudentMergeModal';
import { useState } from 'react';
import { Button } from '@material-ui/core';
import MergeTypeIcon from '@material-ui/icons/MergeType';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    justifyContent: 'flex-end!important',
    WebkitJustifyContent: 'flex-end!important',
    WebKitBoxPack: 'end!important',
    width: '100%'
  },
  createButton: {
    color: '#4353ff',
    fontSize: '0.8125rem',
    padding: '4px',
    border: 'none',
    background: 'none',
    boxShadow: 'none',
    float: 'right',
    margin: '0 10px 0 0'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '600px'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  }
}));

const StudentListActions = props => {
  const { className, maxResults, ...rest } = props;
  const classes = useStyles();
  const [isStudentMergeModalOpen, setIsStudentMergeModalOpen] = useState(false);
  return (
    <>
      <TopToolbar className={classes.root} {...sanitizeListRestProps(rest)}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<MergeTypeIcon />}
          onClick={() => setIsStudentMergeModalOpen(true)}>
          Merge Students
        </Button>
      </TopToolbar>
      <StudentMergeModal
        isOpen={isStudentMergeModalOpen}
        setIsOpen={setIsStudentMergeModalOpen}
      />
    </>
  );
};
export default StudentListActions;
