import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import ToolTip from 'Components/ToolTip';

const useStyles = makeStyles(theme => ({
  levelBox: {
    padding: '2px',
    textAlign: 'center',
    boxSizing: 'border-box',
    border: '1px solid #ddd',
    cursor: 'pointer'
  },
  notStarted: {
    background: '#eee'
  },
  inProgress: {
    background: 'rgb(222, 195, 120)'
  },
  completed: {
    background: 'rgb(120, 222, 169)'
  },
  currentLevel: {
    border: '1px solid red'
  }
}));

const Progress = props => {
  const { progress } = props;
  const classes = useStyles();
  if (!progress) return null;
  return (
    <Grid container direction="row" alignItems="flex-start">
      {progress.levelProgress?.map((l, i) => {
        const levelProgressStyle = () =>
          l.startedQuizCount > 0
            ? l.completedQuizCount === l.quizCount
              ? classes.completed
              : classes.inProgress
            : classes.notStarted;
        const currentLevel =
          progress.currentLevel === l.level ? classes.currentLevel : null;
        return (
          <Grid
            item
            xs={2}
            className={clsx(
              classes.levelBox,
              levelProgressStyle(),
              currentLevel
            )}
            key={i}>
            <ToolTip
              text={`Quiz Count: ${l.quizCount}, Started Quizzes: ${l.startedQuizCount}, Completed Quizzes: ${l.completedQuizCount}`}
              placement="top">
              <div>{l.level}</div>
            </ToolTip>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default Progress;
