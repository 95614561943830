import { Box } from '@material-ui/core';
import TabPanel from 'Components/TabPanel';
import { Delete } from '@material-ui/icons';
import SaveConfirmButton from '../SaveConfirmButton';
import EditFlowItemForm from './EditFlowItemForm';
import EditFlowTelemetryForm from './EditFlowTelemetryForm';
import styled from 'styled-components';

const EditFlowItemTemplate = ({
  flowItem = {},
  flowItemTemplates = [],
  templateSlug,
  flowTemplates = [],
  loading = false,
  updateFlowItem = () => {},
  deleteFlowItem = () => {},
  ...props
}) => {
  const tabs = [
    {
      label: 'Edit Flow Item',
      children: (
        <div {...props}>
          <EditFlowItemForm
            key={flowItem?.sequence}
            title={''}
            flowItem={flowItem}
            flowItems={flowItemTemplates}
            flowTemplates={flowTemplates}
            updateFlowItem={updateFlowItem}
          />
          {!!flowItem && (
            <SaveConfirmButton
              key={flowItem?.sequence}
              isDoubleConfirm={true}
              submitting={loading}
              confirmCopy="Confirm Delete?"
              loadingCopy="Deleting..."
              buttonCopy="Delete"
              icon={<Delete />}
              pristine={false}
              style={{ width: '100%', padding: 0, margin: 0 }}
              onClick={() => deleteFlowItem(flowItem)}
            />
          )}
        </div>
      )
    },
    {
      label: 'Telemetry',
      children: (
        <EditFlowTelemetryForm
          key={flowItem?.sequence}
          flowItem={flowItem}
          updateFlowItem={updateFlowItem}
          loading={loading}
        />
      )
    }
  ];

  if (!flowItem)
    return (
      <Box>
        <NoTemplate>No item selected</NoTemplate>
      </Box>
    );

  return (
    <Box>
      <TabPanel tabs={tabs} />
    </Box>
  );
};

const NoTemplate = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem;
  color: #aaa;
`;

export default EditFlowItemTemplate;
