import { makeStyles } from '@material-ui/core/styles';
import {
  Modal,
  Backdrop,
  TextField,
  Button,
  Checkbox,
  Select,
  FormLabel,
  FormGroup,
  MenuItem,
  FormControlLabel
} from '@material-ui/core';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import React, { useCallback } from 'react';
import { useNotify, Notification } from 'react-admin';
import CheckCircle from '@material-ui/icons/CheckCircle';
import { Form, Field } from 'react-final-form';
import Api from 'Api/Api';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '600px'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  container: {
    minWidth: '600px'
  },
  input: {
    width: '100%',
    margin: '0.5rem 0 1rem'
  },
  select: {
    width: '100%',
    margin: '1rem 0 1rem'
  },
  formControl: {
    width: '100%'
  },
  button: {
    float: 'right',
    margin: '0.5rem 0 0 1rem'
  }
}));

const UnClaimLessonModal = ({
  isModalOpen,
  setisModalOpen,
  flowGeneratorSessionId
}) => {
  const classes = useStyles();
  const notify = useNotify();
  const dispatch = useDispatch();

  const onSubmit = useCallback(
    async values => {
      try {
        await Api.updateFlowGeneratorSessionLessonIntervention(
          flowGeneratorSessionId,
          values
        );
        setisModalOpen();
        dispatch({
          type: 'uxState/set',
          payload: { lessonListAutoScrollEnabled: false }
        });
      } catch (e) {
        notify(
          'There was an error closing this intervention, message Sam Wheeler if the issues persist.',
          'error'
        );
        console.error(e);
      }
    },
    [dispatch, flowGeneratorSessionId, notify, setisModalOpen]
  );

  if (!flowGeneratorSessionId) return null;

  return (
    <Modal
      open={isModalOpen}
      onClose={() => setisModalOpen(false)}
      className={classes.modal}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
      closeAfterTransition>
      <div className={classes.paper}>
        <h3>Unclaim Lesson</h3>
        <Form
          onSubmit={onSubmit}
          initialValues={{
            studentResponded: true,
            isFlaggedAsOffensive: false,
            tutorSatisfactionRating: 50
          }}
          validate={values => {
            const errors = {};
            return errors;
          }}>
          {({ handleSubmit, submitting, pristine, submitError }) => (
            <form className={classes.container} onSubmit={handleSubmit}>
              <FormGroup>
                <Field name="studentResponded" type="checkbox">
                  {({ input, meta }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...input}
                          error={meta.error && meta.touched}
                          name="studentResponded"
                          id="studentResponded"
                        />
                      }
                      label="Did the student respond?"
                    />
                  )}
                </Field>
              </FormGroup>
              <FormGroup>
                <Field name="isFlaggedAsOffensive" type="checkbox">
                  {({ input, meta }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...input}
                          error={meta.error && meta.touched}
                          name="isFlaggedAsOffensive"
                          id="isFlaggedAsOffensive"
                        />
                      }
                      label="Flag as offensive or rude?"
                    />
                  )}
                </Field>
              </FormGroup>
              <FormGroup style={{ margin: '1rem 0 1rem 0' }}>
                <FormLabel>How would you rate the intervention?</FormLabel>
                <Field name="tutorSatisfactionRating">
                  {({ input, meta }) => (
                    <Select
                      {...input}
                      name="tutorSatisfactionRating"
                      error={meta.error && meta.touched}>
                      {[
                        { key: 'Very Good', value: 100 },
                        { key: 'Good', value: 75 },
                        { key: 'Average', value: 50 },
                        { key: 'Poor', value: 25 },
                        { key: 'Very Poor', value: 0 }
                      ].map((s, idx) => (
                        <MenuItem key={idx} value={s.value}>
                          {s.key}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </Field>
              </FormGroup>
              <FormGroup>
                <Field name="tutorNotes">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label="Notes"
                      type="text"
                      multiline
                      rows={4}
                      variant="filled"
                      className={classes.input}
                      error={meta.error && meta.touched}
                      helperText={
                        meta.error && meta.touched ? meta.error : null
                      }
                    />
                  )}
                </Field>
              </FormGroup>
              <Button
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                className={classes.button}
                startIcon={<CheckCircle />}
                disabled={submitting || submitError}>
                Close Intervention
              </Button>
            </form>
          )}
        </Form>
        <Notification />
      </div>
    </Modal>
  );
};

export default UnClaimLessonModal;
