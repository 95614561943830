import {
  useListContext,
  TopToolbar,
  ExportButton,
  sanitizeListRestProps,
  Loading
} from 'react-admin';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Modal,
  Backdrop,
  Container,
  Card,
  CardContent,
  Typography
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CancelIcon from '@material-ui/icons/Cancel';
import { useCallback } from 'react';
import Api from 'Api/Api';
import { useNotify } from 'ra-core/esm/sideEffect';
import useUploadFile from 'Hooks/useUploadFile';
import { useEffect, useState } from 'react';
import ToggleSwitch from 'Components/ToggleSwitch';
import { useRefresh } from 'react-admin';

const useStyles = makeStyles(theme => ({
  root: {
    justifyContent: 'flex-end!important',
    WebkitJustifyContent: 'flex-end!important',
    WebKitBoxPack: 'end!important',
    width: '100%'
  },
  createButton: {
    color: '#4353ff',
    fontSize: '0.8125rem',
    padding: '4px',
    border: 'none',
    background: 'none',
    boxShadow: 'none',
    float: 'right',
    margin: '0 10px 0 0'
  },
  uploadButton: {
    margin: '0 10px 0 0'
  },
  cancelButton: {},
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  fileDetails: {
    display: 'flex'
  },
  successBox: {
    margin: '1rem 0 1rem 0',
    background: '#d1f5c9',
    padding: '1rem'
  },
  errorBox: {
    margin: '1rem 0 1rem 0',
    background: '#fab8aa',
    padding: '1rem'
  },
  loading: {
    maxHeight: '250px',
    margin: 0
  }
}));

const WorksheetQuestionListActions = props => {
  const notify = useNotify();
  const classes = useStyles();
  const history = useHistory();
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [currentFile, setCurrentFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadResponse, setUploadResponse] = useState(null);
  const [isHeaderRow, setIsHeaderRow] = useState(true);
  const { className, maxResults, ...rest } = props;
  const { total } = useListContext();
  const refresh = useRefresh();
  const isChatBased = false;
  const {
    file,
    onFileChange,
    FileDetails,
    fileRef,
    clearFile,
    getSetFormData
  } = useUploadFile(isChatBased, ['.csv']);

  const closeModal = useCallback(() => {
    setIsUploadModalOpen(false);
    clearFile();
    setUploadResponse(null);
    setCurrentFile(null);
    setIsHeaderRow(true);
    refresh();
  }, [clearFile, refresh]);

  const uploadFile = useCallback(async () => {
    if (!file) return;
    try {
      setUploadResponse(null);
      setIsLoading(true);
      const formData = getSetFormData();
      formData.append('HasHeaderRow', isHeaderRow);
      const response = await Api.uploadWorksheetQuestions(formData);
      setUploadResponse(response);
      setIsLoading(false);
    } catch (e) {
      notify(e.toString(), 'warning');
      console.error(e);
      setIsLoading(false);
    }
  }, [file, getSetFormData, isHeaderRow, notify]);

  useEffect(() => {
    const runEffect = async () => {
      if (file?.name && currentFile?.name !== file?.name) {
        setIsUploadModalOpen(true);
        setCurrentFile(file);
      }
      if (!file && currentFile) {
        setCurrentFile(null);
        setIsUploadModalOpen(false);
      }
    };
    runEffect();
  }, [currentFile, file, file?.name]);

  return (
    <>
      <TopToolbar className={classes.root} {...sanitizeListRestProps(rest)}>
        <Button
          disabled={false}
          type="file"
          variant="contained"
          color="default"
          component="label"
          className={classes.createButton}
          startIcon={<CloudUploadIcon />}>
          Upload
          <input type="file" ref={fileRef} onChange={onFileChange} hidden />
        </Button>
        <Button
          variant="contained"
          color="default"
          className={classes.createButton}
          startIcon={<AddIcon />}
          onClick={r => history.push(`/worksheet-questions/create`)}>
          Create Question
        </Button>
        <ExportButton disabled={total === 0} maxResults={maxResults} />
      </TopToolbar>
      <Modal
        open={isUploadModalOpen}
        onClose={() => closeModal()}
        className={classes.modal}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
        closeAfterTransition>
        <Container maxWidth="sm">
          <Card className={classes.create}>
            <CardContent>
              <Typography variant="h6">Upload File</Typography>
              <Typography variant="body1">
                Check the file before uploading
              </Typography>
              {isLoading ? (
                <Loading className={classes.loading} />
              ) : (
                <FileDetails className={classes.fileDetails} />
              )}
              {uploadResponse && (
                <>
                  <Typography variant="h6">Upload Result</Typography>

                  {uploadResponse.isSuccess ? (
                    <div className={classes.successBox}>
                      <Typography variant="body1">
                        <strong>Records imported: </strong>
                        {uploadResponse.recordsImportedCount}
                        <br />
                      </Typography>
                    </div>
                  ) : (
                    <div className={classes.errorBox}>
                      <Typography variant="body1">
                        <strong>Errors: </strong> {uploadResponse.errors}
                        <br />
                      </Typography>
                    </div>
                  )}
                </>
              )}
              {!uploadResponse?.isSuccess && (
                <>
                  <ToggleSwitch
                    name="isHeaderRow"
                    input={{
                      onChange: setIsHeaderRow,
                      value: isHeaderRow
                    }}
                    label="Has Header Row"
                    meta={{ error: null }}
                    invert={false}
                  />
                  <Button
                    disabled={isLoading}
                    variant="contained"
                    color="primary"
                    size="large"
                    type="submit"
                    component="label"
                    className={classes.uploadButton}
                    startIcon={<CloudUploadIcon />}
                    onClick={() => uploadFile()}>
                    Upload
                  </Button>
                </>
              )}
              <Button
                disabled={isLoading}
                variant="contained"
                color="secondary"
                size="large"
                className={classes.cancelButton}
                onClick={() => closeModal()}
                startIcon={<CancelIcon />}>
                Close
              </Button>
            </CardContent>
          </Card>
        </Container>
      </Modal>
    </>
  );
};

export default WorksheetQuestionListActions;
