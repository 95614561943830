import flowItemGroupType from 'Helpers/flowItemGroupType';

const extractProgress = (
  flowItemGroupInputs,
  isExtractingAllWorksheetAnswers = true,
  isIncludingCheckoutQuestions = false,
  width = 12,
  height = 12,
  style = {}
) => {
  if (!flowItemGroupInputs) return [];

  const progress = [];
  let questionSequence = 1;

  flowItemGroupInputs
    .filter(
      (fig, i) =>
        flowItemGroupInputs.findIndex(fi => {
          return fi.flowItemGroup === fig.flowItemGroup;
        }) === i
    )
    .sort((a, b) => a.dateCreated - b.dateCreated)
    .forEach((fi, i) => {
      if (
        fi.flowItemGroupType === flowItemGroupType.diagnosticQuestionAnswer &&
        !fi.flowItemGroup.includes('CheckOutQuestion')
      ) {
        if (progress.find(pi => pi.questionSequence === questionSequence)) {
          questionSequence++;
        }
        progress.push({
          flowItemGroup: fi.flowItemGroup,
          questionSequence: questionSequence,
          flowItemGroupType: fi.flowItemGroupType,
          isCorrect: fi.isValidatorSuccess,
          isAnswered: !!fi.dateInput,
          width,
          height,
          style
        });
      } else if (
        fi.flowItemGroupType ===
          flowItemGroupType.diagnosticQuestionRetryAnswer &&
        !fi.flowItemGroup.includes('CheckOutQuestion')
      ) {
        progress.push({
          flowItemGroup: fi.flowItemGroup,
          questionSequence: questionSequence,
          flowItemGroupType: fi.flowItemGroupType,
          isCorrect: fi.isValidatorSuccess,
          isAnswered: !!fi.dateInput,
          width,
          height,
          style
        });
      } else if (
        fi.flowItemGroupType === flowItemGroupType.diagnosticQuestionAnswer &&
        fi.flowItemGroup.includes('CheckOutQuestion') &&
        isIncludingCheckoutQuestions
      ) {
        progress.push({
          flowItemGroup: fi.flowItemGroup,
          questionSequence: questionSequence,
          flowItemGroupType: flowItemGroupType.checkoutQuestion,
          isCorrect: fi.isValidatorSuccess,
          isAnswered: !!fi.dateInput,
          width,
          height,
          style
        });
      } else if (
        fi.flowItemGroupType ===
          flowItemGroupType.diagnosticQuestionRetryAnswer &&
        fi.flowItemGroup.includes('CheckOutQuestion') &&
        isIncludingCheckoutQuestions
      ) {
        progress.push({
          flowItemGroup: fi.flowItemGroup,
          questionSequence: questionSequence,
          flowItemGroupType: flowItemGroupType.checkoutQuestionRetry,
          isCorrect: fi.isValidatorSuccess,
          isAnswered: !!fi.dateInput,
          width,
          height,
          style
        });
      } else if (
        fi.flowItemGroup.includes('LessonPracticeQuestionOurAnswerOutro') &&
        (isExtractingAllWorksheetAnswers ||
          !progress.find(
            pi =>
              pi.flowItemGroupType ===
                flowItemGroupType.worksheetQuestionAnswer &&
              pi.questionSequence === questionSequence
          ))
      ) {
        progress.push({
          flowItemGroup: fi.flowItemGroup,
          questionSequence: questionSequence,
          flowItemGroupType: flowItemGroupType.worksheetQuestionAnswer,
          isCorrect: fi.isValidatorSuccess,
          isAnswered: !!fi.dateInput,
          width,
          height,
          style
        });
      }
    });

  return progress;
};

export default extractProgress;
