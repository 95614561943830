import { makeStyles, Modal, Paper } from '@material-ui/core';
import { useCallback, useEffect, useState } from 'react';
import LoadingModal from 'Pages/QuestionExtractor/Components/LoadingModal';
import { useDataProvider, useNotify } from 'react-admin';
import { MisconceptionTag } from '../MisconceptionTag';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    display: 'flex',
    flexDirection: 'column',
    minWidth: '500px',
    maxWidth: '1000px',
    height: '768px',
    overflowY: 'scroll'
  }
}));

const MisconceptionTagModal = ({ open, onClose, record }) => {
  const dataProvider = useDataProvider();
  const classes = useStyles();
  const notify = useNotify();
  const [question, setQuestion] = useState(record);
  const [isLoaded, setIsLoaded] = useState(false);

  const fetchQuestion = useCallback(
    async id => {
      try {
        if (!id) return;

        const result = await dataProvider.getOne(
          'topic-pathway-quiz-question-insights',
          {
            id
          }
        );

        if (!result?.data)
          throw new Error(`Unable to fetch flow question ${id}`);

        setQuestion(() => result.data);
      } catch (e) {
        notify(`${e}`, 'error');
        console.error(e);
      }
    },
    [dataProvider, notify]
  );

  useEffect(() => {
    const runEffect = async () => {
      if (isLoaded || !open || !record?.id) return;

      await fetchQuestion(record.id);
      setIsLoaded(true);
    };
    runEffect();
  }, [fetchQuestion, isLoaded, open, record?.id]);

  if (!question) return null;

  if (!isLoaded) return <LoadingModal title="Loading..." />;

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={() => {
        onClose();
        setIsLoaded(false);
      }}
      key={`${question.questionId}`}>
      <Paper className={classes.paper}>
        <MisconceptionTag record={question} />
      </Paper>
    </Modal>
  );
};

export default MisconceptionTagModal;
