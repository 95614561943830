import 'react-big-calendar/lib/css/react-big-calendar.css';
import TimeGrid from 'react-big-calendar/lib/TimeGrid';
import { Navigate } from 'react-big-calendar';
import React from 'react';
import * as dates from 'date-arithmetic';

class Week extends React.Component {
  render() {
    const { date } = this.props;
    const range = Week.range(date);
    return <TimeGrid {...this.props} range={range} eventOffset={15} />;
  }
}

Week.range = date => {
  let start = date;
  let end = dates.add(start, 7, 'day');

  let current = start;
  let range = [];

  while (dates.lte(current, end, 'day')) {
    range.push(current);
    current = dates.add(current, 1, 'day');
  }

  return range;
};

Week.navigate = (date, action) => {
  switch (action) {
    case Navigate.PREVIOUS: {
      return dates.add(date, -7, 'day');
    }

    case Navigate.NEXT: {
      return dates.add(date, 7, 'day');
    }

    default: {
      return date;
    }
  }
};

Week.title = date => {
  return date.toLocaleDateString();
};

export default Week;
