import { useCallback, useEffect, useRef, useState } from 'react';
import { Layer, Line, Stage } from 'react-konva';
import styled from 'styled-components';
import URLImage from './components/URLImage';
import useGetGrid from './hooks/useGetGrid';
import useDrawing from './hooks/useDrawing';
import { colorPalette, thicknessOpts } from './helpers/toolHelpers';
import useUploadImage from './hooks/useUploadImage';
import EditableText from './components/EditableText';
import ToolButton from './components/ToolButton';
import { useParams } from 'react-router-dom';
import Api from 'Api/Api';
import {
  convertToCircle,
  convertToRectangle,
  convertToTriangle
} from './helpers/whiteboardHelpers';
import { useJoinFlowSessionSignalRGroup } from 'Hooks/useJoinFlowSessionSignalRGroup';
import { useSignalROn } from 'Hooks/useSignalROn';
import useGetAuthUser from 'Hooks/useGetAuthUser';
import { generateGUID } from './helpers/stageHelpers';
import useTrackPointer from './hooks/useTrackPointer';
import useDrawPointers from './hooks/useDrawPointers';
import useQuery from 'Hooks/useQuery';

const defaultState = {
  shapes: [],
  lines: [],
  images: [],
  text: []
};

const WhiteboardPage = ({}) => {
  // get image and text url query params
  const query = useQuery();
  const questionImageUrl = query.get('image');
  const questionText = query.get('text');

  const user = useGetAuthUser();
  const { sessionId, inputId } = useParams();
  const [loadedSession, setLoadedSession] = useState(null);
  const { signalRConnection } = useJoinFlowSessionSignalRGroup(sessionId);

  console.log('WhiteboardPage', sessionId, inputId);

  // Grid
  const {
    wrapperRef,
    grid,
    gridSpacing,
    setGridSpacing,
    gridType,
    setGridType,
    stageSize
  } = useGetGrid({});

  useTrackPointer({
    label: 'tutor',
    stageRef: wrapperRef,
    onUpdate: p => {
      try {
        if (!sessionId || !signalRConnection || !user.id) return;

        const payload = {
          SessionId: parseInt(sessionId, 10),
          UserId: user.id,
          X: p.x,
          Y: p.y,
          Label: user?.firstName ? `tutor - ${user.firstName}` : p.label
        };

        console.log(payload, user);

        signalRConnection.invoke('DispatchPointerEvent', payload);
      } catch (e) {
        console.error(e, p);
      }
    }
  });
  const pointers = useDrawPointers({
    sessionId,
    currentUserId: user?.id,
    stageRef: wrapperRef
  });
  const [selectedId, setSelectedId] = useState(null);

  // History
  const historyRef = useRef([]);
  const futureRef = useRef([]);

  // Tools
  const transformStateRef = useRef({ isTransforming: false });
  const [previousTool, setPreviousTool] = useState('pen');

  // Drawing
  const isDrawing = useRef(false);
  const {
    setShapes,
    setLines,
    shapes,
    lines,
    tool,
    setTool,
    color,
    setColor,
    thickness,
    setThickness,
    handleLineDown,
    handleShapeDown,
    handlePenMove,
    handleLineMove,
    handleShapeMove,
    clearDrawing,
    renderShape
  } = useDrawing({
    shapeList: [],
    lineList: [],
    currentTool: 'pen',
    currentThickness: 7,
    currentColor: colorPalette[0]
  });

  const [images, setImages] = useState([]);
  const [text, setText] = useState([]);

  const setTransformingState = useCallback(
    isTransforming => {
      setTool(isTransforming ? 'transform' : previousTool);
      transformStateRef.current.isTransforming = isTransforming;
      setPreviousTool(isTransforming ? tool : 'pen');
    },
    [previousTool, setTool, tool]
  );

  const saveState = useCallback(
    ({ lines, shapes, images, text }, saveHistory = true, saveToApi = true) => {
      setImages(images);
      setText(text);
      setLines(lines);
      setShapes(shapes);

      if (saveHistory) {
        historyRef.current = [
          ...historyRef.current,
          { lines, shapes, images, text }
        ];
        futureRef.current = [];
      }

      if (saveToApi && sessionId) {
        try {
          Api.upsertWhiteboardSession(sessionId, {
            lines,
            shapes,
            images: images.map(i => ({
              ...i,
              payload: null,
              formData: null,
              file: null,
              src: i.src?.split('?')[0]
            })),
            text,
            imageSrcs: images
              .filter(i => !!i.src)
              .map(i => i.src.split('?')[0])
              .filter(i => !!i),
            imageSasTokens: images
              .filter(i => !!i.src)
              .map(i => i.src.split('?')[1])
              .filter(i => !!i),
            inputId
          });
        } catch (e) {
          console.error(e);
        }
      }
    },
    [inputId, sessionId, setLines, setShapes]
  );

  const saveQueryContent = useCallback(async () => {
    if (questionImageUrl || questionText) {
      const newImages =
        !images?.length && questionImageUrl
          ? [
              {
                src: questionImageUrl,
                x: 100,
                y: 100,
                width: 400,
                height: 300,
                isLocked: true
              }
            ]
          : images;
      const newText =
        !text?.length && questionText
          ? [
              {
                text: questionText,
                x: 150,
                y: 50,
                width: 400,
                height: 150,
                fontSize: 14
              }
            ]
          : text;
      saveState(
        { lines, shapes, images: newImages, text: newText },
        true,
        true
      );
    }
  }, [images, lines, questionImageUrl, questionText, saveState, shapes, text]);

  const loadSession = useCallback(async () => {
    try {
      if (loadedSession === sessionId || !sessionId) return;
      setLoadedSession(sessionId);
      const session = !!inputId
        ? await Api.getWhiteboardSessionForInput(sessionId, inputId)
        : await Api.getWhiteboardSession(sessionId);

      if (
        session?.lines?.length ||
        session?.shapes?.length ||
        session?.images?.length ||
        session?.text?.length
      ) {
        saveState(session, true, false);
      } else {
        await saveQueryContent();
      }
    } catch (e) {
      console.error(e);
    }
  }, [inputId, loadedSession, saveQueryContent, saveState, sessionId]);

  useEffect(() => {
    if (sessionId) {
      loadSession();
    }
  }, [loadSession, sessionId]);

  const updateImage = useCallback(
    async (newImage, index = null) => {
      let imagesCopy = [...images];
      let newSelectedId =
        index !== null && index !== undefined
          ? `image-${index}`
          : `image-${imagesCopy.length}`;
      if (!newImage) {
        imagesCopy = imagesCopy.filter((img, i) => i !== index);
        newSelectedId = null;
      } else if (index !== null && index !== undefined) {
        imagesCopy[index] = { ...imagesCopy[index], ...newImage };
      } else {
        try {
          newImage.src =
            sessionId && newImage.payload
              ? await Api.uploadImage(sessionId, newImage.payload)
              : newImage.src;
          newImage.formData = null;
        } catch (e) {
          console.error(e);
        }
        imagesCopy.push(newImage);
      }
      setSelectedId(newSelectedId);
      saveState({ lines, shapes, images: imagesCopy, text });
    },
    [images, lines, saveState, sessionId, shapes, text]
  );

  const { button } = useUploadImage({ updateImage });

  useSignalROn(`WhiteboardUpdated`, payload => {
    try {
      const state = JSON.parse(payload);

      if (
        user.id === state.userId ||
        state.sessionId !== parseInt(sessionId, 10)
      )
        return;

      saveState(
        {
          lines: JSON.parse(state.lines),
          images: JSON.parse(state.images),
          shapes: JSON.parse(state.shapes),
          text: JSON.parse(state.text)?.map(t => ({
            ...t,
            guid: generateGUID()
          }))
        },
        true,
        false
      );
    } catch (e) {
      console.error(e);
    }
  });

  const updateText = useCallback(
    (newText, index = null) => {
      let textCopy = [...text];
      let newSelectedId =
        index !== null && index !== undefined
          ? `text-${index}`
          : `text-${textCopy.length}`;
      if (newText === null || newText === undefined) {
        textCopy = textCopy.filter((t, i) => i !== index);
        newSelectedId = null;
      } else if (index !== null && index !== undefined) {
        textCopy[index] = { ...textCopy[index], ...newText };
      } else {
        textCopy.push(newText);
      }
      setSelectedId(newSelectedId);
      saveState({ lines, shapes, images, text: textCopy });
    },
    [images, lines, saveState, shapes, text]
  );

  const handleUndo = useCallback(() => {
    if (historyRef?.current?.length > 0) {
      const newHistory = [...historyRef.current];
      const lastState = newHistory.pop();
      historyRef.current = newHistory;
      futureRef.current = [lastState, ...futureRef.current];
      if (newHistory.length > 0) {
        const {
          lines: newLines,
          shapes: newShapes,
          images: newImages,
          text: newText
        } = newHistory[newHistory.length - 1];
        saveState(
          {
            lines: newLines || [],
            shapes: newShapes || [],
            images: newImages || [],
            text: newText || []
          },
          false
        );
      } else {
        saveState(defaultState, false);
      }
    }
  }, [saveState]);

  const handleRedo = useCallback(() => {
    if (futureRef?.current?.length > 0) {
      const [firstState, ...newFuture] = futureRef.current;
      futureRef.current = newFuture;
      historyRef.current = [...historyRef.current, firstState];
      saveState(firstState, false);
    }
  }, [saveState]);

  const handleMouseDown = useCallback(
    e => {
      setSelectedId(null);
      if (transformStateRef.current.isTransforming) return;

      isDrawing.current = true;
      const pos = e.target.getStage().getPointerPosition();

      switch (tool) {
        case 'pen':
          handleLineDown(pos);
          break;
        case 'line':
          handleLineDown(pos);
          break;
        case 'triangle':
        case 'rect':
        case 'circle':
          handleShapeDown(pos);
          break;
        default:
          break;
      }
    },
    [handleLineDown, handleShapeDown, tool]
  );

  const handleMouseMove = useCallback(
    e => {
      if (!isDrawing.current || transformStateRef.current.isTransforming) {
        return;
      }

      const stage = e.target.getStage();
      const point = stage.getPointerPosition();

      switch (tool) {
        case 'pen':
          handlePenMove(point);
          break;
        case 'line':
          handleLineMove(point);
          break;
        case 'triangle':
        case 'rect':
        case 'circle':
          handleShapeMove(point);
          break;
        default:
          break;
      }
    },
    [handleLineMove, handlePenMove, handleShapeMove, tool]
  );

  const handleMouseUp = useCallback(() => {
    if (transformStateRef.current.isTransforming) return;

    isDrawing.current = false;
    // handleFinishDrawing();
    if (tool === 'pen') {
      let lastLine = lines[lines.length - 1];
      const newLines = lines.concat(
        convertToTriangle(convertToCircle(convertToRectangle(lastLine)))
      );
      saveState({ lines: newLines, shapes, images, text });
    } else {
      saveState({ lines, shapes, images, text });
    }
  }, [images, lines, saveState, shapes, text, tool]);

  const clearAll = useCallback(() => {
    clearDrawing();
    setImages([]);
    setText([]);
    saveState({ lines: [], shapes: [], images: [], text: [] });
    selectedId && setSelectedId(null);
  }, [clearDrawing, saveState, selectedId]);

  const chooseTool = useCallback(
    chosenTool => {
      chosenTool === tool ? setTool('') : setTool(chosenTool);
      transformStateRef.current.isTransforming = false;
      setSelectedId(null);
    },
    [setTool, tool]
  );

  useEffect(() => {
    const handleKeyDown = e => {
      if (e.ctrlKey && e.key === 'z') {
        handleUndo();
        e.preventDefault();
      } else if (e.ctrlKey && e.key === 'y') {
        handleRedo();
        e.preventDefault();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleUndo, handleRedo]);

  const handleSelect = useCallback(id => {
    setSelectedId(id);
  }, []);

  return (
    <Wrapper>
      <TopRightAbsoluteOpts style={{ top: '60px', right: '1rem' }}>
        <HorizontalToolBox>
          <Palette>
            {colorPalette.map((c, i) => (
              <PaletteItem
                key={i}
                color={c}
                selected={c === color}
                onClick={() => setColor(c)}
              />
            ))}
          </Palette>
          <CircleBox style={{ justifyContent: 'center' }}>
            <Circle size={thickness} color={color} />
            <span>{thickness}</span>
          </CircleBox>
          <ToolButton
            isHold={true}
            onClick={() => setThickness(thickness - 1)}
            disabled={!thicknessOpts[thickness - 2]}
            size="sm"
            preIcon="minus"
            tooltip="Decrease brush thickness"
          />
          <ToolButton
            isHold={true}
            onClick={() => setThickness(thickness + 1)}
            disabled={!thicknessOpts[thickness]}
            size="sm"
            preIcon="plus"
            tooltip="Increase brush thickness"
          />
        </HorizontalToolBox>
      </TopRightAbsoluteOpts>
      <Controls>
        <VerticalToolBox>
          <ToolButton
            selected={tool === 'pen'}
            preIcon="pen"
            tooltip="Pen"
            onClick={() => chooseTool('pen')}
          />
          <ToolButton
            preIcon="text"
            tooltip="Text"
            onClick={() => {
              chooseTool('text');
              updateText({ text: null, x: 100, y: 100 });
              document.activeElement.blur();
            }}
          />
          <ToolButton
            selected={tool === 'line'}
            tooltip="Line"
            preIcon="minus"
            size="md"
            onClick={() => chooseTool('line')}
          />
          <ToolButton
            selected={tool === 'triangle'}
            preIcon="shape-triangle"
            tooltip="Triangle"
            onClick={() => chooseTool('triangle')}
          />
          <ToolButton
            selected={tool === 'rect'}
            preIcon="shape-square"
            tooltip="Rectangle"
            onClick={() => chooseTool('rect')}
          />
          <ToolButton
            selected={tool === 'circle'}
            preIcon="shape-circle"
            tooltip="Circle"
            onClick={() => chooseTool('circle')}
          />
          {/* <ToolButton
            selected={tool === 'eraser'}
            preIcon="eraser"
            tooltip="Eraser"
            onClick={() => chooseTool('eraser')}
          /> */}
          <ToolButton
            selected={tool === 'transform'}
            preIcon="screenshot"
            tooltip="Transform"
            onClick={() => {}}
          />
          {button}
        </VerticalToolBox>
        <VerticalToolBox>
          <ToolButton
            preIcon="recycle"
            tooltip="Clear all"
            onClick={() => clearAll()}
          />
          <ToolButton
            preIcon="undo"
            tooltip="Undo"
            onClick={handleUndo}
            disabled={!historyRef?.current?.length}
          />
          <ToolButton
            preIcon="redo"
            tooltip="Redo"
            onClick={handleRedo}
            disabled={!futureRef?.current?.length}
          />
        </VerticalToolBox>
      </Controls>
      <BottomRightAbsoluteOpts>
        <HorizontalToolBox>
          <CircleBox style={{ justifyContent: 'center' }}>
            <span>Grid</span>
            <span>{gridSpacing}</span>
          </CircleBox>
          <ToolButton
            isHold={true}
            onClick={() => setGridSpacing(gridSpacing - 10)}
            disabled={gridSpacing === 10}
            size="sm"
            preIcon="minus"
            tooltip="Decrease grid spacing"
          />
          <ToolButton
            isHold={true}
            onClick={() => setGridSpacing(gridSpacing + 10)}
            disabled={gridSpacing === 100}
            size="sm"
            preIcon="plus"
            tooltip="Increase grid spacing"
          />
          <ToolButton
            tooltip="Grid Type - Line"
            selected={gridType === 'line'}
            onClick={() => setGridType('line')}>
            Line
          </ToolButton>
          <ToolButton
            tooltip="Grid Type - Dot"
            selected={gridType === 'dot'}
            onClick={() => setGridType('dot')}>
            Dot
          </ToolButton>
        </HorizontalToolBox>
      </BottomRightAbsoluteOpts>
      <StageContainer ref={wrapperRef}>
        <StyledStage
          width={stageSize.width}
          height={stageSize.height}
          onMouseDown={handleMouseDown}
          onTouchStart={handleMouseDown}
          onMousemove={handleMouseMove}
          onTouchMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onTouchEnd={handleMouseUp}>
          <Layer>{grid}</Layer>
          <Layer>
            {images.map((image, i) => (
              <URLImage
                key={i}
                index={i}
                selectedId={selectedId}
                id={`image-${i}`}
                handleSelect={() => handleSelect(`image-${i}`)}
                {...image}
                onStartTransform={setTransformingState}
                onUpdateImage={updateImage}
              />
            ))}
          </Layer>
          <Layer>{shapes.map((shape, i) => renderShape(shape, i))}</Layer>
          <Layer>
            {lines.map((line, i) => (
              <Line
                key={i}
                points={line.points}
                stroke={line.color || colorPalette[0]}
                strokeWidth={line.thickness || thicknessOpts[0]}
                tension={line.tension || 0.025}
                lineCap={line.lineCap || 'round'}
                lineJoin={line.lineJoin || 'round'}
                globalCompositeOperation={
                  line.tool === 'eraser' ? 'destination-out' : 'source-over'
                }
              />
            ))}
          </Layer>
          <Layer>
            {text.map((t, i) => (
              <EditableText
                key={t.guid || i}
                {...t}
                index={i}
                selectedId={selectedId}
                text={t.text}
                id={`text-${i}`}
                onUpdateText={updateText}
                color={t.color || color}
                onStartTransform={setTransformingState}
                handleSelect={() => handleSelect(`text-${i}`)}
              />
            ))}
          </Layer>
        </StyledStage>
      </StageContainer>
      {pointers}
    </Wrapper>
  );
};

const CircleBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 50px;
  height: 50px;
  font-size: 0.7rem;
`;

const Circle = styled.div`
  ${({ size = 1 }) => `width: ${size}px; height: ${size}px;`}
  // color param
  background-color: ${({ color = '#aaa' }) => color};
  border-radius: 50%;
`;

const VerticalToolBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0.25rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: none;
  margin: 0 0 0.25rem 0;
`;

const HorizontalToolBox = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0.5rem 0.25rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: none;
  margin: 0 0 0.25rem 0;
  max-height: 40px;
  z-index: 999;
  background: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const BottomRightAbsoluteOpts = styled.div`
  position: absolute;
  bottom: 0.5rem;
  right: 1rem;
  display: flex;
  flex-direction: row;
  padding: 0.5rem 0.25rem;
`;

const TopRightAbsoluteOpts = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  padding: 0.5rem 0.25rem;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
`;

const StageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Controls = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0 1px 0 0 #ccc;
  padding: 1rem;
  white-space: nowrap;
  width: 58px;
  border-right: 1px solid #ccc;
  // right shadow
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);

  // Thinly styled horizontal scrollbar
  &::-webkit-scrollbar {
    height: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 10px;
  }
`;

const Palette = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
`;

const PaletteItem = styled.div`
  width: 15px;
  height: 15px;
  background-color: ${props => props.color};
  border: ${props => (props.selected ? '1px solid #444' : '1px solid #ccc')};
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  border-radius: 5px;
  margin: 0 0.25rem 0.25rem 0.25rem;
`;

const StyledStage = styled(Stage)`
  cursor: crosshair;
`;

export default WhiteboardPage;
