import { List, Pagination, FunctionField, EditButton } from 'react-admin';
import HybridSchoolFilter from './Components/HybridSchoolFilter';
import HybridSchoolDataGrid from './Components/HybridSchoolDataGrid';
import { Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import HybridSchoolListActions from './Components/HybridSchoolListActions';
import EditIcon from '@material-ui/icons/Edit';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    margin: '12px',
    minHeight: '350px'
  },
  editButton: {
    color: '#4353ff',
    fontSize: '0.8125rem',
    padding: '4px',
    border: 'none',
    background: 'none',
    boxShadow: 'none'
  }
}));

const HybridSchoolPagination = props => (
  <Pagination rowsPerPageOptions={[20, 30, 40, 50]} {...props} />
);

const HybridSchoolsList = props => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Box className={classes.root}>
      <List
        title="Hybrid Schools"
        filters={<HybridSchoolFilter />}
        pagination={<HybridSchoolPagination />}
        bulkActionButtons={false}
        perPage={20}
        sort={{ field: 'SchoolId', order: 'DESC' }}
        actions={<HybridSchoolListActions maxResults={10000} />}
        {...props}>
        <HybridSchoolDataGrid>
          <FunctionField
            source="SchoolId"
            sortByOrder="DESC"
            label="SchoolId"
            sortBy="SchoolId"
            render={r => `#${r.id}`}
          />
          <FunctionField
            label="Name"
            reference="hybrid-schools"
            sortable={false}
            render={r => r.schoolName ?? '-'}
          />
          <FunctionField
            label="Trial Length"
            reference="hybrid-schools"
            sortable={false}
            render={r => r.defaultTrialLength ?? '-'}
          />
          <FunctionField
            label="Date Subscribed"
            reference="hybrid-schools"
            sortable={true}
            sortBy="DateSubscribed"
            render={r =>
              r.dateSubscribed
                ? moment(r.dateSubscribed).format('YYYY-DD-MM')
                : '-'
            }
          />
          <FunctionField
            label="Date Modified"
            reference="hybrid-schools"
            sortable={false}
            render={r =>
              r.dateModified ? moment(r.dateModified).format('YYYY-DD-MM') : '-'
            }
          />
          <EditButton label="School" />
          <EditSubscriptionsButton
            label="Subscriptions"
            classes={classes}
            history={history}
          />
        </HybridSchoolDataGrid>
      </List>
    </Box>
  );
};

const EditSubscriptionsButton = ({ record, history, classes }) => {
  const pushUri = `/user-subscriptions?filter=%7B"schoolId"%3A%7B"id"%3A"schoolId"%2C"Value"%3A${record.schoolId}%2C"Operator"%3A"Eq"%2C"Type"%3A"int"%7D%7D&order=DESC&page=1&perPage=20&sort=StudentId`;
  return (
    <Button
      variant="contained"
      color="default"
      className={classes.editButton}
      startIcon={<EditIcon />}
      onClick={r => history.push(pushUri)}>
      Subscriptions
    </Button>
  );
};

export default HybridSchoolsList;
