import { makeStyles } from '@material-ui/core/styles';
import {
  TextField,
  Button,
  Select,
  InputLabel,
  MenuItem,
  FormGroup,
  Box
} from '@material-ui/core';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import CachedIcon from '@material-ui/icons/Cached';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import { Form, Field } from 'react-final-form';
import ToggleSwitch from 'Components/ToggleSwitch';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '0 1rem 0 1rem'
  },
  input: {
    margin: '1rem 0 0 0'
  },
  createButton: {
    margin: '1rem 0 0 0'
  },
  cancelButton: {
    margin: '1rem 0 0 10px'
  },
  select: {
    margin: '1rem 0 0 0',
    width: '100%'
  }
}));

const EditTopicPathwayQuizForm = ({
  updateTopicPathwayQuiz,
  countryYearGroups,
  topic,
  cacheState,
  setIsOpen = () => {},
  resetCache = () => {}
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Form
        onSubmit={updateTopicPathwayQuiz}
        initialValues={topic}
        validate={values => {
          const errors = {};
          if (isNaN(parseInt(values.quizId, 10))) {
            errors.level = 'Please enter a valid quizId';
          }
          if (
            isNaN(parseInt(values.level, 10)) ||
            parseInt(values.level, 10) <= -1
          ) {
            errors.level = 'Please enter a level from 0+';
          }
          if (
            isNaN(parseInt(values.sequence, 10)) ||
            parseInt(values.sequence, 10) <= 0
          ) {
            errors.sequence = 'Please enter a sequence from 1+';
          }
          if (!values.name) {
            errors.name = 'Please enter a name for the topic';
          }
          if (!values.subjectId) {
            errors.subjectId = 'Please enter a valid subjectId';
          }
          if (!values.topicPathwayCollectionId) {
            errors.topicPathwayCollectionId =
              'Please enter a valid topic pathway collection Id';
          }
          if (
            !values.countryYearGroupIds ||
            values.countryYearGroupIds.length === 0
          ) {
            errors.countryYearGroupIds =
              'Please enter a valid country year group';
          }
          return errors;
        }}>
        {({ handleSubmit, submitting, pristine, submitError }) => (
          <form onSubmit={handleSubmit}>
            {!cacheState.isLoading && (
              <>
                <FormGroup>
                  <Field name="name">
                    {({ input, meta }) => (
                      <TextField
                        {...input}
                        id="standard-basic"
                        label="Topic Name"
                        variant="filled"
                        className={classes.input}
                        error={meta.error && meta.touched}
                        helperText={
                          meta.error && meta.touched ? meta.error : null
                        }
                      />
                    )}
                  </Field>
                </FormGroup>
                <FormGroup>
                  <Field name="quizId">
                    {({ input, meta }) => (
                      <TextField
                        {...input}
                        id="standard-number"
                        label="QuizId"
                        type="number"
                        className={classes.input}
                        error={meta.error && meta.touched}
                        helperText={
                          meta.error && meta.touched ? meta.error : null
                        }
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    )}
                  </Field>
                </FormGroup>
                <FormGroup>
                  <Field name="topicPathwayCollectionId">
                    {({ input, meta }) => (
                      <TextField
                        {...input}
                        id="standard-number"
                        label="TopicPathwayCollectionId"
                        type="number"
                        className={classes.input}
                        disabled={true}
                        error={meta.error && meta.touched}
                        helperText={
                          meta.error && meta.touched ? meta.error : null
                        }
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    )}
                  </Field>
                </FormGroup>
                <FormGroup>
                  <Field name="subjectId">
                    {({ input, meta }) => (
                      <>
                        <InputLabel
                          required
                          id="subject-label"
                          className={classes.select}>
                          Subject
                        </InputLabel>
                        <Select
                          {...input}
                          name="Subject"
                          labelId="subject-label"
                          error={meta.error && meta.touched}>
                          {[
                            { key: 'Number', value: 32 },
                            { key: 'Data & Statistics', value: 101 },
                            { key: 'Geometry', value: 71 },
                            { key: 'Algebra', value: 49 }
                          ].map((c, idx) => (
                            <MenuItem key={idx} value={c.value}>
                              {c.key}
                            </MenuItem>
                          ))}
                        </Select>
                      </>
                    )}
                  </Field>
                </FormGroup>
                <FormGroup>
                  <Field name="countryYearGroupIds">
                    {({ input, meta }) => (
                      <>
                        <InputLabel
                          required
                          id="country-year-group-label"
                          className={classes.select}>
                          Year Group
                        </InputLabel>
                        <Select
                          {...input}
                          multiple
                          name="CountryYearGroupIds"
                          labelId="country-year-group-label"
                          error={meta.error && meta.touched}>
                          {countryYearGroups
                            ?.sort((a, b) => {
                              const numA = parseInt(a.name.match(/\d+/)[0], 10);
                              const numB = parseInt(b.name.match(/\d+/)[0], 10);
                              return numA - numB;
                            })
                            ?.map(c => (
                              <MenuItem
                                key={c.countryYearGroupId}
                                value={c.countryYearGroupId}>
                                {c.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </>
                    )}
                  </Field>
                </FormGroup>
                <FormGroup>
                  <Field name="level">
                    {({ input, meta }) => (
                      <TextField
                        {...input}
                        id="standard-number"
                        label="Level"
                        type="number"
                        className={classes.input}
                        error={meta.error && meta.touched}
                        helperText={
                          meta.error && meta.touched ? meta.error : null
                        }
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    )}
                  </Field>
                </FormGroup>
                <FormGroup>
                  <Field name="sequence">
                    {({ input, meta }) => (
                      <TextField
                        {...input}
                        id="standard-number"
                        label="Sequence"
                        type="number"
                        className={classes.input}
                        error={meta.error && meta.touched}
                        helperText={
                          meta.error && meta.touched ? meta.error : null
                        }
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    )}
                  </Field>
                </FormGroup>
                <FormGroup>
                  <Field name="fact">
                    {({ input, meta }) => (
                      <TextField
                        {...input}
                        id="standard-basic"
                        label="Interesting Fact (optional)"
                        variant="filled"
                        className={classes.input}
                        error={meta.error && meta.touched}
                        helperText={
                          meta.error && meta.touched ? meta.error : null
                        }
                      />
                    )}
                  </Field>
                </FormGroup>
                <FormGroup>
                  <Field
                    name="isDisabled"
                    label="Enabled"
                    component={ToggleSwitch}
                  />
                </FormGroup>
                <FormGroup>
                  <Field
                    name="isInterventionsEnabled"
                    label="Interventions Enabled"
                    component={props => (
                      <ToggleSwitch invert={false} {...props} />
                    )}
                  />
                </FormGroup>
                {topic?.quiz?.questions?.length && (
                  <div className={classes.questionList}>
                    {topic?.quiz?.questions?.map((q, i) => {
                      return (
                        <div className={classes.question} key={q.questionId}>
                          Question {q.sequence}) -{' '}
                          <a
                            href={`/flow-questions?filter=%7B%22q%22%3A${q.questionId}%7D`}
                            target="_blank"
                            rel="noreferrer">
                            Flow Question
                          </a>
                        </div>
                      );
                    })}
                  </div>
                )}
              </>
            )}
            {cacheState.isLoading && (
              <>
                <p style={{ textAlign: 'center' }}>
                  Resetting cache for this topic... this may take a few
                  moments...
                  <span role="img" aria-labelledby="beverage">
                    ☕
                  </span>
                </p>
                <img
                  style={{
                    textAlign: 'center',
                    margin: '0 auto',
                    height: '150px',
                    width: 'auto',
                    display: 'flex'
                  }}
                  src="/assets/loady.gif"
                  alt="loading!"></img>
              </>
            )}
            <Button
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              className={classes.createButton}
              startIcon={<SaveIcon />}
              disabled={submitting || pristine || submitError}>
              Update
            </Button>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              onClick={() => resetCache({ showNotify: true })}
              className={classes.cancelButton}
              startIcon={<CachedIcon />}
              disabled={
                submitting || !pristine || submitError || cacheState.isLoading
              }>
              Reset Cache
            </Button>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              className={classes.cancelButton}
              onClick={() => setIsOpen()}
              startIcon={<CancelIcon />}
              disabled={cacheState.isLoading}>
              Cancel
            </Button>
          </form>
        )}
      </Form>
    </Box>
  );
};

export default EditTopicPathwayQuizForm;
