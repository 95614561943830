import moment from 'moment';
import 'moment-timezone';
import { makeStyles } from '@material-ui/core/styles';
import { List, Grid } from '@material-ui/core';
import ListItemRow from 'Components/ListItemRow';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    margin: '0 0 12px 0'
  },
  summaryLeft: {},
  summaryRight: {}
}));

const Student = props => {
  const classes = useStyles();
  if (!props) return null;

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start">
      <Grid item xs={12} className={classes.summaryLeft}>
        <List dense={true}>
          <ListItemRow
            header="Full name"
            data={`${props.firstName} ${props.lastName}`}
          />
          <ListItemRow header="Country" data={props.country} />
          <ListItemRow header="User ID" data={props.userId} />
          <ListItemRow header="Username" data={props.userName ?? '-'} />
          <ListItemRow
            header="DOB"
            data={
              props.dateOfBirth
                ? moment
                    .utc(props.dateOfBirth)
                    .tz('Europe/London')
                    .format('DD/MM/YYYY')
                : '-'
            }
          />
          <ListItemRow header="Year Group" data={props.yearGroupName ?? '-'} />
          <ListItemRow
            header="Date Joined"
            data={
              props.dateCreated
                ? moment
                    .utc(props.dateCreated)
                    .tz('Europe/London')
                    .format('DD/MM/YYYY')
                : '-'
            }
          />
          <ListItemRow
            header="Current Level"
            data={props.currentLevel ?? '-'}
          />
          <ListItemRow header="School ID" data={props.schoolId ?? '-'} />
          <ListItemRow
            header="Topic Pathway Collection ID"
            data={props.topicPathwayCollectionId ?? '-'}
          />
          <ListItemRow
            header="Topic Pathway Collection Student ID"
            data={props.topicPathwayCollectionStudentId ?? '-'}
          />
        </List>
      </Grid>
    </Grid>
  );
};

export default Student;
