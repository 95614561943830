import { List, Pagination, FunctionField } from 'react-admin';
import { Box, Button, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import MisconceptionTagsDataGrid from './Components/MisconceptionTagsDataGrid';
import MisconceptionTagsFilter from './Components/MisconceptionTagsFilter';
import { useState, useEffect } from 'react';
import MisconceptionTagQuestionModal from './Components/MisconceptionTagQuestionModal';
import MisconceptionTagsStatistics from './Components/MisconceptionTaggingFilter';

import DoneIcon from '@material-ui/icons/Done';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import FlagIcon from '@material-ui/icons/Flag';
import MisconceptionTagModal from './Modal/MisconceptionTagModal';

const useStyles = makeStyles(theme => ({
  root: {
    margin: '12px',
    minHeight: '350px'
  },
  viewButton: {
    color: '#4353ff',
    fontSize: '0.8125rem',
    padding: '4px',
    border: 'none',
    background: 'none',
    boxShadow: 'none'
  }
}));

const StyledList = styled(List)`
  .MuiToolbar-root {
    align-items: center;
  }
`;

const MisconceptionTagsPagination = props => (
  <Pagination rowsPerPageOptions={[20, 100, 200, 500]} {...props} />
);

const MisconceptionTagQuestionsList = props => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const [isViewModalOpen, setIsViewModalOpen] = useState(
    location.pathname.includes('view')
  );

  useEffect(() => {
    const runEffect = async () => {
      const isView = location.pathname.includes('view');
      if (isView !== isViewModalOpen) setIsViewModalOpen(isView);
    };
    runEffect();
  }, [isViewModalOpen, location.pathname]);

  return (
    <Box className={classes.root}>
      {isViewModalOpen && (
        <MisconceptionTagQuestionModal
          isOpen={true}
          setIsOpen={() =>
            history.push('/misconception-tags' + location.search)
          }
        />
      )}
      <StyledList
        title="Misconception Tags"
        filters={<MisconceptionTagsFilter />}
        pagination={<MisconceptionTagsPagination />}
        actions={
          <div style={{ display: 'flex' }}>
            <MisconceptionTagsStatistics />
            <MisconceptionTagsStatistics fromDate="2023-05-19" />
          </div>
        }
        bulkActionButtons={false}
        perPage={20}
        {...props}>
        <MisconceptionTagsDataGrid rowClick="">
          <FunctionField sortable={null} label="#" render={r => r.sequence} />
          <FunctionField
            sortable={false}
            label="Quiz"
            render={r => r.quizName}
          />
          <FunctionField
            sortable={null}
            label="primaryQuestionId"
            render={r => r.primaryQuestionId}
          />
          <FunctionField
            sortable={false}
            label="Question ID"
            render={r => r.questionId}
          />
          <FunctionField
            sortable={false}
            label="Type"
            render={r =>
              r.primaryQuestionId === r.questionId ? 'CheckIn' : 'CheckOut'
            }
          />
          <FunctionField
            sortable={false}
            label="Subject"
            render={r => r.quizSubject}
          />
          <FunctionField
            sortable={false}
            label="Construct"
            render={r => (
              <a
                href={`/misconception-tags?filter=%7B%22q%22%3A${r.constructId}%7D`}>
                {r.constructId}
              </a>
            )}
          />
          <FunctionField
            sortable={false}
            label=""
            render={r =>
              r.currentUserMisconceptionTagCount >= 3 ? (
                <Tooltip title="Complete!">
                  <DoneAllIcon style={{ color: '#09C18A' }} />
                </Tooltip>
              ) : r.currentUserMisconceptionTagCount >= 1 ? (
                <Tooltip
                  title={`You've added ${
                    r.currentUserMisconceptionTagCount
                  } tag${
                    r.currentUserMisconceptionTagCount !== 1 ? 's' : ''
                  } so far`}>
                  <DoneIcon style={{ color: '#848395' }} />
                </Tooltip>
              ) : null
            }
          />
          <FunctionField
            sortable={false}
            label=""
            render={r =>
              r.misconceptionTagCount + r.misconceptionTagFlagCount > 0 ? (
                <Tooltip
                  title={`${r.misconceptionTagCount} tags for ${
                    r.taggedAnswers
                  }.${
                    r.misconceptionTagFlagCount > 0
                      ? ` ${r.misconceptionTagFlagCount} flagged.`
                      : ''
                  }`}>
                  <FlagIcon
                    style={{
                      color:
                        r.misconceptionTagCount + r.misconceptionTagFlagCount >
                        2
                          ? '#F53838'
                          : '#FEAC06'
                    }}
                  />
                </Tooltip>
              ) : null
            }
          />
          <ViewButton classes={classes} history={history} />
        </MisconceptionTagsDataGrid>
      </StyledList>
    </Box>
  );
};

const ViewButton = ({ record, classes, history }) => {
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Button
        variant="contained"
        color="default"
        className={classes.viewButton}
        onClick={r => history.push(`/misconception-tags/view/${record.id}`)}>
        View
      </Button>
      <Button
        variant="contained"
        color="default"
        className={classes.viewButton}
        onClick={() =>
          window.open(`/misconception-tags/${record.id}/show`, '_blank')
        }>
        Edit
      </Button>
      <Button
        variant="contained"
        color="default"
        className={classes.viewButton}
        onClick={() => setIsViewModalOpen(true)}>
        Modal
      </Button>
      <MisconceptionTagModal
        open={isViewModalOpen}
        onClose={() => setIsViewModalOpen(false)}
        record={record}
      />
    </div>
  );
};

export default MisconceptionTagQuestionsList;
