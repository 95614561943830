import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import NextIcon from '@material-ui/icons/NavigateNext';

const useStyles = makeStyles(theme => ({
  input: {
    margin: '1rem 0 0 0'
  }
}));

const Step0 = ({ onNext }) => {
  const classes = useStyles();

  return (
    <div>
      <Typography>
        IF haven't done it - LEVEL BOOSTER! It should say regenerate after
        you've taken this... But we'll highlight the info we have is best guess
        from our model for all users...
      </Typography>
      <Button
        variant="contained"
        color="primary"
        size="large"
        type="submit"
        className={classes.createButton}
        onClick={() => onNext()}
        startIcon={<NextIcon />}>
        Next
      </Button>
    </div>
  );
};

export default Step0;
