import Pentagon from 'Components/Shapes/Pentagon';
import LightTooltip from 'Components/LightTooltip';

const fillColors = [
  'rgb(222, 80, 80)',
  'rgb(110, 194, 130)',
  'rgb(	255, 191, 0)'
];

const CheckoutQuestionProgressItem = ({
  isCorrect,
  isAnswered,
  width,
  height,
  title,
  style
}) => {
  return (
    <LightTooltip
      placement="top-start"
      title={
        title ??
        `Checkout Question - ${
          isCorrect ? 'Correct' : isAnswered ? 'Incorrect' : 'Not Answered'
        }`
      }>
      <div>
        <Pentagon
          style={style}
          width={width ?? 12}
          height={height ?? 12}
          fill={fillColors[isCorrect ? 1 : isAnswered ? 0 : 2]}
        />
      </div>
    </LightTooltip>
  );
};

export default CheckoutQuestionProgressItem;
