import {
  useListContext,
  TopToolbar,
  ExportButton,
  sanitizeListRestProps
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ConversationPromptModal from './ConversationPromptModal';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    justifyContent: 'flex-end!important',
    WebkitJustifyContent: 'flex-end!important',
    WebKitBoxPack: 'end!important',
    width: '100%'
  },
  createButton: {
    color: '#4353ff',
    fontSize: '0.8125rem',
    padding: '4px',
    border: 'none',
    background: 'none',
    boxShadow: 'none',
    float: 'right',
    margin: '0 10px 0 0'
  }
}));

const ConversationListActions = props => {
  const history = useHistory();
  const classes = useStyles();
  const { className, maxResults, ...rest } = props;
  const { total } = useListContext();
  const [isPromptModalOpen, setIsPromptModalOpen] = useState(false);

  return (
    <>
      <TopToolbar className={classes.root} {...sanitizeListRestProps(rest)}>
        <Button
          variant="contained"
          color="default"
          className={classes.createButton}
          startIcon={<AddIcon />}
          onClick={() => history.push('/prompts')}>
          View Prompts
        </Button>
        <Button
          variant="contained"
          color="default"
          className={classes.createButton}
          startIcon={<AddIcon />}
          onClick={() => setIsPromptModalOpen(true)}>
          Start Conversation
        </Button>
        <ExportButton disabled={total === 0} maxResults={maxResults} />
      </TopToolbar>
      <ConversationPromptModal
        isOpen={isPromptModalOpen}
        setIsOpen={setIsPromptModalOpen}
        isCreateMode={true}
      />
    </>
  );
};

export default ConversationListActions;
