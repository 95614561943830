import { Field, Form } from 'react-final-form';
import styled from 'styled-components';
import {
  TextField,
  Button,
  Select,
  InputLabel,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Switch
} from '@material-ui/core';
import moment from 'moment';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import {
  notificationTypeGroups,
  notificationTypes
} from './notificationHelpers';
import { useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';

const Root = styled.div`
  padding: 1rem;
`;

const StyledTextField = styled(TextField)`
  margin: 0.5rem 0 0 0;
`;

const StyledCancelButton = styled(Button)`
  margin: 0 0 0 0.5rem;
`;

const SelectWrapper = styled.div`
  width: 100%;
  margin: 0.5rem 0 0 0;
`;

const EditorWrapper = styled.div`
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin: 0.5rem 0 0.5rem 0;
  min-height: 400px;
  overflow: auto;
  .editor-toolbar {
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #f5f5f5;
  }
`;

const EditNotificationForm = ({
  notification,
  onSave = () => {},
  onSaveAndDispatch = () => {},
  onCancel = () => {},
  isStudentNotification = false
}) => {
  const [editorState, setEditorState] = useState(
    notification?.body
      ? EditorState.createWithContent(stateFromHTML(notification.body))
      : EditorState.createEmpty()
  );
  return (
    <Root>
      <h3>
        {!!notification?.id ? 'Edit Notification' : 'Create Notification'}
      </h3>
      <Form
        onSubmit={values => {
          if (values.dismissible) {
            values.notificationType =
              notificationTypeGroups.find(
                n => n.value === values.notificationType
              )?.dismissible ?? values.notificationType;
          }
          if (values.dispatchOnSave) {
            onSaveAndDispatch(values);
          } else {
            onSave(values);
          }
        }}
        initialValues={{
          ...notification,
          schoolId: notification?.schoolId ?? 167333,
          notificationType: notificationTypeGroups.find(
            n =>
              n.dismissible === notification?.notificationType ||
              notification?.notificationType === n.value
          )?.value,
          dismissible: !!notificationTypeGroups.find(
            n => n.dismissible === notification?.notificationType
          ),
          activeFromDate: moment(notification?.activeFromDate).format(
            'YYYY-MM-DDTHH:mm'
          ),
          activeToDate: moment(notification?.activeToDate).format(
            'YYYY-MM-DDTHH:mm'
          )
        }}
        validate={values => {
          const errors = {};
          if (!values.schoolId && !isStudentNotification) {
            errors.schoolId = 'School ID is required';
          }
          if (!values.studentUserId && isStudentNotification) {
            errors.studentUserId = 'User ID is required';
          }
          if (!values.notificationType) {
            errors.notificationType = 'Notification Type is required';
          }
          if (!isStudentNotification && !values.activeFromDate) {
            errors.activeFromDate = 'From date is required';
          }
          if (!isStudentNotification && !values.activeToDate) {
            errors.activeToDate = 'To date is required';
          }
          if (
            !isStudentNotification &&
            !!values.activeFromDate &&
            !!values.activeToDate
          ) {
            if (moment(values.activeFromDate) >= moment(values.activeToDate)) {
              errors.activeFromDate = 'From date must be before To date';
              errors.activeToDate = 'To date must be after From date';
            }
          }
          return errors;
        }}>
        {({ handleSubmit, submitting, pristine, submitError, values }) => (
          <form onSubmit={handleSubmit}>
            {isStudentNotification ? (
              <FormGroup>
                <Field name="studentUserId">
                  {({ input, meta }) => (
                    <StyledTextField
                      {...input}
                      id="standard-basic"
                      label="UserId"
                      type="number"
                      variant="filled"
                      error={meta.error && meta.touched}
                      helperText={
                        meta.error && meta.touched ? meta.error : null
                      }
                    />
                  )}
                </Field>
              </FormGroup>
            ) : (
              <FormGroup>
                <Field name="schoolId">
                  {({ input, meta }) => (
                    <StyledTextField
                      {...input}
                      id="standard-basic"
                      label="SchoolId"
                      type="number"
                      variant="filled"
                      error={meta.error && meta.touched}
                      helperText={
                        meta.error && meta.touched ? meta.error : null
                      }
                    />
                  )}
                </Field>
              </FormGroup>
            )}
            <FormGroup>
              <Field name="title">
                {({ input, meta }) => (
                  <StyledTextField
                    {...input}
                    id="standard-basic"
                    label="Title (optional)"
                    variant="filled"
                    error={meta.error && meta.touched}
                    helperText={meta.error && meta.touched ? meta.error : null}
                  />
                )}
              </Field>
            </FormGroup>
            <Field name="notificationType">
              {({ input, meta }) => (
                <SelectWrapper>
                  <InputLabel required id="notificationType-label">
                    Notification Type
                  </InputLabel>
                  <Select
                    {...input}
                    name="notificationType"
                    labelId="notificationType-label"
                    error={meta.error && meta.touched}>
                    {notificationTypeGroups.map((n, idx) => (
                      <MenuItem key={idx} value={n.value}>
                        {n.label}
                      </MenuItem>
                    ))}
                  </Select>
                </SelectWrapper>
              )}
            </Field>
            {values?.notificationType?.toLowerCase()?.includes('html') ? (
              <FormGroup>
                <Field name="body">
                  {({ input, meta }) => (
                    <EditorWrapper>
                      <Editor
                        editorState={editorState}
                        toolbarClassName="editor-toolbar"
                        wrapperClassName="editor-wrapper"
                        editorClassName="editor-main"
                        onEditorStateChange={editorState => {
                          input.onChange(
                            stateToHTML(editorState.getCurrentContent())
                          );
                          setEditorState(editorState);
                        }}
                      />
                      {meta.error ? <p>{meta.error}</p> : null}
                    </EditorWrapper>
                  )}
                </Field>
              </FormGroup>
            ) : (
              <FormGroup>
                <Field name="body">
                  {({ input, meta }) => (
                    <StyledTextField
                      {...input}
                      multiline={true}
                      minRows={6}
                      id="standard-basic"
                      label="Body (optional)"
                      variant="filled"
                      error={meta.error && meta.touched}
                      helperText={
                        meta.error && meta.touched ? meta.error : null
                      }
                    />
                  )}
                </Field>
              </FormGroup>
            )}
            {!isStudentNotification && (
              <>
                <FormGroup>
                  <Field name="activeFromDate">
                    {({ input, meta }) => (
                      <StyledTextField
                        {...input}
                        id="datetime-local"
                        label="Active From"
                        type="datetime-local"
                        variant="filled"
                        error={meta.error && meta.touched}
                        helperText={
                          meta.error && meta.touched ? meta.error : null
                        }
                      />
                    )}
                  </Field>
                </FormGroup>
                <FormGroup>
                  <Field name="activeToDate">
                    {({ input, meta }) => (
                      <StyledTextField
                        {...input}
                        id="datetime-local"
                        label="Active To"
                        type="datetime-local"
                        variant="filled"
                        error={meta.error && meta.touched}
                        helperText={
                          meta.error && meta.touched ? meta.error : null
                        }
                      />
                    )}
                  </Field>
                </FormGroup>{' '}
              </>
            )}
            <FormGroup>
              <Field name="isShowInModal" type="checkbox">
                {({ input }) => (
                  <>
                    <FormControlLabel
                      disabled={
                        !!values?.notificationType
                          ?.toLowerCase()
                          ?.includes('banner')
                      }
                      control={<Switch {...input} />}
                      label="Show in modal"
                    />
                  </>
                )}
              </Field>
            </FormGroup>
            <FormGroup>
              <Field name="dismissible" type="checkbox">
                {({ input }) => (
                  <>
                    <FormControlLabel
                      control={<Switch {...input} />}
                      label="Dismissible"
                    />
                  </>
                )}
              </Field>
            </FormGroup>
            {!isStudentNotification && (
              <FormGroup>
                <Field name="dispatchOnSave" type="checkbox">
                  {({ input }) => (
                    <>
                      <FormControlLabel
                        control={
                          <Switch
                            {...input}
                            disabled={
                              moment(values?.activeFromDate) > moment() ||
                              moment(values?.activeToDate) < moment()
                            }
                          />
                        }
                        label="Dispatch notification to school on save?"
                      />
                    </>
                  )}
                </Field>
              </FormGroup>
            )}
            <Button
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              startIcon={<SaveIcon />}
              disabled={submitting || pristine || submitError}>
              {!!notification?.id
                ? moment(values?.activeFromDate) < moment() &&
                  moment(values?.activeToDate) > moment() &&
                  values.dispatchOnSave
                  ? 'Update & Dispatch'
                  : 'Update'
                : moment(values?.activeFromDate) < moment() &&
                  moment(values?.activeToDate) > moment() &&
                  values.dispatchOnSave
                ? 'Save & Dispatch'
                : 'Save'}
            </Button>
            <StyledCancelButton
              variant="contained"
              color="secondary"
              size="large"
              onClick={() => onCancel()}
              startIcon={<CancelIcon />}
              disabled={submitting}>
              Cancel
            </StyledCancelButton>
          </form>
        )}
      </Form>
    </Root>
  );
};

export default EditNotificationForm;
