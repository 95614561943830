import StudentShowCard from 'Components/Students/StudentShowCard';
import { Loading, useShowController } from 'react-admin';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '350px'
  }
}));

const StudentShow = props => {
  const { loaded, loading, record } = useShowController({
    ...props,
    id: props.id,
    resource: props.resource,
    filterDefaultValues: { includes: ['topic-progress'] }
  });
  const classes = useStyles();
  if (loading || !loaded) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  return (
    <Box className={classes.root}>
      <StudentShowCard data={record} isStudentShow={true}></StudentShowCard>
    </Box>
  );
};

export default StudentShow;
