import Api from 'Api/Api';
import querystring from 'querystring';

const resourceAlias = {
  'misconception-tags': 'topic-pathway-quiz-question-insights'
};

const dataProvider = {
  getList: async (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const request = {
      'request.skip': (page - 1) * perPage,
      'request.take': perPage
    };

    if (resourceAlias[resource]) {
      resource = resourceAlias[resource];
    }

    if (field) {
      request['request.sort[0].FieldName'] = field;
    }

    if (order) {
      request['request.sort[0].Direction'] = order;
    }

    if (field?.includes(',')) {
      let i = 0;
      field.split(',').forEach(f => {
        request[`request.sort[${i}].FieldName`] = f;
        request[`request.sort[${i}].Direction`] = order?.toUpperCase();
        i++;
      });
    }

    if (params.filter) {
      let index = 0;
      for (const [key, value] of Object.entries(params.filter)) {
        if (key === 'queryParams') {
          for (const [queryParamKey, queryParamValue] of Object.entries(
            value
          )) {
            if (queryParamValue) request[queryParamKey] = queryParamValue;
          }
          continue;
        }

        if (typeof value === 'object') {
          if (value.value === null || value.value === 'null') continue;

          request[`request.filter.filters[${index}].Value`] = value.Value
            ? `${value.Value}`
            : null;
          request[`request.filter.filters[${index}].FieldName`] = `${key}`;
          request[
            `request.filter.filters[${index}].Operator`
          ] = `${value.Operator}`;
          request[`request.filter.filters[${index}].Type`] =
            value.Type ?? typeof value.Value;
        } else {
          if (value === null || value === 'null') continue;

          request[`request.filter.filters[${index}].Value`] = value
            ? `${value}`
            : null;
          request[`request.filter.filters[${index}].FieldName`] = `${key}`;
          request[`request.filter.filters[${index}].Operator`] = `Eq`;
          request[`request.filter.filters[${index}].Type`] = typeof value;
        }
        index++;
      }
    }

    try {
      return await Api.request('GET', `v4/admin/${resource}`, request);
    } catch {
      throw new Error('Error fetching data');
    }
  },
  getOne: async (resource, params) => {
    let result = await Api.request('GET', `v4/admin/${resource}/${params.id}`);
    if (result) {
      return { data: result };
    }
    throw new Error(`no ${resource} found with id ${params.id}!`);
  },
  getMany: async (resource, params) => {
    const request = {
      ids: params.ids
    };
    return await Api.request('GET', `v4/admin/${resource}`, request);
  },
  getManyReference: async (resource, params) => {
    const { q } = params.filter;
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const request = {
      'request.skip': (page - 1) * perPage,
      'request.take': perPage,
      'request.sort[0].FieldName': field,
      'request.sort[0].Direction': order,
      'request.filter.Search': q,
      'request.filter.FieldFilter': params.filter
    };
    return await Api.request('GET', `v4/admin/${resource}`, request);
  },
  update: async (resource, params) => {
    let query;
    let body = params?.body;
    let id = params?.id;

    if (!!params?.query) {
      query = querystring.stringify(params.query);
    }
    let data = await Api.request(
      'PUT',
      `v4/admin/${resource}/${id}${query ? `?${query}` : ''}`,
      body
    );

    if (params.idAlias) {
      data = {
        ...data,
        id: data[params.idAlias]
      };
    }

    return {
      data
    };
  },

  updateMany: (resource, params) => {},

  create: async (resource, params) => {
    let query;
    let body = params?.body;

    if (!!params?.query) {
      query = querystring.stringify(params.query);
    }
    let data = await Api.request('POST', `v4/admin/${resource}?${query}`, body);

    if (params.idAlias) {
      data = {
        ...data,
        id: data[params.idAlias]
      };
    }

    return {
      data
    };
  },

  delete: async (resource, params) => {
    let result = await Api.request(
      'DELETE',
      `v4/admin/${resource}/${params.id}`
    );
    return { data: result };
  },

  deleteMany: (resource, params) => {}
};

export default dataProvider;
