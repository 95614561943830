import { List, Pagination, FunctionField, Datagrid } from 'react-admin';
import { Box, Tooltip, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { truncateString } from 'Helpers/stringHelpers';
import ConversationPromptModal from './Components/ConversationPromptModal';
import ChatIcon from '@material-ui/icons/Chat';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import ConversationListActions from './Components/ConversationListActions';
import ConversationListFilter from './Components/ConversationListFilter';

const useStyles = makeStyles(theme => ({
  root: {
    margin: '12px',
    minHeight: '350px'
  }
}));

const ConversationPagination = props => (
  <Pagination rowsPerPageOptions={[20]} {...props} />
);

const ConversationList = props => {
  const history = useHistory();
  const classes = useStyles();
  const [currentConversationId, setCurrentConversationId] = useState(null);

  return (
    <Box className={classes.root}>
      <List
        title="conversations"
        pagination={<ConversationPagination />}
        actions={<ConversationListActions />}
        filters={<ConversationListFilter />}
        empty={false}
        bulkActionButtons={false}
        perPage={25}
        sort={{ field: 'dateCreated', order: 'DESC' }}
        {...props}>
        <Datagrid rowClick="">
          <FunctionField
            source="id"
            sortByOrder="DESC"
            label="ID"
            sortBy="FlowGeneratorSessionId"
            render={r => `#${r.id}`}
          />
          <FunctionField
            label="Title"
            render={r => (
              <div>
                <Tooltip
                  title={r.templateSlug?.length > 100 ? r.templateSlug : ''}>
                  <div>
                    {truncateString(r.templateSlug, 100) ||
                      'Untitled Conversation'}
                  </div>
                </Tooltip>
              </div>
            )}
          />
          <FunctionField
            label="Created"
            sortBy="DateCreated"
            render={r =>
              r.dateCreated ? moment(r.dateCreated).format('YYYY/DD/MM') : '-'
            }
          />
          <FunctionField
            label="Prompt"
            render={r => (
              <>
                <Button
                  variant="contained"
                  color="default"
                  startIcon={<ChatIcon />}
                  onClick={() => setCurrentConversationId(r.id)}>
                  View Prompt
                </Button>
              </>
            )}
          />
          <FunctionField
            label="Conversation"
            render={r => (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<QuestionAnswerIcon />}
                  onClick={() => history.push(`/conversations/${r.id}/show`)}>
                  Open
                </Button>
              </>
            )}
          />
        </Datagrid>
      </List>
      <ConversationPromptModal
        isOpen={currentConversationId !== null}
        setIsOpen={isOpen =>
          setCurrentConversationId(isOpen ? currentConversationId : null)
        }
        flowGeneratorSessionId={currentConversationId}
      />
    </Box>
  );
};

export default ConversationList;
