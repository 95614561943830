import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import Avatar from 'Components/Avatar';
import RequestAdminForm from './RequestAdminForm';

const useStyles = makeStyles(theme => ({
  root: {
    height: 'calc(100vh - 48px)',
    backgroundColor: '#fff',
    borderLeft: '1px solid #ddd'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    padding: '16px'
  },
  section: {
    textAlign: 'center',
    backgroundColor: theme.palette.warning.light,
    padding: '0.5rem 0 0.5em 0',
    margin: '0.5rem 0 0.5rem 0'
  },
  avatar: {
    marginRight: '12px',
    flex: '0 0 48px'
  }
}));

const RequestShowCard = props => {
  const { data } = props;
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <section className={classes.header}>
        <div>
          <Avatar
            name={`${data.firstName} ${data.lastName} (${data.username})`}
            size={72}
            className={classes.avatar}
          />
        </div>
        <div>
          <Typography variant="h6" style={{ lineHeight: 1.2 }}>
            {data.firstName} {data.lastName}
            <span>(</span>
            {data.username}
            <span>)</span>
          </Typography>
          <Typography
            variant="body1"
            color="textSecondary"
            style={{ marginBottom: '4px' }}>
            User ID: {data.userId}
          </Typography>
          <Typography
            variant="body1"
            color="textSecondary"
            style={{ marginBottom: '4px' }}>
            Student ID: {data.studentId}
          </Typography>
        </div>
      </section>
      <section>
        <h5 className={classes.section}>
          {data.firstName} requested to be added to the following school
        </h5>
        <RequestAdminForm studentId={data.studentId} data={data} />
      </section>
    </Box>
  );
};

export default RequestShowCard;
