import { useListContext } from 'react-admin';
import { Button, Box } from '@material-ui/core';
import ContentFilter from '@material-ui/icons/FilterList';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect } from 'react';

const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'stretch'
  }
});

const FilterButton = () => {
  const { showFilter, hideFilter } = useListContext();
  const classes = useStyles();
  const setFilter = () => {
    const showLessonFilter = JSON.parse(
      localStorage.getItem('showLessonFilter')
    );
    if (!showLessonFilter) {
      showFilter('main');
      localStorage.setItem('showLessonFilter', true);
    } else {
      hideFilter('main');
      localStorage.setItem('showLessonFilter', false);
    }
  };

  useEffect(() => {
    if (JSON.parse(localStorage.getItem('showLessonFilter'))) {
      showFilter('main');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className={classes.root}>
      <Button
        color="primary"
        onClick={() => setFilter()}
        startIcon={<ContentFilter />}>
        Filters
      </Button>
    </Box>
  );
};

export default FilterButton;
