import { Button, FormGroup } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import { useCallback, useEffect, useRef } from 'react';
import { useState } from 'react';

const useStyles = makeStyles(theme => ({
  formGroup: {
    width: '90%',
    padding: '0.25rem 2rem 0.25rem 1rem',
    margin: '0 0 0.25rem'
  },
  button: {
    margin: '0.25rem 0 1rem'
  }
}));

const SaveConfirmButton = ({
  onClick = null,
  submitting = false,
  pristine = true,
  submitError = null,
  isDoubleConfirm = true,
  confirmCopy = 'Confirm Save?',
  buttonCopy = 'Save',
  loadingCopy = 'Saving...',
  icon = null,
  disabled = false,
  choiceIntervalMS = 5000,
  ...props
}) => {
  const [isConfirmed, setIsConfirmed] = useState(false);
  const secondsRef = useRef({ seconds: choiceIntervalMS / 1000 });
  const [seconds, setSeconds] = useState(secondsRef.current.seconds);
  const classes = useStyles();

  const clearTimer = useCallback(() => {
    clearInterval(secondsRef.current.intervalId);
    secondsRef.current.intervalId = null;
    secondsRef.current.seconds = choiceIntervalMS / 1000;
    setSeconds(secondsRef.current.seconds);
  }, [choiceIntervalMS]);

  useEffect(() => {
    if (submitting || pristine || submitError) {
      setIsConfirmed(false);
    }
  }, [submitting, pristine, submitError]);

  const confirm = useCallback(
    e => {
      e.preventDefault();
      clearTimer();

      setTimeout(() => {
        setIsConfirmed(false);
        clearTimer();
      }, choiceIntervalMS);

      secondsRef.current.intervalId = setInterval(() => {
        secondsRef.current.seconds = secondsRef.current.seconds - 1;
        setSeconds(secondsRef.current.seconds);
      }, 1000);

      setIsConfirmed(true);
    },
    [choiceIntervalMS, clearTimer]
  );

  return (
    <FormGroup className={classes.formGroup} {...props}>
      {!isConfirmed && isDoubleConfirm ? (
        <Button
          variant="contained"
          color="secondary"
          type="button"
          endIcon={icon ?? <SaveIcon />}
          onClick={e => confirm(e)}
          disabled={submitting || pristine || submitError || disabled}
          className={classes.button}>
          {submitting ? loadingCopy : buttonCopy}
        </Button>
      ) : (
        <Button
          variant="contained"
          color="primary"
          endIcon={icon ?? <SaveIcon />}
          type={!!onClick ? 'button' : 'submit'}
          onClick={onClick}
          disabled={submitting || pristine || submitError || disabled}
          className={classes.button}>
          {submitting
            ? loadingCopy
            : isDoubleConfirm
            ? `${confirmCopy} (${seconds})`
            : confirmCopy}
        </Button>
      )}
    </FormGroup>
  );
};

export default SaveConfirmButton;
