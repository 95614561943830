export const getFlowTemplateFromNode = (
  node = { id: '' },
  flowTemplates = []
) => {
  const idParts = node.id.split('>');

  if (!idParts?.length || !idParts[1]) return null;

  return flowTemplates.find(
    ft =>
      (ft.flowItemTemplateSlug === idParts[1] ||
        ft.flowTemplateSlug === idParts[1] ||
        ft.parentFlowTemplateSlug) &&
      ft.position === parseInt(idParts[0].replace('#', ''), 10)
  );
};

export const parseTemplateText = text =>
  text?.replace(/\n/g, '').replace(/ /g, '_');
