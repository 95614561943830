import AttachFileIcon from '@material-ui/icons/AttachFile';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import useUploadFile from 'Hooks/useUploadFile';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'column',
    flexDirection: 'row',
    borderBottom: '1px solid #ddd',
    margin: '0 0 1rem 0',
    padding: '0 1rem 0 0'
  },
  uploadButton: {
    width: '40px',
    height: '40px'
  },
  fileDetails: {
    margin: '0',
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'flex-end',
    fontSize: '12px'
  },
  uploadFormGroup: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center'
  },
  attach: {
    fontSize: '12px'
  }
}));

const useUploadTemplate = () => {
  const classes = useStyles();
  const {
    file,
    onFileChange,
    FileDetails,
    fileRef,
    getSetFormData,
    clearFile
  } = useUploadFile(false, ['.csv']);

  const UploadForm = ({ submitting, label = 'File', meta, isRequired }) => {
    return (
      <div className={classes.root}>
        <div className={classes.attach}>
          <IconButton
            disabled={submitting}
            type="file"
            className={classes.uploadButton}
            variant="contained"
            color="secondary"
            component="label">
            <AttachFileIcon />
            <input type="file" ref={fileRef} onChange={onFileChange} hidden />
          </IconButton>
          {label}
        </div>
        <FileDetails className={classes.fileDetails} />
        {meta?.error && !file && isRequired && (
          <div className={classes.error}>{meta?.error}</div>
        )}
      </div>
    );
  };
  return {
    UploadForm,
    getSetFormData,
    file,
    clearFile
  };
};

export default useUploadTemplate;
