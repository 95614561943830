import { useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Paper, IconButton } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FeaturedVideoOutlinedIcon from '@material-ui/icons/FeaturedVideoOutlined';
import FeaturedVideoIcon from '@material-ui/icons/FeaturedVideo';
import { useNotify } from 'react-admin';

const imageExtensions = ['.png', '.jpeg', '.jpg', '.gif', '.bmp'];
const videoExtensions = ['.mp4'];

const useStyles = makeStyles(theme => ({
  root: {
    margin: '1rem'
  },
  fileDetails: {
    float: 'right',
    margin: '1rem 0 0 0'
  },
  imageContainer: {
    width: '50px',
    height: '50px',
    float: 'right'
  },
  image: {
    width: '50px',
    height: '50px'
  },
  removeFile: {
    float: 'right'
  },
  pinned: {
    float: 'right'
  }
}));

const useUploadFile = (isChatBased = true, permittedFileExtensions = null) => {
  const notify = useNotify();
  const classes = useStyles();
  const [file, setFile] = useState(null);
  const [fileExtension, setFileExtension] = useState(null);
  const [isPinned, setIsPinned] = useState(false);
  const [urlFile, setUrlFile] = useState(null);
  const [formData, setFormData] = useState(null);
  const fileRef = useRef();

  const onFileChange = event => {
    if (!event?.target?.files?.length) {
      notify('No file selected');
      return;
    }

    const extension = event.target.files[0].name.match(/\.[0-9a-z]+$/i)[0];
    setFileExtension(extension);
    if (
      imageExtensions.includes(extension.toLowerCase()) ||
      videoExtensions.includes(extension.toLowerCase())
    ) {
      setUrlFile(URL.createObjectURL(event.target.files[0]));
    }
    if (permittedFileExtensions?.length) {
      if (!permittedFileExtensions.includes(extension.toLowerCase())) {
        notify('File type not allowed', 'warning');
        return;
      }
    }
    setFile(event.target.files[0]);
    getSetFormData();
    setIsPinned(false);
    return event.target.files[0];
  };

  const getSetFormData = (message, fileName = null, props = {}) => {
    if (!file) return;
    const formData = new FormData();
    formData.append('FormFile', file);
    formData.append('FileName', fileName || file.name);

    if (isChatBased) {
      formData.append('Message', message ?? '');
      formData.append('IsPinned', isPinned);
    }

    if (Object.keys(props).length) {
      Object.keys(props).forEach(key => {
        formData.append(key, props[key]);
      });
    }

    setFormData(formData);
    return formData;
  };

  const convertToBase64 = file => {
    try {
      if (!file) return null;
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    } catch (e) {
      notify('Error converting file to base64', 'error');
      console.error(e);
    }
    return null;
  };

  const clearFile = () => {
    setFile(null);
    setUrlFile(null);
    fileRef.current.value = '';
  };

  const FileDetails = ({ className, ...props }) => {
    if (!file) {
      return null;
    }
    return (
      <Box className={className ?? classes.root} {...props}>
        <Box className={classes.fileDetails}>{file.name}</Box>
        <Box>
          <IconButton
            className={classes.removeFile}
            variant="contained"
            color="secondary"
            component="label"
            onClick={() => clearFile()}>
            <HighlightOffIcon />
          </IconButton>
          {isChatBased && (
            <>
              {urlFile &&
                imageExtensions.includes(fileExtension.toLowerCase()) && (
                  <Paper evelation={3} className={classes.imageContainer}>
                    <img
                      className={classes.image}
                      src={urlFile}
                      alt={file.name}
                    />
                  </Paper>
                )}
              <FormControlLabel
                className={classes.pinned}
                control={
                  <Checkbox
                    icon={<FeaturedVideoOutlinedIcon />}
                    checkedIcon={<FeaturedVideoIcon />}
                    name="isPinned"
                  />
                }
                checked={isPinned}
                title={isPinned ? 'Pinned' : 'Not Pinned'}
                onChange={() => setIsPinned(!isPinned)}
              />
            </>
          )}
        </Box>
      </Box>
    );
  };

  return {
    file,
    urlFile,
    onFileChange,
    getSetFormData,
    FileDetails,
    fileRef,
    formData,
    clearFile,
    convertToBase64
  };
};

export default useUploadFile;
