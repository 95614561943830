const CircularProgressBar = ({
  percentage = 50,
  size = 60,
  barWidth = 8,
  backgroundColor = '#ddd',
  foregroundColor = '#09C18A'
}) => {
  const rotateOffset = -90;
  const centre = size / 2;
  const radius = size / 2 - barWidth / 2;
  const circumference = 2 * Math.PI * radius;
  const progress = (percentage / 100) * circumference;

  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox={`0 0 ${size} ${size}`}>
      <defs>
        <linearGradient
          id="progressGradient"
          x1="100%"
          y1="100%"
          x2="70%"
          y2="0%">
          <stop offset="0%" stopColor="#FFA500" />
          <stop offset="100%" stopColor="#008000" />
        </linearGradient>
      </defs>
      <circle
        className="circle-bg"
        stroke={backgroundColor}
        fill="none"
        cx={centre}
        cy={centre}
        r={radius}
        strokeWidth={barWidth}
      />
      <circle
        className="circle"
        stroke="url(#progressGradient)"
        fill="none"
        cx={centre}
        cy={centre}
        r={radius}
        strokeWidth={barWidth}
        strokeDasharray={`${progress}, ${circumference}`}
        transform={`rotate(${rotateOffset} ${centre} ${centre})`}
      />
      <text
        x={centre}
        y={centre}
        dominantBaseline="middle"
        textAnchor="middle"
        fontSize="14px"
        fill={foregroundColor}>
        {`${percentage}%`}
      </text>
    </svg>
  );
};

export default CircularProgressBar;
