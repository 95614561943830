import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import styled from 'styled-components';
import { useNotify } from 'react-admin';
import { useCallback } from 'react';
import Api from 'Api/Api';
import ProgressBar from 'Components/ProgressBar';
import CircleText from 'Components/Shapes/CircleText';
import { Tooltip } from '@material-ui/core';

const HelpRequestRow = ({
  firstName,
  lastName,
  quizName,
  lessonType,
  flowGeneratorSessionId,
  yearGroup,
  helpRequestInterval,
  setClaiming,
  avgScore,
  assignmentId,
  percentageComplete,
  percentages = [],
  country,
  ...props
}) => {
  const notify = useNotify();
  const onClaim = useCallback(async () => {
    try {
      setClaiming(true);
      await Api.claimFlowGeneratorSessionLesson(flowGeneratorSessionId);
      window.open(
        `/flow-generator-sessions/${flowGeneratorSessionId}/show`,
        '_blank',
        'noopener,noreferrer'
      );
    } catch (e) {
      console.error(e);
      notify('Unable to claim this lesson. Please try again.', 'error');
    }
    setClaiming(false);
  }, [flowGeneratorSessionId, notify, setClaiming]);
  const avgScoreFill = !avgScore
    ? '#ccc'
    : avgScore < 0.6
    ? 'red'
    : avgScore < 0.8
    ? 'orange'
    : 'green';
  return (
    <div {...props}>
      <Tooltip
        title={
          <ToolTipInner>
            <span style={{ margin: '0 0 0.15rem 0', fontStyle: 'italic' }}>
              Click to preview lesson
            </span>
            {percentages?.length > 0 &&
              percentages
                .sort((a, b) => a.sequence - b.sequence)
                .map((segment, i) => (
                  <span key={i} style={{ color: segment.color ?? 'white' }}>
                    {i === 0 && percentages?.length > 1
                      ? 'Green - correct diagnostic question check-in answers '
                      : i === 0 && percentages?.length === 1
                      ? 'Blue - lesson completion '
                      : 'Red - incorrect diagnostic question check-in answers '}
                    {segment.value}% {segment.tooltip}
                  </span>
                ))}
            <span>
              This is an
              {assignmentId ? ' Assignment ' : ' Independent Learning '}
              session
            </span>
            {yearGroup && <span>Year Group - {yearGroup}</span>}
            {avgScore && (
              <span style={{ color: avgScoreFill }}>
                Predicted correct check-in answer percentage is {avgScore * 100}
                % for this quiz.
              </span>
            )}
          </ToolTipInner>
        }
        placement="top">
        <HelpRow
          onClick={() =>
            window.open(
              `/flow-generator-sessions/${flowGeneratorSessionId}/show`,
              '_blank',
              'noopener,noreferrer'
            )
          }>
          <Left>
            <RagWrapper>
              <CircleText width="50px" fill={avgScoreFill}>
                <span>{!avgScore ? 'N/A' : `${avgScore * 100}%`}</span>
              </CircleText>
            </RagWrapper>
            <InfoWrapper>
              <span>
                <strong>
                  {firstName} {lastName}
                  {country ? ` (${country})` : ''}
                </strong>{' '}
                - {quizName ?? lessonType}
              </span>
              <span style={{ fontSize: '0.9rem' }}>{lessonType}</span>
              <span style={{ fontSize: '0.7rem' }}>
                ({helpRequestInterval})
              </span>
              <ProgressBar
                percentage={percentageComplete}
                percentages={percentages}
                tooltip="lesson complete"
              />
            </InfoWrapper>
          </Left>
          <Right>
            <ButtonWrappers>
              <ClaimButton onClick={onClaim}>
                Claim <OpenInNewIcon />
              </ClaimButton>
            </ButtonWrappers>
          </Right>
        </HelpRow>
      </Tooltip>
    </div>
  );
};

const Left = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const Right = styled.div`
  display: flex;
  align-items: center;
`;

const RagWrapper = styled.div`
  margin: 0 1rem 0 0;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 1rem 0 0;
`;

const ButtonWrappers = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const HelpRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px 10px 20px;
  border-bottom: 1px solid #e0e0e0;
  width: 95%;
  :last-child {
    border-bottom: none;
  }
  :hover {
    background: #555;
  }
  cursor: pointer;
`;

const ClaimButton = styled.button`
  display: flex;
  align-items: center;
  padding: 5px;
  cursor: pointer;
  border-radius: 5px;
  background: #cf425c;
  border: 1px solid #91142b;
  color: #fff;
  margin: 0 0.5rem 0 0.5rem;
`;

const ToolTipInner = styled.div`
  font-size: 0.8rem;
  display: flex;
  flex-direction: column;
  background: #222;
  border-radius: 5px;
  padding: 0.25rem 1.2rem 0.25rem 1.2rem;
`;

export default HelpRequestRow;
