export const lessonTypes = {
  Question: 'Question',
  Quiz: 'Quiz',
  ChallengeWorksheet: 'ChallengeWorksheet',
  TopicPathwayQuiz: 'TopicPathwayQuiz',
  Generic: 'Generic',
  ChatWithTutor: 'ChatWithTutor',
  TopicPathwayQuizQuestion: 'TopicPathwayQuizQuestion',
  QuestionOfTheDay: 'QuestionOfTheDay',
  OnDemand: 'OnDemand',
  RetrievalPractice: 'RetrievalPractice'
};

export const getTimeAgo = dateString => {
  if (!dateString) return 'N/A';

  const date = new Date(dateString);
  const now = new Date();
  const seconds = Math.floor((now - date) / 1000);
  let interval = Math.floor(seconds / 31536000);

  if (interval >= 1) {
    return interval + ' years ago';
  }
  interval = Math.floor(seconds / 2592000);
  if (interval >= 1) {
    return interval + ' months ago';
  }
  interval = Math.floor(seconds / 86400);
  if (interval >= 1) {
    return interval + ' days ago';
  }
  interval = Math.floor(seconds / 3600);
  if (interval >= 1) {
    return interval + ' hours ago';
  }
  interval = Math.floor(seconds / 60);
  if (interval >= 1) {
    return interval + ' minutes ago';
  }

  return 'Just now';
};

export const lessonTypeMapping = {
  0: lessonTypes.Question,
  1: lessonTypes.Quiz,
  2: lessonTypes.ChallengeWorksheet,
  3: lessonTypes.TopicPathwayQuiz,
  4: lessonTypes.Generic,
  7: lessonTypes.ChatWithTutor,
  8: lessonTypes.TopicPathwayQuizQuestion,
  9: lessonTypes.QuestionOfTheDay,
  10: lessonTypes.OnDemand,
  11: lessonTypes.RetrievalPractice
};
