import { Button } from '@material-ui/core';
import styled from 'styled-components';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useSelector, useDispatch } from 'react-redux';

const HidePanelButton = ({ property, flipIcons = false }) => {
  const dispatch = useDispatch();
  const isOpen = useSelector(s => s.uxState[property]);
  const payload = {};
  payload[property] = !isOpen;
  return (
    <StyledButton
      variant="contained"
      size="large"
      color="secondary"
      startIcon={
        isOpen ? (
          !flipIcons ? (
            <ChevronRightIcon />
          ) : (
            <ChevronLeftIcon />
          )
        ) : !flipIcons ? (
          <ChevronLeftIcon />
        ) : (
          <ChevronRightIcon />
        )
      }
      onClick={() =>
        dispatch({
          type: 'uxState/set',
          payload
        })
      }
    />
  );
};

const StyledButton = styled(Button)`
  z-index: 998;
  padding: 10px !important;
  height: 24px !important;
  width: 24px !important;
  min-width: 24px !important;
  max-width: 24px !important;
  span {
    display: contents;
  }
`;

export default HidePanelButton;
