import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import ListActions from './LessonListComponents/ListActions';
import LessonFilterForm from './LessonListComponents/LessonFilterForm';
import LessonDataGrid from './LessonListComponents/LessonDataGrid';
import ListBase from 'Components/ListBase';
import fetchLessonFilters from './Helpers/fetchLessonFilters';

const useStyles = makeStyles({
  root: {
    backgroundColor: '#fff',
    width: '100%',
    height: 'calc(100vh - 48px)',
    overflow: 'scroll'
  },
  listHeader: {
    position: 'sticky',
    top: 0,
    zIndex: 99,
    backgroundColor: '#fff'
  }
});

const LessonList = props => {
  const { onSelect, onToggleItem } = props;
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(25);
  const [sort, setSort] = useState({
    field: 'dateHelpRequested,dateCreated',
    order: 'DESC'
  });

  const classes = useStyles();

  const listProps = {
    data: [],
    ids: [],
    total: 0,
    page,
    setPerPage,
    perPage,
    setPage,
    rowsPerPageOptions: null,
    loaded: false,
    currentSort: sort,
    sort,
    basePath: '/',
    resource: 'flow-generator-sessions',
    selectedIds: [],
    setSort,
    onSelect,
    onToggleItem,
    filterDefaultValues: fetchLessonFilters({})
  };

  return (
    <div className={classes.root}>
      <div>
        <ListBase {...listProps}>
          <div className={classes.listHeader}>
            <ListActions {...props} />
            <LessonFilterForm {...props} />
          </div>
          <LessonDataGrid {...props} />
        </ListBase>
      </div>
    </div>
  );
};

export default LessonList;
