import { makeStyles } from '@material-ui/core/styles';
import {
  TextField,
  Button,
  Select,
  InputLabel,
  MenuItem,
  FormGroup
} from '@material-ui/core';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { React } from 'react';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import { Form, Field } from 'react-final-form';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import useUploadFile from 'Hooks/useUploadFile';
import styled from 'styled-components';
import { useNotify } from 'ra-core/esm/sideEffect';

const useStyles = makeStyles(theme => ({
  input: {
    margin: '1rem 0 0 0'
  },
  createButton: {
    padding: '4px',
    margin: '1rem 0 1rem 0',
    width: '150px'
  },
  cancelButton: {
    margin: '1rem 0 0 10px'
  },
  select: {
    margin: '1rem 0 0 0',
    width: '100%'
  }
}));

const CreateQuestionForm = ({ onSubmit, onCancel, question = {} }) => {
  const classes = useStyles();
  const notify = useNotify();

  const { file, onFileChange, fileRef, clearFile, urlFile } = useUploadFile(
    false,
    ['.jpg', '.png', '.jpeg']
  );

  return (
    <Form
      onSubmit={values => {
        if (!file) {
          notify('Please upload an image', 'warning');
          return;
        }
        onSubmit(values, file);
      }}
      initialValues={{ ...question, subjectIds: [] }}
      validate={values => {
        const errors = {};
        if (!values.correctAnswer) {
          errors.correctAnswer = 'Please enter a correct answer';
        }
        if (!values.explanationA) {
          errors.explanationA = 'Please enter an explanation A';
        }
        if (!values.explanationB) {
          errors.explanationB = 'Please enter an explanation B';
        }
        if (!values.explanationC) {
          errors.explanationC = 'Please enter an explanation C';
        }
        if (!values.explanationD) {
          errors.explanationD = 'Please enter an explanation D';
        }
        console.log(errors);
        return errors;
      }}>
      {({ handleSubmit, submitting, pristine, submitError }) => (
        <form onSubmit={handleSubmit}>
          <StyledButtonFormGroup>
            <Button
              type="file"
              variant="contained"
              color="secondary"
              component="label"
              className={classes.createButton}
              startIcon={<CloudUploadIcon />}>
              Upload Image
              <input
                type="file"
                ref={fileRef}
                onChange={onFileChange}
                style={{ margin: '1rem 0 1rem 0' }}
                hidden
              />
            </Button>
            <Button
              disabled={!file}
              type="file"
              variant="contained"
              color="default"
              component="label"
              className={classes.createButton}
              onClick={e => clearFile()}
              startIcon={<CancelIcon />}>
              Clear Image
            </Button>
          </StyledButtonFormGroup>
          <FormGroup>
            {!urlFile || !file ? null : (
              <img
                src={urlFile}
                alt="question"
                style={{ width: '600px', height: 'auto' }}
              />
            )}
          </FormGroup>
          <FormGroup>
            <Field name="correctAnswer">
              {({ input, meta }) => (
                <>
                  <InputLabel
                    required
                    id="subject-label"
                    className={classes.select}>
                    Correct Answer
                  </InputLabel>
                  <Select
                    {...input}
                    name="CorrectAnswer"
                    labelId="subject-label"
                    error={meta.error && meta.touched}>
                    {[
                      { key: 'A', value: 1 },
                      { key: 'B', value: 2 },
                      { key: 'C', value: 3 },
                      { key: 'D', value: 4 }
                    ].map((c, idx) => (
                      <MenuItem key={idx} value={c.value}>
                        {c.key}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              )}
            </Field>
          </FormGroup>
          <FormGroup>
            <Field name="explanationA">
              {({ input, meta }) => (
                <TextField
                  {...input}
                  id="standard-basic"
                  label="Teacher Explanation A"
                  variant="filled"
                  multiline
                  className={classes.input}
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : null}
                />
              )}
            </Field>
          </FormGroup>
          <FormGroup>
            <Field name="explanationB">
              {({ input, meta }) => (
                <TextField
                  {...input}
                  id="standard-basic"
                  label="Teacher Explanation B"
                  variant="filled"
                  multiline
                  className={classes.input}
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : null}
                />
              )}
            </Field>
          </FormGroup>
          <FormGroup>
            <Field name="explanationC">
              {({ input, meta }) => (
                <TextField
                  {...input}
                  id="standard-basic"
                  label="Teacher Explanation C"
                  variant="filled"
                  multiline
                  className={classes.input}
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : null}
                />
              )}
            </Field>
          </FormGroup>
          <FormGroup>
            <Field name="explanationD">
              {({ input, meta }) => (
                <TextField
                  {...input}
                  id="standard-basic"
                  label="Teacher Explanation D"
                  variant="filled"
                  multiline
                  className={classes.input}
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : null}
                />
              )}
            </Field>
          </FormGroup>
          <FormGroup>
            <Field name="subjectIds">
              {({ input, meta }) => (
                <>
                  <InputLabel
                    required
                    id="subjectIds-label"
                    className={classes.select}>
                    Subjects
                  </InputLabel>
                  <Select
                    {...input}
                    name="subjectIds"
                    labelId="subjectIds-label"
                    error={meta.error && meta.touched}>
                    {[].map((c, idx) => (
                      <MenuItem key={idx} value={c.code}>
                        {c.name}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              )}
            </Field>
          </FormGroup>
          <ButtonWrapper>
            <Button
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              className={classes.createButton}
              startIcon={<SaveIcon />}
              disabled={submitting || pristine || submitError}>
              Create
            </Button>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              className={classes.cancelButton}
              onClick={() => onCancel()}
              startIcon={<CancelIcon />}
              disabled={submitting}>
              Cancel
            </Button>
          </ButtonWrapper>
        </form>
      )}
    </Form>
  );
};

const ButtonWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  align-content: center;
  justify-content: flex-start;
`;

const StyledButtonFormGroup = styled(FormGroup)`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  align-content: center;
  justify-content: flex-start;
`;

export default CreateQuestionForm;
