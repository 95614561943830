import * as React from 'react';
import { Form, Field } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import { useLogin, useNotify, Notification } from 'react-admin';
import {
  Button,
  Container,
  Card,
  CardContent,
  TextField,
  Grid
} from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import GoogleLoginButton from './GoogleLoginButton';

const useStyles = makeStyles(() => ({
  root: {
    backgroundImage: 'url(/assets/wallpaper.jpg)',
    backgroundRepeat: 'no-repeat',
    height: '1px',
    display: 'flex',
    minHeight: '100vh',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundSize: 'cover',
    justifyContent: 'flex-start'
  },
  container: {},
  textInput: {
    width: '100%'
  },
  button: {
    width: '100%'
  },
  card: {
    margin: '50px 0 0 0',
    padding: '20px'
  },
  factBox: {}
}));

const CustomLoginPage = ({ theme }) => {
  const classes = useStyles();
  const initialValues = { username: '', password: '' };
  const login = useLogin();
  const notify = useNotify();
  const submit = async values => {
    login(values).catch(() =>
      notify(
        'Your username or password has not been recognised. Please try again.',
        'error'
      )
    );
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Container maxWidth="sm" className={classes.container}>
        <Card className={classes.card}>
          <CardContent>
            <div className={classes.factBox}>
              <h2>Sign in</h2>
            </div>
            <Form
              onSubmit={submit}
              validate={values => {
                const errors = {};
                if (!values.username) {
                  errors.username = 'Username is required';
                }
                if (!values.password) {
                  errors.password = 'Password is required';
                }
                return errors;
              }}
              initialValues={initialValues}>
              {({ handleSubmit, submitting, pristine }) => (
                <form onSubmit={handleSubmit}>
                  <Grid
                    container
                    direction="row"
                    alignItems="stretch"
                    spacing={3}>
                    <Grid item xs={12}>
                      <Field name="username">
                        {({ input, meta }) => (
                          <TextField
                            {...input}
                            className={classes.textInput}
                            error={meta.error && meta.touched}
                            variant="outlined"
                            label="Username"
                            helperText={
                              meta.error && meta.touched ? meta.error : null
                            }
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12}>
                      <Field name="password">
                        {({ input, meta }) => (
                          <TextField
                            {...input}
                            className={classes.textInput}
                            error={meta.error && meta.touched}
                            variant="outlined"
                            label="Password"
                            type="password"
                            helperText={
                              meta.error && meta.touched ? meta.error : null
                            }
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        className={classes.button}
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={submitting || pristine}>
                        Login
                      </Button>
                      <GoogleLoginButton
                        callback={r => submit({ googleIdToken: r?.credential })}
                      />
                    </Grid>
                  </Grid>
                </form>
              )}
            </Form>
          </CardContent>
        </Card>
        <Notification />
      </Container>
    </div>
  );
};

export default CustomLoginPage;
