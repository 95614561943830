/* eslint-disable jsx-a11y/media-has-caption */
import {
  CardContent,
  Select,
  InputLabel,
  MenuItem,
  FormGroup,
  Button,
  Grid,
  TextareaAutosize,
  Box
} from '@material-ui/core';
import Api from 'Api/Api';
import { constructVideos } from 'Pages/Constructs/Components/constructVideos';
import { useCallback, useEffect, useState, useRef } from 'react';
import { Loading, useNotify } from 'react-admin';

import styled from 'styled-components';

const EEDI_CONTENT_URL = 'https://home-content.eedi.com/eedi';

const EditWorksheetVideoPanel = ({ worksheetQuestion }) => {
  const notify = useNotify();
  const videoRef = useRef();

  const [loadingTranscripts, setLoadingTranscripts] = useState(true);
  const [videoSrc, setVideoSrc] = useState(null);
  const [playbackConfig, setPlaybackConfig] = useState({ speed: 1 });
  const [subtitles, setSubtitles] = useState(null);

  const [transcriptStatuses, setTranscriptStatuses] = useState([]);
  const [constructTranscripts, setConstructTranscripts] = useState([]);

  const saveProofedTranscript = async () => {
    console.log(subtitles);

    if (
      subtitles.origin === 'Proofed' ||
      subtitles.origin === 'LLM' ||
      subtitles.origin === 'Manual' ||
      subtitles.origin === 'GPT'
    ) {
      try {
        // Upsert the proofed transcript
        await Api.upsertProofedTranscript({
          constructId: worksheetQuestion.constructId,
          relativeVideoUrl: videoSrc,
          transcript: subtitles.text
        });
        notify('Transcript updated successfully', 'success');
      } catch (error) {
        notify(`Error saving transcript: ${error}`, 'error');
      }
    } else {
      notify(`No transcript to save. Transcript origin is unknown`, 'warning');
    }
  };

  const fetchProofedTranscript = async blobUrl => {
    try {
      const constructTranscriptResults = await Api.getProofedVideoTranscript(
        worksheetQuestion.constructId,
        blobUrl
      );
      return constructTranscriptResults;
    } catch (error) {
      console.error(error);
    }
  };

  const fetchProofedTranscripts = useCallback(async () => {
    try {
      const constructTranscriptResults =
        await Api.getProofedConstructTranscripts(worksheetQuestion.constructId);
      return constructTranscriptResults;
    } catch (error) {
      console.error(error);
    }
  }, [worksheetQuestion.constructId]);

  const fetchTranscriptStatuses = useCallback(async () => {
    const statuses = await Api.getConstructVideoTranscriptStatuses(
      worksheetQuestion.constructId
    );
    return statuses;
  }, [worksheetQuestion.constructId]);

  const processConstructVideoTranscripts = async () => {
    const blobUrls = constructVideos.map(v =>
      v.blobUrl(worksheetQuestion.constructId)
    );

    const statuses = await Api.processConstructVideoTranscripts(
      worksheetQuestion.constructId,
      blobUrls
    );

    setTranscriptStatuses(statuses);
  };

  const processConstructVideoTranscript = async (
    constructVideoBlobUrl,
    force = false
  ) => {
    const result = await Api.processConstructVideoTranscript(
      worksheetQuestion.constructId,
      constructVideoBlobUrl,
      force
    );

    setTranscriptStatuses(prevState => {
      const existing = transcriptStatuses.find(
        s => s.blob_name === constructVideoBlobUrl
      );
      if (existing) {
        return prevState.map(s => {
          if (s.blob_name === constructVideoBlobUrl) {
            return result;
          }
          return s;
        });
      }

      return prevState;
    });
  };

  const onSelectVideo = async blobUrl => {
    const label = constructVideos.find(
      v => v.blobUrl(worksheetQuestion.constructId) === blobUrl
    ).label;

    const llmTranscript = transcriptStatuses.find(s => s.blob_name === blobUrl);
    const proofedTranscript = await fetchProofedTranscript(blobUrl);

    const subtitlesToShow = proofedTranscript.transcript?.length
      ? proofedTranscript.transcript
      : llmTranscript?.vtt_file ??
        'WEBVTT\n\n00:00:00.000 --> 00:00:02.000\nStart editing here.';

    const base64Vtt = btoa(unescape(encodeURIComponent(subtitlesToShow)));

    console.log(proofedTranscript);

    setSubtitles({
      label,
      blobUrl,
      origin: proofedTranscript.transcript?.length
        ? 'Proofed'
        : llmTranscript?.gpt_file
        ? 'GPT'
        : llmTranscript?.vtt_file
        ? 'LLM'
        : 'Manual',
      text: subtitlesToShow,
      base64: `data:text/vtt;base64,${base64Vtt}`
    });
    setVideoSrc(blobUrl);
  };

  const onSourceChange = async evt => {
    const option = evt.target.value;

    if (option === 'LLM') {
      const llmTranscript = transcriptStatuses.find(
        s => s.blob_name === videoSrc
      );
      if (llmTranscript?.vtt_file?.length) {
        setSubtitles(prevState => ({
          ...prevState,
          origin: 'LLM',
          text: llmTranscript.vtt_file,
          base64: `data:text/vtt;base64,${btoa(
            unescape(encodeURIComponent(llmTranscript.vtt_file))
          )}`
        }));
      } else {
        notify('No LLM transcript found', 'warning');
      }
    } else if (option === 'GPT') {
      const llmTranscript = transcriptStatuses.find(
        s => s.blob_name === videoSrc
      );
      if (llmTranscript?.gpt_file?.length) {
        setSubtitles(prevState => ({
          ...prevState,
          origin: 'GPT',
          text: llmTranscript.gpt_file,
          base64: `data:text/vtt;base64,${btoa(
            unescape(encodeURIComponent(llmTranscript.gpt_file))
          )}`
        }));
      } else {
        notify('No GPT transcript found', 'warning');
      }
    } else if (option === 'Proofed') {
      const proofedTranscript = await fetchProofedTranscript(videoSrc);
      if (proofedTranscript.transcript?.length) {
        setSubtitles(prevState => ({
          ...prevState,
          origin: 'Proofed',
          text: proofedTranscript.transcript,
          base64: `data:text/vtt;base64,${btoa(
            unescape(encodeURIComponent(proofedTranscript.transcript))
          )}`
        }));
      } else {
        notify('No proofed transcript found', 'warning');
      }
    } else if (option === 'Manual') {
      setSubtitles(prevState => ({
        ...prevState,
        origin: 'Manual',
        text: 'WEBVTT\n\n00:00:00.000 --> 00:00:02.000\nStart editing here.',
        base64: `data:text/vtt;base64,${btoa(
          unescape(
            encodeURIComponent(
              'WEBVTT\n\n00:00:00.000 --> 00:00:02.000\nStart editing here.'
            )
          )
        )}`
      }));
    }
  };

  const handlePlaybackSpeedChange = evt => {
    setPlaybackConfig({ speed: evt.target.value });
    if (videoRef.current) {
      videoRef.current.playbackRate = evt.target.value;
    }
  };

  const handleVideoPause = () => {
    if (videoRef.current) {
      videoRef.current.pause();
    }
  };

  const handleSubtitleChange = evt => {
    setSubtitles({ ...subtitles, text: evt.target.value });
  };

  const rerenderSubtitle = () => {
    const base64Vtt = btoa(unescape(encodeURIComponent(subtitles.text)));
    setSubtitles({
      ...subtitles,
      base64: `data:text/vtt;base64,${base64Vtt}`
    });
  };

  const loadStates = async () => {
    const proofedTranscripts = await fetchProofedTranscripts();
    if (proofedTranscripts.data.length) {
      // If any, set the proofed transcripts for this construct to state
      setConstructTranscripts(proofedTranscripts.data);
    }

    const statuses = await fetchTranscriptStatuses();
    if (statuses.length) {
      setTranscriptStatuses(statuses);
    }
  };

  useEffect(() => {
    let runEffect = async () => {
      await loadStates();
      setLoadingTranscripts(false);
    };

    if (worksheetQuestion?.constructId) {
      setLoadingTranscripts(true);
      runEffect();
    } else {
      notify('No constructId found', 'warning');
    }
  }, [notify, worksheetQuestion?.constructId]);

  useEffect(() => {
    if (!subtitles?.base64) return;

    // This effect will run whenever the subtitles state changes
    const videoElement = document.getElementById('video');
    const trackElement = videoElement?.querySelector('track');
    trackElement.src = subtitles.base64;
    videoElement.load(); // Reload the video to apply the new subtitles
  }, [subtitles?.base64]);

  if (loadingTranscripts) return <Loading />;

  const fullVideoSrc = videoSrc ? `${EEDI_CONTENT_URL}/${videoSrc}` : null;

  console.log(subtitles);

  return (
    <Box sx={{ width: '800px' }}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <StyledFormGroup>
              <InputLabel id="videos-label">Videos</InputLabel>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Select
                  style={{ width: '100%' }}
                  name="Construct Videos"
                  labelId="videos-label"
                  onChange={e => onSelectVideo(e.target.value)}
                  value={videoSrc}>
                  {constructVideos.map((video, idx) => {
                    const videoBlobUrl = video.blobUrl(
                      worksheetQuestion?.constructId
                    );
                    const proofedTranscript = constructTranscripts.find(
                      s => s.relativeVideoUrl === videoBlobUrl
                    );
                    const transcriptStatus = transcriptStatuses.find(
                      s => s.blob_name === videoBlobUrl
                    );

                    console.log(proofedTranscript);

                    return (
                      <MenuItem key={idx} value={videoBlobUrl}>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%'
                          }}>
                          <span
                            style={{
                              textDecoration: proofedTranscript
                                ? 'unset'
                                : 'line-through',
                              color: proofedTranscript ? 'green' : 'gray'
                            }}>
                            Proofed
                          </span>
                          <span>{video.label}</span>
                          <span
                            style={{
                              color:
                                transcriptStatus?.status === 'Processed'
                                  ? 'green'
                                  : transcriptStatus?.status === 'Processing'
                                  ? 'blue'
                                  : 'red'
                            }}>
                            {transcriptStatus?.status ?? 'Not Processed'}
                          </span>
                        </div>
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>
            </StyledFormGroup>
            {subtitles && (
              <Button
                color="primary"
                onClick={async () =>
                  await processConstructVideoTranscript(subtitles.blobUrl, true)
                }>
                Re-Process {subtitles.label}
              </Button>
            )}
          </Grid>
          <Grid item>
            <Button color="primary" onClick={async () => await loadStates()}>
              Refresh Video Statuses
            </Button>
            <Button
              color="primary"
              onClick={() => processConstructVideoTranscripts()}>
              Process all videos
            </Button>
          </Grid>
        </Grid>

        {!!fullVideoSrc ? (
          <Grid container spacing={2}>
            <Grid item xs={7}>
              <video
                id="video"
                ref={videoRef}
                controls
                autoPlay
                name="media"
                style={{ width: '100%' }}
                key={fullVideoSrc}>
                <source src={fullVideoSrc} type="video/mp4" />

                {!!subtitles && (
                  <track
                    label="English"
                    kind="subtitles"
                    src={subtitles.base64}
                    default
                  />
                )}
              </video>
              <InputLabel id="playback-speed">Playback Speed</InputLabel>
              <Select
                labelId="playback-speed"
                value={playbackConfig.speed}
                onChange={handlePlaybackSpeedChange}>
                {[1, 1.5, 2].map(speed => (
                  <MenuItem value={speed}>{speed}x</MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={5}>
              <div>
                Subtitles (Using
                <Select
                  style={{ marginLeft: '0.8rem' }}
                  onChange={onSourceChange}
                  value={subtitles.origin}>
                  {['LLM', 'Proofed', 'Manual'].map((option, idx) => (
                    <MenuItem value={option}>{option}</MenuItem>
                  ))}
                </Select>
                )
              </div>

              <TextareaAutosize
                style={{ width: '100%' }}
                maxRows={20}
                placeholder="No Subtitles Found"
                value={subtitles?.text}
                onChange={handleSubtitleChange}
                onSelect={handleVideoPause}
              />
              <Button color="primary" onClick={rerenderSubtitle}>
                Render Subtitle (Live)
              </Button>
              <Button color="secondary" onClick={saveProofedTranscript}>
                Save Subtitle
              </Button>
            </Grid>
          </Grid>
        ) : (
          <p>No video selected</p>
        )}
      </CardContent>
    </Box>
  );
};

const StyledFormGroup = styled(FormGroup)`
  margin: 0.25rem 0 0.25rem 0;
`;

export default EditWorksheetVideoPanel;
