import { makeStyles } from '@material-ui/core/styles';
import {
  Modal,
  Backdrop,
  Container,
  Card,
  CardContent,
  Typography
} from '@material-ui/core';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useCallback, React } from 'react';
import { useDataProvider, useNotify, useRefresh } from 'react-admin';
import EditWorksheetQuestionForm from './EditWorksheetQuestionForm';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  create: {
    margin: '1rem 0 0 0',
    overflowY: 'scroll',
    maxHeight: `${window.innerHeight ?? 900}px`
  },
  input: {
    margin: '1rem 0 0 0'
  },
  createButton: {
    margin: '1rem 0 0 0'
  },
  cancelButton: {
    margin: '1rem 0 0 10px'
  },
  select: {
    margin: '1rem 0 0 0',
    width: '100%'
  },
  numberInput: {
    margin: '1rem 0 0 0'
  }
}));

const CreateWorksheetQuestionModal = ({ isOpen, setIsOpen }) => {
  const notify = useNotify();
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();

  const createWorksheetQuestion = useCallback(
    async values => {
      try {
        await dataProvider.create('worksheet-questions', {
          body: values
        });
        refresh();
        setIsOpen();
        notify('Worksheet Question Created!', 'success');
      } catch (e) {
        notify('Error Updating Worksheet Question!', 'error');
        console.error(e);
      }
    },
    [dataProvider, notify, refresh, setIsOpen]
  );

  return (
    <Modal
      open={isOpen}
      onClose={() => setIsOpen()}
      className={classes.modal}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
      closeAfterTransition>
      <Container maxWidth="md">
        <Card className={classes.create}>
          <CardContent>
            <Typography variant="h5" gutterBottom>
              Create Worksheet Question
            </Typography>
            <EditWorksheetQuestionForm
              onSubmit={createWorksheetQuestion}
              onCancel={setIsOpen}
            />
          </CardContent>
        </Card>
      </Container>
    </Modal>
  );
};

export default CreateWorksheetQuestionModal;
