import { useCallback, useRef, useState } from 'react';
import ToolButton from '../components/ToolButton';

const imageDefault = {
  x: 0,
  y: 0,
  width: 0,
  height: 0
};

const useUploadImage = ({ updateImage = () => {} }) => {
  const [image, setImage] = useState(null);
  const fileInputRef = useRef(null);

  const handleFileChange = useCallback(
    e => {
      const file = e.target.files[0];
      console.log(file);
      if (file) {
        const reader = new FileReader();
        reader.onload = event => {
          const img = new window.Image();
          img.src = event.target.result;
          img.onload = () => {
            const newImage = {
              ...img,
              ...imageDefault,
              src: event.target.result,
              file,
              payload: getPayload(file, event.target.result),
              width: img.width,
              height: img.height
            };
            setImage(newImage);
            updateImage(newImage);
          };
        };
        reader.readAsDataURL(file);
        // remove file
        e.target.value = '';
      }
    },
    [updateImage]
  );

  const getPayload = (file, source) => {
    if (!file || !source) {
      return null;
    }

    return {
      base64Data: source.split(',')[1],
      filename: file.name
    };
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const button = (
    <div>
      <ToolButton preIcon="image-add" onClick={handleButtonClick} />
      <input
        type="file"
        accept="image/jpeg, image/jpg, image/png"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
    </div>
  );

  return {
    button,
    image
  };
};

export default useUploadImage;
