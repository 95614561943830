import styled from 'styled-components';

const CircleText = ({ width = '25px', fill = '#ccc', children, ...props }) => {
  return (
    <Circle width={width} fill={fill} {...props}>
      {children}
    </Circle>
  );
};

const Circle = styled.div`
  ${({ width = '25px', fill = '#ccc' }) => `
    width: ${width};
    height: ${width};
    border-radius: 50%;
    background: ${fill};
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;

export default CircleText;
