const initialState = {};

export default function usersReducer(state = initialState, action) {
  switch (action.type) {
    case 'user/set':
      localStorage.setItem('user', JSON.stringify(action.payload));
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
