import { makeStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, Button } from '@material-ui/core';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import { useState } from 'react';
import { useNotify } from 'react-admin';
import Api from 'Api/Api';
import Loading from '../Loading';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '400px'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  container: {
    minWidth: '400px'
  },
  input: {
    width: '100%',
    margin: '0.5rem 0 1rem'
  },
  formControl: {
    width: '100%'
  },
  button: {
    float: 'right',
    margin: '0.5rem 0 0 1rem'
  }
}));

const SaveFlowItemTemplateLayoutModal = ({
  isModalOpen,
  setIsModalOpen,
  templateSlug,
  flowItems,
  nodes
}) => {
  const notify = useNotify();
  const [isSaving, setIsSaving] = useState(false);
  const classes = useStyles();

  const onSubmit = async () => {
    try {
      setIsSaving(true);
      const updatedFlowItems = [];

      nodes.forEach(node => {
        const flowItem = flowItems.find(f => f.flowItemId === node.id);
        if (!flowItem) return;

        if (
          node.position.x !== flowItem.xPosition ||
          node.position.y !== flowItem.yPosition
        ) {
          updatedFlowItems.push({
            ...flowItem,
            xPosition: node.position.x,
            yPosition: node.position.y
          });
        }
      });
      for (const flowTemplate of updatedFlowItems.sort(
        (a, b) => a.sequence - b.position
      )) {
        await Api.updateFlowItem(
          templateSlug,
          flowTemplate,
          flowTemplate.sequence,
          true
        );
        await new Promise(resolve => setTimeout(resolve, 1000));
      }

      notify('Layout changes saved!', 'success');
    } catch (e) {
      notify('Error saving layout changes, check logs.', 'error');
      console.error(e);
    }
    setIsModalOpen(false);
    setIsSaving(false);
  };

  return (
    <Modal
      open={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      className={classes.modal}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
      closeAfterTransition>
      <div className={classes.paper}>
        {!isSaving ? (
          <h3>
            Are you sure you want to save the layout changes for <br />
            {templateSlug}?
          </h3>
        ) : (
          <Loading />
        )}
        <form className={classes.container} noValidate>
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.button}
            startIcon={<SaveIcon />}
            onClick={onSubmit}
            disabled={isSaving}>
            Save
          </Button>
          <Button
            variant="contained"
            disabled={isSaving}
            className={classes.button}
            startIcon={<CancelIcon />}
            onClick={() => setIsModalOpen(false)}>
            Cancel
          </Button>
        </form>
      </div>
    </Modal>
  );
};

export default SaveFlowItemTemplateLayoutModal;
