import { Circle, Line } from 'react-konva';

export const getDotMatrixGrid = ({
  width,
  height,
  dotSpacing,
  dotRadius,
  dotColor
}) => {
  const grid = [];
  for (let x = 0; x < width; x += dotSpacing) {
    for (let y = 0; y < height; y += dotSpacing) {
      grid.push(
        <Circle
          key={`${x}-${y}`}
          x={x}
          y={y}
          radius={dotRadius}
          fill={dotColor}
        />
      );
    }
  }
  return grid;
};

export const getLineGrid = ({
  width,
  height,
  lineSpacing,
  lineColor,
  lineWidth
}) => {
  const grid = [];
  for (let x = 0; x < width; x += lineSpacing) {
    grid.push(
      <Line
        key={`x-${x}`}
        points={[x, 0, x, height]}
        stroke={lineColor}
        strokeWidth={lineWidth}
      />
    );
  }
  for (let y = 0; y < height; y += lineSpacing) {
    grid.push(
      <Line
        key={`y-${y}`}
        points={[0, y, width, y]}
        stroke={lineColor}
        strokeWidth={lineWidth}
      />
    );
  }
  return grid;
};
