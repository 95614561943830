import { useEffect, useState } from 'react';
import authProvider from 'authProvider';

const useGetAuthUser = () => {
  const [user, setUser] = useState(null);
  useEffect(() => {
    async function runEffect() {
      if (!user) {
        setUser(await authProvider.getIdentity());
      }
    }
    runEffect();
  }, [user]);
  return user;
};

export default useGetAuthUser;
