import {
  Card,
  CardContent,
  Container,
  Typography,
  TextField,
  Button,
  FormGroup,
  Select,
  FormLabel,
  MenuItem
} from '@material-ui/core';
import Api from 'Api/Api';
import { useCallback, useState } from 'react';
import { useNotify, Notification } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Form, Field } from 'react-final-form';
import StarsIcon from '@material-ui/icons/Stars';

const useStyles = makeStyles({
  root: {
    margin: '1rem 0 0 0'
  },
  input: {
    margin: '1rem 0 0 0'
  }
});

const awardActions = [
  {
    value: 'Awarded50',
    key: '50 Coins'
  },
  {
    value: 'Awarded100',
    key: '100 Coins'
  },
  {
    value: 'Awarded150',
    key: '150 Coins'
  },
  {
    value: 'Awarded200',
    key: '200 Coins'
  },
  {
    value: 'Awarded250',
    key: '250 Coins'
  },
  {
    value: 'Awarded300',
    key: '300 Coins'
  },
  {
    value: 'Awarded350',
    key: '350 Coins'
  },
  {
    value: 'Awarded400',
    key: '400 Coins'
  },
  {
    value: 'Awarded450',
    key: '450 Coins'
  },
  {
    value: 'Awarded500',
    key: '500 Coins'
  }
];

const AwardsShow = props => {
  const classes = useStyles();
  const notify = useNotify();
  const [state, setState] = useState({
    dispatching: false
  });

  const filterUserIds = useCallback(
    userIds => {
      try {
        if (!userIds?.length) {
          return [];
        }
        if (typeof userIds === 'number') {
          return [userIds];
        }
        return [...new Set(userIds.split(',').map(id => parseInt(id)))];
      } catch (e) {
        console.error(e);
        notify('Error: Filtering user ids failed.', 'warning');
      }
      return [];
    },
    [notify]
  );

  const award = useCallback(
    async values => {
      try {
        setState(() => ({ ...state, dispatching: true }));
        await Api.bulkUpdateUserEvents({
          action: values.awardAction,
          schoolId: values.schoolId,
          userIds: filterUserIds(values.userId)
        });
        notify('Coins awarded!', 'success');
      } catch (e) {
        console.error(e);
        notify('Error: Awarding coins failed.', 'warning');
      }
      setState(() => ({ ...state, dispatching: false }));
    },
    [filterUserIds, notify, state]
  );

  return (
    <Container maxWidth="md">
      <Card className={classes.root}>
        <CardContent>
          <Form
            onSubmit={award}
            initialValues={{
              schoolId: null,
              userId: null,
              awardAction: null
            }}
            validate={values => {
              const errors = {};
              if (values.schoolId === null && values.userId === null) {
                errors.schoolId = 'Please enter a school or user.';
                errors.userId = 'Please enter a school or user.';
              }
              if (values.schoolId !== null && values.userId !== null) {
                errors.schoolId = 'Please enter a school or user, not both.';
                errors.userId = 'Please enter a school or user, not both.';
              }
              if (values.awardAction === null) {
                errors.awardAction = 'Please select an award action.';
              }
              return errors;
            }}>
            {({ handleSubmit, submitting, pristine, submitError }) => (
              <form onSubmit={handleSubmit}>
                <Typography variant="h5" gutterBottom>
                  Student Awards
                </Typography>
                <Typography
                  variant="subtitle1"
                  className={classes.schoolName}
                  gutterBottom>
                  Enter either a SchoolId or a UserId to award coins to a
                  student.
                  <br />
                  Entering a SchoolId will award coins to all students in that
                  school.
                </Typography>
                <FormGroup>
                  <Field name="schoolId">
                    {({ input, meta }) => (
                      <TextField
                        {...input}
                        id="standard-basic"
                        label="SchoolId"
                        variant="filled"
                        type="number"
                        className={classes.input}
                        error={meta.error && meta.touched}
                        helperText={
                          meta.error && meta.touched ? meta.error : null
                        }
                      />
                    )}
                  </Field>
                </FormGroup>
                <FormGroup>
                  <Field name="userId">
                    {({ input, meta }) => (
                      <TextField
                        {...input}
                        id="standard-basic"
                        label="UserId(s) - comma separated"
                        variant="filled"
                        type="text"
                        className={classes.input}
                        error={meta.error && meta.touched}
                        helperText={
                          meta.error && meta.touched ? meta.error : null
                        }
                      />
                    )}
                  </Field>
                </FormGroup>
                <FormGroup style={{ margin: '1rem 0 1rem 0' }}>
                  <FormLabel>Award Actions</FormLabel>
                  <Field name="awardAction">
                    {({ input, meta }) => (
                      <Select
                        {...input}
                        name="awardAction"
                        error={meta.error && meta.touched}>
                        {awardActions.map((s, idx) => (
                          <MenuItem key={idx} value={s.value}>
                            {s.key}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  </Field>
                </FormGroup>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  type="submit"
                  className={classes.createButton}
                  startIcon={<StarsIcon />}
                  disabled={
                    submitting || pristine || submitError || state.dispatching
                  }>
                  Send Awards
                </Button>
              </form>
            )}
          </Form>
        </CardContent>
        <Notification />
      </Card>
    </Container>
  );
};

export default AwardsShow;
