import styled from 'styled-components';
import { Button, Typography, CircularProgress } from '@material-ui/core';
import { useCallback } from 'react';
import { useNotify } from 'react-admin';
import { useState } from 'react';
import Api from 'Api/Api';

const GPTFeedback = ({
  tableName = null,
  partitionKey = null,
  rowKey = null,
  comments = ''
}) => {
  const notify = useNotify();
  const [sending, setSending] = useState(false);
  const [isFeedbackGiven, setIsFeedbackGiven] = useState(false);
  const sendFeedback = useCallback(
    async feedback => {
      setSending(true);
      try {
        if (partitionKey === null || rowKey === null || tableName === null)
          throw new Error('No partitionKey or rowKey provided');

        await Api.sendGPTFeedback({
          feedbackTableName: tableName,
          feedbackPartitionKey: partitionKey,
          feedbackRowKey: rowKey,
          comments,
          ...feedback
        });
        setIsFeedbackGiven(true);
        notify('Feedback sent!', 'success');
      } catch (e) {
        console.error(e);
        notify(`Error sending feedback: ${e}`, 'error');
      }
      setSending(false);
    },
    [comments, notify, partitionKey, rowKey, tableName]
  );

  if (!tableName || !partitionKey || !rowKey) return null;

  return (
    <FeedbackBox>
      <div>
        <h5>How was this response?</h5>
      </div>
      <ButtonWrapper>
        <Button
          color="secondary"
          size="small"
          variant="outlined"
          style={{ background: '#fff' }}
          type="button"
          disabled={isFeedbackGiven || sending}
          onClick={() => sendFeedback({ isPositiveFeedback: false })}>
          👎
        </Button>
        <Button
          color="secondary"
          size="small"
          variant="outlined"
          style={{ background: '#fff' }}
          type="button"
          disabled={isFeedbackGiven | sending}
          onClick={() => sendFeedback({ isPositiveFeedback: true })}>
          👍
        </Button>
      </ButtonWrapper>
      {sending && (
        <div>
          <CircularProgress size={20} />
          <Typography>Sending feedback...</Typography>
        </div>
      )}
    </FeedbackBox>
  );
};

const FeedbackBox = styled.div`
  h5 {
    line-height: 1rem;
    padding: 0;
    margin: 0 0 0.5rem 0;
  }
`;

const ButtonWrapper = styled.div`
  button {
    margin: 0 0.5rem 0 0;
  }
`;

export default GPTFeedback;
