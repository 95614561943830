import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '1rem',
    borderBottom: '1px solid #ddd',
    marginBottom: '1rem'
  },
  successPill: {
    display: 'flex',
    padding: '0.5rem',
    backgroundColor: '#4caf50',
    color: '#fff',
    borderRadius: '4px',
    marginRight: '1rem',
    width: '150px'
  },
  failedPill: {
    display: 'flex',
    padding: '0.5rem',
    backgroundColor: '#f44336',
    color: '#fff',
    borderRadius: '4px',
    marginRight: '1rem',
    width: '150px'
  },
  errorBox: {
    margin: '1rem 0 0 0',
    padding: '0.5rem',
    border: '1px solid #f44336',
    borderRadius: '4px',
    backgroundColor: '#fff',
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    overflowY: 'scroll',
    maxWidth: '800px'
  }
}));

const FlowImportResponse = ({
  response,
  title,
  flowTemplateType = 'flowItems'
}) => {
  const classes = useStyles();
  if (!response) return null;

  return (
    <Box className={classes.root}>
      <h3>{title}</h3>
      <div>
        <p>
          <strong>
            {flowTemplateType === 'flowItems'
              ? 'Flow Items Count'
              : 'Flow Templates Count'}
            :{' '}
          </strong>
          {flowTemplateType === 'flowItems'
            ? response.flowItemsCount
            : response.flowTemplatesCount}
        </p>
      </div>
      {response.isSuccess ? (
        <div>
          <div className={classes.successPill}>
            <CheckIcon /> Success
          </div>
        </div>
      ) : (
        <div>
          <div className={classes.failedPill}>
            <ClearIcon /> Failed Validation
          </div>
          <div className={classes.errorBox}>
            {response.errors.map((error, index) => (
              <div key={index}>{error}</div>
            ))}
          </div>
        </div>
      )}
    </Box>
  );
};

export default FlowImportResponse;
