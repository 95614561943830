import { useEffect, useState, useContext } from 'react';
import { SignalRConnectionContext } from 'Providers/SignalRProvider';

export const useJoinLessonDashboardSignalRGroup = () => {
  const { signalRConnection, connectionInfo } = useContext(
    SignalRConnectionContext
  );

  const [connectionDate, setConnectionDate] = useState(null);

  useEffect(() => {
    const runEffect = async () => {
      if (
        (!connectionDate && connectionInfo.isConnected) ||
        connectionDate !== connectionInfo.connectionDate
      ) {
        try {
          console.warn('connectionInfo - Joining lesson dashboard group');
          await signalRConnection.invoke('JoinLessonDashboardGroup');
        } catch (e) {
          console.error(
            'connectionInfo - error joining lesson dashboard group',
            e
          );
        }
        setConnectionDate(connectionInfo.connectionDate);
      }
    };
    runEffect();
  }, [
    connectionDate,
    connectionInfo.connectionDate,
    connectionInfo.isConnected,
    connectionInfo.isReconnecting,
    signalRConnection
  ]);
};
