import { List, Pagination, FunctionField, Datagrid } from 'react-admin';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {
    margin: '12px',
    minHeight: '350px'
  }
}));

const LessonPagination = props => (
  <Pagination rowsPerPageOptions={[100]} {...props} />
);

const Lesson = props => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <List
        title="flow-generator-sessions"
        pagination={<LessonPagination />}
        bulkActionButtons={false}
        perPage={100}
        sort={{ field: 'dateCreated', order: 'DESC' }}
        {...props}>
        <Datagrid rowClick="show">
          <FunctionField
            source="id"
            sortByOrder="DESC"
            label="ID"
            sortBy="FlowGeneratorSessionId"
            render={r => `#${r.id}`}
          />
          <FunctionField
            label="Name"
            reference="flow-generator-sessions"
            sortBy="User.FirstName, User.LastName"
            render={r => `${r.firstName} ${r.lastName}`}
          />
          <FunctionField
            label="Quiz"
            reference="flow-generator-sessions"
            sortBy="Quiz.Name"
            render={r => r.quizName ?? 'N/A'}
          />
          <FunctionField
            label="Quiz ID"
            reference="flow-generator-sessions"
            sortBy="QuizId"
            render={r => r.quizId ?? 'N/A'}
          />
          <FunctionField
            label="Started"
            reference="flow-generator-sessions"
            sortBy="DateCreated"
            render={r =>
              r.dateCreated ? moment(r.dateCreated).format('YYYY/DD/MM') : '-'
            }
          />
          <FunctionField
            label="Completed"
            reference="flow-generator-sessions"
            sortBy="DateCompleted"
            render={r =>
              r.dateCompleted
                ? moment(r.dateCompleted).format('YYYY/DD/MM')
                : '-'
            }
          />
        </Datagrid>
      </List>
    </Box>
  );
};

export default Lesson;
