import { Loading } from 'react-admin';
import styled from 'styled-components';
import {
  TextField,
  FormGroup,
  MenuItem,
  FormControl,
  InputLabel,
  Button
} from '@material-ui/core';
import ImageMetaDataCanvas from './ImageMetaDataCanvas';
import React, { useCallback, useEffect, useState } from 'react';
import { Select } from '@material-ui/core';
import Latex from 'react-latex';
import { cropImage } from './Components/imageHelpers';
import MetaDataReviewModal from './Components/MetaDataReviewModal';
import RateReviewIcon from '@material-ui/icons/RateReview';

const textTypes = [
  { value: 'None', label: 'None' },
  { value: 'PlainText', label: 'Plain Text' },
  { value: 'LaTeX', label: 'LaTeX' },
  { value: 'Markdown', label: 'Markdown' }
];

const ImageMetaDataShow = ({
  defaultImageWidth = 700,
  isLoading = true,
  metaData = [],
  metaDataTags = [],
  editState = { imageURL: null, bitmap: null, isLoaded: false },
  insertMetaData = () => {},
  updateMetaData = () => {},
  handleDeletePress = () => {},
  setMetaData = () => {}
}) => {
  const [metaDataReviewModalId, setMetaDataReviewModalId] = useState(null);
  const [drawing, setDrawing] = useState(false);
  const [startPos, setStartPos] = useState({ x: 0, y: 0 });
  const [endPos, setEndPos] = useState({ x: 0, y: 0 });
  const [selectedRectangleId, setSelectedRectangleId] = useState(null);

  const handleKeyDown = useCallback(e => {
    if (e.keyCode === 27) {
      setSelectedRectangleId(null);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  });

  const handleMouseDown = e => {
    const isClickingOnEmpty = !e.target.parent?.attrs?.x && !e.target.attrs?.y;
    if (isClickingOnEmpty) {
      setSelectedRectangleId(null);
    }
    if (selectedRectangleId || !isClickingOnEmpty) {
      setDrawing(false);
    }
    if ((drawing || selectedRectangleId) && !isClickingOnEmpty) return;
    setDrawing(true);
    const pos = e.target.getStage().getPointerPosition();
    setStartPos(pos);
    setEndPos(pos);
  };

  const handleMouseMove = e => {
    if (!drawing) return;
    const pos = e.target.getStage().getPointerPosition();
    setEndPos(pos);
  };

  const generateId = id =>
    `${id ? id : ''}${id !== null ? '-' : ''}${
      metaData.filter(r => (id !== null ? r.id.includes(id) : true)).length + 1
    }`;

  const handleMouseUp = async ({ id = null, e }) => {
    if (!drawing) return;
    const rectangleId = generateId(id);
    setDrawing(false);

    const width = endPos.x - startPos.x;
    const height = endPos.y - startPos.y;

    if (width <= 20 || height <= 20) return;

    const scale = defaultImageWidth / editState.bitmap.width;

    const newMetaData = {
      x: startPos.x,
      y: startPos.y,
      width,
      height,
      rectangleId,
      hexColor: '#DC143C',
      opacity: 0.5,
      textType: 'None',
      sourceWidth: editState.bitmap.width,
      sourceHeight: editState.bitmap.height,
      sourceImageURL: editState.imageURL,
      scale,
      isStale: true,
      isOCRAble: true,
      sequence: metaData.length + 1
    };

    newMetaData.blob = await cropImage({
      ...newMetaData,
      bitmap: editState.bitmap
    });

    setMetaData(
      [...metaData, newMetaData].filter(s => s.width > 30 && s.height > 30)
    );

    setSelectedRectangleId(rectangleId);
  };

  const handleRectClick = (e, rectangleId) => {
    setDrawing(false);
    e.cancelBubble = true;
    setSelectedRectangleId(
      selectedRectangleId === rectangleId ||
        !metaData.find(m => m.rectangleId === rectangleId)
        ? null
        : rectangleId
    );
  };

  const handleRectDragStart = (e, rectangleId) => {
    setDrawing(false);
  };

  const handleRectDragEnd = async (e, rectangleId, width, height) => {
    const { x, y } = e.target.attrs;
    const scale = defaultImageWidth / editState.bitmap.width;

    const updatedMetaData = metaData.map(s => {
      if (s.rectangleId === rectangleId) {
        const updated = {
          ...s,
          x,
          y,
          width,
          height,
          scale,
          isStale: true
        };
        return updated;
      }
      return s;
    });

    const indexOfUpdated = updatedMetaData.findIndex(
      s => s.rectangleId === rectangleId
    );

    if (indexOfUpdated !== -1) {
      updatedMetaData[indexOfUpdated].blob = await cropImage({
        ...updatedMetaData[indexOfUpdated],
        bitmap: editState.bitmap
      });
    }

    setMetaData(updatedMetaData);
  };

  const onEditMetaData = useCallback(
    async (index, propertyName, value) => {
      const metaDataCopy = [...metaData];
      metaDataCopy[index][propertyName] = value;
      metaDataCopy[index].isStale = true;
      setMetaData(metaDataCopy);
    },
    [metaData, setMetaData]
  );

  if (isLoading) return <Loading />;

  return (
    <Root>
      <Left>
        {metaData.map((m, i) => (
          <MetaDataWrapper
            key={i}
            isselected={`${m.rectangleId === selectedRectangleId}`}
            color={m.hexColor}>
            <StyledFormGroup>
              <TextField
                disabled={true}
                name="sequence"
                type="number"
                value={m.sequence ?? 0}
                label="Sequence"
                style={{ width: '50px', margin: '0 0.5rem 0 0.5rem' }}
                onChange={e => onEditMetaData(i, 'sequence', e.target.value)}
                inputProps={{ min: 0 }}
              />
              <StyledTextField
                multiline
                value={m.text}
                disabled={true}
                onChange={e => onEditMetaData(i, 'text', e.target.value)}
              />
              <StyledSelectFormControl>
                <InputLabel>Text Type</InputLabel>
                <Select
                  disabled={true}
                  value={m.textType ?? ''}
                  onChange={e => onEditMetaData(i, 'textType', e.target.value)}
                  variant="filled"
                  style={{ display: 'flex', flexdirection: 'column' }}>
                  {textTypes.map((t, i) => (
                    <MenuItem key={i} value={t.value}>
                      {t.label}
                    </MenuItem>
                  ))}
                </Select>
              </StyledSelectFormControl>
              <StyledSelectFormControl style={{ minWidth: '180px' }}>
                <InputLabel>Metadata tag</InputLabel>
                <Select
                  disabled={true}
                  value={m.metaDataTagId ?? ''}
                  onChange={e => {
                    onEditMetaData(i, 'metaDataTagId', e.target.value);
                    onEditMetaData(
                      i,
                      'hexColor',
                      metaDataTags?.find(
                        t => t.metaDataTagId === e.target.value
                      )?.hexColor || '#DC143C'
                    );
                  }}
                  variant="filled">
                  {metaDataTags?.map((t, i) => (
                    <StyledMenuItem key={i} value={t.metaDataTagId}>
                      <ColorBlock blockcolor={t.hexColor} />
                      {t.label}
                    </StyledMenuItem>
                  ))}
                </Select>
              </StyledSelectFormControl>
            </StyledFormGroup>
            <StyledMetaData>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  justifyContent: 'space-between'
                }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column'
                  }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      background: '#eee',
                      padding: '0.5rem',
                      border: '1px solid #ddd'
                    }}>
                    {m.text && (
                      <div>
                        {m.textType === 'LaTeX' ? (
                          <Latex>{m.text}</Latex>
                        ) : (
                          <span>{m.text}</span>
                        )}
                      </div>
                    )}
                  </div>
                  {m.blob && (
                    <div>
                      <MetaDataImg
                        src={URL.createObjectURL(m.blob)}
                        alt={m.text}
                      />
                    </div>
                  )}
                </div>
                <div>
                  <Button
                    variant="contained"
                    disabled={!m.metaDataId}
                    color="secondary"
                    style={{
                      background:
                        m.reviewStatus === 'Approved'
                          ? 'green'
                          : m.reviewStatus === 'Failed'
                          ? 'red'
                          : !!m.comment?.length
                          ? 'orange'
                          : '#4353ff'
                    }}
                    size="medium"
                    startIcon={<RateReviewIcon />}
                    onClick={() => setMetaDataReviewModalId(m.metaDataId)}>
                    Review
                  </Button>
                </div>
              </div>
            </StyledMetaData>
            <MetaDataReviewModal
              metaData={m}
              open={metaDataReviewModalId === m.metaDataId}
              onClose={() => setMetaDataReviewModalId(null)}
              metaDataTag={metaDataTags?.find(
                mt => mt.metaDataTagId === m.metaDataTagId
              )}
              handleSave={updateMetaData}
            />
          </MetaDataWrapper>
        ))}
      </Left>
      <Right>
        <ImageMetaDataCanvas
          rectangles={metaData}
          drawing={drawing}
          startPos={startPos}
          endPos={endPos}
          selectedId={selectedRectangleId}
          handleMouseDown={handleMouseDown}
          handleMouseMove={handleMouseMove}
          handleMouseUp={handleMouseUp}
          handleRectClick={handleRectClick}
          handleRectDragStart={handleRectDragStart}
          handleRectDragEnd={handleRectDragEnd}
          editState={editState}
          imagewidth={defaultImageWidth}
          imageURL={editState.imageURL}
          bitmap={editState.bitmap}
        />
      </Right>
    </Root>
  );
};

const MetaDataImg = styled.img`
  max-width: 320px;
  height: auto;
`;

const Root = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

const Left = styled.div`
  flex: 1;
  width: 50%;
  overflow-y: scroll;
  height: calc(100vh - 155px);
`;

const Right = styled.div`
  flex: 1;
  width: 50%;
  padding: 1rem;
`;

const StyledSelectFormControl = styled(FormControl)`
  margin: 0 0.5rem 0 0.5rem;
  min-width: 150px;
  .MuiSelect-root {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: flex-start;
  }
`;

const StyledFormGroup = styled(FormGroup)`
  display: flex;
  flex-direction: row;
  padding: 0.5rem 0 0.5rem 0;
  align-content: center;
  align-items: flex-end;
`;

const MetaDataWrapper = styled.div`
  ${props =>
    props.isselected === 'true' && !!props.color
      ? `
      background: ${props.color}3a;
  `
      : null};
  border-bottom: 1px solid #ccc;
`;

const StyledMetaData = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0.5rem;
  strong {
    margin: 0 0.5rem 0 0;
  }
`;

const StyledTextField = styled(TextField)`
  flex-grow: 1;
`;

const ColorBlock = styled.div`
  width: 10px;
  height: 20px;
  background: ${props => props.blockcolor ?? '#DC143C'};
  margin: 0 0.5rem 0 0;
`;

const StyledMenuItem = styled(MenuItem)`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
`;

export default ImageMetaDataShow;
