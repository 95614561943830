import {
  Modal,
  Backdrop,
  Container,
  Card,
  CardContent,
  Typography
} from '@material-ui/core';

const TutorPreviewModal = ({
  record,
  classes,
  isModalOpen,
  setisModalOpen
}) => {
  return (
    <Modal
      open={isModalOpen}
      onClose={setisModalOpen}
      className={classes.modal}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
      closeAfterTransition>
      <Container maxWidth="md">
        <Card className={classes.create}>
          <CardContent style={{ textAlign: 'center' }}>
            <img
              src={record.imageURL}
              alt=""
              style={{ width: 'auto', maxHeight: '500px' }}
            />
            <Typography variant="h6" gutterBottom>
              {record.firstName} {record.lastName}
            </Typography>
          </CardContent>
        </Card>
      </Container>
    </Modal>
  );
};

export default TutorPreviewModal;
