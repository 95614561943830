export const colorPalette = [
  '#000000', // Black
  '#FFFF00', // Yellow
  '#800080', // Purple
  '#008000', // Green
  '#808080', // Gray
  '#FF0000', // Red
  '#0000FF', // Blue
  '#FFA500' // Orange
];

export const thicknessOpts = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28, 29, 30
];
