import { Typography, CardContent, Paper, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from 'Components/Avatar';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row'
  },
  bubble: {
    flex: 1
  },
  paper: {
    borderRadius: '1.5rem',
    backgroundColor: '#ADEED3',
    color: '#111',
    textAlign: 'left'
  },
  avatar: {
    margin: '12px',
    width: '24px',
    height: '24px',
    flex: '0 0 24px',
    opacity: '0'
  },
  header: {
    fontSize: '12px',
    padding: '16px 16px 0 16px'
  },
  time: {
    margin: '5px 0 0 0',
    textAlign: 'right',
    fontSize: '11px',
    opacity: '0.75'
  },
  content: {
    padding: '16px !important'
  },
  text: {
    fontWeight: 'bold',
    fontSize: '12px'
  }
}));

const InformationFlowItem = ({ props }) => {
  const { text, dateDispatched } = props;
  const classes = useStyles();

  if (!text) return null;

  return (
    <Box className={classes.root}>
      <Avatar name="information" size={24} className={classes.avatar} />
      <Box>
        <Paper elevation={1} className={classes.paper}>
          <CardContent className={classes.content}>
            <Typography
              variant="body2"
              component="span"
              className={classes.text}>
              {text?.split('\n')?.map(t => (
                <>
                  {t}
                  <br />
                </>
              ))}
            </Typography>
          </CardContent>
        </Paper>
        {dateDispatched && (
          <Typography variant="body2" className={classes.time}>
            {dateDispatched}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default InformationFlowItem;
