import { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button, FormGroup, Grid } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import { Field, Form } from 'react-final-form';
import useUploadTemplate from './Components/Hooks/useUploadTemplate';
import Api from 'Api/Api';
import { useNotify } from 'react-admin';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import FlowImportResponse from './Components/FlowImportResponse';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    padding: 0
  },
  validation: {
    overflowY: 'scroll',
    height: 'calc(100vh - 48px)',
    overflowX: 'hidden'
  },
  listGridItem: {
    borderRight: '1px solid #ddd',
    borderLeft: '1px solid #ddd',
    padding: '0.5rem'
  },
  input: {
    width: '100%',
    margin: '0.5rem 0 1rem'
  },
  select: {
    width: '100%',
    margin: '1rem 0 1rem'
  },
  formControl: {
    width: '100%'
  },
  button: {
    float: 'right',
    margin: '0.5rem 0 0 1rem'
  },
  uploadButton: {
    width: '40px',
    height: '40px'
  },
  fileDetails: {
    margin: '0',
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'flex-end',
    borderBottom: '1px solid #ddd'
  },
  uploadFormGroup: {
    flexDirection: 'row',
    display: 'flex',
    borderBottom: '1px solid #ddd',
    alignItems: 'center'
  }
}));

const NewFlowPage = () => {
  const history = useHistory();
  const classes = useStyles();
  const notify = useNotify();
  const [isValidateOnly, setIsValidateOnly] = useState(true);
  const [parentFlowTemplateResponse, setParentFlowTemplateResponse] =
    useState(null);
  const [childFlowTemplateResponse, setChildFlowTemplateResponse] =
    useState(null);
  const [flowItemTemplateResponse, setFlowItemTemplateResponse] =
    useState(null);

  const {
    UploadForm: ParentFlowTemplateUploadForm,
    getSetFormData: getSetParentFlowTemplateFormData,
    file: parentFlowTemplateFile,
    clearFile: parentFlowTemplateClearFile
  } = useUploadTemplate();

  const {
    UploadForm: ChildFlowTemplateUploadForm,
    getSetFormData: getSetChildFlowTemplateFormData,
    file: childFlowTemplateFile,
    clearFile: childFlowTemplateClearFile
  } = useUploadTemplate();

  const {
    UploadForm: FlowItemTemplateUploadForm,
    getSetFormData: getSetFlowItemTemplateFormData,
    file: flowItemTemplateFile,
    clearFile: flowItemTemplateClearFile
  } = useUploadTemplate();

  const uploadParentFlowTemplate = useCallback(
    async ({ templateSlug }) => {
      try {
        if (!templateSlug) throw new Error('Template Slug Required');

        if (parentFlowTemplateFile) {
          const parentFlowTemplateFormData = getSetParentFlowTemplateFormData(
            null,
            null,
            {
              HasHeaderRecord: true,
              IsValidateOnly: isValidateOnly
            }
          );
          const response = await Api.uploadFlowTemplate(
            templateSlug,
            parentFlowTemplateFormData
          );

          if (!isValidateOnly) parentFlowTemplateClearFile();

          setParentFlowTemplateResponse(response);

          return response.isSuccess;
        }
      } catch (e) {
        setParentFlowTemplateResponse(e);
        console.error(e);
      }

      return false;
    },
    [
      getSetParentFlowTemplateFormData,
      isValidateOnly,
      parentFlowTemplateClearFile,
      parentFlowTemplateFile
    ]
  );

  const uploadChildFlowTemplate = useCallback(
    async ({ templateSlug }) => {
      try {
        if (!templateSlug) throw new Error('Template Slug Required');

        if (childFlowTemplateFile) {
          const childFlowTemplateFormData = getSetChildFlowTemplateFormData(
            null,
            null,
            {
              HasHeaderRecord: true,
              IsValidateOnly: isValidateOnly
            }
          );
          const response = await Api.uploadFlowTemplate(
            templateSlug,
            childFlowTemplateFormData
          );

          if (!isValidateOnly) childFlowTemplateClearFile();

          setChildFlowTemplateResponse(response);

          return response.isSuccess;
        }
      } catch (e) {
        setChildFlowTemplateResponse(e);
        console.error(e);
      }

      return false;
    },
    [
      childFlowTemplateClearFile,
      childFlowTemplateFile,
      getSetChildFlowTemplateFormData,
      isValidateOnly
    ]
  );

  const uploadFlowItemTemplate = useCallback(
    async ({ templateSlug }) => {
      try {
        if (!templateSlug) throw new Error('Template Slug Required');

        if (flowItemTemplateFile) {
          const flowItemTemplateFormData = getSetFlowItemTemplateFormData(
            null,
            null,
            {
              HasHeaderRecord: true,
              IsValidateOnly: isValidateOnly
            }
          );
          const response = await Api.uploadFlowItemTemplate(
            templateSlug,
            flowItemTemplateFormData
          );

          if (!isValidateOnly) flowItemTemplateClearFile();

          setFlowItemTemplateResponse(response);

          return response.isSuccess;
        }
      } catch (e) {
        setFlowItemTemplateResponse(e);
        console.error(e);
      }

      return false;
    },
    [
      flowItemTemplateClearFile,
      flowItemTemplateFile,
      getSetFlowItemTemplateFormData,
      isValidateOnly
    ]
  );

  const onSubmit = useCallback(
    async values => {
      try {
        if (
          !parentFlowTemplateFile &&
          !flowItemTemplateFile &&
          !childFlowTemplateFile
        )
          throw new Error('At least one template file is required');

        const isParentTemplateSuccess = await uploadParentFlowTemplate({
          ...values
        });
        const isChildTemplateSuccess = await uploadChildFlowTemplate({
          ...values
        });
        const isFlowItemTemplateSuccess = await uploadFlowItemTemplate({
          ...values
        });

        if (parentFlowTemplateFile && !isParentTemplateSuccess) return;
        if (childFlowTemplateFile && !isChildTemplateSuccess) return;
        if (flowItemTemplateFile && !isFlowItemTemplateSuccess) return;

        if (isValidateOnly) notify('Template(s) passed validation', 'success');
        if (!isValidateOnly) history.push(`/flows/${values.templateSlug}`);

        setIsValidateOnly(false);
      } catch (e) {
        notify(e.message, 'warning');
        console.error(e);
      }
    },
    [
      childFlowTemplateFile,
      flowItemTemplateFile,
      history,
      isValidateOnly,
      notify,
      parentFlowTemplateFile,
      uploadChildFlowTemplate,
      uploadFlowItemTemplate,
      uploadParentFlowTemplate
    ]
  );

  useEffect(() => {
    if (!flowItemTemplateFile && !!setFlowItemTemplateResponse) {
      setFlowItemTemplateResponse(null);
    }
    if (!parentFlowTemplateFile && !!setParentFlowTemplateResponse) {
      setParentFlowTemplateResponse(null);
    }
    if (!childFlowTemplateFile && !!setChildFlowTemplateResponse) {
      setChildFlowTemplateResponse(null);
    }

    if (
      !flowItemTemplateFile &&
      !parentFlowTemplateFile &&
      !childFlowTemplateFile
    ) {
      setIsValidateOnly(true);
    }
  }, [
    childFlowTemplateFile,
    childFlowTemplateResponse,
    flowItemTemplateFile,
    flowItemTemplateResponse,
    isValidateOnly,
    parentFlowTemplateFile,
    parentFlowTemplateResponse
  ]);

  return (
    <Grid container className={classes.root}>
      <Grid item xs={2} className={classes.listGridItem}>
        <h3 style={{ display: 'flex' }}>
          Upload Template <CloudUploadIcon style={{ margin: '0 0 0 0.5rem' }} />
        </h3>
        <Form
          onSubmit={onSubmit}
          initialValues={{ templateSlug: '' }}
          validate={values => {
            const errors = {};
            if (!values.templateSlug) {
              errors.templateSlug = 'Template Slug Required';
            }
            return errors;
          }}>
          {({ handleSubmit, submitting, pristine, submitError }) => (
            <form className={classes.container} onSubmit={handleSubmit}>
              <FormGroup>
                <Field name="templateSlug">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label="Template Slug"
                      className={classes.input}
                      InputLabelProps={{
                        shrink: true
                      }}
                      error={meta.error && meta.touched}
                      helperText={
                        meta.error && meta.touched ? meta.error : null
                      }
                    />
                  )}
                </Field>
              </FormGroup>
              <Field name="parentFlowTemplateFile">
                {({ meta }) => (
                  <ParentFlowTemplateUploadForm
                    meta={meta}
                    submitting={submitting}
                    label="Parent Flow Template"
                  />
                )}
              </Field>
              <Field name="childFlowTemplateFile">
                {({ meta }) => (
                  <ChildFlowTemplateUploadForm
                    meta={meta}
                    submitting={submitting}
                    label="Child Flow Template (optional)"
                  />
                )}
              </Field>
              <Field name="flowItemTemplateFile">
                {({ meta }) => (
                  <FlowItemTemplateUploadForm
                    meta={meta}
                    submitting={submitting}
                    label="Flow Item Template"
                  />
                )}
              </Field>
              <div style={{ display: 'flex' }}>
                <Button
                  variant="contained"
                  className={classes.button}
                  startIcon={<ChevronLeftIcon />}
                  disabled={submitting}
                  onClick={() => history.push('/flows')}>
                  Back
                </Button>{' '}
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  type="submit"
                  className={classes.button}
                  startIcon={
                    isValidateOnly ? <VerifiedUserIcon /> : <SaveIcon />
                  }
                  disabled={pristine || submitting || submitError}>
                  {isValidateOnly ? 'Validate' : 'Save'}
                </Button>
              </div>
            </form>
          )}
        </Form>
      </Grid>
      <Grid item xs={10} className={classes.validation}>
        <FlowImportResponse
          response={parentFlowTemplateResponse}
          title={`Parent Flow Template ${
            isValidateOnly ? 'Validation' : 'Import'
          }`}
          flowTemplateType="flowTemplates"
        />
        <FlowImportResponse
          response={childFlowTemplateResponse}
          title={`Child Flow Template ${
            isValidateOnly ? 'Validation' : 'Import'
          }`}
          flowTemplateType="flowTemplates"
        />
        <FlowImportResponse
          response={flowItemTemplateResponse}
          title={`Flow Item Template ${
            isValidateOnly ? 'Validation' : 'Import'
          }`}
        />
      </Grid>
    </Grid>
  );
};

export default NewFlowPage;
