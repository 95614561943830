import { useEffect, useState } from 'react';
import Api from 'Api/Api';
import { isEmpty } from 'lodash';

const useGetAllTutors = () => {
  const [tutors, setTutors] = useState([]);

  useEffect(() => {
    const runEffect = async () => {
      if (!tutors.length) {
        try {
          const response = await Api.getAllTutors();
          if (!isEmpty(response)) {
            setTutors(response);
          }
        } catch (e) {
          console.error(e);
        }
      }
    };
    runEffect();
  }, [tutors]);

  return tutors;
};

export default useGetAllTutors;
