import moment from 'moment';
import 'moment-timezone';
import { makeStyles } from '@material-ui/core/styles';
import { List, Grid } from '@material-ui/core';
import ListItemRow from 'Components/ListItemRow';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    margin: '0 0 12px 0'
  },
  summaryLeft: {},
  summaryRight: {}
}));

const Summary = props => {
  const classes = useStyles();
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start">
      <Grid item xs={12} className={classes.summaryLeft}>
        <List dense={true}>
          <ListItemRow header="Quiz Name" data={props.quizName} />
          <ListItemRow header="Quiz ID" data={props.quizId} />
          <ListItemRow header="Lesson ID" data={props.lessonId} />
          {props.flowGeneratorSessionId && (
            <ListItemRow
              header="Flow Generator Session ID"
              data={props.flowGeneratorSessionId}
            />
          )}
          {props.flowSessionId && (
            <ListItemRow
              header="(Legacy) Flow Session ID"
              data={props.flowSessionId}
            />
          )}
          {props.quizSession && (
            <>
              <ListItemRow
                header="Quiz Session ID"
                data={props.quizSession.quizSessionId}
              />
              <ListItemRow
                header="Topic Pathway Quiz ID"
                data={props.quizSession.topicPathwayQuizId}
              />
            </>
          )}
          <ListItemRow
            header="Started"
            data={moment
              .utc(props.dateCreated)
              .tz('Europe/London')
              .format('MMMM Do YYYY, h:mm:ss a')}
          />
          <ListItemRow
            header="Completed"
            data={
              props.dateCompleted
                ? moment
                    .utc(props.dateCompleted)
                    .tz('Europe/London')
                    .format('MMMM Do YYYY, h:mm:ss a')
                : '-'
            }
          />
          <ListItemRow header="Lesson Type" data={props.lessonType} />
        </List>
      </Grid>
    </Grid>
  );
};

export default Summary;
