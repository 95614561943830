import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(theme => ({
  paper: {
    background: '#444',
    padding: theme.spacing(2),
    color: '#fff',
    display: 'flex',
    textAlign: 'center',
    flexDirection: 'column'
  }
}));

const StatsCard = ({ loading, children, ...props }) => {
  const classes = useStyles();
  return (
    <Paper className={classes.paper} {...props}>
      {loading ? (
        <div style={{ width: '100%' }}>
          <img
            style={{
              textAlign: 'center',
              margin: '0 auto',
              height: '50px',
              width: 'auto',
              display: 'flex'
            }}
            src="/assets/dash-loader.gif"
            alt="loading!"
          />
        </div>
      ) : (
        <>{children}</>
      )}
    </Paper>
  );
};

export default StatsCard;
