import { makeStyles } from '@material-ui/core/styles';
import {
  Modal,
  Backdrop,
  TextField,
  Button,
  Container,
  Card,
  CardContent,
  Typography,
  FormGroup,
  InputLabel,
  MenuItem,
  Select
} from '@material-ui/core';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useCallback, React } from 'react';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import { Form, Field } from 'react-final-form';
import { useNotify } from 'react-admin';
import Api from 'Api/Api';
import useGetAuthUser from 'Hooks/useGetAuthUser';
import languageHelpers from 'Helpers/languageHelpers';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  create: {
    margin: '1rem 0 0 0'
  },
  input: {
    margin: '1rem 0 0 0'
  },
  createButton: {
    margin: '1rem 0 0 0'
  },
  cancelButton: {
    margin: '1rem 0 0 10px'
  },
  select: {
    margin: '1rem 0 0 0',
    width: '100%'
  }
}));

const CreateCollectionModal = ({ isOpen, setIsOpen }) => {
  const user = useGetAuthUser();
  const notify = useNotify();
  const classes = useStyles();

  const createCollection = useCallback(
    async values => {
      try {
        await Api.insertTopicPathwayCollection(user.userId, values);
        setIsOpen();
        notify('Collection Created!', 'success');
        window.location = '/topic-pathway-quizs';
      } catch (e) {
        notify('Error creating topic!', 'error');
        console.error(e);
      }
    },
    [notify, setIsOpen, user?.userId]
  );

  return (
    <Modal
      open={isOpen}
      onClose={() => setIsOpen()}
      className={classes.modal}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
      closeAfterTransition>
      <Container maxWidth="md">
        <Card className={classes.create}>
          <CardContent>
            <Typography variant="h5" gutterBottom>
              Create New Collection
            </Typography>
            <Form
              onSubmit={createCollection}
              initialValues={{
                name: null,
                description: null
              }}
              validate={values => {
                const errors = {};
                if (!values.name) {
                  errors.name = 'Please enter a name for the collection';
                }
                if (!values.description) {
                  errors.description =
                    'Please enter a description for the collection';
                }
                if (!values.locale) {
                  errors.locale = 'Please select a locale for the collection';
                }
                return errors;
              }}>
              {({ handleSubmit, submitting, pristine, submitError }) => (
                <form onSubmit={handleSubmit}>
                  <FormGroup>
                    <Field name="name">
                      {({ input, meta }) => (
                        <TextField
                          {...input}
                          id="standard-basic"
                          label="Collection Name"
                          variant="filled"
                          className={classes.input}
                          error={meta.error && meta.touched}
                          helperText={
                            meta.error && meta.touched ? meta.error : null
                          }
                        />
                      )}
                    </Field>
                  </FormGroup>
                  <FormGroup>
                    <Field name="description">
                      {({ input, meta }) => (
                        <TextField
                          {...input}
                          id="standard-basic"
                          label="Description"
                          variant="filled"
                          className={classes.input}
                          error={meta.error && meta.touched}
                          helperText={
                            meta.error && meta.touched ? meta.error : null
                          }
                        />
                      )}
                    </Field>
                  </FormGroup>
                  <FormGroup style={{ marginTop: '1rem' }}>
                    <Field name="locale">
                      {({ input, meta }) => (
                        <>
                          <InputLabel id="locale-label">Locale</InputLabel>
                          <Select
                            name="locale"
                            labelId="locales-label"
                            error={meta.error && meta.touched}
                            {...input}>
                            {languageHelpers.locales.map((locale, idx) => (
                              <MenuItem key={idx} value={locale.code}>
                                {locale.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </>
                      )}
                    </Field>
                  </FormGroup>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    type="submit"
                    className={classes.createButton}
                    startIcon={<SaveIcon />}
                    disabled={submitting || pristine || submitError}>
                    Create
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    className={classes.cancelButton}
                    onClick={() => setIsOpen()}
                    startIcon={<CancelIcon />}>
                    Cancel
                  </Button>
                </form>
              )}
            </Form>
          </CardContent>
        </Card>
      </Container>
    </Modal>
  );
};

export default CreateCollectionModal;
