import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

const FullPageLoader = ({ loading }) => {
  if (!loading) return null;
  return (
    <Backdrop style={{ zIndex: 999 }} open={loading}>
      <CircularProgress />
    </Backdrop>
  );
};

export default FullPageLoader;
