import ReactLinkify from 'react-linkify';
import styled from 'styled-components';
// import { Latex } from 'Helpers/latexify';

const FormattedTextContent = ({ content = '' }) => {
  let extractedSections = {};

  const extractedContent = content.replace(
    /\\\[?\s*\\begin\{([^}]*)\}[\s\S]*?\\end\{\1\}/g,
    match => {
      try {
        const placeholder = `%%EXTRACTED_SECTION_${
          Object.keys(extractedSections).length
        }%%`;
        extractedSections[placeholder] = match.replace(/\n/g, '');
        return placeholder;
      } catch {}
      return match;
    }
  );

  const formattedParagraphs = extractedContent
    .replaceAll('\\n', '\n')
    .split('\n')
    .filter(x => !!x && x !== '\\[' && x !== '\\]')
    .map(str => (extractedSections[str] ? extractedSections[str] : str));

  return (
    <ReactLinkify
      componentDecorator={(decoratedHref, decoratedText, key) => (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={decoratedHref}
          key={key}>
          {decoratedText}
        </a>
      )}>
      {formattedParagraphs.map((str, i) => (
        <Paragraph key={i}>
          {/* <Latex output="html" displayMode={false}> */}
          {str}
          {/* </Latex> */}
        </Paragraph>
      ))}
    </ReactLinkify>
  );
};

const Paragraph = styled.p`
  word-break: break-word;
  margin: 0;

  & + & {
    margin-top: 1rem;
  }
`;

export default FormattedTextContent;
