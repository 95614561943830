import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-admin';
import CircularProgressBar from 'Components/CircularProgressBar';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CircleIcon from '@material-ui/icons/RadioButtonUnchecked';
import { pink } from '@material-ui/core/colors';
import ProgressBar from 'Components/ProgressBar';

const useStyles = makeStyles(theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  subHeading: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightLight
  },
  summaryHeading: {
    fontSize: theme.typography.pxToRem(15)
  },
  summarySecondaryHeading: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightLight,
    color: theme.palette.text.secondary
  },
  itemDetails: {
    display: 'flex',
    alignItems: 'center',
    lineHeight: '0.8rem',
    marginLeft: '1rem'
  }
}));

const PlanShow = ({ planGroups = [] }) => {
  const classes = useStyles();

  const mappedPlanGroups = planGroups
    .sort((a, b) => a.sequence - b.sequence)
    .map((planGroup, index) => {
      return {
        title: planGroup.title,
        description: planGroup.description,
        allDone: planGroup.items.every(item => item.isCompleted),
        items: planGroup.items
          .sort((a, b) => a.sequence - b.sequence)
          .map((item, index) => ({
            title: item.title,
            description: item.description,
            link: item.link,
            progress: item.progress,
            isCompleted: item.isCompleted,
            prediction: item.prediction
          }))
      };
    });

  return (
    <div>
      {mappedPlanGroups.map((planGroup, index) => (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header">
            {planGroup.allDone ? (
              <CheckCircleIcon color="success" />
            ) : (
              <CircleIcon sx={{ color: pink[500] }} />
            )}
            <Typography className={classes.summaryHeading}>
              {planGroup.title}
            </Typography>

            <Typography className={classes.summarySecondaryHeading}>
              {planGroup.description}
            </Typography>
          </AccordionSummary>
          {planGroup.items.map((item, index) => (
            <AccordionDetails className={classes.itemDetails}>
              <strong>
                <CircularProgressBar percentage={item.progress} size={48} />
              </strong>
              <div style={{ marginLeft: '0.8rem', width: '100%' }}>
                <div>
                  <Typography component="span" variant="subtitle1">
                    {item.title}
                  </Typography>
                </div>
                <div>
                  <Typography component="span" variant="caption" colorSecondary>
                    {item.description}
                  </Typography>
                </div>
                {item.prediction && (
                  <div>
                    Prediction:{' '}
                    <ProgressBar
                      style={{ width: '100%' }}
                      percentage={item.prediction * 100}
                    />
                  </div>
                )}
                <Link
                  to={{ pathname: item.link }}
                  target="_blank"
                  style={{ fontSize: '10px' }}>
                  Go to topic here
                </Link>
              </div>
            </AccordionDetails>
          ))}
        </Accordion>
      ))}
    </div>
  );
};

export default PlanShow;
