import { useEffect, useState, useCallback, useRef } from 'react';

const useTrackPointer = ({
  label = 'user',
  onUpdate = p => console.log(p),
  stageRef = null
}) => {
  const [position, setPosition] = useState({ x: 0, y: 0, label });
  const positionRef = useRef({ position, previousPosition: position });

  const updatePosition = useCallback(
    event => {
      if (stageRef && stageRef.current) {
        const rect = stageRef.current.getBoundingClientRect();
        const offsetX = event.clientX - rect.left;
        const offsetY = event.clientY - rect.top;

        setPosition(prev => ({
          ...prev,
          x: offsetX,
          y: offsetY
        }));
        positionRef.current.position = { x: offsetX, y: offsetY, label };
      }
    },
    [label, stageRef]
  );

  useEffect(() => {
    const handleMouseMove = event => {
      try {
        if (stageRef && stageRef.current) {
          const rect = stageRef.current.getBoundingClientRect();
          const isWithinBounds =
            event.clientX >= rect.left &&
            event.clientX <= rect.right &&
            event.clientY >= rect.top &&
            event.clientY <= rect.bottom;

          if (isWithinBounds) {
            updatePosition(event);
          }
        }
      } catch (e) {
        console.error(e);
      }
    };

    const intervalId = setInterval(() => {
      if (!stageRef.current) return;

      if (
        positionRef.current.previousPosition.x !==
          positionRef.current.position.x ||
        positionRef.current.previousPosition.y !==
          positionRef.current.position.y
      ) {
        onUpdate(position);
      }
      positionRef.current.previousPosition = positionRef.current.position;
    }, 200);

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('touchmove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('touchmove', handleMouseMove);
      clearInterval(intervalId);
    };
  }, [position, onUpdate, updatePosition, stageRef]);

  return position;
};

export default useTrackPointer;
