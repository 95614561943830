import {
  Grid,
  Paper,
  Grow,
  Box,
  Button,
  CardMedia,
  Typography,
  Slide
} from '@material-ui/core';
import { FlowItem } from 'Components/FlowItems/index';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState, createRef } from 'react';
import moment from 'moment';
import 'moment-timezone';
import useGetAuthUser from 'Hooks/useGetAuthUser';
import Api from 'Api/Api';
import useIsTyping from 'Hooks/useIsTyping';
import styled from 'styled-components';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import FeaturedVideoOutlinedIcon from '@material-ui/icons/FeaturedVideoOutlined';
import { useSelector } from 'react-redux';
import ToolTip from 'Components/ToolTip';
import BlockIcon from '@material-ui/icons/Block';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%!important',
    padding: '2rem 1rem',
    margin: '0!important'
  },
  typingBox: {
    backgroundImage: 'url(/assets/chatting.gif)',
    backgroundRepeat: 'no-repeat',
    minHeight: '30px',
    width: '50px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    border: '1px solid #ccc',
    borderRadius: '5px',
    display: 'flex'
  },
  typingBoxWrapper: {
    display: 'flex'
  },
  typingName: {
    display: 'flex',
    alignSelf: 'center',
    fontWeight: 'bold',
    margin: '0 10px 0 0',
    textAlign: 'right'
  },
  flowItem: {
    alignSelf: props => (props.bot ? 'flex-start' : 'flex-end')
  },
  paper: {
    background: 'none',
    border: 'none',
    padding: 0,
    boxShadow: 'none',
    margin: 0
  },
  claimLesson: {
    margin: '0 1rem'
  }
}));

const FlowItemListGrid = ({ data, claimLesson, isClaiming, flowItems }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [lessonId, setLessonId] = useState(null);
  const { lessonListAutoScrollEnabled } = useSelector(s => s.uxState);
  const [tutors, setTutors] = useState([]);
  const [showPinnedContent, setShowPinnedContent] = useState(false);
  const ref = createRef();
  const user = useGetAuthUser();
  const typingStates = useIsTyping(data?.flowGeneratorSessionId);
  const student = data.student ? data.student : data;

  const scrollToBottom = lessonListAutoScrollEnabled => {
    dispatch({
      type: 'uxState/set',
      payload: {
        lessonListAutoScrollEnabled: !lessonListAutoScrollEnabled
      }
    });

    if (!lessonListAutoScrollEnabled) return;

    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };
  const isPlusUser = !!student?.parents?.find(
    p =>
      p.subscriptionStatus === 'active' || p.subscriptionStatus === 'trialing'
  );

  useEffect(() => {
    const runEffect = async () => {
      if (data && lessonId !== data?.lessonId) {
        setLessonId(data?.lessonId);
      }
    };

    runEffect();
    if (lessonListAutoScrollEnabled) {
      setTimeout(
        () => ref.current?.scrollIntoView({ behavior: 'smooth' }),
        700
      );
    }
  }, [
    lessonId,
    data,
    ref,
    tutors.length,
    typingStates,
    lessonListAutoScrollEnabled
  ]);

  useEffect(() => {
    const runEffect = async () => {
      if (!tutors.length) {
        try {
          setTutors(await Api.getAllTutors());
        } catch (e) {
          console.error(e);
        }
      }
    };
    runEffect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLatestPinnedFlowItem = () => {
    if (!flowItems?.length) return null;
    const pinnableFlowItemTypes = ['PinnedImage', 'PinnedText'];
    const flowGeneratorSession = data?.flowGeneratorSession;

    const sortedFlowItems = [...flowItems].sort(
      (a, b) => new Date(b.dateDispatched) - new Date(a.dateDispatched)
    );

    const pinnedBotFlowItem = sortedFlowItems?.find(
      fi =>
        pinnableFlowItemTypes.includes(fi.flowItemType) &&
        fi.position >= flowGeneratorSession.position
    );

    const pinnedTutorFlowItem = sortedFlowItems?.find(
      fi =>
        pinnableFlowItemTypes.includes(fi.flowItemType) &&
        fi.position >= flowGeneratorSession.position &&
        fi.userId === flowGeneratorSession.tutorUserId &&
        new Date(pinnedBotFlowItem?.dateDispatched) <
          new Date(fi.dateDispatched)
    );

    return pinnedTutorFlowItem || pinnedBotFlowItem;
  };

  const mapFlowItems = () => {
    if (!flowItems?.length) return [];
    const pinnedFlowItem = getLatestPinnedFlowItem();
    const flowItemsCopy = [];
    flowItems
      .sort((a, b) => new Date(a.dateDispatched) - new Date(b.dateDispatched))
      .forEach((fi, i) => {
        fi.isCurrentlyPinned =
          pinnedFlowItem?.flowItemGroup &&
          pinnedFlowItem?.flowItemGroup === fi.flowItemGroup;
        fi.student = data.student;
        fi.isShowName = true;
        if (fi.userId === data.student.userId) {
          fi.studentName = data.student.firstName
            ? data.student.firstName
            : 'Student';
          fi.isStudent = true;
        }
        if (fi.userId !== data.flowGeneratorSession.userId) {
          const tutor = tutors.find(t => t.userId === fi.userId);
          fi.isTutor = !!tutor;
          fi.tutorName = tutor?.firstName
            ? `Tutor - ${tutor.firstName}`
            : `Eedi`;
        }
        if (i > 0) {
          const previousFlowItem = flowItemsCopy[i - 1];
          fi.isShowName = previousFlowItem.userId !== fi.userId;
        }
        flowItemsCopy.push(fi);
      });
    return flowItemsCopy;
  };

  const mappedFlowItems = mapFlowItems();
  const pinnedContent = mappedFlowItems
    .filter(
      f =>
        !!f.isCurrentlyPinned &&
        ['PinnedImage', 'PinnedVideo', 'PinnedText'].includes(f.flowItemType)
    )
    .sort((a, b) => b.dateDispatched - a.dateDispatched)
    .pop();

  return (
    <>
      <Grid
        container
        item
        direction="column"
        spacing={2}
        className={classes.root}>
        <StickyHeader>
          <PinnedContentButton
            variant="contained"
            size="large"
            className={classes.button}
            startIcon={<FeaturedVideoOutlinedIcon />}
            disabled={!pinnedContent}
            onClick={() => setShowPinnedContent(!showPinnedContent)}
          />
          <PinnedBox></PinnedBox>
          <div style={{ flex: 'auto' }} />
          <ToolTip
            text={!isPlusUser ? 'Cannot claim non plus user sessions' : null}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              className={classes.claimLesson}
              onClick={() => claimLesson()}
              disabled={isClaiming || !isPlusUser}>
              {user?.id === data.tutorUserId
                ? 'unclaim this lesson'
                : 'claim this lesson'}
            </Button>
          </ToolTip>
          <ScrollToBottomButton
            variant="contained"
            size="large"
            className={classes.button}
            startIcon={
              lessonListAutoScrollEnabled ? (
                <KeyboardArrowDownIcon />
              ) : (
                <BlockIcon />
              )
            }
            onClick={() => scrollToBottom(lessonListAutoScrollEnabled)}
          />
        </StickyHeader>
        <Slide
          direction="down"
          in={showPinnedContent && !!pinnedContent}
          mountOnEnter
          unmountOnExit
          timeout={200}>
          <PinnedWrapper>
            <PinnedFlowItem>
              {pinnedContent?.flowItemType !== 'PinnedText' && (
                <CardMedia
                  component={
                    pinnedContent?.flowItemType === 'PinnedVideo'
                      ? 'video'
                      : 'img'
                  }
                  height="auto"
                  className={classes.media}
                  image={
                    pinnedContent?.flowItemType === 'PinnedVideo'
                      ? pinnedContent?.videoUrl
                      : pinnedContent?.imageUrl
                  }
                  controls
                />
              )}
              {pinnedContent?.flowItemType === 'PinnedText' && (
                <Typography variant="subtitle" component="span">
                  {pinnedContent?.text?.split('\n')?.map(t => (
                    <>
                      {t}
                      <br />
                    </>
                  ))}
                </Typography>
              )}
            </PinnedFlowItem>
          </PinnedWrapper>
        </Slide>
        <Grid
          container
          item
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={2}>
          {mappedFlowItems.map((fi, i) => {
            return (
              <Grid
                item
                xs={8}
                key={i}
                style={{
                  alignSelf:
                    fi.userId > 0 && fi.userId !== user?.id
                      ? 'flex-end'
                      : 'flex-start'
                }}>
                <Grow in={true}>
                  <Paper elevation={1} className={classes.paper}>
                    <FlowItem
                      props={{
                        ...fi,
                        dateDispatched: moment
                          .utc(fi.dateDispatched)
                          .tz('Europe/London')
                          .format('MMMM Do YYYY, h:mm:ss a')
                      }}
                      key={i}
                    />
                  </Paper>
                </Grow>
              </Grid>
            );
          })}
          {typingStates
            ?.filter(ts => ts?.isCurrentUser === false)
            .map((ts, i) => (
              <Grid item xs={8} key={1} style={{ alignSelf: 'flex-end' }}>
                <Grow in={true}>
                  <Paper elevation={1} className={classes.paper}>
                    <Box className={classes.typingBoxWrapper}>
                      <Box className={classes.typingName}>
                        {ts.firstName} is typing
                      </Box>
                      <Box className={classes.typingBox} />
                    </Box>
                  </Paper>
                </Grow>
              </Grid>
            ))}
          <div ref={ref} />
        </Grid>
      </Grid>
    </>
  );
};

const StickyHeader = styled.div`
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 32px;
  display: flex;
  justify-content: flex-end;
`;

const PinnedContentButton = styled(Button)`
  padding: 10px !important;
  width: 48 !important;
  height: 32px;
  min-width: 32px !important;
  min-width: 32px;
  span {
    display: contents;
  }
`;

const ScrollToBottomButton = styled(Button)`
  padding: 10px !important;
  border-radius: 50% !important;
  width: 32px !important;
  height: 32px;
  min-width: 32px !important;
  min-width: 32px;
  span {
    display: contents;
  }
`;

const PinnedBox = styled.div`
  display: flex;
  align-self: flex-start;
`;

const PinnedWrapper = styled.div`
  z-index: 999;
  position: fixed;
  top: 120px;
  max-width: 450px;
  min-width: 450px;
`;

const PinnedFlowItem = styled.div`
  background: #eee;
  border-radius: 1rem;
  overflow: hidden;
  padding: 1rem;
  -webkit-box-shadow: 7px 7px 6px -1px rgba(0, 0, 0, 0.4);
  box-shadow: 7px 7px 6px -1px rgba(0, 0, 0, 0.4);
`;

export default FlowItemListGrid;
