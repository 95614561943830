import { makeStyles } from '@material-ui/core/styles';
import {
  TextField,
  Button,
  Select,
  InputLabel,
  MenuItem,
  FormGroup
} from '@material-ui/core';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { React } from 'react';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import { Form, Field } from 'react-final-form';
import ToggleSwitch from 'Components/ToggleSwitch';
import languageHelpers from 'Helpers/languageHelpers';
import CachedIcon from '@material-ui/icons/Cached';

const useStyles = makeStyles(theme => ({
  input: {
    margin: '1rem 0 0 0'
  },
  createButton: {
    margin: '1rem 0 0 0'
  },
  cancelButton: {
    margin: '1rem 0 0 10px'
  },
  select: {
    margin: '1rem 0 0 0',
    width: '100%'
  }
}));

const EditFlowQuestionForm = ({
  onSubmit,
  onCancel,
  flowQuestion = {},
  resetCache,
  cacheState
}) => {
  const classes = useStyles();

  const isCreate = !flowQuestion?.primaryQuestionId;

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={flowQuestion}
      validate={values => {
        const errors = {};
        if (isNaN(parseInt(values.questionId, 10))) {
          errors.questionId = 'Please enter a valid questionId';
        }
        if (
          parseInt(values.questionId, 10) !==
            parseInt(values.primaryQuestionId, 10) &&
          !values.isCheckoutQuestion
        ) {
          errors.isCheckoutQuestion =
            'If this is a check-in question the primary questionId must match the questionId';
        }
        if (
          parseInt(values.questionId, 10) ===
            parseInt(values.primaryQuestionId, 10) &&
          values.isCheckoutQuestion
        ) {
          errors.isCheckoutQuestion =
            'If this is a check-out question the primary questionId must not match the questionId';
        }
        if (isNaN(parseInt(values.constructId, 10))) {
          errors.constructId = 'Please enter a valid constructId';
        }
        if (isNaN(parseInt(values.primaryQuestionId, 10))) {
          errors.primaryQuestionId =
            'Please enter a valid primary (check in) questionId';
        }
        if (!values.locale) {
          errors.locale = 'Please select a language';
        }
        if (!isCreate && !values.correctAnswer) {
          errors.correctAnswer = 'Please enter a correct answer';
        }
        if (!values.explanationA) {
          errors.explanationA = 'Please enter an explanation A';
        }
        if (!values.explanationB) {
          errors.explanationB = 'Please enter an explanation B';
        }
        if (!values.explanationC) {
          errors.explanationC = 'Please enter an explanation C';
        }
        if (!values.explanationD) {
          errors.explanationD = 'Please enter an explanation D';
        }
        return errors;
      }}>
      {({ handleSubmit, submitting, pristine, submitError }) => (
        <form onSubmit={handleSubmit}>
          <FormGroup>
            <Field name="primaryQuestionId">
              {({ input, meta }) => (
                <TextField
                  {...input}
                  id="standard-basic"
                  label="Primary QuestionId"
                  variant="filled"
                  disabled={!isCreate}
                  className={classes.input}
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : null}
                />
              )}
            </Field>
          </FormGroup>
          <FormGroup>
            <Field
              name="isCheckoutQuestion"
              label="IsCheckoutQuestion"
              component={props => <ToggleSwitch invert={false} {...props} />}
            />
          </FormGroup>
          <FormGroup>
            <Field name="questionId">
              {({ input, meta }) => (
                <TextField
                  {...input}
                  id="standard-basic"
                  label="QuestionId"
                  variant="filled"
                  className={classes.input}
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : null}
                />
              )}
            </Field>
          </FormGroup>
          <FormGroup>
            <Field name="constructId">
              {({ input, meta }) => (
                <TextField
                  {...input}
                  id="standard-basic"
                  label="ConstructId"
                  variant="filled"
                  className={classes.input}
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : null}
                />
              )}
            </Field>
          </FormGroup>
          {!isCreate && (
            <FormGroup>
              <Field name="sourceQuestionImageURL">
                {({ input, meta }) => (
                  <TextField
                    {...input}
                    id="standard-basic"
                    label="ImageURL from source Question"
                    variant="filled"
                    className={classes.input}
                    disabled={true}
                    error={meta.error && meta.touched}
                    helperText={meta.error && meta.touched ? meta.error : null}
                  />
                )}
              </Field>
            </FormGroup>
          )}
          <FormGroup>
            <Field name="imageURL">
              {({ input, meta }) => (
                <TextField
                  {...input}
                  id="standard-basic"
                  label="ImageURL override (Optional) will default to source Question ImageURL if left empty"
                  variant="filled"
                  className={classes.input}
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : null}
                />
              )}
            </Field>
          </FormGroup>
          <FormGroup>
            <Field name="locale">
              {({ input, meta }) => (
                <>
                  <InputLabel
                    required
                    id="locale-label"
                    className={classes.select}>
                    Language
                  </InputLabel>
                  <Select
                    {...input}
                    name="locale"
                    labelId="locale-label"
                    error={meta.error && meta.touched}>
                    {languageHelpers.locales.map((c, idx) => (
                      <MenuItem key={idx} value={c.code}>
                        {c.name}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              )}
            </Field>
          </FormGroup>
          {!isCreate && (
            <FormGroup>
              <Field name="correctAnswer">
                {({ input, meta }) => (
                  <>
                    <InputLabel
                      required
                      id="subject-label"
                      className={classes.select}>
                      Correct Answer
                    </InputLabel>
                    <Select
                      {...input}
                      name="CorrectAnswer"
                      labelId="subject-label"
                      error={meta.error && meta.touched}>
                      {[
                        { key: 'A', value: 1 },
                        { key: 'B', value: 2 },
                        { key: 'C', value: 3 },
                        { key: 'D', value: 4 }
                      ].map((c, idx) => (
                        <MenuItem key={idx} value={c.value}>
                          {c.key}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
              </Field>
            </FormGroup>
          )}

          <FormGroup>
            <Field name="explanationA">
              {({ input, meta }) => (
                <TextField
                  {...input}
                  id="standard-basic"
                  label="Explanation A"
                  variant="filled"
                  multiline
                  rows={2}
                  className={classes.input}
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : null}
                />
              )}
            </Field>
          </FormGroup>
          <FormGroup>
            <Field name="explanationB">
              {({ input, meta }) => (
                <TextField
                  {...input}
                  id="standard-basic"
                  label="Explanation B"
                  variant="filled"
                  multiline
                  rows={2}
                  className={classes.input}
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : null}
                />
              )}
            </Field>
          </FormGroup>
          <FormGroup>
            <Field name="explanationC">
              {({ input, meta }) => (
                <TextField
                  {...input}
                  id="standard-basic"
                  label="Explanation C"
                  variant="filled"
                  multiline
                  rows={2}
                  className={classes.input}
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : null}
                />
              )}
            </Field>
          </FormGroup>
          <FormGroup>
            <Field name="explanationD">
              {({ input, meta }) => (
                <TextField
                  {...input}
                  id="standard-basic"
                  label="Explanation D"
                  variant="filled"
                  multiline
                  rows={2}
                  className={classes.input}
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : null}
                />
              )}
            </Field>
          </FormGroup>
          <Button
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            className={classes.createButton}
            startIcon={<SaveIcon />}
            disabled={submitting || pristine || submitError}>
            Update
          </Button>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            onClick={() => resetCache({ showNotify: true })}
            className={classes.cancelButton}
            startIcon={<CachedIcon />}
            disabled={
              submitting || !pristine || submitError || cacheState?.isLoading
            }>
            Reset Cache
          </Button>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            className={classes.cancelButton}
            onClick={() => onCancel()}
            startIcon={<CancelIcon />}
            disabled={submitting}>
            Cancel
          </Button>
        </form>
      )}
    </Form>
  );
};

export default EditFlowQuestionForm;
