/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useEffect, useRef } from 'react';
import { MediaRecorder, register } from 'extendable-media-recorder';
import { connect } from 'extendable-media-recorder-wav-encoder';

const AudioRecorder = ({ onStop = () => {} }) => {
  const [audioURL, setAudioURL] = useState('');
  const [isRecording, setIsRecording] = useState(false);
  const recorderRef = useRef(null);
  const audioRef = useRef(null);
  const canvasRef = useRef(null);
  const audioCtxRef = useRef(null);
  const analyserRef = useRef(null);

  useEffect(() => {
    const runEffect = async () => {
      await register(await connect());
    };
    runEffect();
  }, []);

  useEffect(() => {
    // Check for support
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      console.error(
        'MediaDevices.getUserMedia() not supported on your browser!'
      );
      return;
    }

    navigator.mediaDevices
      .getUserMedia({
        audio: { channelCount: 1, sampleRate: 16000, sampleSize: 16, volume: 1 }
      })
      .then(stream => {
        const ctx = new AudioContext({ sampleRate: 16000 });

        console.log(
          'Sample rate :',
          stream.getAudioTracks(),
          stream.getAudioTracks()[0].getSettings().sampleRate
        );

        recorderRef.current = new MediaRecorder(stream, {
          mimeType: 'audio/wav'
        });
        recorderRef.current.audioChunks = [];

        recorderRef.current.ondataavailable = e => {
          console.log('data available!', e.data.size);
          recorderRef.current.audioChunks.push(e.data);
        };

        recorderRef.current.onstop = () => {
          const audioBlob = new Blob(recorderRef.current.audioChunks, {
            type: 'audio/wav'
          });
          const audioUrl = URL.createObjectURL(audioBlob);
          setAudioURL(audioUrl);
          recorderRef.current.audioChunks = [];
          console.log('recorder stopped', audioUrl, audioBlob);
          onStop(audioBlob);
        };

        audioCtxRef.current = ctx;
        const source = ctx.createMediaStreamSource(stream);
        const analyser = ctx.createAnalyser();
        analyser.fftSize = 2048;
        source.connect(analyser);
        analyserRef.current = analyser;
        visualize();
      })
      .catch(err => console.error('The following error occurred: ' + err));

    // Resize canvas on window resize
    const handleResize = () => {
      if (canvasRef.current) {
        canvasRef.current.width = window.innerWidth;
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [onStop]);

  const visualize = () => {
    if (!canvasRef.current || !analyserRef.current) return;
    const canvas = canvasRef.current;
    const analyser = analyserRef.current;
    const canvasCtx = canvas.getContext('2d');
    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    const draw = () => {
      requestAnimationFrame(draw);
      analyser.getByteTimeDomainData(dataArray);
      canvasCtx.fillStyle = 'rgb(200, 200, 200)';
      canvasCtx.fillRect(0, 0, canvas.width, canvas.height);
      canvasCtx.lineWidth = 2;
      canvasCtx.strokeStyle = 'rgb(0, 0, 0)';
      canvasCtx.beginPath();
      let sliceWidth = (canvas.width * 1.0) / bufferLength;
      let x = 0;

      for (let i = 0; i < bufferLength; i++) {
        let v = dataArray[i] / 128.0;
        let y = (v * canvas.height) / 2;
        if (i === 0) {
          canvasCtx.moveTo(x, y);
        } else {
          canvasCtx.lineTo(x, y);
        }
        x += sliceWidth;
      }
      canvasCtx.lineTo(canvas.width, canvas.height / 2);
      canvasCtx.stroke();
    };
    draw();
  };

  const startRecording = () => {
    if (!recorderRef.current) return;
    recorderRef.current.start();
    setIsRecording(true);
  };

  const stopRecording = () => {
    if (!recorderRef.current) return;
    recorderRef.current.stop();
    setIsRecording(false);
  };

  return (
    <div>
      <div style={{ width: '100%', overflow: 'hidden' }}>
        <canvas
          ref={canvasRef}
          className="visualizer"
          width="100%"
          height="80"
        />
      </div>

      <button onClick={startRecording} disabled={isRecording}>
        Start Recording
      </button>
      <button onClick={stopRecording} disabled={!isRecording}>
        Stop Recording
      </button>
      {audioURL && <audio ref={audioRef} controls src={audioURL}></audio>}
    </div>
  );
};

export default AudioRecorder;
