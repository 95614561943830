const Pentagon = props => {
  const { width, height, fill } = props;
  return (
    <svg
      height={height}
      width={width}
      preserveAspectRatio="none"
      {...props}
      viewBox={`0 0 ${width} ${height}`}>
      <g transform={`translate(0,0) scale(0.${width})`}>
        <polygon points="50 0, 100 38, 82 100, 20 100, 0 38" fill={fill} />
      </g>
    </svg>
  );
};

export default Pentagon;
