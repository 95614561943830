import { makeStyles } from '@material-ui/core/styles';
import { List, Grid, Box } from '@material-ui/core';
import ListItemRow from 'Components/ListItemRow';
import { Doughnut } from 'react-chartjs-2';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    margin: '0 0 12px 0'
  },
  summaryLeft: {},
  summaryRight: {},
  doughnut: {
    height: '300px'
  }
}));

const DynamicQuiz = ({ props }) => {
  const classes = useStyles();

  const answers = props.dynamicQuizAnswerPattern.split('');
  const correctAnswersTotal = answers.filter(a => a === '1').length;
  const incorrectAnswersTotal = answers.filter(a => a === '0').length;
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start">
      <Grid item xs={12} className={classes.summaryLeft}>
        <Box className={classes.doughnut}>
          <Doughnut
            height={50}
            data={{
              labels: ['Correct Answers', 'Incorrect Answers', 'Not Answered'],
              datasets: [
                {
                  label: 'Quiz Results',
                  data: [
                    correctAnswersTotal,
                    incorrectAnswersTotal,
                    10 - (correctAnswersTotal + incorrectAnswersTotal)
                  ],
                  backgroundColor: [
                    'rgb(152, 235, 176)',
                    'rgb(235, 152, 152)',
                    'rgb(219, 219, 219)'
                  ],
                  hoverOffset: 4
                }
              ]
            }}
          />
        </Box>
        <List dense={true}>
          <ListItemRow header="Quiz Session ID" data={props.quizSessionId} />
          <ListItemRow
            header="Answer Pattern"
            data={props.dynamicQuizAnswerPattern}
          />
        </List>
      </Grid>
    </Grid>
  );
};

export default DynamicQuiz;
