import { List, Pagination, FunctionField } from 'react-admin';
import { Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useLocation } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import styled from 'styled-components';
import WorksheetQuestionsDataGrid from './Components/WorksheetQuestionsDataGrid';
import WorksheetQuestionsFilter from './Components/WorksheetQuestionsFilter';
import WorksheetQuestionListActions from './Components/WorksheetQuestionListActions';
import PanoramaIcon from '@material-ui/icons/Panorama';
import { useState, useEffect } from 'react';
import EditWorksheetQuestionModal from './Components/EditWorksheetQuestionModal';
import ImageModal from './Components/ViewModal';
import CreateWorksheetQuestionModal from './Components/CreateWorksheetQuestionModal';
import { truncateString } from 'Helpers/stringHelpers';

const useStyles = makeStyles(theme => ({
  root: {
    margin: '12px',
    minHeight: '350px'
  },
  editButton: {
    color: '#4353ff',
    fontSize: '0.8125rem',
    padding: '4px',
    border: 'none',
    background: 'none',
    boxShadow: 'none'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '600px'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  formGroup: { display: 'flex', flexDirection: 'row', width: '100%' },
  buttonWrapper: { display: 'flex' }
}));

const StyledList = styled(List)`
  .MuiToolbar-root {
    justify-content: flex-start;
  }
`;

const WorksheetQuestionsListPagination = props => (
  <Pagination rowsPerPageOptions={[20, 30, 40, 50]} {...props} />
);

const WorksheetQuestionsList = props => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const [isEditModalOpen, setIsEditModalOpen] = useState(
    location.pathname.includes('edit')
  );

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(
    location.pathname.includes('create')
  );

  useEffect(() => {
    const runEffect = async () => {
      const isEdit = location.pathname.includes('edit');
      if (isEdit !== isEditModalOpen) setIsEditModalOpen(isEdit);

      const isCreate = location.pathname.includes('create');
      if (isCreate !== isCreateModalOpen) setIsCreateModalOpen(isCreate);
    };
    runEffect();
  }, [isCreateModalOpen, isEditModalOpen, location.pathname]);

  return (
    <Box className={classes.root}>
      {isEditModalOpen && (
        <EditWorksheetQuestionModal
          isOpen={true}
          setIsOpen={() =>
            history.push('/worksheet-questions' + location.search)
          }
        />
      )}
      {isCreateModalOpen && (
        <CreateWorksheetQuestionModal
          isOpen={true}
          setIsOpen={() =>
            history.push('/worksheet-questions' + location.search)
          }
        />
      )}
      <StyledList
        title="Worksheet Questions"
        filters={<WorksheetQuestionsFilter />}
        pagination={<WorksheetQuestionsListPagination />}
        actions={<WorksheetQuestionListActions />}
        bulkActionButtons={false}
        perPage={20}
        {...props}>
        <WorksheetQuestionsDataGrid rowClick="">
          <FunctionField sortable={false} label="Id" render={r => r.id} />
          <FunctionField
            sortBy="ConstructId"
            label="ConstructId"
            render={r => r.constructId}
          />
          <FunctionField
            label="Sequence"
            sortBy="Sequence"
            render={r => r.sequence}
          />
          <FunctionField
            label="QuestionText"
            sortBy="QuestionText"
            render={r => truncateString(r.questionText, 40) ?? '-'}
          />
          <FunctionField
            label="AnswerText"
            sortBy="AnswerText"
            render={r => truncateString(r.answerText, 40) ?? '-'}
          />
          <FunctionField
            label="View"
            render={r => <ViewButton classes={classes} record={r} />}
          />
          <EditButton classes={classes} history={history} />
        </WorksheetQuestionsDataGrid>
      </StyledList>
    </Box>
  );
};

const ViewButton = ({ record, classes }) => {
  const [isModalOpen, setisModalOpen] = useState(false);
  return (
    <>
      <Button
        variant="contained"
        color="default"
        className={classes?.editButton}
        startIcon={<PanoramaIcon />}
        onClick={() => setisModalOpen(!isModalOpen)}></Button>
      <ImageModal
        record={record}
        classes={classes}
        isModalOpen={isModalOpen}
        setisModalOpen={() => setisModalOpen(false)}
      />
    </>
  );
};

const EditButton = ({ record, classes, history }) => (
  <Button
    variant="contained"
    color="default"
    className={classes.editButton}
    startIcon={<EditIcon />}
    onClick={r => history.push(`/worksheet-questions/edit/${record.id}`)}>
    Edit
  </Button>
);

export default WorksheetQuestionsList;
