import { useCallback, useState } from 'react';
import { Line, Rect, Circle } from 'react-konva';
import {
  convertToCircle,
  convertToRectangle,
  convertToTriangle,
  smoothLines
} from '../helpers/whiteboardHelpers';
import { thicknessOpts } from '../helpers/toolHelpers';

const useDrawing = ({
  shapeList = [],
  lineList = [],
  currentTool = 'pen',
  currentThickness = 1,
  currentColor = '#000000'
}) => {
  const [shapes, setShapes] = useState(shapeList);
  const [lines, setLines] = useState(lineList);
  const [tool, setTool] = useState(currentTool);
  const [color, setColor] = useState(currentColor);
  const [thickness, setThickness] = useState(currentThickness);

  const handleLineDown = useCallback(
    pos =>
      setLines([...lines, { tool, points: [pos.x, pos.y], color, thickness }]),
    [color, lines, thickness, tool]
  );

  const handleShapeDown = useCallback(
    pos => {
      setShapes([
        ...shapes,
        {
          tool,
          color,
          thickness: thickness > 3 ? thickness : 3,
          startX: pos.x,
          startY: pos.y,
          endX: pos.x,
          endY: pos.y
        }
      ]);
    },
    [color, shapes, thickness, tool]
  );

  const handlePenMove = useCallback(
    point => {
      if (!lines.length) return;

      let lastLine = lines[lines.length - 1];

      if (!lastLine?.points?.length) return;

      lastLine.points = lastLine.points.concat([point.x, point.y]);
      lines.splice(lines.length - 1, 1, smoothLines(lastLine));
      setLines(lines.concat());
    },
    [lines]
  );

  const handleLineMove = useCallback(
    point => {
      let lastLine = lines[lines.length - 1];
      lastLine.points = [
        lastLine.points[0],
        lastLine.points[1],
        point.x,
        point.y
      ];
      lines.splice(lines.length - 1, 1, lastLine);
      setLines(lines.concat());
    },
    [lines]
  );

  const handleShapeMove = useCallback(
    point => {
      let lastShape = shapes[shapes.length - 1];
      lastShape.endX = point.x;
      lastShape.endY = point.y;
      shapes.splice(shapes.length - 1, 1, lastShape);
      setShapes(shapes.concat());
    },
    [shapes]
  );

  const handleFinishDrawing = useCallback(() => {
    console.log(tool, lines);
    let newLines = lines;
    if (tool === 'pen') {
      let lastLine = lines[lines.length - 1];
      newLines = lines.concat(
        convertToTriangle(convertToCircle(convertToRectangle(lastLine)))
      );
      setLines(newLines);
    }
  }, [lines, tool]);

  const clearDrawing = useCallback(() => {
    setLines([]);
    setShapes([]);
  }, []);

  const renderShape = (shape, i) => {
    const { tool, color, startX, startY, endX, endY, thickness } = shape;
    const width = endX - startX;
    let height = endY - startY;
    switch (tool) {
      case 'rect':
        return (
          <Rect
            key={i}
            x={startX}
            y={startY}
            width={width}
            height={height}
            stroke={color}
            strokeWidth={thickness}
            fill="transparent"
          />
        );
      case 'circle':
        height = endY - startY;
        const radius = Math.sqrt(width * width + height * height);
        return (
          <Circle
            key={i}
            x={startX}
            y={startY}
            radius={radius}
            stroke={color}
            strokeWidth={thickness}
            fill="transparent"
          />
        );
      case 'triangle':
        height = startY - endY;
        return (
          <Line
            key={i}
            points={[
              startX,
              startY,
              startX + width,
              startY,
              startX + width / 2,
              startY - height,
              startX,
              startY
            ]}
            stroke={color}
            strokeWidth={thickness}
            closed
            fill="transparent"
          />
        );
      default:
        return null;
    }
  };

  const updateThickness = thickness => {
    console.log(thickness);
    if (thickness < 1) {
      return setThickness(1);
    }
    if (thickness > thicknessOpts[thicknessOpts.length - 1]) {
      return setThickness(thicknessOpts[thicknessOpts.length - 1]);
    }
    setThickness(thickness);
  };

  return {
    setLines,
    setShapes,
    shapes,
    lines,
    tool,
    setTool,
    color,
    setColor,
    thickness,
    setThickness: updateThickness,
    handleLineDown,
    handleShapeDown,
    handlePenMove,
    handleLineMove,
    handleShapeMove,
    handleFinishDrawing,
    clearDrawing,
    renderShape
  };
};

export default useDrawing;
