import { List, Pagination, FunctionField } from 'react-admin';
import { Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useLocation } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import styled from 'styled-components';
import FlowQuestionsDataGrid from './Components/FlowQuestionsDataGrid';
import FlowQuestionsFilter from './Components/FlowQuestionsFilter';
import FlowQuestionListActions from './Components/FlowQuestionListActions';
import PanoramaIcon from '@material-ui/icons/Panorama';
import { useState, useEffect } from 'react';
import EditFlowQuestionModal from './Components/EditFlowQuestionModal';
import ImageModal from './Components/ImageModal';
import CreateFlowQuestionModal from './Components/CreateFlowQuestionModal';

const useStyles = makeStyles(theme => ({
  root: {
    margin: '12px',
    minHeight: '350px'
  },
  editButton: {
    color: '#4353ff',
    fontSize: '0.8125rem',
    padding: '4px',
    border: 'none',
    background: 'none',
    boxShadow: 'none'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '600px'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  formGroup: { display: 'flex', flexDirection: 'row', width: '100%' },
  buttonWrapper: { display: 'flex' }
}));

const StyledList = styled(List)`
  .MuiToolbar-root {
    justify-content: flex-start;
  }
`;

const TopicPathwayQuizPagination = props => (
  <Pagination rowsPerPageOptions={[20, 30, 40, 50]} {...props} />
);

const FlowQuestionsList = props => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const [isEditModalOpen, setIsEditModalOpen] = useState(
    location.pathname.includes('edit')
  );

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(
    location.pathname.includes('create')
  );

  useEffect(() => {
    const runEffect = async () => {
      const isEdit = location.pathname.includes('edit');
      if (isEdit !== isEditModalOpen) setIsEditModalOpen(isEdit);

      const isCreate = location.pathname.includes('create');
      if (isCreate !== isCreateModalOpen) setIsCreateModalOpen(isCreate);
    };
    runEffect();
  }, [isCreateModalOpen, isEditModalOpen, location.pathname]);

  return (
    <Box className={classes.root}>
      {isEditModalOpen && (
        <EditFlowQuestionModal
          isOpen={true}
          setIsOpen={() => history.push('/flow-questions' + location.search)}
        />
      )}
      {isCreateModalOpen && (
        <CreateFlowQuestionModal
          isOpen={true}
          setIsOpen={() => history.push('/flow-questions' + location.search)}
        />
      )}
      <StyledList
        title="Flow Questions"
        filters={<FlowQuestionsFilter />}
        pagination={<TopicPathwayQuizPagination />}
        actions={<FlowQuestionListActions />}
        bulkActionButtons={false}
        perPage={20}
        {...props}>
        <FlowQuestionsDataGrid rowClick="">
          <FunctionField sortable={false} label="Id" render={r => r.id} />
          <FunctionField
            sortBy="PrimaryQuestionId"
            label="PrimaryQuestionId"
            render={r => r.primaryQuestionId}
          />
          <FunctionField
            sortable={false}
            label="Type"
            render={r =>
              r.primaryQuestionId === r.questionId ? 'CheckIn' : 'CheckOut'
            }
          />
          <FunctionField
            label="QuestionId"
            sortBy="QuestionId"
            render={r => r.questionId}
          />
          <FunctionField
            label="ConstructId"
            sortBy="ConstructId"
            render={r => (
              <a
                href={`/worksheet-questions?filter=%7B%22q%22%3A${r.constructId}%7D`}
                target="_blank"
                rel="noreferrer">
                {r.constructId}
              </a>
            )}
          />
          <FunctionField
            label="MetaData"
            render={r => (
              <Button
                variant="contained"
                color="default"
                className={classes.editButton}
                startIcon={<EditIcon />}
                onClick={e =>
                  window.open(
                    `https://metaextractor.eedi.com/${
                      r.questionId
                    }?imageWidth=700&token=${localStorage.getItem(
                      'authToken'
                    )}`,
                    '_blank'
                  )
                }>
                Edit Metadata
              </Button>
            )}
          />
          <FunctionField
            source="ImageURL"
            label="View"
            render={r => <ImageButton classes={classes} record={r} />}
          />
          <EditButton classes={classes} history={history} />
        </FlowQuestionsDataGrid>
      </StyledList>
    </Box>
  );
};

const ImageButton = ({ record, classes }) => {
  const [isModalOpen, setisModalOpen] = useState(false);
  return (
    <>
      <Button
        variant="contained"
        color="default"
        className={classes?.editButton}
        startIcon={<PanoramaIcon />}
        onClick={() => setisModalOpen(!isModalOpen)}></Button>
      <ImageModal
        record={record}
        classes={classes}
        isModalOpen={isModalOpen}
        setisModalOpen={() => setisModalOpen(false)}
      />
    </>
  );
};

const EditButton = ({ record, classes, history }) => (
  <Button
    variant="contained"
    color="default"
    className={classes.editButton}
    startIcon={<EditIcon />}
    onClick={r => history.push(`/flow-questions/edit/${record.id}`)}>
    Edit
  </Button>
);

export default FlowQuestionsList;
