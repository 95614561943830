import { Grid, Typography, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { MetadataContext } from 'Providers/MetadataProvider';
import { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Api from 'Api/Api';
import { useCallback } from 'react';
import { useNotify } from 'ra-core/esm/sideEffect';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  delete_button: {
    margin: 'auto'
  }
}));

const RequestEntry = ({ studentId, entry }) => {
  const history = useHistory();
  const notify = useNotify();
  const classes = useStyles();
  const { countries } = useContext(MetadataContext);
  const country = countries.find(c => c.countryId === entry.countryId);

  const rejectEntry = useCallback(async () => {
    try {
      await Api.request(
        'DELETE',
        `v4/admin/students/${studentId}/team-school/requests`
      );

      history.push('/requests');
    } catch {
      notify(
        'There had been issue when trying to perform this action',
        'error'
      );
    }
  }, [history, notify, studentId]);

  return (
    <Grid container direction="column">
      <>
        <div>
          <Typography component="span" variant="body2" color="textPrimary">
            URL -{' '}
          </Typography>
          {entry.url ?? '[ Not Entered ]'}
        </div>
        <div>
          <Typography component="span" variant="body2" color="textPrimary">
            Name -{' '}
          </Typography>
          {entry.name ?? '[ Not Entered ]'}
        </div>
        <div>
          <Typography component="span" variant="body2" color="textPrimary">
            Country -{' '}
          </Typography>
          {country.name ?? '[ Not Entered ]'}
        </div>
      </>
      <IconButton
        aria-label="delete"
        className={classes.delete_button}
        onClick={() => rejectEntry()}>
        <DeleteIcon />
      </IconButton>
    </Grid>
  );
};

export default RequestEntry;
