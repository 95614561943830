import { Form, Field } from 'react-final-form';
import { TextField, Button, FormGroup } from '@material-ui/core';
import NextIcon from '@material-ui/icons/NavigateNext';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useState } from 'react';

const useStyles = makeStyles(theme => ({
  input: {
    margin: '1rem 0 0 0'
  }
}));

const Step1 = ({ onNext = payload => {}, onComplete = () => {} }) => {
  const classes = useStyles();
  const [input, setInput] = useState();

  const submitForm = async values => {
    console.log('submit form values', values);
    onNext({ prompt: values.input });

    onComplete({ prompt: values.input });
  };

  return (
    <Form
      onSubmit={submitForm}
      initialValues={{
        input: input || ''
      }}
      validate={values => {
        const errors = {};
        if (!values.input) errors.input = 'Required';
        return errors;
      }}>
      {({ handleSubmit, submitting, pristine, submitError }) => (
        <form onSubmit={handleSubmit}>
          <FormGroup>
            <Field name="input">
              {({ input, meta }) => (
                <TextField
                  {...input}
                  style={{ width: '100%' }}
                  minRows={1}
                  maxRows={3}
                  multiline
                  type="text"
                  variant="outlined"
                  label="Learning goal/how can we help?"
                  className={classes.input}
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : null}
                />
              )}
            </Field>
          </FormGroup>
          <Button
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            className={classes.createButton}
            startIcon={<NextIcon />}
            disabled={(submitting || pristine || submitError) && !input}>
            Next
          </Button>
        </form>
      )}
    </Form>
  );
};

export default Step1;
