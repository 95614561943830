import { TextField, Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Api from 'Api/Api';
import { useCallback, useEffect, useState } from 'react';
import { useNotify } from 'ra-core/esm/sideEffect';
import useFocus from 'Helpers/useFocus';
import CancelIcon from '@material-ui/icons/Cancel';
import SendIcon from '@material-ui/icons/Send';
import ChatIcon from '@material-ui/icons/Chat';
import ConversationPromptModal from './ConversationPromptModal';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    minHeight: '180px',
    background: '#eee',
    padding: '1rem',
    borderTop: '1px solid #ccc'
  },
  textField: {
    background: '#fff',
    width: '100%'
  },
  button: {
    margin: '1rem 0 0 0'
  },
  emojiButton: {},
  uploadButton: {},
  buttonGrid: {
    display: 'flex'
  },
  optionsGrid: {
    textAlign: 'center'
  }
}));

const ConversationChatBox = ({
  isPromptModalOpen = false,
  flowGeneratorSessionId,
  loading = false,
  cancelResponse = () => {},
  setIsPromptModalOpen = () => {}
}) => {
  const notify = useNotify();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(loading);
  const [message, setMessage] = useState('');
  const [textInput, setFocus] = useFocus();

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  const sendMessage = useCallback(async () => {
    if (!message) return;
    try {
      setIsLoading(true);
      await Api.messageConversation({
        conversationId: flowGeneratorSessionId,
        input: message
      });
      setMessage('');
      setIsLoading(false);
      setFocus();
    } catch (e) {
      notify(e.toString(), 'warning');
      console.error(e);
      setIsLoading(false);
    }
  }, [message, setFocus, flowGeneratorSessionId, notify]);

  const onKeyPress = async e => {
    if (!e.shiftKey && (e.key === 'Enter' || e.keyCode === 13)) {
      sendMessage();
    }
  };

  return (
    <Grid className={classes.root} container>
      <Grid item xs={12}>
        <form id="chatbox">
          <TextField
            autoFocus
            inputRef={textInput}
            className={classes.textField}
            id="chatinput"
            multiline
            maxRows={8}
            minRows={4}
            variant="outlined"
            onChange={e => setMessage(e.target.value)}
            value={message}
            onKeyPress={async e => await onKeyPress(e)}
          />
        </form>
      </Grid>
      <Grid item container xs={12} className={classes.buttonGrid}>
        <Grid item xs={12}>
          <Button
            className={classes.button}
            style={{ margin: '0.5rem 1rem 0 0' }}
            variant="contained"
            color="primary"
            disabled={!message || isLoading}
            startIcon={<SendIcon />}
            onClick={() => sendMessage()}>
            Send
          </Button>
          <Button
            className={classes.button}
            style={{ margin: '0.5rem 1rem 0 0' }}
            variant="contained"
            color="secondary"
            disabled={!isLoading}
            startIcon={<CancelIcon />}
            onClick={() => cancelResponse()}>
            Stop Generating
          </Button>
          <Button
            className={classes.button}
            style={{ margin: '0.5rem 1rem 0 0' }}
            variant="contained"
            color="secondary"
            startIcon={<ChatIcon />}
            onClick={() => setIsPromptModalOpen(true)}>
            View Prompt
          </Button>
        </Grid>
      </Grid>
      <ConversationPromptModal
        isOpen={isPromptModalOpen}
        setIsOpen={setIsPromptModalOpen}
        flowGeneratorSessionId={flowGeneratorSessionId}
        isCreateMode={false}
      />
    </Grid>
  );
};

export default ConversationChatBox;
