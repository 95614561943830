import { SelectInput, Filter, SearchInput } from 'react-admin';

const levelChoices = (numberOfLevels = 35) => {
  const choices = [];
  for (let i = 1; i <= numberOfLevels; i++) {
    choices.push({ id: i, name: `Level ${i}`, value: i });
  }
  return choices;
};

const StudentFilter = props => {
  const choices = levelChoices(35);
  return (
    <div>
      <Filter {...props}>
        <SearchInput source="q" alwaysOn />
        <SelectInput choices={choices} source="currentLevel" />
      </Filter>
    </div>
  );
};
export default StudentFilter;
