import { Loading } from 'react-admin';
import { Box, Card, List, ListItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import useApiRequest from 'Hooks/useApiRequest';
import Api from 'Api/Api';

const useStyles = makeStyles(theme => ({
  root: {
    margin: '12px',
    minHeight: '350px'
  }
}));

const MisconceptionTagsList = props => {
  const classes = useStyles();

  const { loading, data: tags } = useApiRequest(() =>
    Api.getMisconceptionTags()
  );

  if (loading) {
    return <Loading />;
  }

  const deduplicatedTags = [
    ...new Map(
      tags.data.map(item => [item['embeddingOutputId'], item])
    ).values()
  ];

  return (
    <Box className={classes.root}>
      <Card>
        <List>
          {deduplicatedTags.map(r => (
            <ListItem>
              <div>{r.searchText}</div>
            </ListItem>
          ))}
        </List>
      </Card>
    </Box>
  );
};

export default MisconceptionTagsList;
