import Api from 'Api/Api';
import useApiRequest from 'Hooks/useApiRequest';
import { useNotify } from 'ra-core/esm/sideEffect';
import { useCallback, useEffect, useState } from 'react';
import { Loading } from 'react-admin';
import styled from 'styled-components';
import { cropImage, loadImageBitmap } from './Components/imageHelpers';
import ImageMetaDataShow from './ImageMetaDataShow';
import { Button } from '@material-ui/core';
import PreviewQuestionModal from './Components/PreviewQuestionModal';
import { ImageSearch } from '@material-ui/icons';
import EditTagModal from './EditTagModal';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { useHistory } from 'react-router-dom';
import FunctionalQuestionModal from './Components/FunctionalQuestionModal';

const roiDefaults = {
  threshold: 1,
  rectangleProximityThreshold: 5,
  expandPixels: 12,
  cropTopPixels: 80,
  cropBottomPixels: 80,
  minPixels: 60
};

const QuestionExtractorShow = props => {
  const history = useHistory();
  const defaultImageWidth = 900;
  const notify = useNotify();
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [metaData, setMetaData] = useState([]);
  const [editState, setEditState] = useState({
    isLoaded: false,
    bitmap: null,
    imageURL: null,
    isDeleteModalOpen: false,
    sortDirection: 'asc',
    sortBy: 'sequence',
    ...roiDefaults
  });
  const [isEditTagModalOpen, setIsEditTagModalOpen] = useState(false);
  const [isFunctionalQuestionModalOpen, setIsFunctionalQuestionModalOpen] =
    useState(false);
  const { loading: isQuestionMetaDataLoading, data: fetchedQuestionMetaData } =
    useApiRequest(() =>
      Api.request('GET', `v4/admin/questions/${props.id}/metadata`)
    );

  const { loading: isMetaDataTagsLoading, data: fetchedMetaDataTags } =
    useApiRequest(() => Api.request('GET', `v4/admin/metadata-tags`));
  const [metaDataTags, setMetaDataTags] = useState(fetchedMetaDataTags ?? []);

  const getSortedMetaData = useCallback(
    () =>
      editState?.sortDirection === 'desc'
        ? metaData.sort((a, b) =>
            editState?.sortBy?.length > 0
              ? b[editState?.sortBy] > a[editState?.sortBy]
                ? 1
                : -1
              : 0
          )
        : metaData.sort((a, b) =>
            editState?.sortBy?.length > 0
              ? a[editState?.sortBy] > b[editState?.sortBy]
                ? 1
                : -1
              : 0
          ),
    [editState?.sortBy, editState?.sortDirection, metaData]
  );

  const handleDeletePress = async metaDataToDelete => {
    if (!metaDataToDelete?.metaDataId) {
      setMetaData(
        metaData
          .filter(m => m.rectangleId !== metaDataToDelete.rectangleId)
          .sort((a, b) => a.sequence - b.sequence)
          .map((m, i) => ({
            ...m,
            rectangleId: m.id ? m.id : i,
            sequence: m.id ? m.sequence : i + 1
          }))
      );
      return;
    }
    setEditState({
      ...editState,
      isDeleteModalOpen: true,
      metaDataToDelete
    });
  };

  const insertMetaData = async metaDataInsert => {
    if (!!metaDataInsert.metaDataId) return;

    const scaleFactor = 1.0 / metaDataInsert.scale;
    metaDataInsert.x = Math.round(metaDataInsert.x * scaleFactor);
    metaDataInsert.y = Math.round(metaDataInsert.y * scaleFactor);
    metaDataInsert.width = Math.round(metaDataInsert.width * scaleFactor);
    metaDataInsert.height = Math.round(metaDataInsert.height * scaleFactor);
    metaDataInsert.scale = 1;

    setEditState(() => ({ ...editState, isSaving: true }));

    try {
      const result = await Api.request(
        'POST',
        `v4/admin/questions/${props.id}/metadata`,
        metaDataInsert
      );

      const metaDataResult = result.questionMetaDatas
        .filter(f => !metaData?.find(m => m.metaDataId === f.metaDataId))
        .map(m => ({
          ...m,
          rectangleId: m.id,
          opacity: 0.5,
          hexColor: m.metaDataTag?.hexColor ?? '#DC143C'
        }))[0];

      const metaDataCopy = [...metaData];
      const index = metaDataCopy.findIndex(
        i => i.rectangleId === metaDataInsert.rectangleId
      );
      metaDataCopy[index] = {
        ...metaDataCopy[index],
        ...metaDataResult,
        isStale: false
      };
      setMetaData(metaDataCopy);
      notify('Inserted MetaData', 'success');
    } catch (e) {
      notify(e.toString(), 'error');
      console.error(e);
    }

    setEditState(() => ({ ...editState, isSaving: false }));
  };

  const updateMetaData = async metaDataUpdate => {
    if (!metaDataUpdate.metaDataId) return;

    setEditState(() => ({ ...editState, isSaving: true }));

    try {
      const result = await Api.request(
        'PUT',
        `v4/admin/questions/${props.id}/metadata/${metaDataUpdate.metaDataId}`,
        metaDataUpdate
      );

      const resultMetaData = result.questionMetaDatas.find(
        m => m.metaDataId === metaDataUpdate.metaDataId
      );

      if (!resultMetaData) return;

      const metaDataCopy = [...metaData];
      const index = metaDataCopy.findIndex(
        i => i.metaDataId === resultMetaData.metaDataId
      );
      metaDataCopy[index] = {
        ...metaDataCopy[index],
        ...resultMetaData,
        rectangleId: resultMetaData.id,
        opacity: 0.5,
        hexColor: resultMetaData.metaDataTag?.hexColor ?? '#DC143C',
        isStale: false
      };
      setMetaData(metaDataCopy);
      notify('Updated MetaData', 'success');
    } catch (e) {
      notify(e.toString(), 'error');
    }

    setEditState(() => ({ ...editState, isSaving: false }));
  };

  const onSubmitMetaDataTag = useCallback(
    async metaDataTag => {
      if (!metaDataTag) return;

      try {
        let updatedTag = null;
        if (!metaDataTag.metaDataTagId) {
          updatedTag = await Api.request(
            'POST',
            'v4/admin/metadata-tags',
            metaDataTag
          );
          setMetaDataTags([...metaDataTags, updatedTag]);
        } else {
          updatedTag = await Api.request(
            'PUT',
            `v4/admin/metadata-tags/${metaDataTag.metaDataTagId}`,
            metaDataTag
          );
          const updatedMetaDataTags = metaDataTags.map(t =>
            t.metaDataTagId === updatedTag.metaDataTagId ? updatedTag : t
          );
          setMetaDataTags(updatedMetaDataTags);
        }
        if (updatedTag) {
          const updatedMetaData = metaData.map(m => {
            if (m.metaDataTagId === updatedTag.metaDataTagId) {
              return {
                ...m,
                hexColor: updatedTag.hexColor,
                metaDataTag: updatedTag
              };
            }
            return m;
          });
          setMetaData(updatedMetaData);
        }
        setIsEditTagModalOpen(false);
        notify('Tag saved', 'success');
      } catch (e) {
        notify(e.message, 'error');
      }
    },
    [metaData, metaDataTags, notify, setMetaData]
  );

  const handleGoBack = () => {
    if (history.location.state?.previousUrl) {
      history.push(history.location.state?.previousUrl);
      return;
    }
    history.push(`/question-metadata`);
  };

  useEffect(() => {
    const runEffect = async () => {
      if (!metaDataTags?.length && !!fetchedMetaDataTags?.length)
        setMetaDataTags(fetchedMetaDataTags);

      if (!fetchedQuestionMetaData?.imageURL || editState.isLoaded) return;
      const imageURL =
        'https://diagnosticquestionsprod.blob.core.windows.net' +
        fetchedQuestionMetaData.imageURL;
      const bitmap = await loadImageBitmap(imageURL);

      if (metaData.length) {
        const metaDataCopy = [...metaData];
        metaDataCopy.forEach(async m => {
          if (m.blob) return m;
          m.blob = await cropImage({
            bitmap,
            x: m.x,
            y: m.y,
            width: m.width,
            height: m.height,
            scale: m.scale
          });
          return m;
        });

        setMetaData(metaDataCopy);
      }

      setEditState(() => ({ ...editState, imageURL, bitmap, isLoaded: true }));
    };

    if (
      !!fetchedQuestionMetaData?.questionMetaDatas?.length &&
      !metaData.length &&
      !editState.isLoaded
    ) {
      const fetchedMetaDatas = fetchedQuestionMetaData.questionMetaDatas.map(
        m => {
          const meta = {
            ...m,
            rectangleId: m.id,
            opacity: 0.5,
            hexColor: m.metaDataTag?.hexColor ?? '#DC143C'
          };
          return meta;
        }
      );
      setMetaData(fetchedMetaDatas);
    }

    runEffect();
  }, [
    editState,
    fetchedMetaDataTags,
    fetchedQuestionMetaData,
    metaData,
    metaDataTags?.length
  ]);

  useEffect(() => {}, [metaData, editState]);

  const isLoading =
    isQuestionMetaDataLoading || !editState.isLoaded || isMetaDataTagsLoading;

  if (isLoading) return <Loading />;

  return (
    <StyledPage>
      <QuestionInfo>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start'
          }}>
          <div>
            <BackButton
              variant="contained"
              color="secondary"
              startIcon={<ChevronLeftIcon />}
              onClick={handleGoBack}
            />
          </div>
          <div>
            <h4 style={{ margin: 0, lineHeight: 0 }}>QuestionId: {props.id}</h4>
            <h5>
              Correct Answer:{' '}
              {
                ['None', 'A', 'B', 'C', 'D'][
                  fetchedQuestionMetaData.correctAnswer
                ]
              }
            </h5>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start'
          }}>
          <div>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              startIcon={<ImageSearch />}
              onClick={() => setIsFunctionalQuestionModalOpen(true)}
              disabled={!metaData.length}>
              Simple Preview
            </Button>
          </div>
          <div>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              startIcon={<ImageSearch />}
              onClick={() =>
                window.open(
                  `https://metaextractor.eedi.com/${
                    props.id
                  }?imageWidth=700&token=${localStorage.getItem('authToken')}`,
                  '_blank'
                )
              }>
              Open in MetaExtractor
            </Button>
          </div>
        </div>
      </QuestionInfo>
      <ImageMetaDataShow
        {...props}
        defaultImageWidth={defaultImageWidth}
        insertMetaData={insertMetaData}
        updateMetaData={updateMetaData}
        handleDeletePress={handleDeletePress}
        setMetaData={setMetaData}
        metaData={getSortedMetaData()}
        editState={editState}
        setEditState={setEditState}
        isLoading={isLoading}
        metaDataTags={metaDataTags}
      />
      <PreviewQuestionModal
        imageURL={editState.imageURL}
        open={isPreviewModalOpen}
        onClose={() => setIsPreviewModalOpen(false)}
        bitmap={editState.bitmap}
        metaData={metaData}
      />
      <EditTagModal
        isOpen={isEditTagModalOpen}
        setIsOpen={() => setIsEditTagModalOpen(false)}
        onSubmit={onSubmitMetaDataTag}
        metaDataTags={metaDataTags ?? []}
      />
      <FunctionalQuestionModal
        open={isFunctionalQuestionModalOpen}
        onClose={() => setIsFunctionalQuestionModalOpen(false)}
        metaData={metaData}
        metaDataTags={metaDataTags}
        question={fetchedQuestionMetaData}
        imageURL={editState.imageURL}
      />
    </StyledPage>
  );
};

const StyledPage = styled.div`
  display: flex;
  flex-direction: column;
`;

const QuestionInfo = styled.div`
  padding: 1rem 0 1rem 1rem;
  margin: 1rem 0 0 0;
  border-bottom: 1px solid #ccc;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  h5 {
    line-height: 3rem;
    margin: 0;
  }
  div {
    margin: 0 0.5rem 0 0;
  }
`;

const BackButton = styled(Button)`
  z-index: 998;
  padding: 10px !important;
  height: 24px !important;
  width: 24px !important;
  min-width: 24px !important;
  max-width: 24px !important;
  span {
    display: contents;
  }
`;

export default QuestionExtractorShow;
