import {
  Modal,
  Backdrop,
  Container,
  Card,
  CardContent,
  Typography
} from '@material-ui/core';

const ViewModal = ({ record, classes, isModalOpen, setisModalOpen }) => {
  return (
    <Modal
      open={isModalOpen}
      onClose={setisModalOpen}
      className={classes.modal}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
      closeAfterTransition>
      <Container maxWidth="md">
        <Card className={classes.create}>
          <CardContent
            style={{
              textAlign: 'center',
              overflowY: 'scroll',
              maxHeight: `${window.innerHeight ?? 900}px`
            }}>
            <Typography variant="h5">
              <strong>
                ConstructId: {record.constructId} - Question {record.sequence})
              </strong>
            </Typography>
            {record.questionImageUrl && (
              <img
                src={record.questionImageUrl}
                alt=""
                style={{
                  maxHeight: '500px',
                  width: 'auto',
                  margin: '1rem 0 1rem 0'
                }}
              />
            )}
            {record.questionText && (
              <Typography
                variant="h6"
                style={{ margin: '1rem 0 1rem 0' }}
                gutterBottom>
                {record.questionText}
              </Typography>
            )}
            <hr />
            <Typography variant="h5">
              <strong>Answer:</strong>
            </Typography>
            {record.answerImageUrl && (
              <img
                src={record.answerImageUrl}
                alt=""
                style={{ maxHeight: '500px', width: '100%' }}
              />
            )}
            {record.answerText && (
              <Typography variant="h6" gutterBottom>
                {record.answerText}
              </Typography>
            )}
          </CardContent>
        </Card>
      </Container>
    </Modal>
  );
};

export default ViewModal;
