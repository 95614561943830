import { Filter, SearchInput } from 'react-admin';

const WorksheetQuestionsFilter = props => {
  return (
    <Filter {...props}>
      <SearchInput source="q" alwaysOn />
    </Filter>
  );
};
export default WorksheetQuestionsFilter;
