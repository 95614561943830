import { List, Pagination, FunctionField } from 'react-admin';
import { Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useLocation } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import styled from 'styled-components';
import ConstructsDataGrid from './Components/ConstructsDataGrid';
import ConstructsFilter from './Components/ConstructsFilter';

import { useState, useEffect } from 'react';
import EditConstructTranscriptModal from './Components/EditConstructTranscriptModal';

const useStyles = makeStyles(theme => ({
  root: {
    margin: '12px',
    minHeight: '350px'
  },
  editButton: {
    color: '#4353ff',
    fontSize: '0.8125rem',
    padding: '4px',
    border: 'none',
    background: 'none',
    boxShadow: 'none'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '600px'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  formGroup: { display: 'flex', flexDirection: 'row', width: '100%' },
  buttonWrapper: { display: 'flex' }
}));

const StyledList = styled(List)`
  .MuiToolbar-root {
    justify-content: flex-start;
  }
`;

const ConstructsListPagination = props => (
  <Pagination rowsPerPageOptions={[20, 30, 40, 50]} {...props} />
);

const ConstructsList = props => {
  const history = useHistory();
  const location = useLocation();

  const classes = useStyles();

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  useEffect(() => {
    const isEdit = location.pathname.includes('edit');
    if (isEdit !== isEditModalOpen) setIsEditModalOpen(isEdit);
  }, [isEditModalOpen, location.pathname]);

  return (
    <Box className={classes.root}>
      {isEditModalOpen && (
        <EditConstructTranscriptModal
          isOpen={true}
          setIsOpen={() =>
            history.push('/construct-transcripts' + location.search)
          }
        />
      )}
      <StyledList
        title="Constructs"
        filters={<ConstructsFilter />}
        pagination={<ConstructsListPagination />}
        bulkActionButtons={false}
        perPage={20}
        {...props}>
        <ConstructsDataGrid rowClick="">
          <FunctionField
            sortBy="ConstructId"
            label="ConstructId"
            render={r => r.constructId}
          />
          <FunctionField
            sortBy="ConstructName"
            label="ConstructName"
            render={r => r.constructName}
          />
          <FunctionField
            label="VideosProcessing"
            render={r => r.videosProcessing}
          />
          <FunctionField
            sortBy="TotalVideoCount"
            label="TotalVideoCount"
            render={r => r.totalVideoCount}
          />
          <FunctionField
            sortBy="VideosProofed"
            label="VideosProofed"
            render={r => r.videosProofed}
          />
          <FunctionField
            sortBy="VideosTranscribed"
            label="VideosTranscribed"
            render={r => r.videosTranscribed}
          />
          <FunctionField
            label="VideosParsedByGPT"
            render={r => r.videosParsedByGPT}
          />
          <FunctionField
            sortBy="TranscriptionsFailed"
            label="TranscriptionsFailed"
            render={r => r.transcriptionsFailed}
          />

          <FunctionField
            sortBy="ProofCompletion"
            label="ProofCompletion"
            render={r => `${r.proofCompletion}%`}
          />
          <EditButton classes={classes} history={history} />
        </ConstructsDataGrid>
      </StyledList>
    </Box>
  );
};

const EditButton = ({ record, classes, history }) => (
  <Button
    variant="contained"
    color="default"
    className={classes.editButton}
    startIcon={<EditIcon />}
    onClick={r => history.push(`/construct-transcripts/edit/${record.id}`)}>
    Edit
  </Button>
);

export default ConstructsList;
