import { makeStyles } from '@material-ui/core/styles';
import { Modal, Backdrop } from '@material-ui/core';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import styled from 'styled-components';
import EditFlowItemForm from '../Forms/EditFlowItemForm';
import {
  useHistory,
  useParams
} from 'react-router-dom/cjs/react-router-dom.min';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '700px'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxHeight: '80vh',
    overflowY: 'scroll'
  }
}));

const CreateFlowItemTemplateModal = ({
  flowTemplates = [],
  flowItems = [],
  flowItem = {},
  templateSlug,
  isModalOpen,
  setIsModalOpen,
  insertFlowItem = () => {},
  updateFlowItem = () => {},
  loading = false
}) => {
  const slug = useParams()?.templateSlug;
  const history = useHistory();
  const classes = useStyles();

  if (!templateSlug) return null;

  return (
    <Modal
      open={isModalOpen}
      onClose={() => !loading && setIsModalOpen(false)}
      className={classes.modal}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
      closeAfterTransition>
      <div className={classes.paper}>
        <StyledEditFlowItemForm
          flowTemplates={flowTemplates}
          flowItems={flowItems}
          flowItem={flowItem}
          templateSlug={templateSlug}
          updateFlowItem={async (values, sequence) => {
            if (flowItem.flowItemId) {
              await updateFlowItem(values, sequence);
            } else {
              await insertFlowItem(values, sequence);
              history.push(`/flows/${slug}/0/${templateSlug}`);
            }
            setIsModalOpen(false);
          }}
          loading={loading}
        />
      </div>
    </Modal>
  );
};

const StyledEditFlowItemForm = styled(EditFlowItemForm)`
  width: 500px;
  div {
    padding: 0;
  }
`;

export default CreateFlowItemTemplateModal;
