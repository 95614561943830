import { makeStyles } from '@material-ui/core/styles';
import {
  Modal,
  Backdrop,
  Container,
  Card,
  CardContent,
  Typography
} from '@material-ui/core';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useCallback, React, useState, useEffect } from 'react';
import { useDataProvider, useNotify, useRefresh } from 'react-admin';
import { useLocation } from 'react-router-dom';
import EditFlowQuestionForm from './EditFlowQuestionForm';
import Api from 'Api/Api';
import TabPanel from 'Components/TabPanel';
import EditQuizQuestionForm from './EditQuizQuestionForm';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  create: {
    margin: '1rem 0 0 0',
    overflowY: 'scroll',
    maxHeight: `${window.innerHeight ?? 900}px`
  },
  input: {
    margin: '1rem 0 0 0'
  },
  content: {
    padding: 0,
    margin: 0
  }
}));

const EditFlowQuestionModal = ({ isOpen, setIsOpen }) => {
  const location = useLocation();
  const id = location.pathname.split('/').reverse()[0];
  const notify = useNotify();
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const [flowQuestion, setFlowQuestion] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [cacheState, setCacheState] = useState({
    isModalOpen: false,
    isLoading: false
  });

  const fetchFlowQuestion = useCallback(
    async id => {
      try {
        if (!id) return;

        const result = await dataProvider.getOne('flow-questions', {
          id
        });

        if (!result?.data)
          throw new Error(`Unable to fetch flow question ${id}`);

        setFlowQuestion(() => result.data);
      } catch (e) {
        notify('Error fetching flow question!', 'error');
        console.error(e);
      }
    },
    [dataProvider, notify]
  );

  const resetCache = useCallback(
    async ({ showNotify = true }) => {
      if (!flowQuestion?.questionId) return;
      try {
        setCacheState(() => ({ ...cacheState, isLoading: true }));
        await Api.reCacheFlowTemplates({
          flowQuestionIds: [flowQuestion?.questionId]
        });
        setCacheState(() => ({
          ...cacheState,
          isModalOpen: false,
          isLoading: false
        }));
        showNotify && notify('Whoop! Cache cleared!', 'success');
      } catch (error) {
        setCacheState(() => ({ ...cacheState, isLoading: false }));
        notify('Error resetting cache', 'error');
        console.log(error);
      }
    },
    [cacheState, notify, flowQuestion?.questionId]
  );

  const updateFlowQuestion = useCallback(
    async values => {
      try {
        values.sequence = values.sequence ? 1 : 2;
        await dataProvider.update('flow-questions', {
          id: values.id,
          body: values
        });
        await resetCache({ showNotify: false });
        refresh();
        setIsOpen();
        notify('Flow Question Updated!', 'success');
      } catch (e) {
        notify('Error Updating Flow Question!', 'error');
        console.error(e);
      }
    },
    [dataProvider, notify, refresh, resetCache, setIsOpen]
  );

  useEffect(() => {
    const runEffect = async () => {
      if (isLoaded || !isOpen) return;
      id && (await fetchFlowQuestion(id));
      setIsLoaded(true);
    };
    runEffect();
  }, [fetchFlowQuestion, isLoaded, isOpen, id]);

  const tabs = [
    {
      label: 'Flow Question',
      children: (
        <EditFlowQuestionForm
          onSubmit={updateFlowQuestion}
          onCancel={setIsOpen}
          flowQuestion={flowQuestion}
          resetCache={resetCache}
          cacheState={cacheState}
        />
      )
    },
    {
      label: 'Quiz Question',
      children: (
        <EditQuizQuestionForm
          questionId={flowQuestion?.questionId}
          onCancel={setIsOpen}
        />
      )
    }
  ];

  return (
    <Modal
      open={isOpen}
      onClose={() => setIsOpen()}
      className={classes.modal}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
      closeAfterTransition>
      <Container maxWidth="md">
        <Card className={classes.create}>
          <CardContent className={classes.content}>
            {cacheState.isLoading ? (
              <>
                <p style={{ textAlign: 'center' }}>
                  Resetting cache for this flow question. This includes all
                  linked quizs and topics... this may take a few moments...
                  <span role="img" aria-labelledby="beverage">
                    ☕
                  </span>
                </p>
                <img
                  style={{
                    textAlign: 'center',
                    margin: '0 auto',
                    height: '150px',
                    width: 'auto',
                    display: 'flex'
                  }}
                  src="/assets/loady.gif"
                  alt="loading!"></img>
              </>
            ) : (
              <TabPanel tabs={tabs} />
            )}
          </CardContent>
        </Card>
      </Container>
    </Modal>
  );
};

export default EditFlowQuestionModal;
