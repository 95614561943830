export const getFlowTemplateNodeHeight = ({
  flowItemTemplateSlug,
  parentFlowTemplateSlug,
  isStartNode = false,
  isEndNode = false,
  nodeHeight = 100
}) => {
  const ftHeight = flowItemTemplateSlug ? 30 : 0;
  const pftHeight = parentFlowTemplateSlug ? 30 : 0;
  const startHeight = isStartNode ? 30 : 0;
  const endHeight = isEndNode ? 30 : 0;
  return nodeHeight + ftHeight + pftHeight + startHeight + endHeight;
};

export const getFlowItemTemplateNodeHeight = ({
  radioButtons = [],
  imageUrlOptions = [],
  textOptions = [],
  nodeHeight = 100
}) => {
  return (
    radioButtons.reduce(acc => acc + 5, 0) +
    imageUrlOptions.reduce(acc => acc + 250, 0) +
    textOptions.reduce(acc => acc + 20, 0) +
    (!radioButtons?.length && !textOptions?.length && !imageUrlOptions?.length
      ? nodeHeight
      : 120)
  );
};
