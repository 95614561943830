import {
  Container,
  Typography,
  Card,
  CardContent,
  Button,
  Slide
} from '@material-ui/core';
import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CreateHybridSchoolCard from './Components/CreateHybridSchoolCard';
import UpdateHybridSchoolCard from './Components/UpdateHybridSchoolCard';

const useStyles = makeStyles({
  wrapper: {
    overflow: 'hidden',
    width: '100%',
    height: '100%'
  },
  root: {
    margin: '1rem 0 0 0'
  },
  create: {
    margin: '1rem 0 0 0'
  },
  input: {
    margin: '1rem 0 0 0'
  },
  createNewSchoolButton: {
    margin: '0 10px 0 0'
  },
  updateExistingSchoolButton: {},
  createButton: {
    margin: '1rem 0 0 0'
  },
  cancelButton: {
    margin: '1rem 0 0 10px'
  }
});

const HybridSchoolCreate = props => {
  const [state, setState] = useState({
    isCreatingSchool: null
  });

  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Slide
        direction="down"
        in={state.isCreatingSchool === null}
        timeout={350}>
        <Container maxWidth="md">
          <Card className={classes.root}>
            <CardContent>
              <Typography variant="h5" gutterBottom>
                Create Hybrid School
              </Typography>
              <Button
                variant="contained"
                color="primary"
                className={classes.createNewSchoolButton}
                onClick={() =>
                  setState(() => ({ ...state, isCreatingSchool: true }))
                }>
                Create New School
              </Button>
              <Button
                className={classes.updateExistingSchoolButton}
                variant="contained"
                color="primary"
                onClick={() =>
                  setState(() => ({ ...state, isCreatingSchool: false }))
                }>
                Update Existing School
              </Button>
            </CardContent>
          </Card>
        </Container>
      </Slide>
      <Container maxWidth="md">
        <Slide
          direction="left"
          in={state.isCreatingSchool}
          mountOnEnter
          unmountOnExit
          timeout={350}>
          <div>
            <CreateHybridSchoolCard
              onCancel={() =>
                setState(() => ({ ...state, isCreatingSchool: null }))
              }
            />
          </div>
        </Slide>
        <Slide
          direction="left"
          in={state.isCreatingSchool === false}
          mountOnEnter
          unmountOnExit
          timeout={350}>
          <div>
            <UpdateHybridSchoolCard
              onCancel={() =>
                setState(() => ({ ...state, isCreatingSchool: null }))
              }
            />
          </div>
        </Slide>
      </Container>
    </div>
  );
};

export default HybridSchoolCreate;
