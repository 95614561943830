import { Button, FormGroup, TextField, makeStyles } from '@material-ui/core';
import { useNotify } from 'react-admin';
import { Field, Form } from 'react-final-form';
import SaveConfirmButton from '../SaveConfirmButton';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles(theme => ({
  formGroup: {
    margin: '0 0 1rem 0'
  },
  telemetryRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  telemtryInput: {
    margin: '0 0.1rem 0.25rem 0'
  },
  telemetryButton: {
    margin: '0.25rem 0 0.25rem',
    width: '100%'
  },
  clearPropertyButton: {
    minWidth: 0,
    borderRadius: '25px',
    padding: '3px',
    margin: '0 0 0 0.1rem',
    color: '#fff',
    background: '#f44336',
    fontSize: '12px'
  }
}));

const EditFlowTelemetryForm = ({
  flowItem = {},
  updateFlowItem = () => {},
  loading = false
}) => {
  const classes = useStyles();
  const notify = useNotify();

  const onSubmit = async values => {
    if (!flowItem || loading) return;
    try {
      await updateFlowItem(
        {
          ...flowItem,
          telemetryAction: values.telemetryAction,
          telemetryProperties: values.telemetryProperties.reduce(
            (acc, curr) => ({
              ...acc,
              [curr.key]: curr.value
            }),
            {}
          )
        },
        flowItem.sequence
      );
      notify('Telemetry updated', 'info');
    } catch (e) {
      notify(`Error: ${e.message}`, 'warning');
    }
  };

  return (
    <div>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          telemetryAction: flowItem?.telemetryAction ?? '',
          telemetryProperties:
            Object.keys(flowItem?.telemetryProperties ?? {}).map(key => ({
              key,
              value: flowItem?.telemetryProperties[key]
            })) ?? []
        }}
        validate={values => {
          const errors = {};
          if (!values.telemetryAction) {
            errors.telemetryAction = 'A telemetry action is required';
          }
          if (!values.telemetryProperties?.length) {
            errors.telemetryAction = 'At least one property is required';
          }
          if (
            values.telemetryProperties?.length &&
            values.telemetryProperties.some(p => !p.key || !p.value)
          ) {
            errors.telemetryAction = 'All properties must have a key and value';
          }
          return errors;
        }}>
        {({ handleSubmit, values, submitting, pristine, submitError }) => (
          <form onSubmit={handleSubmit}>
            <FormGroup className={classes.formGroup}>
              <Field name="telemetryAction">
                {({ input, meta }) => (
                  <TextField
                    className={classes.telemtryInput}
                    {...input}
                    label="Telemetry Action"
                    variant="filled"
                    error={meta.error && meta.touched}
                    helperText={meta.error && meta.touched ? meta.error : null}
                  />
                )}
              </Field>
            </FormGroup>
            <Field name="telemetryProperties" style={{ width: '100%' }}>
              {({ input, meta }) => (
                <>
                  {input.value.map((property, index) => (
                    <FormGroup className={classes.telemetryRow} key={index}>
                      <TextField
                        className={classes.telemtryInput}
                        value={property.key}
                        spellCheck={false}
                        label="Key"
                        variant="filled"
                        onChange={event =>
                          input.onChange(
                            input.value.map((item, i) =>
                              i === index
                                ? { ...item, key: event.target.value }
                                : item
                            )
                          )
                        }
                        error={meta.error && meta.touched}
                        helperText={
                          meta.error && meta.touched ? meta.error : null
                        }
                      />
                      <TextField
                        className={classes.telemtryInput}
                        value={property.value}
                        spellCheck={false}
                        label="Value"
                        variant="filled"
                        onChange={event =>
                          input.onChange(
                            input.value.map((item, i) =>
                              i === index
                                ? { ...item, value: event.target.value }
                                : item
                            )
                          )
                        }
                        error={meta.error && meta.touched}
                        helperText={
                          meta.error && meta.touched ? meta.error : null
                        }
                      />
                      <Button
                        className={classes.clearPropertyButton}
                        variant="contained"
                        onClick={() => {
                          input.onChange(
                            input.value.filter((_, i) => i !== index)
                          );
                        }}>
                        <DeleteIcon />
                      </Button>
                    </FormGroup>
                  ))}
                  <Button
                    className={classes.telemetryButton}
                    variant="contained"
                    color="secondary"
                    onClick={() =>
                      input.onChange([{ key: '', value: '' }, ...input.value])
                    }
                    endIcon={<AddCircleOutlineIcon />}>
                    Add Property
                  </Button>
                </>
              )}
            </Field>
            <FormGroup className={classes.formGroup}>
              <SaveConfirmButton
                className={classes.telemetryButton}
                isDoubleConfirm={true}
                submitting={submitting || loading}
                pristine={pristine}
                submitError={submitError}
                style={{ width: '100%', margin: 0, padding: 0 }}
              />
            </FormGroup>
          </form>
        )}
      </Form>
    </div>
  );
};

export default EditFlowTelemetryForm;
