import styled from 'styled-components';
import AddIcon from '@material-ui/icons/Add';
import { Button } from '@material-ui/core';
import { useState } from 'react';
import SimpleFlowItem from './FlowItems/SimpleFlowItem';

const FlowItemTemplateNode = ({
  templateSlug,
  flowItemGroup,
  flowItemType,
  nodeType,
  openCreateFlowTemplateModal,
  toggleFlowItem = null,
  ...props
}) => {
  const flowItem = { flowItemType, flowItemGroup, templateSlug, ...props };
  const [isAddButtonVisible, setIsAddButtonVisible] = useState(false);
  const flowItemComponent = SimpleFlowItem(flowItem);
  const showInfo = toggleFlowItem !== 'Flow Item Preview' || !flowItemComponent;
  const showFlowItem = toggleFlowItem !== 'Flow Item Info' && flowItemComponent;
  return (
    <Root
      onMouseEnter={() => setIsAddButtonVisible(true)}
      onMouseLeave={() => setIsAddButtonVisible(false)}>
      <div>#{flowItem.sequence}</div>
      {showInfo ? (
        <>
          {flowItemGroup ? (
            <ParentFlowTemplateSlug>{flowItemGroup}</ParentFlowTemplateSlug>
          ) : null}
          <ContentType>Type: {flowItemType}</ContentType>
          {nodeType !== 'default' ? (
            <IONode input={nodeType === 'input'}>
              {nodeType === 'input' ? 'START' : 'END'}
            </IONode>
          ) : null}
        </>
      ) : null}
      {showFlowItem ? flowItemComponent : null}
      {isAddButtonVisible && (
        <NodeButtonWrapper>
          <AddNodeButton
            variant="contained"
            color="default"
            onClick={openCreateFlowTemplateModal}
            startIcon={<AddIcon />}></AddNodeButton>
        </NodeButtonWrapper>
      )}
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  div {
    word-break: break-all;
  }
`;

const AddNodeButton = styled(Button)`
  position: relative;
  display: flex;
  border-radius: 50%;
  padding: 0;
  height: 30px;
  width: 3%;
  min-width: 30px;
  .MuiButton-startIcon {
    margin: 0;
  }
`;

const NodeButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: -30px;
`;

const ParentFlowTemplateSlug = styled.div`
  border: 1px solid #4b9c60;
  background: #fdee73;
  border-radius: 5px;
  padding: 0.25rem;
  margin: 0 0 0.25rem;
`;

const ContentType = styled.div`
  border: 1px solid #4b9c60;
  background: #cbc3e3;
  border-radius: 5px;
  padding: 0.25rem;
  margin: 0 0 0.25rem;
`;

const IONode = styled.div`
  font-weight: bold;
  ${props =>
    props.input
      ? `
      border: 1px solid #3d43db;
      background: #7175f0;
    `
      : `
      border: 1px solid #c92466;
      background: #ed669c;
  `}

  border-radius: 5px;
  padding: 0.25rem;
`;

export default FlowItemTemplateNode;
