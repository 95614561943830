import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Group,
  Image as KonvaImage,
  Rect,
  Text,
  Transformer
} from 'react-konva';

const URLImage = ({
  src,
  onStartTransform = () => {},
  onUpdateImage = () => {},
  handleSelect = () => {},
  selectedId = null,
  width = null,
  height = null,
  x = 0,
  y = 0,
  rotation = 0,
  index = null,
  id = null
}) => {
  const [isSelected, setIsSelected] = useState(false);
  const [image, setImage] = useState(null);
  const [lock, setLock] = useState(false);
  const imageStateRef = useRef({
    lock: false,
    showTransformer: false
  });
  const [showLockButton, setShowLockButton] = useState(false);
  const [showTransformer, setShowTransformer] = useState(false);
  const [isTransforming, setIsTransforming] = useState(false);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const imageRef = useRef();
  const groupRef = useRef();
  const transformerRef = useRef();
  const [dimensions, setDimensions] = useState({
    width,
    height,
    x,
    y,
    rotation
  });

  const handleImageClick = useCallback(() => {
    setIsSelected(true);
    handleSelect(id);
  }, [handleSelect, id]);

  useEffect(() => {
    imageStateRef.current.lock = lock;
    if (isSelected && transformerRef.current) {
      transformerRef.current.nodes([imageRef.current]);
      transformerRef.current.getLayer().batchDraw();
    }
    if (selectedId?.length) {
      setIsSelected(selectedId === id);
      onStartTransform(showTransformer);
      setIsTransforming(true);
    } else {
      setIsSelected(false);
    }
  }, [id, isSelected, lock, onStartTransform, selectedId, showTransformer]);

  useEffect(() => {
    const img = new window.Image();
    img.src = src;
    img.onload = () => {
      setImage(img);
      setDimensions({
        width: width || img.width,
        height: height || img.height,
        x,
        y,
        rotation
      });
    };
  }, [height, rotation, src, width, x, y]);

  useEffect(() => {
    const handleMouseUpOutside = () => {
      setIsMouseDown(false);
    };
    const handleMouseDownOutside = () => {
      setIsMouseDown(true);
    };
    document.addEventListener('mouseup', handleMouseUpOutside);
    document.addEventListener('touchend', handleMouseUpOutside);
    document.addEventListener('mousedown', handleMouseDownOutside);
    document.addEventListener('touchstart', handleMouseDownOutside);

    // delete or backspace key
    const handleKeyDown = e => {
      if (e.key === 'Delete' || e.key === 'Backspace') {
        if (isSelected && !imageStateRef.current.lock) {
          onUpdateImage(null, index);
        }
      }
      if (e.key === 'Escape') {
        setShowTransformer(false);
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('mouseup', handleMouseUpOutside);
      document.removeEventListener('touchend', handleMouseUpOutside);
      document.removeEventListener('mousedown', handleMouseDownOutside);
      document.removeEventListener('touchstart', handleMouseDownOutside);
    };
  }, [
    index,
    isSelected,
    lock,
    onStartTransform,
    onUpdateImage,
    showTransformer
  ]);

  const handleTransform = useCallback(() => {
    if (lock) return;

    const node = imageRef.current;
    const scaleX = node.scaleX();

    const newWidth = Math.min(window.innerWidth * 0.7, node.width() * scaleX);
    const newHeight = (newWidth / image.width) * image.height;
    node.width(newWidth);
    node.height(newHeight);
    node.scaleX(1);
    node.scaleY(1);

    // save dimensions
    const newDimensions = {
      width: newWidth,
      height: newHeight,
      x: node.x(),
      y: node.y(),
      rotation: node.rotation()
    };
    setDimensions(newDimensions);
    onUpdateImage(newDimensions, index);
  }, [image?.height, image?.width, index, onUpdateImage, lock]);

  const handleDragEnd = useCallback(
    e => {
      if (lock) return;

      const node = imageRef.current;

      const newDimensions = {
        width: node.width(),
        height: node.height(),
        x: node.x(),
        y: node.y(),
        rotation: node.rotation()
      };
      setDimensions(newDimensions);
      onUpdateImage(newDimensions, index);
    },
    [index, onUpdateImage, lock]
  );

  const handleMouseOver = useCallback(() => {
    setShowLockButton(true);
    if (isMouseDown || lock) return;
    setShowTransformer(true);
  }, [isMouseDown, lock]);

  const handleMouseOut = useCallback(() => {
    setShowLockButton(false);
    if (isMouseDown || isTransforming) return;
    setShowTransformer(false);
  }, [isMouseDown, isTransforming]);

  const handleMouseDown = useCallback(
    e => {
      if (isMouseDown || lock) return;
      setIsMouseDown(true);
      onStartTransform(showTransformer);
      setIsTransforming(true);
    },
    [isMouseDown, lock, onStartTransform, showTransformer]
  );

  const handleMouseUp = useCallback(() => {
    setIsMouseDown(false);
  }, []);

  return (
    <>
      {image && (
        <>
          <KonvaImage
            x={dimensions.x}
            y={dimensions.y}
            width={dimensions.width}
            height={dimensions.height}
            rotation={dimensions.rotation}
            image={image}
            ref={imageRef}
            draggable={!lock}
            onClick={handleImageClick}
            onTransformEnd={handleTransform}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onTouchStart={handleMouseDown}
            onTouchEnd={handleMouseUp}
            onDragStart={() => {
              handleMouseDown();
              setShowLockButton(false);
            }}
            onDragEnd={() => {
              handleDragEnd();
              setShowLockButton(true);
            }}
            style={{ cursor: 'pointer' }}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
          />
          <Group
            x={dimensions.x}
            y={dimensions.y}
            width={40}
            height={40}
            rotation={dimensions.rotation}
            ref={groupRef}>
            {showLockButton && (
              <>
                <Rect
                  width={30}
                  height={30}
                  fill="white"
                  cornerRadius={5}
                  shadowBlur={5}
                  onMouseOver={() => {
                    setShowLockButton(true);
                  }}
                />
                <Text
                  text={lock ? '🔒' : '🔓'}
                  fontSize={20}
                  align="center"
                  verticalAlign="middle"
                  width={30}
                  height={30}
                />
              </>
            )}
            <Rect
              width={30}
              height={30}
              fill="transparent"
              onMouseOver={() => {
                setShowLockButton(true);
              }}
              onMouseLeave={() => {
                setShowLockButton(false);
              }}
              onClick={() => {
                setShowTransformer(lock);
                setIsTransforming(lock);
                onStartTransform(lock);
                setLock(!lock);
              }}
            />
          </Group>
          <Transformer visible={isSelected} ref={transformerRef} />
        </>
      )}
    </>
  );
};

export default URLImage;
