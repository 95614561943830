import { List, Pagination, FunctionField } from 'react-admin';
import { Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import QuestionMetadataDataGrid from './Components/QuestionMetadataDataGrid';
import { useState, useEffect } from 'react';
import QuestionMetadataFilters from './Components/QuestionMetadataFilters';
import EditIcon from '@material-ui/icons/Edit';
import CreateQuestionModal from './Components/CreatQuestionModal';
import QuestionMetadataListActions from './Components/QuestionMetadataListActions';
import Tooltip from '@material-ui/core/Tooltip';
import { getQuestionMetaDataProgress } from './Components/validationHelpers';
import LightTooltip from 'Components/LightTooltip';
import { ImageSearch } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  root: {
    margin: '12px',
    minHeight: '350px'
  },
  editButton: {
    color: '#4353ff',
    fontSize: '0.8125rem',
    padding: '5px 10px 5px 10px',
    border: 'none',
    background: 'none',
    boxShadow: 'none',
    margin: '0 10px 0 0'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '600px'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  formGroup: { display: 'flex', flexDirection: 'row', width: '100%' },
  buttonWrapper: { display: 'flex' }
}));

const StyledList = styled(List)`
  .MuiToolbar-root {
    justify-content: flex-start;
  }
`;

const QuestionExtractorListPagination = props => (
  <Pagination rowsPerPageOptions={[20, 30, 40, 50]} {...props} />
);

const QuestionExtractorList = props => {
  document.body.style.overflow = '';
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(
    location.pathname.includes('create')
  );

  useEffect(() => {
    const runEffect = async () => {
      const isCreate = location.pathname.includes('create');
      if (isCreate !== isCreateModalOpen) setIsCreateModalOpen(isCreate);
    };
    runEffect();
  }, [isCreateModalOpen, location.pathname]);

  return (
    <Box className={classes.root}>
      {isCreateModalOpen && (
        <CreateQuestionModal
          isOpen={true}
          setIsOpen={() => history.push('/question-metadata' + location.search)}
        />
      )}
      <StyledList
        title="Question Metadata"
        filters={<QuestionMetadataFilters />}
        pagination={<QuestionExtractorListPagination />}
        actions={<QuestionMetadataListActions />}
        bulkActionButtons={false}
        perPage={20}
        {...props}>
        <QuestionMetadataDataGrid rowClick="">
          <FunctionField
            label="QuestionId"
            sortBy="QuestionId"
            render={r => r.questionId}
          />
          <FunctionField
            sortable={false}
            label="Preview"
            render={r => (
              <Tooltip
                style={{
                  background: '#fff',
                  border: '1px solid #ccc',
                  padding: '0.25rem'
                }}
                title={
                  <div>
                    <StyledPreviewImage
                      src={
                        'https://images.diagnosticquestions.com' + r.imageURL
                      }
                    />
                  </div>
                }>
                <StyledImage
                  src={'https://images.diagnosticquestions.com' + r.imageURL}
                />
              </Tooltip>
            )}
          />
          <FunctionField label="Metadata Count" render={r => r.metaCount} />
          <FunctionField
            label="Tags"
            render={r => {
              const result = getQuestionMetaDataProgress(r?.metaDataTags ?? []);

              return (
                <StyledTagProgress>
                  <LightTooltip placement="bottom" title="Answer A">
                    <StyledTagProgressItem
                      color={
                        result?.find(r => r === 'Answer A') ? '#44BBA8' : '#ccc'
                      }
                    />
                  </LightTooltip>
                  <LightTooltip placement="bottom" title="Answer B">
                    <StyledTagProgressItem
                      color={
                        result?.find(r => r === 'Answer B') ? '#44BBA8' : '#ccc'
                      }
                    />
                  </LightTooltip>
                  <LightTooltip placement="bottom" title="Answer C">
                    <StyledTagProgressItem
                      color={
                        result?.find(r => r === 'Answer C') ? '#44BBA8' : '#ccc'
                      }
                    />
                  </LightTooltip>
                  <LightTooltip placement="bottom" title="Answer D">
                    <StyledTagProgressItem
                      color={
                        result?.find(r => r === 'Answer D') ? '#44BBA8' : '#ccc'
                      }
                    />
                  </LightTooltip>
                  <LightTooltip placement="bottom" title="Question">
                    <StyledTagProgressItem
                      color={
                        result?.find(r => r === 'Question') ? '#44BBA8' : '#ccc'
                      }
                    />
                  </LightTooltip>
                </StyledTagProgress>
              );
            }}
          />
          <FunctionField
            label="Edit"
            render={r => (
              <EditButton record={r} classes={classes} history={history} />
            )}
          />
        </QuestionMetadataDataGrid>
      </StyledList>
    </Box>
  );
};

const EditButton = ({ record, classes, history }) => (
  <div>
    <Button
      color="default"
      className={classes.editButton}
      startIcon={<ImageSearch />}
      onClick={r =>
        history.push(`/question-metadata/${record.questionId}/show`)
      }>
      Preview
    </Button>
    <Button
      color="default"
      className={classes.editButton}
      startIcon={<EditIcon />}
      onClick={() =>
        window.open(
          `https://metaextractor.eedi.com/${
            record.questionId
          }?imageWidth=700&token=${localStorage.getItem('authToken')}`,
          '_blank'
        )
      }>
      Edit
    </Button>
  </div>
);

const StyledTagProgress = styled.div`
  display: flex;
  flex-direction: row;
  height: 30px;
`;

const StyledTagProgressItem = styled.div`
  border-radius: 15px;
  width: 10px;
  height: 100%;
  background-color: ${props => props.color ?? '#ccc'};
  margin: 0 1px 0 0;
`;

const StyledImage = styled.img`
  width: 40px;
  height: 30px;
`;

const StyledPreviewImage = styled.img`
  width: 300px;
  height: 200px;
`;

export default QuestionExtractorList;
