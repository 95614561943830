import { AppBar as RAAppBar } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import { useContext } from 'react';
import { SignalRConnectionContext } from 'Providers/SignalRProvider';
import Logo from './Logo';
import Circle from './Shapes/Circle';
import LightTooltip from './LightTooltip';
import CachedIcon from '@material-ui/icons/Cached';
import useGetPermissions from 'Hooks/useGetPermissions';

const useStyles = makeStyles(theme => ({
  bar: {
    backgroundColor: theme.palette.primary.main
  },
  spacer: {
    flex: 1
  }
}));

const AppBar = ({ ...props }) => {
  const permissions = useGetPermissions();
  const isContractor = !!permissions?.userRoles?.includes('ContractorAdmin');
  const { connectionInfo, startSignalRConnection } = useContext(
    SignalRConnectionContext
  );
  const { isConnected, isLoading, isReconnecting } = connectionInfo;
  const connectionSummary =
    'Connection Status: ' +
    (isConnected
      ? 'Connected'
      : isLoading
      ? 'Loading'
      : isReconnecting
      ? 'Reconnecting'
      : 'Disconnected');
  const statusHexColor = isConnected
    ? '#11DC00'
    : isLoading
    ? '#FFC700'
    : isReconnecting
    ? '#FFC700'
    : '#FF0000';
  const classes = useStyles();

  if (isContractor)
    return (
      <RAAppBar {...props} open={false} className={classes.bar}>
        <span className={classes.spacer} />
        <Logo />
        <span className={classes.spacer} />
      </RAAppBar>
    );

  return (
    <RAAppBar {...props} open={false} className={classes.bar}>
      <span className={classes.spacer} />
      <Logo />
      <span className={classes.spacer} />

      <LightTooltip placement="bottom" title={connectionSummary}>
        <div style={{ cursor: 'pointer' }}>
          <Circle
            width={20}
            height={20}
            radius={10}
            fill={statusHexColor}
            style={{ display: 'flex' }}
          />
        </div>
      </LightTooltip>
      <LightTooltip placement="bottom" title="Reconnect to SignalR">
        <div>
          <IconButton
            aria-label="reconnect"
            onClick={() => startSignalRConnection(true)}>
            <CachedIcon style={{ color: '#fff' }} />
          </IconButton>
        </div>
      </LightTooltip>
    </RAAppBar>
  );
};

export default AppBar;
