import LightTooltip from 'Components/LightTooltip';
import Circle from 'Components/Shapes/Circle';

const fillColors = [
  'rgb(222, 80, 80)',
  'rgb(110, 194, 130)',
  'rgb(	255, 191, 0)'
];

const QuizQuestionRetryProgressItem = ({
  isCorrect,
  isAnswered,
  width,
  height,
  style,
  title
}) => {
  return (
    <LightTooltip
      placement="top-start"
      title={
        title ??
        `Diagnostic Question Retry - ${
          isCorrect ? 'Correct' : isAnswered ? 'Incorrect' : 'Not Answered'
        }`
      }>
      <div>
        <Circle
          style={style}
          width={width ?? 12}
          height={height ?? 12}
          radius={width / 2}
          fill={fillColors[isCorrect ? 1 : isAnswered ? 0 : 2]}
        />
      </div>
    </LightTooltip>
  );
};

export default QuizQuestionRetryProgressItem;
