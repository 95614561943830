import { makeStyles } from '@material-ui/core/styles';
import {
  List,
  Box,
  Grid,
  Button,
  Typography,
  Modal,
  Backdrop,
  Card,
  CardContent,
  Container,
  FormGroup
} from '@material-ui/core';
import ListItemRow from 'Components/ListItemRow';
import { useCallback, useEffect, useState } from 'react';
import { useNotify } from 'react-admin';
import Api from 'Api/Api';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    margin: '0 0 12px 0'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  summaryLeft: {},
  summaryRight: {}
}));

const Parents = props => {
  const [parentsToShow, setParentsToShow] = useState([]);
  const { parents, studentUserId, uniqueCode } = props;
  const [isDetachParentModalOpen, setIsDetachParentModalOpen] = useState(false);
  const classes = useStyles();
  const notify = useNotify();

  useEffect(() => {
    setParentsToShow(parents);
  }, [parents]);

  const detachParent = useCallback(
    async studentUserId => {
      try {
        const updatedStudent = await Api.detachParents(studentUserId);
        if (!!updatedStudent?.parents?.length) {
          setParentsToShow(updatedStudent.parents);
        }
        notify('Parent detached!', 'success');
      } catch (error) {
        notify('Error detaching parent, see logs.', 'error');
        console.error(error);
      }
      setIsDetachParentModalOpen(false);
    },
    [notify]
  );

  if (!parentsToShow.length) return null;

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start">
      <Typography variant="h6">Invite Code</Typography>
      <Typography variant="body1">{uniqueCode}</Typography>
      {parentsToShow.map((p, i) => {
        return (
          <Grid
            item
            xs={12}
            className={i % 2 > 0 ? classes.summaryRight : classes.summaryLeft}
            key={i}>
            <Box>
              <List dense={true}>
                <ListItemRow
                  header="Name"
                  data={`${p.firstName} ${p.lastName}`}
                />
                <ListItemRow header="User ID" data={p.userId} />
                <ListItemRow header="Username" data={p.userName} />
                <ListItemRow
                  header="Subscription Status"
                  data={p.subscriptionStatus}
                />
              </List>
            </Box>
            <Box>
              <Button
                variant="contained"
                color="primary"
                disabled={
                  !!parentsToShow.find(
                    p => p.userName?.includes('unclaimed') || !p.firstName
                  )
                }
                onClick={() => setIsDetachParentModalOpen(true)}>
                Detach Parent
              </Button>
            </Box>
          </Grid>
        );
      })}
      <Modal
        open={isDetachParentModalOpen}
        className={classes.modal}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
        closeAfterTransition
        onClose={() => setIsDetachParentModalOpen(false)}>
        <Container maxWidth="sm">
          <Card className={classes.create}>
            <CardContent className={classes.content}>
              <Typography variant="h6" id="modal-title">
                Are you sure you want to detach this parent?
              </Typography>
              <Button
                variant="contained"
                color="primary"
                style={{ margin: '0 0.5rem 0 0' }}
                onClick={() => detachParent(studentUserId)}>
                Yes
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setIsDetachParentModalOpen(false)}>
                No
              </Button>
            </CardContent>
          </Card>
        </Container>
      </Modal>
    </Grid>
  );
};

export default Parents;
