import { List, Pagination, FunctionField, useDataProvider } from 'react-admin';
import TopicPathwayQuizFilter from './Components/TopicPathwayQuizFilter';
import TopicPathwayQuizDataGrid from './Components/TopicPathwayQuizDataGrid';
import { Box, Switch, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TopicPathwayQuizListActions from './Components/TopicPathwayQuizListActions';
import { useHistory, useLocation } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import CreateTopicPathwayQuizModal from './Components/CreateTopicPathwayQuizModal';
import EditTopicPathwayQuizModal from './Components/EditTopicPathwayQuizModal';
import CreateCollectionModal from './Components/CreateCollectionModal';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

const useStyles = makeStyles(theme => ({
  root: {
    margin: '12px',
    minHeight: '350px'
  },
  editButton: {
    color: '#4353ff',
    fontSize: '0.8125rem',
    padding: '4px',
    border: 'none',
    background: 'none',
    boxShadow: 'none'
  }
}));

const StyledList = styled(List)`
  .MuiToolbar-root {
    justify-content: flex-start;
  }
`;

const TopicPathwayQuizPagination = props => (
  <Pagination rowsPerPageOptions={[20, 30, 40, 50]} {...props} />
);

const TopicPathwayQuizList = props => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(
    location.pathname.includes('create-topic')
  );
  const [isCreateCollectionModalOpen, setIsCreateCollectionModalOpen] =
    useState(location.pathname.includes('create-collection'));
  const [isEditModalOpen, setIsEditModalOpen] = useState(
    location.pathname.includes('edit')
  );
  const dataProvider = useDataProvider();

  useEffect(() => {
    const runEffect = async () => {
      const isCreate = location.pathname.includes('create-topic');
      const isCreateCollection =
        location.pathname.includes('create-collection');
      const isEdit = location.pathname.includes('edit');
      if (isCreate !== isCreateModalOpen) setIsCreateModalOpen(isCreate);
      if (isEdit !== isEditModalOpen) setIsEditModalOpen(isEdit);
      if (isCreateCollection !== isCreateCollectionModalOpen)
        setIsCreateCollectionModalOpen(isCreateCollection);
    };
    runEffect();
  }, [
    isCreateCollectionModalOpen,
    isCreateModalOpen,
    isEditModalOpen,
    location.pathname
  ]);

  return (
    <Box className={classes.root}>
      {isCreateModalOpen && (
        <CreateTopicPathwayQuizModal
          isOpen={true}
          setIsOpen={() =>
            history.push('/topic-pathway-quizs' + location.search)
          }
        />
      )}
      {isEditModalOpen && (
        <EditTopicPathwayQuizModal
          isOpen={true}
          setIsOpen={() =>
            history.push('/topic-pathway-quizs' + location.search)
          }
        />
      )}
      {isCreateCollectionModalOpen && (
        <CreateCollectionModal
          isOpen={true}
          setIsOpen={() =>
            history.push('/topic-pathway-quizs' + location.search)
          }
        />
      )}
      <StyledList
        title="Topic Pathway Quizs"
        filters={<TopicPathwayQuizFilter />}
        pagination={<TopicPathwayQuizPagination />}
        bulkActionButtons={false}
        perPage={20}
        sort={{ field: 'Level, Sequence', order: 'ASC' }}
        actions={<TopicPathwayQuizListActions />}
        {...props}>
        <TopicPathwayQuizDataGrid rowClick="">
          <FunctionField
            sortByOrder="DESC"
            label="TopicPathwayQuizId"
            sortBy="TopicPathwayQuizId"
            render={r => `${r.id}`}
          />
          <FunctionField
            sortByOrder="DESC"
            label="QuizId"
            sortBy="QuizId"
            render={r => `${r.quizId}`}
          />
          <FunctionField label="Level" sortBy="Level" render={r => r.level} />
          <FunctionField
            sortByOrder="DESC"
            label="Sequence"
            sortBy="Sequence"
            render={r => r.sequence}
          />
          <FunctionField
            label="Enabled"
            sortBy="IsDisabled"
            render={r => (
              <Switch
                checked={!r.isDisabled}
                onChange={async () => {
                  await dataProvider.update('topic-pathway-quizs', {
                    id: r.topicPathwayQuizId,
                    body: { isDisabled: !r.isDisabled }
                  });
                }}
                name="checkedA"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            )}
          />
          <FunctionField
            label="Name"
            sortable={false}
            render={r => r.name ?? '-'}
          />
          <FunctionField
            label="CollectionId"
            sortBy="TopicPathwayCollectionId"
            render={r => r.topicPathwayCollectionId}
          />
          <EditTopicButton classes={classes} history={history} />
        </TopicPathwayQuizDataGrid>
      </StyledList>
    </Box>
  );
};

const EditTopicButton = ({ record, classes, history }) => (
  <Button
    variant="contained"
    color="default"
    className={classes.editButton}
    startIcon={<EditIcon />}
    onClick={r =>
      history.push(`/topic-pathway-quizs/edit/${record.topicPathwayQuizId}`)
    }>
    Edit
  </Button>
);

export default TopicPathwayQuizList;
